import React, { useEffect, useState } from "react"
import { LineChart, CardConsumerSummary, LoaderWindow } from "xsales-components"
import { I18n } from "@aws-amplify/core"
import { XSalesData } from "../../../DataQuery/XSalesData"
import moment from "moment"
import { XSalesConsumer } from "../../../Utils/Utils"
//import NoDataAvailable from "../StatementComponents/NoDataAvailable"
let chartLineRef = null

const enumOptions = {
    transactionsPerMonth: "1",
    estimatedBill: "2",
    averageOrdersAmount: "3",
    averageItemsPerOrder: "4",
}

const { currencySymbol } = {
    //currencyISO: "USD",
    currencySymbol: "$",
}

const SummarySupplier = (props) => {
    //const [dataHistory, setDataHistory] = useState([{ data: [] }])
    const [dataSummary, setDataSummary] = useState({ data: [] })
    //const [dataLabelsChart, setDataLabelsChart] = useState([])
    const [dataApiHistory, setDataApiHistory] = useState({
        data: [],
        monthsNames: [],
    })
    const [totalTransactions, setTotalTransactions] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [loader, setLoader] = useState(true)
    const getData = async (supplierID) => {
        const { years, periods } = getLastSixPeriods()
        let data = []
        const monthsNames = []
        for (const year of years) {
            const months = []
            for (const period of periods.filter((x) => x.year === year)) {
                months.push(period.month)
                monthsNames.push(XSalesConsumer.getMonth(period.month))
            }
            const minMonth = Math.min(...months)
                .toString()
                .padStart(2, "0")
            const maxMonth = Math.max(...months)
                .toString()
                .padStart(2, "0")
            //let dataApi = await XSalesData.Statement.getStatementBySupplier(
            let dataApi = await XSalesData.Statement.getStatementBySupplierPaddedMonth(supplierID, {
                between: [
                    { year: year, paddedMonth: minMonth },
                    { year: year, paddedMonth: maxMonth },
                ],
            })
            dataApi = dataApi.filter((x) => x.contractDetail.fees != null)
            if (dataApi.length > 0) data = [...data, dataApi]
        }
        const dataHistory = dataApiHistory
        dataHistory.data = data
        dataHistory.monthsNames = monthsNames
        setDataApiHistory(dataHistory)
    }

    const sumPeriod = (rpt, ySupplier, dataSupplier, namePeriod) => {
        const transactions = ySupplier.count.order + ySupplier.count.deposit + ySupplier.count.payment
        const amount = ySupplier.amount.orderUSD + ySupplier.amount.depositUSD + ySupplier.amount.paymentUSD
        const avgAmount = ySupplier.avgAmount.orderUSD + ySupplier.avgAmount.depositUSD + ySupplier.avgAmount.paymentUSD
        const avgItem = ySupplier.avgItem.order + ySupplier.avgItem.deposit + ySupplier.avgItem.payment
        dataSupplier[namePeriod].transactions += transactions
        dataSupplier[namePeriod].amount += amount
        dataSupplier[namePeriod].avgAmount += avgAmount
        dataSupplier[namePeriod].avgItem += avgItem
    }

    const buildInformationSummary = async (rpt = enumOptions.transactionsPerMonth) => {
        //let index = 0
        if (dataApiHistory.data.length > 0) {
            //console.log(dataApiHistory)

            const suppliers = [...new Set(dataApiHistory.data.flat().map((x) => x.supplierId))]
            const dataTable = []
            let { periods } = getLastSixPeriods()
            periods = periods.map((x) => x.month)

            suppliers.forEach((supplierId) => {
                const dataSupplier = {
                    id: supplierId,
                    name: "",
                    month1: {
                        transactions: 0,
                        amount: 0,
                        avgAmount: 0,
                        avgItem: 0,
                    },
                    month2: {
                        transactions: 0,
                        amount: 0,
                        avgAmount: 0,
                        avgItem: 0,
                    },
                    month3: {
                        transactions: 0,
                        amount: 0,
                        avgAmount: 0,
                        avgItem: 0,
                    },
                    month4: {
                        transactions: 0,
                        amount: 0,
                        avgAmount: 0,
                        avgItem: 0,
                    },
                    month5: {
                        transactions: 0,
                        amount: 0,
                        avgAmount: 0,
                        avgItem: 0,
                    },
                    month6: {
                        transactions: 0,
                        amount: 0,
                        avgAmount: 0,
                        avgItem: 0,
                    },
                    totalTransactions: 0,
                    totalAmount: 0,
                    totalAvgAmount: 0,
                    totalAvgItem: 0,
                }

                dataApiHistory.data
                    .flat()
                    .filter((x) => x.supplierId === supplierId)
                    .forEach((ySupplier) => {
                        dataSupplier.name = ySupplier.supplier.businessName
                        let indexMonth = periods.indexOf(ySupplier.month)
                        if (indexMonth > -1) {
                            indexMonth++
                        }
                        switch (indexMonth) {
                            case 1:
                                sumPeriod(rpt, ySupplier, dataSupplier, "month1")
                                break
                            case 2:
                                sumPeriod(rpt, ySupplier, dataSupplier, "month2")
                                break
                            case 3:
                                sumPeriod(rpt, ySupplier, dataSupplier, "month3")
                                break
                            case 4:
                                sumPeriod(rpt, ySupplier, dataSupplier, "month4")
                                break
                            case 5:
                                sumPeriod(rpt, ySupplier, dataSupplier, "month5")
                                break
                            case 6:
                                sumPeriod(rpt, ySupplier, dataSupplier, "month6")
                                break
                            default:
                                break
                        }
                    })

                dataSupplier.totalTransactions +=
                    dataSupplier.month1.transactions +
                    dataSupplier.month2.transactions +
                    dataSupplier.month3.transactions +
                    dataSupplier.month4.transactions +
                    dataSupplier.month5.transactions +
                    dataSupplier.month6.transactions

                dataSupplier.totalAmount +=
                    dataSupplier.month1.amount +
                    dataSupplier.month2.amount +
                    dataSupplier.month3.amount +
                    dataSupplier.month4.amount +
                    dataSupplier.month5.amount +
                    dataSupplier.month6.amount

                dataSupplier.totalAvgAmount +=
                    dataSupplier.month1.avgAmount +
                    dataSupplier.month2.avgAmount +
                    dataSupplier.month3.avgAmount +
                    dataSupplier.month4.avgAmount +
                    dataSupplier.month5.avgAmount +
                    dataSupplier.month6.avgAmount

                dataSupplier.totalAvgItem +=
                    dataSupplier.month1.avgItem +
                    dataSupplier.month2.avgItem +
                    dataSupplier.month3.avgItem +
                    dataSupplier.month4.avgItem +
                    dataSupplier.month5.avgItem +
                    dataSupplier.month6.avgItem

                dataTable.push(dataSupplier)
            })
            updateChartLabels(dataApiHistory.monthsNames, dataTable)
            const dataH = dataSummary
            dataH.data = [...dataTable]
            setDataSummary(dataH)
        }
    }

    const getDataChartBilledSummary = (data) => {
        const dataChart = {
            month1: 0,
            month2: 0,
            month3: 0,
            month4: 0,
            month5: 0,
            month6: 0,
        }
        data.forEach((obj) => {
            dataChart.month1 += obj.month1.amount
            dataChart.month2 += obj.month2.amount
            dataChart.month3 += obj.month3.amount
            dataChart.month4 += obj.month4.amount
            dataChart.month5 += obj.month5.amount
            dataChart.month6 += obj.month6.amount
        })
        return [dataChart.month1, dataChart.month2, dataChart.month3, dataChart.month4, dataChart.month5, dataChart.month6]
    }

    const getLastSixPeriods = () => {
        const now = moment()
        const periods = [] //{ year: 2020, month }
        let years = [] // years
        Array.from({ length: 6 }, (_, i) => i).forEach((_, index) => {
            const date = now.subtract(1, "months")
            const year = date.year()
            const month = date.month()
            periods.push({ year: year, month: month + 1 })
            years.push(year)
        })
        years = [...new Set(years)]
        return { years, periods }
    }

    const updateChartLabels = async (labels, datatable) => {
        //setDataLabelsChart(labels)
        const dataChart = getDataChartBilledSummary(datatable)
        if (chartLineRef) {
            chartLineRef.data.labels = labels
            chartLineRef.data.datasets[0].data = dataChart
            chartLineRef.update()
        }
    }

    const buildCardTotals = async () => {
        const totalTran = dataSummary.data.reduce((acc, el) => acc + el.totalTransactions, 0)
        setTotalTransactions(totalTran)

        const totalAmount = dataSummary.data.reduce((acc, el) => acc + el.totalAmount, 0)
        setTotalAmount(totalAmount)

        const maxValuesPerMonth = []
        maxValuesPerMonth.push(getMaxMonth(dataSummary.data, "month1"))
        maxValuesPerMonth.push(getMaxMonth(dataSummary.data, "month2"))
        maxValuesPerMonth.push(getMaxMonth(dataSummary.data, "month3"))
        maxValuesPerMonth.push(getMaxMonth(dataSummary.data, "month4"))
        maxValuesPerMonth.push(getMaxMonth(dataSummary.data, "month5"))
        maxValuesPerMonth.push(getMaxMonth(dataSummary.data, "month6"))
    }

    const getMonthNumber = (parameter) => {
        switch (parameter) {
            case "month1":
                return 1
            case "month2":
                return 2
            case "month3":
                return 3
            case "month4":
                return 4
            case "month5":
                return 5
            case "month6":
                return 6
            default:
                return 1
        }
    }

    const getMaxMonth = (arr, prop) => {
        let max
        let amountValue = 0
        const monthNumber = getMonthNumber(prop)
        for (var i = 0; i < arr.length; i++) {
            if (max == null || parseInt(arr[i][prop].amount) > parseInt(max[prop].amount)) max = arr[i]
            amountValue += max[prop].amount
        }
        return {
            id: max?.id,
            value: amountValue,
            month: prop,
            monthName: XSalesConsumer.getMonth(monthNumber),
            monthNumber: monthNumber,
        }
    }

    useEffect(() => {
        const user = localStorage.getItem("ConsumerUser") ? JSON.parse(localStorage.getItem("ConsumerUser")) : null
        if (user) {
            getData(user.supplier.id)
                .then(() => {
                    setLoader(false)
                    buildCardTotals()
                })
                .catch((err) => {
                    setLoader(false)
                    console.error(err)
                })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        buildInformationSummary()
        //buildCardTotals()
    })
    const formatNumber = (value) => {
        const locale = props.culture ? props.culture : navigator.language
        return new Intl.NumberFormat(locale, { style: "decimal" }).format(value)
    }

    /* const randomScalingFactor = function () {
        return (
            (Math.random() > 0.5 ? 1.0 : -1.0) * Math.round(Math.random() * 100)
        )
    } */

    return (
        <>
            {loader ? (
                <LoaderWindow />
            ) : (
                // dataApiHistory.data.length == 0 ?
                //     ""
                //     :
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="title__section_statement">
                            <img src={"https://cdn.xsalesconsumer.com/console/timeline-24px.svg"} alt="" loading="lazy" />
                            <h4>{I18n.get("lblsummaryDashboard")}</h4>
                        </div>

                        <div className="summary__cards_container">
                            <div className="statement__summary__total__cards">
                                <CardConsumerSummary
                                    image={"https://cdn.xsalesconsumer.com/console/dashboard_results.svg"}
                                    label={I18n.get("lbltotalTransactions")}
                                    value={formatNumber(totalTransactions)}
                                />

                                <CardConsumerSummary
                                    image={"https://cdn.xsalesconsumer.com/console/dashboard_payment.svg"}
                                    label={I18n.get("lbltotalbilledamount")}
                                    value={`${currencySymbol} ${formatNumber(totalAmount)}`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-7" style={{ overflowX: "auto" }}>
                        <h5>{I18n.get("lblbillingHistory")}</h5>
                        <div className="statement__chart__summary__line">
                            {
                                <LineChart
                                    labels={dataApiHistory.monthsNames}
                                    data={dataApiHistory.data}
                                    setRefChart={(chart) => (chartLineRef = chart)}
                                    // width={"100"}
                                    height={"200"}
                                />
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default SummarySupplier
