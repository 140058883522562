import Swal from "sweetalert2"
import { XSalesData, ImageUrlTypes } from "../DataQuery/XSalesData"
import PreferenceDefault from "../configConsumer/preferenceDefault.json"
import imgCDN from "../configConsumer/imagesDefaultCDN.json"
import { API, Auth } from "aws-amplify"
import { I18n } from "@aws-amplify/core"
import axios from "axios"
import csc from "country-state-city"
import moment from "moment"

var GOOGLE_FCM_KEY =
    "AAAAvrNJaxk:APA91bEc47mTcEQAe0Aj5FyV4DFLEVCFLv1_9-B2V3rGgEoXFwshxXlyZy2s71c-KDCrON2Gl3wQPlfj6ArlAKOrpj35WbdCqyQwDOqDK5vBaxPLCNGvvHKfKVyxYckho3tXgDbUa9h6"

// Class for utils components and functions
class Utils {
    constructor() {
        this.supplierLanguagesKey = "supplierLanguages"
    }
    getPhoneType = (listPhone, type) => {
        if (listPhone !== null) {
            let typePhone = listPhone.filter((x) => x.type.toString().toUpperCase() === type.toString().toUpperCase())
            if (typePhone.length > 0) {
                return typePhone[0].number
            }
            return ""
        } else {
            return ""
        }
    }

    formattAddress = (consumer) => {
        let addressFormatt = consumer.street1
        if (consumer.street2 !== undefined && consumer.street2 !== null && consumer.street2 !== "") {
            addressFormatt += ", " + consumer.street2
        }
        if (consumer.street3 !== undefined && consumer.street3 !== null && consumer.street3 !== "") {
            addressFormatt += ", " + consumer.street3
        }
        let city = consumer.city !== "" ? csc.getCityById(consumer.city).name : ""
        let state = consumer.state !== "" ? (city !== "" ? ", " : " ") + csc.getStateById(consumer.state).name + ". " : ". "
        let country = consumer.country !== "" ? csc.getCountryById(consumer.country).name + ". " : ""
        addressFormatt += ". " + city + state + consumer.zipcode + ". " + country

        return addressFormatt
    }

    sentPushNotification = async () => {
        return 0
    }

    firePushNotification = async (token, title, body, data) => {
        //console.log("parameter received", token, title, body, data)
        if (token !== "") {
            const headers = {
                Authorization: `key=${GOOGLE_FCM_KEY}`,
                "Content-Type": "application/json",
            }

            const bodyToSend = JSON.stringify({
                to: token,
                notification: {
                    title,
                    body,
                },
                data,
            })
            try {
                await axios({
                    method: "post",
                    url: "https://fcm.googleapis.com/fcm/send",
                    headers: headers,
                    data: bodyToSend,
                })
            } catch (err) {
                return { err }
            }
        }
    }

    fireMessage = (Title, Message, showLoading = true, timerProgressBar = false) => {
        Swal.fire({
            position: "top-end",
            width: 350,
            title: I18n.get(Title),
            html: I18n.get(Message),
            timer: 4000,
            timerProgressBar: timerProgressBar,
            onBeforeOpen: () => {
                if (showLoading) {
                    Swal.showLoading()
                }
            },
            onClose: () => {},
        }).then((result) => {
            /* Read more about handling dismissals below */
        })
    }

    containsObject = (obj, list) => {
        var i
        for (i = 0; i < list.length; i++) {
            if (list[i] === obj) {
                return true
            }
        }
        return false
    }

    generateQuickGuid = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    }

    getCurrentUserLogged = async () => {
        try {
            if (localStorage.getItem("ConsumerUser") === null || localStorage.getItem("ConsumerUser") === undefined) {
                return ""
            }
            let result = await JSON.parse(localStorage.getItem("ConsumerUser"))
            return result.username
        } catch (error) {
            return ""
        }
    }
    /**
     * @deprecated The method should not be used, please use getCurrentUserNew
     */
    getCurrentUser = async () => {
        try {
            if (localStorage.getItem("ConsumerUser") === null || localStorage.getItem("ConsumerUser") === undefined) {
                window.location.href = "/SignIn"
                return null
            } 
            let result = await JSON.parse(localStorage.getItem("ConsumerUser"))
            return result
        } catch (error) {
            console.log("Your current user is not logged")
            window.location.href = "/SignIn"
            return
        }
    }

    getCurrentUserNew = () => {
        try {
            if (localStorage.getItem("ConsumerUser") === null || localStorage.getItem("ConsumerUser") === undefined) {
                window.location.href = "/SignIn"
                return null 
            }
            let result = JSON.parse(localStorage.getItem("ConsumerUser"))
            return result
        } catch (error) {
            console.log("Your current user is not logged")
            window.location.href = "/SignIn"
            return null
        }
    }

    validateStatusUser(status, type) {
        if (type === "admin") return

        if ((status !== "ACTIVE" && status !== "CONFIGURING") || status === undefined) {
            this.logout()
        }
    }

    async logout() {
        await Auth.signOut()
            .then(async (data) => {
                localStorage.clear()
                window.location.href = "/Logout"
            })
            .catch((err) => {
                console.log(err)
            })
    }

    setCurrentUser = async (obj) => {
        localStorage.setItem("ConsumerUser", JSON.stringify(obj))
    }

    setLanguage = async (supplierId) => {
        XSalesData.Suppliers.GetPreferences(supplierId)
            .then((data) => {
                const lang = data.language
                I18n.setLanguage(lang)
            })
            .catch((err) => {
                I18n.setLanguage("EN")
            })
    }

    setAllLabels = async () => {
        if (localStorage.getItem("XSSConsumer") === null || localStorage.getItem("ConsumerLabels") === undefined) {
            let user = await this.getCurrentUser()
            if (user === null || user === undefined) {
                return null
            }
            return await XSalesData.Label.GetAllLabelWeb()
                .then((data) => {
                    return data.map((item) => {
                        let obj = {
                            Key: user.language === "ES" ? item.nameLanguage2 : item.nameLanguage1,
                            Label: item.labels.items.map((label) => {
                                return {
                                    id: label.id,
                                    lblLanguage: user.language === "ES" ? label.nameLanguage2 : label.nameLanguage1,
                                }
                            }),
                            Message: item.messages.items.map((message) => {
                                return {
                                    id: message.id,
                                    msgLanguage: user.language === "ES" ? message.nameLanguage2 : message.nameLanguage1,
                                }
                            }),
                        }
                        return obj
                    })
                })
                .catch((error) => {
                    return null
                })
            //actualmente retorna
            // {  id, windowId, nameLanguage1, nameLanguage2, nameLanguage3, nameLanguage4  }
            // se debe cambiar a
            // {  XSCLabel [{ windowId, nameLanguage }]  }
        }
        // return JSON.parse(localStorage.getItem('ConsumerLabels'))
    }

    ChangeColorFilter = (e) => {
        let lista = e.target.parentElement.children
        for (let item of lista) {
            if (item === e.target) {
                item.classList.add("active")
            } else {
                item.classList.remove("active")
            }
        }
    }

    ConvertEnumToArray = (enumObject) => {
        var all = []
        for (var key in enumObject) {
            all.push(enumObject[key])
        }
        return all
    }

    ConvertEnumToSelectStructure = async (enumObject) => {
        var all = []
        for (var key in enumObject) {
            all.push({
                label: enumObject[key],
                value: enumObject[key],
            })
        }
        return await all.map((item) => {
            return item
        })
    }

    getCurrentUserFromCognito = async () => {
        return Auth.currentAuthenticatedUser()
            .then((user) => {
                return user
            })
            .catch((err) => {
                console.log("getCurrentUserFromCognito", err)
                return "user not authenticated yet"
            })
    }

    getCurrentUserFromCognitoNew = async () => {
        try {
            return await Auth.currentAuthenticatedUser()
        } catch (error) {
            return null
        }
    }

    getDateFormatter = (pDate) => {
        if (pDate === undefined || pDate === null) return ""
        let tz = PreferenceDefault.TimeZone
        let culture = PreferenceDefault.Culture
        return new Date(pDate).toLocaleString(culture, { timeZone: tz })
    }

    CloseFilter = () => {
        try {
            window.$(".fas.fa-filter.filterIconFaviconIcon")[0].click()
        } catch (error) {
            console.log(error.message)
        }
    }

    getStringFromUint8Array = (param) => {
        var i,
            str = ""

        for (i = 0; i < param.length; i++) {
            str += "%" + ("0" + param[i].toString(16)).slice(-2)
        }
        return decodeURIComponent(str)
    }

    getMonth = (idx, format = "short") => {
        const objDate = new Date()
        objDate.setDate(1)
        objDate.setMonth(idx - 1)
        const locale = navigator.language,
            month = objDate.toLocaleString(locale, { month: format })
        return month
    }

    getPercent = (x, total, decimals = 2) => {
        const value = Number(((x / total) * 100).toFixed(decimals))
        return isNaN(value) ? 0 : value
    }

    async saveLanguagesSupplierPreferences(supplierId) {
        const result = await XSalesData.Suppliers.GetSupplierLanguages(supplierId)
        window.localStorage.removeItem(this.supplierLanguagesKey)
        window.localStorage.setItem(this.supplierLanguagesKey, result)
    }

    getLanguagesSupplierPreferences() {
        return window.localStorage.getItem(this.supplierLanguagesKey)
    }

    hideLanguagesControls() {
        let languages = this.getLanguagesSupplierPreferences()
        languages = languages.split(",")
        const [lang1, lang2, lang3, lang4] = languages
        const controls = document.querySelectorAll("input")
        const hideControl = (name) => {
            const [formGroup] = window.$(`input[name=${name}]`).parent()
            if (formGroup) {
                const [parentDiv] = window.$(formGroup).parent()
                window.$(formGroup).remove()

                if (parentDiv) {
                    const [parentCol] = window.$(parentDiv).parent()
                    window.$(parentDiv).remove()
                    if (parentCol) {
                        window.$(parentCol).remove()
                    }
                }
            }

            // formGroup.style.display = 'none'
        }
        controls.forEach((x) => {
            if (x.name.toLowerCase().includes(`language`)) {
                let hide = true
                if (x.name.toString().includes(`${lang1}`)) {
                    hide = false
                } else if (x.name.toString().includes(`${lang2}`)) {
                    hide = false
                } else if (x.name.toString().includes(`${lang3}`)) {
                    hide = false
                } else if (x.name.toString().includes(`${lang4}`)) {
                    hide = false
                }

                if (hide) {
                    hideControl(x.name)
                }
            }
        })
    }

    async uploadFile(file, supplierId, type, replace, copy) {
        try {
            /**
             * type categories: 1
             * type subcategories: 2
             * type products: 3
             * type logos: 4
             * type banners: 5
             */
            const formData = new FormData()
            formData.append("file", file)
            formData.append("supplierId", supplierId)
            formData.append("type", type)
            formData.append("replace", replace)
            formData.append("copy", copy)
            const result = await API.post("backendapi", "/images/upload", {
                header: { "content-type": "multipart/form-data" },
                body: formData,
            })
            return result
        } catch (error) {
            console.error(error)
            return []
        }
    }

    getImageUrl({ imageInternalName, supplierId, imageType }) {
        try {
            let storageSetups = localStorage.getItem("cdnSetups")
            // let currentUser = localStorage.getItem("ConsumerUser")
            let folder = ""
            const dataStorage = JSON.parse(storageSetups)
            const baseUrl = dataStorage[XSalesData.Constants.Setup.SY002]
            const suppliers = dataStorage[XSalesData.Constants.Setup.SY003]

            switch (imageType) {
                case ImageUrlTypes.Types.categories:
                    folder = dataStorage[XSalesData.Constants.Setup.SY004]
                    break
                case ImageUrlTypes.Types.subcategories:
                    folder = dataStorage[XSalesData.Constants.Setup.SY005]
                    break
                case ImageUrlTypes.Types.products:
                    folder = dataStorage[XSalesData.Constants.Setup.SY006]
                    break
                case ImageUrlTypes.Types.logos:
                    folder = dataStorage[XSalesData.Constants.Setup.SY007]
                    break
                case ImageUrlTypes.Types.banners:
                    folder = dataStorage[XSalesData.Constants.Setup.SY008]
                    break
                default:
                    break
            }
            return `https://${baseUrl}/${suppliers}/${supplierId}/${folder}/${imageInternalName}`
        } catch (error) {
            console.warn(error)
            return ""
        }
    }

    getImageUrlList({ imageInternalName, imageExternalURL, supplierId, imageType }) {
        let data = []
        const _imageInternalName = Array.isArray(imageInternalName) ? imageInternalName.filter((x) => x !== "") : []
        const _imageExternalURL = Array.isArray(imageExternalURL) ? imageExternalURL.filter((x) => x !== "") : []
        if (_imageExternalURL?.length > 0) {
            data = _imageExternalURL
        } else if (_imageInternalName?.length > 0) {
            _imageInternalName.forEach((x) => {
                data.push(
                    this.getImageUrl({
                        imageInternalName: x,
                        supplierId,
                        imageType,
                    })
                )
            })
        } else {
            data.push(imgCDN.SupplierConsumerBannerDefault)
        }
        return data.filter((x) => x !== "")
    }

    filenameFromUrl(path) {
        path = path.substring(path.lastIndexOf("/") + 1)
        return (path.match(/[^.]+(\.[^?#]+)?/) || [])[0]
    }

    getImageFiles(supplierId, imageInternalName, imageExternalURL, TYPE) {
        let imageFiles = []
        const _imageInternalName = Array.isArray(imageInternalName) ? imageInternalName.filter((x) => x !== "") : []
        const _imageExternalURL = Array.isArray(imageExternalURL) ? imageExternalURL.filter((x) => x !== "") : []

        if (_imageInternalName.length > 0 || _imageExternalURL.length > 0) {
            imageFiles = XSalesConsumer.getImageUrlList({
                imageInternalName: imageInternalName,
                imageExternalURL: imageExternalURL,
                supplierId: supplierId,
                imageType: TYPE,
            })
            imageFiles = imageFiles.map((x) => ({
                name: XSalesConsumer.filenameFromUrl(x),
                file: { src: x },
            }))
        }
        return imageFiles
    }

    waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

    formatShortDate = (date) => {
        if (!date) {
            return date
        }

        const month = moment(date).format("MMM")
        const day = moment(date).format("DD")
        const year = moment(date).format("YY")
        return `${day}-${month}-${year}`
    }

    formatShortDatetime = (date) => {
        if (!date) {
            return date
        }
        const month = moment(date).format("MMM")
        const day = moment(date).format("DD")
        const year = moment(date).format("YY")
        const time = moment(date).format("hh:mm a")
        return `${day}-${month}-${year} ${time}`
    }

    formattDecimal(value, cultureInfo = "en-US", numberDecimal = 2) {
        try{
            const formatter = new Intl.NumberFormat(cultureInfo, {
                minimumFractionDigits: numberDecimal,
                maximumFractionDigits: numberDecimal,
            })

            return formatter.format(value)
        }catch (error) {
            console.log(error.message);
        }
    }

    formatterCurrency(value, currencySymbol = "USD", cultureInfo = "en-US", symbolDecimal = ".") {
        if (!value) return
        try{
            if (cultureInfo.includes("es-")) {
                //value = value.toString().replace(',','')
                //value = value.toString().replace('.',',')
            }
            const formatter = new Intl.NumberFormat(cultureInfo, {
                style: "currency",
                currency: currencySymbol,
            })
            return formatter.format(value)
        }catch (error) {
            console.log(error.message);
        }
    }

    getRandomColorjs() {
        let letters = "0123456789ABCDEF"
        let color = "#"
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)]
        }
        return color
    }

    GetTableName(name) {
        return `${name}-${process.env.API_XSALESCONSUMERAPI_GRAPHQLAPIIDOUTPUT}-${process.env.ENV}`
    }

    randomColor() {
        const applyColors = []
        return () => {
            const colors = [
                "#7984D8",
                "#9AA3E2",
                "#BCC1EB",
                "#5765CE",
                "#7DD9F2",
                "#9EE3F5",
                "#BEECF9",
                "#5DD0EF",
                "#72CCFB",
                "#95D9FC",
                "#B9E5FD",
                "#4FBFFA",
                "#64FFDA",
                "#A2FFE9",
                "#C1FFF0",
                "#64FFDA",
                "#FF6E7D",
                "#FF929E",
                "#FFB7BE",
                "#FF4A5D",
                "#F06695",
                "#F48CAF",
                "#F7B3CA",
                "#EC407A",
                "#F9A94B",
                "#FABE78",
                "#FCD4A5",
                "#F7931E",
            ]
            const min = 0
            const max = colors.length - 1
            const randomNumber = Math.floor(Math.random() * (max - min + 1) + min)
            const color = colors[randomNumber]
            const idx = applyColors.findIndex((x) => x === color)
            if (idx !== -1 && applyColors.length === colors.length) {
                return this.getRandomColorjs()
            }
            applyColors.push(color)
            return color
        }
    }
    cleanString(string) {
        return string.toString().replace(/["]/g, '\\"')
    }
    arrayToLower(array) {
        if (Array.isArray(array)) {
            return array.map((item) => this.cleanString(item).toLowerCase())
        } else {
            return []
        }
    }

    isTrue(input) {
        if (typeof input == "string") {
            return input.toLowerCase() === "true"
        }

        return !!input
    }
    // arrayToLower(array){
    //     if (Array.isArray(array)) {
    //         return array.map((item) => item.toLowerCase())
    //     } else {
    //         return []
    //     }
    // }
}

const XSalesConsumer = new Utils()

export { XSalesConsumer }
