import React, { useEffect } from "react"
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom"

// import firebase from 'firebase'

import SignIn from "./componentsConsumer/Login/SignIn"
import Logout from "./componentsConsumer/Login/Logout"
import Forgot from "./componentsConsumer/Login/Forgot"
import SignUp from "./componentsConsumer/Login/SignUp"

import Main from "./views/Main/Index"
import Registration from "./componentsConsumer/Login/Registration"
// import Dashboard from "./views/Dashboard/index"
import Setting from "./views/Settings/index"
import ConsumerList from "./views/Consumer/index"
import ConsumerDetail from "./views/Consumer/Detail"
import ConsumersDetail from './views/Admin/Consumer/Detail'
import SupplierList from "./views/Supplier/index"
import SupplierDetail from "./views/Supplier/Detail"
import SupplierAdvanced from "./views/Supplier/Advanced"
import { AdminBanners } from "./views/Supplier/AdminBanners"
import MySupplier from "./views/Supplier/MySupplier"
import SupplierAccountStatement from "./views/Supplier/AccountStatement"
import ConsumersListing from './views/Admin/Consumer/index'
import ConsumersListAll from "./views/Admin/SupplierConsumer/index"
import Validation from "./componentsConsumer/Login/Validation"
import ValidationForgot from "./componentsConsumer/Login/ValidationForgot"
import AdminSupportSignIn from "./componentsConsumer/AdminSupport/SignIn"
import SyncData from "./componentsConsumer/SyncData"
import RolesList from "./views/Roles/index"
import RolesDetail from "./views/Roles/Detail"

import { XSalesConsumer } from "./Utils/Utils"
import Amplify, { Hub, I18n } from "aws-amplify"
import awsExports from "./aws-exports"
import Layout from "./componentsLayout/Layout"
// import { ProcessDataBasicTables } from "./basicTablesData/processBasicTables"
import { dict } from "./configConsumer/Internationalization"
import StatementDashboardXSM from './componentsConsumer/Dashboard/Statement'
import StatementDashboardSupplier from './componentsConsumer/Dashboard/StatementSupplier'
import DetailStateman1 from './componentsConsumer/Dashboard/StatementComponents/DetailStatement1'
import DetailStateman2 from './componentsConsumer/Dashboard/StatementComponents/DetailStatement2'
import { ExchangeRate } from './views/Admin/ExchangeRate'
import { DashboardConsumer } from './views/Dashboard/DashboardConsumer'
// import { XSalesData } from "./DataQuery/XSalesData"
// import firebaseApp from './firebase/firebaseApp'
// import { XSalesData } from "./DataQuery/XSalesData"
// import firebaseApp from './firebase/firebaseApp'
// import { getAnalytics } from "firebase/analytics";


//firebase.initializeApp(firebaseConfig);
//firebaseConfig.analytics().logEvent("APP_START")

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBf5b348DznY98tIFY-nw_43ES-UnIM0Ig",
//   authDomain: "xsales-consumer-consola.firebaseapp.com",
//   projectId: "xsales-consumer-consola",
//   storageBucket: "xsales-consumer-consola.appspot.com",
//   messagingSenderId: "823600381138",
//   appId: "1:823600381138:web:62c836b36772c9e98d810b",
//   measurementId: "G-HRX7DH9WBX"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// const analytics =  getAnalytics(firebaseApp);
import { SubCategory } from './views/AdminProducts/SubCategory/index'
import { Category } from './views/AdminProducts/Category/index'
import { Product } from './views/AdminProducts/Product/index'
import { OrderManagment } from './views/order/managment/'
import { DetailOrders } from './views/order/managment/DetailOrders'
import { DetailProduct } from './views/order/managment/DetailProducts'

import { WizardImport } from './views/Wizard'
I18n.putVocabularies(dict)
const [lang1] = navigator.language.split('-')
if (lang1) {
    I18n.setLanguage(lang1.toUpperCase())
}


Amplify.configure({
    ...awsExports,
    maxRetries: 3
})


// Amplify.Logger.LOG_LEVEL = 'DEBUG'
// ProcessDataBasicTables.processAll()

class objUser {
    constructor() {
        this.user = ""
        this.email = ""
        this.type = ""
        this.username = ""
        this.language = ""
        this.supplier = {}
    }
}

const App = () => {
    useEffect(() => {
        Hub.listen("auth", (hubData) => {
            const { event, data } = hubData.payload
            let userCurrent = data

            if (event === "signOut") {
                localStorage.removeItem("ConsumerUser");
                localStorage.clear();
                sessionStorage.clear();
            }

            if (event === "signUp") {
                let obj = new objUser()
                obj.user = userCurrent.user.username
                obj.email = userCurrent.user.username
                obj.type = "SUPPLIER"
                obj.username = userCurrent.userSub
                obj.language = "EN"
                obj.supplier = {}

                XSalesConsumer.setCurrentUser(obj)
                    .then((data) => {
                        // console.log("user set correctly")
                    })
                    .catch((err) => {
                        console.log("error set current user in signUp event")
                    })
            }

            if (event === "signIn") {
                localStorage.removeItem("ConsumerUser")

                if (
                    data.attributes["custom:userType"] === undefined ||
                    data.attributes["custom:userType"] === null ||
                    data.attributes["custom:userType"] !== "2"
                ) {
                    XSalesConsumer.fireMessage(
                        I18n.get("lblLogin"),
                        I18n.get("msgUserRegisteredAsConsumer")
                    )
                    return
                }

                let obj = new objUser()
                obj.user = userCurrent.attributes.email
                obj.email = userCurrent.attributes.email
                obj.type = "SUPPLIER"
                obj.username = userCurrent.username
                obj.language = "EN"




                XSalesConsumer.setCurrentUser(obj)
                    .then((data) => {
                        window.location.href = "/SyncData"
                    })
                    .catch((err) => {
                        XSalesConsumer.fireMessage(
                            "Login",
                            "Have been ocurren an error in SyncData"
                        )
                    })
            }
        })
    })

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={withRouter(SignIn)} />
                <Route exact path="/SignIn" component={withRouter(SignIn)} />
                <Route
                    exact
                    path="/Registration"
                    component={withRouter(Registration)}
                />
                <Route
                    exact
                    path="/SyncData"
                    component={withRouter(SyncData)}
                />
                <Route
                    path="/Admin/SignIn"
                    component={withRouter(AdminSupportSignIn)}
                />
                <Route exact path="/SignUp" component={withRouter(SignUp)} />
                <Route
                    exact
                    path="/Validation/:email"
                    component={withRouter(Validation)}
                />
                <Route
                    exact
                    path="/ValidationForgot/:email"
                    component={withRouter(ValidationForgot)}
                />
                <Route exact path="/Forgot" component={withRouter(Forgot)} />
                <Route exact path="/Logout" component={withRouter(Logout)} />
                <Layout>
                    <Route exact path="/Main" component={withRouter(Main)} />
                    <Route exact path="/Admin/Main" component={withRouter(Main)} />
                    <Route
                        exact
                        path="/Dashboard"
                        component={withRouter(DashboardConsumer)}
                    />
                    <Route
                        exact
                        path="/Admin/Dashboard"
                        component={withRouter(DashboardConsumer)}
                    />
                    <Route
                        exact
                        path="/Settings"
                        component={withRouter(Setting)}
                    />
                    <Route
                        exact
                        path="/Admin/Settings"
                        component={withRouter(Setting)}
                    />
                    <Route
                        exact
                        path="/Consumers/Index/:status"
                        component={withRouter(ConsumerList)}
                    />
                    <Route
                        exact
                        path='/Consumer/Detail/:id'
                        component={withRouter(ConsumersDetail)}
                    />
                    <Route
                        exact
                        path='/Admin/Consumer/Detail/:id'
                        component={withRouter(ConsumersDetail)}
                    />
                    <Route
                        exact
                        path="/Consumers"
                        component={withRouter(ConsumerList)}
                    />
                    <Route
                        exact
                        path="/Consumers/Index"
                        component={withRouter(ConsumerList)}
                    />
                    <Route
                        exact
                        path="/Consumers/Detail/:id"
                        component={withRouter(ConsumerDetail)}
                    />
                    {/* <Route
                            exact
                            path="/Consumers/Detail/:id/:subCategory"
                            component={withRouter(ConsumerDetail)}
                        /> */}
                    <Route
                        exact
                        path="/Admin/Consumers/Detail/:id"
                        component={withRouter(ConsumerDetail)}
                    />
                    <Route
                        exact
                        path="/Suppliers/Index/:XSM"
                        component={withRouter(SupplierList)}
                    />
                    <Route
                        exact
                        path="/Suppliers"
                        component={withRouter(SupplierList)}
                    />
                    <Route
                        exact
                        path="/Admin/Suppliers/Index"
                        component={withRouter(SupplierList)}
                    />
                    <Route
                        exact
                        path="/Admin/Product/Index"
                        component={withRouter(SupplierList)}
                    />
                    <Route
                        exact
                        path="/Admin/Category/Index"
                        component={withRouter(SupplierList)}
                    />
                    <Route
                        exact
                        path="/AdminProducts/SubCategory/Listing"
                        component={withRouter(SubCategory)}
                    />
                    <Route
                        exact
                        path="/AdminProducts/Category/Listing"
                        component={withRouter(Category)}
                    />
                    <Route
                        exact
                        path="/AdminProducts/Product/Listing"
                        component={withRouter(Product)}
                    />
                    <Route
                        exact
                        path="/Suppliers/Detail/:id"
                        component={withRouter(SupplierDetail)}
                    />
                    <Route
                        exact
                        path="/Suppliers/Advanced"
                        component={withRouter(SupplierAdvanced)}
                    />
                    <Route
                        exact
                        path="/Suppliers/Banners"
                        component={withRouter(AdminBanners)}
                    />
                    <Route
                        exact
                        path="/Suppliers/MySupplier"
                        component={withRouter(MySupplier)}
                    />
                    <Route
                        exact
                        path="/Suppliers/AccountStatement"
                        component={withRouter(SupplierAccountStatement)}
                    />
                    <Route
                        exact
                        path="/Consumer/Listing/:XSM"
                        component={withRouter(ConsumersListing)}
                    />
                    <Route
                        exact
                        path="/Consumer"
                        component={withRouter(ConsumersListing)}
                    />
                    <Route
                        exact
                        path="/Consumer/Index"
                        component={withRouter(ConsumersListing)}
                    />
                    <Route
                        exact
                        path="/Admin/Consumer/Listing"
                        component={withRouter(ConsumersListing)}
                    />
                    <Route
                        exact
                        path="/Admin/Consumer/ListAll"
                        component={withRouter(ConsumersListAll)}
                    />
                    <Route
                        exact
                        path="/Roles"
                        component={withRouter(RolesList)}
                    />
                    <Route
                        exact
                        path="/Admin/Roles/Index"
                        component={withRouter(RolesList)}
                    />
                    <Route
                        exact
                        path="/Roles/Detail/:id"
                        component={withRouter(RolesDetail)}
                    />
                    <Route
                        exact
                        path="/Admin/Statement"
                        component={withRouter(StatementDashboardXSM)}
                    />
                    <Route
                        exact
                        path="/StatementSupplier"
                        component={withRouter(StatementDashboardSupplier)}
                    />
                    <Route
                        exact
                        path="/DetailStatement1/:month/:year/:supplierID"
                        component={withRouter(DetailStateman1)}
                    />
                    <Route
                        exact
                        path="/DetailStatement2/:month/:year/:supplierID/:orderId/:orderCodeERP"
                        component={withRouter(DetailStateman2)}
                    />
                    <Route
                        exact
                        path="/Admin/DetailStatement1/:month/:year/:supplierID"
                        component={withRouter(DetailStateman1)}
                    />
                    <Route
                        exact
                        path="/Admin/DetailStatement2/:month/:year/:supplierID/:orderId/:orderCodeERP"
                        component={withRouter(DetailStateman2)}
                    />
                    <Route
                        exact
                        path="/Admin/exchangeRate"
                        component={withRouter(ExchangeRate)}
                    />
                    <Route
                        exact
                        path="/order/managment"
                        component={withRouter(OrderManagment)}
                    />
                    <Route
                        exact
                        path="/order/managment/detail/order/:date"
                        component={withRouter(DetailOrders)}
                    />
                    <Route
                        exact
                        path="/order/managment/detail/product/:date"
                        component={withRouter(DetailProduct)}
                    />

                    <Route
                        exact
                        path="/wizard/:table"
                        component={withRouter(WizardImport)}
                    />

                    {/* <Route
                            exact
                            path="/order/managment/detail/product/:date"
                            component={withRouter(DetailOrders)}
                        /> */}
                </Layout>
            </Switch>
        </BrowserRouter>
    )
}

export default App
