import React, { useEffect, useState, useRef } from "react"
import JSONPretty from "react-json-prettify"
import { github } from "react-json-prettify/dist/themes"
import { API, I18n } from "aws-amplify"
import { Button, InputText, DatePicker, InputNumber, SelectCombo, Loader } from "xsales-components"
import moment from "moment"
import { XSalesConsumer } from "../../Utils/Utils"

const shippingEnum = [
    { label: "PICKUP", value: "PICKUP" },
    { label: "DELIVERY", value: "DELIVERY" },
]

const paymentEnum = [
    { label: "CARD", value: "CARD" },
    { label: "CASH", value: "CASH" },
    { label: "OTHER", value: "OTHER" },
]

export const PostOrder = ({ type = "ORDER" }) => {
    const selectShipping = useRef(null)
    const selectPayment = useRef(null)
    const [responseApi, setResponseApi] = useState([])
    const [loader, setLoader] = useState(false)
    //const [selectShippingValue, setSelectShippingValue] = useState("")
    //const [selectPaymentValue, setSelectPaymentValue] = useState("")
    const [orderForm, setOrderForm] = useState({
        consumerId: "consumerId",
        consumerIdERP: "",
        paymentCondition: "",
        deliveryDate: moment().toDate().toISOString(),
        shipping: "",
        // currencyIsoRequest: "USD",
        demo: false,
        orderProduct: [{ productId: "", unitId: "", quantity: 0 }],
    })

    useEffect(() => {
        // window.$(`#datepicker-deliveryDate${type}`).on("change.datetimepicker", handleChangeDate)
    }, [])

    const handleChangeDate = (date) => {
        try {
            setOrderForm((prevState) => ({
                ...prevState,
                deliveryDate: date.toDate().toISOString(),
            }))
        } catch (error) {
            console.log(error.message)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setOrderForm((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const handleChangeOrderProduct = (e, index) => {
        const element = e.target
        const obj = orderForm
        switch (element.id) {
            case `productId${index}`:
                obj.orderProduct[index].productId = element.value
                break
            case `unitId${index}`:
                obj.orderProduct[index].unitId = element.value
                break
            case `quantity${index}`:
                obj.orderProduct[index].quantity = element.value
                break
            default:
                break
        }
        setOrderForm({ ...obj })
    }

    const handleClear = (e) => {
        // if (selectPayment.current) {
        //     selectPayment.current.select.clearValue();
        // }

        // if (selectShipping.current) {
        //     selectShipping.current.select.clearValue();
        // }

        // const consumerIdERP = document.getElementById(`consumerIdERP${type}`)
        // consumerIdERP.value = ""
        // const deliveryDate = document.getElementById(`deliveryDate${type}`)
        // deliveryDate.value = ""
        setOrderForm((prevState) => ({
            ...prevState,
            consumerId: "",
            consumerIdERP: "",
            paymentCondition: null,
            deliveryDate: moment().format("YYYY-MM-DD"),
            shipping: "",
            demo: false,
            orderProduct: [],
        }))
        setResponseApi([])
    }

    const handleClickExecute = async (e) => {
        setLoader(true)
        const { supplier } = await XSalesConsumer.getCurrentUser()
        try {
            const orderInput = { body: orderForm }
            let query = ""
            if (type === "ORDER") {
                //order
                query = `/suppliers/${supplier.id}/order`
            } else {
                //quote
                query = `/suppliers/${supplier.id}/quote`
            }
            const result = await API.post("backendapi", query, orderInput)
            setResponseApi(result)
            setLoader(false)
        } catch (error) {
            if (error.message === "Network Error") {
                setResponseApi({ message: "Network Error" })
            } else {
                console.log(error)
                setResponseApi(error.response)
            }
            setLoader(false)
        }
    }

    const handleClickAdd = (e) => {
        const obj = orderForm
        obj.orderProduct.push({ productId: "", unitId: "", quantity: 0 })
        setOrderForm({ ...obj })
    }

    const handleClickDeleteOrderProduct = (e, index) => {
        const obj = orderForm
        obj.orderProduct.splice(index, 1)
        setOrderForm({ ...obj })
    }

    const handleChangeSelectShipping = (e) => {
        if (e != null) {
            //setSelectShippingValue(e.value)
            setOrderForm((prevState) => ({
                ...prevState,
                shipping: e.value,
            }))
        }
    }

    const handleChangeSelectPayment = (e) => {
        if (e != null) {
            //setSelectPaymentValue(e.value)
            setOrderForm((prevState) => ({
                ...prevState,
                paymentCondition: e.value,
            }))
        }
    }

    return (
        <>
            <div className="row mb-4">
                <div className="col-sm-12">{<strong>{I18n.get("lblmessagepostorder")}</strong>}</div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="row">
                        <div className="col-sm-6 ">
                            <InputText
                                id={`consumerIdERP${type}`}
                                name="consumerIdERP"
                                label={I18n.get("lblcustomerId")}
                                maxLength="30"
                                value={orderForm.consumerIdERP}
                                onChange={handleChange}
                                isEditable={true}
                            />
                        </div>
                        <div className="col-sm-6">
                            <DatePicker
                                id={`deliveryDate${type}`}
                                name="deliveryDate"
                                value={orderForm.deliveryDate}
                                label={I18n.get("lbldeliverydate")}
                                onChange={handleChangeDate}
                                isEditable={true}
                            />
                        </div>
                        <div className="col-sm-6">
                            <SelectCombo
                                setRef={selectPayment}
                                isEditable={true}
                                id={`paymentCondition${type}`}
                                label={I18n.get("lblpaymentcondition")}
                                name="paymentCondition"
                                onChange={handleChangeSelectPayment}
                                value={orderForm.paymentCondition}
                                options={paymentEnum}
                            />
                        </div>
                        <div className="col-sm-6">
                            <SelectCombo
                                setRef={selectShipping}
                                isEditable={true}
                                id="shipping"
                                label={I18n.get("lblshipping")}
                                name="shipping"
                                value={orderForm.shipping}
                                onChange={handleChangeSelectShipping}
                                options={shippingEnum}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div
                            className="col-sm-12"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <h6>{I18n.get("lblorderproduct")}</h6>
                            <i className="fas fa-plus" style={{ cursor: "pointer" }} onClick={handleClickAdd}></i>
                        </div>
                        <hr />
                    </div>
                    {orderForm.orderProduct.map((obj, index) => {
                        return (
                            <div key={index} className="row">
                                <div className="col-sm-4">
                                    <InputText
                                        id={`productId${index}`}
                                        name={`productId${index}`}
                                        label={I18n.get("lblproductId")}
                                        maxLength="20"
                                        value={orderForm.orderProduct[index].productId}
                                        onChange={(e) => handleChangeOrderProduct(e, index)}
                                        isEditable={true}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <InputText
                                        id={`unitId${index}`}
                                        name={`unitId${index}`}
                                        label={I18n.get("lblunitId")}
                                        maxLength="20"
                                        value={orderForm.orderProduct[index].unitId}
                                        onChange={(e) => handleChangeOrderProduct(e, index)}
                                        isEditable={true}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <InputNumber
                                        id={`quantity${index}`}
                                        name={`quantity${index}`}
                                        label={I18n.get("lblquantity")}
                                        value={orderForm.orderProduct[index].quantity}
                                        onChange={(e) => handleChangeOrderProduct(e, index)}
                                        isEditable={true}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <i
                                        className="fas fa-minus-circle"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => handleClickDeleteOrderProduct(e, index)}
                                    ></i>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="row">
                        <div className="col-sm-12 container-post-order" style={{ maxHeight: "480px", position: "relative", overflow: "hidden" }}>
                            {loader ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Loader
                                        center={true}
                                        style={{
                                            height: "120px",
                                            width: "120px",
                                        }}
                                    />
                                </div>
                            ) : (
                                <JSONPretty json={responseApi} theme={github} padding={4} />
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-sm-12 mt-4"
                            
                        >

                            {
                                // <pre>
                                //     {
                                //         JSON.stringify(orderForm, null, 2)
                                //     }
                                // </pre>
                            }
                        </div>
                        <div style={{ textAlign:"right", width: "100%" }}>    
                                <Button
                                    id="clearOrder"
                                    name="clearOrder"
                                    label={I18n.get("lblclear")}
                                    className="mr-1"
                                    outline={true}
                                    size="md"
                                    color="secondary"
                                    onClick={handleClear}
                                />
                                <Button
                                    id="executeOrder"
                                    name="executeOrder"
                                    label={I18n.get("lblexecute")}
                                    outline={true}
                                    size="md"
                                    color="secondary"
                                    onClick={handleClickExecute}
                                />
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}
