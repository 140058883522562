import React, { useEffect, useState } from 'react'
import { Filters } from './Filters'
import { XSalesConsumer } from '../../../Utils/Utils'
import { LoaderWindow } from 'xsales-components'
import moment from 'moment'
import { TableSummary } from './TableSummary'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { filterData, TitleDetail, maxDays } from './commons'
import { useToast, Toastify, Confirm, Modal } from 'xsales-components'
import { I18n } from 'aws-amplify'
import { XSalesData } from '../../../DataQuery/XSalesData'
import { FormOrderDetail } from './FormOrderDetail'
import { ReportPdf } from './ReportPDF'

let gFilters
export function DetailOrders() {
    const componentRef = React.useRef(null);
    //const [filters, setFilters] = useState({ ...defaultFilters })
    const [supplierId, setSupplierId] = useState("")
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [dataComplete, setDataComplete] = useState([])
    const [dataPagination, setDataPagination] = useState([])
    const [groupType, setGroupType] = useState("group1")
    const [openModal, setOpenModal] = useState(false)
    const [dataDetail, setDataDetail] = useState({})
    const [showReportInfo, setShowReportInfo] = useState(false)
    const { date } = useParams()
    const router = useHistory()
    const { notifyAsync, notify } = useToast({
        pending: I18n.get('lblprocess'),
        success: I18n.get('lblsuccess'),
        error: I18n.get('lblprocesserror')
    })

    useEffect(() => {
        main()
    }, [date])// eslint-disable-line react-hooks/exhaustive-deps

    async function main() {
        const { supplier } = XSalesConsumer.getCurrentUserNew()
        setSupplierId(supplier.id)
        setLoader(true)
        filterPagination(date)
        setLoader(false)
    }

    async function setPagination(orders = []) {
        const paginationData = []

        // const item = window.sessionStorage.getItem("orderManagmentPagination")
        // if (!item) {
        // const dataCache = await getOrdersFromCache()
        orders.forEach(order => {
            const date = moment(order.deliveryOn).format("YYYY-MM-DD")
            if (!paginationData.find(x => x.date === date)) {
                paginationData.push({ shortDate: XSalesConsumer.formatShortDate(order.deliveryOn), date: date, shortDatetime: XSalesConsumer.formatShortDatetime(order.deliveryOn) })
            }
        })
        window.sessionStorage.setItem("orderManagmentPagination", JSON.stringify(paginationData))
        setDataPagination(paginationData)
        //}

        // setDataPagination(JSON.parse(item))
    }

    function handleChangeFilters(dataFilters) {
        if (!dataFilters) return

        dataFilter(dataFilters)
        /* setFilters(prevState => ({
            ...prevState,
            dataFilters
        })) */
        gFilters = { ...dataFilters }
    }

    useEffect(() => {
        //dataFilter(gFilters)
    }, [dataComplete])

    async function dataFilter(dataFilters) {
        setLoader(true)
        redirectReportType(dataFilters)
        if (supplierId !== "" && dataFilters.showRange) {
            const dateFrom = moment(dataFilters.dateFrom)
            const dateTo = moment(dataFilters.dateTo)
            const diff = dateTo.diff(dateFrom, 'days')
            if (diff > maxDays) {
                notify("warning", I18n.get('msgOrdMaMaxDays'))
                setLoader(false)
                return
            }
        }

        await loadData(dataFilters, [])
        setLoader(false)
    }

    async function loadData(dataFilters, dataComplete) {
        const { dataFiltered, valueGroupType } = await filterData(dataFilters, dataComplete)
        setData(dataFiltered)
        setDataComplete([...dataFiltered])
        setPagination(dataFiltered)
        setGroupType(valueGroupType)
    }

    function handlerPagination({ date }) {
        router.push(`/order/managment/detail/order/${date}`)
        // setInterval(() => {
        //     main()
        // },5000)
        // window.location.href = `/order/managment/detail/order/${date}`
    }

    function filterPagination(date) {
        const filterd = dataComplete.filter(x => moment(x.deliveryOn).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"))
        setData(filterd)
    }

    function Pagination(pagination) {
        if (!pagination) return
        const lastDate = moment(date).subtract(1, 'day').format("YYYY-MM-DD")
        const nextDate = moment(date).add(1, 'day').format("YYYY-MM-DD")
        const lastIndex = pagination.findIndex(x => x.date === lastDate)
        const nextIndex = pagination.findIndex(x => x.date === nextDate)
        let last = null
        let next = null
        if (lastIndex !== -1) {
            last = pagination[lastIndex]
        }

        if (nextIndex !== -1) {
            next = pagination[nextIndex]
        }
        return (
            <ButtonPaginationContainer>
                <>
                    {
                        last && <ButtonPagination onClick={() => handlerPagination(last)}>{I18n.get("lblOrdMaLastDay")}: <span>{last.shortDate}</span></ButtonPagination>
                    }

                    {
                        next && <ButtonPagination onClick={() => handlerPagination(next)}>{I18n.get("lblOrdMaNextDay")}: <span>{next.shortDate}</span></ButtonPagination>
                    }
                </>
            </ButtonPaginationContainer>
        )
    }


    async function handleClickDelivered(e) {
        const element = e.target
        const checked = element.checked
        const orderId = element.dataset.code
        const status = checked ? "DELIVERED" : "PLACED"
        const message = checked ? I18n.get("msgMarkDeliveredOrder") : I18n.get("msgUnmarkDeliveredOrder")
        const confirm = await Confirm({ ...message, message })
        if (!confirm) {
            element.checked = !checked
            return
        }

        await notifyAsync(XSalesData.Order.updateDeliveryOrder(orderId, status))
        loadData(gFilters, date)
    }

    function redirectReportType(filters) {
        const [reportType] = filters.itemsRadioType.filter(x => x.Key === 'products' && x.value === true)
        if (reportType) {
            router.push(`/order/managment/detail/product/${date}`)
        }
    }

    async function handleClickRow(orderId) {
        const order = await XSalesData.Order.getOrder(orderId)
        if (order) {
            setOpenModal(!openModal)
            setDataDetail(order)
        }
    }

    async function handleOnSubmitOrderDetail(orderId, { deliveryOn }) {
        await XSalesData.Order.updateDeliveryDate(orderId, deliveryOn)
        await loadData(gFilters, [])
        setOpenModal(false)
    }

    function getDefaultRadioLabel(radioName, _filters) {
        if (!_filters) return ""
        const [result] = _filters[radioName]?.filter(x => x.value === true)
        return result?.Label
    }

    return (
        <Container>
            <Filters
                filterName="filterDetailOrder"
                handleChangeFilters={handleChangeFilters}
                pdfRef={componentRef}
                handleBeforeGetContent={() => setShowReportInfo(true)}
                handleAfterPrint={() => setShowReportInfo(false)}
            />
            {
                loader ? <LoaderWindow center={true} />
                    :
                    <div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 mt-2'>
                                {
                                    <TitleDetail>{XSalesConsumer.formatShortDate(date)}</TitleDetail>
                                }
                              
                                <ReportPdf
                                    reference={componentRef}
                                    reportTitle={I18n.get("lblOrdMaOrders")}
                                    from={gFilters?.dateFrom}
                                    to={gFilters?.dateTo}
                                    status={getDefaultRadioLabel("itemsRadioOrderStatus", gFilters)}
                                    reportBy={getDefaultRadioLabel("itemsRadioReportBy", gFilters)}
                                    shippingType={getDefaultRadioLabel("itemsRadioShippingType", gFilters)}
                                    type={getDefaultRadioLabel("itemsRadioType", gFilters)}
                                    show={showReportInfo}
                                >
                                    {
                                        <TableSummary
                                            handleClickRow={handleClickRow}
                                            groupType={groupType}
                                            data={data}
                                            handleClickDelivered={handleClickDelivered} />

                                    }
                                </ReportPdf>
                            </div>
                        </div>
                        <FooterContainer>
                            <ButtonBack onClick={() => router.push('/order/managment')} ><i className="fas fa-arrow-left"></i>{I18n.get("lblOrdMaBack")}</ButtonBack>
                            {
                                Pagination(dataPagination)
                            }
                        </FooterContainer>
                        <Toastify autoClose={15000} />
                        <Modal
                            id="modalDetailOrder"
                            title={"Editar Orden"}
                            lblbtnSave={I18n.get('lblSave')}
                            lblbtnCancel={I18n.get('lblCancel')}
                            handleSave={(e) => console.log(e)}
                            handleCancel={(e) => setOpenModal(false)}
                            open={openModal}
                            size='lg'
                            submitButton
                            formId={"form-edit-order"}>
                            <FormOrderDetail
                                label={""}
                                data={dataDetail}
                                handleSubmit={handleOnSubmitOrderDetail}
                            />
                        </Modal>
                    </div>




            }
            {/* <pre>
                {
                    JSON.stringify(gFilters, null, 2)
                }
            </pre> */}
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`

const FooterContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
`
const ButtonBack = styled.button`
    border: none;
    background-color: #fff;
    color: var(--gray-xs-color);
    && i{
        margin-right: 5px;
    }
`

const ButtonPagination = styled.button`
    border: none;
    background-color: #fff;
    color: var(--gray-xs-color);
    border: 0.1px solid var(--gray-xs-color);
    padding: 10px;
    font-weight: bold;
    && span {
        font-weight: 400;
    }
`

const ButtonPaginationContainer = styled.div`
    display: flex;
    justify-content: space-around;
`


