import React, { useEffect, Fragment, useState } from "react"
// import Dashboard from "./../Dashboard/index"
import DashboardAdminSupport from './../Admin/Dashboard/AdminSupportDashboard'
import { XSalesConsumer } from '../../Utils/Utils'
import DashboardStatement from '../../componentsConsumer/Dashboard/StatementSupplier'

export default function Index() {
    
    const [roles, setRoles] = useState('')

    useEffect(() => {
        const ac = new AbortController()

        XSalesConsumer.getCurrentUser().then(data => {
            setRoles(data.type.toUpperCase())
        }).catch(err => {
            setRoles('')
        })

        return () => {
            ac.abort()
        }
    }, [])

    return (
        <Fragment>
            <div className="col-sm-12">
                {roles === "SUPPLIER" && <DashboardStatement />} 
                {roles === "ADMIN" && <DashboardAdminSupport />}
                {roles === "SUPPORT" && <DashboardAdminSupport />}
            </div>
        </Fragment>
    )
}
