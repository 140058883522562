import React from "react"
//import loading from "./../../assets/img/preloader.gif"..
import ImageCDN from "../../configConsumer/imagesDefaultCDN.json"
const loading = ImageCDN.loader

const Loader = (props) => (
    <div style={{ textAlign: "center" }}>
        <img
            src={loading}
            style={{
                height: "60px",
                width: "60px",
            }}
            alt="Loading gif"
        />
    </div>
)

export default Loader
