import React, { Fragment } from "react"
// import DatatableConsumer from "../../../componentsConsumer/Datatable/DatatableConsumer"
import { TabulatorTable, Title, Filters } from 'xsales-components'
import { XSalesConsumer } from "../../../Utils/Utils"
// import Filters from "../../../componentsConsumer/Filters"
import { XSalesData } from "./../../../DataQuery/XSalesData"
import { I18n } from "aws-amplify"


class index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            objData: {
                InitialData: [
                    {
                        id: "",
                        consumerId: "",
                        consumerName: "",
                        consumerBusinessName: "",
                        supplierId: "",
                        supplierName: "",
                        supplierBusinessName: "",
                        requestedOn: "",
                        responsedOn: "",
                        erpCode: "",
                        status: "",
                    },
                ],
                Data: [],
                Filter: [],
                Columns: [],
                RowClick: this.rowClickEventConsumer,
                GroupByHeader: this.GroupByHeader,
                GroupBy: "consumerId",
            },
            dataFilters: []
        }
    }

    rowClickEventConsumer = (e, row) => {
        let id = row.getData().id
        //window.location.href = `/Consumers/Detail/${id}`
        this.props.history.push(`/Admin/Consumers/Detail/${id}`)

        window.sessionStorage.setItem("pathRoot", this.props.match.path)
        
    }

    GroupByHeader = (value, count, data, group) => {
        const label = count > 1 ? I18n.get('lblItemsSuppliers') : I18n.get('lblItemSuppliers')
        return value + ' (' + count + ' ' + label + ')'
    }

    getLanguage = async () => {
        try {
            let lang = localStorage.getItem("languageApp");
            if (!lang) {
              const [lang1] = navigator.language.split('-');
              lang = lang1.toUpperCase();
            }
            I18n.setLanguage(lang.toUpperCase());
          } catch (error) {
            I18n.setLanguage('EN');
            console.log(error.message);
          }
    }

    componentDidMount() {
        document.title = "Admin:Consumer:ListAll"
        this.getLanguage();
        let objData= this.state.objData;
        let columns=  [
            {
                title: I18n.get("lblSuppplierConsumerID"),
                field: "id",
                visible: false,
            },
            {
                title: I18n.get("lblConsumerID"),
                field: "consumerId",
                visible: false,
            },
            {
                title: I18n.get("lblConsumerName"),
                field: "consumerName",
                visible: false,
            },
            {
                title: I18n.get("lblconsumerBusinessName"),
                field: "consumerBusinessName",
                visible: false,
            },
            {
                title: I18n.get("lblSupplierID"),
                field: "supplierId",
                visible: false,
            },
            {
                title: I18n.get("lblcomercialname"),
                field: "supplierName",
                widthGrow: 5,
            },
            {
                title: I18n.get("lblbusinessname"),
                field: "supplierBusinessName",
                widthGrow: 5,
            },
            {
                title: I18n.get("lblconRequestDate"),
                field: "requestedOn",
                widthGrow: 3,
                formatter:  (cell, formatterParams, onRendered) => XSalesConsumer.formatShortDate(cell.getValue()) 
            },
            {
                title: I18n.get("lblconApprovalDate"),
                field: "responsedOn",
                widthGrow: 3,
                formatter:  (cell, formatterParams, onRendered) => XSalesConsumer.formatShortDate(cell.getValue()) 
            },
            {
                title: I18n.get("lblconERPCode"),
                field: "erpCode",
                widthGrow: 3,
            },
            {
                title: I18n.get("lblconStatus"),
                field: "status",
                widthGrow: 2,
            },
        ]
        let dataFilters= [
            {
                label: I18n.get("lblALL"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "ALL",
                name: "ALL",
                type: "button",
                active: true
            },
            {
                label: I18n.get("lblAPPROVED"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button",
                id: "lblAPPROVED",
                name: "APPROVED",
                type: "button",
                active: false
            },
            {
                label: I18n.get("lblVALIDATING"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button",
                id: "lblVALIDATING",
                name: "VALIDATING",
                type: "button",
                active: false
            },
            {
                label: I18n.get("lblDELETED"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button ",
                id: "lblDELETED",
                name: "DELETED",
                type: "button",
                active: false
            },
            {
                label: I18n.get("lblREJECTED"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button",
                id: "lblREJECTED",
                name: "REJECTED",
                type: "button",
                active: false
            }
        ]
        objData.Columns= columns;
        this.setState({ dataFilters: dataFilters })
        
        XSalesData.Consumer.GetConsumersWithDependencies()
            .then(async (data) => {
                let consumers = data.Consumers
                let supplierConsumers = data.SupplierConsumer
                let dataFinal = []
                consumers.map((con) => (
                    supplierConsumers.map((supcon) => {
                        if (supcon.consumer !== null && supcon.supplier !== null && supcon.consumer?.id === con.id) {
                            dataFinal.push({
                                id: supcon.id,
                                consumerId: con.id,
                                consumerName: con.name,
                                consumerBusinessName: con.businessName,
                                supplierId: supcon.supplier?.id,
                                supplierName: supcon.supplier.name,
                                supplierBusinessName:
                                supcon.supplier.businessName,
                                requestedOn: supcon.requestedOn,
                                responsedOn: supcon.responsedOn,
                                erpCode: supcon.consumerIdERP,
                                status: I18n.get(`lbl${supcon.requestStatus}`),
                            })
                        }
                        return null
                    })
                ))

                objData.Data = dataFinal
                this.setState({ objData: objData })

                let filters = window.sessionStorage.getItem("filtersAdminConsumer")
                if(filters !== 'null' && filters !== null)
                {
                    this.EventFilterChange(filters)
                    document.getElementById(filters).classList.add("btn__filters__active--component")
                }

            })
            .catch((err) => {
                //objData.Data = err.data.listConsumers.items
                ///this.setState({ objData: objData })
                console.log("admin componentdidmount error ", err)
            })
    }

    onlyApproved = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [{ field: "status", type: "in", value: ["APPROVED"] }]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyValidating = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            { field: "status", type: "in", value: ["VALIDATING"] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyRejected = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [{ field: "status", type: "in", value: ["REJECTED"] }]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    ALL = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = []
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    lista = () => {
        return (
            <Fragment>
                <li className="lista" onClick={this.ALL}>
                    {I18n.get("lblALL")}
                </li>
                <li className="lista" onClick={this.onlyApproved}>
                    {I18n.get("lblAPPROVED")}
                </li>
                <li className="lista" onClick={this.onlyValidating}>
                    {I18n.get("lblVALIDATING")}
                </li>
                <li className="lista" onClick={this.onlyRejected}>
                    {I18n.get("lblREJECTED")}
                </li>
            </Fragment>
        )
    }


    EventFilterChange=(id)=>
    {
        const buttons = document.querySelectorAll(".btn__filters__active--component")
        buttons.forEach((btn) => {
            
            if (btn.id === id)
            {
                btn.classList.add("btn__filters__active--component")
            }
            else
            {
                btn.classList.remove("btn__filters__active--component")
            }
        })
        let objData = this.state.objData;
        switch (id) {
            case "ALL":
                objData.Filter = []
                break;
            case "lblAPPROVED":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblAPPROVED")] },
                ]
                break;
            case "lblVALIDATING":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblVALIDATING")] },
                ]
                break;
            case "lblDELETED":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblDELETED")] },
                ]
                break;
            case "lblREJECTED":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblREJECTED")] },
                ]
                break;
            default:
                objData.Filter = []
                break;
        }

        this.setState({ objData: objData })
        
    }

    handleClickFilters = e => {
        let { id } = e.target;
        // objData = this.state.objData
        if (id!=='ALL'){
            const hasClass = e.target.classList.contains('btn__filters__active--component');
            if (hasClass===false){
                id="ALL";
            } 
        }

        this.EventFilterChange(id)

       
        window.sessionStorage.setItem("filtersAdminConsumer", id)
        
    }


    render() {
        return (
            <Fragment>
                <Title label={I18n.get("lblConsumers")} />
                <Filters filterName="filterSupplier" data={this.state.dataFilters} />
                <div className="mt-4">
                    <TabulatorTable
                        Title={{}}
                        Data={this.state.objData.Data}
                        Columns={this.state.objData.Columns}
                        Filter={this.state.objData.Filter}
                        RowClick={this.state.objData.RowClick}
                        GroupBy={{
                            Key: "consumerName",
                            Collapse: true,
                        }}
                        placeholder={I18n.get("lblLoadingData")}
                        GroupByHeader={this.state.objData.GroupByHeader}
                        labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                        groupToggleElement="header"
                        lblSearch= {I18n.get("lblSearch")}
                    />
                </div>
            </Fragment>
        )
    }
}

export default index
