import React from "react"
import { InputText, Button, ToRight, Card } from "xsales-components"

import { I18n } from "@aws-amplify/core"
import { Auth } from "aws-amplify"
import { XSalesConsumer } from "./../../Utils/Utils"

//import Container from "./Container"
import PreferenceDefault from "../../configConsumer/preferenceDefault.json"
import ImageCDN from "../../configConsumer/imagesDefaultCDN.json"
import "../../assets/css/login.css"

//import logoConsumer from "./../../assets/img/consumerx-logo.png"
//const logoConsumer = ImageCDN.XSalesConsumerWebIsotipo

class ValidationForgot extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sectionData: {
                email: "",
                code: "",
                password: "",
            },
        }
    }

    componentDidMount() {
        let email = this.props.match.params.email
        let sd = this.state.sectionData
        sd.email = email
        XSalesConsumer.getCurrentUser()
            .then((data) => {
                sd.password = data.password
                this.setState({ sectionData: sd })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleChange = (e) => {
        const { name, value } = e.target
        let sd = this.state.sectionData
        sd[name] = value
        this.setState({
            sectionData: sd,
        })
    }

    sendToRegistration = async () => {
        let sd = this.state.sectionData
        await Auth.forgotPasswordSubmit(
            sd.email.trim().toLowerCase(),
            sd.code,
            sd.password
        )
            .then(async (data) => {
                XSalesConsumer.fireMessage("lblLogin", "msgPassChanged")
                window.location.href = "/SignIn"
            })
            .catch(async (err) => {
                if (err.code === "ExpiredCodeException") {
                    XSalesConsumer.fireMessage("lblLogin", "msgCodeExpired")
                    //se debe volver a enviar el codigo de confirmacion
                    await this.sendDataForgot()
                } else if (err.code === "CodeMismatchException") {
                    XSalesConsumer.fireMessage("lblLogin", "msgCodeIsNotValid")
                    //no es el mismo codigo de validacion que se envio
                }
            })
    }

    render() {
        return (
            <section className="login__container">
            <div className="login__box">
                    <a
                        href={PreferenceDefault.XSalesConsumerWEB}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={ImageCDN.XSalesConsumerWebIsotipo}
                            alt="Logo Consumer"
                        /><br />
                        <img
                            src={ImageCDN.XSalesConsumerWebLogo}
                            alt="Logo Consumer"
                        />
                    </a>
                <div className="login__form">
                <Card label={I18n.get("lblValidationCode")}>
                    <InputText
                        id="code"
                        name="code"
                        maxLength="6"
                        label={I18n.get("lblCode")}
                        value={this.state.sectionData.code}
                        onChange={this.handleChange}
                    />
                    <ToRight>
                        <Button
                            id="send"
                            name="send"
                            label={I18n.get("lblValidateCode")}
                            size="lg"
                            color="primary"
                            type="submit"
                            className="login__btn btn btn-primary btn-block"
                            outline={false}
                            onClick={() => this.sendToRegistration()}
                        />
                    </ToRight>
                </Card>
                </div>

            </div></section>
        )
    }
}

export default ValidationForgot
