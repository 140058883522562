import React from "react"

export default function AccountStatement() {
    return (
        <div className="row">
            <div className="col-5">
                <table></table>
            </div>
            <div className="col-7">
                <graphic></graphic>
            </div>
        </div>
    )
}
