import React, { useEffect} from "react";
import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from "mdbreact";
import { Link } from "react-router-dom";
import { I18n } from 'aws-amplify' 
import "../assets/css/breadCrumb.css"
const BreadcrumbPage = (props) => {

    
    //const [pathLink, setPathLink] = useState([])

    useEffect(() => {

        

    }, [])

    const t = () => {
        return props.pathLink?.map((item, index) => { 

            return (
                (index !== props.pathLink.length ) 
                ?
                    <MDBBreadcrumbItem>
                        <Link to={'../..' + (item.path.startsWith("/Admin") ? item.path.substring(6) : item.path)}>{I18n.get('lbl' + item.linkName)}</Link>
                    </MDBBreadcrumbItem>
                : ""
            )
        })
    }

    return (
        
        <MDBContainer color="white" className="container_breadcrumb">
            <MDBBreadcrumb>
                {t()}
            </MDBBreadcrumb>
        </MDBContainer>
        
    );
};

export default BreadcrumbPage;