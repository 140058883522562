import React, { Fragment } from "react"
import InputText from "../../componentsConsumer/InputText"
import {Tabulator} from "tabulator-tables"
//import Button from "../../componentsConsumer/Button"

import { XSalesConsumer } from "../../Utils/Utils"
import { I18n } from "aws-amplify"
import { XSalesData } from "../../DataQuery/XSalesData"

import $ from "jquery"

var tableAssigned = null
var tableAvailable = null
export default class Detail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentRol: {},
            currentRolId: "",
            security: [],
            crewSecurity: [],
            langCurrentUser: "",
        }
    }

    componentDidMount() {
        let rolId = this.props.match.params.id
        let lang = XSalesConsumer.getCurrentUser()
            .then((data) => {
                return data.language
            })
            .catch((err) => {
                return "ES"
            })
        this.setState({
            currentRolId: rolId,
            langCurrentUser: lang,
        })

        const AvailableRoleFilter = () => {
            var value = $("#search-available-roles").val()
            var filter = [
                [
                    { field: "id", type: "like", value: value },
                    {
                        field:
                            this.state.langCurrentUser === "ES"
                                ? "nameLanguage2"
                                : "nameLanguage1",
                        type: "like",
                        value: value,
                    },
                ],
            ]
            tableAvailable.setFilter(filter)
        }

        const AllowedRoleFilter = () => {
            var value = $("#search-assigned-roles").val()
            var filter = [
                [
                    { field: "id", type: "like", value: value },
                    {
                        field:
                            this.state.langCurrentUser === "ES"
                                ? "nameLanguage2"
                                : "nameLanguage1",
                        type: "like",
                        value: value,
                    },
                ],
            ]

            tableAssigned.setFilter(filter)
        }

        XSalesData.Rol.GetRolById(rolId)
            .then(async (data) => {
                // console.log("GetRolById Roles/Detail.js", data)
                this.setState({ currentRol: data })

                await XSalesData.Rol.GetSecuritiesByCrew(rolId)
                    .then((crewSecurities) => {
                        this.setState({
                            crewSecurity: crewSecurities.map((item) => {
                                return {
                                    id: item.security.id,
                                    nameLanguage1: item.security.nameLanguage1,
                                    nameLanguage2: item.security.nameLanguage2,
                                }
                            }),
                        })
                    })
                    .catch((error) => {
                        console.log(
                            "error from componentDidMount Detail.js XSalesData.Rol.GetRolById catch",
                            error
                        )
                    })

                await XSalesData.Rol.GetSecurities()
                    .then(async (secs) => {
                        // console.log("list of securities", secs)
                        this.setState({
                            security: secs.map((item) => {
                                return {
                                    id: item.id,
                                    nameLanguage1: item.nameLanguage1,
                                    nameLanguage2: item.nameLanguage2,
                                }
                            }),
                        })
                    })
                    .catch((err) => {
                        console.log("list of securities catch", err)
                    })
            })
            .catch((err) => {
                console.log("error searching rol by Id", err)
            })
            .finally(async (fin) => {
                let securities = []
                let cs = this.state.crewSecurity
                let secs = this.state.security

                await secs.map(async (items) => {
                    await cs.map(async (itemcs) => {
                        if (itemcs.id === items.id) {
                            securities.push(items.id)
                        }
                    })
                })

                let secArray = secs

                securities.map((itemToDelete) => {
                    secArray = secArray.filter(
                        (item) => item.id !== itemToDelete
                    )
                    return null;
                })

                this.setState({
                    security: secArray,
                })

                // console.log("los que deben quedar al final", secArray)

                tableAssigned = new Tabulator("#assignedPermission", {
                    data: this.state.crewSecurity,
                    layout: "fitColumns",
                    height: 300,
                    pagination: "local",
                    paginationSize: 5,
                    columns: [
                        {
                            title: I18n.get("lblsecurityassigned"),
                            field: "id",
                        },
                        {
                            title: I18n.get("lblnamelanguage"),
                            field:
                                this.state.langCurrentUser === "ES"
                                    ? "nameLanguage2"
                                    : "nameLanguage1",
                        },
                    ],
                    selectable: true,
                    selectableRangeMode: "click",
                })

                $("#search-assigned-roles").keyup(AllowedRoleFilter)

                tableAvailable = new Tabulator("#availablePermission", {
                    layout: "fitColumns",
                    height: 300,
                    pagination: "local",
                    paginationSize: 5,
                    columns: [
                        {
                            title: I18n.get("lblsecurityallowed"),
                            field: "id",
                        },
                        {
                            title: I18n.get("lblnamelanguage"),
                            field:
                                this.state.langCurrentUser === "ES"
                                    ? "nameLanguage2"
                                    : "nameLanguage1",
                        },
                    ],
                    data: this.state.security,
                    selectable: true,
                    selectableRangeMode: "click",
                })

                $("#search-available-roles").keyup(AvailableRoleFilter)
            })

        var _this = this
        $("#add-selected").click(function () {
            _this.AddRole()
        })

        $("#add-roles").click(function () {
            _this.AddRoles()
        })

        $("#clear-available").click(function () {
            _this.DeselectAvailableRole()
        })

        $("#clear-allowed").click(function () {
            _this.DeselectAllowedRole()
        })

        $("#remove-roles").click(function () {
            _this.RemoveAllRoles()
        })

        $("#remove-selected").click(function () {
            _this.RemoveRole()
        })
    }

    AddRole = () => {
        var selectedData = tableAvailable.getSelectedData()
        // console.log("selectedData", selectedData)
        tableAssigned.updateOrAddData(selectedData)
        $.each(selectedData, function (index, value) {
            // console.log("AddRole", index, value, tableAvailable)
            tableAvailable.deleteRow(value.id)
        })
    }

    AddRoles = () => {
        tableAvailable.selectRow("active")
        this.AddRole()
    }

    DeselectAvailableRole = () => {
        tableAvailable.deselectRow()
    }

    DeselectAllowedRole = () => {
        tableAssigned.deselectRow()
    }

    RemoveAllRoles = () => {
        //var selectRows = tableAssigned.selectRow()
        this.RemoveRole()
    }

    RemoveRole = () => {
        var selectedData = tableAssigned.getSelectedData()

        tableAvailable.updateOrAddData(selectedData)

        $.each(selectedData, function (index, value) {
            tableAssigned.deleteRow(value.id)
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target
        let current = this.state.currentRol
        current[name] = value
        this.setState({ currentRol: current })
    }

    saveRole = async () => {
        // console.log("saveRole", tableAssigned)
        var permissions = tableAssigned.getData()
        let role = this.state.currentRol
        XSalesData.Rol.SetRoleAndCrewSecurity(role, permissions)
            .then((data) => {
                //console.log("saveRole save Ok")
            })
            .catch((err) => {
                console.log("Detail.js/saveRole/catch ", err)
            })
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-4">
                        <InputText
                            id="nameLanguage1"
                            name="nameLanguage1"
                            label={I18n.get("lblnameLanguage1")}
                            maxLength="20"
                            value={this.state.currentRol.nameLanguage1}
                            onChange={this.handleChange}
                            isEditable={true}
                        />
                    </div>
                    <div className="col-sm-4">
                        <InputText
                            id="nameLanguage2"
                            name="nameLanguage2"
                            label={I18n.get("lblnameLanguage2")}
                            maxLength="20"
                            value={this.state.currentRol.nameLanguage2}
                            onChange={this.handleChange}
                            isEditable={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <InputText
                            id="nameLanguage3"
                            name="nameLanguage3"
                            label={I18n.get("lblnameLanguage3")}
                            maxLength="20"
                            value={this.state.currentRol.nameLanguage3}
                            onChange={this.handleChange}
                            isEditable={true}
                        />
                    </div>
                    <div className="col-sm-4">
                        <InputText
                            id="nameLanguage4"
                            name="nameLanguage4"
                            label={I18n.get("lblnameLanguage4")}
                            maxLength="20"
                            value={this.state.currentRol.nameLanguage4}
                            onChange={this.handleChange}
                            isEditable={true}
                        />
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <p className="lead">
                                {I18n.get("lblsecurityallowed")}
                            </p>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <input
                                    type="text"
                                    id="search-available-roles"
                                    className="form-control"
                                    placeholder=""
                                    aria-label="Search Roles"
                                    aria-describedby="search-users-icon"
                                />
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text input-group-icon"
                                        id="search-users-icon"
                                    >
                                        <i className="material-icons">
                                            {I18n.get("lblSearch")}
                                        </i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="card">
                                <div
                                    className="col-sm-12"
                                    id="availablePermission"
                                    key="availblePermission"
                                ></div>
                            </div>
                        </div>
                        {/*<div className="form-group">
                            <div className="form-row">
                                <div className="col-sm-4">
                                    <button
                                        type="button"
                                        id="clear-available"
                                        className="btn btn-outline-secondary btn-block"
                                        data-label="lblclear"
                                    >
                                        {I18n.get("lblClear")}
                                    </button>
                                </div>
                                <div className="col-sm-4">
                                    <button
                                        type="button"
                                        id="add-roles"
                                        className="btn btn-outline-secondary btn-block"
                                        data-label="lbladdall"
                                    >
                                        {I18n.get("lblAddAll")}
                                    </button>
                                </div>
                                <div className="col-sm-4">
                                    <button
                                        type="button"
                                        id="add-selected"
                                        className="btn btn-block btn-outline-primary bounds-button"
                                        data-label="lbladd"
                                    >
                                        {I18n.get("lblAdd")}
                                    </button>
                                </div>
                            </div>
        </div>*/}
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <p className="lead">
                                {I18n.get("lblsecurityassigned")}
                            </p>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <input
                                    type="text"
                                    id="search-assigned-roles"
                                    className="form-control"
                                    placeholder=""
                                    aria-label="Search Roles"
                                    aria-describedby="search-users-icon"
                                />
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text input-group-icon"
                                        id="search-users-icon"
                                    >
                                        <i className="material-icons">
                                            {I18n.get("lblSearch")}
                                        </i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="card">
                                <div
                                    className="col-sm-12"
                                    id="assignedPermission"
                                    key="assignedPermission"
                                ></div>
                            </div>
                        </div>
                      {/*  <div className="form-group">
                            <div className="form-row">
                                <div className="col-sm-4">
                                    <button
                                        type="button"
                                        id="clear-allowed"
                                        className="btn btn-outline-secondary btn-block"
                                        data-label="lblclear"
                                    >
                                        {I18n.get("lblClear")}
                                    </button>
                                </div>
                                <div className="col-sm-4">
                                    <button
                                        type="button"
                                        id="remove-roles"
                                        className="btn btn-outline-secondary btn-block"
                                        data-label="lbladdall"
                                    >
                                        {I18n.get("lblRemoveAll")}
                                    </button>
                                </div>
                                <div className="col-sm-4">
                                    <button
                                        type="button"
                                        id="remove-selected"
                                        className="btn btn-block btn-outline-primary bounds-button"
                                        data-label="lbladd"
                                    >
                                        {I18n.get("lblRole")}
                                    </button>
                                </div>
                            </div>
        </div>*/}
                    </div>

                    {/*<div className="col s12 m12 l12">
                        <div style={{ float: "right" }} className="col-sm-3">
                            <Button
                                id="send"
                                name="send"
                                label={I18n.get("lblSave")}
                                className="col-sm-12 btn btn-block btn-outline-primary bounds-button"
                                onClick={() => this.saveRole()}
                            />
                        </div>
    </div>*/}
                </div>
            </Fragment>
        )
    }
}
