import React, { useState} from "react"
import {  SectionEditable, InputText ,SelectCombo  } from 'xsales-components'
//import loadingLine from "../../assets/img/loadingLine.gif"
//import { XSalesData } from "../../DataQuery/XSalesData"
//import { XSalesConsumer } from "../../Utils/Utils"
import { I18n } from "@aws-amplify/core"
//import CurrencyConfigConsumer from "./CurrencyConfigConsumer"



// import $ from "jquery"

const listStatus = () => {
    return [
        { label: I18n.get("lblLOCKED"), value: "LOCKED" },
        { label: I18n.get("lblUNLOCKED"), value: "UNLOCKED" },
    ]
}
export default function Preferences(props) {

//const [actionCancelEdit, setActionCancelEdit] = useState(false)

    const allowEdit =
        props.AllowEdit === null || props.AllowEdit === undefined
            ? true
            : props.AllowEdit

    //begin minimum config
   /*  const [updateAssignMinimumConfig, setUpdateAssignMinimumConfig] = useState(
        false
    ) */
    /* const UpdateActionButtonMinimumConfig = () => {
        setUpdateAssignMinimumConfig(!updateAssignMinimumConfig)
    } */

    /* const CancelMinimumConfig = () => {
        props.CancelMinimumConfig()
        setUpdateAssignMinimumConfig(false)
    } */

    /* const ChangeMinimumConfig = () => {
        props.ChangeMinimumConfig()
        setUpdateAssignMinimumConfig(false)
    } */
    //end minimum config

    //begin blockedForSale
    /* eslint-disable no-unused-vars */
    const [
        statusBlockedForSalesSupplierConsumer,
        setStatusBlockedForSalesSupplierConsumer,
    ] = useState(listStatus)
    /* eslint-enable no-unused-vars */

    const [
        updateAssignBlockedForSale,
        setUpdateAssignBlockedForSale,
    ] = useState(false)

    const UpdateActionButtonBlockedForSale = () => {
        setUpdateAssignBlockedForSale(!updateAssignBlockedForSale)
    }

    const CancelBlockedForSale = () => {
        props.CancelBlockedForSale()
        setUpdateAssignBlockedForSale(false)
    }

    const ChangeBlockedForSale = () => {
        props.ChangeBlockedForSale()
        setUpdateAssignBlockedForSale(false)
    }
    //end blockedForSaleRefreshData

    //const [modalContent, setModalContent] = useState("")

    //begin netoBruto
    /* const handleChangeNetoBruto = (e) => {
        props.handleChangeNetoBruto(e)
    } */

    //const [UpdateOrderCatalog, setUpdateOrderCatalog] = useState(false)

   /*  const CancelAssignOrderCatalog = (e) => {
        //setActionCancelEdit(true)
        setUpdateOrderCatalog(false)
        props.RefreshData()
    } */
 
    /* const ChangeAssignOrderCatalog = async (e) => {
        const SelectedCurrency = props.Data.sectionData.SelectedCurrency
        const SupplierCurrency = props.Data.sectionData.SupplierCurrency
                if (SelectedCurrency !== SupplierCurrency)
                {
                if (await Confirm({

                    tittle :  I18n.get("lblConfirmTittle"),
                    message: I18n.get("msgChangeCatalogExclusive"),
                    confirmText:  I18n.get("lblConfirmText"),
                    cancelText:  I18n.get("lblcancelText"),
                    confirmColor: "primary",
                    cancelColor: "link text-danger"

                })) 
                    {
                        props.handleChangeTypeExclusive()
                    }
                }
               
            
      
        props.ChangeCurrencyConsumer()
        props.ChangeCatalogConfig(e)
        props.ChangeNetoBrutoConfig(e)
        setUpdateOrderCatalog(false) 
        
    } */

    //end netoBruto

    //begin erpCode
  /*   const [modalValidationERPCode, setModalValidationERPCode] = useState({
        name: "",
        businessName: "",
        consumerIdERP: "",
        error: "",
    }) */

    /* const [showModal, setShowModal] = useState(false) */
    
    /* const [statusValidationERPCode, setStatusValidationERPCode] = useState(
        "invalid"
    ) */

    /* const handleValidationERPCode = async (consumerIdERP) => {
       
        //(true)
        //setShowModal(false)
        if (
            consumerIdERP === undefined ||
            consumerIdERP === null ||
            consumerIdERP === ""
        ) {
            XSalesConsumer.fireMessage("lblAssignErpCode", "msgFillErpCode")
            window.$("#modalValidateERP").modal("toggle")
            return
        }
  

        
        const supplierId = await XSalesConsumer.getCurrentUser().then(
            (data) => {
              return data.supplier.id
            }
            
        )

        const demoMode = await  XSalesData.Suppliers.GetSupplierDemoMode(supplierId)
        if (demoMode === 'true'){
            setTimeout(() => {
                window.$('#modalValidateERP').modal('hide');
            },2000)
            setNoAllowSave(false)
            setStatusValidationERPCode("valid")
            // setModalContent("")
            // setShowModal(false)
            return
        }

        await XSalesData.Suppliers.GetIfERPCodeIsValid(
            supplierId,
            consumerIdERP
        )
            .then((data) => {
                setModalValidationERPCode((prevState) => {
                    prevState.name = data.name
                    prevState.businessName = data.businessName
                    prevState.consumerIdERP = data.customerId
                    return prevState
                })
                let mc = modalValidationERPCode
                let content =
                    mc.error !== "" &&
                    mc.name === "" &&
                    mc.businessName === "" &&
                    mc.consumerIdERP === "" ? (
                        <p>{mc.error}</p>
                    ) : (
                        <Fragment>
                            <p style={{ fontSize: "1.25rem" }}>
                                {mc.consumerIdERP} {` - `} {mc.businessName}
                            </p>
                            <p style={{ fontSize: "1rem" }}>{mc.name}</p>
                        </Fragment>
                    )
                setNoAllowSave(mc.error !== "" ? true : false)
                setModalContent(content)
            })
            .catch((error) => {
                setModalValidationERPCode((prevState) => {
                    prevState.error = new Error(error).message
                    return prevState
                })
                setNoAllowSave(true)
                //setModalContent(modalValidationERPCode.error)
            })
            .finally((fin) => {})
    } */

    /* const ChangeAssignERPCode = (e) => {
        let erpCode = props.Data.sectionData.erpCode
        if (erpCode === undefined || erpCode === null || erpCode === "") {
            XSalesConsumer.fireMessage("msgFillErpCode", "msgValidateErpCode")
            CancelAssignERPCode(e)
            props.RefreshData()
            return
        }

        if (statusValidationERPCode === "valid") {
            props.ChangeAssignERPCode(e)
            //setUpdateAssignErpCode(false)
        } else {
            XSalesConsumer.fireMessage("msgFillErpCode", "msgValidateErpCode")
            CancelAssignERPCode(e)
            props.RefreshData()
            return
        }
    } */

    //const [noAllowSave, setNoAllowSave] = useState(false)

   /*  const CancelAssignERPCode = (e) => {
        props.CancelAssignERPCode(e)
        //setUpdateAssignErpCode(false)
        props.RefreshData()
    } */

    //const [updateAssignErpCode, setUpdateAssignErpCode] = useState(false)

   /*  const UpdateActionButtonErpCode = () => {
        setUpdateAssignErpCode(!updateAssignErpCode)
    } */

    /* const UpdateActionButtonOrderCatalog = () => {
        setUpdateOrderCatalog(!UpdateOrderCatalog)
    } */

   /*  const handleChangeCatalogType = (e) => {
        const SupplierCurrency = props.Data.sectionData.SupplierCurrency
        const SelectedCurrency = props.Data.sectionData.SelectedCurrency
 
            
            if(SelectedCurrency !== "" && SupplierCurrency != SelectedCurrency )
            {
                const { id } = e.target

                if(id == "merge")
                {
                    XSalesConsumer.fireMessage("", I18n.get("msgCatalogExclusive"))
                    props.handleChangeTypeExclusive()
                }
                 else 
                {
                    props.handleChangeCatalogType(e)
                } 
            }
            else
            {
                props.handleChangeCatalogType(e)
            } 

        
    } */




    //end erpCode

    return (
        <div className="row">
    
            <div className="col-sm-12 col-md-6">
                <InputText
                    id="status"
                    name="status"
                    label={I18n.get("lblconStatus")}
                    maxLength="20"
                    value={props.Data.sectionData.status}
                    onChange={props.handleChange}
                    isEditable={allowEdit}
                />
            </div>
            <div className="col-sm-12 col-md-6">
                <InputText
                    id="ERPCode"
                    name="ERPCode"
                    label={I18n.get("lblconERPCode")}
                    maxLength="20"
                    value={props.Data.sectionData.erpCode}
                    onChange={props.handleChange}
                    isEditable={allowEdit}
                />
            </div>
            <div className="col-sm-12">
                <InputText
                    id="name"
                    name="name"
                    label={I18n.get("lblconname")}
                    maxLength="20"
                    value={props.Data.sectionData.name}
                    onChange={props.handleChange}
                    isEditable={allowEdit}
                />
            </div>
           {/*  <div className="col-sm-12 col-md-6">
                <SectionEditable
                    label={I18n.get("lblMinimumOrderConfig")}
                    labelEdit={I18n.get("lblMinimumOrderConfig")}
                    Action={"UPDATE"}
                    UpdateAction={UpdateActionButtonMinimumConfig}
                    CancelAction={CancelMinimumConfig}
                    SaveAction={ChangeMinimumConfig}
                    Collapse={false}
                    lblbtnSave={I18n.get("lblSave")}
                    lblbtnCancel={I18n.get("lblCancel")}
                >
                    <div className="row">
                    <div className="col-md-6">
                        <InputNumber
                            id="orderMinAmount"
                            name="orderMinAmount"
                            label={I18n.get("lblorderMinAmount")}
                            maxLength="20"
                            value={
                                props.Data.sectionDataMinimumOrder
                                    .orderMinAmount
                            }
                            onChange={props.handleChangeMinimumOrder}
                            isEditable={updateAssignMinimumConfig}
                        />
                    </div>  
                       
                        <div className="col-md-6">
                         <RadioButton
                                Items={props.DataNetoBruto}
                                name="radioNetoBruto"
                                handleChange={handleChangeNetoBruto}
                                Title={I18n.get("lblSetMinimumOrder")}
                                isEditable={UpdateOrderCatalog}
                            />
                        </div>
                            </div>
                </SectionEditable>
                 <div className="col-md-12">
                <SectionEditable
                    label={I18n.get("lblConfigcodeNetoBrtuto")}
                    labelEdit={I18n.get("lblAssignErpCode")}
                    Action={"UPDATE"}
                    UpdateAction={UpdateActionButtonErpCode}
                    CancelAction={CancelAssignERPCode}
                    SaveAction={ChangeAssignERPCode}
                    Collapse={false}
                    lblbtnSave={I18n.get("lblSave")}
                    lblbtnCancel={I18n.get("lblCancel")}
                >
                    <div className="row">
                        <Modal
                            id="modalValidateERP"
                            title={I18n.get("lblValidateERP")}
                            lblbtnCancel={I18n.get("lblCancel")}
                            lblbtnSave={I18n.get("lblAccept")}
                            handleCancel={(e) => {
                                CancelAssignERPCode(e)
                                setUpdateAssignErpCode(true)
                                setStatusValidationERPCode("invalid")
                                setModalValidationERPCode({
                                    name: "",
                                    businessName: "",
                                    consumerIdERP: "",
                                    error: "",
                                })
                            }}
                            handleSave={() => {
                                setStatusValidationERPCode("valid")
                                setUpdateAssignErpCode(true)
                                setModalValidationERPCode({
                                    name: "",
                                    businessName: "",
                                    consumerIdERP: "",
                                    error: "",
                                })
                            }}
                            noAllowSave={noAllowSave}
                        >
                            {modalContent === "" ? (
                                <img
                                    src={loadingLine}
                                    alt="loading line gif"
                                    style={{
                                        display: "flex",
                                        margin: "0 auto",
                                    }}
                                />
                            ) : (
                                modalContent
                            )}
                        </Modal>
                        {updateAssignErpCode ? (
                            <Fragment>
                                <label
                                    htmlFor="erpCode"
                                    className="label-control"
                                >
                                    {I18n.get("lblSetERPCpde")}
                                </label>
                                <div className="input-group">
                                    <input
                                        id="erpCode"
                                        name="erpCode"
                                        type="text"
                                        className="form-control"
                                        value={props.Data.sectionData.erpCode}
                                        onChange={props.handleChange}
                                    />
                                    <span className="input-group-append">
                                        <button
                                            type="button"
                                            className="mb-2 mr-2 btn-transition btn btn-outline-primary bounds-button"
                                            onClick={() => {
                                                if (
                                                    props.Data.sectionData
                                                        .erpCode !==
                                                        undefined &&
                                                    props.Data.sectionData
                                                        .erpCode !== null &&
                                                    props.Data.sectionData
                                                        .erpCode !== ""
                                                ) {
                                                    window
                                                        .$("#modalValidateERP")
                                                        .modal("toggle")
                                                    handleValidationERPCode(
                                                        props.Data.sectionData
                                                            .erpCode
                                                    )
                                                } else {
                                                    XSalesConsumer.fireMessage(
                                                        I18n.get(
                                                            "lblAssignErpCode"
                                                        ),
                                                        I18n.get(
                                                            "msgFillErpCode"
                                                        )
                                                    )
                                                    return
                                                }
                                            }}
                                        >
                                            {I18n.get("lblValidate")}
                                        </button>
                                    </span>
                                </div>
                            </Fragment>
                        ) : (
                            <div className="col-sm-12">
                                <InputText
                                    id="erpCode"
                                    name="erpCode"
                                    label={I18n.get("lblSetERPCpde")}
                                    maxLength="20"
                                    value={props.Data.sectionData.erpCode}
                                    onChange={props.handleChange}
                                    isEditable={updateAssignErpCode}
                                    onBlur={handleValidationERPCode}
                                />
                            </div>
                        )}
                    </div>
                </SectionEditable>
                 </div> 
            </div> */}

            {/* <div className="col-sm-12 col-md-6">
                <SectionEditable
                    label={I18n.get("lblConfigCatOrd")}
                    labelEdit={I18n.get("lblConfigCatOrd")}
                    Action={"UPDATE"}
                    UpdateAction={UpdateActionButtonOrderCatalog}
                    CancelAction={CancelAssignOrderCatalog}
                    SaveAction={ChangeAssignOrderCatalog}
                    Collapse={false}
                    lblbtnSave={I18n.get("lblSave")}
                    lblbtnCancel={I18n.get("lblCancel")}
                >
                    <div className="row">
                      
                        <div className="col-md-6">
                        <CurrencyConfigConsumer
                            UpdateAction={UpdateOrderCatalog}
                            CancelActionEdit = {actionCancelEdit}
                            Data ={props.Data.sectionData}
                        />
                        </div> 
                        <div className="col-md-6">
                            <RadioButton
                                Items={props.DataCatalog}
                                name="radioCatalogType"
                                handleChange={handleChangeCatalogType}
                                Title={I18n.get("lblsettypecatalog")}
                                isEditable={UpdateOrderCatalog}
                            />
                        </div>
                    </div>
                  
                
                </SectionEditable>
               
            </div> */}
            
            <div className="col-sm-12">
                <SectionEditable
                    label={I18n.get("lblBlockedForSale")}
                    labelEdit={I18n.get("lblBlockedForSale")}
                    Action={"UPDATE"}
                    UpdateAction={UpdateActionButtonBlockedForSale}
                    CancelAction={CancelBlockedForSale}
                    SaveAction={ChangeBlockedForSale}
                    Collapse={false}             
                    lblbtnSave={I18n.get("lblSave")}
                    lblbtnCancel={I18n.get("lblCancel")}
                >
                    <div className="col-sm-12 col-md-6">

                    <SelectCombo
                        id="statusSel"
                        name="statusSel"
                        label={I18n.get("lblconStatusSales")}
                        options={statusBlockedForSalesSupplierConsumer}
                        optionSelected={props.Data.sectionData.blockedForSale}
                        defaultValue={""}
                        value={props.Data.sectionData.blockedForSale}
                        isEditable={updateAssignBlockedForSale}
                        onChange={props.handleChangeBlockedForSale}/>

                    </div>
                </SectionEditable>
                {/* <div><pre>{JSON.stringify(props.Data.sectionData.blockedForSale, null, 2) }</pre></div> */}
            </div>
        </div>
    )
}
