import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { TabulatorTable } from "xsales-components"
import { I18n } from "@aws-amplify/core"
//import { ExchangeRateCard } from "./ExchangeRateCard"
import { API } from "aws-amplify"
//import axios from "axios"
import moment from "moment"
import { XSalesConsumer } from "../../../Utils/Utils"

import flagJson from "../../../Utils/flags.json"
// const apiWise = `https://wise.com/public-resources/assets/flags/rectangle/{currency}.png`

export function ExchangeRate() {

    const [lblLoadingData, setLblLoadingData] = useState("")
    const [dataTabulator, setDataTabulator] = useState({
        data: [],
        Columns: [],
        Filter: [],
        dataFilters: [],
        RowClick: handleRowClickTabulator,
    })

    async function getColumsTabulator(){
        debugger
        let Columns=[
            {
                title: I18n.get("lblfrom"),
                field: "currencyCodeFrom",
                widthGrow: 1,
                formatter: "html",
                maxWidth: 150,
            },
            {
                title: I18n.get("lblto"),
                field: "currencyCodeTo",
                widthGrow: 1,
                formatter: "html",
                maxWidth: 150,
            },
            {
                title: I18n.get("lblrate"),
                field: "rate",
                formatter: "money",
                align: "right",
                widthGrow: 0.5,
                maxWidth: 200,
            },
            {
                title: I18n.get("lbleffectiveDate"),
                field: "effectiveDate",
                widthGrow: 1,
                 formatter:  (cell, formatterParams, onRendered) => (cell.getValue() ? XSalesConsumer.formatShortDatetime(cell.getValue()): cell.getValue())  
            },
        ]
        const obj = dataTabulator;
        obj.Columns= Columns;
        setLblLoadingData(I18n.get("lblLoadingData"));
    }

    function handleRowClickTabulator() {}

    async function getLanguage() {
        try {
            let lang = localStorage.getItem("languageApp");
            if (!lang) {
              const [lang1] = navigator.language.split('-');
              lang = lang1.toUpperCase();
            }
            I18n.setLanguage(lang.toUpperCase());
          } catch (error) {
            I18n.setLanguage('EN');
            console.log(error.message);
          }
    }

    useEffect(() => {
        getLanguage() 
        getColumsTabulator()
        getData()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        try {
            const dataApi = await API.get(
                "backendapi",
                "/exchangeRates/currencyFrom/USD"
            )
            // const dataApi = await axios.get("http://localhost:3001/exchangeRates/currencyFrom/USD")
            const obj = dataTabulator
            obj.data = dataApi.map((x) => {
                const currencyFromImg =
                    x.currencyCodeFrom.toLowerCase() === "ves"
                        ? "vef"
                        : x.currencyCodeFrom.toLowerCase()
                const currencyToImg =
                    x.currencyCodeTo.toLowerCase() === "ves"
                        ? "vef"
                        : x.currencyCodeTo.toLowerCase()
                return {
                    currencyCodeFrom: `<div style="display:flex;justify-content:space-around;align-items:center;width:70px;"><img src="${flagJson[currencyFromImg]}"/> ${x.currencyCodeFrom}</div>`,
                    currencyCodeTo: `<div style="display:flex;justify-content:space-around;align-items:center;width:70px;"><img src="${flagJson[currencyToImg]}"/> ${x.currencyCodeTo}</div>`,
                    effectiveDate: `${moment(x.effectiveDate).format(
                        "L"
                    )} ${moment(x.effectiveDate).format("hh:mm A")}`,
                    rate: x.rate,
                }
            })
            setDataTabulator({ ...obj })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Container>
            <TabulatorTable
                Title={{
                    label: I18n.get("lbllatestrates"),
                }}
                Data={dataTabulator.data}
                Columns={dataTabulator.Columns}
                Filter={dataTabulator.Filter}
                RowClick={dataTabulator.RowClick}
                placeholder={lblLoadingData}
                Icons={false}
                Search={true}
                Pagination={false}
                tooltips={false}
                Layout="fitColumns"
                lblSearch= {I18n.get("lblSearch")}
                labelEmptyData={I18n.get("lblnodataavailabletabulator")}
            />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    width: 100%;
    height: 100%;
`
