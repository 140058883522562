import React, { useState } from "react"
import {
    InputEmail,
    InputPass,
    Button,
    //ToRight,
    //ToLeft,
    CheckStatus,
    //Card,
} from "xsales-components"
import Loader from "../common/loader"
import PreferenceDefault from "../../configConsumer/preferenceDefault.json"
import ImageCDN from "../../configConsumer/imagesDefaultCDN.json"
import { I18n } from "@aws-amplify/core"
import { Auth } from "aws-amplify"
import ConfigAuth from "../../configConsumer/authenticationRules.json"
import { XSalesConsumer } from "./../../Utils/Utils"

class objUser {
    constructor() {
        this.email = ""
        this.password = ""
    }
}

function Forgot(props) {
    const [showLoader, setShowLoader] = useState(false)
    const sendData = async () => {
        if (
            sectionData.email.trim() !== "" &&
            sectionData.password.trim() === sectionData.confirmPassword.trim()
        ) {
            setShowLoader(true)
            await Auth.forgotPassword(sectionData.email.trim().toLowerCase())
                .then(async (data) => {
                    if (
                        data.CodeDeliveryDetails !== null &&
                        data.CodeDeliveryDetails !== undefined
                    ) {
                        XSalesConsumer.fireMessage(
                            "lblForgot",
                            "msgEmailSendAgain"
                        )
                        let user = new objUser()
                        user.email = sectionData.email
                        user.password = sectionData.password
                        XSalesConsumer.setCurrentUser(user)
                            .then((data) => {
                                window.location.href = `/ValidationForgot/${sectionData.email}`
                            })
                            .catch((err) => {
                                XSalesConsumer.fireMessage(
                                    "lblForgot",
                                    "msgPleaseAgain"
                                )
                            })
                    }
                })
                .catch(async (err) => {
                    if (err.code === "UserNotFoundException") {
                        XSalesConsumer.fireMessage(
                            "lblLogin",
                            "msgLoginUserNotExist"
                        )
                    }
                })
        } else {
            XSalesConsumer.fireMessage("lblLogin", "msgPassNotBeEquals")
        }
        setShowLoader(false)
    }

    const sendBackSignIn = async () => {
        props.history.push("/SignIn")
    }
    //Load configuration in /src/configConsumer/authenticationRules.json
    const [requiredMinimunLength] = useState(ConfigAuth.requiredMinimunLength)
    const [requiredNumber] = useState(ConfigAuth.requiredNumber)
    const [requiredSpecialCharacter] = useState(
        ConfigAuth.requiredSpecialCharacter
    )
    const [requiredUpperCase] = useState(ConfigAuth.requiredUpperCase)
    const [requiredLowerCase] = useState(ConfigAuth.requiredLowerCase)
    const [requiredEqualPass] = useState(ConfigAuth.requiredEqualPass)

    //Variables by User KeyPress value for Password and confirm Password
    const [containNumber, setContainNumber] = useState(false)
    const [containSpecialCharacter, setContainSpecialCharacter] = useState(
        false
    )
    const [containUpperCase, setContainUpperCase] = useState(false)
    const [containLowerCase, setContainLowerCase] = useState(false)
    const [containMinimumLength, setContainMinimumLength] = useState(false)
    const [containDifferencePass, setContainDifferencePass] = useState(false)

    const [sectionData, setSectionData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
    })

    const handleChange = (e) => {
         const { name, value } = e.target
        if (name !== undefined && name !== null && name !== "") {
            setSectionData((prevState) => ({
                ...prevState,
                [name]: value
            }))
        }

    }

    const handleChangePassword = (e) => {
        handleChange(e)
        handleOnBlur(e)
    }

    const handleOnBlur = (e) => {
        const { value } = e.target
        if (requiredNumber) {
            let rn = new RegExp("(?=.*?[0-9])")
            setContainNumber(rn.test(value))
        }

        if (requiredUpperCase) {
            let ruc = new RegExp("(?=.*?[ÑA-Z])")
            setContainUpperCase(isNaN(value) && ruc.test(value))
        }

        if (requiredLowerCase) {
            let rlc = new RegExp("(?=.*?[ña-z])")
            setContainLowerCase(isNaN(value) && rlc.test(value))
        }

        if (requiredSpecialCharacter) {
            let rsc = new RegExp(/\W|_/g)
            setContainSpecialCharacter(isNaN(value) && rsc.test(value))
        }

        if (requiredEqualPass) {
            setContainDifferencePass(() => {
                return (
                    sectionData.password.trim() ===
                    sectionData.confirmPassword.trim()
                )
            })
        }

        if (requiredMinimunLength > 0) {
            let rml = value.length >= requiredMinimunLength
            setContainMinimumLength(rml)
        }
    }

    const handleValidationBeforeSend = () => {
        if (
            (requiredLowerCase && !containLowerCase) ||
            (requiredUpperCase && !containUpperCase) ||
            (requiredMinimunLength > 0 && !containMinimumLength) ||
            (requiredSpecialCharacter && !containSpecialCharacter) ||
            (requiredNumber && !containNumber) ||
            (requiredEqualPass && !containDifferencePass)
        ) {
            return
        }
        sendData()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        handleValidationBeforeSend()
    }

    return (
        // <Container
        //     linkXSalesConsumer={PreferenceDefault.XSalesConsumerWEB}
        //     XSalesConsumer={ImageCDN.XSalesConsumerWebLogo}
        // >
        //     <Card label={I18n.get("lblForgotIndication")}>
        //         <div className='col-sm-12' style={{
        //             fontStyle: 'cursive',
        //             fontSize: '0.75rem',
        //             color: '#2125297d',
        //             backgroundColor: 'rgb(239, 239, 239, 1)',
        //             borderRadius: '5px'
        //         }}>
        //             {I18n.get('lblRestorePass')}
        //         </div>
        //         <InputEmail
        //             id="email"
        //             name="email"
        //             maxLength="30"
        //             label={I18n.get("lblEmail")}
        //             value={sectionData.email}
        //             onChange={handleChange}
        //         />
        //         <InputPass
        //             id="password"
        //             name="password"
        //             maxLength="30"
        //             label={I18n.get("lblPassword")}
        //             value={sectionData.password}
        //             onChange={handleChangePassword}
        //         />
        //         <InputPass
        //             id="confirmPassword"
        //             name="confirmPassword"
        //             maxLength="30"
        //             label={I18n.get("lblconfirmPassword")}
        //             value={sectionData.confirmPassword}
        //             onChange={handleChangePassword}
        //         />
        //         <div>
        //             {requiredMinimunLength && (
        //                 <div className="col-sm-12">
        //                     <CheckStatus
        //                         condition={containMinimumLength}
        //                         label={I18n.get("lblCheckMinimum")}
        //                     />
        //                 </div>
        //             )}

        //             {requiredSpecialCharacter && (
        //                 <div className="col-sm-12">
        //                     <CheckStatus
        //                         condition={containSpecialCharacter}
        //                         label={I18n.get("lblCheckSpecial")}
        //                     />
        //                 </div>
        //             )}

        //             {requiredNumber && (
        //                 <div className="col-sm-12">
        //                     <CheckStatus
        //                         condition={containNumber}
        //                         label={I18n.get("lblCheckNumber")}
        //                     />
        //                 </div>
        //             )}

        //             {requiredLowerCase && (
        //                 <div className="col-sm-12">
        //                     <CheckStatus
        //                         condition={containLowerCase}
        //                         label={I18n.get("lblCheckLowerCase")}
        //                     />
        //                 </div>
        //             )}

        //             {requiredUpperCase && (
        //                 <div className="col-sm-12">
        //                     <CheckStatus
        //                         condition={containUpperCase}
        //                         label={I18n.get("lblCheckUpperCase")}
        //                     />
        //                 </div>
        //             )}

        //             {requiredEqualPass && (
        //                 <div className="col-sm-12">
        //                     <CheckStatus
        //                         condition={containDifferencePass}
        //                         label={I18n.get("lblCheckPassDifUser")}
        //                     />
        //                 </div>
        //             )}
        //         </div>
        //         <div className="col-sm-12">
        //             <ToRight>
        //                 <ToLeft>
        //                     <Button
        //                         id="back"
        //                         name="back"
        //                         label={I18n.get("lblback")}
        //                         className="btn btn-block btn-outline-secondary bounds-button-cancel"
        //                         onClick={() => sendBackSignIn()}
        //                     />
        //                 </ToLeft>
        //                 <ToLeft>
        //                     <Button
        //                         id="send"
        //                         name="send"
        //                         label={I18n.get("lblSignUp")}
        //                         onClick={handleValidationBeforeSend}
        //                     />
        //                 </ToLeft>
        //             </ToRight>
        //         </div>
        //         {showLoader && (
        //             <div style={{ textAlign: "center" }}>
        //                 <img
        //                     src={loading}
        //                     style={{
        //                         height: "60px",
        //                         width: "60px",
        //                     }}
        //                     alt="Loading gif"
        //                 />
        //             </div>
        //         )}
        //     </Card>
        // </Container>
        <>
            <section className="login__container">
                <div className="login__box">
                    <a
                        href={PreferenceDefault.XSalesConsumerWEB}
                        target="_blank" rel="noopener noreferrer"
                    >
                        <img
                            src={ImageCDN.XSalesConsumerWebIsotipo}
                            alt="Logo Consumer"
                        /><br />
                        <img
                            src={ImageCDN.XSalesConsumerWebLogo}
                            alt="Logo Consumer"
                        />
                    </a>
                    <div className="container-fluid">
                        <div className="row mt-3">
                            <div className="col-sm-12 col-md-12 text-center">
                                <h4 className="login_sigInIndication">
                                    {I18n.get("lblForgotIndication")}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-sm-12"
                        style={{
                            fontStyle: "cursive",
                            fontSize: "0.80rem",
                            fontWeight: 300,
                            // color: '#2125297d',
                            width: "70%",
                            //    backgroundColor: 'rgb(239, 239, 239, 1)',
                            textAlign: "justify",
                            borderRadius: "5px",
                        }}
                    >
                        {I18n.get("lblRestorePass")}
                    </div>
                    <form className="login__form" onSubmit={handleSubmit}>
                        <InputEmail
                            id="email"
                            name="email"
                            maxLength="30"
                            label={I18n.get("lblEmail")}
                            value={sectionData.email}
                            onChange={handleChange}
                            onBlur={handleOnBlur}
                            required={true}
                        />
                        <InputPass
                            id="password"
                            name="password"
                            maxLength="30"
                            label={I18n.get("lblPassword")}
                            value={sectionData.password}
                            onChange={handleChangePassword}
                            onBlur={handleOnBlur}
                            required={true}
                            Icon={true}
                        />
                        <InputPass
                            id="confirmPassword"
                            name="confirmPassword"
                            maxLength="30"
                            label={I18n.get("lblconfirmPassword")}
                            value={sectionData.confirmPassword}
                            onChange={handleChangePassword}
                            onBlur={handleOnBlur}
                            onKeyPress={handleOnBlur}
                            required={true}
                            Icon={true}
                        />
                        <div>
                            {requiredMinimunLength && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containMinimumLength}
                                        label={I18n.get("lblCheckMinimum")}
                                    />
                                </div>
                            )}

                            {requiredSpecialCharacter && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containSpecialCharacter}
                                        label={I18n.get("lblCheckSpecial")}
                                    />
                                </div>
                            )}

                            {requiredNumber && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containNumber}
                                        label={I18n.get("lblCheckNumber")}
                                    />
                                </div>
                            )}

                            {requiredLowerCase && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containLowerCase}
                                        label={I18n.get("lblCheckLowerCase")}
                                    />
                                </div>
                            )}

                            {requiredUpperCase && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containUpperCase}
                                        label={I18n.get("lblCheckUpperCase")}
                                    />
                                </div>
                            )}

                            {requiredEqualPass && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containDifferencePass}
                                        label={I18n.get("lblCheckPassDifUser")}
                                    />
                                </div>
                            )}
                        </div>
                        {/* <button className="login__btn btn btn-primary btn-block">{I18n.get("lblSend")}</button> */}
                        <div className="login__buttons">
                            <Button
                                id="back"
                                name="back"
                                label={I18n.get("lblback")}
                                className="btn btn-outline-secondary bounds-button-cancel"
                                onClick={() => sendBackSignIn()}
                            />
                            <Button
                                id="send"
                                name="send"
                                type="submit"
                                label={I18n.get("lblSignUp")}
                                className="btn btn-outline-primary bounds-button"
                            />
                        </div>
                    </form>
                    {showLoader && <Loader />}
                </div>
            </section>
        </>
    )
}

export default Forgot
