import React, { useEffect, useState } from "react"
import {
    TabulatorTable,
    VerticalBarChart,
    ProgressBar,
    Tags,
    Total,
    SelectCombo,
    LoaderWindow,
} from "xsales-components"
import { I18n } from "@aws-amplify/core"
import ReactDOMServer from "react-dom/server"
import { XSalesData } from "../../../DataQuery/XSalesData"
import moment from "moment"
import { XSalesConsumer } from "../../../Utils/Utils"
import { useHistory } from "react-router-dom"
//import NoDataAvailable from "../StatementComponents/NoDataAvailable"

let chartBarRef = null
const minimumSupplierLoading = 6

const enumOptions = {
    transactions: "1",
    estimatedBill: "2",
}

const getTagHtml = (value) => {
    const val = value ? value.toUpperCase() : value
    switch (val) {
        case "PREMIUM":
            return ReactDOMServer.renderToStaticMarkup(
                <Tags label="PREMIUM" color="#EAD45B" size="sm" />
            )
        case "BASIC":
            return ReactDOMServer.renderToStaticMarkup(
                <Tags label="BASIC" color="secondary" size="sm" />
            )
        default:
            return value
    }
}

const today = moment()
const lastDayOfMonth = moment(today).endOf("month").format("DD")

const daysRemaining = (today) => {
    const endOfDate = moment().endOf("month")
    return endOfDate.diff(today, "days")
}

const percentDaysRemaining = () => {
    return XSalesConsumer.getPercent(
        Number(today.format("DD")),
        Number(lastDayOfMonth)
    )
}

const checkboxIcon = function (cell, formatterPatterns) {
    const { id } = cell._cell.column.definition
    return `<input type='checkbox' name='checkbox' id='checkbox-${id}'  className='fileCompare_Chkbx checkbox-${id}' checked= true style='margin-left:15px;' />`
}

//let totalTransactionsGlobal = 0

const currencySymbol = "$"

const CurrentPeriod = (props) => {
    const [dataPeriod, setDataPeriod] = useState([])
    const [barColor, setBarColor] = useState([
        { color: "#3766FB", apply: false },
        { color: "#7DD2D6", apply: false },
        { color: "#2B395A", apply: false },
        { color: "#631BA7", apply: false },
        { color: "#9A67CB", apply: false },
        { color: "#F5A81E", apply: false },
    ])
    const [totalTransactions, setTotalTransactions] = useState(0)
    const [dataBarChart, setDataBarChart] = useState({ labels: [], data: [] })
    const [selectValuePeriod, setSelectValuePeriod] = useState(
        enumOptions.transactions
    )
    const [dataApi, setDataApi] = useState({ data: [] })
    const [loader, setLoader] = useState(true)
    const [gRpt, setGrpt] = useState("")
    const history = useHistory()
    const [columnsPeriod, setColumnsPeriod] = useState()

    const periodComboOptions = [
        { label: I18n.get("lbltransactions"), value: enumOptions.transactions },
        {
            label: I18n.get("lblestimatedbilled"),
            value: enumOptions.estimatedBill,
        },
    ]
    async function getColumsTabulator(){
        let columns= [
            {
                title: "", //"<input type='checkbox' id='files_select_all-period' />",
                formatter: checkboxIcon,
                width: 70,
                id: "period",
                headerSort: false,
                cellClick: handleClickCheckbox,
                download: false
            },
            {
                title: I18n.get("lblnameColumn"),
                field: "name",
                widthGrow: 1,
            },
            {
                title: I18n.get("lblplan"),
                field: "planHtml",
                formatter: "html",
                widthGrow: 1,
                download: false,
            },
            {
                title: I18n.get("lblplan"),
                field: "plan",
                widthGrow: 1,
                visible: false,
                download: true,
            },
            {
                title: I18n.get("lbltransactions"),
                field: "transactions",
                formatter: "money",
                align: "right",
                widthGrow: 1,
                formatterParams: {
                    symbol: "",
                    symbolAfter: false,
                    precision: 2,
                },
            },
            {
                title: I18n.get("lbloptions"),
                field: "options",
                widthGrow: 1,
                formatter: "html",
                align: "center",
                download: false,
                cellClick: function (e, cell) {
                    const { month, year, supplierId } = cell.getData()
                    history.push(
                        `/Admin/DetailStatement1/${month}/${year}/${supplierId}`
                    )
                },
            },
        ]
        setColumnsPeriod(columns);
    }

    async function getLanguage() {
        try {
            let lang = localStorage.getItem("languageApp");
            if (!lang) {
              const [lang1] = navigator.language.split('-');
              lang = lang1.toUpperCase();
            }
            I18n.setLanguage(lang.toUpperCase());
          } catch (error) {
            I18n.setLanguage('EN');
            console.log(error.message);
          }
    }

    useEffect(() => {
        getData()
        getLanguage()
        getColumsTabulator()
            .then(() => {
                setLoader(false)
                //getDataTransactions(selectValuePeriod)
            })
            .catch((err) => {
                setLoader(false)
                console.error(err)
            })
        return () => {
            if (chartBarRef) {
                chartBarRef.destroy()
            }
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (dataPeriod.length===0){
            getDataTransactions(selectValuePeriod)
        }
    });

    const handleClickCheckbox = (e, cell) => {
        try{
            const data = cell.getData()
            const element = e.target
            if (element.tagName !== "INPUT") return
    
            if (!element.checked) {
                //  cell.getRow().deselect();
                //  cell.getData().IsSelected = false;
                if (chartBarRef) {
                    const rowSelected = chartBarRef.data.datasets.filter(
                        (x) => x.id === data.id
                    )
                    setColorBarApply(rowSelected[0].backgroundColor, false)
                    const rows = chartBarRef.data.datasets.filter(
                        (x) => x.id !== data.id
                    )
                    // if (index > -1) chartBarRef.data.datasets[0].data.splice(index, 1);
                    chartBarRef.data.datasets = rows
                    chartBarRef.update()
                }
            } else {
                // cell.getRow().select();
                //  cell.getData().IsSelected = true;
                if (chartBarRef) {
                    if (chartBarRef.data.datasets.length >= 6) {
                        element.checked = !element.checked
                        return
                    }
                    const valuePercent = dataBarChart.data.filter(
                        (x) => x.id === data.id
                    )
                    const newDataset = {
                        id: data.id,
                        label: data.name,
                        month: data.month,
                        year: data.year,
                        supplierId: data.supplierId,
                        data: [valuePercent[0].percent],
                        backgroundColor: getColorBar(),
                        borderColor: [],
                        borderWidth: 1,
                    }
                    let exits= chartBarRef.data.datasets.find(e => e.id === data.id)
                    if (!exits){
                        chartBarRef.data.datasets = [
                            ...chartBarRef.data.datasets,
                            newDataset,
                        ]
                    }
                    chartBarRef.update()
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleClickTable = (e) => {}

    const getData = async () => {
        const month = new Date().getMonth() + 1
        const year = new Date().getFullYear()
        let dataList = await XSalesData.Statement.getList(month, year)
        dataList = dataList.filter((x) => x.contractDetail.fees != null)
        const newDataApi = dataApi
        dataApi.data = [...dataList]
        setDataApi(newDataApi)
    }

    const checkFirstSixSupplier = () => {
        // const element = document.getElementById('files_select_all-period');
        // element.checked = true;
        const nodeList = document.querySelectorAll(".checkbox-period")
        const nodeArray = Array.from(nodeList)
        nodeArray
            .slice(0, minimumSupplierLoading)
            .forEach((x) => (x.checked = !x.checked))
    }

    const getDataTransactions = async (rpt) => {
        // const month = new Date().getMonth() + 1
        // const year = new Date().getFullYear()
        // const data = await getData(month, year);
        setGrpt(rpt)
        const { data } = dataApi
        let counter = 0
        const labels = []
        const dataTable = data.map((data) => {
            if (rpt === enumOptions.transactions) {
                counter +=
                    data.count.deposit + data.count.order + data.count.payment
            } else {
                counter +=
                    data.amount.depositUSD +
                    data.amount.orderUSD +
                    data.amount.paymentUSD
            }
            return {
                id: data.id,
                name: data.supplier.businessName,
                planHtml: getTagHtml(data.contractDetail.plan),
                plan: data.contractDetail.plan,
                month: data.month,
                year: data.year,
                supplierId: data.supplierId,
                transactions:
                    rpt === enumOptions.transactions
                        ? data.count.deposit +
                          data.count.order +
                          data.count.payment
                        : data.amount.depositUSD +
                          data.amount.orderUSD +
                          data.amount.paymentUSD,
                options: `<img className="btn__info__current__period" src="${"https://cdn.xsalesconsumer.com/console/info-24px.svg"}" />`,
            }
        })
        setDataPeriod(dataTable)
        //totalTransactionsGlobal = 0
        //totalTransactionsGlobal = counter
        setTotalTransactions(counter)

        const dataBarChart = dataTable.map((data) => {
            labels.push(data.name)
            return {
                id: data.id,
                name: data.name,
                month: data.month,
                year: data.year,
                supplierId: data.supplierId,
                percent: XSalesConsumer.getPercent(data.transactions, counter),
            }
        })
        updateChartBar(labels, dataBarChart)
        setTimeout(() => {
            handleClickCheckBoxAll()
            checkFirstSixSupplier()
        }, 0)
        setTableSymbol(rpt)
    }

    const setColorBarApply = (color, apply, all = false) => {
        const objArray = barColor
        for (let index = 0; index < objArray.length; index++) {
            const element = objArray[index]
            if (all) {
                element.apply = false
            } else {
                if (element.color === color) {
                    element.apply = apply
                    break
                }
            }
        }
        setBarColor(objArray)
    }

    const getColorBar = () => {
        const colors = barColor
        let condition = true
        let color = "#631BA7"
        let counter = 0
        while (condition && counter < 100) {
            const random = Math.floor(Math.random() * colors.length)
            if (colors[random].apply === false) {
                colors[random].apply = !colors[random].apply
                color = colors[random].color
                condition = false
            }

            counter++
        }
        setBarColor(colors)
        return color
    }

    const updateChartBar = async (labels, data) => {
        try{
            const dataBarChartObj = dataBarChart
            dataBarChartObj.labels = labels
            dataBarChartObj.data = data
            setDataBarChart(dataBarChartObj)
            if (chartBarRef) {
                // chartBarRef.data.labels = labels;
                const datasetList = []
                data.slice(0, minimumSupplierLoading).forEach(
                    (dataChart, index) => {
                        const datasetObj = {
                            id: dataChart.id,
                            supplierId: dataChart.supplierId,
                            month: dataChart.month,
                            year: dataChart.year,
                            label: dataChart.name,
                            data: [dataChart.percent],
                            backgroundColor: getColorBar(),
                            borderColor: [],
                            borderWidth: 0,
                        }
    
                        datasetList.push(datasetObj)
                    }
                )
                if (datasetList){
                    if (datasetList.length>0 ){
                        chartBarRef.data.datasets = datasetList
                        chartBarRef.update()
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }

    }

    const handleClickCheckBoxAll = () => {
        // const element = document.getElementById('files_select_all-period');
        // element.addEventListener('click', (e) => {
        //     const nodeList = document.querySelectorAll(".checkbox-period")
        //     const nodeArray = Array.from(nodeList);
        //     nodeArray.slice(0, minimumSupplierLoading).forEach(x => x.checked = !x.checked)
        // })
    }

    const handleOnChangeSelect = (e) => {
        setColorBarApply("", false, true)
        setSelectValuePeriod(e.value)
        getDataTransactions(e.value)
    }

    const handleClickBar = function (e, i) {
        const element = this.getElementAtEvent(e)
        if (element[0] && chartBarRef) {
            const { supplierId, year, month } = chartBarRef.data.datasets[
                element[0]._datasetIndex
            ]
            history.push(
                `/Admin/DetailStatement1/${month}/${year}/${supplierId}`
            )
        }
    }

    const setTableSymbol = (rpt) => {
        const objColumns = columnsPeriod
        if (objColumns!==undefined){
            if (rpt === enumOptions.transactions) {
                objColumns[4].formatterParams.symbol = ""
            } else {
                objColumns[4].formatterParams.symbol = currencySymbol
            }
            setColumnsPeriod(objColumns)
        }
    }

    return (
        <>
            {loader ? (
                <LoaderWindow />
            ) : (
                // dataApi.data.length == 0 ?
                //     <NoDataAvailable />
                //     :
                <div className="statement__section">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="title__section_statement">
                                <img
                                    src={
                                        "https://cdn.xsalesconsumer.com/console/event-24px.svg"
                                    }
                                    alt=""
                                    loading="lazy"
                                />
                                <h4>{I18n.get("lblmonth")}</h4>
                            </div>

                            <div className="col-sm-4">
                                <SelectCombo
                                    id="currentPeriod"
                                    name="currentPeriod"
                                    label={""}
                                    options={periodComboOptions}
                                    optionSelected={selectValuePeriod}
                                    onChange={handleOnChangeSelect}
                                    isEditable={true}
                                    value={selectValuePeriod}
                                />
                            </div>
                            <TabulatorTable
                                Title={""}
                                Data={dataPeriod}
                                Columns={columnsPeriod}
                                // Filter={[]}
                                RowClick={handleClickTable}
                                placeholder={I18n.get("lblLoadingData")}
                                downloadIcon={true}
                                moreIcon={false}
                                Search={true}
                                labelEmptyData={I18n.get(
                                    "lblnodataavailabletabulator"
                                )}
                                lblSearch= {I18n.get("lblSearch")}
                            />
                            <div
                                style={{
                                    marginLeft: "30rem",
                                    marginTop: "5rem",
                                }}
                            >
                                <Total
                                    label={I18n.get("lbltotalTransactions")}
                                    amount={totalTransactions}
                                    currencySymbol={
                                        gRpt === "2" ? currencySymbol : ""
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="progress__bar_statement">
                                <h5>{`${today.format(
                                    "DD"
                                )} ${XSalesConsumer.getMonth(
                                    today.month() + 1
                                )} ${today.year()} - ${lastDayOfMonth} ${XSalesConsumer.getMonth(
                                    today.month() + 1
                                )} ${today.year()}`}</h5>
                                <ProgressBar
                                    completePercent={percentDaysRemaining()}
                                    width="330px"
                                />
                                <span className="progress__bar__title__days">
                                    {I18n.get("lbldaysleft").replace(
                                        "{days}",
                                        daysRemaining(today)
                                    )}
                                </span>
                            </div>
                            <div className="col-sm-12 col-md-12"  style={{overflowX:'auto'}}>
                                <div className="statement__chart__bar__container">
                                    <VerticalBarChart
                                        id="VerticalBarChart"
                                        labels={[]}
                                        datasets={[]}
                                        // maxYValue={100}
                                        // minYValue={0}
                                        // stepSize={20}
                                        stacked={false}
                                        showLabelBar={true}
                                        showLegend={true}
                                        sho
                                        barPercentage={0.7}
                                        handleClick={handleClickBar}
                                        setRefChart={(chart) => (chartBarRef = chart)}
                                        // width="100"
                                        height="150"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CurrentPeriod