import React, { useState, useEffect } from "react"
import HeaderSignOut from "./Header_SignOut"
import { XSalesConsumer } from "../Utils/Utils"
import { Link } from "react-router-dom"
import { I18n } from "aws-amplify"
import { XSalesData } from "../DataQuery/XSalesData"

export default function Header(props) {
    const [supplier, setSupplier] = useState({
        name: "",
        businessName: "",
        user: "",
    })
    const [userRole, setUserRole] = useState("SUPPLIER")
    const [userName, setUserName] = useState("")

    async function getuserinfo() {
        const userInfo = await XSalesConsumer.getCurrentUserNew();
        let username= "";
        XSalesData.User.GetUserByUsername(userInfo.username)
        .then(async (data) => {
            username= data.name + " " + data.lastName;
            setUserName(username);
        })
        .catch((err) => {
            
        })
        
    }

    useEffect(() => {
        // XSalesConsumer.getCurrentUserFromCognito().then((d) => console.log(d))
        getuserinfo()
        XSalesConsumer.getCurrentUser()
            .then((data) => {
                // setSupplier((prevState) => {
                //     prevState["name"] =
                //         data.supplier !== undefined ? data.supplier.name : ""
                //     prevState["businessName"] =
                //         data.supplier !== undefined
                //             ? data.supplier.businessName
                //             : ""
                //     return prevState
                // })
                setSupplier({
                    name: data?.supplier?.name,
                    businessName: data?.supplier?.businessName,
                    user: data?.user,
                })
            })
            .catch((err) => {
                setSupplier({ name: "", businessName: "" })
            })
        let rol = "SUPPLIER"
        async function getRoleToApply() {
            rol = await XSalesConsumer.getCurrentUser()
                .then((data) => {
                    return data.type
                })
                .catch((err) => {
                    return "SUPPLIER"
                })
            setUserRole(rol)
        }
        getRoleToApply()
        return () => {}
    }, [])

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-widget="pushmenu"
                        href="/#"
                        role="button"
                    >
                        <i className="fas fa-bars" />
                    </a>
                </li>
            </ul>
            {
                // supplier.name !== "" && (
                //     <Fragment>
                //         <p style={{ paddingLeft: "1rem", margin: "auto 0px" }}>
                //             {supplier.name}
                //         </p>
                //         <p style={{ paddingLeft: "1rem", margin: "auto 0px" }}>
                //             [{supplier.businessName}]
                //         </p>
                //     </Fragment>
                // )
            }
            <div
                style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    width:"100%"
                }}
            >
                <Link to="/Main" className="logo__house__header brand-link">
                    {userRole === "admin" ? (
                        <img
                            className="header__icon__home-svg"
                            alt=""
                            src={
                                "https://cdn.xsalesconsumer.com/console/home-24px.svg"
                            }
                        />
                    ) : (
                        <img
                            className="header__icon__home-svg"
                            alt=""
                            src={
                                "https://cdn.xsalesconsumer.com/console/storefront-24px.svg"
                            }
                        />
                    )}
                </Link>
                    <div className="col-6 text-left">
                        <h4 className="supplier__name__header">{supplier?.name}</h4>
                    </div>
                    <div className="col-6 text-right pr-4">
                        <h4 className="supplier__name__header">{I18n.get("lblHello")}, {userName}</h4>
                    </div>
            </div>
            <form className="form-inline ml-3"></form>
            <ul className="navbar-nav ml-auto navbar__options">
                {/* Messages Dropdown Menu */}
                {/* <HeaderMessages /> */}
                {/* Notifications Dropdown Menu */}
                {/* <HeaderNotifications /> */}
                {/* <HeaderStatusConection /> */}
                {/* <li className="navbar__option--username">{supplier?.name}</li> */}
                <HeaderSignOut />
            </ul>
        </nav>
    )
}
