import React from 'react'
import { I18n } from "@aws-amplify/core"
import { XSalesConsumer } from '../../../Utils/Utils';
const DetailStatementHeader = ({businessName, startOfMonth,endOfMonth, fee, minTransaction,licensesType}) => {
    return (
        <>
            <h4 className="bussinesname__title">{businessName}</h4>
            <div className="row">
                <div className="col-sm-12 col-md-3">
                    <div className="detail_statement_header_text-wrapper">
                        <p className="detail__statement__header__info__text detail__header__info__bold">{I18n.get("lblaccountStatement")}:</p>
                        <p className="detail__statement__header__info__text">
                            {XSalesConsumer.formatShortDate(startOfMonth)} - {XSalesConsumer.formatShortDate(endOfMonth)}
                        </p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <div className="detail_statement_header_text-wrapper">
                        <p className="detail__statement__header__info__text detail__header__info__bold">{I18n.get("lbllicenseType")}:</p>
                        <p className="detail__statement__header__info__text">
                            {licensesType}
                        </p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <div className="detail_statement_header_text-wrapper">
                        <p className="detail__statement__header__info__text detail__header__info__bold">{I18n.get("lblfee")}:</p>
                        <p className="detail__statement__header__info__text">
                            {`$${fee}`}
                        </p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <div className="detail_statement_header_text-wrapper">
                        <p className="detail__statement__header__info__text detail__header__info__bold">{I18n.get("lblmintransactions")}:</p>
                        <p className="detail__statement__header__info__text">
                            {minTransaction}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailStatementHeader;