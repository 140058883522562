// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import firebaseConfig from "../configConsumer/firebase-config.json"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBf5b348DznY98tIFY-nw_43ES-UnIM0Ig",
//   authDomain: "xsales-consumer-consola.firebaseapp.com",
//   projectId: "xsales-consumer-consola",
//   storageBucket: "xsales-consumer-consola.appspot.com",
//   messagingSenderId: "823600381138",
//   appId: "1:823600381138:web:62c836b36772c9e98d810b",
//   measurementId: "G-HRX7DH9WBX"
// };

if (process.env.ENV !== "prd") {
    firebaseConfig.measurementId = "G-Z1DSMDTGXZ"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export const analyticsApp = analytics

export const firebaseApp = app

// import firebase from 'firebase'
// import firebaseConfigFile from '../configConsumer/firebase-config.json'

// // export const firebaseConfig = firebaseConfigFile;

// // export const firebaseAnalytics = firebase.analytics()

// var app = firebase.initializeApp(firebaseConfigFile)

// app.analytics()

// export default app;

//var messaging = app.messaging()
// messaging.getToken()

// app.messaging().getToken({ vapidKey: firebaseConfig.apiKey }).then((currentToken) => {
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);
//     //   setTokenFound(true);
//       // Track the token -> client mapping, by sending to backend server
//       // show on the UI that permission is secured
//     } else {
//       console.log('No registration token available. Request permission to generate one.');
//     //   setTokenFound(false);
//       // shows on the UI that permission is required
//     }
// }).catch((err) => {
// console.log('An error occurred while retrieving token. ', err);
// // catch error while creating client token
// });
