/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      name
      lastName
      alternateEmail
      lastSessionDate
      lastIP
      lastOS
      lastDevice
      lastBrowser
      token
      email
      userType
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      name
      lastName
      alternateEmail
      lastSessionDate
      lastIP
      lastOS
      lastDevice
      lastBrowser
      token
      email
      userType
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      name
      lastName
      alternateEmail
      lastSessionDate
      lastIP
      lastOS
      lastDevice
      lastBrowser
      token
      email
      userType
      createdAt
      updatedAt
    }
  }
`;
export const createReasonUnsubscribe = /* GraphQL */ `
  mutation CreateReasonUnsubscribe(
    $input: CreateReasonUnsubscribeInput!
    $condition: ModelReasonUnsubscribeConditionInput
  ) {
    createReasonUnsubscribe(input: $input, condition: $condition) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      commentRequired
      inactive
      inactiveOn
      createdAt
      updatedAt
    }
  }
`;
export const updateReasonUnsubscribe = /* GraphQL */ `
  mutation UpdateReasonUnsubscribe(
    $input: UpdateReasonUnsubscribeInput!
    $condition: ModelReasonUnsubscribeConditionInput
  ) {
    updateReasonUnsubscribe(input: $input, condition: $condition) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      commentRequired
      inactive
      inactiveOn
      createdAt
      updatedAt
    }
  }
`;
export const deleteReasonUnsubscribe = /* GraphQL */ `
  mutation DeleteReasonUnsubscribe(
    $input: DeleteReasonUnsubscribeInput!
    $condition: ModelReasonUnsubscribeConditionInput
  ) {
    deleteReasonUnsubscribe(input: $input, condition: $condition) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      commentRequired
      inactive
      inactiveOn
      createdAt
      updatedAt
    }
  }
`;
export const createUserUnsubscribed = /* GraphQL */ `
  mutation CreateUserUnsubscribed(
    $input: CreateUserUnsubscribedInput!
    $condition: ModelUserUnsubscribedConditionInput
  ) {
    createUserUnsubscribed(input: $input, condition: $condition) {
      username
      name
      lastName
      alternateEmail
      email
      userType
      verificationRequestDate
      verificationCode
      verificationConfirmationDate
      reasonId
      reasonComments
      requestDate
      status
      scheduleUnsubscribe
      scheduleUnsubscribeName
      scheduleUnsubscribeDate
      scheduleReminderName
      scheduleReminderDate
      unsubscribedProcessedDate
      createdAt
      updatedAt
    }
  }
`;
export const updateUserUnsubscribed = /* GraphQL */ `
  mutation UpdateUserUnsubscribed(
    $input: UpdateUserUnsubscribedInput!
    $condition: ModelUserUnsubscribedConditionInput
  ) {
    updateUserUnsubscribed(input: $input, condition: $condition) {
      username
      name
      lastName
      alternateEmail
      email
      userType
      verificationRequestDate
      verificationCode
      verificationConfirmationDate
      reasonId
      reasonComments
      requestDate
      status
      scheduleUnsubscribe
      scheduleUnsubscribeName
      scheduleUnsubscribeDate
      scheduleReminderName
      scheduleReminderDate
      unsubscribedProcessedDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserUnsubscribed = /* GraphQL */ `
  mutation DeleteUserUnsubscribed(
    $input: DeleteUserUnsubscribedInput!
    $condition: ModelUserUnsubscribedConditionInput
  ) {
    deleteUserUnsubscribed(input: $input, condition: $condition) {
      username
      name
      lastName
      alternateEmail
      email
      userType
      verificationRequestDate
      verificationCode
      verificationConfirmationDate
      reasonId
      reasonComments
      requestDate
      status
      scheduleUnsubscribe
      scheduleUnsubscribeName
      scheduleUnsubscribeDate
      scheduleReminderName
      scheduleReminderDate
      unsubscribedProcessedDate
      createdAt
      updatedAt
    }
  }
`;
export const createSupplier = /* GraphQL */ `
  mutation CreateSupplier(
    $input: CreateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    createSupplier(input: $input, condition: $condition) {
      id
      name
      businessName
      username
      taxID
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      status
      historyStatus {
        date
        status
      }
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      contactName
      contactEmail
      latitude
      longitude
      summary
      createdAt
      approvedAt
      industries {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencySymbol
      currencyISOErp
      contract {
        current {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        future {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        history {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
      }
      isXSM
      imageInternalName
      imageExternalURL
      taxPercentage
      discountPercentage
      groups1
      groups2
      groups3
      productTags
      paymentMethods {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      productSameOrderClasses
      updatedAt
    }
  }
`;
export const updateSupplier = /* GraphQL */ `
  mutation UpdateSupplier(
    $input: UpdateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    updateSupplier(input: $input, condition: $condition) {
      id
      name
      businessName
      username
      taxID
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      status
      historyStatus {
        date
        status
      }
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      contactName
      contactEmail
      latitude
      longitude
      summary
      createdAt
      approvedAt
      industries {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencySymbol
      currencyISOErp
      contract {
        current {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        future {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        history {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
      }
      isXSM
      imageInternalName
      imageExternalURL
      taxPercentage
      discountPercentage
      groups1
      groups2
      groups3
      productTags
      paymentMethods {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      productSameOrderClasses
      updatedAt
    }
  }
`;
export const deleteSupplier = /* GraphQL */ `
  mutation DeleteSupplier(
    $input: DeleteSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    deleteSupplier(input: $input, condition: $condition) {
      id
      name
      businessName
      username
      taxID
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      status
      historyStatus {
        date
        status
      }
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      contactName
      contactEmail
      latitude
      longitude
      summary
      createdAt
      approvedAt
      industries {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencySymbol
      currencyISOErp
      contract {
        current {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        future {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        history {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
      }
      isXSM
      imageInternalName
      imageExternalURL
      taxPercentage
      discountPercentage
      groups1
      groups2
      groups3
      productTags
      paymentMethods {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      productSameOrderClasses
      updatedAt
    }
  }
`;
export const createSupplierIndustry = /* GraphQL */ `
  mutation CreateSupplierIndustry(
    $input: CreateSupplierIndustryInput!
    $condition: ModelSupplierIndustryConditionInput
  ) {
    createSupplierIndustry(input: $input, condition: $condition) {
      id
      supplierId
      industryId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSupplierIndustry = /* GraphQL */ `
  mutation UpdateSupplierIndustry(
    $input: UpdateSupplierIndustryInput!
    $condition: ModelSupplierIndustryConditionInput
  ) {
    updateSupplierIndustry(input: $input, condition: $condition) {
      id
      supplierId
      industryId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSupplierIndustry = /* GraphQL */ `
  mutation DeleteSupplierIndustry(
    $input: DeleteSupplierIndustryInput!
    $condition: ModelSupplierIndustryConditionInput
  ) {
    deleteSupplierIndustry(input: $input, condition: $condition) {
      id
      supplierId
      industryId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createIndustry = /* GraphQL */ `
  mutation CreateIndustry(
    $input: CreateIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    createIndustry(input: $input, condition: $condition) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      active
      suppliers {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      consumers {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateIndustry = /* GraphQL */ `
  mutation UpdateIndustry(
    $input: UpdateIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    updateIndustry(input: $input, condition: $condition) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      active
      suppliers {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      consumers {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteIndustry = /* GraphQL */ `
  mutation DeleteIndustry(
    $input: DeleteIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    deleteIndustry(input: $input, condition: $condition) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      active
      suppliers {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      consumers {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConsumer = /* GraphQL */ `
  mutation CreateConsumer(
    $input: CreateConsumerInput!
    $condition: ModelConsumerConditionInput
  ) {
    createConsumer(input: $input, condition: $condition) {
      id
      name
      businessName
      username
      taxID
      status
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      summary
      contactName
      contactEmail
      latitude
      longitude
      industries {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isXSM
      currencyISO
      currencySymbol
      currencyISOErp
      createdAt
      updatedAt
    }
  }
`;
export const updateConsumer = /* GraphQL */ `
  mutation UpdateConsumer(
    $input: UpdateConsumerInput!
    $condition: ModelConsumerConditionInput
  ) {
    updateConsumer(input: $input, condition: $condition) {
      id
      name
      businessName
      username
      taxID
      status
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      summary
      contactName
      contactEmail
      latitude
      longitude
      industries {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isXSM
      currencyISO
      currencySymbol
      currencyISOErp
      createdAt
      updatedAt
    }
  }
`;
export const deleteConsumer = /* GraphQL */ `
  mutation DeleteConsumer(
    $input: DeleteConsumerInput!
    $condition: ModelConsumerConditionInput
  ) {
    deleteConsumer(input: $input, condition: $condition) {
      id
      name
      businessName
      username
      taxID
      status
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      summary
      contactName
      contactEmail
      latitude
      longitude
      industries {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isXSM
      currencyISO
      currencySymbol
      currencyISOErp
      createdAt
      updatedAt
    }
  }
`;
export const createConsumerIndustry = /* GraphQL */ `
  mutation CreateConsumerIndustry(
    $input: CreateConsumerIndustryInput!
    $condition: ModelConsumerIndustryConditionInput
  ) {
    createConsumerIndustry(input: $input, condition: $condition) {
      id
      consumerId
      industryId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateConsumerIndustry = /* GraphQL */ `
  mutation UpdateConsumerIndustry(
    $input: UpdateConsumerIndustryInput!
    $condition: ModelConsumerIndustryConditionInput
  ) {
    updateConsumerIndustry(input: $input, condition: $condition) {
      id
      consumerId
      industryId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteConsumerIndustry = /* GraphQL */ `
  mutation DeleteConsumerIndustry(
    $input: DeleteConsumerIndustryInput!
    $condition: ModelConsumerIndustryConditionInput
  ) {
    deleteConsumerIndustry(input: $input, condition: $condition) {
      id
      consumerId
      industryId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSupplierConsumer = /* GraphQL */ `
  mutation CreateSupplierConsumer(
    $input: CreateSupplierConsumerInput!
    $condition: ModelSupplierConsumerConditionInput
  ) {
    createSupplierConsumer(input: $input, condition: $condition) {
      id
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      consumerId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      requestedOn
      requestedBy
      requestComments
      responsedOn
      responsedBy
      responseComments
      consumerIdERP
      requestStatus
      blockedForSales
      daysDelivery {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        sameDayDelivery
        sameDayDeliveryUntil
        sameDayDeliveryTimeZone
      }
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      taxPercentage
      discountPercentage
      group1
      group2
      group3
      createdAt
      updatedAt
    }
  }
`;
export const updateSupplierConsumer = /* GraphQL */ `
  mutation UpdateSupplierConsumer(
    $input: UpdateSupplierConsumerInput!
    $condition: ModelSupplierConsumerConditionInput
  ) {
    updateSupplierConsumer(input: $input, condition: $condition) {
      id
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      consumerId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      requestedOn
      requestedBy
      requestComments
      responsedOn
      responsedBy
      responseComments
      consumerIdERP
      requestStatus
      blockedForSales
      daysDelivery {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        sameDayDelivery
        sameDayDeliveryUntil
        sameDayDeliveryTimeZone
      }
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      taxPercentage
      discountPercentage
      group1
      group2
      group3
      createdAt
      updatedAt
    }
  }
`;
export const deleteSupplierConsumer = /* GraphQL */ `
  mutation DeleteSupplierConsumer(
    $input: DeleteSupplierConsumerInput!
    $condition: ModelSupplierConsumerConditionInput
  ) {
    deleteSupplierConsumer(input: $input, condition: $condition) {
      id
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      consumerId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      requestedOn
      requestedBy
      requestComments
      responsedOn
      responsedBy
      responseComments
      consumerIdERP
      requestStatus
      blockedForSales
      daysDelivery {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        sameDayDelivery
        sameDayDeliveryUntil
        sameDayDeliveryTimeZone
      }
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      taxPercentage
      discountPercentage
      group1
      group2
      group3
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      supplierId
      categoryId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      inactive
      inactiveOn
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      supplierId
      categoryId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      inactive
      inactiveOn
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      supplierId
      categoryId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      inactive
      inactiveOn
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const createSubcategory = /* GraphQL */ `
  mutation CreateSubcategory(
    $input: CreateSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    createSubcategory(input: $input, condition: $condition) {
      id
      supplierId
      categoryId
      subcategoryId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      inactive
      inactiveOn
      inactiveParent
      inactiveParentOn
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const updateSubcategory = /* GraphQL */ `
  mutation UpdateSubcategory(
    $input: UpdateSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    updateSubcategory(input: $input, condition: $condition) {
      id
      supplierId
      categoryId
      subcategoryId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      inactive
      inactiveOn
      inactiveParent
      inactiveParentOn
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubcategory = /* GraphQL */ `
  mutation DeleteSubcategory(
    $input: DeleteSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    deleteSubcategory(input: $input, condition: $condition) {
      id
      supplierId
      categoryId
      subcategoryId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      inactive
      inactiveOn
      inactiveParent
      inactiveParentOn
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      productId
      supplierId
      unit
      subcategoryId
      descriptionLanguage1
      descriptionLanguage2
      descriptionLanguage3
      descriptionLanguage4
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      tags {
        tag
      }
      price
      percentageDiscount
      amountDiscount
      blockedForSales
      blockedForSalesDate
      inactive
      inactiveOn
      inactiveParent
      inactiveParentOn
      imageInternalName
      imageExternalURL
      taxFree
      barcode
      sameOrderClass
      stock
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      productId
      supplierId
      unit
      subcategoryId
      descriptionLanguage1
      descriptionLanguage2
      descriptionLanguage3
      descriptionLanguage4
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      tags {
        tag
      }
      price
      percentageDiscount
      amountDiscount
      blockedForSales
      blockedForSalesDate
      inactive
      inactiveOn
      inactiveParent
      inactiveParentOn
      imageInternalName
      imageExternalURL
      taxFree
      barcode
      sameOrderClass
      stock
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      productId
      supplierId
      unit
      subcategoryId
      descriptionLanguage1
      descriptionLanguage2
      descriptionLanguage3
      descriptionLanguage4
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      tags {
        tag
      }
      price
      percentageDiscount
      amountDiscount
      blockedForSales
      blockedForSalesDate
      inactive
      inactiveOn
      inactiveParent
      inactiveParentOn
      imageInternalName
      imageExternalURL
      taxFree
      barcode
      sameOrderClass
      stock
      createdAt
      updatedAt
    }
  }
`;
export const createSupplierConsumerProduct = /* GraphQL */ `
  mutation CreateSupplierConsumerProduct(
    $input: CreateSupplierConsumerProductInput!
    $condition: ModelSupplierConsumerProductConditionInput
  ) {
    createSupplierConsumerProduct(input: $input, condition: $condition) {
      id
      productId
      supplierId
      consumerId
      unit
      price
      percentageDiscount
      amountDiscount
      stock
      createdAt
      updatedAt
    }
  }
`;
export const updateSupplierConsumerProduct = /* GraphQL */ `
  mutation UpdateSupplierConsumerProduct(
    $input: UpdateSupplierConsumerProductInput!
    $condition: ModelSupplierConsumerProductConditionInput
  ) {
    updateSupplierConsumerProduct(input: $input, condition: $condition) {
      id
      productId
      supplierId
      consumerId
      unit
      price
      percentageDiscount
      amountDiscount
      stock
      createdAt
      updatedAt
    }
  }
`;
export const deleteSupplierConsumerProduct = /* GraphQL */ `
  mutation DeleteSupplierConsumerProduct(
    $input: DeleteSupplierConsumerProductInput!
    $condition: ModelSupplierConsumerProductConditionInput
  ) {
    deleteSupplierConsumerProduct(input: $input, condition: $condition) {
      id
      productId
      supplierId
      consumerId
      unit
      price
      percentageDiscount
      amountDiscount
      stock
      createdAt
      updatedAt
    }
  }
`;
export const createWindow = /* GraphQL */ `
  mutation CreateWindow(
    $input: CreateWindowInput!
    $condition: ModelWindowConditionInput
  ) {
    createWindow(input: $input, condition: $condition) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      messages {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateWindow = /* GraphQL */ `
  mutation UpdateWindow(
    $input: UpdateWindowInput!
    $condition: ModelWindowConditionInput
  ) {
    updateWindow(input: $input, condition: $condition) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      messages {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteWindow = /* GraphQL */ `
  mutation DeleteWindow(
    $input: DeleteWindowInput!
    $condition: ModelWindowConditionInput
  ) {
    deleteWindow(input: $input, condition: $condition) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      messages {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLabel = /* GraphQL */ `
  mutation CreateLabel(
    $input: CreateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    createLabel(input: $input, condition: $condition) {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLabel = /* GraphQL */ `
  mutation UpdateLabel(
    $input: UpdateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    updateLabel(input: $input, condition: $condition) {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLabel = /* GraphQL */ `
  mutation DeleteLabel(
    $input: DeleteLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    deleteLabel(input: $input, condition: $condition) {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSecurity = /* GraphQL */ `
  mutation CreateSecurity(
    $input: CreateSecurityInput!
    $condition: ModelSecurityConditionInput
  ) {
    createSecurity(input: $input, condition: $condition) {
      id
      securityId
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      securityXSM
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSecurity = /* GraphQL */ `
  mutation UpdateSecurity(
    $input: UpdateSecurityInput!
    $condition: ModelSecurityConditionInput
  ) {
    updateSecurity(input: $input, condition: $condition) {
      id
      securityId
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      securityXSM
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecurity = /* GraphQL */ `
  mutation DeleteSecurity(
    $input: DeleteSecurityInput!
    $condition: ModelSecurityConditionInput
  ) {
    deleteSecurity(input: $input, condition: $condition) {
      id
      securityId
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      securityXSM
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSetup = /* GraphQL */ `
  mutation CreateSetup(
    $input: CreateSetupInput!
    $condition: ModelSetupConditionInput
  ) {
    createSetup(input: $input, condition: $condition) {
      id
      setupId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateSetup = /* GraphQL */ `
  mutation UpdateSetup(
    $input: UpdateSetupInput!
    $condition: ModelSetupConditionInput
  ) {
    updateSetup(input: $input, condition: $condition) {
      id
      setupId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteSetup = /* GraphQL */ `
  mutation DeleteSetup(
    $input: DeleteSetupInput!
    $condition: ModelSetupConditionInput
  ) {
    deleteSetup(input: $input, condition: $condition) {
      id
      setupId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const createSupplierSetup = /* GraphQL */ `
  mutation CreateSupplierSetup(
    $input: CreateSupplierSetupInput!
    $condition: ModelSupplierSetupConditionInput
  ) {
    createSupplierSetup(input: $input, condition: $condition) {
      id
      supplierId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateSupplierSetup = /* GraphQL */ `
  mutation UpdateSupplierSetup(
    $input: UpdateSupplierSetupInput!
    $condition: ModelSupplierSetupConditionInput
  ) {
    updateSupplierSetup(input: $input, condition: $condition) {
      id
      supplierId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteSupplierSetup = /* GraphQL */ `
  mutation DeleteSupplierSetup(
    $input: DeleteSupplierSetupInput!
    $condition: ModelSupplierSetupConditionInput
  ) {
    deleteSupplierSetup(input: $input, condition: $condition) {
      id
      supplierId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const createConsumerSetup = /* GraphQL */ `
  mutation CreateConsumerSetup(
    $input: CreateConsumerSetupInput!
    $condition: ModelConsumerSetupConditionInput
  ) {
    createConsumerSetup(input: $input, condition: $condition) {
      id
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateConsumerSetup = /* GraphQL */ `
  mutation UpdateConsumerSetup(
    $input: UpdateConsumerSetupInput!
    $condition: ModelConsumerSetupConditionInput
  ) {
    updateConsumerSetup(input: $input, condition: $condition) {
      id
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteConsumerSetup = /* GraphQL */ `
  mutation DeleteConsumerSetup(
    $input: DeleteConsumerSetupInput!
    $condition: ModelConsumerSetupConditionInput
  ) {
    deleteConsumerSetup(input: $input, condition: $condition) {
      id
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const createSupplierConsumerSetup = /* GraphQL */ `
  mutation CreateSupplierConsumerSetup(
    $input: CreateSupplierConsumerSetupInput!
    $condition: ModelSupplierConsumerSetupConditionInput
  ) {
    createSupplierConsumerSetup(input: $input, condition: $condition) {
      id
      supplierId
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateSupplierConsumerSetup = /* GraphQL */ `
  mutation UpdateSupplierConsumerSetup(
    $input: UpdateSupplierConsumerSetupInput!
    $condition: ModelSupplierConsumerSetupConditionInput
  ) {
    updateSupplierConsumerSetup(input: $input, condition: $condition) {
      id
      supplierId
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteSupplierConsumerSetup = /* GraphQL */ `
  mutation DeleteSupplierConsumerSetup(
    $input: DeleteSupplierConsumerSetupInput!
    $condition: ModelSupplierConsumerSetupConditionInput
  ) {
    deleteSupplierConsumerSetup(input: $input, condition: $condition) {
      id
      supplierId
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const createAudit = /* GraphQL */ `
  mutation CreateAudit(
    $input: CreateAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    createAudit(input: $input, condition: $condition) {
      id
      date
      type
      code
      message
      systemMessage
      application
      process
      format
      username
      url
      ipAddress
      appVersion
      errorNumber
      duration
      supplierId
      consumerId
      createdAt
      updatedAt
    }
  }
`;
export const updateAudit = /* GraphQL */ `
  mutation UpdateAudit(
    $input: UpdateAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    updateAudit(input: $input, condition: $condition) {
      id
      date
      type
      code
      message
      systemMessage
      application
      process
      format
      username
      url
      ipAddress
      appVersion
      errorNumber
      duration
      supplierId
      consumerId
      createdAt
      updatedAt
    }
  }
`;
export const deleteAudit = /* GraphQL */ `
  mutation DeleteAudit(
    $input: DeleteAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    deleteAudit(input: $input, condition: $condition) {
      id
      date
      type
      code
      message
      systemMessage
      application
      process
      format
      username
      url
      ipAddress
      appVersion
      errorNumber
      duration
      supplierId
      consumerId
      createdAt
      updatedAt
    }
  }
`;
export const createCrew = /* GraphQL */ `
  mutation CreateCrew(
    $input: CreateCrewInput!
    $condition: ModelCrewConditionInput
  ) {
    createCrew(input: $input, condition: $condition) {
      id
      crewId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCrew = /* GraphQL */ `
  mutation UpdateCrew(
    $input: UpdateCrewInput!
    $condition: ModelCrewConditionInput
  ) {
    updateCrew(input: $input, condition: $condition) {
      id
      crewId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCrew = /* GraphQL */ `
  mutation DeleteCrew(
    $input: DeleteCrewInput!
    $condition: ModelCrewConditionInput
  ) {
    deleteCrew(input: $input, condition: $condition) {
      id
      crewId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCrewSecurity = /* GraphQL */ `
  mutation CreateCrewSecurity(
    $input: CreateCrewSecurityInput!
    $condition: ModelCrewSecurityConditionInput
  ) {
    createCrewSecurity(input: $input, condition: $condition) {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      securityId
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCrewSecurity = /* GraphQL */ `
  mutation UpdateCrewSecurity(
    $input: UpdateCrewSecurityInput!
    $condition: ModelCrewSecurityConditionInput
  ) {
    updateCrewSecurity(input: $input, condition: $condition) {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      securityId
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCrewSecurity = /* GraphQL */ `
  mutation DeleteCrewSecurity(
    $input: DeleteCrewSecurityInput!
    $condition: ModelCrewSecurityConditionInput
  ) {
    deleteCrewSecurity(input: $input, condition: $condition) {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      securityId
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCrewUser = /* GraphQL */ `
  mutation CreateCrewUser(
    $input: CreateCrewUserInput!
    $condition: ModelCrewUserConditionInput
  ) {
    createCrewUser(input: $input, condition: $condition) {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const updateCrewUser = /* GraphQL */ `
  mutation UpdateCrewUser(
    $input: UpdateCrewUserInput!
    $condition: ModelCrewUserConditionInput
  ) {
    updateCrewUser(input: $input, condition: $condition) {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const deleteCrewUser = /* GraphQL */ `
  mutation DeleteCrewUser(
    $input: DeleteCrewUserInput!
    $condition: ModelCrewUserConditionInput
  ) {
    deleteCrewUser(input: $input, condition: $condition) {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      supplierId
      consumerId
      consumerIdERP
      orderCodeERP
      date
      dateERP
      deliveryOn
      deliveryExpectedOn
      requestComment
      orderComments
      shipping
      status
      statusERP
      historyStatusERP {
        datetimeERP
        statusERP
      }
      grossAmount
      discountAmount
      taxAmount
      netAmount
      orderProducts {
        items {
          id
          orderId
          productId
          unitId
          price
          quantity
          grossAmount
          discountAmount
          taxAmount
          netAmount
          shortnameLanguage1
          shortnameLanguage2
          shortnameLanguage3
          shortnameLanguage4
          imageInternalName
          imageExternalURL
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencyISOErpRequest
      currencyISOErp
      currencySymbol
      exchangeRate
      posted
      postedOn
      itemCount
      paymentCondition
      taxes {
        name
        amount
      }
      discounts {
        name
        amount
      }
      paymentMethod {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      shippingAddress {
        key
        value
        default
      }
      group1
      group2
      group3
      consumerName
      sameOrderClass
      dateTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      supplierId
      consumerId
      consumerIdERP
      orderCodeERP
      date
      dateERP
      deliveryOn
      deliveryExpectedOn
      requestComment
      orderComments
      shipping
      status
      statusERP
      historyStatusERP {
        datetimeERP
        statusERP
      }
      grossAmount
      discountAmount
      taxAmount
      netAmount
      orderProducts {
        items {
          id
          orderId
          productId
          unitId
          price
          quantity
          grossAmount
          discountAmount
          taxAmount
          netAmount
          shortnameLanguage1
          shortnameLanguage2
          shortnameLanguage3
          shortnameLanguage4
          imageInternalName
          imageExternalURL
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencyISOErpRequest
      currencyISOErp
      currencySymbol
      exchangeRate
      posted
      postedOn
      itemCount
      paymentCondition
      taxes {
        name
        amount
      }
      discounts {
        name
        amount
      }
      paymentMethod {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      shippingAddress {
        key
        value
        default
      }
      group1
      group2
      group3
      consumerName
      sameOrderClass
      dateTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      supplierId
      consumerId
      consumerIdERP
      orderCodeERP
      date
      dateERP
      deliveryOn
      deliveryExpectedOn
      requestComment
      orderComments
      shipping
      status
      statusERP
      historyStatusERP {
        datetimeERP
        statusERP
      }
      grossAmount
      discountAmount
      taxAmount
      netAmount
      orderProducts {
        items {
          id
          orderId
          productId
          unitId
          price
          quantity
          grossAmount
          discountAmount
          taxAmount
          netAmount
          shortnameLanguage1
          shortnameLanguage2
          shortnameLanguage3
          shortnameLanguage4
          imageInternalName
          imageExternalURL
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencyISOErpRequest
      currencyISOErp
      currencySymbol
      exchangeRate
      posted
      postedOn
      itemCount
      paymentCondition
      taxes {
        name
        amount
      }
      discounts {
        name
        amount
      }
      paymentMethod {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      shippingAddress {
        key
        value
        default
      }
      group1
      group2
      group3
      consumerName
      sameOrderClass
      dateTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createOrderProduct = /* GraphQL */ `
  mutation CreateOrderProduct(
    $input: CreateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    createOrderProduct(input: $input, condition: $condition) {
      id
      orderId
      productId
      unitId
      price
      quantity
      grossAmount
      discountAmount
      taxAmount
      netAmount
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderProduct = /* GraphQL */ `
  mutation UpdateOrderProduct(
    $input: UpdateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    updateOrderProduct(input: $input, condition: $condition) {
      id
      orderId
      productId
      unitId
      price
      quantity
      grossAmount
      discountAmount
      taxAmount
      netAmount
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderProduct = /* GraphQL */ `
  mutation DeleteOrderProduct(
    $input: DeleteOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    deleteOrderProduct(input: $input, condition: $condition) {
      id
      orderId
      productId
      unitId
      price
      quantity
      grossAmount
      discountAmount
      taxAmount
      netAmount
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const createSyncLog = /* GraphQL */ `
  mutation CreateSyncLog(
    $input: CreateSyncLogInput!
    $condition: ModelSyncLogConditionInput
  ) {
    createSyncLog(input: $input, condition: $condition) {
      id
      supplierId
      consumerId
      date
      dateStart
      dateEnd
      syncId
      process
      typeProcess
      status
      isOk
      isWarning
      message
      errors
      systemMessage
      sourceCount
      inserted
      updated
      noupdated
      errored
      errorNumber
      totalDuration
      extractDuration
      transformDuration
      loadDuration
      createdAt
      updatedAt
    }
  }
`;
export const updateSyncLog = /* GraphQL */ `
  mutation UpdateSyncLog(
    $input: UpdateSyncLogInput!
    $condition: ModelSyncLogConditionInput
  ) {
    updateSyncLog(input: $input, condition: $condition) {
      id
      supplierId
      consumerId
      date
      dateStart
      dateEnd
      syncId
      process
      typeProcess
      status
      isOk
      isWarning
      message
      errors
      systemMessage
      sourceCount
      inserted
      updated
      noupdated
      errored
      errorNumber
      totalDuration
      extractDuration
      transformDuration
      loadDuration
      createdAt
      updatedAt
    }
  }
`;
export const deleteSyncLog = /* GraphQL */ `
  mutation DeleteSyncLog(
    $input: DeleteSyncLogInput!
    $condition: ModelSyncLogConditionInput
  ) {
    deleteSyncLog(input: $input, condition: $condition) {
      id
      supplierId
      consumerId
      date
      dateStart
      dateEnd
      syncId
      process
      typeProcess
      status
      isOk
      isWarning
      message
      errors
      systemMessage
      sourceCount
      inserted
      updated
      noupdated
      errored
      errorNumber
      totalDuration
      extractDuration
      transformDuration
      loadDuration
      createdAt
      updatedAt
    }
  }
`;
export const createStatement = /* GraphQL */ `
  mutation CreateStatement(
    $input: CreateStatementInput!
    $condition: ModelStatementConditionInput
  ) {
    createStatement(input: $input, condition: $condition) {
      id
      year
      month
      paddedMonth
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      contractDetail {
        since
        plan
        fees {
          deposit
          payment
          order
        }
        minTransaction {
          deposit
          payment
          order
        }
        maxTransaction
      }
      count {
        deposit
        payment
        order
      }
      amount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      avgItem {
        deposit
        payment
        order
      }
      avgAmount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      itemCount {
        deposit
        payment
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateStatement = /* GraphQL */ `
  mutation UpdateStatement(
    $input: UpdateStatementInput!
    $condition: ModelStatementConditionInput
  ) {
    updateStatement(input: $input, condition: $condition) {
      id
      year
      month
      paddedMonth
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      contractDetail {
        since
        plan
        fees {
          deposit
          payment
          order
        }
        minTransaction {
          deposit
          payment
          order
        }
        maxTransaction
      }
      count {
        deposit
        payment
        order
      }
      amount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      avgItem {
        deposit
        payment
        order
      }
      avgAmount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      itemCount {
        deposit
        payment
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteStatement = /* GraphQL */ `
  mutation DeleteStatement(
    $input: DeleteStatementInput!
    $condition: ModelStatementConditionInput
  ) {
    deleteStatement(input: $input, condition: $condition) {
      id
      year
      month
      paddedMonth
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      contractDetail {
        since
        plan
        fees {
          deposit
          payment
          order
        }
        minTransaction {
          deposit
          payment
          order
        }
        maxTransaction
      }
      count {
        deposit
        payment
        order
      }
      amount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      avgItem {
        deposit
        payment
        order
      }
      avgAmount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      itemCount {
        deposit
        payment
        order
      }
      createdAt
      updatedAt
    }
  }
`;
