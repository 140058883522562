import { I18n } from "aws-amplify"
import moment from "moment"
import { XSalesConsumer } from "../../../Utils/Utils"
import { getDataOrdersByDate } from "./services"
import styled from 'styled-components'
export function assignLanguageRadio(radioItem,filters, labels){
    filters[radioItem].forEach( (item,index) => {
        item.Label = labels[index]
    })
}

export async function filterData(dataFilters, data, supplierId) {
    const [valueGroupType] = dataFilters.itemsRadioReportBy.filter(x => x.value === true)
    let dataFiltered = []
    dataFiltered = await filterByDeliveryDate(dataFilters, dataFiltered)
    dataFiltered = filterByItemRadio(dataFilters?.itemsRadioOrderStatus, "status", dataFiltered)
    dataFiltered = filterByItemRadio(dataFilters?.itemsRadioShippingType, "shipping", dataFiltered)
    dataFiltered = filterByGroup(dataFilters.groups, valueGroupType?.Key, dataFiltered)
    assignLanguageRadio("itemsRadioDeliveryDate",dataFilters,[
        I18n.get('lblToday'),
        I18n.get('lblYesterday'),
        I18n.get('lblTomorrow'),
        I18n.get('lblRange')
    ])
    assignLanguageRadio("itemsRadioOrderStatus",dataFilters,[
        I18n.get('lblPlaced'),
        I18n.get('lblDelivered'),
        I18n.get('lblAll')
    ])

    assignLanguageRadio("itemsRadioShippingType",dataFilters,[
        I18n.get('lblShippingDelivery'),
        I18n.get('lblShippingPickup')
    ])

    assignLanguageRadio("itemsRadioReportBy",dataFilters,[
        I18n.get('lblClientGroup1'),
        I18n.get('lblClientGroup2'),
        I18n.get('lblClientGroup3')
    ])

    assignLanguageRadio("itemsRadioType",dataFilters,[
        I18n.get('lblOrders'),
        I18n.get('lblProducts')
    ])
    
    // setData(dataFiltered)
    // setGroupType(valueGroupType?.Key)
    return { dataFiltered, valueGroupType: valueGroupType?.Key }
}

export async function filterByDeliveryDate({ itemsRadioDeliveryDate: itemRadioDate, dateFrom, dateTo }, data, date) {
    if (!itemRadioDate) return
    const { supplier } = XSalesConsumer.getCurrentUserNew()
    const supplierId = supplier.id
    let dataFilter = [...data]
    const [filterSelected] = itemRadioDate.filter(x => x.value === true)
    if (filterSelected?.value) {
        if (filterSelected?.Key === "today") {
            const { startDate, endDate } = getDatesByFilter('today')
            dataFilter = await getDataOrder(supplierId,startDate, endDate)
        }
        
        if (filterSelected?.Key === "yesterday") {
            const { startDate, endDate } = getDatesByFilter('yesterday')
            dataFilter = await getDataOrder(supplierId,startDate, endDate)
        }

        if (filterSelected?.Key === "tomorrow") {
            const { startDate, endDate } =  getDatesByFilter('tomorrow')
            dataFilter = await getDataOrder(supplierId,startDate, endDate)
        }

        if (filterSelected?.Key === "range") {
            const { startDate, endDate } = getDatesByFilter('range', dateFrom, dateTo)
            dataFilter = await getDataOrder(supplierId,startDate, endDate)
        }
    }
    return dataFilter
}

export function getDatesByFilter(type,dateFrom = new Date(), dateTo = new Date()) {
    // today default 
    let startDate = moment().startOf('day').toISOString()
    let endDate = moment().endOf('day').toISOString() 

    if (type === 'tomorrow') {
        startDate = moment().add(1, 'day').startOf('day').toISOString()
        endDate = moment().add(1, 'day').endOf('day').toISOString()
    }

    if (type === 'yesterday') {
        startDate = moment().subtract(1, 'day').startOf('day').toISOString()
        endDate = moment().subtract(1, 'day').endOf('day').toISOString()
    }

    if (type === 'range') {
        startDate = moment(dateFrom).startOf('day').toISOString()
        endDate = moment(dateTo).endOf('day').toISOString()
    }
    return { startDate, endDate }
} 

export function filterByItemRadio(itemRadio, field, data) {
    if (!itemRadio) return
    let dataFilter = [...data]
    const [filterSelected] = itemRadio.filter(x => x.value === true);
    if (filterSelected?.value && filterSelected?.Key !== 'all') {
        dataFilter = dataFilter.filter(x => x[field].toLowerCase() === filterSelected.Key.toLowerCase())
    }
    return dataFilter
}

export function filterByGroup({ group1, group2, group3 }, reportType, data) {
    let dataFilter = [...data]
    if (group1?.values?.value && reportType === "group1") {
        dataFilter = dataFilter.filter(x => x.group1 === group1.values.value)
    }
    if (group2?.values?.value && reportType === "group2") {
        dataFilter = dataFilter.filter(x => x.group2 === group2.values.value)
    }
    if (group3?.values?.value && reportType === "group3") {
        dataFilter = dataFilter.filter(x => x.group3 === group3.values.value)
    }
    return dataFilter
}

export async function getDataOrder(supplierId, startDate, endDate) {
    const dataOrderByDate = await getDataOrdersByDate(supplierId, startDate, endDate)
    const data = dataOrderByDate.map(x => ({
        id: x.id,
        group1: x.group1,
        group2: x.group2,
        group3: x.group3,
        customer: x.consumerName,
        order: x.orderCodeERP,
        grossAmount: x.grossAmount,
        createOn: XSalesConsumer.formatShortDatetime(x.createdAt),
        itemQty: x.orderProducts.items.length,
        netAmount: x.netAmount,
        deliveryOn: x.deliveryOn,
        delivered: x.deliveryOn ? "true" : "false",
        status: x.status,
        shipping: x.shipping,
        orderProducts: x.orderProducts.items,
        currencyISOErp: x.currencyISOErp,
        currencySymbol: x.currencySymbol
    }))
    return data
}

export async function getOrdersFromCache(startDate, endDate)
{   const { supplier } = XSalesConsumer.getCurrentUserNew()
    const cache = window.sessionStorage.getItem("orderData")
    let itemData = []
    if(!cache){
        const _startDate = moment().subtract(7,'days').startOf('day').toISOString()
        const _endDate =  moment().endOf('day').toISOString()
        const data = await getDataOrder(supplier.id,_startDate,_endDate)
        window.sessionStorage.setItem("orderData",JSON.stringify(data))
        itemData = data
    }else{
        itemData = JSON.parse(cache)
    }

    if(startDate && endDate){
        let start = moment(startDate).startOf('day').toDate().getTime()
        let end = moment(endDate).endOf('day').toDate().getTime()
        itemData = itemData.filter(x => moment(x.deliveryOn).toDate().getTime() >= start && moment(x.deliveryOn).toDate().getTime() <= end)
    }
    return itemData
}

export  function groupByProductByUnit(orders) {
    const products = {}
    const productList = []
    for (const order of orders) {
        for (const orderProduct of order.orderProducts) {
            if (!products[orderProduct.productId]) {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                products[orderProduct.productId] = [
                    { unit: orderProduct.unitId, label: orderProduct.shortnameLanguage1, value: orderProduct.quantity }
                ]
            } else {
                const idx = products[orderProduct.productId].findIndex(x => x.unit === orderProduct.unitId)
                if (idx === -1) {
                    products[orderProduct.productId].push(
                        { unit: orderProduct.unitId, label: orderProduct.shortnameLanguage1, value: orderProduct.quantity }
                    )
                } else {
                    products[orderProduct.productId][idx].value += orderProduct.quantity
                }
            }
        }
    }
    Object.keys(products).forEach(key => {
        productList.push({ productId: products[key], label: products[key].label, value: products[key].value })
    })

    return products
}

export const maxDays = 7
export function sanatizeText(text, qty) {
    if (text.length > qty) {
        return `${text.substring(0, qty)}...`
    }
    return text
}

export const defaultFilters = {
    itemsRadioDeliveryDate: [
        {
            Key: "today",
            Label: "Today",
            value: true
        },
        {
            Key: "yesterday",
            Label: "Yesterday",
            value: false
        },
        {
            Key: "tomorrow",
            Label: "Tomorrow",
            value: false
        },
        {
            Key: "range",
            Label: "Range",
            value: false
        }
    ],
    dateFrom: new Date().toISOString(),
    dateTo: new Date().toISOString(),
    itemsRadioOrderStatus: [
        {
            Key: "placed",
            Label: "Placed",
            value: true
        },
        {
            Key: "delivered",
            Label: "Delivered",
            value: false
        },
        {
            Key: "all",
            Label: "All",
            value: false
        }
    ],
    itemsRadioShippingType: [
        {
            Key: "delivery",
            Label: "Delivery",
            value: true
        },
        {
            Key: "pickup",
            Label: "Pickup",
            value: false
        }
    ],
    itemsRadioReportBy: [
        {
            Key: "group1",
            Label: "Client Group1",
            value: true
        },
        {
            Key: "group2",
            Label: "Client Group2",
            value: false
        },
        {
            Key: "group3",
            Label: "Client Group3",
            value: false
        },
    ],
    itemsRadioType: [
        {
            Key: "orders",
            Label: "Orders",
            value: true
        },
        {
            Key: "products",
            Label: "Products",
            value: false
        }
    ],
    groups: {
        group1: {
            values: "",
            options: [{ value: "", label: "" }]
        },
        group2: {
            values: "",
            options: [{ value: "", label: "" }]
        },
        group3: {
            values: "",
            options: [{ value: "", label: "" }]
        },
    }
}

export const TitleDetail = styled.h3`
    margin-top: 30px;
`