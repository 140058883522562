/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  Tab,
  TabCell,
  SectionEditable,
  InputText,
  InputNumber,
  CheckBox,
  InputPass,
  TextArea,
  SelectCombo,
  RadioButton,
  //InputUrl,
  Image,
  SeparateTop,
  LoaderWindow,
  Carrousel,
} from 'xsales-components'
import csc from 'country-state-city'
import { ImageUrlTypes, XSalesData } from '../../DataQuery/XSalesData'
import { XSalesConsumer } from '../../Utils/Utils'
import * as db from './../../models'
import { I18n } from '@aws-amplify/core'
import imgCDN from '../../configConsumer/imagesDefaultCDN.json'
import { CurrencyConfig } from './CurrencyConfig'
//import { withAuthFileWithAuthResponse } from "@azure/ms-rest-nodeauth/dist/lib/login"
// import { PromotionalBannersSlider } from './PromotionalBannersSlider'

import { PaymentMethodConfig } from './PaymentMethodConfig'

const listAuthType = () => {
  return [
    { label: I18n.get('lblAuthType_NoAuth'), value: '0' },
    { label: I18n.get('lblAuthType_ApiKey'), value: '1' },
    { label: I18n.get('lblAuthType_Basic'), value: '2' },
  ]
}

const heightTextArea = '160px'

export default function MySupplier() {
  const [standAlone, setStandAlone] = useState(false)

  const [loader, setLoader] = useState(true)
  const [options] = useState([
    {
      id: 'general',
      label: I18n.get('lblgeneralinfo'),
      default: true,
    },
    {
      id: 'preference',
      label: I18n.get('lblpreferencessup'),
      default: false,
    },
    {
      id: 'promotionalsbanners',
      label: I18n.get('lblbannerssup'),
      default: false,
    },
  ])
  const [supplier, setSupplier] = useState({
    id: '',
    email: '',
    businessName: '',
    username: '',
    name: '',
    taxID: '',
    summary: '',
    imageUrl: '',
    contactName: '',
    contactEmail: '',
    address: [],
    phones: [],
    website: '',
    status: '',
    latitude: 0,
    longitude: 0,
    taxPercentage: '0',
    discountPercentage: '0',
    promotions: [],
    imageName: '',
    paymentMethods: [],
  })

  const [taxPercentage, setTaxPercentage] = useState('0')
  const [discountPercentage, setDiscountPercentage] = useState('0')
  const [promotions, setPromotions] = useState([])
  //const [promotionsData, setPromotionsData] = useState([])

  const [
    UpdateActionSupplierEndpoint,
    SetUpdateActionSupplierEndpoint,
  ] = useState(false)

  //*************************ENDPOINT******************************************************************
  //state
  const [supplierEnpoint, setSupplierEndpoint] = useState({
    endpointSupplierApi: '',
    endpointSupplierApiKey: '',
    endpointSupplierApiSectretKey: '',
    endpointAuthType: '',
    endpointAuthBasicUser: '',
    endpointAuthBasicPass: '',
  })

  //methods
  const UpdateActionButtonSupplierEndpoint = () => {
    SetUpdateActionSupplierEndpoint(!UpdateActionSupplierEndpoint)
  }

  const handleChangeSupplierEndpoint = (e) => {
    /*  const { name, value } = e.target
        setSupplierEndpoint((prevState) => {
            prevState[name] = value
            return prevState
        }) */

    const { name, value } = e.target
    if (name !== undefined && name !== null && name !== '') {
      setSupplierEndpoint((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleChangeEndpointAuthType = (e) => {
    const { value } = e
    setSupplierEndpoint({
      ...supplierEnpoint,
      endpointAuthType: value,
    }) //https://stackoverflow.com/questions/58399919/react-does-not-change-state-after-updating-state-in-usestate-hook-in-custom-fun
    //Because you are still using the same object. prevState in your example is still the same object. You should create a new object with the changed data – tic Oct 16 '19 at 20:22
  }

  const CancelSupplierEndpoint = async () => {
    //get setup CW007, CW008, CW009
    SetUpdateActionSupplierEndpoint(false)
    await GetSupplierEndpoint(supplier.id)
  }

  const ChangeSupplierEndpoint = async () => {
    //if authType = 1 &&
    //supplierEnpoint.endpointSupplierApiKey, == "" raise error
    //save setup CW007, CW008, CW009

    SetUpdateActionSupplierEndpoint(false)
    await XSalesData.Suppliers.SetSupplierEndpoint(
      supplier.id,
      supplierEnpoint,
    )
      .then((data) => {
        //console.log("ChangeSupplierEndpoint OK", data)
        XSalesConsumer.fireMessage(
          'MySupplier',
          'Endpoint configured correctly',
        )
      })
      .catch((err) => {
        console.log('ChangeSupplierEndpoint ERROR', err)
      })
  }

  const GetSupplierEndpoint = async (SupplierId) => {
    await XSalesData.Suppliers.GetSupplierEndpoint(SupplierId)
      .then((data) => {
        let newState = {
          endpointSupplierApi: data.CW007,
          endpointSupplierApiKey: data.CW008,
          endpointSupplierApiSectretKey: data.CW009,
          endpointAuthType: data.CW016,
          endpointAuthBasicUser: data.CW017,
          endpointAuthBasicPass: data.CW018,
        }
        setSupplierEndpoint(newState)
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  function getAuthType(value) {
    const item = listAuthType().find((o) => o.value === value)
    if (item) {
      return item.value
    } else {
      return getAuthType('0')
    }
  }

  // END ENDPOINT

  const [minimumOrder, setMinimumOrder] = useState({
    orderMinAmount: '',
    orderMinRule: '',
  })

  const [phoneWork, setPhoneWork] = useState({
    type: '',
    number: '',
    default: false,
  })
  const [phoneHome, setPhoneHome] = useState({
    type: '',
    number: '',
    default: false,
  })
  const [phoneMobile, setPhoneMobile] = useState({
    type: '',
    number: '',
    default: false,
  })
  const [defaultPhone, setDefaultPhone] = useState([
    { Key: 'work', Label: I18n.get('lblWork'), value: false },
    { Key: 'mobile', Label: I18n.get('lblMobile'), value: false },
    { Key: 'home', Label: I18n.get('lblHome'), value: false },
  ])

  const [address, setAddress] = useState(() => {
    return new db.Address({
      type: db.AddressType.MAIN,
      default: true,
      country: '',
      city: '',
      state: '',
      street1: '',
      street2: '',
      street3: '',
      zipcode: '',
      countryId: '',
      cityId: '',
      stateId: '',
    })
  })

  //const [countryShow, setCountryShow] = useState("")
  //const [stateShow, setStateShow] = useState("")
  //const [cityShow, setCityShow] = useState("")

  const [countryList] = useState(
    csc.getAllCountries().map((item) => {
      return { label: item.name, value: item.id }
    }),
  )

  const [myAddress, setMyAddress] = useState({ data: [] })

  const [myAddressSelected, setMyAddressSelected] = useState('')

  // let countryList = async () => {
  //     return csc.getAllCountries().map((item) => {
  //         return { label: item.name, value: item.id }
  //     })
  // }
  const [supplierAddress, setSupplierAddress] = useState([
    { data: [] },
  ])
  //const [country, setCountry] = useState([{ label: "", value: "" }])
  let stateList = async (countryCode) => {
    return await csc.getStatesOfCountry(countryCode).map((item) => {
      return { label: item.name, value: item.id }
    })
  }
  const [state, setState] = useState([{ label: '', value: '' }])
  let cityList = async (stateCode) => {
    return await csc.getCitiesOfState(stateCode).map((item) => {
      return { label: item.name, value: item.id }
    })
  }
  const [city, setCity] = useState([{ label: '', value: '' }])

  const GetSupplierMinimumOrder = async (SupplierId) => {
    await XSalesData.Suppliers.GetSupplierMinimumOrder(SupplierId)
      .then((data) => {
        let newState = {
          orderMinAmount: isNaN(data.orderAmount)
            ? 0
            : data.orderAmount,
          orderMinRule: data.orderkey,
        }
        setMinimumOrder(newState)
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  async function GetSupplier(SupplierId) {
    await XSalesData.Suppliers.GetSupplier(SupplierId)
      .then(async (data) => {
        let phoneWork = data.phones?.filter(
          (x) => x.type === db.PhoneType.WORK,
        )
        if (phoneWork) {
          setPhoneWork(
            new db.Phone({
              type: db.PhoneType.WORK,
              number: phoneWork.length > 0 ? phoneWork[0].number : '',
              default:
                phoneWork.length > 0 ? phoneWork[0].default : false,
            }),
          )
        }
        let phoneHome = data.phones?.filter(
          (x) => x.type === db.PhoneType.HOME,
        )
        if (phoneHome) {
          setPhoneHome(
            new db.Phone({
              type: db.PhoneType.HOME,
              number: phoneHome.length > 0 ? phoneHome[0].number : '',
              default:
                phoneHome.length > 0 ? phoneHome[0].default : false,
            }),
          )
        }

        let phoneMobile = data.phones?.filter(
          (x) => x.type === db.PhoneType.MOBILE,
        )
        if (phoneMobile) {
          setPhoneMobile(
            new db.Phone({
              type: db.PhoneType.MOBILE,
              number:
                phoneMobile.length > 0 ? phoneMobile[0].number : '',
              default:
                phoneMobile.length > 0
                  ? phoneMobile[0].default
                  : false,
            }),
          )
        }

        setDefaultPhone(
          defaultPhone.map((item) => {
            if (item.Key === 'work')
              item.value =
                phoneWork.length > 0 ? phoneWork[0].default : false
            if (item.Key === 'mobile')
              item.value =
                phoneMobile.length > 0
                  ? phoneMobile[0].default
                  : false
            if (item.Key === 'home')
              item.value =
                phoneHome.length > 0 ? phoneHome[0].default : false

            return item
          }),
        )

        let mainAddress = data.address?.filter(
          (x) => x.type === db.AddressType.MAIN,
        )
        if (mainAddress) {
          if (mainAddress && mainAddress[0]) {
            mainAddress = mainAddress[0]
          } else {
            mainAddress = new db.Address()
          }
          setAddress(mainAddress)
        }
        const images = XSalesConsumer.getImageUrlList({
          imageInternalName: data.imageInternalName,
          imageExternalURL: data.imageExternalURL,
          supplierId: data.id,
          imageType: ImageUrlTypes.Types.logos,
        })

        if (images) {
          data.imageUrl = images.at(0)
        }
        if (
          data.imageExternalURL !== undefined &&
          data.imageExternalURL !== null
        ) {
          data.imageName =
            data.imageInternalName?.length > 0
              ? data.imageInternalName.at(0)
              : data.imageExternalURL.at(0)
        }
        setSupplier(data)
        const dataAddress = data.address.map((x) => {
          return { label: x.type, value: x.type }
        })
        const objAddress = myAddress
        objAddress.data = dataAddress

        await setMyAddress({ ...objAddress })
        const selected =
          myAddress.data.length > 0 ? myAddress.data[0].value : ''
        setMyAddressSelected(selected)
        setLoader(false)
        setSelectedCombos(data.address, selected)
        setSupplierAddress(data.address)
        setTaxPercentage(
          data.taxPercentage === null ||
            data.taxPercentage === 0 ||
            isNaN(data.taxPercentage)
            ? '0'
            : (data.taxPercentage * 100).toFixed(0),
        )
        setDiscountPercentage(
          data.discountPercentage === null ||
            data.discountPercentage === 0 ||
            isNaN(data.discountPercentage)
            ? '0'
            : (data.discountPercentage * 100).toFixed(0),
        )
        setStandAlone(
          await XSalesData.Suppliers.GetSupplierStandaloneMode(
            SupplierId,
          ),
        )
      })
      .catch((err) => {
        console.log('GetSupplier catch', err)
      })
  }

  useEffect(() => {
    async function fetchDataSupplier() {
      let SupplierId = await XSalesConsumer.getCurrentUser().then(
        (data) => {
          //console.log(data.supplier)
          return data.supplier.id
        },
      )
      setSupplier((prevState) => {
        prevState['id'] = SupplierId
        return prevState
      })
      await GetSupplier(SupplierId)
      await GetSupplierEndpoint(SupplierId)
      await GetSupplierMinimumOrder(SupplierId)

      let promotions = await XSalesData.Suppliers.GetSupplierBanner(
        SupplierId,
      )

      promotions = promotions?.map((data) => {
        const dataImg = XSalesConsumer.getImageUrlList({
          imageInternalName: data.imageInternalName,
          imageExternalURL: data.imageExternalURL,
          supplierId: SupplierId,
          imageType: ImageUrlTypes.Types.banners,
        })
        return {
          ...data,
          imageUrl: dataImg.length > 0 ? dataImg.at(0) : '',
        }
      })

      setPromotions(promotions)
    }
    fetchDataSupplier()
  }, [])

  const handleChangeSupplier = async (e) => {
    const { name, value } = e.target
    let sup = supplier
    if (name === 'imageUrl') {
      if (sup.imageExternalURL?.length > 0) {
        sup.imageExternalURL[0] = value
        sup['imageExternalURL'] = new Array(sup.imageExternalURL)
      } else {
        sup.imageInternalName[0] = value
        sup['imageInternalName'] = new Array(sup.imageInternalName)
      }

      const images = XSalesConsumer.getImageUrlList({
        imageInternalName: sup.imageInternalName,
        imageExternalURL: sup.imageExternalURL,
        supplierId: sup.id,
        imageType: ImageUrlTypes.Types.logos,
      })

      sup['imageUrl'] = images.at(0) //url armada para el componente image
      sup['imageName'] = value
    } else {
      sup[name] = value
    }
    // Object.assign(sup, supplier)

    setSupplier({ ...sup })
  }

  const handleChangeTaxPercentage = (e) => {
    let { value } = e.target
    if (isNaN(value)) {
      value = 0
    }
    setTaxPercentage(value)
  }

  const handleBlurTaxPercentage = (e) => {
    let { value } = e.target
    if (isNaN(value)) {
      value = 0
    }
    if (value > 100) {
      setTaxPercentage(100)
    }
    if (value < 0) {
      setTaxPercentage(0)
    }
  }

  const handleChangeDiscountPercentage = (e) => {
    let { value } = e.target
    if (isNaN(value)) {
      value = 0
    }
    setDiscountPercentage(value)
  }
  const handleBlurDiscountPercentage = (e) => {
    let { value } = e.target
    if (isNaN(value)) {
      value = 0
    }
    if (value > 100) {
      setDiscountPercentage(100)
    }
    if (value < 0) {
      setDiscountPercentage(0)
    }
  }
  const handleChangeSupplierAddress = (e) => {
    const element = e.target

    const obj = { ...supplier }
    if (obj.address.length > 0) {
      obj.address[0][element.name] = element.value
      setSupplier({ ...obj })
    }
  }

  const handleChangeSupplierPhone = (e) => {
    const { name, value } = e.target
    switch (name.toUpperCase()) {
      case 'WORKPHONE':
        let phoneWorkTemp = {}
        phoneWorkTemp.number = value
        phoneWorkTemp.type = phoneWork.type
        phoneWorkTemp.default = phoneWork.default
        setPhoneWork(phoneWorkTemp)
        break
      case 'HOMEPHONE':
        let phoneHomeTemp = {}
        phoneHomeTemp.number = value
        phoneHomeTemp.type = phoneHome.type
        phoneHomeTemp.default = phoneHome.default
        setPhoneHome(phoneHomeTemp)
        break
      case 'MOBILEPHONE':
        let phoneMobileTemp = {}
        phoneMobileTemp.number = value
        phoneMobileTemp.type = phoneMobile.type
        phoneMobileTemp.default = phoneMobile.default
        setPhoneMobile(phoneMobileTemp)
        break
      default:
        break
    }
  }

  //const handleChangeMyAddress = (e) => {}

  const handleChangeCountry = async (e) => {
    setState([])
    setCity([])
    let statesByCountry = await stateList(e.value)
    setState(statesByCountry)

    let sectionDataSupplier = { ...supplier }

    if (!sectionDataSupplier.address) {
      sectionDataSupplier.address = [...address]
    }

    sectionDataSupplier.address[0].country = e.label
    sectionDataSupplier.address[0].countryId = e.value

    sectionDataSupplier.address[0].state = ''
    sectionDataSupplier.address[0].stateId = ''
    sectionDataSupplier.address[0].city = ''
    sectionDataSupplier.address[0].cityId = ''
    setSelectedCountry(e.value)
    setSupplier({ ...sectionDataSupplier })
  }

  const handleChangeState = async (e) => {
    setCity([])
    let cityByState = await cityList(e.value)
    setCity(cityByState)

    let sectionDataSupplier = { ...supplier }
    sectionDataSupplier.address[0].state = e.label
    sectionDataSupplier.address[0].stateId = e.value
    sectionDataSupplier.address[0].city = ''
    sectionDataSupplier.address[0].cityId = ''
    setSupplier({ ...sectionDataSupplier })
    setSelectedState(e.value)
  }

  const handleChangeCity = async (e) => {
    let sectionDataSupplier = { ...supplier }
    sectionDataSupplier.address[0].city = e.label
    sectionDataSupplier.address[0].cityId = e.value
    setSupplier({ ...sectionDataSupplier })
    setSelectedCity(e.value)
  }

  const handleChangeMinimumOrder = async (e) => {
    const { name, value } = e.target
    let minimum = {}
    Object.assign(minimum, minimumOrder)
    minimum[name] = value
    setMinimumOrder(minimum)
  }

  const CancelBasicInfo = async () => {
    setUpdateActionBasicInfo(false)
    await GetSupplier(supplier.id)
  }

  const ChangeBasicInfo = async () => {
    setUpdateActionBasicInfo(false)
    await XSalesData.Suppliers.SetSupplierBasicInfo(
      supplier.id,
      supplier,
    )
      .then((data) => {
        //console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const [UpdateActionBasicInfo, setUpdateActionBasicInfo] =
    useState(false)

  const UpdateActionButtonBasicInfo = () => {
    setUpdateActionBasicInfo(!UpdateActionBasicInfo)
  }

  const CancelBanner = async () => {
    setUpdateActionBanner(false)
    await GetSupplier(supplier.id)
  }

  const ChangeBanner = async () => {
    setUpdateActionBanner(false)
    await XSalesData.Suppliers.SetSupplierBanner(
      supplier.id,
      supplier.imageInternalName,
    )
      .then((data) => {
        //console.log(data)
        const images = XSalesConsumer.getImageUrlList({
          imageInternalName: data.imageInternalName,
          imageExternalURL: data.imageExternalURL,
          supplierId: data.id,
          imageType: ImageUrlTypes.Types.logos,
        })

        data.imageUrl = images.at(0) //url armada para el componente image
        data.imageName =
          data.imageInternalName?.length > 0
            ? data.imageExternalURL.at(0)
            : data.imageInternalName.at(0) // nombre imagen para input
        setSupplier(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const [UpdateActionBanner, setUpdateActionBanner] = useState(false)

  const UpdateActionButtonBanner = () => {
    setUpdateActionBanner(!UpdateActionBanner)
  }

  const CancelSummary = async () => {
    setUpdateActionSummary(false)
    await GetSupplier(supplier.id)
  }

  const ChangeSummary = async () => {
    setUpdateActionSummary(false)
    await XSalesData.Suppliers.SetSupplierSummmary(
      supplier.id,
      supplier.summary,
    )
      .then((data) => {
        //console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const [UpdateActionSummary, setUpdateActionSummary] =
    useState(false)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')

  const UpdateActionButtonSummary = () => {
    setUpdateActionSummary(!UpdateActionSummary)
  }

  const CancelAddress = async () => {
    setUpdateActionAddress(false)
    await GetSupplier(supplier.id)
  }

  const ChangeAddress = async () => {
    setUpdateActionAddress(false)
    await XSalesData.Suppliers.SetSupplierAddress(
      supplier.id,
      supplier.address,
    )
      .then((data) => {
        //console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })

    setSelectedCombos(supplier.address, myAddressSelected)
  }

  const [UpdateActionAddress, setUpdateActionAddress] =
    useState(false)

  /*const searchCountryCode = (country) => {
        if (country === null || country === undefined || country === "")
            return ""
        let cou = csc.getAllCountries().filter((x) => x.name === country)[0]
        return { label: cou.name, value: cou.id }
    }*/

  /*const searchStateCode = (countrycode, state) => {
        if (
            countrycode === null ||
            countrycode === undefined ||
            countrycode === "" ||
            state === null ||
            state === undefined ||
            state === ""
        )
            return ""
        return csc
            .getStatesOfCountry(countrycode)
            .filter((x) => x.name === state)[0]
    }*/

  // const searchCityCode = (country, state, city) => {
  //     if (country === null || country === undefined || country === '' || state === null || state === undefined || state === '' || city === null || city === undefined || city === '')
  //         return ''
  //     return csc.getCitiesOfState(searchStateCode(state,))
  // }

  const UpdateActionButtonAddress = async () => {
    setUpdateActionAddress(!UpdateActionAddress)
    /* if (address.country === "") {
            //no hay creada una direccion
        } else {
            //ya hay una direccion creada
            //asignar valor default
            /* let countryTemp = csc.getAllCountries().map((item) => {
                return { label: item.name, value: item.id }
            }) */
    //setCountry(countryTemp)

    /*setCountryShow(searchCountryCode(address.country))
            let stateTemp = await stateList(countryShow.value)
            setState(stateTemp)
            setStateShow(searchStateCode(countryShow.value, address.state))
            //lenar listas

            let cityTemp = await cityList(stateShow.id)
            setCity(cityTemp)
        }*/
  }

  /*  const CancelPhone = async () => {
        setUpdateActionPhone(false)
        await GetSupplier(supplier.id)
    } */

  /*  const ChangePhone = async () => {
        setUpdateActionPhone(false)
        let listPhones = []
        listPhones.push(phoneHome)
        listPhones.push(phoneMobile)
        listPhones.push(phoneWork)

        await XSalesData.Suppliers.SetSupplierPhone(supplier.id, listPhones)
            .then((data) => {
                //console.log(data)
            })
            .catch((err) => {
                console.log(err)
            })
    } */

  //const [UpdateActionPhone, setUpdateActionPhone] = useState(false)

  /* const UpdateActionButtonPhone = () => {
        setUpdateActionPhone(!UpdateActionPhone)
    } */

  const CancelContactInfo = async () => {
    setUpdateActionContactInfo(false)
    await GetSupplier(supplier.id)
  }

  const ChangeContactInfo = async () => {
    setUpdateActionContactInfo(false)
    await XSalesData.Suppliers.SetSupplierContactInfo(
      supplier.id,
      supplier.contactName,
      supplier.contactEmail,
    )
      .then((data) => {
        //console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })

    //setUpdateActionPhone(false)
    let listPhones = []
    listPhones.push(phoneHome)
    listPhones.push(phoneMobile)
    listPhones.push(phoneWork)

    await XSalesData.Suppliers.SetSupplierPhone(
      supplier.id,
      listPhones,
    )
      .then((data) => {
        //console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const [UpdateActionContactInfo, setUpdateActionContactInfo] =
    useState(false)

  const UpdateActionButtonContactInfo = () => {
    setUpdateActionContactInfo(!UpdateActionContactInfo)
  }

  const handleChangeDefaultPhone = (e) => {
    const { id } = e.target
    let radioDefaultPhone = defaultPhone
    let rdp = radioDefaultPhone.map((item) => {
      let obj = item
      obj.value = obj.Key === id ? true : false
      return obj
    })

    setDefaultPhone(rdp)

    let phoneWorkTemp = {}
    phoneWorkTemp.number = phoneWork.number
    phoneWorkTemp.type = phoneWork.type
    phoneWorkTemp.default = id.toUpperCase() === 'WORK' ? true : false
    setPhoneWork(phoneWorkTemp)

    let phoneHomeTemp = {}
    phoneHomeTemp.number = phoneHome.number
    phoneHomeTemp.type = phoneHome.type
    phoneHomeTemp.default = id.toUpperCase() === 'HOME' ? true : false
    setPhoneHome(phoneHomeTemp)

    let phoneMobileTemp = {}
    phoneMobileTemp.number = phoneMobile.number
    phoneMobileTemp.type = phoneMobile.type
    phoneMobileTemp.default =
      id.toUpperCase() === 'MOBILE' ? true : false
    setPhoneMobile(phoneMobileTemp)
  }

  const CancelMinimumOrder = async () => {
    setUpdateActionMinimumOrder(false)
    await GetSupplierMinimumOrder(supplier.id)
  }

  const CancelPercentege = async () => {
    await GetSupplier(supplier.id)
    setUpdateActionPercentage(false)
  }

  const ChangePercentage = async () => {
    setUpdateActionPercentage(false)

    let taxPercentageNew = 0
    let discountPercentageNew = 0

    if (isNaN(taxPercentage)) {
      setSupplier({ taxPercentage: 0 })
    }
    if (isNaN(discountPercentage)) {
      setSupplier({ discountPercentage: 0 })
    }
    if (taxPercentage > 0) {
      taxPercentageNew = taxPercentage / 100
    }
    if (discountPercentage > 0) {
      discountPercentageNew = discountPercentage / 100
    }
    //taxPercentage= round(taxPercentage /100, 2)

    await XSalesData.Suppliers.SetSupplierPercentage(
      supplier.id,
      taxPercentageNew,
      discountPercentageNew,
    )
      .then((data) => {
        //console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const ChangeMinimumOrder = async () => {
    setUpdateActionMinimumOrder(false)
    //TODO: test logic for save Minimum Order Config in SupplierSetup table
    if (isNaN(minimumOrder.orderMinAmount)) {
      minimumOrder.orderMinAmount = 0
    }
    await XSalesData.Suppliers.SetSupplierMinimumOrder(
      supplier.id,
      minimumOrder.orderMinAmount,
      minimumOrder.orderMinRule,
    )
      .then((data) => {
        //console.log(data)
        XSalesConsumer.fireMessage(
          'MySupplier',
          'Data saved sucessfully',
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const [UpdateActionMinimumOrder, setUpdateActionMinimumOrder] =
    useState(false)
  const [UpdateActionPercentage, setUpdateActionPercentage] =
    useState(false)

  const UpdateActionButtonMinimumOrder = () => {
    setUpdateActionMinimumOrder(!UpdateActionMinimumOrder)
  }
  const UpdateActionButtonPercentage = () => {
    setUpdateActionPercentage(!UpdateActionPercentage)
  }

  const [authType] = useState(listAuthType)

  const imgOnError = (e) => {
    // let target = e.target
    const _supplier = {
      ...supplier,
      imageUrl: imgCDN.SupplierBannerDefault,
    }
    setSupplier(_supplier)
  }

  const setSelectedCombos = async (
    supplierAddress,
    addressType = 'MAIN',
  ) => {
    if (supplierAddress) {
      const address = supplierAddress.filter(
        (x) => x.type === addressType,
      )
      if (address.length > 0) {
        const countryId = countryList.filter(
          (x) => x.value === address[0].countryId,
        )
        const selectedCountry =
          countryId.length > 0 ? countryId[0].value : ''

        const statesByCountry = await stateList(selectedCountry)
        await setState(statesByCountry)
        const stateId = statesByCountry.filter(
          (x) => x.value === address[0].stateId,
        )
        const selectedState =
          stateId.length > 0 ? stateId[0].value : ''

        const cityTemp = await cityList(selectedState)
        await setCity(cityTemp)
        const cityId = cityTemp.filter(
          (x) => x.value === address[0].cityId,
        )
        const selectedCity = cityId.length > 0 ? cityId[0].value : ''

        setTimeout(() => {
          setSelectedCountry(selectedCountry)
          setSelectedState(selectedState)
          setSelectedCity(selectedCity)
          const checkElement = document.getElementById(
            'check__default_address',
          )
          if (checkElement) checkElement.checked = address[0].default
        }, 0)
      }
    }
  }

  const handleOnChangeCheckDefault = (e) => {
    const element = e.target
    const obj = address
    obj.default = element.checked
    setAddress(obj)
    if (element.checked) {
      const index = supplier.address.findIndex(
        (x) => x.type === obj.type,
      )
      if (index !== -1) supplier.address[index].default = true

      supplier.address.forEach((o) => {
        if (o.type !== obj.type) {
          obj.default = false
        }
      })
    }
  }

  const handleChangeTypeAddress = async (e) => {
    const elementStreet1 = document.getElementById('street1')
    const elementStreet2 = document.getElementById('street2')
    const elementStreet3 = document.getElementById('street3')
    const elementZipCode = document.getElementById('zipcode')
    const newAddressData = supplierAddress.filter(
      (x) => x.type === e.value,
    )
    setSelectedCombos(supplierAddress, e.value)
    if (newAddressData.length > 0) {
      const objAddress = address
      objAddress.type = e.value
      objAddress.street1 = newAddressData[0].street1
      objAddress.street2 = newAddressData[0].street2
      objAddress.street3 = newAddressData[0].street3
      objAddress.zipcode = newAddressData[0].zipcode
      elementStreet1.value = objAddress.street1
      elementStreet2.value = objAddress.street2
      elementStreet3.value = objAddress.street3
      elementZipCode.value = objAddress.zipcode
      setAddress(objAddress)
      setMyAddressSelected(e.value)
    }
  }

  const ChangePaymentMethods = async (paymentMethods) => {
    const objMethods = paymentMethods.map((x) => ({
      key: x.objkey,
      value: x.objvalue,
      valueLanguage1: x.objvalueLanguage1,
      valueLanguage2: x.objvalueLanguage2,
      valueLanguage3: x.objvalueLanguage3,
      valueLanguage4: x.objvalueLanguage4,
      default: x.objdefault,
    }))

    await XSalesData.Suppliers.SetSupplierPaymentMethods(
      supplier.id,
      objMethods,
    )
      .then((data) => {
        setSupplier((prevState) => ({
          ...prevState,
          paymentMethods: objMethods,
        }))
        XSalesConsumer.fireMessage(
          'Payment Methods',
          'Data saved sucessfully',
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      {loader ? (
        <LoaderWindow />
      ) : (
        <Tab options={options}>
          <TabCell show={true} id="general">
            <SectionEditable
              label={I18n.get('lbllogo')}
              labelEdit={I18n.get('lbllogo')}
              Action={'UPDATE'}
              UpdateAction={UpdateActionButtonBanner}
              CancelAction={CancelBanner}
              SaveAction={ChangeBanner}
              Collapse={false}
              lblbtnSave={I18n.get('lblSave')}
              lblbtnCancel={I18n.get('lblCancel')}
            >
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12">
                    {UpdateActionBanner ? (
                      <InputText
                        id="imageUrl"
                        name="imageUrl"
                        maxLength="300"
                        label={I18n.get('lblimageUrl')}
                        value={supplier.imageName}
                        onChange={handleChangeSupplier}
                        isEditable={UpdateActionBanner}
                      />
                    ) : (
                      supplier.imageUrl && (
                        <Image
                          src={supplier.imageUrl}
                          className="img__consumer__banner"
                          alt="Banner URL"
                          OnError={imgOnError}
                          figcaption={I18n.get('lblImageRequeriment')}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </SectionEditable>
            <SectionEditable
              label={I18n.get('lblBasicInfo')}
              labelEdit={I18n.get('lblBasicInfo')}
              Action={'UPDATE'}
              UpdateAction={UpdateActionButtonBasicInfo}
              CancelAction={CancelBasicInfo}
              SaveAction={ChangeBasicInfo}
              Collapse={false}
              lblbtnSave={I18n.get('lblSave')}
              lblbtnCancel={I18n.get('lblCancel')}
            >
              <div className="col-12">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <InputText
                      id="businessName"
                      name="businessName"
                      maxLength="60"
                      label={I18n.get('lblbusinessname')}
                      value={supplier.businessName}
                      onChange={handleChangeSupplier}
                      isEditable={UpdateActionBasicInfo}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <InputText
                      id="name"
                      name="name"
                      maxLength="60"
                      label={I18n.get('lblcomercialname')}
                      value={supplier.name}
                      onChange={handleChangeSupplier}
                      isEditable={UpdateActionBasicInfo}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <InputText
                      id="taxID"
                      name="taxID"
                      maxLength="12"
                      label={I18n.get('lblSupTaxID')}
                      value={supplier.taxID}
                      onChange={handleChangeSupplier}
                      isEditable={UpdateActionBasicInfo}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <InputText
                      id="website"
                      name="website"
                      maxLength="60"
                      label={I18n.get('lblwebsite')}
                      value={supplier.website}
                      onChange={handleChangeSupplier}
                      isEditable={UpdateActionBasicInfo}
                    />
                  </div>
                </div>
              </div>
            </SectionEditable>
            <SectionEditable
              label={I18n.get('lblSummary')}
              labelEdit={I18n.get('lblSummary')}
              Action={'UPDATE'}
              UpdateAction={UpdateActionButtonSummary}
              CancelAction={CancelSummary}
              SaveAction={ChangeSummary}
              Collapse={false}
              lblbtnSave={I18n.get('lblSave')}
              lblbtnCancel={I18n.get('lblCancel')}
            >
              <div className="col-sm-12">
                <TextArea
                  rows={3}
                  id="summary"
                  name="summary"
                  maxLength="300"
                  style={{ height: heightTextArea }}
                  label={I18n.get('lblSummary')}
                  value={supplier.summary}
                  onChange={handleChangeSupplier}
                  isEditable={UpdateActionSummary}
                />
              </div>
            </SectionEditable>
            <SectionEditable
              label={I18n.get('lblContactInformation')}
              labelEdit={I18n.get('lblPhone')}
              Action={'UPDATE'}
              UpdateAction={UpdateActionButtonContactInfo}
              CancelAction={CancelContactInfo}
              SaveAction={ChangeContactInfo}
              Collapse={false}
              lblbtnSave={I18n.get('lblSave')}
              lblbtnCancel={I18n.get('lblCancel')}
            >
              <div className="col-sm-12 col-md-12">
                <div className="row">
                  <div className="col-sm-12 col-md-8">
                    <InputText
                      id="workPhone"
                      name="workPhone"
                      label={I18n.get('lblofficeNumber')}
                      maxLength="30"
                      value={phoneWork.number}
                      onChange={handleChangeSupplierPhone}
                      isEditable={UpdateActionContactInfo}
                    />
                    <InputText
                      id="mobilePhone"
                      name="mobilePhone"
                      label={I18n.get('lblmobileNumber')}
                      maxLength="30"
                      value={phoneMobile.number}
                      onChange={handleChangeSupplierPhone}
                      isEditable={UpdateActionContactInfo}
                    />
                    <InputText
                      id="homePhone"
                      name="homePhone"
                      label={I18n.get('lblhomeNumber')}
                      maxLength="30"
                      value={phoneHome.number}
                      onChange={handleChangeSupplierPhone}
                      isEditable={UpdateActionContactInfo}
                    />

                    <InputText
                      id="contactName"
                      name="contactName"
                      maxLength="20"
                      label={I18n.get('lblcontactName')}
                      value={supplier.contactName}
                      onChange={handleChangeSupplier}
                      isEditable={UpdateActionContactInfo}
                    />
                    <InputText
                      id="contactEmail"
                      name="contactEmail"
                      maxLength="60"
                      label={I18n.get('lblcontactEmail')}
                      value={supplier.contactEmail}
                      onChange={handleChangeSupplier}
                      isEditable={UpdateActionContactInfo}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="col-12">
                      <RadioButton
                        id="workDefault"
                        Name="typePhone"
                        Title={I18n.get('lblDefaultPhone')}
                        Items={defaultPhone}
                        handleChange={handleChangeDefaultPhone}
                        isEditable={UpdateActionContactInfo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SectionEditable>

            <div className="row ml-4">
              <div className="col-sm-6 col-md-2">
                <SelectCombo
                  id="adreesss"
                  name="adreesss"
                  label={I18n.get('lbladdresss')}
                  labelDefault="Address"
                  options={myAddress.data}
                  //defaultValue={""}
                  value={myAddressSelected}
                  optionSelected={myAddressSelected}
                  onChange={handleChangeTypeAddress}
                  isEditable={true}
                />
              </div>
            </div>
            <SectionEditable
              label={I18n.get('lblAddress')}
              labelDefault="Address"
              labelEdit="lblEditAddress"
              labelEditDefault="Address"
              Action={'UPDATE'}
              UpdateAction={UpdateActionButtonAddress}
              CancelAction={CancelAddress}
              SaveAction={ChangeAddress}
              Collapse={false}
            >
              <div className="col-12">
                <div className="row">
                  <div className="col-sm-12 mb-2">
                    <CheckBox
                      id="check__default_address"
                      onChange={handleOnChangeCheckDefault}
                      isEditable={UpdateActionAddress}
                      label={I18n.get('lbldefaultaddress')}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <SelectCombo
                      id="country"
                      name="country"
                      label={I18n.get('lblCountry')}
                      labelDefault="Country"
                      options={countryList}
                      //defaultValue={""}
                      value={selectedCountry}
                      optionSelected={selectedCountry}
                      onChange={handleChangeCountry}
                      isEditable={UpdateActionAddress}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <SelectCombo
                      id="state"
                      name="state"
                      label={I18n.get('lblstate')}
                      labelDefault="State"
                      options={state}
                      //defaultValue={""}
                      value={selectedState}
                      optionSelected={selectedState}
                      onChange={handleChangeState}
                      isEditable={UpdateActionAddress}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <SelectCombo
                      id="city"
                      name="city"
                      label={I18n.get('lblcity')}
                      labelDefault="City"
                      options={city}
                      //defaultValue={""}
                      value={selectedCity}
                      optionSelected={selectedCity}
                      onChange={handleChangeCity}
                      isEditable={UpdateActionAddress}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3 mt-4">
                    <InputText
                      id="zipcode"
                      name="zipcode"
                      maxLength="20"
                      label={I18n.get('lblzipcode')}
                      labelDefault="Zip Code"
                      value={address.zipcode}
                      onChange={handleChangeSupplierAddress}
                      isEditable={UpdateActionAddress}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4  mt-3">
                    <TextArea
                      id="street1"
                      rows={4}
                      name="street1"
                      maxLength="150"
                      label={I18n.get('lblstreet1')}
                      labelDefault="Street 1"
                      value={address.street1}
                      onChange={handleChangeSupplierAddress}
                      isEditable={UpdateActionAddress}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4  mt-3">
                    <TextArea
                      id="street2"
                      rows={4}
                      name="street2"
                      maxLength="150"
                      label={I18n.get('lblstreet2')}
                      labelDefault="Street 2"
                      value={address.street2}
                      onChange={handleChangeSupplierAddress}
                      isEditable={UpdateActionAddress}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4  mt-3">
                    <TextArea
                      id="street3"
                      rows={4}
                      name="street3"
                      maxLength="150"
                      label={I18n.get('lblstreet3')}
                      labelDefault="Street 3"
                      value={address.street3}
                      onChange={handleChangeSupplierAddress}
                      isEditable={UpdateActionAddress}
                    />
                  </div>
                </div>
              </div>
            </SectionEditable>
          </TabCell>

          <TabCell show={false} id="preference">
            <div className="col-sm-12">
              <SectionEditable
                label={I18n.get('lblMinimumOrderConfig')}
                labelEdit={I18n.get('lblMinimumOrderConfig')}
                Action={'UPDATE'}
                UpdateAction={UpdateActionButtonMinimumOrder}
                CancelAction={CancelMinimumOrder}
                SaveAction={ChangeMinimumOrder}
                Collapse={false}
                lblbtnSave={I18n.get('lblSave')}
                lblbtnCancel={I18n.get('lblCancel')}
              >
                <div className="col-sm-12 col-md-12">
                  <InputNumber
                    id={'orderMinAmount'}
                    name={'orderMinAmount'}
                    label={I18n.get('lblorderMinAmount')}
                    value={
                      isNaN(minimumOrder.orderMinAmount)
                        ? 0
                        : minimumOrder.orderMinAmount
                    }
                    onChange={handleChangeMinimumOrder}
                    isEditable={UpdateActionMinimumOrder}
                    max={100.0}
                    min={0.0}
                  />
                </div>
              </SectionEditable>
            </div>

            <SeparateTop>
              <SectionEditable
                label={I18n.get('lblConfigurePercentage')}
                labelEdit={I18n.get('lblConfigurePercentage')}
                Action={'UPDATE'}
                UpdateAction={UpdateActionButtonPercentage}
                CancelAction={CancelPercentege}
                SaveAction={ChangePercentage}
                Collapse={false}
                lblbtnSave={I18n.get('lblSave')}
                lblbtnCancel={I18n.get('lblCancel')}
              >
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <InputNumber
                      id={'taxPercentage'}
                      name={'taxPercentage'}
                      label={I18n.get('lblpercentageTax')}
                      value={isNaN(taxPercentage) ? 0 : taxPercentage}
                      onChange={handleChangeTaxPercentage}
                      isEditable={UpdateActionPercentage}
                      onBlur={handleBlurTaxPercentage}
                      max={100.0}
                      min={0.0}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <InputNumber
                      id={'discountPercentage'}
                      name={'discountPercentage'}
                      label={I18n.get('lblpercentageDiscount')}
                      value={
                        isNaN(discountPercentage)
                          ? 0
                          : discountPercentage
                      }
                      onChange={handleChangeDiscountPercentage}
                      onBlur={handleBlurDiscountPercentage}
                      isEditable={UpdateActionPercentage}
                      max={100.0}
                      min={0.0}
                    />
                  </div>
                </div>
              </SectionEditable>

              {standAlone !== 'true' && (
                <SectionEditable
                  label={I18n.get('lblConfigERPEndpoint')}
                  labelEdit={I18n.get('lblConfigERPEndpoint')}
                  Action={'UPDATE'}
                  UpdateAction={UpdateActionButtonSupplierEndpoint}
                  CancelAction={CancelSupplierEndpoint}
                  SaveAction={ChangeSupplierEndpoint}
                  Collapse={false}
                  lblbtnSave={I18n.get('lblSave')}
                  lblbtnCancel={I18n.get('lblCancel')}
                >
                  <div className="col-sm-12">
                    <TextArea
                      rows={'2'}
                      id="endpointSupplierApi"
                      name="endpointSupplierApi"
                      maxLength="300"
                      //  style={{height:heightTextArea}}
                      label={I18n.get('lblendpointSupplierApi')}
                      value={supplierEnpoint.endpointSupplierApi}
                      onChange={handleChangeSupplierEndpoint}
                      isEditable={UpdateActionSupplierEndpoint}
                    />
                  </div>

                  <div className="col-sm-12  mb-4">
                    <SelectCombo
                      id="authType"
                      name="authType"
                      label={I18n.get('lblendpointAuthType')}
                      options={authType}
                      optionSelected={getAuthType(
                        supplierEnpoint.endpointAuthType,
                      )}
                      //defaultValue={""}
                      value={getAuthType(
                        supplierEnpoint.endpointAuthType,
                      )}
                      isEditable={UpdateActionSupplierEndpoint}
                      onChange={handleChangeEndpointAuthType}
                    />
                  </div>

                  {supplierEnpoint.endpointAuthType === '1' && (
                    <div className="col-sm-12 mt-4">
                      <TextArea
                        rows={'1'}
                        id="endpointSupplierApiKey"
                        name="endpointSupplierApiKey"
                        maxLength="300"
                        label={I18n.get('lblendpointSupplierApiKey')}
                        value={supplierEnpoint.endpointSupplierApiKey}
                        onChange={handleChangeSupplierEndpoint}
                        isEditable={UpdateActionSupplierEndpoint}
                      />
                    </div>
                  )}
                  {supplierEnpoint.endpointAuthType === '2' && (
                    <div>
                      <div className="col-sm-12">
                        <InputText
                          id="endpointAuthBasicUser"
                          name="endpointAuthBasicUser"
                          maxLength="32"
                          label={I18n.get('lblendpointAuthBasicUser')}
                          value={
                            supplierEnpoint.endpointAuthBasicUser
                          }
                          onChange={handleChangeSupplierEndpoint}
                          isEditable={UpdateActionSupplierEndpoint}
                        />
                      </div>
                      <div className="col-sm-12">
                        <InputPass
                          id="endpointAuthBasicPass"
                          name="endpointAuthBasicPass"
                          maxLength="128"
                          label={I18n.get('lblendpointAuthBasicPass')}
                          value={
                            supplierEnpoint.endpointAuthBasicPass
                          }
                          onChange={handleChangeSupplierEndpoint}
                          isEditable={UpdateActionSupplierEndpoint}
                        />
                      </div>
                    </div>
                  )}
                </SectionEditable>
              )}
              <div className="row ml-12">
                <div className="col-sm-12 col-md-12">
                  <CurrencyConfig
                    disabledEdit={false}
                    id={supplier.id}
                    modeAdmin={false}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <PaymentMethodConfig
                    paymentMethods={supplier.paymentMethods}
                    ChangePaymentMethods={ChangePaymentMethods}
                  />
                </div>
              </div>
            </SeparateTop>
          </TabCell>

          <TabCell show={false} id="promotionalsbanners">
            {Array.isArray(promotions) && (
              <Carrousel
                dataBanners={
                  Array.isArray(promotions) ? promotions : []
                }
                lblactive={I18n.get('lblactivebanner')}
                lblinactive={I18n.get('lblinactivebanner')}
                lblsince={I18n.get('lblsince')}
                lbluntil={I18n.get('lbluntil')}
                width="328px"
                height="122px"
              />
            )}
          </TabCell>
        </Tab>
      )}
      {/*   <pre>
                    {
                        JSON.stringify(supplier, null, 2)
                    }
                </pre>   */}
    </>
  )
}
