import React, { Fragment, useState, useEffect } from "react"
import {
    InputText,
    SectionEditable,
    SelectCombo,
    //CheckBox,
    Switch,
    Warning,
} from "xsales-components"
// import InputText from "../../componentsConsumer/InputText"
// import EditableData from "../../componentsConsumer/SecurableObject/editableData"
// import SelectCombo from "../../componentsConsumer/SelectCombo"
// import Checkbox from "../../componentsConsumer/Checkbox"
import { XSalesConsumer } from "../../Utils/Utils"
import { XSalesData } from "../../DataQuery/XSalesData"
import { I18n } from "@aws-amplify/core"
import { useParams } from "react-router-dom"
import styled from "styled-components"

// import Warning from "../../componentsConsumer/Warning"
//import Switch from "../../componentsConsumer/Switch"

const listStatus = () => {
    return [
        { label: I18n.get("lblDRAFT"), value: "DRAFT" },
        { label: I18n.get("lblCONFIGURING"), value: "CONFIGURING" },
        { label: I18n.get("lblACTIVE"), value: "ACTIVE" },
        { label: I18n.get("lblINACTIVE"), value: "INACTIVE" },
        { label: I18n.get("lblDELETED"), value: "DELETED" },
        { label: I18n.get("lblREJECTED"), value: "REJECTED" },
    ]
}

export default function AdditionalInfo(props) {
    let { id } = useParams()
    const supplierId = id
    let languajeSelected = []

    let [lng1, SetLng1] = useState(false)
    let [lng2, SetLng2] = useState(false)
    let [lng3, SetLng3] = useState(false)
    let [lng4, SetLng4] = useState(false)

    useEffect(() => {
        getLanguageSupplier(id)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    async function getLanguageSupplier(id) {
        let languageConfigured = await XSalesData.Suppliers.GetSupplierLanguage(
            id
        )

        SetLng1(String(languageConfigured).includes("1") ? true : false)
        SetLng2(String(languageConfigured).includes("2") ? true : false)
        SetLng3(String(languageConfigured).includes("3") ? true : false)
        SetLng4(String(languageConfigured).includes("4") ? true : false)

        props.Data.language1 = lng1
        props.Data.language2 = lng2
        props.Data.language3 = lng3
        props.Data.language4 = lng4
    }

    const allowEdit =
        props.AllowEdit === null || props.AllowEdit === undefined
            ? true
            : props.AllowEdit

    //isXSM methods
    const CancelisXSM = (e) => {
        props.ChangeisXSM(e)
        setUpdateChangeisXSM(false)
        props.RefreshData()
    }

    const ChangeisXSM = (e) => {
        props.ChangeisXSM(e)
        props.ChangeStandAlone(e)
        props.ChangeOrdenManagement(e)
        props.ChangePromotions(e)
        props.ChangeListPrice(e)
        props.ChangeAdminProducts(e)
        props.ChangeMinimunStock(e)

        props.ChangeBarcodeScanner(e)
        props.ChangeCustomerCatalog(e)
        props.ChangeActiveProductDiscount(e)
        props.ChangeActiveSameOrderClass(e)
        props.ChangeActiveShippingAddress(e)
        props.ChangeActivePaymentMethod(e)

        //ChangeLengueage(e)
        ChangeLengueage(e)
        setUpdateChangeisXSM(false)
    }

    const [updateChangeisXSM, setUpdateChangeisXSM] = useState(false)

    const UpdateActionButtonisXSM = () => {
        setUpdateChangeisXSM(!updateChangeisXSM)
    }
    //isXSM methods

    //Language
    //-------------------------------
    const [updateChangeLanguage, setupdateChangeLanguage] = useState(false)

    /* const UpdateActionButtonLanguage = () => {
        setupdateChangeLanguage(!updateChangeLanguage)
    } */

    /*  const CancelSaveLanguage = (e) => {
        setupdateChangeLanguage(false)
        props.RefreshData()
    } */

    const ChangeLengueage = (e) => {
        setupdateChangeLanguage(!updateChangeLanguage)
        languajeSelected = []
        if (lng1) languajeSelected.push("1")
        if (lng2) languajeSelected.push("2")
        if (lng3) languajeSelected.push("3")
        if (lng4) languajeSelected.push("4")

        let languaje = languajeSelected.join()
        props.ChangeLenguage(languaje)
    }

    //-------------------------------

    //standAlone
    //-------------------------------
    const updateChangeisStandAlone = false

    /* const CancelStandAlone = (e) => {
        setUpdateChangeisStandAlone(false)
        props.RefreshData()
    } */
    /* const UpdateActionButtonStandAlone = () => {
        setUpdateChangeisStandAlone(!updateChangeisStandAlone)
    } */

    /* const ChangeStandAlone = async (e) => {
        props.ChangeStandAlone(e)
        setUpdateChangeisStandAlone(false)
    } */

    //-------------------------------
    //

    //standAlone
    //-------------------------------
    const updateChangeOrderManagement = false

    /* const CancelOrderManagement = (e) => {
        setUpdateChangeOrderManagement(false)
        props.RefreshData()
    }
    const UpdateActionButtonOrderManagement = () => {
        setUpdateChangeOrderManagement(!updateChangeOrderManagement)
    }

    const ChangeOrdenManagement = async (e) => {
        props.ChangeOrdenManagement(e)
        setUpdateChangeOrderManagement(false)
    } */

    //-------------------------------
    //

    //Promotions
    //-------------------------------
    const updatePromotions = false
    /*  const CancelPromotions = (e) => {
        setUpdatePromotions(false)
        props.RefreshData()
    }
    const UpdateActionButtonPromotions = () => {
        setUpdatePromotions(!updatePromotions)
    }

    const ChangePromotions = async (e) => {
        props.ChangePromotions(e)
        setUpdatePromotions(false)
    } */

    //-------------------------------
    //

    //List Price
    //-------------------------------
    const updateListPrice = false
    /* const CancelListPrice = (e) => {
        setUpdateListPrice(false)
        props.RefreshData()
    }
    const UpdateActionButtonListPrice = () => {
        setUpdateListPrice(!updateListPrice)
    }

    const ChangeListPrice = async (e) => {
        
        props.ChangeListPrice(e)
        setUpdateListPrice(false)
    } */

    //-------------------------------
    //
    //AdminProducts
    //-------------------------------
    const updateAdminProducts = false
    /* const CancelAdminProducts = (e) => {
        setUpdateAdminProducts(false)
        props.RefreshData()
    }
    const UpdateActionButtonAdminProducts = () => {
        setUpdateAdminProducts(!updateAdminProducts)
    }

    const ChangeAdminProducts = async (e) => {
        
        props.ChangeAdminProducts(e)
        setUpdateAdminProducts(false)
    } */

    //-------------------------------
    //

    //AdminProducts
    //-------------------------------
    const updateMinimunStock = false
    /*  const CancelMinimunStock = (e) => {
        setUpdateMinimunStock(false)
        props.RefreshData()
    }
    

    const ChangeMinimunStock = async (e) => {
        
        props.ChangeMinimunStock(e)
        setUpdateMinimunStock(false)
    }

*/
    const updateBarcodeScanner = false
    /* const CancelBarcodeScanner = (e) => {
        setUpdateBarcodeScanner(false)
        props.RefreshData()
    } */

    /*  const ChangeBarcodeScanner = async (e) => {
        
        props.ChangeBarcodeScanner(e)
        setUpdateBarcodeScanner(false)
    }  */

    const updateCustomerCatalog = false
    /* const CancelCustomerCatalog = (e) => {
        setUpdateCustomerCatalog(false)
        props.RefreshData()
    } */

    /*  const ChangeCustomerCtalog = async (e) => {
        
        props.ChangeCustomerCatalog(e)
        setUpdateCustomerCatalog(false)
    } */

    const updateActiveProductDiscount = false
    /* const CancelActiveProductDiscount = (e) => {
        setUpdateActiveProductDiscount(false)
        props.RefreshData()
    } */

    /*  const ChangeActiveProductDiscount = async (e) => {
        
        props.ChangeActiveProductDiscount(e)
        setUpdateActiveProductDiscount(false)
    } */

    const updateActiveSameOrderClass = false
    /*  const CancelActiveSameOrderClass = (e) => {
        setUpdateActiveSameOrderClass(false)
        props.RefreshData()
    } */

    /* const ChangeActiveSameOrderClass = async (e) => {
        
        props.ChangeActiveSameOrderClass(e)
        setUpdateActiveSameOrderClass(false)
    } */

    const updateActiveShippingAddress = false
    /* const CancelActiveShippingAddress = (e) => {
        setUpdateActiveShippingAddress(false)
        props.RefreshData()
    } */
    /* const ChangeActiveShippingAddress = async (e) => {
        props.ChangeActiveShippingAddress(e)
        setUpdateActiveShippingAddress(false)
    } */

    const [updateActivePaymentMethod] =  useState(false)
    // const CancelActivePaymentMethod = (e) => {
    //     setUpdateActivePaymentMethod(false)
    //     props.RefreshData()
    // }
    // const ChangeActivePaymentMethod = async (e) => {
    //     props.ChangeActivePaymentMethod(e)
    //     setUpdateActivePaymentMethod(false)
    // }

    //Change status
    const CancelAssignStatus = (e) => {
        props.CancelAssignStatus(e)
        setUpdateChangeStatus(false)
        props.RefreshData()
    }

    const ChangeAssignStatus = (e) => {
        props.ChangeAssignStatus(e)
        setUpdateChangeStatus(false)
    }

    const [updateChangeStatus, setUpdateChangeStatus] = useState(false)

    const UpdateActionButtonStatus = () => {
        setUpdateChangeStatus(!updateChangeStatus)
    }
    //Change status

    /* eslint-disable no-unused-vars */
    const [statusSupplierConsumer, setStatusSupplierConsumer] =
        useState(listStatus)
    /* eslint-enable no-unused-vars */

    const [emailConsumer, SetEmailConsumer] = useState("")
    const handleChangeEmailConsumer = async (e) => {
        const { value } = e.target
        SetEmailConsumer(value)
    }

    const handleChangeLanguage = (e) => {
        switch (e.target.name) {
            case "1":
                SetLng1(!lng1)
                break
            case "2":
                SetLng2(!lng2)
                break
            case "3":
                SetLng3(!lng3)
                break
            case "4":
                SetLng4(!lng4)
                break
            default:
                break
        }
    }

    return (
        <Fragment>
            <div className="row"> 
                <div className="col-sm-12 col-md-4">
                    <InputText
                        id="name"
                        name="name"
                        label={I18n.get("lblSupName")}
                        labelDefault="Name"
                        maxLength="20"
                        value={props.Data.name}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-sm-12 col-md-4">
                    <InputText
                        id="status"
                        name="status"
                        label={I18n.get("lblSupStatus")}
                        labelDefault="Status"
                        maxLength="20"
                        value={I18n.get("lbl" + props.Data.status)}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-10">
                    <SectionEditable
                        label={I18n.get("lblAssignStatus")}
                        labelEdit={I18n.get("lblAssignStatus")}
                        Action={"UPDATE"}
                        UpdateAction={UpdateActionButtonStatus}
                        CancelAction={CancelAssignStatus}
                        SaveAction={ChangeAssignStatus}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <InputText
                                    id="createdAt"
                                    name="createdAt"
                                    label={I18n.get("lblCreated")}
                                    maxLength="30"
                                    value={XSalesConsumer.formatShortDatetime(
                                        props.Data.createdAt
                                    )}
                                    onChange={props.handleChange}
                                    isEditable={allowEdit}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <InputText
                                    id="approvedAt"
                                    name="approvedAt"
                                    label={I18n.get("lblapproved")}
                                    maxLength="20"
                                    value={XSalesConsumer.formatShortDatetime(
                                        props.Data.approvedAt
                                    )}
                                    onChange={props.handleChange}
                                    isEditable={allowEdit}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12  col-md-4">
                                <SelectCombo
                                    id="statusSel"
                                    name="statusSel"
                                    label={I18n.get("lblSupStatus")}
                                    options={statusSupplierConsumer}
                                    value={props.Data.status}
                                    onChange={props.handleChange}
                                    isEditable={updateChangeStatus}
                                />
                            </div>
                        </div>
                    </SectionEditable>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-10">
                    <SectionEditable
                        label={I18n.get("lblSupplierXSM")}
                        labelEdit={I18n.get("lblSupplierXSM")}
                        Action={"UPDATE"}
                        UpdateAction={UpdateActionButtonisXSM}
                        CancelAction={CancelisXSM}
                        SaveAction={ChangeisXSM}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <Switch
                                    id="isXSM"
                                    name="isXSM"
                                    value={props.Data.isXSM}
                                    onChange={props.handleChangeStateXSM}
                                    label={I18n.get("lblisXSM")}
                                    isEditable={updateChangeisXSM}
                                    disabled={!updateChangeisXSM}
                                />
                            </div>
                            <Warning>{I18n.get("lblXSMSupplierInfo")}</Warning>

                            {props.Data.isXSM === true && (
                                <div className="col-sm-12">
                                    <hr />
                                    <Fragment>
                                        <label
                                            htmlFor="emailConsumer"
                                            className="label-control"
                                        >
                                            {I18n.get("lblSetEmailConsumer")}
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="emailConsumer"
                                                name="emailConsumer"
                                                type="text"
                                                className="form-control"
                                                value={emailConsumer}
                                                onChange={
                                                    handleChangeEmailConsumer
                                                }
                                            />
                                            <span className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="mb-2 mr-2 btn-transition btn btn-outline-primary bounds-button"
                                                    onClick={async () => {
                                                        if (
                                                            emailConsumer !==
                                                                undefined &&
                                                            emailConsumer !==
                                                                null &&
                                                            emailConsumer.trim() !==
                                                                ""
                                                        ) {
                                                            await XSalesData.Consumer.AddConsumerDemo(
                                                                supplierId,
                                                                emailConsumer
                                                            )
                                                                .then(
                                                                    (data) => {
                                                                        /*console.log(
                                                                            "XSalesData.Consumer.AddConsumerDemo All Ok. Saved"
                                                                        )*/
                                                                    }
                                                                )
                                                                .catch(
                                                                    (err) => {
                                                                        const codeError =
                                                                            err
                                                                        switch (
                                                                            codeError
                                                                        ) {
                                                                            case "ErrorSupplierConsumerEmailNotExist":
                                                                                XSalesConsumer.fireMessage(
                                                                                    I18n.get(
                                                                                        "lblConsumerDemo"
                                                                                    ),
                                                                                    I18n.get(
                                                                                        "lblErrorSupplierConsumerEmailNotExist"
                                                                                    )
                                                                                )
                                                                                break
                                                                            case "ErrorSupplierConsumerExist":
                                                                                XSalesConsumer.fireMessage(
                                                                                    I18n.get(
                                                                                        "lblConsumerDemo"
                                                                                    ),
                                                                                    I18n.get(
                                                                                        "lblErrorSupplierConsumerExist"
                                                                                    )
                                                                                )
                                                                                break
                                                                            case "ErrorSupplierConsumerCatch":
                                                                                XSalesConsumer.fireMessage(
                                                                                    I18n.get(
                                                                                        "lblConsumerDemo"
                                                                                    ),
                                                                                    I18n.get(
                                                                                        "lblErrorSupplierConsumerCatch"
                                                                                    )
                                                                                )
                                                                                break

                                                                            default:
                                                                                break
                                                                        }
                                                                        SetEmailConsumer(
                                                                            ""
                                                                        )
                                                                    }
                                                                )
                                                        }
                                                    }}
                                                >
                                                    {I18n.get(
                                                        "lblAddConsumerDemo"
                                                    )}
                                                </button>
                                            </span>
                                        </div>
                                        <p className="text_information">
                                            {I18n.get(
                                                "lblSetEmailConsumerInfo"
                                            )}
                                        </p>
                                    </Fragment>
                                </div>
                            )}
                        </div>
                        <ContainerSetup>
                            <div className="row">
                                <div className="col-sm-12  col-md-6">
                                    <Switch
                                        id="isStandAlone"
                                        name="isStandAlone"
                                        onChange={
                                            props.handleChangeStateStandAlone
                                        }
                                        value={props.Data.standAlone}
                                        label={I18n.get("lblEnableStandAlone")}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateChangeisStandAlone}
                                    />
                                    <p className="text_information">
                                        {I18n.get("lblhelpmodestandalone")}
                                    </p>
                                </div>
                                <div className="col-sm-12  col-md-6">
                                    <Switch
                                        id="OrderManagement"
                                        name="OrderManagement"
                                        onChange={
                                            props.handleChangeOrderManagement
                                        }
                                        value={props.Data.orderManagement}
                                        label={I18n.get(
                                            "lblEnableOrderManagement"
                                        )}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateChangeOrderManagement}
                                    />
                                    <p className="text_information">
                                        {I18n.get("lblhelpordermanagement")}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12  col-md-6">
                                    <Switch
                                        id="isPromotions"
                                        name="isPromotions"
                                        onChange={props.handleChangePromotions}
                                        value={props.Data.ActivePromotions}
                                        label={I18n.get("lblEnablePromotions")}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updatePromotions}
                                    />
                                    <p className="text_information">
                                        {I18n.get("lblhelppromotions")}
                                    </p>
                                </div>
                                <div className="col-sm-12  col-md-6">
                                    <Switch
                                        id="isAdminProducts"
                                        name="isAdminProducts"
                                        onChange={
                                            props.handleChangeAdminProducts
                                        }
                                        value={props.Data.ActiveAdminProducts}
                                        label={I18n.get(
                                            "lblEnableAdminProducts"
                                        )}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateAdminProducts}
                                    />
                                    <p className="text_information">
                                        {I18n.get("lbladminproducts")}
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12  col-md-6">
                                    <Switch
                                        id="isListPrice"
                                        name="isListPrice"
                                        onChange={props.handleChangeListPrice}
                                        value={props.Data.ActiveListPrice}
                                        label={I18n.get("lblEnableListPrice")}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateListPrice}
                                    />
                                    <p className="text_information">
                                        {I18n.get("lblhelplistprices")}
                                    </p>
                                </div>
                                <div className="col-sm-12  col-md-6">
                                    <Switch
                                        id="MinimunStock"
                                        name="MinimunStock"
                                        onChange={
                                            props.handleChangeMinimunStock
                                        }
                                        value={props.Data.ActiveMinimunStock}
                                        label={I18n.get(
                                            "lblEnableMinimunStock"
                                        )}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateMinimunStock}
                                    />
                                    <p className="text_information">
                                        {I18n.get("lblhelpminimunstock")}
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12  col-md-6">
                                    <Switch
                                        id="isBarcodeScanner"
                                        name="isBarcodeScanner"
                                        onChange={
                                            props.handleChangeBarcodeScanner
                                        }
                                        value={props.Data.ActivebarcodeScanner}
                                        label={I18n.get(
                                            "lblEnableBarcodeScanner"
                                        )}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateBarcodeScanner}
                                    />
                                    <p className="text_information">
                                        {I18n.get("lblhelpbarCodescanner")}
                                    </p>
                                </div>
                                <div className="col-sm-12  col-md-6">
                                    <Switch
                                        id="isCheckCustomerCatalog"
                                        name="isCheckCustomerCatalog"
                                        onChange={
                                            props.handleChangeCheckCustomerCatalog
                                        }
                                        value={props.Data.ActiveCustomerCatalog}
                                        label={I18n.get(
                                            "lblEnableCustomerCatalog"
                                        )}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateCustomerCatalog}
                                    />
                                    <p className="text_information">
                                        {I18n.get("Customercatalog")}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12  col-md-6">
                                    <Switch
                                        id="isActiveProductDiscount"
                                        name="isActiveProductDiscount"
                                        onChange={
                                            props.handleChangeProductDiscount
                                        }
                                        value={props.Data.ActiveProductDiscount}
                                        label={I18n.get(
                                            "lblEnableProductDiscount"
                                        )}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateActiveProductDiscount}
                                    />
                                    <p className="text_information">
                                        {I18n.get("Percentajediscounts")}
                                    </p>
                                </div>
                                <div className="col-sm-12  col-md-6">
                                    <Switch
                                        id="isActiveSameOrderClass"
                                        name="isActiveSameOrderClass"
                                        onChange={
                                            props.handleChangeSameOrderClass
                                        }
                                        value={props.Data.ActiveSameOrderClass}
                                        label={I18n.get(
                                            "lblEnableSameOrderClass"
                                        )}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateActiveSameOrderClass}
                                    />
                                    <p className="text_information">
                                        {I18n.get("lblhelpsocproducts")}
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12  col-md-6">
                                    <Switch
                                        id="isActiveShippingAddress"
                                        name="isActiveShippingAddress"
                                        onChange={
                                            props.handleChangeShippingAddress
                                        }
                                        value={props.Data.ActiveShippingAddress}
                                        label={I18n.get(
                                            "lblEnableShippingAddress"
                                        )}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateActiveShippingAddress}
                                    />
                                    <p className="text_information">
                                        {I18n.get("lblhelpshippingaddress")}
                                    </p>
                                </div>
                                <div className="col-sm-12  col-md-4">
                                    <Switch
                                        id="isActivePaymentMethod"
                                        name="isActivePaymentMethod"
                                        onChange={
                                            props.handleChangePaymentMethod
                                        }
                                        value={props.Data.ActivePaymentMethod}
                                        label={I18n.get(
                                            "lblEnablePaymentMethod"
                                        )}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateActivePaymentMethod}
                                    />
                                    <p className="text_information">
                                        {I18n.get("lblhelpPaymentMethod")}
                                    </p>
                                </div>
                            </div>
                        </ContainerSetup>
                        <ContainerSetup>
                            <div className="row">
                                <div className="col-sm-12  col-md-8">
                                    <p className="text_information">
                                        {I18n.get("msgLanguages")}
                                    </p>
                                </div>
                            </div>
                            {/* <Switch
                                    id="Language1"
                                    disabled={!updateChangeLanguage}
                                    isEditable={updateChangeLanguage}
                                    value={lng1}
                                    name="1"
                                    onChange={handleChangeLanguage}
                                    label={I18n.get("lbllanguage1")}
                                /> */}
                            <div className="row">
                                <div className="col-sm-12  col-md-2">
                                    <Switch
                                        id="Language2"
                                        name="2"
                                        onChange={handleChangeLanguage}
                                        value={lng2}
                                        label={I18n.get("lbllanguage2")}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateChangeLanguage}
                                    />
                                </div>
                                <div className="col-sm-12  col-md-2">
                                    <Switch
                                        id="Language3"
                                        name="3"
                                        onChange={handleChangeLanguage}
                                        value={lng3}
                                        label={I18n.get("lbllanguage3")}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateChangeLanguage}
                                    />
                                </div>
                                <div className="col-sm-12  col-md-2">
                                    <Switch
                                        id="Language4"
                                        name="4"
                                        onChange={handleChangeLanguage}
                                        value={lng4}
                                        label={I18n.get("lbllanguage4")}
                                        isEditable={updateChangeisXSM}
                                        disabled={!updateChangeLanguage}
                                    />
                                </div>
                            </div>
                        </ContainerSetup>
                    </SectionEditable>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-sm-12 col-md-10">
                    <SectionEditable
                        label={I18n.get("lblModeStandAlone")}
                        labelEdit={I18n.get("lblModeStandAlone")}
                        Action={"UPDATE"}
                        UpdateAction={UpdateActionButtonStandAlone}
                        CancelAction={CancelStandAlone}
                        SaveAction={ChangeStandAlone}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <Switch
                                    id="isStandAlone"
                                    name="isStandAlone"
                                    onChange={props.handleChangeStateStandAlone}
                                    value={props.Data.standAlone}
                                    label={I18n.get("lblEnableStandAlone")}
                                    isEditable={updateChangeisStandAlone}
                                    disabled={!updateChangeisStandAlone}
                                />
                            </div>

                            <p className="text_information">
                                {I18n.get("msgEnableStandAlone")}
                            </p>
                        </div>
                    </SectionEditable>
                </div>
            </div> */}
            {/* <div className="row">
                <div className="col-sm-12 col-md-10">
                    <SectionEditable
                        label={I18n.get("lblActiveOrderManagement")}
                        labelEdit={I18n.get("lblActiveOrderManagement")}
                        Action={"UPDATE"}
                        UpdateAction={UpdateActionButtonOrderManagement}
                        CancelAction={CancelOrderManagement}
                        SaveAction={ChangeOrdenManagement}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <Switch
                                    id="OrderManagement"
                                    name="OrderManagement"
                                    onChange={props.handleChangeOrderManagement}
                                    value={props.Data.orderManagement}
                                    label={I18n.get("lblEnableStandAlone")}
                                    isEditable={updateChangeOrderManagement}
                                    disabled={!updateChangeOrderManagement}
                                />
                            </div>

                            
                        </div>
                    </SectionEditable>
                </div>
            </div> */}
            {/* <div className="row">
                <div className="col-sm-12 col-md-10">
                    <SectionEditable
                        label={I18n.get("lblActiveBanners")}
                        labelEdit={I18n.get("lblActiveBanners")}
                        Action={"UPDATE"}
                        UpdateAction={UpdateActionButtonPromotions}
                        CancelAction={CancelPromotions}
                        SaveAction={ChangePromotions}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <Switch
                                    id="isPromotions"
                                    name="isPromotions"
                                    onChange={props.handleChangePromotions}
                                    value={props.Data.ActivePromotions}
                                    label={I18n.get("lblEnableStandAlone")}
                                    isEditable={updatePromotions}
                                    disabled={!updatePromotions}
                                />
                            </div>

                            
                        </div>
                    </SectionEditable>
                </div>
            </div> */}
            {/*  <div className="row">
                <div className="col-sm-12 col-md-10">
                    <SectionEditable
                        label={I18n.get("lblActiveListPrice")}
                        labelEdit={I18n.get("lblActiveListPrice")}
                        Action={"UPDATE"}
                        UpdateAction={UpdateActionButtonListPrice}
                        CancelAction={CancelListPrice}
                        SaveAction={ChangeListPrice}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <Switch
                                    id="isListPrice"
                                    name="isListPrice"
                                    onChange={props.handleChangeListPrice}
                                    value={props.Data.ActiveListPrice}
                                    label={I18n.get("lblEnableStandAlone")}
                                    isEditable={updateListPrice}
                                    disabled={!updateListPrice}
                                />
                            </div>

                            
                        </div>
                    </SectionEditable>
                </div>
            </div> */}

            {/* <div className="row">
                <div className="col-sm-12 col-md-10">
                    <SectionEditable
                        label={I18n.get("lblActiveAdminProducts")}
                        labelEdit={I18n.get("lblActiveAdminProducts")}
                        Action={"UPDATE"}
                        UpdateAction={UpdateActionButtonAdminProducts}
                        CancelAction={CancelAdminProducts}
                        SaveAction={ChangeAdminProducts}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <Switch
                                    id="isAdminProducts"
                                    name="isAdminProducts"
                                    onChange={props.handleChangeAdminProducts}
                                    value={props.Data.ActiveAdminProducts}
                                    label={I18n.get("lblEnableStandAlone")}
                                    isEditable={updateAdminProducts}
                                    disabled={!updateAdminProducts}
                                />
                            </div>

                            
                        </div>
                    </SectionEditable>
                </div>
            </div> */}

            {/* <div className="row">
                <div className="col-sm-12 col-md-10">
                    <SectionEditable
                        label={I18n.get("lblLanguages")}
                        labelEdit={I18n.get("lblLanguages")}
                        Action={"UPDATE"}
                        UpdateAction={UpdateActionButtonLanguage}
                        CancelAction={CancelSaveLanguage}
                        SaveAction={ChangeLengueage}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                {/* <Switch
                                    id="Language1"
                                    disabled={!updateChangeLanguage}
                                    isEditable={updateChangeLanguage}
                                    value={lng1}
                                    name="1"
                                    onChange={handleChangeLanguage}
                                    label={I18n.get("lbllanguage1")}
                                /> 
                                <Switch
                                    id="Language2"
                                    name="2"
                                    onChange={handleChangeLanguage}
                                    value={lng2}
                                    label={I18n.get("lbllanguage2")}
                                    isEditable={updateChangeLanguage}
                                    disabled={!updateChangeLanguage}
                                />

                                <Switch
                                    id="Language3"
                                    name="3"
                                    onChange={handleChangeLanguage}
                                    value={lng3}
                                    label={I18n.get("lbllanguage3")}
                                    isEditable={updateChangeLanguage}
                                    disabled={!updateChangeLanguage}
                                />

                                <Switch
                                    id="Language4"
                                    name="4"
                                    onChange={handleChangeLanguage}
                                    value={lng4}
                                    label={I18n.get("lbllanguage4")}
                                    isEditable={updateChangeLanguage}
                                    disabled={!updateChangeLanguage}
                                />

                                <p className="text_information">
                                    {I18n.get("msgLanguages")}
                                </p>
                            </div>
                        </div>
                    </SectionEditable>
                </div>
            </div> */}
            {/* <div><pre>{JSON.stringify(props.Data, null, 2) }</pre></div>
             */}
        </Fragment>
    )
}
const ContainerSetup = styled.div`
    margin-top: 20px;
`
