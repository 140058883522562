import React from "react"
import { Auth } from "aws-amplify"
//import { DataStore } from "@aws-amplify/datastore"
import {  useHistory } from 'react-router-dom'
//import { MyAppContext } from '../context'

export default function Header_SignOut(props) {
    const { push } = useHistory();
    //const { setLanguageApp } = useContext(MyAppContext)
    const logout = async () => {
        await Auth.signOut()
            .then(async (data) => {
                localStorage.clear()
                //setLanguageApp()
                // await DataStore.clear()  //No se va a borrar la data por temas de performance
               push("/Logout")
                // window.location.href = "/Logout"
            
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <li className="nav-item">
            <a
                className="nav-link"
                data-widget="control-sidebar"
                data-slide="true"
                href="/#"
                role="button"
                onClick={logout}
            >
                <i className="fas fa-sign-out-alt" />
            </a>
        </li>
    )
}
