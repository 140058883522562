import React from "react"
import { InputText, Button  } from 'xsales-components'

import { I18n } from "@aws-amplify/core"
import { Auth } from "aws-amplify"
import { XSalesConsumer } from "./../../Utils/Utils"

// import Container from "./Container"
// import Card from "./Card"

import PreferenceDefault from "../../configConsumer/preferenceDefault.json"
import ImageCDN from "../../configConsumer/imagesDefaultCDN.json"

class Validation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sectionData: {
                email: "",
                code: "",
            },
        }
    }

    componentDidMount() {
        let email = this.props.match.params.email
        let sd = this.state.sectionData
        sd.email = email
        this.setState({ sectionData: sd })
    }

    handleChange = (e) => {
        const { name, value } = e.target
        let sd = this.state.sectionData
        sd[name] = value
        this.setState({
            sectionData: sd,
        })
    }

    sendToRegistration = async () => {
        let sd = this.state.sectionData
        await Auth.confirmSignUp(sd.email.trim().toLowerCase(), sd.code)
            .then((data) => {
                if (data === "SUCCESS") {
                    XSalesConsumer.fireMessage("lblLogin", "msdCodeVerified")
                    // window.location.href = "/Registration"
                    this.props.history.push("/Registration")
                } else {
                    XSalesConsumer.fireMessage(
                        "Login",
                        "msgErrorValidatingCode"
                    )
                   // window.location.href = "/Login"
                    this.props.history.push("/Login")
                }
            })
            .catch((err) => {
                XSalesConsumer.fireMessage("lblLogin", "msgErrorOcurred")
            })
    }

    handleSubmit = e => {
        e.preventDefault();
        this.sendToRegistration();
    }

    render() {
        return (
            // <Container
            //     linkXSalesConsumer={PreferenceDefault.XSalesConsumerWEB}
            //     XSalesConsumer={ImageCDN.XSalesConsumerWebLogo}
            // >
            //     <Card label={I18n.get("lblValidationCode")}>
            //         <InputText
            //             id="code"
            //             name="code"
            //             maxLength="6"
            //             label={I18n.get("lblCode")}
            //             value={this.state.sectionData.code}
            //             onChange={this.handleChange}
            //         />
            //         <ToRight>
            //             <Button
            //                 id="send"
            //                 name="send"
            //                 label={I18n.get("lblValidateCode")}
            //                 onClick={() => this.sendToRegistration()}
            //             />
            //         </ToRight>
            //     </Card>
            // </Container>
            <>
                <section className="login__container">
                    <div className="login__box">
                        <a href={PreferenceDefault.XSalesConsumerWEB} target="_blank" rel="noopener noreferrer">
                            <img src={ImageCDN.XSalesConsumerWebLogo} alt="Logo Consumer" />
                        </a>
                        <h4>{I18n.get("lblValidationCode")}</h4>
                        <form className="login__form" onSubmit={this.handleSubmit}>
                                <InputText
                                        id="code"
                                        name="code"
                                        maxLength="6"
                                        label={I18n.get("lblCode")}
                                        value={this.state.sectionData.code}
                                        onChange={this.handleChange}
                                        requiredField={true}
                                    />
                            <div className="login__buttons">
                                     <Button
                                        id="send"
                                        name="send"
                                        type="submit"
                                        size="md"
                                        color="primary"
                                        label={I18n.get("lblValidateCode")}
                                    />
                            </div>
                        </form>
                    </div>
                </section>
            </>
        )
    }
}

export default Validation
