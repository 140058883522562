import React, { Fragment, useState } from "react"
import {InputNumber, RadioButton, Modal,  SectionEditable, InputText, TextArea, SelectCombo, Confirm, InputTag, useToast, Toastify } from 'xsales-components'
import { I18n } from "@aws-amplify/core"
import loadingLine from "../../assets/img/loadingLine.gif"
import { XSalesConsumer } from "../../Utils/Utils"
import { XSalesData } from "../../DataQuery/XSalesData"

import CurrencyConfigConsumer from "./CurrencyConfigConsumer"
//import { createTrue } from "typescript"

const listStatus = () => {
    return [
        { label: I18n.get("lblAPPROVED"), value: "APPROVED" },
        { label: I18n.get("lblREJECTED"), value: "REJECTED" },
        { label: I18n.get("lblDELETED"), value: "DELETED" },
    ]
}

export default function AdditionalInfo(props) {

    const { notify } = useToast()
    const allowEdit =
        props.AllowEdit === null || props.AllowEdit === undefined
            ? true
            : props.AllowEdit

    const CancelAssignStatus = (e) => {
        props.CancelAssignStatus(e)
        setUpdateChangeStatus(false)
        props.RefreshData()
    }

    const ChangeAssignStatus = (e) => {
        props.ChangeAssignStatus(e)
        setUpdateChangeStatus(false)
    }

    const [updateChangeStatus, setUpdateChangeStatus] = useState(false)

    const UpdateActionButtonStatus = () => {
        setUpdateChangeStatus(!updateChangeStatus)
    }

    /* eslint-disable no-unused-vars */
    const [statusSupplierConsumer, setStatusSupplierConsumer] = useState(
        listStatus
    )
    /* eslint-enable no-unused-vars */
    const [UpdateActionPercentage, setUpdateActionPercentage] = useState(false)
    const [updateGroups, setUpdateGroups] = useState(true)
    const [updateAssignMinimumConfig, setUpdateAssignMinimumConfig] = useState(false)
    const [UpdateOrderCatalog, setUpdateOrderCatalog] = useState(false)
    const [noAllowSave, setNoAllowSave] = useState(false)
    const [modalContent, setModalContent] = useState("")
    const [updateAssignErpCode, setUpdateAssignErpCode] = useState(false)
    const [modalValidationERPCode, setModalValidationERPCode] = useState({
        name: "",
        businessName: "",
        consumerIdERP: "",
        error: "",
    })
    const [statusValidationERPCode, setStatusValidationERPCode] = useState("invalid")
    //const [showModal, setShowModal] = useState(false)
    const [actionCancelEdit, setActionCancelEdit] = useState(false)
    //const [modeStandAlone, setModeStandAlone] = useState(false)
    const UpdateActionButtonMinimumConfig = () => {
        setUpdateAssignMinimumConfig(!updateAssignMinimumConfig)
    }

    const CancelMinimumConfig = () => {
        props.CancelMinimumConfig()
        setUpdateAssignMinimumConfig(false)
    }

    const CancelPercentege = async () => {
        setUpdateActionPercentage(false)
    }

    const ChangePercentage = ()=>
    {
        setUpdateActionPercentage(false)
        props.ChangePercentage()
    }

    const ChangeMinimumConfig = () => {
        props.ChangeMinimumConfig()
        props.ChangeNetoBrutoConfig()
        setUpdateAssignMinimumConfig(false)
    }

    const handleChangeNetoBruto = (e) => {
        props.handleChangeNetoBruto(e)
    }
    const UpdateActionButtonErpCode = () => {
        setUpdateAssignErpCode(!updateAssignErpCode)
    }

    const UpdateActionButtonPercentage = () => {
        setUpdateActionPercentage(!UpdateActionPercentage)
    }

    const CancelAssignERPCode = (e) => {
        props.CancelAssignERPCode(e)
        setUpdateAssignErpCode(false)
        props.RefreshData()
    }


    


    const ChangeAssignERPCode = (e) => {
        const { erpCode } = props.Data.sectionData
        if (!erpCode) {
            notify("error", I18n.get('msgValidateErpCode'))
            //XSalesConsumer.fireMessage("msgFillErpCode", "msgValidateErpCode")
            CancelAssignERPCode(e)
            props.RefreshData()
            return
        }

        if (statusValidationERPCode === "valid") {
            props.ChangeAssignERPCode(e)
            setUpdateAssignErpCode(false)
        } else {
            notify("error", I18n.get('msgValidateErpCode'))
            //XSalesConsumer.fireMessage("msgFillErpCode", "msgValidateErpCode")
            CancelAssignERPCode(e)
            props.RefreshData()
            return
        }
    }

    const handleValidationERPCode = async (consumerIdERP) => {
        try {
            setNoAllowSave(true)
            //setShowModal(false)
            if (
                consumerIdERP === undefined ||
                consumerIdERP === null ||
                consumerIdERP === ""
            ) {
                notify("error", I18n.get('lblAssignErpCode'))
                //XSalesConsumer.fireMessage("lblAssignErpCode", "msgFillErpCode")
                window.$("#modalValidateERP").modal("toggle")
                return
            }
            setModalContent("")
            //setShowModal(true)
    
            
            const { supplier } = await XSalesConsumer.getCurrentUser()
            
            const data =  await XSalesData.Suppliers.GetIfERPCodeIsValid(supplier.id,consumerIdERP)
            await XSalesData.Suppliers.GetIfERPCodeIsValid(
                supplier.id,
                consumerIdERP
            )
    
            setModalValidationERPCode((prevState) => {
                prevState.name = data.name
                prevState.businessName = data.businessName
                prevState.consumerIdERP = data.customerId
                return prevState
            })
            let mc = modalValidationERPCode
            let content =
                mc.error !== "" &&
                mc.name === "" &&
                mc.businessName === "" &&
                mc.consumerIdERP === "" ? (
                    <p>{mc.error}</p>
                ) : (
                    <Fragment>
                        <p style={{ fontSize: "1.25rem" }}>
                            {mc.consumerIdERP} {` - `} {mc.businessName}
                        </p>
                        <p style={{ fontSize: "1rem" }}>{mc.name}</p>
                    </Fragment>
                )
            setNoAllowSave(mc.error !== "" ? true : false)
            setModalContent(content)
        } catch (error) {
            setModalValidationERPCode((prevState) => {
                prevState.error = new Error(error).message
                return prevState
            })
            setNoAllowSave(true)
            setModalContent(modalValidationERPCode.error)
        }
    }

    const UpdateActionButtonOrderCatalog = () => {
        setUpdateOrderCatalog(!UpdateOrderCatalog)
    }
    const UpdateActionGroups = () => {
        setUpdateGroups(!updateGroups)
    }

    const CancelAssignOrderCatalog = (e) => {
        setActionCancelEdit(true)
        setUpdateOrderCatalog(false)
        props.RefreshData()
    }

    const CancelGroups = (e) => {
        setUpdateGroups(!updateGroups) 
        props.RefreshData()
    }

    const ChangeGroups = async (e) =>{
            const newGroup1 = props.Data.sectionData.supplierGroup1.map(x => x.value)
            const newGroup2 = props.Data.sectionData.supplierGroup2.map(x => x.value)
            const newGroup3 = props.Data.sectionData.supplierGroup3.map(x => x.value)
            
            const group1 = props.Data.sectionData.group1
            const group2 = props.Data.sectionData.group2
            const group3 = props.Data.sectionData.group3

            await XSalesData.Suppliers.SetSupplierrGroups(props.Data.sectionData.supplierId,newGroup1,newGroup2,newGroup3)  
            await XSalesData.Suppliers.SetConsumerSupplierGroups(props.Data.supplierConsumerId,group1.value,group2.value,group3.value) 
         
    }


    const ChangeAssignOrderCatalog = async (e) => {
        const SelectedCurrency = props.Data.sectionData.SelectedCurrency
        const SelectedSymbol =props.Data.sectionData.SelectedSymbol
        const SupplierCurrency = props.Data.sectionData.SupplierCurrency
        
                if (SelectedCurrency !== "" && SelectedCurrency !== SupplierCurrency)
                {
                if (await Confirm({

                    tittle :  I18n.get("lblConfirmTittle"),
                    message: I18n.get("msgChangeCatalogExclusive"),
                    confirmText:  I18n.get("lblConfirmText"),
                    cancelText:  I18n.get("lblcancelText"),
                    confirmColor: "primary",
                    cancelColor: "link text-danger"

                })) 
                    {
                        props.handleChangeTypeExclusive()
                    }
                }
               
                if (SelectedCurrency === "")
                {
                    props.Data.sectionData.SelectedCurrency = null
                }
                if (SelectedSymbol === "")
                {
                    props.Data.sectionData.SelectedSymbol = null
                }
                
      
        props.ChangeCurrencyConsumer()
        props.ChangeCatalogConfig(e)
        //props.ChangeNetoBrutoConfig(e)
        setUpdateOrderCatalog(false) 
        
    }
    const handleChangeCatalogType = (e) => {
        const SupplierCurrency = props.Data.sectionData.SupplierCurrency
        const SelectedCurrency = props.Data.sectionData.SelectedCurrency
 
            
            if(SelectedCurrency !== "" && SupplierCurrency !== SelectedCurrency )
            {
                const { id } = e.target

                if(id === "merge")
                {
                    XSalesConsumer.fireMessage("", I18n.get("msgCatalogExclusive"))
                    props.handleChangeTypeExclusive()
                }
                 else 
                {
                    props.handleChangeCatalogType(e)
                } 
            }
            else
            {
                props.handleChangeCatalogType(e)
            } 
    }

    const handleClickValidate = () => {
        if (props.Data.sectionData.erpCode) {
            window
                .$("#modalValidateERP")
                .modal("toggle")
            handleValidationERPCode(
                props.Data.sectionData.erpCode
            )
        } else {
            XSalesConsumer.fireMessage(
                I18n.get(
                    "lblAssignErpCode"
                ),
                I18n.get(
                    "msgFillErpCode"
                )
            )
            return
        }
    }

    return (
        <Fragment>
            <div className="row">

                <div className="col-sm-12 col-md-8">
                    <InputText
                        id="name"
                        name="name"
                        label={I18n.get("lblconname")}
                        maxLength="20"
                        value={props.Data.sectionData.name}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-sm-12 col-md-4">
                    <InputText
                        id="status"
                        name="status"
                        label={I18n.get("lblconStatus")}
                        maxLength="20"
                        value={
                            listStatus().find(
                                (o) => o.value === props.Data.sectionData.status
                            ) &&
                            listStatus().find(
                                (o) => o.value === props.Data.sectionData.status
                            ).label
                        }
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-sm-12 col-md-8">
                    <InputText
                        id="requestedBy"
                        name="requestedBy"
                        label={I18n.get("lblrequestedBy")}
                        maxLength="20"
                        value={props.Data.sectionData.requestedBy}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-sm-12 col-md-4">
                    <InputText
                        id="requestedOn"
                        name="requestedOn"
                        label={I18n.get("lblrequestedon")}
                        maxLength="20"
                        value={XSalesConsumer.formatShortDate(props.Data.sectionData.requestedOn)}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-sm-12 col-md-12">
                    <TextArea
                        rows="3"
                        id="requestComments"
                        name="requestComments"
                        label={I18n.get("lblrequestComments")}
                        maxLength="200"
                        style={{ height: "160px" }}
                        value={props.Data.sectionData.requestComments}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                {props.Data.standaloneMode ==="false" &&(
                <div className="col-sm-12 col-md-6">
                <SectionEditable
                    label={I18n.get("lblConfigcodeNetoBrtuto")}
                    labelEdit={I18n.get("lblAssignErpCode")}
                    Action={"UPDATE"}
                    UpdateAction={UpdateActionButtonErpCode}
                    CancelAction={CancelAssignERPCode}
                    SaveAction={ChangeAssignERPCode}
                    Collapse={false}
                    lblbtnSave={I18n.get("lblSave")}
                    lblbtnCancel={I18n.get("lblCancel")}
                >
                    <div className="row">
                        <Modal
                            id="modalValidateERP"
                            title={I18n.get("lblValidateERP")}
                            lblbtnCancel={I18n.get("lblCancel")}
                            lblbtnSave={I18n.get("lblAccept")}
                            handleCancel={(e) => {
                                CancelAssignERPCode(e)
                                setUpdateAssignErpCode(true)
                                setStatusValidationERPCode("invalid")
                                setModalValidationERPCode({
                                    name: "",
                                    businessName: "",
                                    consumerIdERP: "",
                                    error: "",
                                })
                            }}
                            handleSave={() => {
                                setStatusValidationERPCode("valid")
                                setUpdateAssignErpCode(true)
                                setModalValidationERPCode({
                                    name: "",
                                    businessName: "",
                                    consumerIdERP: "",
                                    error: "",
                                })
                            }}
                            noAllowSave={noAllowSave}
                        >
                            {modalContent === "" ? (
                                <img
                                    src={loadingLine}
                                    alt="loading line gif"
                                    style={{
                                        display: "flex",
                                        margin: "0 auto",
                                    }}
                                />
                            ) : (
                                modalContent
                            )}
                        </Modal>
                        {updateAssignErpCode ? (
                         <div className="col-sm-12">
                            <Fragment>
                                <label
                                    htmlFor="erpCode"
                                    className="label-control"
                                >
                                    {I18n.get("lblSetERPCpde")}
                                </label>
                                <div className="input-group">
                                    <input
                                        id="erpCode"
                                        name="erpCode"
                                        type="text"
                                        className="form-control"
                                        value={props.Data.sectionData.erpCode}
                                        onChange={props.handleChange}
                                    />
                                    <span className="input-group-append">
                                        <button
                                            type="button"
                                            className="mb-2 mr-2 btn-transition btn btn-outline-primary bounds-button"
                                            onClick={handleClickValidate}
                                        >
                                            {I18n.get("lblValidate")}
                                        </button>
                                    </span>
                                </div>
                                
                            </Fragment>
                            </div>
                        ) : (
                            <div className="col-sm-12">
                                <InputText
                                    id="erpCode"
                                    name="erpCode"
                                    label={I18n.get("lblSetERPCpde")}
                                    maxLength="20"
                                    value={props.Data.sectionData.erpCode}
                                    onChange={props.handleChange}
                                    isEditable={updateAssignErpCode}
                                    onBlur={handleValidationERPCode}
                                />
                            </div>
                        )}
                    </div>
                </SectionEditable>
                </div>
                )}
                <div className="col-sm-12 col-md-12">
                    <SectionEditable
                        label={I18n.get("lblAssignStatus")}
                        labelEdit={I18n.get("lblAssignStatus")}
                        Action={"UPDATE"}
                        UpdateAction={UpdateActionButtonStatus}
                        CancelAction={CancelAssignStatus}
                        SaveAction={ChangeAssignStatus}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <SelectCombo
                                    id="statusSel"
                                    name="statusSel"
                                    label={I18n.get("lblconStatus")}
                                    options={statusSupplierConsumer}
                                    defaultValue={""}
                                    optionSelected={props.Data.sectionData.status}
                                    value={props.Data.sectionData.status}
                                    onChange={props.handleChange}
                                    isEditable={updateChangeStatus}
                                />

                                

                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-12 col-md-12">
                                <TextArea
                                    rows="3"
                                    id="responseComments"
                                    name="responseComments"
                                    label={I18n.get("lblresponseComments")}
                                    maxLength="200"
                                    value={props.Data.sectionData.responseComments}
                                    onChange={props.handleChange}
                                    isEditable={updateChangeStatus}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className=" col-sm-12 col-md-7">
                                <InputText
                                    id="responsedBy"
                                    name="responsedBy"
                                    label={I18n.get("lblresponsedBy")}
                                    maxLength="30"
                                    value={props.Data.sectionData.responsedBy}
                                    onChange={props.handleChange}
                                    isEditable={allowEdit}
                                />
                            </div>
                            <div className="col-sm-12 col-md-5">
                                <InputText
                                    id="responsedOn"
                                    name="responsedOn"
                                    label={I18n.get("lblresponsedon")}
                                    maxLength="20"
                                    value={XSalesConsumer.formatShortDate(props.Data.sectionData.responsedOn)}
                                    onChange={props.handleChange}
                                    isEditable={allowEdit}
                                />
                            </div>
                        </div>
                    </SectionEditable>
                </div>

                <div className="col-sm-12 col-md-6">
                <SectionEditable
                    label={I18n.get("lblMinimumOrderConfig")}
                    labelEdit={I18n.get("lblMinimumOrderConfig")}
                    Action={"UPDATE"}
                    UpdateAction={UpdateActionButtonMinimumConfig}
                    CancelAction={CancelMinimumConfig}
                    SaveAction={ChangeMinimumConfig}
                    Collapse={false}
                    lblbtnSave={I18n.get("lblSave")}
                    lblbtnCancel={I18n.get("lblCancel")}
                >
                    <div className="row">
                    <div className="col-md-6">
                        <InputNumber
                            id="orderMinAmount"
                            name="orderMinAmount"
                            label={I18n.get("lblorderMinAmount")}
                            maxLength="20"
                            value={
                                props.Data.sectionDataMinimumOrder.orderMinAmount 
                            }
                            onChange={props.handleChangeMinimumOrder}
                            isEditable={updateAssignMinimumConfig}
                        />
                    </div>  
                       </div>
                       <div className="row">
                       <div className="col-md-6">
                         <RadioButton
                                Items={props.DataNetoBruto}
                                name="radioNetoBruto"
                                handleChange={handleChangeNetoBruto}
                                Title={I18n.get("lblSetMinimumOrder")}
                                isEditable={updateAssignMinimumConfig}
                            />
                        </div>
                        </div>
                </SectionEditable>
            
                <SectionEditable
                                label={I18n.get("lblConfigurePercentage")}
                                labelEdit={I18n.get("lblConfigurePercentage")}
                                Action={"UPDATE"}
                                UpdateAction={UpdateActionButtonPercentage}
                                CancelAction={CancelPercentege}
                                SaveAction={ChangePercentage}
                                Collapse={false}
                                lblbtnSave={I18n.get("lblSave")}
                                lblbtnCancel={I18n.get("lblCancel")}
                            >
                                <div className="row">
                                <div className="col">

                                <InputNumber
                                            id={"taxPercentage"}
                                            name={"taxPercentage"}
                                            label={I18n.get('lblpercentageTax')}
                                            value={props.Data.sectionData.taxPercentage}
                                            onChange={props.handleChange} 
                                            isEditable={UpdateActionPercentage}
                                            max={100}
                                            min={0}
                                           
                                        />


                                  
                                </div>
                                <div className="col">
                                <InputNumber
                                            id={"discountPercentage"}
                                            name={"discountPercentage"}
                                            label={I18n.get('lblpercentageDiscount')}
                                            value={props.Data.sectionData.discountPercentage}
                                            onChange={props.handleChange} 
                                            isEditable={UpdateActionPercentage}
                                            max={100}
                                            min={0}
                                        />
                                   
                                </div>
                                </div>
                            </SectionEditable>



                </div>
                <div className="col-sm-12 col-md-6">
                <SectionEditable
                    label={I18n.get("lblConfigCatOrd")}
                    labelEdit={I18n.get("lblConfigCatOrd")}
                    Action={"UPDATE"}
                    UpdateAction={UpdateActionButtonOrderCatalog}
                    CancelAction={CancelAssignOrderCatalog}
                    SaveAction={ChangeAssignOrderCatalog}
                    Collapse={false}
                    lblbtnSave={I18n.get("lblSave")}
                    lblbtnCancel={I18n.get("lblCancel")}
                >
                    <div className="row">
                      
                        <div className="col-md-6">
                        <CurrencyConfigConsumer
                            UpdateAction={UpdateOrderCatalog}
                            CancelActionEdit = {actionCancelEdit}
                            Data ={props.Data.sectionData}
                        />
                        </div> 
                        </div>
                        <div className="row">
                        <div className="col-md-6">
                            <RadioButton
                                Items={props.DataCatalog}
                                name="radioCatalogType"
                                handleChange={handleChangeCatalogType}
                                Title={I18n.get("lblsettypecatalog")}
                                isEditable={UpdateOrderCatalog}
                            />
                        </div>
                    </div>
                  
                
                </SectionEditable>
               
                </div>  
            </div>
            
                <>
                <SectionEditable
                    label={I18n.get("lblConfigGroup")}
                    labelEdit={I18n.get("lblConfigGroup")}
                    Action={"UPDATE"}
                    UpdateAction={UpdateActionGroups}
                    CancelAction={CancelGroups}
                    SaveAction={ChangeGroups}
                    Collapse={false}
                    lblbtnSave={I18n.get("lblSave")}
                    lblbtnCancel={I18n.get("lblCancel")}
                >
               <div className="row">
                            <div className="col-sm-12 col-md-3">
                                    <InputTag
                                    isDisabled= {updateGroups}
                                    options={props.Data.sectionData.supplierGroup1}
                                    onChange = {props.handleChangeGroup1} 
                                    id= {"Group1"}
                                    name={"Group1"}
                                    noResultMessage = {null}
                                    label= {"Group1"}
                                    values={ props.Data.sectionData.group1}
                                    // selectedValues={tagsSelects}
                                /> 

                            </div>
                            <div className="col-sm-12 col-md-3">

                            <InputTag
                                    isDisabled= {updateGroups}
                                    options={props.Data.sectionData.supplierGroup2}
                                    onChange = {props.handleChangeGroup2} 
                                    id= {"Group2"}
                                    name={"Group2"}
                                    noResultMessage = {null}
                                    label= {"Group2"}
                                    values={props.Data.sectionData.group2}
                                    // selectedValues={tagsSelects}
                                /> 

                            </div>
                            <div className="col-sm-12 col-md-3">

                            <InputTag
                                    isDisabled= {updateGroups}
                                    options={props.Data.sectionData.supplierGroup3}
                                    onChange = {props.handleChangeGroup3} 
                                    id= {"Group3"}
                                    name={"Group3"}
                                    noResultMessage = {null}
                                    label= {"Group3"}
                                    values={props.Data.sectionData.group3}
                                    // selectedValues={tagsSelects}
                                /> 

                            </div> 
               
                            </div>
                </SectionEditable>
                <Toastify autoClose={6000} />
                </>
               
                {/* <div><pre>{JSON.stringify(props.Data.standaloneMode, null, 2) }</pre></div> */} 
        </Fragment>
        
    )
}
