import React, { useEffect } from "react"
import Header from "./Header"
import Main from "./Main"
import Content from "./Content"
import Footer from "./Footer"

import ImageCDN from "../configConsumer/imagesDefaultCDN.json"
import PreferenceDefault from '../configConsumer/preferenceDefault.json'
import { XSalesConsumer } from "../Utils/Utils"
// import { XSalesData } from "../DataQuery/XSalesData"
// import { I18n } from "aws-amplify"
import { ContextProvider } from '../context'
export default function Layout(props) {

    useEffect(() => {
        XSalesConsumer.getCurrentUserFromCognitoNew().then((data) => {
            if(!data){
                 if (data?.attributes["custom:userType"] === "2") {
                     // props.history.push("/")
                     window.location.href = '/'
                 }
            }
         })  

        XSalesConsumer.getCurrentUser().then(async (user) => {
            XSalesConsumer.validateStatusUser(user.supplier?.status, user.type)
        })    
    }, [])


    return (
        <ContextProvider>
            <div className="wrapper">
                <Header />
                <Main />
                <Content>{props.children}</Content>
                <Footer
                    linkXSalesConsumer={ImageCDN.XSalesConsumerWebLogo}
                    XSalesConsumer={PreferenceDefault.XSalesConsumer}
                    Version={PreferenceDefault.XSalesConsumerWEBVersion}
                />
            </div>
        </ContextProvider>
    )
}
