import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { XSalesConsumer } from '../../../Utils/Utils'
import LogoConsumer from '../../../assets/img/XS_Consumer_logo.svg'
import { I18n } from 'aws-amplify'
import "../../../assets/css/reports.css"

export function ReportPdf(props = {
    reportTitle: "",
    from: moment().toISOString(),
    to: moment().toISOString(),
    status: "",
    reportBy: "",
    shippingType: "",
    type: "",
    show: false
}) {
    return (
        <Container ref={props.reference}>
            {
                props.show === true &&
                (
                    
                    
                    <Header>
                        <RectanguleHeader></RectanguleHeader>
                        <ContainerReport>
                        <HeaderInfo>
                            <Logo src={LogoConsumer} />
                            <ReportTitle>{props.reportTitle}</ReportTitle >
                            {/* <Date>{XSalesConsumer.formatShortDate(moment().toISOString())}</Date> */}
                            <Date>William</Date>
                        </HeaderInfo>
                        <HeaderDetail>
                            <RowDetail>
                                <div>
                                    <Label>{I18n.get('lblOrdMaFrom')}:</Label>
                                    <LabelValue>{XSalesConsumer.formatShortDate(props.from)}</LabelValue>
                                </div>
                                <div>
                                    <Label>{I18n.get('lblOrdMaStatusOrder')}:</Label>
                                    <LabelValue>{props.status}</LabelValue>
                                </div>
                                <div>
                                    <Label>{I18n.get('lblOrdMaShippingType')}:</Label>
                                    <LabelValue>{props.shippingType}</LabelValue>
                                </div>
                            </RowDetail>
                            <RowDetail>
                                <div>
                                    <Label>{I18n.get('lblOrdMaTo')}:</Label>
                                    <LabelValue>{XSalesConsumer.formatShortDate(props.to)}</LabelValue>
                                </div>
                                <div>
                                    <Label>{I18n.get('lblOrdMaReportBy')}:</Label>
                                    <LabelValue>{props.reportBy}</LabelValue>
                                </div>
                                <div>
                                    <Label>{I18n.get('lblOrdMaType')}:</Label>
                                    <LabelValue>{props.type}</LabelValue>
                                </div>
                            </RowDetail>
                        </HeaderDetail>
                        </ContainerReport>                      
                    </Header>
                )
            }
            {
                <ContainerData>
                    
                    {props.children}
                    
                    </ContainerData>
            }
            {
            props.show === true &&
            (
                    <FooterContainer>©2022 XSales Mobility, Inc. – All rights reserved</FooterContainer>
            )          
     
    }
        </Container>
        
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
`
const ContainerReport = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-left: 120px;
    padding-right: 120px;
    
`

const ContainerData = styled.div`
    width: 100%;
    height: 100%;
    padding-left: 120px;
    padding-right: 120px;
    
`
const Header = styled.div`
    width:100%;
   
`

const RectanguleHeader = styled.div`
    width:100%;
    height: 24px;
    background: #5E35B1;
    left: 0px;
    top: 0px;
`

const HeaderInfo = styled.div`
    display: flex;
    margin: 0;
    justify-content: space-between;
`

const Logo = styled.img`
    width: 170px;
    height: 75px;
`
const ReportTitle = styled.h4`
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    padding-top: 50px;

`
const Date = styled.p`
    
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    padding-top: 50px;
    color: #000000;
`
const HeaderDetail = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-left:10px;
    width: 60%; 
`

const Label = styled.p`
    display: inline-block;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #333333;


`

const LabelValue = styled.span`
    color: #a9b3ba;
    font-weight: bold;
    margin-left: 5px;
`

const RowDetail = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const FooterContainer = styled.div`
border-top: 1px solid #dee2e6;
position:absolute;
bottom:0;
width:100%;
height:60px;   /* Height of the footer */
font-family: 'Roboto';
padding-left: 110px;
font-size: 14px;
color: #c4c4c4;
`



