import React, { useEffect, useState } from 'react'
import { TabulatorTable, ProgressBar, SelectCombo, HorizontalBarChart, LoaderWindow } from 'xsales-components'
import { I18n } from "@aws-amplify/core"
import { XSalesData } from '../../../DataQuery/XSalesData';
import moment from 'moment'
import { XSalesConsumer } from '../../../Utils/Utils';
import { useHistory } from 'react-router-dom'
import DetailHeader from '../StatementComponents/DetailStatementHeader'
// import NoDataAvailable from '../StatementComponents/NoDataAvailable'
let chartBarRef = null

// const minimumSupplierLoading = 6 

const enumOptions = {
    transactions: "1",
    estimatedBill: "2"
}

const today = moment()
const dayToday = today.format("DD")
const firstDay = moment(today).startOf('month').format("L")
const LastDay = moment(today).endOf('month').format("L")
const lastDayOfMonth = moment(today).endOf('month').format("DD")
const objMonths = {}

const CurrentMonth = (props) => {
    //const [dataPeriod, setDataPeriod] = useState([])
    const [barColor, setBarColor] = useState([
        { color: '#3766FB', apply: false },
        { color: '#7DD2D6', apply: false },
        { color: '#2B395A', apply: false },
        { color: '#631BA7', apply: false },
        { color: '#9A67CB', apply: false },
        { color: '#F5A81E', apply: false }
    ]);
    const [columnsTable, setColumnsTable] = useState([
        {
            title: I18n.get("lblnameColumn"),
            field: "name",
            widthGrow: 1,
        },
        {
            title: "MONTH1",
            field: "month1",
            visible: true,
            download: true,
            align: "right",
            formatter: "money",
            formatterParams:{
                // decimal:",",
                // thousand:".",
                symbol:"$",
                symbolAfter: false,
                precision: 2,
            },
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cellClick: function (e, cell) {
                const { year, supplierId } = cell.getData()
                const month = moment().format("M")  
                history.push(`DetailStatement1/${month}/${year}/${supplierId}`)
            }
        },
        {
            title: "MONTH2",
            field: "month2",
            visible: true,
            download: true,
            align: "right",
            formatter: "money",
            formatterParams:{
                symbol:"$",
                symbolAfter: false,
                precision: 2,
            },
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cellClick: function (e, cell) {
                const {year, supplierId } = cell.getData()
                const month = moment().subtract(1,"month").format("M")  
                history.push(`DetailStatement1/${month}/${year}/${supplierId}`)
            }
        }
    ])
    //const [totalTransactions, setTotalTransactions] = useState(0);
    //const [dataBarChart, setDataBarChart] = useState({ labels: [], data: [] });
    //const [dataLabelsChart, setDataLabelsChart] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [selectValuePeriod, setSelectValuePeriod] = useState(enumOptions.transactions)
    const [dataApiHistory, setDataApiHistory] = useState({ data: [], monthsNames: [] });
    const [businessName, setBusinessName] = useState("");
    const [licensesType, setLicensesType] = useState("");
    const [fee, setFee] = useState(0);
    const [minTransaction, setMinTrasaction] = useState(0);
    const [transactionsQty, setTransactionsQty] = useState(0);
    //const [minPayment, setMinPayment] = useState(0);
    const [amountPerMonth, setAmountPerMonth] = useState(0);
    const [loader, setLoader] = useState(true);
    const [currencyS, setCurrency] = useState({ currency : "$"})
    const history = useHistory();

    const periodComboOptions = [
        { label: I18n.get("lbltransactions"), value: enumOptions.transactions }, 
        { label: I18n.get("lblestimatedbilled"), value: enumOptions.estimatedBill }
    ];

    const handleClickTable = (e) => {

    }

    const getData = async (supplierID) => {
        const { years, periods } = getLastTwoPeriods();
        let data = []
        const monthsNames = []
        for (const year of years) {
            const months = []
            for (const period of periods.filter(x => x.year === year)) {
                months.push(period.month)
                let monthName = XSalesConsumer.getMonth(period.month).toUpperCase()
                objMonths[monthName] = period.month
                monthsNames.push(monthName)
                let dataApi = await XSalesData.Statement.getStatementBySupplier(supplierID, { eq: { year: year, month: period.month } });
                dataApi = dataApi.filter(x => x.contractDetail.fees != null);
                if (dataApi.length > 0) data = [...data, dataApi];
            }
        }
        const dataHistory = dataApiHistory
        dataHistory.data = data.flat()
        dataHistory.monthsNames = monthsNames
        setDataApiHistory(dataHistory)
        return dataHistory
    }

    const setColorBarApply = (color, apply, all = false) => {
        const objArray = barColor
        for (let index = 0; index < objArray.length; index++) {
            const element = objArray[index];
            if (all) {
                element.apply = false;
            } else {
                if (element.color === color) {
                    element.apply = apply
                    break;
                }
            }
        }
        setBarColor(objArray)
    }

    const handleOnChangeSelect = (e) => {
        setColorBarApply("", false, true)
        setSelectValuePeriod(e.value)
        buildInformation(e.value)
    }

    const handleClickBar = function (e, i) {
        const element = this.getElementAtEvent(e);
        if (element[0] && chartBarRef) {
            //console.log(chartBarRef.data.datasets[element[0]._datasetIndex])
            //const { supplierId, year, month } = chartBarRef.data.datasets[element[0]._datasetIndex]
           // history.push(`DetailStatement1/${month}/${year}/${supplierId}`)
        }
    }

    const sumPeriod = (rpt, ySupplier, dataSupplier, namePeriod) => {
        if (rpt === enumOptions.transactionsPerMonth) {
            dataSupplier[namePeriod] += (ySupplier.count.order + ySupplier.count.deposit + ySupplier.count.payment)
        } else if (rpt === enumOptions.estimatedBill) {
            dataSupplier[namePeriod] += (ySupplier.amount.orderUSD + ySupplier.amount.depositUSD + ySupplier.amount.paymentUSD)
        } else if (rpt === enumOptions.averageOrdersAmount) {
            dataSupplier[namePeriod] += (ySupplier.avgAmount.orderUSD + ySupplier.avgAmount.depositUSD + ySupplier.avgAmount.paymentUSD)
        } else if (rpt === enumOptions.averageItemsPerOrder) {
            dataSupplier[namePeriod] += (ySupplier.avgItem.order + ySupplier.avgItem.deposit + ySupplier.avgItem.payment)
        } else {
            dataSupplier[namePeriod] += (ySupplier.count.order + ySupplier.count.deposit + ySupplier.count.payment)
        }
        changeCurrencyByRpt(rpt)
    }

    const changeCurrencyByRpt = async (rpt) =>  {
        const columns = columnsTable
        let currency = ""
        if(rpt === enumOptions.estimatedBill){
            columns[1].formatterParams.symbol = "$"
            columns[2].formatterParams.symbol = "$"
            currency = "$"
        }else{
            columns[1].formatterParams.symbol = ""
            columns[2].formatterParams.symbol = ""
        }

        
        const objCurrency = currencyS
        objCurrency.currency = currency
        await setCurrency(objCurrency)
        setColumnsTable(columns)
        if(chartBarRef){
            chartBarRef.update()
        }
    }

    const getLastTwoPeriods = () => {
        const now = moment();
        const periods = [] //{ year: 2020, month }
        let years = [] // years 
        Array.from({ length: 2 }, (_, i) => i).forEach((_, index) => {
            const date = index === 0 ? now : now.subtract(1, 'months')
            const year = date.year()
            const month = date.month()
            periods.push({ year: year, month: month + 1 })
            years.push(year)
        })
        years = [...new Set(years)];
        return { years, periods };
    }

    const buildInformation = (rpt) => {
        let index = 0;
        dataApiHistory.monthsNames.forEach((name, _) => {
            let indexColumn = index + 1;
            columnsTable[indexColumn].title = name.toUpperCase()
            index++;
        })

        const suppliers = [...new Set(dataApiHistory.data.map(x => x.supplierId))]
        const dataTable = []
        let { periods } = getLastTwoPeriods();
        periods = periods.map(x => x.month)
        // window.dataApi = dataApiHistory
        // console.log(dataApiHistory)
        suppliers.forEach((supplierId) => {
            const dataSupplier = {
                id: supplierId,
                name: "",
                month1: 0,
                month2: 0,
                month3: 0,
                month4: 0,
                month5: 0,
                month6: 0,
                total: 0,
                supplierId: "",
                year: 0
            }

            dataApiHistory.data.filter(x => x.supplierId === supplierId).forEach((ySupplier) => {
                dataSupplier.name = ySupplier.supplier.businessName;
                dataSupplier.year = ySupplier.year
                dataSupplier.supplierId = ySupplier.supplierId
                let indexMonth = periods.indexOf(ySupplier.month)
                if (indexMonth > -1) {
                    indexMonth++;
                }
                switch (indexMonth) {
                    case 1:
                        sumPeriod(rpt, ySupplier, dataSupplier, "month1")
                        break;
                    case 2:
                        sumPeriod(rpt, ySupplier, dataSupplier, "month2")
                        break;
                    case 3:
                        sumPeriod(rpt, ySupplier, dataSupplier, "month3")
                        break;
                    case 4:
                        sumPeriod(rpt, ySupplier, dataSupplier, "month4")
                        break;
                    case 5:
                        sumPeriod(rpt, ySupplier, dataSupplier, "month5")
                        break;
                    case 6:
                        sumPeriod(rpt, ySupplier, dataSupplier, "month6")
                        break;
                    default:
                        break;
                }
            })
            dataSupplier.total += (dataSupplier.month1 + dataSupplier.month2 + dataSupplier.month3 + dataSupplier.month4 + dataSupplier.month5 + dataSupplier.month6)
            dataTable.push(dataSupplier)
        })
        updateChartLabels(dataApiHistory.monthsNames, dataTable)
        setDataTable(dataTable);
    }

    const updateChartLabels = (labels, data) => {
        //setDataLabelsChart(labels)
        const colors = barColor;
        colors.forEach(x => x.apply = false)
        setBarColor(colors)
        if (chartBarRef && data.length > 0) {
            const _data = data[0];
            chartBarRef.data.labels = labels;
            chartBarRef.data.datasets[0].data = [_data.month1, _data.month2];
            chartBarRef.update();
        }
    }

    useEffect(() => {
        const user = localStorage.getItem("ConsumerUser") ? JSON.parse(localStorage.getItem("ConsumerUser")) : null
        if (user.supplier) {
            setBusinessName(user.supplier.businessName)
            getData(user.supplier.id)
                .then((d) => {
                    setLoader(false)
                    const month = new Date().getMonth() + 1;
                    const data = d.data.filter(x => x.month === month)
                    if (data.length > 0) {
                        setLicensesType(data[0].contractDetail.plan)
                        setMinTrasaction(data[0].contractDetail.minTransaction.order)
                        setFee(data[0].contractDetail.fees.order)
                        setTransactionsQty(data[0].count.order + data[0].count.deposit + data[0].count.payment)
                        //setMinPayment(data[0].contractDetail.fees.order * data[0].contractDetail.minTransaction.order)
                        setAmountPerMonth((data[0].amount.order + data[0].amount.payment + data[0].amount.deposit))
                    }          
                    buildInformation(enumOptions.transactions)         
                })
                .catch((err) => {
                    console.log(err.message)
                    setLoader(false)
                })
        }
        return () => {
            if (chartBarRef) chartBarRef.destroy()
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const daysRemaining = (today) => {
        const endOfDate = moment().endOf('month');
        return endOfDate.diff(today, 'days');
    }

    const percentDaysRemaining = (today) => {
        return XSalesConsumer.getPercent(Number(today.format("DD")), Number(lastDayOfMonth))
    }

    const getPercent = (value, total) => {
        const percent = XSalesConsumer.getPercent(Number(value), Number(total))
        return percent > 100 ? 100 : percent
    }

    const formatNumber = (value) => {
        const locale = navigator.language
        return new Intl.NumberFormat(locale, { style: 'decimal' }).format(value)
    }

    return (
        <>
            {

                loader ?
                    <LoaderWindow />
                    :
                    // dataApiHistory.data.length == 0 ?
                    //     ""
                    //     :
                        <>
                            <div className="statement__section">
                                <div style={{ width: "100%" }}>
                                    <DetailHeader
                                        businessName={businessName}
                                        fee={fee}
                                        licensesType={licensesType}
                                        minTransaction={minTransaction}
                                        startOfMonth={firstDay}
                                        endOfMonth={LastDay}
                                    />
                                    <div className="separator__line_detail"></div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="title__section_statement">
                                            <img src={"https://cdn-xsalesconsumer.s3.us-east-2.amazonaws.com/console/event-24px.svg"} loading="lazy" alt="" />
                                            <h4>{I18n.get("lblmonth")}</h4>
                                        </div>

                                        <div className="col-sm-4">
                                            <SelectCombo
                                                id="currentPeriod"
                                                name="currentPeriod"
                                                label={""}
                                                options={periodComboOptions}
                                                optionSelected={selectValuePeriod}
                                                onChange={handleOnChangeSelect}
                                                isEditable={true}
                                                value={selectValuePeriod}
                                            />
                                        </div>
                                        <TabulatorTable
                                            id="CurrentMonth"
                                            Title={""}
                                            Data={dataTable}
                                            Columns={columnsTable}
                                            Height="150px"
                                            // Filter={[]}
                                            RowClick={handleClickTable}
                                            placeholder={I18n.get("lblLoadingData")}
                                            downloadIcon={true}
                                            Pagination={false}
                                            moreIcon={false}
                                            Search={false}
                                            lblSearch= {I18n.get("lblSearch")}
                                            labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                                        />
                                        <div style={{ marginLeft: "0", marginTop: "0", width: "65%" }}>
                                            <HorizontalBarChart
                                                id="HorizontalBarChart"
                                                labels={[]}
                                                datasets={[
                                                    {
                                                        label: "",
                                                        data: [],
                                                        backgroundColor: [
                                                            '#2B395A',
                                                            '#7DD2D6'
                                                        ],
                                                        borderColor: [],
                                                        borderWidth: 0.1
                                                    }]}
                                                // maxYValue={100}
                                                // minYValue={0}
                                                // stepSize={20}
                                                stacked={false}
                                                showLabelBar={true}
                                                showLegend={false}
                                                formatLabel="currency"
                                                currencySymbol={""}
                                                barPercentage={0.1}
                                                handleClick={handleClickBar}
                                                setRefChart={(chart) => chartBarRef = chart}
                                                // width="100"
                                                height="90"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="statement__progress__bar__container">
                                            <div className="statement__progress__bar__wrapper">
                                                <div className="section__progress__bar__summary">
                                                    <p>{`${I18n.get("lblsummarymonth").replace("{day1}",dayToday).replace("{day2}",lastDayOfMonth).replace("{month}",today.format("MMMM"))}`}</p>
                                                    <ProgressBar showPercent={false} width={"100%"} completePercent={percentDaysRemaining(today)} />
                                                    <span className="progress__bar__title__days">{I18n.get("lbldaysleft").replace("{days}", daysRemaining(today))}</span>
                                                </div>
                                         
                                                <div className="section__progress__bar__summary">                      
                                                    <p>{`${I18n.get("lblminTransactionsPayment").replace("{var1}",formatNumber(transactionsQty)).replace("{var2}",formatNumber(minTransaction))}`}</p>
                                                    <ProgressBar showPercent={true} width={"100%"} completePercent={getPercent(transactionsQty, minTransaction)} />
                                                </div>
                                                <div className="section__progress__bar__summary">
                                                    <p>{`${I18n.get("lblminPayment").replace("{amount1}",formatNumber(transactionsQty * fee)).replace("{amount2}",formatNumber(amountPerMonth))}`}</p>
                                                    <ProgressBar showPercent={true} width={"100%"} completePercent={getPercent(300, 300)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="statement__section_separator"></div>
                        </>
            }
        </>
    )
}

export default CurrentMonth