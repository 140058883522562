import React from "react"

export default function Footer(props) {
  return (
    <footer className="main-footer">
      <strong>
        Copyright © {new Date().getFullYear()} <a style={{color:"#5E35B1"}} href={props.linkXSalesConsumer}>{props.XSalesConsumer}</a>.
      </strong>
      All rights reserved.
      <div className="float-right d-none d-sm-inline-block">
        <b>Versión </b>{props.Version}
      </div>
    </footer>
  )
}