// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const StatusSupplier = {
  "DRAFT": "DRAFT",
  "VALIDATING": "VALIDATING",
  "CONFIGURING": "CONFIGURING",
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "DELETED": "DELETED",
  "REJECTED": "REJECTED"
};

const AddressType = {
  "MAIN": "MAIN"
};

const PhoneType = {
  "WORK": "WORK",
  "HOME": "HOME",
  "MOBILE": "MOBILE"
};

const StatusConsumer = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "DELETED": "DELETED"
};

const Plans = {
  "BASIC": "BASIC",
  "PREMIUM": "PREMIUM"
};

const StatusSupplierRequest = {
  "VALIDATING": "VALIDATING",
  "APPROVED": "APPROVED",
  "REJECTED": "REJECTED",
  "DELETED": "DELETED"
};

const StatusForSales = {
  "LOCKED": "LOCKED",
  "UNLOCKED": "UNLOCKED"
};

const TypeApp = {
  "MOBILE": "MOBILE",
  "WEB": "WEB"
};

const DataType = {
  "STRING": "STRING",
  "INT": "INT",
  "BOOL": "BOOL",
  "FLOAT": "FLOAT"
};

const AuditType = {
  "INFO": "INFO",
  "WARNING": "WARNING",
  "ERROR": "ERROR",
  "TRACE": "TRACE",
  "AUDIT": "AUDIT",
  "CREATE": "CREATE",
  "UPDATE": "UPDATE",
  "DELETE": "DELETE"
};

const AuditFormat = {
  "TEXT": "TEXT",
  "JSON": "JSON",
  "XML": "XML"
};

const ShippingType = {
  "PICKUP": "PICKUP",
  "DELIVERY": "DELIVERY"
};

const OrderStatus = {
  "DRAFT": "DRAFT",
  "CALCULATED": "CALCULATED",
  "PLACED": "PLACED",
  "DELIVERED": "DELIVERED"
};

const TypeProcess = {
  "MANUAL": "MANUAL",
  "AUTOMATIC": "AUTOMATIC",
  "MOBILE": "MOBILE"
};

const StatusSync = {
  "TODO": "TODO",
  "PROCESSING": "PROCESSING",
  "DONE": "DONE"
};

const { Product, Category, Subcategory, SupplierConsumer, Supplier, SupplierIndustry, Industry, ConsumerIndustry, Consumer, SupplierSetup, SupplierConsumerSetup, User, SupplierConsumerProduct, Window, Message, Label, Security, Setup, ConsumerSetup, Audit, Crew, CrewSecurity, CrewUser, Order, OrderProduct, SyncLog, Statement, Tag, Promotions, HistoryStatusSupplier, Address, Phone, Contract, ContractDetail, TransactionFee, TransactionMinimum, paymentMethodType, DaysDelivery, AuditFields, historyStatusERP, TaxType, DiscountType, shippingAddressType, TransactionCount, TransactionTotalAmount, TransactionAvgItem, TransactionAvgAmount, TransactionSumItem } = initSchema(schema);

export {
  Product,
  Category,
  Subcategory,
  SupplierConsumer,
  Supplier,
  SupplierIndustry,
  Industry,
  ConsumerIndustry,
  Consumer,
  SupplierSetup,
  SupplierConsumerSetup,
  User,
  SupplierConsumerProduct,
  Window,
  Message,
  Label,
  Security,
  Setup,
  ConsumerSetup,
  Audit,
  Crew,
  CrewSecurity,
  CrewUser,
  Order,
  OrderProduct,
  SyncLog,
  Statement,
  StatusSupplier,
  AddressType,
  PhoneType,
  StatusConsumer,
  Plans,
  StatusSupplierRequest,
  StatusForSales,
  TypeApp,
  DataType,
  AuditType,
  AuditFormat,
  ShippingType,
  OrderStatus,
  TypeProcess,
  StatusSync,
  Tag,
  Promotions,
  HistoryStatusSupplier,
  Address,
  Phone,
  Contract,
  ContractDetail,
  TransactionFee,
  TransactionMinimum,
  paymentMethodType,
  DaysDelivery,
  AuditFields,
  historyStatusERP,
  TaxType,
  DiscountType,
  shippingAddressType,
  TransactionCount,
  TransactionTotalAmount,
  TransactionAvgItem,
  TransactionAvgAmount,
  TransactionSumItem
};