import React, { useEffect, useState } from "react"
import { TabulatorTable, LoaderWindow, Modal, Filters, SeparateTop, Button, TextMessage, Title} from 'xsales-components'
import { XSalesConsumer } from "../../Utils/Utils"
import { XSalesData } from "../../DataQuery/XSalesData"
import styled from "styled-components"
import { PostOrder } from './PostOrder'
import loadingLine from "../../assets/img/loadingLine.gif"
import * as db from "../../models"
//import * as subscription from "../../graphql/subscriptions"
//import { API, graphqlOperation,I18n } from "aws-amplify"
import { I18n } from "aws-amplify"
import { WizardImport } from '../Wizard'
import moment from 'moment'

let Entitystepper = null;
let standaloneMode = false;
export default function Advanced(props)
{

const [loader, setLoader] = useState(false)
const [counter, setCounter] = useState(0)
const [table, setTable] = useState("")
const [enabledUpload, setEnabledUpload] = useState(false)

const [loadingData, setLoadingData] = useState({
    loadingData: "",
    nodataavailable: "",
})

//let subscriptionSyncLogCreate = {}
//let subscriptionSyncLogUpdate = {}

const handleClickFilters= async (e) =>{
        let { name } = e.target;
        getColumsTabulator();
        setTable(name);

        if (name!=='product'){
            const hasClass = e.target.classList.contains('btn__filters__active--component');
            if (hasClass===false){
                name="product";
            } 
        }

        if((name === "product" || name === "category" || name === "subcategory") && standaloneMode===true) 
        { 
            setEnabledUpload(true) 
        }
        else
        {
            setEnabledUpload(false)  
        }

        if(name ==="supplierbanner")
        {
            viewDataBanners(name)
        }
        
        viewData(name)
}

const handleClickFiltersSync= async (e) =>{
    getColumsTabulatorLog();
    if (e.target!=null){
        let { id } = e.target; 
        await fetchSyncLog(id);
    }
}

const fetchSyncLog = async (idfilter) => {
    try {
        let statusLastSync = tabulatorProps.tablesSync;
        XSalesConsumer.getCurrentUser()
            .then(async (data) => {
                const supplierId = data.supplier.id
                let sup = tabulatorProps.supplier
                sup.status = data.supplier.status

                setTabulatorProps(prevState => ({
                    ...prevState,
                    supplier: sup,
                }))

                Promise.all(statusLastSync.map(async (item) => {
                    const lastSync = await XSalesData.Suppliers.GetSyncLogTop(
                        supplierId,
                        item.tableName,
                        30
                    )
                        .then((lastSync) => {
                            return lastSync
                        })
                        .catch((err) => {
                            console.log("opprinting error ", err)
                            return []
                        })
                    item.countRegister = await countRegisterOfTable(
                        item.tableName
                    )
                    item.statusLastSync =
                        lastSync.length > 0
                            ? lastSync[0].status
                            : db.StatusSync.TODO
                    return item
                })
                )
                    .then((data) => {
                        countBannerPromotions(data)

                        setTabulatorProps(prevState => ({
                            ...prevState,
                            tablesSync: data,
                        }))
                        
                    })
                    .catch((err) => {
                        console.log("promise all catch err ", err)
                    })

                //Query for syncLog data of table
                let dateFilter = moment().add(-5, 'd');
                await XSalesData.BackendAPI.GetSyncLog(supplierId, 100, dateFilter)
                    .then((syncLogData) => {
                        let obj = tabulatorProps.objData
                        let dataFormatted = syncLogData
                            .sort(compareValues("date", "desc"))
                            .map((item) => {
                                item.date = XSalesConsumer.getDateFormatter(
                                    item.date
                                )
                                item.status = I18n.get("lbl" + item.status)
                                return item
                            })
                        obj.Data = dataFormatted
                        obj.showLabelEmptyData = obj.length === 0 ? true : false
                        switch (idfilter) {
                            case "ALL":
                                obj.Filter = []
                                break;
                            case "onlyTodo":
                                obj.Filter = [
                                    { field: "status", type: "in", value: [I18n.get("lblTODO")] },
                                ]
                                break;
                            case "onlyProcessing":
                                obj.Filter = [
                                    { field: "status", type: "in", value: [I18n.get("lblPROCESSING")] },
                                ]
                                break;
                            case "onlyDone":
                                obj.Filter = [
                                    { field: "status", type: "in", value: [I18n.get("lblDONE")] },
                                ]
                                break;
                            default:
                                obj.Filter = []
                                break;
                        }

                        setTabulatorProps(prevState => ({
                            ...prevState,
                            objData: obj,
                        }))
                        
                        const elements = document.querySelectorAll(`.tabulator`);
                        elements.forEach(el => {
                            el.setAttribute('style', 'margin-top: 10px; height: auto');
                        }) 
                    })
                    .catch((err) => {
                        console.log("syncLogData catch", err)
                    })
            })
            .catch((err) => {
                console.log("supplier not found in localstorage")
            })
        // XSalesData.Suppliers.GetSyncLogTop()
    } catch (error) {
        console.log("error fetchSyncLog")
    }


} 
/*const ALL = (e) => {
    if (e !== undefined) XSalesConsumer.ChangeColorFilter(e)
    let objData = tabulatorProps.objData
    objData.Filter = []
    setTabulatorProps(prevState => ({
        ...prevState,
        objData: objData,
    }))

}

const onlyTodo = (e) => {
    if (e !== undefined) XSalesConsumer.ChangeColorFilter(e)
    let objData = tabulatorProps.objData
    objData.Filter = [
        { field: "status", type: "in", value: [I18n.get("lblTODO")] },
    ]
    setTabulatorProps(prevState => ({
        ...prevState,
        objData: objData,
    }))

}

const onlyProcessing = (e) => {
    if (e !== undefined) XSalesConsumer.ChangeColorFilter(e)
    let objData = tabulatorProps.objData
    objData.Filter = [
        { field: "status", type: "in", value: [I18n.get("lblPROCESSING")] },
    ]
    setTabulatorProps(prevState => ({
        ...prevState,
        objData: objData,
    }))

}

const onlyDone = (e) => {
    if (e !== undefined) XSalesConsumer.ChangeColorFilter(e)
    let objData = tabulatorProps.objData
    objData.Filter = [
        { field: "status", type: "in", value: [I18n.get("lblDONE")] },
    ]

    setTabulatorProps(prevState => ({
        ...prevState,
        objData: objData,
    }))
}*/

const viewData = async (tableName) => {
        setCounter(0)
        XSalesConsumer.getCurrentUser()
            .then((data) => {
                
                let col = []
                let obj = tabulatorProps.objDataTable
                
                XSalesData.Suppliers.GetTableDataBySupplier(
                    tableName,
                    data.supplier.id, 
                    1000
                )
                    .then((dataFromTable) => {
                        let newData = dataFromTable.map((item) => {
                            return XSalesData.ReturnData.formatData(item)
                        })
                        if (newData){
                            obj.Title = I18n.get("lbl" + tableName + "Table")
                            Object.keys(newData[0]).map((item) => (
                                col.push({
                                    title: I18n.get("lbl" + item),
                                    field: item,
                                    visible: true,
                                })
                            ))
                            obj.Data = newData
                            obj.Columns = col
                            setCounter(newData.length)
                            setTabulatorProps(prevState => ({
                                    ...prevState,
                                    objDataTable: obj,
                                }))
                        }
                        setLoader(false)
                    })
                    .catch((err) => {
                        obj.Data = []
                        obj.Columns = []
                        obj.Title = ""
                        
                        setTabulatorProps(prevState => ({
                                ...prevState,
                                objDataTable: obj,
                            }))
                        
                         
                    })
                    setLoader(false)
            })
            .catch((err) => {
                console.log("error searchiing currentUser ", err)
                setLoader(false)
            })
    }

const   viewDataBanners = async (tableName) => {
        try {
            setCounter(0)
            let col = []
            let obj = tabulatorProps.objDataTable
            const supplier = await XSalesConsumer.getCurrentUser()
            if (supplier){
                const promotions = await XSalesData.Suppliers.GetSupplierBanner(supplier.supplier.id)
                if (promotions){
                    if (promotions.length > 0) {
                        obj.Title = I18n.get("lbl" + tableName + "Table")
                        Object.keys(promotions[0]).map((item) => (
                            col.push({
                                title: I18n.get("lbl" + item),
                                field: item,
                                visible: true,
                            })
                        ))
                        obj.Data = promotions
                        obj.Columns = col
                        setCounter(promotions.length)
                                setTabulatorProps(prevState => ({
                                        ...prevState,
                                        objDataTable: obj,
                                    }))
                                    setLoader(false)
                    }
                }
            }
        } catch (error) {
            setLoader(false)
            console.error(error)
        }
    }

const    downloadData = async (tableName) => {

        const supplierId = await XSalesConsumer.getCurrentUser()
            .then((data) => {
                return data.supplier.id
            })
            .catch((err) => {
                console.log("error showing supplierId")
                return ""
            })
        if (supplierId !== "" && tableName !== "") {
            switch (tableName.toString().toUpperCase()) {
                case "PRODUCT":
                    await XSalesData.BackendAPI.downloadProductBySupplierId(
                        supplierId
                    )
                        .then((data) => {
                            setLoader(false)
                            /*console.log(
                                "product have been downloading rigth now",
                                data
                            )*/
                        })
                        .catch((err) => {
                            setLoader(false)
                            console.log(
                                "product download have been ocurred an error"
                            )
                        })
                    break
                case "CATEGORY":
                    await XSalesData.BackendAPI.downloadCategoryBySupplierId(
                        supplierId
                    )
                        .then((data) => {
                            setLoader(false)
                            /*console.log(
                                "category have been downloading rigth now"
                            )*/
                        })
                        .catch((err) => {
                            setLoader(false)
                            console.log(
                                "category download have been ocurred an error"
                            )
                        })
                    break
                case "SUBCATEGORY":
                    await XSalesData.BackendAPI.downloadSubCategoryBySupplierId(
                        supplierId
                    )
                        .then((data) => {
                            setLoader(false)
                            /*console.log(
                                "subcategory have been downloading rigth now"
                            )*/
                        })
                        .catch((err) => {
                            setLoader(false)
                            console.log(
                                "subcategory download have been ocurred an error"
                            )
                        })
                    break
                case "CONSUMERPRODUCT":
                    await XSalesData.BackendAPI.downloadSupplierConsumerProductBySupplierId(
                        supplierId
                    )
                        .then((data) => {
                            setLoader(false)
                            /*console.log(
                                "supplierConsumerProduct have been downloading rigth now"
                            )*/
                        })
                        .catch((err) => {
                            setLoader(false)
                            console.log(
                                "supplierConsumerProduct download have been ocurred an error"
                            )
                        })
                    break

                case "CUSTOMERBANNER":
                    await XSalesData.BackendAPI.downloadConsumerBannerBySupplierId(
                        supplierId
                    )
                        .then((data) => {
                            setLoader(false)
                            /*console.log(
                                "consumerbanner have been downloading rigth now"
                            )*/
                        })
                        .catch((err) => {
                            setLoader(false)
                            console.log(
                                "consumerbanner download have been ocurred an error"
                            )
                        })
                    break
                case "SUPPLIERBANNER":
                    await XSalesData.BackendAPI.downloadSupplierBannerBySupplierId(
                        supplierId
                    )
                        .then((data) => {
                            setLoader(false)
                            /*console.log(
                                "supplierbanner have been downloading rigth now"
                            )*/
                        })
                        .catch((err) => {
                            setLoader(false)
                            console.log(
                                "supplierbanner download have been ocurred an error"
                            )
                        })
                    break;

                default:
                    /*console.log(
                        "downloadData not implment for tableName: ",
                        tableName
                    )*/
                    setLoader(false)
                    break
            }
        }
    }


const searchSyncLogerrorById = async (syncId) => {
        await XSalesData.Suppliers.GetSyncLogById(syncId)
            .then(async (data) => {
                let buff = new Buffer(data.errors, "base64")
                let datos = buff.toString("ascii")
                let arrData = datos.split(",")
                
               await setTabulatorProps(prevState => ({
                    ...prevState,
                    modalContent: arrData,
                }))
                
            })
            .catch((err) => {
                console.log("searchSyncLogerrorById", err)
            })
    }

    

const [tabulatorProps, setTabulatorProps] = useState({
        supplier: {
            status: db.StatusSupplier.VALIDATING,
        },
        filter: [],
        data: [],
        languages: [],
        tablesSync: [],
        modalContent: [],
        objDataTable: {
                InitialData: [],
                Title: "",
                Data: [],
                Columns: [],
                Counter:0,
        },
        objData: {
                showLabelEmptyData: false,
                InitialData: [
                    {
                        id: "",
                        date: "",
                        syncId: "",
                        process: "",
                        status: "",
                        message: "",
                        sourceCount: 0,
                        inserted: 0,
                        updated: 0,
                        noupdated: 0,
                        errored: 0,
                        errorNumber: "",
                        totalDuration: 0,
                        errors: "",
                    },
                ],
                Data: [],
                Filter: [],
                Columns: [],
                //RowClick: rowClickEventSyncLog,
        },
        dataFilters: [],
        dataFiltersLogs:[],
    })

async function getColumsTabulator(){
    let dataFilters= [
        {
            label: I18n.get("lblproductTableSchema").toUpperCase(),
            handleClick: handleClickFilters,
            className: "btn btn-outline-primary bounds-button btn__filters__active--component",
            id: "f_product",
            name: "product",
            type: "button",
            active: false
        },
        {
            label: I18n.get("lblcategoryTableSchema").toUpperCase(),
            handleClick: handleClickFilters,
            className: "btn btn-outline-primary bounds-button",
            id: "f_category",
            name: "category",
            type: "button",
            active: false
        },
        {
            label: I18n.get("lblsubcategoryTableSchema").toUpperCase(),
            handleClick: handleClickFilters,
            className: "btn btn-outline-primary bounds-button",
            id: "f_subcategory",
            name: "subcategory",
            type: "button",
            active: false
        },
        {
            label: I18n.get("lblconsumerproductTableSchema").toUpperCase(),
            handleClick: handleClickFilters,
            className: "btn btn-outline-primary bounds-button",
            id: "f_consumerproduct",
            name: "consumerproduct",
            type: "button",
            active: false
        },
        {
            label: I18n.get("lblcustomerbannerTableSchema").toUpperCase(),
            handleClick: handleClickFilters,
            className: "btn btn-outline-primary bounds-button",
            id: "f_customerbanner",
            name: "customerbanner",
            type: "button",
            active: false
        },
        {
            label: I18n.get("lblsupplierbannerTableSchema").toUpperCase(),
            handleClick: handleClickFilters,
            className: "btn btn-outline-primary bounds-button",
            id: "f_supplierbanner",
            name: "supplierbanner",
            type: "button",
            active: false
        }
    ]
    setTabulatorProps(prevState => ({
        ...prevState,
        dataFilters: dataFilters
    }))
}

async function getColumsTabulatorLog(){

    let Columns= [
        {
            title: I18n.get("lblSyncId"),
            field: "id",
            headerSort: false,
            visible: false,
        },
        {
            title: I18n.get("lblSyncDate"),
            field: "date",
            headerSort: false,
            visible: true,
        },
        {
            title: I18n.get("lblSyncSyncId"),
            field: "syncId",
            visible: false,
            formatter: function (
                cell,
                formatterParams,
                onRendered
            ) {
                if (
                    cell.getValue() === "undefined" ||
                    cell.getValue() === undefined ||
                    cell.getValue() === null ||
                    cell.getValue() === ""
                ) {
                    return ""
                }
                return cell.getValue()
            },
        },
        {
            title: I18n.get("lblSyncProcess"),
            field: "process",
            visible: true,
        },
        {
            title: I18n.get("lblSyncStatus"),
            field: "status",
            visible: true,
        },
        {
            title: I18n.get("lblSyncMessage"),
            field: "message",
            visible: false,
        },
        {
            title: I18n.get("lblSyncSourceCount"),
            field: "sourceCount",
            visible: true,
        },
        {
            title: I18n.get("lblSyncInserted"),
            field: "inserted",
            visible: true,
        },
        {
            title: I18n.get("lblSyncUpdated"),
            field: "updated",
            visible: true,
        },
        {
            title: I18n.get("lblSyncNoupdated"),
            field: "noupdated",
            visible: true,
        },
        {
            title: I18n.get("lblSyncErrored"),
            field: "errored",
            visible: true,
        },
        {
            title: I18n.get("lblSyncErrorNumber"),
            field: "errorNumber",
            visible: true,
        },
        {
            title: I18n.get("lblSyncTotalDuration"),
            field: "totalDuration",
            visible: false,
        },
        {
            title: I18n.get("lblSyncErrors"),
            field: "errors",
            visible: true,
            //width: "auto",
            cellClick: function (e, cell) {
                const idSync = cell._cell.row.data.id
                window.$("#modalShowError").modal("toggle")
                searchSyncLogerrorById(idSync)
            },
            formatter: function (
                cell,
                formatterParams,
                onRendered
            ) {
                //cell - the cell component
                //formatterParams - parameters set for the column
                //onRendered - function to call when the formatter has been rendered
                if (
                    cell.getValue() === undefined ||
                    cell.getValue() === null ||
                    cell.getValue() === "" ||
                    cell.getValue() === "undefined" ||
                    cell.getValue() === "null"
                ) {
                    return ""
                }
                return "<i className='fa fa-info-circle icon-onfocus' data-toggle='popover' title='Errores' data-content='show error' />"
            },
        },
    ]
    let obj = tabulatorProps.objData;
    obj.Columns = Columns;
    setTabulatorProps(prevState => ({
        ...prevState,
        objData: obj
    }))

    let tablesSync= [
        {
            tableName: "product",
            view: (tableName) => {
                viewData(tableName)
            },
            download: (tableName) => {
                downloadData(tableName)
            },
            countRegister: 0,
            statusLastSync: db.StatusSync.TODO,
            required: true,
            visible: true
        },
        {
            tableName: "category",
            view: (tableName) => {
                viewData(tableName)
            },
            download: (tableName) => {
                downloadData(tableName)
            },
            countRegister: 0,
            statusLastSync: db.StatusSync.TODO,
            required: true,
            visible: true,
        },
        {
            tableName: "subcategory",
            view: (tableName) => {
                viewData(tableName)
            },
            download: (tableName) => {
                downloadData(tableName)
            },
            countRegister: 0,
            statusLastSync: db.StatusSync.TODO,
            required: true,
            visible: true,
        },
        {
            tableName: "consumerproduct",
            view: (tableName) => {
                viewData(tableName)
            },
            download: (tableName) => {
                downloadData(tableName)
            },
            countRegister: 0,
            statusLastSync: db.StatusSync.TODO,
            required: false,
            //visible: CW010,
        },
        {
            tableName: "customerbanner",
            view: (tableName) => {
                viewData(tableName)
            },
            download: (tableName) => {
                downloadData(tableName)
            },
            countRegister: 0,
            statusLastSync: db.StatusSync.TODO,
            required: false,
            //visible: CW011,
        },
        {
            tableName: "supplierbanner",
            view: (tableName) => {
                viewDataBanners(tableName)
            },
            download: (tableName) => {
                downloadData(tableName)
            },
            countRegister: 0,
            statusLastSync: db.StatusSync.TODO,
            required: false,
            visible: true,
        },
        {
            tableName: "postorder",
            view: (tableName) => {
                //  this.viewDataBanners(tableName)
            },
            download: (tableName) => {
                // this.downloadData(tableName)
                window.$("#modalPostOrder").modal("toggle")
            },
            countRegister: 0,
            statusLastSync: db.StatusSync.TODO,
            required: false,
            visible: true,
        },
        {
            tableName: "postquote",
            view: (tableName) => {
                //  this.viewDataBanners(tableName)
            },
            download: (tableName) => {
                // this.downloadData(tableName)
                window.$("#modalPostQuote").modal("toggle")
            },
            countRegister: 0,
            statusLastSync: db.StatusSync.TODO,
            required: false,
            visible: true,
        }
    ]
    setTabulatorProps(prevState => ({
        ...prevState,
        tablesSync: tablesSync
    }))

    let dataFiltersLogs= [
        {
            label: I18n.get("lblALL"),
            handleClick: handleClickFiltersSync,
            className: "btn btn-outline-primary bounds-button btn__filters__active--component",
            id: "ALL",
            name: "ALL",
            type: "button",
            active: true
        },
        {
            label: I18n.get("lblTODO"),
            handleClick: handleClickFiltersSync,
            className: "btn btn-outline-primary bounds-button btn__filters__active--component",
            id: "onlyTodo",
            name: "onlyTodo",
            type: "button",
            active: false
        },
        {
            label: I18n.get("lblPROCESSING"),
            handleClick: handleClickFiltersSync,
            className: "btn btn-outline-primary bounds-button btn__filters__active--component",
            id: "onlyProcessing",
            name: "onlyProcessing",
            type: "button",
            active: false
        },
        {
            label: I18n.get("lblDONE"),
            handleClick: handleClickFiltersSync,
            className: "btn btn-outline-primary bounds-button btn__filters__active--component",
            id: "onlyDone",
            name: "onlyDone",
            type: "button",
            active: false
        },

    ]
    setTabulatorProps(prevState => ({
        ...prevState,
        dataFiltersLogs: dataFiltersLogs
    }))
}
useEffect(() => {
        document.title = "Supplier:Advanced"
        getLanguage();
        getColumsTabulator();
        getColumsTabulatorLog();
        setLoadingData({loadingData: I18n.get("lblLoadingData")});
        setLoadingData({nodataavailable: I18n.get("lblnodataavailabletabulator")});
        getBttnSync();
        fetchSyncLog('ALL');
        /*subscribeChange()
        .then((data) => {
            // console.log("component didmount subscribechange")
        })
        .catch((err) => {
            console.log("component didmount catch subscribechange")
        })*/
        
        //UnseletedOption();
        //fetchSyncLog('ALL');
        //const el = document.getElementById(`ALL`);
        //el.classList.add(`btn__filters__active--component`);
}, []) // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
    
}, [])
async function getBttnSync() {
    const{ supplier } =  await XSalesConsumer.getCurrentUser()
    const StandaloneMode = await XSalesData.Suppliers.GetSupplierStandaloneMode(supplier.id)
    if (StandaloneMode ==='false'){
        standaloneMode= false;
    }else{
        standaloneMode= true;
    }
}

async function getLanguage() {
    try {
        let lang = localStorage.getItem("languageApp");
        if (!lang) {
          const [lang1] = navigator.language.split('-');
          lang = lang1.toUpperCase();
        }
        setTabulatorProps(prevState => ({
          ...prevState,
          languages: lang
        }));
        I18n.setLanguage(lang.toUpperCase());
      } catch (error) {
        I18n.setLanguage('EN');
        console.log(error.message);
      }
}

    /*const  UnseletedOption = () => {
        //const container = document.querySelector(listRef);
        const elements = document.querySelectorAll(`.btn__filters__active--component`);
        elements.forEach(el => {
            el.classList.remove("btn__filters__active--component");
        })
    }*/

const countBannerPromotions = async (statusLastSync) => {
    // const statusLastSync = this.state.tablesSync
    const supplier = await XSalesConsumer.getCurrentUser()
    const promotions = await XSalesData.Suppliers.GetSupplierBanner(supplier.supplier.id)
    const supplierBannerIndex = statusLastSync.findIndex(x => x.tableName === "supplierbanner");
    if (supplierBannerIndex !== -1) {
        let obj = statusLastSync;
        if(obj){
            obj[supplierBannerIndex].countRegister = promotions ? promotions.length : 0
        }
        //   this.setState({ tablesSync: obj })
    }
}

const countRegisterOfTable = async (tableName) => {
    return await XSalesConsumer.getCurrentUser()
        .then(async (data) => {
            return await XSalesData.BackendAPI.GetCountForTable(
                tableName,
                data.supplier.id
            )
                .then((data) => {
                    return data
                })
                .catch((err) => {
                    return 0
                })
        })
        .catch((err) => {
            console.log("countRegisterOfTable", err)
            return 0
        })
}

const compareValues = (key, order = "asc") =>{
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0
        }

        const varA =
            typeof a[key] === "string" ? a[key].toUpperCase() : a[key]
        const varB =
            typeof b[key] === "string" ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
            comparison = 1
        } else if (varA < varB) {
            comparison = -1
        }
        return order === "desc" ? comparison * -1 : comparison
    }
}

/*const fetchSyncLog = async (idnew) => {
    try {
        let statusLastSync = tabulatorProps.tablesSync
        XSalesConsumer.getCurrentUser()
            .then(async (data) => {
                const supplierId = data.supplier.id
                let sup = tabulatorProps.supplier
                sup.status = data.supplier.status

                setTabulatorProps(prevState => ({
                    ...prevState,
                    supplier: sup,
                }))

                Promise.all(statusLastSync.map(async (item) => {
                    const lastSync = await XSalesData.Suppliers.GetSyncLogTop(
                        supplierId,
                        item.tableName,
                        30
                    )
                        .then((lastSync) => {
                            return lastSync
                        })
                        .catch((err) => {
                            console.log("opprinting error ", err)
                            return []
                        })
                    item.countRegister = await countRegisterOfTable(
                        item.tableName
                    )
                    item.statusLastSync =
                        lastSync.length > 0
                            ? lastSync[0].status
                            : db.StatusSync.TODO
                    return item
                })
                )
                    .then((data) => {
                        countBannerPromotions(data)

                        setTabulatorProps(prevState => ({
                            ...prevState,
                            tablesSync: data,
                        }))
                        
                    })
                    .catch((err) => {
                        console.log("promise all catch err ", err)
                    })

                //Query for syncLog data of table
                await XSalesData.BackendAPI.GetSyncLog(supplierId, 100)
                    .then((syncLogData) => {
                        let obj = tabulatorProps.objData
                        let dataFormatted = syncLogData
                            .sort(compareValues("date", "desc"))
                            .map((item) => {
                                item.date = XSalesConsumer.getDateFormatter(
                                    item.date
                                )
                                item.status = I18n.get("lbl" + item.status)
                                return item
                            })
                        obj.Data = dataFormatted
                        obj.showLabelEmptyData = obj.length === 0 ? true : false
                        
                        setTabulatorProps(prevState => ({
                            ...prevState,
                            objData: obj,
                        }))
                        const elements = document.querySelectorAll(`.tabulator`);
                        elements.forEach(el => {
                            el.style="margin-top: 10px";
                        })
                        
                    })
                    .catch((err) => {
                        console.log("syncLogData catch", err)
                    })
            })
            .catch((err) => {
                console.log("supplier not found in localstorage")
            })
        // XSalesData.Suppliers.GetSyncLogTop()
    } catch (error) {
        console.log("error fetchSyncLog")
    }
}*/

/*const subscribeChange = async () => {
    //fetchSyncLog()
    await API.graphql(
        graphqlOperation(subscription.onCreateSyncLog)
    ).subscribe(() => {
        fetchSyncLog()
    })

    await API.graphql(
        graphqlOperation(subscription.onUpdateSyncLog)
    ).subscribe(() => {
        fetchSyncLog()
    })
}*/


const handleClickPostQuote = ()=>{
    window.$("#modalPostQuote").modal("toggle")
}

const handleClickPostOrder = ()=>{
    window.$("#modalPostOrder").modal("toggle")
}

const handleClickUpload = ()=>
{
    uploadData(table)
}

const handleClickSync = ()=>
{
    downloadData(table)
}


const uploadData = async (tableName) => {
    //this.setState({ tableWizard: tableName });
    window.$("#modalWizard").modal("toggle");
}


const handleCancelReset = (e) => 
{
    Entitystepper.reset()

}

return (
        <div>
            {loader ? (
                <LoaderWindow />
            ) : (
                <>
                <SeparateTop>

                
               <div className="row">
                      <div className="col-sm-12 col-md-12">
                                <div className="row">
                                <div className="col-sm-12 col-md-8"></div>
                                <div className="col-sm-12 col-md-2" style={{ textAlign:"right"}}>
                                <Button
                                        id="btnPostOrder"
                                        name="btnPostOrder"
                                        label={I18n.get("lblPostOrder")}
                                        className="bounds-button"
                                        onClick={handleClickPostOrder}
                                        outline= {true}
                                        size="sm"
                                        type= "button"/>  
                                </div>
                                <div className="col-sm-12 col-md-2">
                                
                                        <Button
                                        id="btnPostQuote"
                                        name="btnPostQuote"
                                        label={I18n.get("lblPostQuote")}
                                        className="bounds-button"
                                        onClick={handleClickPostQuote}
                                        outline= {true}
                                        size="sm"
                                        type= "button"
                                />

                                </div>

                                </div>
                        </div> 
                 </div>
                 <div className="row" style={{ marginTop: "15px"}}>
                        
                        <div className="col-sm-12 col-md-12">
                                <Filters 
                                    filterName="filterproduct"
                                    data={tabulatorProps.dataFilters} 
                                />
                        </div>

                </div>

                { (table !=="") && (
                <div className="row" style={{ marginTop: "15px"}}>
                      <div className="col-sm-12 col-md-12">
                      <ContainerCounter>
                                <div className="row" style={{ marginTop: "15px"}}>
                                        <div className="col-sm-12 col-md-1">
                                        <TextMessage 
                                            label={I18n.get("lblConsumerTotal")+": "+counter}
                                            size="18px"
                                            />
                                        </div>
                                        
                                        <div className="col-sm-12 col-md-4">
                                            {standaloneMode === false ? (
                                                <Button
                                                    id="bttnSync"
                                                    name="bttnSync"
                                                    label={I18n.get("lblSync")}
                                                    onClick={handleClickSync}
                                                    size="sm"
                                                    color="orange"
                                                    type= "button"
                                                />
                                            ):(
                                                <></>
                                            )}
                                        {(enabledUpload) && (
                                            <ContainerBtn>
                                                <Button
                                                    id="bttnUpload"
                                                    name="bttnUpload"
                                                    label={I18n.get("lblUpload")}
                                                    onClick={handleClickUpload}
                                                    size="sm"
                                                    color="orange"
                                                    type= "button"
                                                />
                                            </ContainerBtn>
                                        )}
                                        </div>
                        </div>
                        </ContainerCounter>
                    
                        </div>
                </div>
                )}
                { (table !=="") && (
                <div className="row">
                      <div className="col-sm-12 col-md-12">
                      <TabulatorTable
                                id="mainTable"
                                Title={""}
                                Data={tabulatorProps.objDataTable.Data}
                                Columns={tabulatorProps.objDataTable.Columns}
                                Filter={tabulatorProps.filter}
                                placeholder={loadingData.loadingData}
                                Icons={false}
                                labelEmptyData={loadingData.nodataavailable}
                                Height="35vh"
                                Search= {false}
                                languages={tabulatorProps.languages}
                                //RowClick={tabulatorProps.RowClick}
                        />     
                      </div>
                </div>
                )}
                
                <div className="row" style={{  marginTop: "10px"}}
                            >
                                <hr style={{ padding: "5px", color: "#c2c7d0"}}/>
                                <div className="col-sm-12 col-md-12">
                                        <Title label={ I18n.get("lblSyncLogs")} />
                                        <p className="text_information">{ I18n.get("lblSyncLogsShowLast5days")}</p>
                                        <Filters 
                                            filterName="filtersync"
                                            data={tabulatorProps.dataFiltersLogs} 
                                        />
                                    
                                    <TabulatorTable
                                        id="secondTable"
                                        Title=""
                                        Data={tabulatorProps.objData.Data}
                                        Columns={tabulatorProps.objData.Columns}
                                        Filter={tabulatorProps.objData.Filter}
                                       // RowClick={tabulatorProps.objDatobjData.RowClick}
                                        placeholder={loadingData.loadingData}
                                        labelEmptyData={loadingData.nodataavailable}
                                        columnsIcon={true} 
                                        Icons={false}
                                        Search= {false}
                                        languages={tabulatorProps.languages}
                                    />
                                </div>
                            </div>
                    </SeparateTop>
                    {/*  <div><pre>{JSON.stringify(dataProductsConsumers, null, 2) }</pre></div>  */}
                    <Modal
                                id="modalPostQuote"
                                title={I18n.get("lbltestpostquote")}
                                lblbtnCancel={I18n.get("lblclose")}
                                lblbtnSave={""}
                                handleCancel={(e) => { }}
                                handleSave={() => { }}
                                noAllowSave={true}
                                size="xl"
                            >
                                <PostOrder type="QUOTE" />
                    </Modal>
                    <Modal
                                id="modalPostOrder"
                                title={I18n.get("lbltestpostorder")}
                                lblbtnCancel={I18n.get("lblclose")}
                                lblbtnSave={""}
                                handleCancel={(e) => { }}
                                handleSave={() => { }}
                                noAllowSave={true}
                                size="xl"
                            >
                                <PostOrder />
                    </Modal>
                    <Modal
                                id="modalShowError"
                                title={I18n.get("lblShowError")}
                                lblbtnCancel={I18n.get("lblCancel")}
                                lblbtnSave={I18n.get("lblAccept")}
                                handleCancel={(e) => { }}
                                handleSave={() => { }}
                                size="lg"
                                noAllowSave={true}
                            >
                                {tabulatorProps.modalContent.length <= 0 ? (
                                    <img
                                        src={loadingLine}
                                        alt="loading line gif"
                                        style={{
                                            display: "flex",
                                            margin: "0 auto",
                                        }}
                                    />
                                ) : (
                                    tabulatorProps.modalContent.map((item) => {
                                        return (
                                            <p key={XSalesConsumer.generateQuickGuid()}>
                                                {item}
                                            </p>
                                        )
                                    })
                                )}
                            </Modal>
                            {(enabledUpload) &&(
                             <Modal
                                id="modalWizard"
                                title={`${I18n.get("lblimportwizard")} ${table}`}
                                lblbtnCancel={I18n.get("lblclose")}
                                lblbtnSave={""}
                                handleCancel={handleCancelReset}
                                handleSave={() => { }}
                                noAllowSave={true}
                                size="xl">
                                   
                                  <WizardImport setStepperRef={stepper => Entitystepper = stepper} table={table} />
                                  
                            </Modal>  
                            )}
                
                </>
            )}
        </div>
    )
}
const ContainerCounter = styled.div`
    padding-top: 40px;

`

const ContainerBtn = styled.button`
    background-color: white;
    margin-left: 10px;
    border: none;
`