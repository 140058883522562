import React from "react"

function InputText(props) {
    if (props.maxLength === null || props.maxLength === undefined)
        throw new Error("You must define maxLength")

    let isEditable =
        props.isEditable !== null && props.isEditable !== undefined
            ? props.isEditable
            : true

    return (
        <div className="form-group">
            <label htmlFor={props.id} className="label-control">
                {props.label}
                {props.required ? <div className="required">*</div> : ""}
                {props.messageError ? (
                    <div style={{ fontFamily: "cursive" }} className="required">
                        {props.messageError}
                    </div>
                ) : (
                    ""
                )}
            </label>
            {isEditable ? (
                <input
                    type="text"
                    maxLength={props.maxLength ? props.maxLength : 12}
                    className={
                        props.className ? props.className : "form-control"
                    }
                    name={props.name}
                    id={props.id}
                    defaultValue={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                />
            ) : (
                <label className="form-control-readonly" name={props.name}>
                    {props.value}
                </label>
            )}
        </div>
    )
}

export default InputText
