import React, { useState, useEffect, Fragment } from "react"
import ImageCDN from "../configConsumer/imagesDefaultCDN.json"
import { Link } from "react-router-dom"
import { I18n } from "aws-amplify"
import { XSalesConsumer } from "../Utils/Utils"
import { useLocation } from 'react-router-dom'
import { XSalesData } from "../DataQuery/XSalesData"
//import { MyAppContext } from '../context'
export default function Main(props) {
    //const { language } = React.useContext(MyAppContext)
    const { pathname } = useLocation();
    const [userRole, setUserRole] = useState("SUPPLIER")
    const [showOrderManagment, setShowOrderManagment] = useState(false)
    const [showPromotions, setShowPromotions] = useState(false)
    const [showAdminProducts, setShowAdminProducts] = useState(false)

    useEffect(() => {
        getOrderManagment()
        getPromotions()
        getAdminProducts()  

    }, [])

    async function getOrderManagment() {
        const CW021 = await XSalesData.Setup.getSetupFromCache('CW021')
        setShowOrderManagment(CW021?.value === 'true' ? true : false)
    }

    async function getPromotions() {
        const CW022 = await XSalesData.Setup.getSetupFromCache('CW022')
        setShowPromotions(CW022?.value === 'true' ? true : false)
    }

    async function getAdminProducts() {
        const CW024 = await XSalesData.Setup.getSetupFromCache('CW024')
        setShowAdminProducts(CW024?.value === 'true' ? true : false)
    }

    const setOptionActive = () => {
        const element = document.querySelector(`a[href='${pathname}']:not(.brand-link`);
        if (element) element.classList.add("nav-link__active--custom");
    }

    const handleClickMenuAction = (e) => {
        const { currentTarget } = e;
        const location = window.location.protocol + '//' + window.location.host;
        const pathName = currentTarget.href.replace(location, "")
        if (currentTarget.href) {
            deleteOldActiveOption()
            const element = document.querySelector(`a[href='${pathName}']:not(.brand-link)`);
            if (element) element.classList.add("nav-link__active--custom");
        }
    }

    const deleteOldActiveOption = () => {
        const elements = document.querySelectorAll(`a.nav-link__active--custom:not(.brand-link)`);
        elements.forEach(el => {
            el.classList.remove("nav-link__active--custom");
        })
    }

    useEffect(() => {
        setOptionActive();
        let rol = "SUPPLIER"
        async function getRoleToApply() {
            rol = await XSalesConsumer.getCurrentUser()
                .then((data) => {
                    return data.type
                })
                .catch((err) => {
                    return "SUPPLIER"
                })
            setUserRole(rol)
        }
        getRoleToApply()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    // async function setLanguage(){
    //     try {
    //         const id = await XSalesConsumer.getCurrentUser().then((data) => {
    //             if (data.type.toUpperCase() !== "SUPPLIER") {
    //                 return data.user
    //             }
    //             return data.supplier.id
    //         }).catch(err => {
    //             return ''
    //         })

    //         const preferences = await XSalesData.Suppliers.GetPreferences(id)
    //         if(preferences.language){
    //              I18n.setLanguage(preferences.language.toUpperCase())
    //         }
    //     } catch (error) {
    //         console.log(error.message)
    //     }
    // }

    return (
        <aside className="main-sidebar sidebar-dark-primary">
            {/* Brand Logo */}
            <a href="/Main" className="brand-link">
                <img
                    src={ImageCDN.XSalesConsumerWebIsotipo}
                    alt="Consumer isotipo"
                    className="brand-image logoConsumerWeb"
                    style={{ opacity: ".8" }}
                />
                <span className="brand-text font-weight-light">
                    | consumer
                    {/* <img
                        src={ImageCDN.XSalesConsumerWebText}
                        alt="Consumer logo"
                        className="brand-image logoConsumerWeb"
                        style={{ opacity: ".8" }}
                    /> */}
                </span>
            </a>
            <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
                <nav className="mt-2">
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        {(userRole === "admin" ||
                            userRole === "support") ? (
                            <Fragment>
                                <li className="nav-item nav__item--custom">
                                    <Link to="/Admin/Dashboard" className=" nav-link nav-link--custom" onClick={handleClickMenuAction}>
                                        <i className="nav-icon fas fa-tachometer-alt" />
                                        <p>{I18n.get("lblDashboard")}</p>
                                    </Link>
                                </li>
                            </Fragment>

                        )
                            :
                            <li className="nav-item nav__item--custom">
                                <Link to="/Dashboard" className=" nav-link nav-link--custom" onClick={handleClickMenuAction}>
                                    <i className="nav-icon fas fa-tachometer-alt" />
                                    <p>{I18n.get("lblDashboard")}</p>
                                </Link>
                            </li>
                        }

                        <li className="nav-item has-treeview nav__item--custom">
                            <Link to="#" className=" nav-link nav-link--custom" onClick={handleClickMenuAction}>
                                <i className="nav-icon fas fa-users" />
                                <p>
                                    {I18n.get("lblConsumers")}
                                    <i className="right fas fa-angle-left" />
                                </p>
                            </Link>
                            <ul className="nav nav-treeview nav__item--custom">
                                {(userRole === "SUPPLIER") &&
                                    <li className="nav-item nav__sub__item">
                                        <Link 
                                            to="/Consumers/Index"
                                            className=" nav-link nav-link--custom"
                                            onClick={handleClickMenuAction}
                                        >
                                            <i className="fas fa-circle fa-circle-custom nav-icon" />
                                            <p>{I18n.get("lblList")}</p>
                                        </Link>
                                    </li>
                                }
                                {(userRole === "admin" ||
                                    userRole === "support") && (
                                        <Fragment>
                                            <li className="nav-item nav__item--custom nav__sub__item">
                                                <Link
                                                    to="/Admin/Consumer/Listing"
                                                    className=" nav-link nav-link--custom"
                                                    onClick={handleClickMenuAction}
                                                >
                                                    <i className="fas fa-circle fa-circle-custom nav-icon" />
                                                    <p>{I18n.get("lblConsumerListing")}</p>
                                                </Link>
                                            </li>
                                            <li className="nav-item nav__item--custom nav__sub__item">
                                                <Link
                                                    to="/Admin/Consumer/ListAll"
                                                    className=" nav-link nav-link--custom"
                                                    onClick={handleClickMenuAction}
                                                >
                                                    <i className="fas fa-circle fa-circle-custom nav-icon" />
                                                    <p>{I18n.get("lblListAll")}</p>
                                                </Link>
                                            </li>
                                        </Fragment>
                                    )}
                            </ul>
                        </li>

                        <li className="nav-item has-treeview nav__item--custom">
                            <Link to="#"
                                className=" nav-link nav-link--custom"
                                onClick={handleClickMenuAction}
                            >
                                <i className="nav-icon fas fa-store" />
                                <p>
                                    {I18n.get("lblSuppliers")}
                                    <i className="right fas fa-angle-left" />
                                </p>
                            </Link>
                            <ul className="nav nav-treeview nav__item--custom">
                                {(userRole === "SUPPLIER") &&
                                    <li className="nav-item nav__sub__item ">
                                        <Link
                                            to="/Suppliers/MySupplier"
                                            className=" nav-link nav-link--custom"
                                            onClick={handleClickMenuAction}
                                        >
                                            <i className="fas fa-circle fa-circle-custom nav-icon" />
                                            <p>{I18n.get("lblMyInfo")}</p>
                                        </Link>
                                    </li>
                                }
                                {(userRole === "SUPPLIER") &&
                                    <li className="nav-item nav__item--custom nav__sub__item">
                                        <Link
                                            to="/Suppliers/Advanced"
                                            className=" nav-link nav-link--custom"
                                            onClick={handleClickMenuAction}
                                        >
                                            <i className="fas fa-circle fa-circle-custom nav-icon" />
                                            <p>{I18n.get("lblSupplierAdvanced")}</p>
                                        </Link>
                                    </li>
                                }
                                {
                               (userRole === "SUPPLIER" && showPromotions) && (
                                <li className="nav-item nav__item--custom nav__sub__item">
                                    <Link
                                        to="/Suppliers/Banners"
                                        className=" nav-link nav-link--custom"
                                        onClick={handleClickMenuAction}
                                    >
                                        <i className="fas fa-circle fa-circle-custom nav-icon" />
                                        <p>{I18n.get("lblpromotionalbanners")}</p>
                                    </Link>
                                </li>
                               )
                                }
                                {(userRole === "admin" ||
                                    userRole === "support") && (
                                        <li className="nav-item nav__item--custom nav__sub__item">
                                            <Link
                                                to="/Admin/Suppliers/Index"
                                                className=" nav-link nav-link--custom"
                                                onClick={handleClickMenuAction}
                                            >
                                                <i className="fas fa-circle fa-circle-custom nav-icon" />
                                                <p>{I18n.get("lblList")}</p>
                                            </Link>
                                        </li>
                                    )}
                            </ul>
                        </li>
                        {(userRole === "SUPPLIER" && showAdminProducts) && (
                            <li className="nav-item has-treeview nav__item--custom">
                                <Link to="#"
                                    className=" nav-link nav-link--custom"
                                    onClick={handleClickMenuAction}
                                >
                                    <i className="nav-icon fas fa-shopping-bag" />
                                    <p>
                                        {I18n.get("lblProducts")}
                                        <i className="right fas fa-angle-left" />
                                    </p>
                                </Link>
                                <ul className="nav nav-treeview nav__item--custom">
                                    <li className="nav-item nav__sub__item ">
                                        <Link
                                            to="/AdminProducts/Category/Listing"
                                            className=" nav-link nav-link--custom"
                                            onClick={handleClickMenuAction}
                                        >
                                            <i className="fas fa-circle fa-circle-custom nav-icon" />
                                            <p>{I18n.get("lblCategory")}</p>
                                        </Link>
                                    </li>

                                    <li className="nav-item nav__sub__item ">
                                        <Link
                                            to="/AdminProducts/SubCategory/Listing"
                                            className=" nav-link nav-link--custom"
                                            onClick={handleClickMenuAction}>

                                            <i className="fas fa-circle fa-circle-custom nav-icon" />
                                            <p>{I18n.get("lblSubCategory")}</p>
                                        </Link>
                                    </li>

                                    <li className="nav-item nav__sub__item ">
                                        <Link
                                            to="/AdminProducts/Product/Listing"
                                            className=" nav-link nav-link--custom"
                                            onClick={handleClickMenuAction}
                                        >
                                            <i className="fas fa-circle fa-circle-custom nav-icon" />
                                            <p>{I18n.get("lblProduct")}</p>
                                        </Link>
                                    </li>
                                </ul>
                                </li>
                        )
                        }
                                {
                                    (showOrderManagment && userRole !== "admin") && (
                                        <li className="nav-item has-treeview nav__item--custom">
                                            <Link to="#"
                                                className=" nav-link nav-link--custom"
                                                onClick={handleClickMenuAction}
                                            >
                                                <i className="nav-icon fas fa-chart-pie" />
                                                <p>
                                                    {I18n.get("lblOrderMenu")}
                                                    <i className="right fas fa-angle-left" />
                                                </p>
                                            </Link>
                                            <ul className="nav nav-treeview nav__item--custom">
                                                <>
                                                    <li className="nav-item nav__sub__item">
                                                        <Link
                                                            to="/order/managment"
                                                            className=" nav-link nav-link--custom"
                                                            onClick={handleClickMenuAction}
                                                        >
                                                            <i className="far fa-circle fa-circle-custom nav-icon" />
                                                            <p>{I18n.get("lblordermanagment")}</p>
                                                        </Link>
                                                    </li>
                                                </>

                                            </ul>
                                        </li>
                                    )
                                }



                         

                        {(userRole === "admin" ||
                            userRole === "support") ? (
                            <>
                                <li className="nav-item has-treeview nav__item--custom">
                                    <Link to="#"
                                        className=" nav-link nav-link--custom"
                                        onClick={handleClickMenuAction}
                                    >
                                        <i className="nav-icon fas fa-chart-pie" />
                                        <p>
                                            {I18n.get("lblReports")}
                                            <i className="right fas fa-angle-left" />
                                        </p>
                                    </Link>
                                    <ul className="nav nav-treeview nav__item--custom">
                                        <>
                                            <li className="nav-item nav__sub__item">
                                                <Link
                                                    to="/Admin/Statement"
                                                    className=" nav-link nav-link--custom"
                                                    onClick={handleClickMenuAction}
                                                >
                                                    <i className="far fa-circle fa-circle-custom nav-icon" />
                                                    <p>{I18n.get("lblStatementDashboard")}</p>
                                                </Link>
                                            </li>
                                        </>

                                    </ul>
                                </li>


                                <li className="nav-item nav__item--custom">
                                    <Link to="/Admin/exchangeRate" className=" nav-link nav-link--custom" onClick={handleClickMenuAction}>
                                        <i className="nav-icon fas fa-chart-line"></i>
                                        <p>
                                            {I18n.get("lblexchangerate")}
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item nav__item--custom">
                                    <Link to="/Admin/Settings" className=" nav-link nav-link--custom" onClick={handleClickMenuAction}>
                                        <i className="nav-icon fas fa-user-cog" />
                                        <p>
                                            {I18n.get("lblSetting")}
                                            {/* <span className="right badge badge-danger">New</span> */}
                                        </p>
                                    </Link>
                                </li>
                            </>
                        )
                            :
                            <li className="nav-item nav__item--custom">
                                <Link to="/Settings" className=" nav-link nav-link--custom" onClick={handleClickMenuAction}>
                                    <i className="nav-icon fas fa-user-cog" />
                                    <p>
                                        {I18n.get("lblSetting")}
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </Link>
                            </li>
                        }


                            {/*<li className="nav-item nav__item--custom">
                                <Link to="/Admin/Roles/index" className=" nav-link nav-link--custom" onClick={handleClickMenuAction}>
                                    <i className="nav-icon fas fa-th" />
                                    <p>{I18n.get("lblRoles")}</p>
                                </Link>
                            </li>*/}
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
                {/* </Fragment>
        } */}
            </div>
            {/* /.sidebar */}
        </aside>
    )
}
