//implement queries, mutations and subscriptions from graphql API
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../../src/graphql/queries'
import * as mutations from '../../src/graphql/mutations'
import Bowser from 'bowser'
import * as db from './../models'
import PreferenceDefault from './../configConsumer/preferenceDefault.json'
import { XSalesConsumer } from './../Utils/Utils'
import { XSalesEmail } from './../TemplateEmails/SendEmailDispatcher'
import { I18n } from 'aws-amplify'
import TemplatePush from '../firebase/templatePush.json'
import setupDefault from '../configConsumer/setupDefault.json'
// import { ConsoleLogger } from "@aws-amplify/core
import { v4 as uuidv4 } from 'uuid'
import MailerService from '../services/mailer'

const Stopwatch = require('statman-stopwatch')

//const common = require("./common")
class ReturnData {
  data = null
  whiteList = [
    'promotions',
    'daysDelivery',
    'phones',
    'address',
    'imageInternalName',
    'imageExternalURL',
    'inactive',
    'inactiveParent',
    'productTags',
    'tags',
    'groups1',
    'groups2',
    'groups3',
    'paymentMethods',
    'productSameOrderClasses',
  ]

  constructor(data) {
    this.data = data
  }

  existModel(typeExist) {
    return db[typeExist] === undefined ? false : true
  }

  saveData = async (typeNameModel) => {
    try {
      //console.log("SAVEDATA: data from save data", this.data)
      try {
        delete this.data['createdAt']
      } catch (error) {}
      try {
        delete this.data['updatedAt']
      } catch (error) {}
      try {
        Object.keys(this.data).forEach((element) => {
          if (
            typeof this.data[element] === 'object' &&
            // (element !== "daysDelivery" || element !== "phones")
            this.whiteList.includes(element) === false
          ) {
            delete this.data[element]
          }
        })
        //console.log(this.data)
      } catch (error) {}
      // console.log("SAVEDATA: data from save data 2", this.data)
      const insert = await API.graphql(
        graphqlOperation(mutations['create' + typeNameModel], {
          input: this.data,
        }),
      )
      //console.log("SAVEDATA: insert data console log", insert)
      return insert['data'][Object.keys(insert['data'])[0]]
    } catch (error) {
      console.log('SAVEDATA: ERROR CATCH saveDate', error)
      XSalesConsumer.fireMessage(
        I18n.get('lblError'),
        I18n.get('msgErrorCreating'),
      )
    }
  }

  updateData = async (typeNameModel) => {
    try {
      //console.log("UPDATEDATA: data from update data", this.data)
      try {
        delete this.data['createdAt']
      } catch (error) {}
      try {
        delete this.data['updatedAt']
      } catch (error) {}
      //no podemos estar en este peo de estar actualizando este whitelist con cada campo que nace, que locura
      //estas vainas dinamicas tambuen joden, hay que dejarlo
      try {
        Object.keys(this.data).forEach((element) => {
          if (
            typeof this.data[element] === 'object' &&
            this.whiteList.includes(element) === false
          ) {
            delete this.data[element]
          }
        })
        /*console.log(
                    "updateData try daysDelivey || phones property exception to delete",
                    this.data
                )*/
      } catch (error) {}
      //console.log("data from update data 2", this.data)
      const update = await API.graphql(
        graphqlOperation(mutations['update' + typeNameModel], {
          input: this.data,
        }),
      )
      //console.log("update data console log", update)
      return update['data'][Object.keys(update['data'])[0]]
    } catch (error) {
      XSalesConsumer.fireMessage(
        I18n.get('lblError'),
        I18n.get('msgErrorUpdating'),
      )
      throw error
    }
  }

  deleteData = async (typeNameModel) => {
    try {
      //console.log("DELETE: data from update data", this.data)

      /*  try {
                delete this.data["_deleted"]
            } catch (error) { }
            try {
                delete this.data["_lastChangedAt"]
            } catch (error) { }
            try {
                delete this.data["createdAt"]
            } catch (error) { }
            try {
                delete this.data["updatedAt"]
            } catch (error) { }
            try {
                Object.keys(this.data).forEach((element) => {
                    if (
                        typeof this.data[element] === "object" &&
                        this.whiteList.includes(element) == false
                    ) {
                        delete this.data[element]
                    }
                })
                
            } catch (error) { }
            console.log("data from delete data 2", this.data) */
      const deleteData = await API.graphql(
        graphqlOperation(mutations['delete' + typeNameModel], {
          input: this.data,
        }),
      )
      console.log('delete  data console log', deleteData)
      return deleteData['data'][Object.keys(deleteData['data'])[0]]
    } catch (error) {
      XSalesConsumer.fireMessage(
        I18n.get('lblError'),
        I18n.get('msgErrorUpdating'),
      )
      throw error
    }
  }

  static formatData = (obj, excludedID = true) => {
    let objData = {}
    Object.assign(objData, obj)
    try {
      delete objData['createdAt']
    } catch (error) {}
    try {
      delete objData['updatedAt']
    } catch (error) {}
    try {
      delete objData['supplier']
    } catch (error) {}
    try {
      delete objData['consumer']
    } catch (error) {}
    if (excludedID === true) {
      try {
        delete objData['id']
      } catch (error) {}
    }
    try {
      Object.keys(objData).forEach((element) => {
        if (
          typeof objData[element] === 'object' &&
          this.fieldExceptionForObjectDelete(element)
        ) {
          delete objData[element]
        }
      })
    } catch (error) {}
    return objData
  }

  getDataObject() {
    //console.log("GETDATAOBJECT: input ", this.data)
    if (
      this.data === undefined ||
      this.data === null ||
      this.data['data'][Object.keys(this.data['data'])[0]] ===
        undefined ||
      this.data['data'][Object.keys(this.data['data'])[0]] === null
    ) {
      ///console.log("getDataObject in if data getDataObject")
      return null
    }

    return this.data['data'][Object.keys(this.data['data'])[0]]
  }

  getDataList() {
    //console.log("getDataList input ", this.data)
    if (
      this.data === undefined ||
      this.data === null ||
      this.data['data'][Object.keys(this.data['data'])[0]] ===
        undefined ||
      this.data['data'][Object.keys(this.data['data'])[0]] === null
    ) {
      //console.log("getDataList in if data getDataList")
      return []
    }

    return this.data['data'][Object.keys(this.data['data'])[0]][
      'items'
    ]
  }
}

export class ImageUrlTypes {
  static Types = {
    categories: 1,
    subcategories: 2,
    products: 3,
    logos: 4,
    banners: 5,
  }
}

class Constants {
  static Setup = {
    /** Language preference for SupplierConsumer */
    CW001: 'CW001',
    /** Timezone preference for SupplierConsumer */
    CW002: 'CW002',
    /** Minimum Order Amount */
    CW003: 'CW003',
    /** Minimum Order Key [bruto o neto del Minimum Order Amount]  */
    CW004: 'CW004',
    /** Calulation type [Neto o Bruto] */
    CW005: 'CW005',
    /** Catalog type for Show [Exclusive, Merge] */
    CW006: 'CW006',
    /** Endpoint Supplier API */
    CW007: 'CW007',
    /** Endpoint API KEY */
    CW008: 'CW008',
    /** Endpoint APY SECRET KEY */
    CW009: 'CW009',
    /** Endpoint Authentication Type*/
    CW016: 'CW016',
    /** Endpoint Basic User*/
    CW017: 'CW017',
    /** Endpoint Basic Password*/
    CW018: 'CW018',
    /** Stand Alone Mode*/
    CW019: 'CW019',
    /** Configured languages for the Supplier. ex. Full 4 languages: 1,2,3,4. Only 2 languages: 1,3. If no value asume Full 4 languages. */
    CW020: 'CW020',
    /** Order Managment */
    CW021: 'CW021',
    /** Promotions */
    CW022: 'CW022',
    /** List Price */
    CW023: 'CW023',
    /** Admin Products */
    CW024: 'CW024',
    /** Barcode Scanner */
    CW025: 'CW025',
    /** Check CustomerCatalog */
    CW026: 'CW026',
    /** Product Discount */
    CW027: 'CW027',
    /** Same Order Class */
    CW028: 'CW028',
    /** check Shipping Address */
    CW029: 'CW029',
    /** check Payment Method */
    CW031: 'CW031',
    /** Minimun Stock */
    CW030: 'CW030',
    /** Sync chunk size*/
    SY001: 'SY001',
    /** Base Url Cdn */
    SY002: 'SY002',
    /** suppliers folder name cdn */
    SY003: 'SY003',
    /** categories folder name cdn */
    SY004: 'SY004',
    /** subcategories folder name cdn */
    SY005: 'SY005',
    /** products folder name cdn */
    SY006: 'SY006',
    /** logos folder name cdn */
    SY007: 'SY007',
    /** banners folder name cdn */
    SY008: 'SY008',
    /** Min mobile app version */
    SY009: 'SY009',

    /** EMAIL SERVICE */
    SY011: 'SY011',
  }
}

class User {
  GetUserByUsername = async (username) => {
    let source = await API.graphql(
      graphqlOperation(queries.getUser, {
        username,
      }),
    )

    return new ReturnData(source).getDataObject()
    //return await DataStore.query(db.User, (c) => c.username("eq", username))
  }

  SetUser = async (objUser) => {
    try {
      // console.log("XSalesData registration set user", objUser)
      //detect browser used
      const browser = Bowser.getParser(window.navigator.userAgent)
      const currentBrowser = browser.getBrowser()
      // console.log("current Browser", currentBrowser)

      const userToSave = new db.User({
        username: objUser.username,
        name: objUser.name,
        lastName: objUser.lastName,
        lastSessionDate: new Date().toISOString(),
        alternateEmail: '',
        lastBrowser: `${currentBrowser.name} ${currentBrowser.version}`,
        lastDevice: '',
        lastIP: '',
        lastOS: '',
        email: objUser.email,
        userType: objUser.userType,
      })
      // console.log("userToSave", userToSave)
      return await new ReturnData(userToSave).saveData(db.User.name)
    } catch (error) {
      console.log(error)
    }
  }
}

class Setup {
  constructor() {
    this.cacheKey = 'supplierSetups'
    this.listSetups = [
      Constants.Setup.SY002,
      Constants.Setup.SY003,
      Constants.Setup.SY004,
      Constants.Setup.SY005,
      Constants.Setup.SY005,
      Constants.Setup.SY006,
      Constants.Setup.SY007,
      Constants.Setup.SY008,
      Constants.Setup.SY009,
    ]
    this.defaultValues = [
      {
        id: uuidv4(),
        setupId: Constants.Setup.SY002,
        nameLanguage1: 'Base Folder CDN',
        nameLanguage2: 'Base Folder CDN',
        nameLanguage3: 'Base Folder CDN',
        nameLanguage4: 'Base Folder CDN',
        type: 'STRING',
        value: 'cdn.xsalesconsumer.com',
      },
      {
        id: uuidv4(),
        setupId: Constants.Setup.SY003,
        nameLanguage1: 'suppliers folder CDN',
        nameLanguage2: 'suppliers folder CDN',
        nameLanguage3: 'suppliers folder CDN',
        nameLanguage4: 'suppliers folder CDN',
        type: 'STRING',
        value: 'suppliers',
      },
      {
        id: uuidv4(),
        setupId: Constants.Setup.SY004,
        nameLanguage1: 'categories folder CDN',
        nameLanguage2: 'categories folder CDN',
        nameLanguage3: 'categories folder CDN',
        nameLanguage4: 'categories folder CDN',
        type: 'STRING',
        value: 'categories',
      },
      {
        id: uuidv4(),
        setupId: Constants.Setup.SY005,
        nameLanguage1: 'subcategories folder CDN',
        nameLanguage2: 'subcategories folder CDN',
        nameLanguage3: 'subcategories folder CDN',
        nameLanguage4: 'subcategories folder CDN',
        type: 'STRING',
        value: 'subcategories',
      },
      {
        id: uuidv4(),
        setupId: Constants.Setup.SY006,
        nameLanguage1: 'products folder CDN',
        nameLanguage2: 'products folder CDN',
        nameLanguage3: 'products folder CDN',
        nameLanguage4: 'products folder CDN',
        type: 'STRING',
        value: 'products',
      },
      {
        id: uuidv4(),
        setupId: Constants.Setup.SY007,
        nameLanguage1: 'logos folder CDN',
        nameLanguage2: 'logos folder CDN',
        nameLanguage3: 'logos folder CDN',
        nameLanguage4: 'logos folder CDN',
        type: 'STRING',
        value: 'logos',
      },
      {
        id: uuidv4(),
        setupId: Constants.Setup.SY008,
        nameLanguage1: 'banners folder CDN',
        nameLanguage2: 'banners folder CDN',
        nameLanguage3: 'banners folder CDN',
        nameLanguage4: 'banners folder CDN',
        type: 'STRING',
        value: 'banners',
      },
    ]
  }

  async getById(setupId) {
    const result = await API.graphql(
      graphqlOperation(queries.getSetup, {
        setupId,
      }),
    )
    return new ReturnData(result).getDataObject()
  }

  SetSystemSetupDefaults = async () => {
    // console.log("setupDefault", JSON.stringify(setupDefault));
    //leer el json
    setupDefault.forEach(async (x) => {
      //console.log(x);
      const setup = await this.getById(x?.setupId)
      if (!setup?.setupId) {
        try {
          //const setSetupResult = await this.setSetup(x)
          //console.log(setSetupResult)
        } catch (error) {
          console.log(error)
        }
      }
    })
    //por cada item del arreglo, verificar si no existe
    //crear registro con la info del item
  }

  GetSupplierConsumerSetup = async (
    supplierId,
    consumerId,
    setupId,
  ) => {
    let resultSupplierConsummerSetup = await API.graphql(
      graphqlOperation(queries.getSupplierConsumerSetup, {
        supplierId,
        consumerId,
        setupId,
      }),
    )
    let SupplierConsummerSetup = new ReturnData(
      resultSupplierConsummerSetup,
    ).getDataObject()

    if (SupplierConsummerSetup === null) {
      //search in supplierSetup
      let resultSupplierSetup = await API.graphql(
        graphqlOperation(queries.getSupplierSetup, {
          supplierId,
          setupId,
        }),
      )
      let SupplierSetup = new ReturnData(
        resultSupplierSetup,
      ).getDataObject()

      if (SupplierSetup === null) {
        //search in Setup
        const resultSetup = await API.graphql(
          graphqlOperation(queries.getSetup, {
            setupId,
          }),
        )
        let Setup = new ReturnData(resultSetup).getDataObject()

        if (Setup === null) {
          return ''
        }
        return (await Setup) ? Setup.value : ''
      }
      return await SupplierSetup.value
    }
    return await SupplierConsummerSetup.value
  }

  async create(setup) {
    return await API.graphql(
      graphqlOperation(mutations.createSetup, { input: setup }),
    )
  }

  async setSetup(objSetup) {
    let setupId = objSetup.setupId
    const setupToSave = new db.Setup({
      setupId: setupId,
      nameLanguage1: objSetup.nameLanguage1,
      type: objSetup.type,
      value: objSetup.value,
    })

    const result = await API.graphql(
      graphqlOperation(queries.getSetup, {
        setupId,
      }),
    )
    let setupOld = new ReturnData(result).getDataObject()
    if (setupOld === null) {
      return await new ReturnData(setupToSave).saveData(db.Setup.name)
    } else {
      return await new ReturnData(setupToSave).updateData(
        db.Setup.name,
      )
    }
  }

  async getSetups(supplierId) {
    const setups = []
    try {
      const result = await API.graphql(
        graphqlOperation(queries.listSupplierSetups, {
          supplierId: supplierId,
        }),
      )
      const data = new ReturnData(result).getDataList()

      data.forEach((item) => {
        setups.push({ setupId: item.setupId, value: item.value })
      })

      window.localStorage.setItem(
        this.cacheKey,
        JSON.stringify(setups),
      )
    } catch (error) {
      console.error(error)
      return setups
    }
  }

  getSetupFromCache(setupId) {
    const cache = window.localStorage.getItem(this.cacheKey)
    if (cache) {
      let data = JSON.parse(cache)
      const [result] = data.filter((x) => x.setupId === setupId)
      return result
    }

    return null
  }

  async getPathsCDNSetups() {
    const setups = {}
    try {
      let operations = []
      this.listSetups.forEach((x) => {
        const result = API.graphql(
          graphqlOperation(queries.getSetup, {
            setupId: x,
          }),
        )
        operations.push(result)
      })

      let data = await Promise.all(operations)
      operations = []
      const createOperations = []

      data.forEach((x, index) => {
        let obj = new ReturnData(x).getDataObject()
        if (obj?.setupId == null) {
          const idx = index - 1
          createOperations.push(this.create(this.defaultValues[idx]))
          return
        }
        operations.push({ setupId: obj?.setupId, value: obj?.value })
      })
      // se crean los setups por defecto si no existen la primera vez.
      if (createOperations.length > 0) {
        data = await Promise.all(createOperations)
        data.forEach((x, index) => {
          let obj = new ReturnData(x).getDataObject()
          operations.push({
            setupId: obj?.setupId,
            value: obj?.value,
          })
        })
      }
      operations.forEach((element) => {
        setups[element.setupId] = element.value
      })
      return setups
    } catch (error) {
      console.error(error)
      return setups
    }
  }

  SetSystemSetupDefaults = async () => {
    // console.log("setupDefault", JSON.stringify(setupDefault));
    //leer el json
    setupDefault.forEach(async (x) => {
      //console.log(x);
      const setup = await this.getById(x?.setupId)
      if (!setup?.setupId) {
        try {
          //const setSetupResult = await this.setSetup(x)
          //console.log(setSetupResult)
        } catch (error) {
          console.log(error)
        }
      }
    })
    //por cada item del arreglo, verificar si no existe
    //crear registro con la info del item
  }
}

class Consumer {
  SetConsumerCurrency = async (consumerId, currency, symbol) => {
    const result = await API.graphql(
      graphqlOperation(queries.getConsumer, { id: consumerId }),
    )
    const source = new ReturnData(result).getDataObject()
    source.currencyISOErp = currency
    source.currencySymbol = symbol
    return await new ReturnData(source).updateData(db.Consumer.name)
  }

  getToken = async (consumerId) => {
    const consumer = await API.graphql(
      graphqlOperation(queries.getConsumer, { id: consumerId }),
    )
    const result = new ReturnData(consumer).getDataObject()
    const consumerSetup = await API.graphql(
      graphqlOperation(queries.getConsumerSetup, {
        consumerId: consumerId,
        setupId: 'M001',
      }),
    )
    const resultconsumerSetup = new ReturnData(
      consumerSetup,
    ).getDataObject()
    const user = await API.graphql(
      graphqlOperation(queries.getUser, {
        username: result.username,
      }),
    )
    const userData = new ReturnData(user).getDataObject()
    //console.log("getToken", userData)
    if (userData !== undefined && userData !== null) {
      return {
        token: userData.token,
        language: resultconsumerSetup.value,
      }
    } else {
      return { token: '', language: '' }
    }
  }

  AddConsumerDemo = async (supplierId, emailConsumer) => {
    try {
      const supplier = await API.graphql(
        graphqlOperation(queries.getSupplier, { id: supplierId }),
      )
      const supplierData = new ReturnData(supplier).getDataObject(
        db.Supplier.name,
      )
      const consumer = await API.graphql(
        graphqlOperation(queries.ConsumerByEmail, {
          email: emailConsumer,
        }),
      )
      const consumerData = new ReturnData(consumer).getDataList()

      if (
        supplierData !== undefined &&
        supplierData !== null &&
        consumerData !== undefined &&
        consumerData !== null &&
        consumerData[0] !== undefined &&
        consumerData[0] !== null
      ) {
        const SupConExist = await API.graphql(
          graphqlOperation(queries.ConsumerSupplierRealation, {
            supplierId: supplierData.id,
            consumerId: {
              eq: consumerData[0].id,
            },
          }),
        )

        const existSupplierConsumer = new ReturnData(
          SupConExist,
        ).getDataList()
        if (existSupplierConsumer.length > 0) {
          throw new Error('ErrorSupplierConsumerExist').message
        }

        const newSupplierConsumer = new db.SupplierConsumer({
          requestedOn: new Date().toISOString(),
          requestStatus: db.StatusSupplierRequest.VALIDATING,
          requestComments: I18n.get('lblRequestCommentDemo'),
          requestedBy: I18n.get('lblAutomaticAssignDemo'),
          blockedForSales: db.StatusForSales.UNLOCKED,
          supplierId: supplierData.id,
          consumerId: consumerData[0].id,
        })

        await API.graphql(
          graphqlOperation(mutations.createSupplierConsumer, {
            input: newSupplierConsumer,
          }),
        )
        XSalesConsumer.fireMessage(
          I18n.get('lblConsumerDemo'),
          I18n.get('lblConsumerDemoCreated'),
        )
      } else {
        throw new Error('ErrorSupplierConsumerEmailNotExist').message
      }
    } catch (error) {
      throw new Error(error).message
      // console.log('errttttttttttttttt', error)
      // XSalesConsumer.fireMessage(I18n.get('lblConsumerDemo'), I18n.get('lblErrorSupplierConsumerCatch'))
      // throw new Error('ErrorSupplierConsumerCatch')
    }
  }

  SetConsumerXSM = async (consumerId, isXSM) => {
    const result = await API.graphql(
      graphqlOperation(queries.getConsumer, {
        id: consumerId,
      }),
    )
    let source = new ReturnData(result).getDataObject()
    source.isXSM = isXSM

    //add logic for delete all supplierConsumer relashionship's between this consumer.isXSM == true and all supplier's XSM
    //apply only if consumer is XSM currently and it will be disabled as XSM Team
    if (source.isXSM === false) {
      //search all supplier.isXSM == true and consumer.id == consumerId
      //set supplierConsumer.status == Status.Deleted

      //get all suppliersconsumer by consumer
      const listSuppliersXSM = await API.graphql(
        graphqlOperation(queries.SuppliersByConsumer, {
          consumerId: consumerId,
        }),
      )
      const resultSuppliersXSM = new ReturnData(
        listSuppliersXSM,
      ).getDataList()
      //for each supplier, validate if it is XSM
      resultSuppliersXSM.forEach(async (element) => {
        //debugger
        const supplierXSM = await API.graphql(
          graphqlOperation(queries.getSupplier, {
            id: element.supplier.id,
          }),
        )
        const resultSupplierXSM = new ReturnData(
          supplierXSM,
        ).getDataObject()
        //debugger
        if (resultSupplierXSM.isXSM === true) {
          //delete relation in supplierConsumer table
          element.requestStatus = db.StatusSupplierRequest.DELETED
          //debugger
          new ReturnData(element).updateData(db.SupplierConsumer.name)
          //debugger
        }
      })
    }

    return await new ReturnData(source).updateData(db.Consumer.name)
  }

  UsernameIsConsumer = async (username) => {
    const consumerExist = await API.graphql(
      graphqlOperation(queries.getUser, { username: username }),
    )
    return new ReturnData(consumerExist).getDataObject()
  }

  GetConsumer = async (consumerId) => {
    const result = await API.graphql(
      graphqlOperation(queries.getConsumer, {
        id: consumerId,
      }),
    )
    let source = new ReturnData(result).getDataObject()
    return source
  }

  GetConsumers = async () => {
    const consumers = await API.graphql(
      graphqlOperation(queries.listConsumers),
    )
    return new ReturnData(consumers).getDataList()
  }

  GetConsumersWithDependencies = async () => {
    const Consumers = await API.graphql(
      graphqlOperation(queries.listConsumers),
    )
    const SupplierConsumer = await API.graphql(
      graphqlOperation(queries.listSupplierConsumers),
    )
    return {
      Consumers: new ReturnData(Consumers).getDataList(),
      SupplierConsumer: new ReturnData(
        SupplierConsumer,
      ).getDataList(),
    }
  }

  GetConsumersBySupplier = async (supplierId) => {
    let filter = {
      supplierId: {
        eq: supplierId,
      },
    }

    const consumers = await API.graphql(
      graphqlOperation(queries.listSupplierConsumers, {
        filter: filter,
        limit: 1000,
      }),
    )
    return new ReturnData(consumers).getDataList()
  }

  GetSupplierConsumerByIds = async (SupplierConsumerId) => {
    const consumerById = await API.graphql(
      graphqlOperation(queries.getSupplierConsumer, {
        id: SupplierConsumerId,
      }),
    )
    return new ReturnData(consumerById).getDataObject()
  }

  GetConsumerMinimunOrderConfiguration = async (
    supplierId,
    consumerId,
  ) => {
    let Set = new Setup()
    let SetupMOAmount = await Set.GetSupplierConsumerSetup(
      supplierId,
      consumerId,
      Constants.Setup.CW003,
    )
    let SetupMOKey = await Set.GetSupplierConsumerSetup(
      supplierId,
      consumerId,
      Constants.Setup.CW004,
    )
    return { SetupMOAmount, SetupMOKey }
  }
}

class Suppliers {
  async getGroups(supplierId) {
    const { groups1, groups2, groups3 } = await this.GetSupplier(
      supplierId,
    )
    return { groups1, groups2, groups3 }
  }

  SetSubCategory = async (obj) => {
    let objData = {}
    Object.assign(objData, obj)
    //debugger
    const result = await API.graphql(
      graphqlOperation(queries.getSubcategory, {
        supplierId: obj['supplierId'],
        subcategoryId: obj['subcategoryId'],
      }),
    )

    let source = new ReturnData(result).getDataObject()

    if (source === null) {
      await new ReturnData(
        new db.Subcategory({
          supplierId: obj['supplierId'],
          subcategoryId: obj['subcategoryId'],
          nameLanguage1: obj['nameLanguage1'],
          nameLanguage2: obj['nameLanguage2'],
          nameLanguage3: obj['nameLanguage3'],
          nameLanguage4: obj['nameLanguage4'],
          categoryId: obj['categoryId'],
        }),
      ).saveData(db.Subcategory.name)
    } else {
      return await new ReturnData(obj).updateData(db.Subcategory.name)
    }
  }

  getListCategories = async (supplierId) => {
    let categories = await API.graphql(
      graphqlOperation(queries.listCategorys, {
        supplierId,
      }),
    )

    return new ReturnData(categories).getDataObject()
  }

  GetSupplierLanguages = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()
    const CW020 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW020,
      true,
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()
    //console.log("END: GetSupplierLanguages. Duration:", duration)
    return CW020
  }

  GetSupplierSetups = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    //const duration = stopwatch.read(0)
    stopwatch.stop()
    //console.log("END: GetSupplierLanguages. Duration:", duration)
    return supplierSetups
  }

  GetSupplierStandaloneMode = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW019 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW019,
      false,
      'false',
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW019
  }

  GetSupplierListPriceMode = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW023 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW023,
      false,
      'false',
    )

    // const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW023
  }

  GetSupplierAdminProductsMode = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW024 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW024,
      false,
      'false',
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW024
  }

  GetSupplierMinimunStock = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW030 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW030,
      false,
      'false',
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW030
  }

  GetSupplierBarcodeScanner = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW025 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW025,
      false,
      'false',
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW025
  }

  GetSupplierCheckCustomerCatalog = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW026 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW026,
      false,
      'false',
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW026
  }

  GetSupplierActiveProductDiscount = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW027 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW027,
      false,
      'false',
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW027
  }

  GetSupplierActiveSameOrderClass = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW028 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW028,
      false,
      'false',
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW028
  }

  GetSupplierActiveShippingAddress = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW029 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW029,
      false,
      'false',
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW029
  }
  GetSupplierActivePaymentMethod = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()
    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )
    if (!result.data) {
      return
    }
    const supplierSetups = new ReturnData(result).getDataList()
    const CW031 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW031,
      false,
      'false',
    )
    //const duration = stopwatch.read(0)
    stopwatch.stop()
    return CW031
  }

  GetSupplierOrderManagement = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW021 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW021,
      false,
      'false',
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW021
  }

  GetSupplierActivePromotions = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW021 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW022,
      false,
      'false',
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW021
  }
  GetSupplierLanguage = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW020 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW020,
      false,
      '1,2,3,4',
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    // console.log("END: GetSupplierEndpoint. Duration:", duration)

    return CW020
  }

  SetSupplierCurrency = async (
    supplierId,
    currencyISO,
    currencySymbol,
  ) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplier, { id: supplierId }),
    )
    const source = new ReturnData(result).getDataObject()
    source.currencyISOErp = currencyISO
    source.currencySymbol = currencySymbol
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  SetSupplierCurrencyInternal = async (supplierId, currencyISO) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplier, { id: supplierId }),
    )
    const source = new ReturnData(result).getDataObject()
    source.currencyISO = currencyISO
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  GetSupplierContract = async (supplierId) => {
    const getSupplier = /* GraphQL */ `
      query GetSupplier($id: ID!) {
        getSupplier(id: $id) {
          id
          contract {
            current {
              since
              minTransaction {
                payment
                deposit
                order
              }
              fees {
                deposit
                payment
                order
              }
              plan
            }
            future {
              since
              minTransaction {
                payment
                deposit
                order
              }
              fees {
                deposit
                payment
                order
              }
              plan
            }
            history {
              since
              minTransaction {
                payment
                deposit
                order
              }
              fees {
                deposit
                payment
                order
              }
              plan
            }
          }
        }
      }
    `
    const result = await API.graphql(
      graphqlOperation(getSupplier, {
        id: supplierId,
      }),
    )
    return new ReturnData(result).getDataObject()
  }

  SetSupplierContract = async (
    supplierId,
    contract,
    typeContract = 'future',
  ) => {
    //console.log("setsuppliercontract parameter contract", contract)
    await XSalesData.Suppliers.GetSupplier(supplierId)
      .then(async (data) => {
        delete data['industries']
        delete data['updatedAt']
        if (!data.contract) {
          data.contract = {
            current: null,
            future: null,
            history: [],
          }
        }
        data.contract[typeContract] = contract

        await API.graphql(
          graphqlOperation(mutations.updateSupplier, { input: data }),
        )
      })
      .catch((err) => {
        console.log('error querying contract', err)
      })
  }

  GetTableDataBySupplier = async (
    tableName,
    supplierId,
    nroRegister = null,
  ) => {
    let listID = ''
    switch (tableName.toUpperCase()) {
      case 'PRODUCT':
        listID = 'listProducts'
        break

      case 'CATEGORY':
        listID = 'listCategorys'
        break

      case 'SUBCATEGORY':
        listID = 'listSubcategorys'
        break

      case 'CONSUMERPRODUCT':
        listID = 'listSupplierConsumerProducts'
        break

      case 'CUSTOMERBANNER':
        listID = 'POR DEFINIR'
        break

      default:
        listID = ''
        break
    }

    if (listID === '') {
      return
    }

    const result = await API.graphql(
      graphqlOperation(queries[listID], {
        supplierId: supplierId,
        limit: nroRegister !== null ? nroRegister : 5,
      }),
    )
    return new ReturnData(result).getDataList()
  }

  GetSyncLogById = async (Id) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSyncLog, {
        id: Id,
      }),
    )
    return new ReturnData(result).getDataObject()
  }

  GetSyncLogTop = async (supplierId, tableName, limit = 10, date) => {
    let table = tableName === 'supplierbanner' ? 'banner' : tableName
    const result = await API.graphql(
      graphqlOperation(queries.SyncLogBySupplier, {
        supplierId: supplierId,
        sortDirection: 'DESC',
        filter: {
          date: { gt: date },
          process: {
            eq: table,
          },
        },
        limit: limit,
      }),
    )
    return new ReturnData(result).getDataList()
  }

  GetSupplierConsumerBanner = async (supplierConsumerId) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplierConsumer, {
        id: supplierConsumerId,
      }),
    )

    const source = new ReturnData(result).getDataObject()

    let currentPromotions =
      source && source.promotions ? source.promotions : null
    return currentPromotions
  }

  GetSupplierPhones = async (supplierId) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )
    const source = new ReturnData(result).getDataObject()
    let phones = source && source.phones ? source.phones : null
    return phones
  }

  SetSupplierConsumerDaysDelivery = async (
    supplierConsumerId,
    objDaysDelivery,
  ) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplierConsumer, {
        id: supplierConsumerId,
      }),
    )
    let source = new ReturnData(result).getDataObject()
    source.daysDelivery = objDaysDelivery
    return await new ReturnData(source).updateData(
      db.SupplierConsumer.name,
    )
  }

  SetSupplierConsumerPercentage = async (
    supplierConsumerId,
    taxPercentage,
    discountPercentage,
  ) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplierConsumer, {
        id: supplierConsumerId,
      }),
    )
    let source = new ReturnData(result).getDataObject()
    source.taxPercentage = taxPercentage === '' ? 0 : taxPercentage
    source.discountPercentage =
      discountPercentage === '' ? 0 : discountPercentage
    return await new ReturnData(source).updateData(
      db.SupplierConsumer.name,
    )
  }

  SetSupplierrGroups = async (supplierId, group1, group2, group3) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplier, { id: supplierId }),
    )
    const source = new ReturnData(result).getDataObject()
    source.groups1 = group1
    source.groups2 = group2
    source.groups3 = group3

    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  SetSupplierPromotions = async (supplierId, promotions) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplier, { id: supplierId }),
    )
    const source = new ReturnData(result).getDataObject()
    source.promotions = promotions
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  SetConsumerSupplierGroups = async (
    supplierConsumerId,
    group1,
    group2,
    group3,
  ) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplierConsumer, {
        id: supplierConsumerId,
      }),
    )
    const source = new ReturnData(result).getDataObject()
    source.group1 = group1
    source.group2 = group2
    source.group3 = group3

    return await new ReturnData(source).updateData(
      db.SupplierConsumer.name,
    )
  }

  SetSupplierConsumerBanner = async (
    supplierConsumerId,
    newPromotions,
  ) => {
    try {
      const result = await API.graphql(
        graphqlOperation(queries.getSupplierConsumer, {
          id: supplierConsumerId,
        }),
      )

      const source = new ReturnData(result).getDataObject()
      const currentPromotions =
        source !== null && source.promotions ? source.promotions : []
      const newPromotionsBanners = []
      newPromotions.forEach((item) => {
        const found = (element) => element.id === item.id
        if (
          currentPromotions &&
          currentPromotions.length > 0 &&
          currentPromotions.some(found)
        ) {
          newPromotionsBanners.push(
            new db.Promotions({
              id: item.id,
              description: item.description,
              active: true,
              // imageUrl: item.imageUrl,
              imageExternalURL: item.imageExternalURL,
              imageInternalName: XSalesConsumer.arrayToLower(
                item.imageInternalName,
              ),
              since: item.since,
              until: item.until,
            }),
          )
        } else {
          newPromotionsBanners.push(
            new db.Promotions({
              id: XSalesConsumer.generateQuickGuid(),
              description: '',
              active: true,
              // imageUrl: item.imageUrl,
              imageExternalURL: item.imageExternalURL,
              imageInternalName: XSalesConsumer.arrayToLower(
                item.imageInternalName,
              ),
              since: XSalesConsumer.getDateFormatter(new Date()),
              until: XSalesConsumer.getDateFormatter(new Date()),
            }),
          )
        }
      })

      source.promotions = newPromotionsBanners
      return await new ReturnData(source).updateData(
        db.SupplierConsumer.name,
      )
    } catch (error) {
      console.log(error)
    }
  }

  GetSuppliersConsumerCalculation = async (
    supplierId,
    consumerId,
  ) => {
    let resultSupplierConsumerSetup = await API.graphql(
      graphqlOperation(queries.getSupplierConsumerSetup, {
        supplierId,
        consumerId,
        setupId: Constants.Setup.CW005,
      }),
    )
    let calculation = new ReturnData(
      resultSupplierConsumerSetup,
    ).getDataObject()

    let resultSupplierSetup = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW005,
      }),
    )
    let calculationGlobal = new ReturnData(
      resultSupplierSetup,
    ).getDataObject()

    return calculation === null
      ? calculationGlobal === null
        ? PreferenceDefault.CalculationMinimumOrder
        : calculationGlobal.value //search in setup table
      : calculation.value
  }

  GetSuppliersCatalogType = async (supplierId) => {
    let catalogTypeResult = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW006,
      }),
    )
    let catalogType = new ReturnData(
      catalogTypeResult,
    ).getDataObject()

    let catalogTypeGlobalResult = await API.graphql(
      graphqlOperation(queries.getSetup, {
        setupId: Constants.Setup.CW006,
      }),
    )
    let catalogTypeGlobal = new ReturnData(
      catalogTypeGlobalResult,
    ).getDataObject()

    return catalogType === null
      ? catalogTypeGlobal === null
        ? PreferenceDefault.CatalogType
        : catalogTypeGlobal.value //search in setup table
      : catalogType.value
  }

  GetSuppliersConsumerCatalogType = async (
    supplierId,
    consumerId,
  ) => {
    let catalogTypeResult = await API.graphql(
      graphqlOperation(queries.getSupplierConsumerSetup, {
        supplierId,
        consumerId,
        setupId: Constants.Setup.CW006,
      }),
    )
    let catalogType = new ReturnData(
      catalogTypeResult,
    ).getDataObject()

    let catalogTypeGlobalResult = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW006,
      }),
    )
    let catalogTypeGlobal = new ReturnData(
      catalogTypeGlobalResult,
    ).getDataObject()

    return catalogType === null
      ? catalogTypeGlobal === null
        ? PreferenceDefault.CatalogType
        : catalogTypeGlobal.value //search in setup table
      : catalogType.value
  }

  GetPreferences = async (supplierId) => {
    const languageResult = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW001,
      }),
    )

    let language = new ReturnData(languageResult).getDataObject()
    let timezoneResult = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW002,
      }),
    )
    let timezone = new ReturnData(timezoneResult).getDataObject()

    return {
      language:
        language && language === null
          ? PreferenceDefault.Language
          : language.value,
      timezone:
        timezone && timezone === null
          ? PreferenceDefault.TimeZone
          : timezone.value,
    }
  }

  SetMinimumOrderConfiguration = async (
    supplierId,
    consumerId,
    MinimumOrderAmount,
    MinimumOrderKey,
  ) => {
    let result03 = await API.graphql(
      graphqlOperation(queries.getSupplierConsumerSetup, {
        supplierId,
        consumerId,
        setupId: Constants.Setup.CW003,
      }),
    )

    let sourceMinimumOrderAmount = new ReturnData(
      result03,
    ).getDataObject()

    if (sourceMinimumOrderAmount === null) {
      await new ReturnData(
        new db.SupplierConsumerSetup({
          supplierId: supplierId,
          consumerId: consumerId,
          setupId: Constants.Setup.CW003,
          value: MinimumOrderAmount,
        }),
      ).saveData(db.SupplierConsumerSetup.name)
    } else {
      sourceMinimumOrderAmount.value = MinimumOrderAmount
      await new ReturnData(sourceMinimumOrderAmount).updateData(
        db.SupplierConsumerSetup.name,
      )
    }

    let result04 = await API.graphql(
      graphqlOperation(queries.getSupplierConsumerSetup, {
        supplierId,
        consumerId,
        setupId: Constants.Setup.CW004,
      }),
    )

    let sourceMinimumOrderKey = new ReturnData(
      result04,
    ).getDataObject()

    if (sourceMinimumOrderKey === null) {
      await new ReturnData(
        new db.SupplierConsumerSetup({
          supplierId: supplierId,
          consumerId: consumerId,
          setupId: Constants.Setup.CW004,
          value: MinimumOrderKey,
        }),
      ).saveData(db.SupplierConsumerSetup.name)
    } else {
      sourceMinimumOrderKey.value = MinimumOrderKey
      await new ReturnData(sourceMinimumOrderKey).updateData(
        db.SupplierConsumerSetup.name,
      )
    }
  }

  SetPreferences = async (supplierId, planguage, ptimezone) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW001,
      }),
    )

    let sourceLanguage = new ReturnData(result01).getDataObject()

    if (sourceLanguage === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW001,
          supplierId: supplierId,
          value: planguage,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      sourceLanguage.value = planguage
      await new ReturnData(sourceLanguage).updateData(
        db.SupplierSetup.name,
      )
    }
    I18n.setLanguage(planguage)

    let result02 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW002,
      }),
    )

    let sourceTimezone = new ReturnData(result02).getDataObject()

    if (sourceTimezone === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW002,
          supplierId: supplierId,
          value: ptimezone,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      sourceTimezone.value = ptimezone
      await new ReturnData(sourceTimezone).updateData(
        db.SupplierSetup.name,
      )
    }
  }

  SetSupplierStatusChange = async (objSupplier) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: objSupplier.id,
      }),
    )

    let source = new ReturnData(result).getDataObject()

    //Email for Supplier
    const objTemplateData = {
      supplierName: '',
      datetime: XSalesConsumer.getDateFormatter(new Date()),
    }
    let destinatarios = []
    let dataEmailSupplier = objTemplateData
    dataEmailSupplier.supplierName = source.businessName
    destinatarios.push(
      new XSalesEmail.Destination(source.email, dataEmailSupplier),
    )

    let param = new XSalesEmail.param(
      PreferenceDefault.EmailAccountNotification,
      destinatarios,
      'ActivateSupplier',
      objTemplateData,
    )
    let email = new XSalesEmail.SendMailDispatcher(param)
    email.sendMail((err, data) => {
      console.log(err, data)
    })

    if (
      objSupplier.status === db.StatusSupplier.ACTIVE &&
      source.approvedAt === null
    ) {
      source.approvedAt = new Date().toISOString()
    }

    if (objSupplier.status === db.StatusSupplier.CONFIGURING) {
      //assign in crewUser the SupplierConfig crew to user
      const existCU = await API.graphql(
        graphqlOperation(queries.getCrewUser, {
          crewId: 'SupplierConfig',
          username: source.username,
        }),
      )
      const exist = new ReturnData(existCU).getDataObject()

      if (exist === undefined || exist === null) {
        let objCrewUser = new db.CrewUser({
          crewId: 'SupplierConfig',
          username: source.username,
        })
        await API.graphql(
          graphqlOperation(mutations.createCrewUser, {
            input: objCrewUser,
          }),
        )
      }
    }
    source.status = objSupplier.status

    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  GetSupplierByUser = async (Username) => {
    /** Filter for search in table */
    let filter = {
      username: {
        eq: Username,
      },
    }

    const result = await API.graphql(
      graphqlOperation(queries.listSuppliers, {
        filter,
      }),
    )
    return new ReturnData(result).getDataList()
  }

  GetSupplier = async (supplierId) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )
    return new ReturnData(result).getDataObject()
  }

  GetSuppliers = async () => {
    const result = await API.graphql(
      graphqlOperation(queries.listSuppliers),
    )
    return new ReturnData(result).getDataList()
  }

  GetSupplierConsumerById = async (supplierConsumerId) => {
    const result = await API.graphql(
      graphqlOperation(queries.listSupplierConsumers, {
        id: supplierConsumerId,
      }),
    )
    //console.log("GET GetSupplierConsumerById", result)
    return new ReturnData(result).getDataList()
  }

  GetSupplierConsumer = async () => {
    const result = await API.graphql(
      graphqlOperation(queries.listSupplierConsumers),
    )
    //console.log("GET SUPPLIERCONSUMER", result)
    return new ReturnData(result).getDataList()
  }

  SetSupplierBasicInfo = async (supplierId, objSupplier) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )

    let source = new ReturnData(result).getDataObject()
    source.businessName = objSupplier.businessName
    source.name = objSupplier.name
    source.taxID = objSupplier.taxID
    source.website = objSupplier.website
    await new ReturnData(source).updateData(db.Supplier.name)
  }

  SetSupplierPercentage = async (
    supplierId,
    taxPercentage,
    discountPercentage,
  ) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )

    let source = new ReturnData(result).getDataObject()
    source.taxPercentage = taxPercentage === '' ? 0 : taxPercentage
    source.discountPercentage =
      discountPercentage === '' ? 0 : discountPercentage
    await new ReturnData(source).updateData(db.Supplier.name)
  }

  SetSupplierXSM = async (supplierId, isXSM) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )
    let source = new ReturnData(result).getDataObject()
    source.isXSM = isXSM
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  SetSupplierProductTags = async (supplierId, arrayNew) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )
    let source = new ReturnData(result).getDataObject()
    let array = []
    if (source.productTags !== null) {
      array = source.productTags
      array = array.concat(arrayNew)
    } else {
      array = arrayNew
    }

    source.productTags = array
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  SetSupplierProductSameOrderClasses = async (
    supplierId,
    arrayNew,
  ) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )
    let source = new ReturnData(result).getDataObject()
    let array = []
    if (source.productSameOrderClasses !== null) {
      array = source.productSameOrderClasses
      array = array.concat(arrayNew.value)
    } else {
      array.push(arrayNew.value)
    }

    source.productSameOrderClasses = array
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  SetSupplierStandAlone = async (supplierId, standAlone) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW019,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW019,
          supplierId: supplierId,
          value: standAlone,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = standAlone
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierActivePromotions = async (
    supplierId,
    ActivePromotions,
  ) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW022,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW022,
          supplierId: supplierId,
          value: ActivePromotions,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = ActivePromotions
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierActiveListPrice = async (
    supplierId,
    ActiveListPrice,
  ) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW023,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW023,
          supplierId: supplierId,
          value: ActiveListPrice,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = ActiveListPrice
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierActiveAdminProducts = async (
    supplierId,
    ActiveAdminProducts,
  ) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW024,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW024,
          supplierId: supplierId,
          value: ActiveAdminProducts,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = ActiveAdminProducts
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierActiveMinimunStock = async (
    supplierId,
    ActiveMinimunStock,
  ) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW030,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW030,
          supplierId: supplierId,
          value: ActiveMinimunStock,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = ActiveMinimunStock
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierActiveBarcodeScanner = async (
    supplierId,
    ActivebarcodeScanner,
  ) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW025,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW025,
          supplierId: supplierId,
          value: ActivebarcodeScanner,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = ActivebarcodeScanner
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierCheckCustomerCatalog = async (
    supplierId,
    ActiveCustomerCatalog,
  ) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW026,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW026,
          supplierId: supplierId,
          value: ActiveCustomerCatalog,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = ActiveCustomerCatalog
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierActiveProductDiscount = async (
    supplierId,
    ActiveProductDiscount,
  ) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW027,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW027,
          supplierId: supplierId,
          value: ActiveProductDiscount,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = ActiveProductDiscount
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierActiveSameOrderClass = async (
    supplierId,
    ActiveSameOrderClass,
  ) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW028,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW028,
          supplierId: supplierId,
          value: ActiveSameOrderClass,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = ActiveSameOrderClass
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierActiveShippingAddress = async (
    supplierId,
    ActiveShippingAddress,
  ) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW029,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW029,
          supplierId: supplierId,
          value: ActiveShippingAddress,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = ActiveShippingAddress
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierActivePaymentMethod = async (
    supplierId,
    ActivePaymentMethod,
  ) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW031,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW031,
          supplierId: supplierId,
          value: ActivePaymentMethod,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = ActivePaymentMethod
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierOrderManagement = async (
    supplierId,
    orderManagement,
  ) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW021,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW021,
          supplierId: supplierId,
          value: orderManagement,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = orderManagement
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierLenguage = async (supplierId, lenguages) => {
    let result01 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW020,
      }),
    )

    let source = new ReturnData(result01).getDataObject()
    if (source === null) {
      await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW020,
          supplierId: supplierId,
          value: lenguages,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = lenguages
      localStorage.setItem('supplierLanguages', lenguages)
      await new ReturnData(source).updateData(db.SupplierSetup.name)
    }
  }

  SetSupplierBanner = async (supplierId, imageInternalName) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )
    let source = new ReturnData(result).getDataObject()
    source.imageInternalName =
      XSalesConsumer.arrayToLower(imageInternalName)
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  GetSupplierBanner = async (supplierId) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )

    const source = new ReturnData(result).getDataObject()
    const currentPromotions =
      source && source.promotions ? source.promotions : null
    return currentPromotions
  }

  SetSupplierBannersPromotions = async (
    supplierId,
    newPromotions,
  ) => {
    const result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )

    const source = new ReturnData(result).getDataObject()
    const currentPromotions =
      source !== null && source.promotions ? source.promotions : []
    const newPromotionsBanners = []
    newPromotions.forEach((item) => {
      const found = (element) => element.id === item.id
      if (
        currentPromotions &&
        currentPromotions.length > 0 &&
        currentPromotions.some(found)
      ) {
        newPromotionsBanners.push(
          new db.Promotions({
            id: item.id,
            description: item.description,
            active: true,
            // imageUrl: item.imageUrl,
            since: item.since,
            until: item.until,
            imageExternalURL: item.imageExternalURL,
            imageInternalName: XSalesConsumer.arrayToLower(
              item.imageInternalName,
            ),
          }),
        )
      } else {
        newPromotionsBanners.push(
          new db.Promotions({
            id: XSalesConsumer.generateQuickGuid(),
            description: '',
            active: true,
            // imageUrl: item.imageUrl,
            imageExternalURL: item.imageExternalURL,
            imageInternalName: XSalesConsumer.arrayToLower(
              item.imageInternalName,
            ),
            since: XSalesConsumer.getDateFormatter(new Date()),
            until: XSalesConsumer.getDateFormatter(new Date()),
          }),
        )
      }
    })

    source.promotions = newPromotionsBanners
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  SetSupplierSummmary = async (supplierId, summary) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )

    let source = new ReturnData(result).getDataObject()
    source.summary = summary
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  SetSupplierAddress = async (supplierId, address) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )
    let source = new ReturnData(result).getDataObject()
    source.address = address
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  /** Set only phones section for Supplier */
  SetSupplierPhone = async (supplierId, phones) => {
    //console.log("setsupplierphone parameter phone", phones)

    await XSalesData.Suppliers.GetSupplier(supplierId)
      .then(async (data) => {
        delete data['industries']
        delete data['updatedAt']
        data.phones = phones

        await API.graphql(
          graphqlOperation(mutations.updateSupplier, { input: data }),
        )
      })
      .catch((err) => {
        console.log('error querying setsupplierphone', err)
      })
  }

  /** Set only Contact Info section for Supplier */
  SetSupplierContactInfo = async (
    supplierId,
    contactName,
    contactEmail,
  ) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )

    let source = new ReturnData(result).getDataObject()
    source.contactName = contactName
    source.contactEmail = contactEmail
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  SetSupplierPaymentMethods = async (supplierId, paymentMethods) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: supplierId,
      }),
    )

    let source = new ReturnData(result).getDataObject()
    source.paymentMethods = paymentMethods
    return await new ReturnData(source).updateData(db.Supplier.name)
  }

  /** Set a new Supplier */
  SetSupplier = async (objSupplier) => {
    let phonesList = []

    phonesList.push(
      new db.Phone({
        number: objSupplier.phones[0].number,
        type: objSupplier.phones[0].type,
        default: objSupplier.phones[0].default,
      }),
    )
    phonesList.push(
      new db.Phone({
        number: objSupplier.phones[1].number,
        type: objSupplier.phones[1].type,
        default: objSupplier.phones[1].default,
      }),
    )

    const supplierToSave = new db.Supplier({
      businessName: objSupplier.businessName,
      username: objSupplier.username,
      address: new db.Address({
        country: objSupplier.address.country,
        state: objSupplier.address.state,
        city: objSupplier.address.city,
        type: db.AddressType.MAIN,
        street1: objSupplier.address.street1,
        street2: objSupplier.address.street2,
        street3: objSupplier.address.street3,
        zipcode: objSupplier.address.zipcode,
        cityId: objSupplier.address.cityId,
        countryId: objSupplier.address.countryId,
        stateId: objSupplier.address.stateId,
        default: true,
      }),
      email: objSupplier.email,
      // imageUrl: objSupplier.imageUrl,
      imageInternalName: XSalesConsumer.arrayToLower(
        objSupplier.imageInternalName,
      ),
      imageExternalURL: null,
      name: objSupplier.name,
      status: db.StatusSupplier.VALIDATING,
      contactName: objSupplier.contactName,
      contactEmail: objSupplier.contactEmail,
      summary: objSupplier.summary,
      website: objSupplier.website,
      taxID: objSupplier.taxID,
      phones: phonesList,
      currencyISO: objSupplier.currencyISO,
      currencySymbol: objSupplier.currencySymbol,
      longitude: objSupplier.longitude,
      latitude: objSupplier.latitude,
      createdAt: new Date().toISOString(),
    })

    return await new ReturnData(supplierToSave).saveData(
      db.Supplier.name,
    )
  }

  assignERPCode = async (SupplierConsumerId, erpCode) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplierConsumer, {
        id: SupplierConsumerId,
      }),
    )

    let source = new ReturnData(result).getDataObject()

    source.consumerIdERP = erpCode
    return await new ReturnData(source).updateData(
      db.SupplierConsumer.name,
    )
  }

  //Update the request in table SupplierConsumer
  updateConsumerRequest = async (requestData) => {
    const whoChangeStatus = await XSalesData.User.GetUserByUsername(
      requestData.username,
    )
      .then((data) => {
        return data
      })
      .catch((err) => {
        return ''
      })

    let responseBy = ''
    if (Array.isArray(whoChangeStatus)) {
      responseBy =
        whoChangeStatus.length > 0
          ? `${whoChangeStatus[0].name} ${whoChangeStatus[0].lastName}`
          : ''
    } else {
      responseBy = `${whoChangeStatus.name} ${whoChangeStatus.lastName}`
    }

    let result = await API.graphql(
      graphqlOperation(queries.getSupplierConsumer, {
        id: requestData.id,
      }),
    )
    //aqui
    let supplierConsumer = new ReturnData(result).getDataObject()
    const supplierConsumerOriginal = { ...supplierConsumer }

    //Validación que no se puede aprobar un consumidor si no tiene asaignado su erpCode
    if (
      supplierConsumer.requestStatus ===
      db.StatusSupplierRequest.APPROVED
    ) {
      if (
        requestData.erpCode === undefined ||
        requestData.erpCode === null ||
        requestData.erpCode === ''
      ) {
        XSalesConsumer.fireMessage(
          'lblAssignStatusConsumer',
          'msgSetErpCodeBeforeChangeStatus',
        )
        return
      } else {
        if (supplierConsumer.responsedOn === null) {
          //por alguna razon, aqui se solicita sync los banners especificos de este consumidor
          XSalesData.BackendAPI.downloadConsumerBannerBySupplierIdAndConsumerId(
            supplierConsumer.supplier.id,
            supplierConsumer.consumer.id,
          )
            .then((data) => {
              //console.log("then data XSalesData", data)
            })
            .catch((err) => {
              console.log(
                'error donwloadConsumerBannerBySupplierId',
                err,
              )
            })
        }
      }
    }

    //asignar información de traza de quien y cuando se aprueba
    supplierConsumer.responsedOn = requestData.responsedOn
    supplierConsumer.responseComments = requestData.responseComments
    supplierConsumer.requestStatus = requestData.status
    supplierConsumer.responsedBy = responseBy

    //actualizcion en BD
    const objSave = await new ReturnData(supplierConsumer).updateData(
      db.SupplierConsumer.name,
    )

    /**************************************
        INICIO RUTINA ENVIO PUSH
        **************************************/
    //let templateName = ""
    //console.log("templateName", supplierConsumer, SupplierConsumer)
    let tokenConsumer = ''
    let languageConsumer = ''
    await XSalesData.Consumer.getToken(supplierConsumer.consumerId)
      .then((data) => {
        tokenConsumer = data.token
        languageConsumer = data.language.toUpperCase()
      })
      .catch((err) => {
        console.log('Error getToken', err)
      })

    const { templateName, templatePush } = getTemplates(requestData)

    //asume EN if languageConsumer is empty
    if (languageConsumer === '') {
      languageConsumer = 'EN'
    }

    //ESTE FUNCIONA, PERO USA UN METODO DEPRECADO
    await XSalesConsumer.firePushNotification(
      tokenConsumer,
      templatePush[languageConsumer].title,
      templatePush[languageConsumer].body.replace(
        '{SUPPLIER}',
        supplierConsumerOriginal.supplier.businessName,
      ),
      null,
    )

    /**************************************
        FIN RUTINA ENVIO PUSH
        **************************************/

    /**************************************
        INICIO RUTINA ENVIO EMAIL AL CONSUMER
        **************************************/

    //Email for Consumer
    // const objTemplateData = {
    //     supplierName: "",
    //     consumerName: "",
    //     datetime: XSalesConsumer.getDateFormatter(new Date()),
    // }
    //let destinatarios = []
    try {
      if (templateName !== '') {
        // let dataEmailConsumer = objTemplateData
        // dataEmailConsumer.supplierName = supplierConsumerOriginal.supplier.businessName
        // dataEmailConsumer.consumerName = supplierConsumerOriginal.consumer.businessName
        // destinatarios.push(new XSalesEmail.Destination(supplierConsumerOriginal.consumer.email, dataEmailConsumer))

        //let param = new XSalesEmail.param(PreferenceDefault.EmailAccountNotification, destinatarios, templateName, objTemplateData)
        //let email = new XSalesEmail.SendMailDispatcher(param)
        const emailDTO = {
          TO: [
            { emailAddress: supplierConsumerOriginal.consumer.email },
          ],
          TemplateName: templateName,
          TemplateData: JSON.stringify({
            supplier: supplierConsumerOriginal.supplier.businessName,
            consumer: supplierConsumerOriginal.consumer.businessName,
          }),
        }
        //await XSalesConsumer.emailNotification(emailDTO)

        const supplierSetups = await this.GetSupplierSetups(
          supplierConsumer.supplierId,
        )

        const mailerService = new MailerService(supplierSetups)

        await mailerService.emailNotification(emailDTO)

        // email.sendMail((err, data) => {
        //     console.log(err, data)
        // })
      }
    } catch (error) {
      console.error('Consumer Email Notification Error', error)
    }

    /**************************************
        INICIO RUTINA ENVIO PUSH AL SUPPLIER?
        **************************************/

    // try {
    //     if (templateName !== "") {
    //         //Email for Supplier
    //         let destinatariosSupplier = []
    //         let dataEmailSupplier = objTemplateData
    //         dataEmailSupplier.supplierName = supplierConsumerOriginal.supplier.businessName
    //         dataEmailSupplier.consumerName = supplierConsumerOriginal.consumer.businessName
    //         destinatariosSupplier.push(new XSalesEmail.Destination(supplierConsumerOriginal.supplier.email, dataEmailSupplier))

    //         let paramSupplier = new XSalesEmail.param(
    //             PreferenceDefault.EmailAccountNotification,
    //             destinatariosSupplier,
    //             "SupplierApproveConsumer",
    //             objTemplateData
    //         )
    //         let emailSupplier = new XSalesEmail.SendMailDispatcher(paramSupplier)
    //         emailSupplier.sendMail((err, data) => {
    //             console.log(err, data)
    //         })
    //     }
    // } catch (error) {
    //     console.error("Supplier Email Notification Error", error)
    // }

    return objSave
  }

  GetSupplierPromotionsBannerByIds = async (SupplierId) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplier, {
        id: SupplierId,
      }),
    )

    let supplier = new ReturnData(result).getDataObject()
    return await supplier[0].promotions
  }

  GetSupplierConsumerPromotionsBannerByIds = async (
    SupplierConsumerId,
  ) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplierConsumer, {
        id: SupplierConsumerId,
      }),
    )

    let supplierConsumer = new ReturnData(result).getDataObject()
    return await supplierConsumer[0].promotions
  }

  SetSupplierCalculationMinimumOrder = async (
    supplierId,
    calculation,
  ) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW005,
      }),
    )

    let source = new ReturnData(result).getDataObject()

    if (source === null) {
      return await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW005,
          supplierId: supplierId,
          value: calculation,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = calculation
      return await new ReturnData(source).updateData(
        db.SupplierSetup.name,
      )
    }
  }

  SetSupplierConsumerCalculationMinimumOrder = async (
    supplierId,
    consumerId,
    calculation,
  ) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplierConsumerSetup, {
        supplierId,
        consumerId,
        setupId: Constants.Setup.CW005,
      }),
    )

    let source = new ReturnData(result).getDataObject()

    if (source === null) {
      return await new ReturnData(
        new db.SupplierConsumerSetup({
          setupId: Constants.Setup.CW005,
          consumerId: consumerId,
          supplierId: supplierId,
          value: calculation,
        }),
      ).saveData(db.SupplierConsumerSetup.name)
    } else {
      source.value = calculation
      return await new ReturnData(source).updateData(
        db.SupplierConsumerSetup.name,
      )
    }
  }

  SetSupplierCatalogType = async (supplierId, catType) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW006,
      }),
    )

    let source = new ReturnData(result).getDataObject()

    if (source === null) {
      return await new ReturnData(
        new db.SupplierSetup({
          setupId: Constants.Setup.CW006,
          supplierId: supplierId,
          value: catType,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      source.value = catType
      return await new ReturnData(source).updateData(
        db.SupplierSetup.name,
      )
    }
  }

  SetSupplierConsumerCatalogType = async (
    supplierId,
    consumerId,
    catType,
  ) => {
    //console.log("xsales data", supplierId, consumerId, catType)
    let result = await API.graphql(
      graphqlOperation(queries.getSupplierConsumerSetup, {
        supplierId,
        consumerId,
        setupId: Constants.Setup.CW006,
      }),
    )

    let source = new ReturnData(result).getDataObject()
    if (source === null) {
      return await new ReturnData(
        new db.SupplierConsumerSetup({
          setupId: Constants.Setup.CW006,
          consumerId: consumerId,
          supplierId: supplierId,
          value: catType,
        }),
      ).saveData(db.SupplierConsumerSetup.name)
    } else {
      source.value = catType
      return await new ReturnData(source).updateData(
        db.SupplierConsumerSetup.name,
      )
    }
  }

  GetSupplierMinimumOrder = async (supplierId) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW003,
      }),
    )

    let orderAmount = new ReturnData(result).getDataObject()

    result = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW004,
      }),
    )

    let orderkey = new ReturnData(result).getDataObject()

    return {
      orderAmount: orderAmount === null ? '' : orderAmount.value,
      orderkey: orderkey === null ? '' : orderkey.value,
    }
  }

  SetSupplierMinimumOrder = async (
    supplierId,
    MinimumOrderAmount,
    MinimumOrderKey,
  ) => {
    let result03 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW003,
      }),
    )

    let sourceMinimumOrderAmount = new ReturnData(
      result03,
    ).getDataObject()

    if (sourceMinimumOrderAmount === null) {
      await new ReturnData(
        new db.SupplierSetup({
          supplierId: supplierId,
          setupId: Constants.Setup.CW003,
          value: MinimumOrderAmount,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      sourceMinimumOrderAmount.value = MinimumOrderAmount
      await new ReturnData(sourceMinimumOrderAmount).updateData(
        db.SupplierSetup.name,
      )
    }

    let result04 = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW004,
      }),
    )

    let sourceMinimumOrderKey = new ReturnData(
      result04,
    ).getDataObject()

    if (sourceMinimumOrderKey === null) {
      await new ReturnData(
        new db.SupplierSetup({
          supplierId: supplierId,
          setupId: Constants.Setup.CW004,
          value: MinimumOrderKey,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      sourceMinimumOrderKey.value = MinimumOrderKey
      await new ReturnData(sourceMinimumOrderKey).updateData(
        db.SupplierSetup.name,
      )
    }
  }

  GetSupplierSetup = async (supplierId, setupId) => {
    let ss = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId,
      }),
    )
    let ssData = new ReturnData(ss).getDataObject()
    return ssData
  }

  GetSetupValue = (setups, id, isOptional, defaultValue) => {
    let setup = setups.find(({ setupId }) => setupId === id)

    if (setup === undefined || !setup.value) {
      if (defaultValue) {
        setup = { value: defaultValue }
      } else if (isOptional) {
        setup = { value: null }
      } else {
        throw new Error(`Setup ${id} not defined`)
      }
    }

    return setup.value
  }

  // GetSupplierPreferences = async (supplierId) => {
  //     var stopwatch = new Stopwatch()
  //     stopwatch.start()

  //     let result = await API.graphql(
  //         graphqlOperation(queries.listSupplierSetups, {
  //             supplierId,
  //         })
  //     )

  //     if (!result.data) {
  //         return
  //     }

  //     const supplierSetups = new ReturnData(result).getDataList()

  //     const CW001 = this.GetSetupValue(
  //         supplierSetups,
  //         Constants.Setup.CW001,
  //         true,
  //         'EN'
  //     )
  //     const CW002 = this.GetSetupValue(
  //         supplierSetups,
  //         Constants.Setup.CW008,
  //         true
  //     )
  //     const CW009 = this.GetSetupValue(
  //         supplierSetups,
  //         Constants.Setup.CW009,
  //         true
  //     )
  //     const CW016 = this.GetSetupValue(
  //         supplierSetups,
  //         Constants.Setup.CW016,
  //         true
  //     )
  //     const CW017 = this.GetSetupValue(
  //         supplierSetups,
  //         Constants.Setup.CW017,
  //         true
  //     )
  //     const CW018 = this.GetSetupValue(
  //         supplierSetups,
  //         Constants.Setup.CW018,
  //         true
  //     )

  //     const duration = stopwatch.read(0)
  //     stopwatch.stop()

  //     //console.log("END: GetSupplierEndpoint. Duration:", duration)

  //     return {
  //         CW007,
  //         CW008,
  //         CW009,
  //         CW016,
  //         CW017,
  //         CW018,
  //     }
  // }

  GetSupplierEndpoint = async (supplierId) => {
    var stopwatch = new Stopwatch()
    stopwatch.start()

    let result = await API.graphql(
      graphqlOperation(queries.listSupplierSetups, {
        supplierId,
      }),
    )

    if (!result.data) {
      return
    }

    const supplierSetups = new ReturnData(result).getDataList()

    const CW007 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW007,
      true,
    )
    const CW008 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW008,
      true,
    )
    const CW009 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW009,
      true,
    )
    const CW016 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW016,
      true,
    )
    const CW017 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW017,
      true,
    )
    const CW018 = this.GetSetupValue(
      supplierSetups,
      Constants.Setup.CW018,
      true,
    )

    //const duration = stopwatch.read(0)
    stopwatch.stop()

    //console.log("END: GetSupplierEndpoint. Duration:", duration)

    return {
      CW007,
      CW008,
      CW009,
      CW016,
      CW017,
      CW018,
    }
  }

  SetSupplierEndpoint = async (supplierId, supplierEnpoint) => {
    //const setupsBD = await this.GetSupplierEndpoint(supplierId)
    //console.log(" CR SetSupplierEndpoint supplierEnpoint", supplierEnpoint)

    //obtener los valores de la bd, si están null es que no existe por lo que se crean nuevos, sino se actualizan
    let result = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW007,
      }),
    )
    const newCW007 = new ReturnData(result).getDataObject()

    result = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW008,
      }),
    )
    const newCW008 = new ReturnData(result).getDataObject()

    //obsoleto
    // result = await API.graphql(
    //     graphqlOperation(queries.getSupplierSetup, {
    //         supplierId,
    //         setupId: Constants.Setup.CW009,
    //     })
    // )
    // const newCW009 = new ReturnData(result).getDataObject()

    result = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW016,
      }),
    )
    const newCW016 = new ReturnData(result).getDataObject()

    result = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW017,
      }),
    )
    const newCW017 = new ReturnData(result).getDataObject()

    result = await API.graphql(
      graphqlOperation(queries.getSupplierSetup, {
        supplierId,
        setupId: Constants.Setup.CW018,
      }),
    )
    const newCW018 = new ReturnData(result).getDataObject()

    //actualización de cada setup
    this.UpdateSetup(
      newCW007,
      Constants.Setup.CW007,
      supplierId,
      supplierEnpoint.endpointSupplierApi,
    )
    this.UpdateSetup(
      newCW008,
      Constants.Setup.CW008,
      supplierId,
      supplierEnpoint.endpointSupplierApiKey,
    )
    this.UpdateSetup(
      newCW016,
      Constants.Setup.CW016,
      supplierId,
      supplierEnpoint.endpointAuthType,
    )
    this.UpdateSetup(
      newCW017,
      Constants.Setup.CW017,
      supplierId,
      supplierEnpoint.endpointAuthBasicUser,
    )
    this.UpdateSetup(
      newCW018,
      Constants.Setup.CW018,
      supplierId,
      supplierEnpoint.endpointAuthBasicPass,
    )
  }

  GetIfERPCodeIsValid = async (supplierId, erpCode) => {
    try {
      const url = `/suppliers/${supplierId}/customers/${erpCode}`
      const codigo = await API.get('backendapi', url)
        .then((data) => {
          if (
            data !== undefined &&
            data !== null &&
            data.length > 0
          ) {
            return data[0]
          } else if (
            data.data !== undefined &&
            data.data !== null &&
            data.data.length > 0
          ) {
            return data.data[0]
          } else {
            throw new Error(I18n.get('msgCustomerIdNotFound'))
          }
        })
        .catch((err) => {
          throw new Error(err)
        })

      return codigo
    } catch (err) {
      console.log('error searching erpCode:', err)
      throw new Error(I18n.get('msgCustomerIdNotFound')).message
    }
  }

  SetSupplierConsumerBlockedForSale = async (
    supplierConsumerId,
    blockedForSale,
  ) => {
    let result = await API.graphql(
      graphqlOperation(queries.getSupplierConsumer, {
        id: supplierConsumerId,
      }),
    )

    let source = new ReturnData(result).getDataObject()
    source.blockedForSales = blockedForSale
    return await new ReturnData(source).updateData(
      db.SupplierConsumer.name,
    )
  }

  UpdateSetup(setup, setupId, supplierId, setupVaue) {
    /*console.log(
            `setup: ${setup}, setupId:${setupId}, supplierId:${supplierId}, setupVaue:${setupVaue})`
        )*/
    if (setup === null) {
      new ReturnData(
        new db.SupplierSetup({
          supplierId: supplierId,
          setupId: setupId,
          value: setupVaue,
        }),
      ).saveData(db.SupplierSetup.name)
    } else {
      setup.value = setupVaue
      new ReturnData(setup).updateData(db.SupplierSetup.name)
    }
  }
}

class Label {
  GetAllLabelWeb = async () => {
    //TODO: agregar key LabelByWindow, le falta el queryName

    let result = await API.graphql(
      graphqlOperation(queries.listLabels, {
        filter: { windowId: db.TypeApp.WEB },
      }),
    )

    let source = new ReturnData(result).getDataList()

    return source
    // return await DataStore.query(db.Window, (w) =>
    //     w.type("eq", db.TypeApp.WEB)
    // )
  }

  // SetLabel = async () => {
  //     return await DataStore.save(db.Window.copyOf(source, updated => {
  //         updated.
  //     }))
  // }
}

class BackendAPI {
  GetSyncLog = async (supplierId, registers, date) => {
    let registerData = []
    await XSalesData.Suppliers.GetSyncLogTop(
      supplierId,
      'ImportProduct',
      100,
      date,
    )
      .then((data) => {
        data.map((item) => registerData.push(item))
      })
      .catch((err) => {})
    await XSalesData.Suppliers.GetSyncLogTop(
      supplierId,
      'ImportCategory',
      100,
      date,
    )
      .then((data) => {
        data.map((item) => registerData.push(item))
      })
      .catch((err) => {})
    await XSalesData.Suppliers.GetSyncLogTop(
      supplierId,
      'ImportSubcategory',
      100,
      date,
    )
      .then((data) => {
        data.map((item) => registerData.push(item))
      })
      .catch((err) => {})
    await XSalesData.Suppliers.GetSyncLogTop(
      supplierId,
      'product',
      100,
      date,
    )
      .then((data) => {
        data.map((item) => registerData.push(item))
      })
      .catch((err) => {})
    await XSalesData.Suppliers.GetSyncLogTop(
      supplierId,
      'category',
      100,
      date,
    ).then((data) => {
      data.map((item) => registerData.push(item))
    })
    await XSalesData.Suppliers.GetSyncLogTop(
      supplierId,
      'subcategory',
      100,
      date,
    ).then((data) => {
      data.map((item) => registerData.push(item))
    })
    await XSalesData.Suppliers.GetSyncLogTop(
      supplierId,
      'supplierbanner',
      100,
      date,
    ).then((data) => {
      data.map((item) => registerData.push(item))
    })
    return registerData.map((item) => {
      return XSalesData.ReturnData.formatData(item, false)
    })
  }

  GetCountForTable = async (tableName, supplierId) => {
    //console.log(tableName, supplierId)
    return await XSalesData.Suppliers.GetTableDataBySupplier(
      tableName,
      supplierId,
      100,
    )
      .then((dataFromTable) => {
        return dataFromTable.length === 100
          ? '100+'
          : dataFromTable.length
      })
      .catch((err) => {
        return 0
      })
  }

  downloadProductBySupplierId = async (supplierId) => {
    try {
      await API.put(
        'backendapi',
        `/suppliers/${supplierId}/product/sync`,
      )

      //console.log("products: ", products)
    } catch (err) {
      console.log('error creating products:', err)
    }
  }

  downloadCategoryBySupplierId = async (supplierId) => {
    try {
      await API.put(
        'backendapi',
        `/suppliers/${supplierId}/category/sync`,
      )
      //console.log("categories: ", categories)
    } catch (err) {
      console.log('error creating categories:', err)
    }
  }

  downloadSubCategoryBySupplierId = async (supplierId) => {
    try {
      await API.put(
        'backendapi',
        `/suppliers/${supplierId}/subcategory/sync`,
      )
      //console.log("subcategories: ", subcategories)
    } catch (err) {
      console.log('error creating subcategories:', err)
    }
  }

  downloadSupplierConsumerProductBySupplierId = async (
    supplierId,
  ) => {
    XSalesData.Consumer.GetConsumersBySupplier(supplierId)
      .then(async (data) => {
        await data.map(async (item) => {
          const SupplierConsumerId = item.id
          try {
            await API.put(
              'backendapi',
              `/suppliers/${supplierId}/supplierConsumerProduct/${SupplierConsumerId}/sync`,
            )
          } catch (err) {
            console.log('error creating ')
          }
        })
      })
      .catch((err) => {
        console.log(
          'no se pudo ejecutar porque el metodo fallo GetConsumersBySupplier',
        )
      })
  }

  downloadConsumerBannerBySupplierId = async (supplierId) => {
    XSalesData.Consumer.GetConsumersBySupplier(supplierId)
      .then(async (data) => {
        //console.log("downloadConsumerBannerBySupplierId then", data)
        Promise.all(
          data.map(async (item) => {
            await XSalesData.BackendAPI.downloadConsumerBannerBySupplierIdAndConsumerId(
              item.supplier.id,
              item.consumer.id,
            )
              .then((dataCB) => {
                //console.log(dataCB)
              })
              .catch((err) => {
                console.log(
                  'error promise.all downloadConsumerBannerBySupplierIdAndConsumerId',
                  err,
                )
              })
          }),
        )
          .then((datos) => {
            //console.log("Promise.all then datos", datos)
          })
          .catch((err) => {
            console.log('Promise.all catch', err)
          })
      })
      .catch((err) => {
        console.log(
          'error from downloadConsumerBannerBySupplierId XSalesData',
          err,
        )
      })
  }

  downloadConsumerBannerBySupplierIdAndConsumerId = async (
    supplierId,
    consumerId,
  ) => {
    try {
      await API.put(
        'backendapi',
        `/suppliers/${supplierId}/consumer/${consumerId}/banner/sync`,
      )

      /*console.log(
                "consumerBanner by supplierId and consumerId: ",
                consumerBanner
            )*/
    } catch (err) {
      console.log('error creating consumerBanner specific:', err)
    }
  }

  downloadSupplierBannerBySupplierId = async (supplierId) => {
    try {
      await API.put(
        'backendapi',
        `/suppliers/${supplierId}/banner/sync`,
      )

      //console.log("banners: ", banners)
    } catch (err) {
      console.log('error creating banners:', err)
    }
  }

  // getFillDataForConsumer = async (supplierId, consumerIdERP) => {
  //     //TODO: add logic for call fill data to new consumer approved by supplier
  // }
}

class Rol {
  GetRoles = async () => {
    let result = await API.graphql(
      graphqlOperation(queries.listCrews),
    )

    let source = new ReturnData(result).getDataList()

    return source
  }

  GetRolById = async (id) => {
    //console.log("getrolbyid parameter: ", id)
    let result = await API.graphql(
      graphqlOperation(queries.getCrew, {
        crewId: id,
      }),
    )

    return new ReturnData(result).getDataObject()
  }

  GetSecuritiesByCrew = async (crewId) => {
    let result = await API.graphql(
      graphqlOperation(queries.listCrewSecuritys, { crewId: crewId }),
    )

    return new ReturnData(result).getDataList()
  }

  GetSecurities = async () => {
    let result = await API.graphql(
      graphqlOperation(queries.listSecuritys),
    )

    return new ReturnData(result).getDataList()
  }

  SetRoleAndCrewSecurity = async (role, crewSecurity) => {
    //console.log("SetRoleAndCrewSecurity", role, crewSecurity)
    const roleToSave = new db.Crew({
      crewId: role.crewId,
      type: role.type,
      nameLanguage1: role.nameLanguage1,
      nameLanguage2: role.nameLanguage2,
      nameLanguage3: role.nameLanguage3,
      nameLanguage4: role.nameLanguage4,
    })

    await new ReturnData(roleToSave).updateData(db.Crew.name)

    if (crewSecurity !== undefined && crewSecurity.length > 0) {
      crewSecurity.map(async (item) => {
        const query = await API.graphql(
          graphqlOperation(queries.getCrewSecurity, {
            crewId: roleToSave.crewId,
            securityId: item.id,
          }),
        )

        const csExist = new ReturnData(query).getDataObject(
          db.CrewSecurity.name,
        )

        if (csExist === null) {
          const crewSecurityToSave = new db.CrewSecurity({
            crewId: role.crewId,
            securityId: item.id,
          })
          await new ReturnData(crewSecurityToSave).saveData(
            db.CrewSecurity.name,
          )
        } else {
          const crewSecurityToSave = new db.CrewSecurity({
            crewId: roleToSave.crewId,
            securityId: item.id,
          })
          await new ReturnData(crewSecurityToSave).updateData(
            db.CrewSecurity.name,
          )
        }
      })
    }
  }
}

class Order {
  async updateDeliveryDate(orderId, deliveryDate) {
    const entity = await this.getOrder(orderId)
    entity.deliveryOn = deliveryDate
    return await new ReturnData(entity).updateData(db.Order.name)
  }

  async updateDeliveryOrder(orderId, status) {
    const entity = await this.getOrder(orderId)
    entity.status =
      status.trim().toUpperCase() === db.OrderStatus.DELIVERED
        ? db.OrderStatus.DELIVERED
        : db.OrderStatus.PLACED
    entity.statusERP =
      status.trim().toUpperCase() === db.OrderStatus.DELIVERED
        ? db.OrderStatus.DELIVERED
        : db.OrderStatus.PLACED
    return await new ReturnData(entity).updateData(db.Order.name)
  }

  async getOrderBySupplierByDate(supplierId, date1, date2) {
    const result = await API.graphql(
      graphqlOperation(queries.OrdersBySupplierByDate, {
        supplierId,
        date: { between: [date1, date2] },
        limit: 1000,
      }),
    )
    return new ReturnData(result).getDataList()
  }

  async getOrderBySupplierByDeliveryDate(supplierId, date1, date2) {
    const result = await API.graphql(
      graphqlOperation(queries.OrdersBySupplierByDeliveryDate, {
        supplierId,
        deliveryOn: { between: [date1, date2] },
        limit: 1000,
      }),
    )
    return new ReturnData(result).getDataList()
  }

  async getOrderDetail(orderId) {
    const result = await API.graphql(
      graphqlOperation(queries.OrderProductsByDetail, {
        orderId: orderId,
      }),
    )
    return new ReturnData(result).getDataList()
  }

  async getOrder(orderId) {
    const result = await API.graphql(
      graphqlOperation(queries.getOrder, {
        id: orderId,
      }),
    )
    return result.data.getOrder
  }
}

class Statement {
  async getList(month, year) {
    const result = await API.graphql(
      graphqlOperation(queries.StatementByPeriod, {
        month,
        year: {
          eq: year,
        },
      }),
    )
    return new ReturnData(result).getDataList()
  }

  async getStatementByYearAndPeriod(year, month) {
    const result = await API.graphql(
      graphqlOperation(queries.StatementByYearAndPeriod, {
        year,
        month,
      }),
    )
    return new ReturnData(result).getDataList()
  }

  async getStatementBySupplier(supplierId, monthYear) {
    const statementBySupplier = /* GraphQL */ `
      query StatementBySupplier(
        $supplierId: ID
        $monthYear: ModelStatementBySupplierCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelStatementFilterInput
        $limit: Int
        $nextToken: String
      ) {
        StatementBySupplier(
          supplierId: $supplierId
          monthYear: $monthYear
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            year
            month
            supplierId
            supplier {
              id
              name
              businessName
              username
            }
            count {
              deposit
              payment
              order
            }
            amount {
              deposit
              payment
              order
              orderUSD
              depositUSD
              paymentUSD
            }
            avgItem {
              payment
              order
              deposit
            }
            avgAmount {
              payment
              order
              deposit
              orderUSD
              depositUSD
              paymentUSD
            }
            amount {
              payment
              order
              deposit
            }
            contractDetail {
              since
              plan
              fees {
                order
                deposit
                payment
              }
              minTransaction {
                order
                payment
                order
              }
            }
          }
        }
      }
    `

    const result = await API.graphql(
      graphqlOperation(statementBySupplier, {
        supplierId,
        monthYear,
      }),
    )
    return new ReturnData(result).getDataList()
  }

  async getStatementBySupplierPaddedMonth(
    supplierId,
    yearPaddedMonth,
  ) {
    const statementBySupplier = /* GraphQL */ `
      query StatementBySupplierYearPaddedMonth(
        $supplierId: ID
        $yearPaddedMonth: ModelStatementBySupplierYearPaddedMonthCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelStatementFilterInput
        $limit: Int
        $nextToken: String
      ) {
        StatementBySupplierYearPaddedMonth(
          supplierId: $supplierId
          yearPaddedMonth: $yearPaddedMonth
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            year
            month
            supplierId
            supplier {
              id
              name
              businessName
              username
            }
            count {
              deposit
              payment
              order
            }
            amount {
              deposit
              payment
              order
              orderUSD
              depositUSD
              paymentUSD
            }
            avgItem {
              payment
              order
              deposit
            }
            avgAmount {
              payment
              order
              deposit
              orderUSD
              depositUSD
              paymentUSD
            }
            amount {
              payment
              order
              deposit
            }
            contractDetail {
              since
              plan
              fees {
                order
                deposit
                payment
              }
              minTransaction {
                order
                payment
                order
              }
            }
          }
        }
      }
    `

    const result = await API.graphql(
      graphqlOperation(statementBySupplier, {
        supplierId,
        yearPaddedMonth,
      }),
    )
    return new ReturnData(result).getDataList()
  }
}

class Category {
  async saveImages(supplierId, categoryId, images) {
    if (supplierId && categoryId) {
      const entity = await this.getById(supplierId, categoryId)
      entity.imageInternalName = images
      return await new ReturnData(entity).updateData(db.Category.name)
    }
  }

  async list(supplierId) {
    const result = await API.graphql(
      graphqlOperation(queries.listCategorys, {
        supplierId,
        limit: 1000,
      }),
    )
    return new ReturnData(result).getDataList()
  }

  async getById(supplierId, categoryId) {
    const result = await API.graphql(
      graphqlOperation(queries.getCategory, {
        supplierId,
        categoryId,
      }),
    )
    return new ReturnData(result).getDataObject()
  }

  async edit(category) {
    const resultQuery = await API.graphql(
      graphqlOperation(queries.getCategory, {
        supplierId: category.supplierId,
        categoryId: category.categoryId,
      }),
    )

    const source = new ReturnData(resultQuery).getDataObject()
    const EntityCategory = {
      supplierId: category.supplierId,
      categoryId: category.categoryId,
      nameLanguage1: category.nameLanguage1,
      nameLanguage2: category.nameLanguage2,
      nameLanguage3: category.nameLanguage3,
      nameLanguage4: category.nameLanguage4,
      inactive: category.inactive,
      imageInternalName: source.imageInternalName,
      imageExternalURL: source.imageExternalURL,
      inactiveOn: category.inactiveOn,
    }

    return await new ReturnData(EntityCategory).updateData(
      db.Category.name,
    )
  }

  async create(category) {
    const EntityCategory = {
      supplierId: category.supplierId,
      categoryId: category.categoryId,
      nameLanguage1: category.nameLanguage1,
      nameLanguage2: category.nameLanguage2,
      nameLanguage3: category.nameLanguage3,
      nameLanguage4: category.nameLanguage4,
      inactive: category.inactive,
      imageInternalName: category.imageInternalName,
      imageExternalURL: category.imageExternalURL,
      inactiveOn: category.inactiveOn,
    }

    const result = await API.graphql(
      graphqlOperation(mutations.createCategory, {
        input: EntityCategory,
      }),
    )
    return result
  }
}
class Subcategory {
  async List(supplierId) {
    let subcategories = await API.graphql(
      graphqlOperation(queries.listSubcategorys, {
        supplierId,
        limit: 1000,
      }),
    )

    let categories = await API.graphql(
      graphqlOperation(queries.listCategorys, {
        supplierId,
        limit: 1000,
      }),
    )

    return {
      categories: new ReturnData(categories).getDataList(),
      subcategories: new ReturnData(subcategories).getDataList(),
    }
  }

  async ListByCategory(supplierId, categoryId) {
    let subcategories = await API.graphql(
      graphqlOperation(queries.listSubcategorys, {
        supplierId,
      }),
    )
    subcategories = new ReturnData(subcategories).getDataList()
    return subcategories.filter((x) => x.categoryId === categoryId)
  }

  async getById(supplierId, subcategoryId) {
    let SubCategories = await API.graphql(
      graphqlOperation(queries.getSubcategory, {
        supplierId,
        subcategoryId,
      }),
    )

    return new ReturnData(SubCategories).getDataObject()
  }

  async Edit(subCategory) {
    /*const resultQuery = await API.graphql(
            graphqlOperation(queries.getSubcategory, {
                supplierId: subCategory.supplierId,
                subcategoryId: subCategory.subcategoryId,
            })
        )*/
    //const source = new ReturnData(resultQuery).getDataObject()
    const EntitySubCategory = {
      id: subCategory.id,
      supplierId: subCategory.supplierId,
      categoryId: subCategory.categoryId,
      subcategoryId: subCategory.subcategoryId,
      nameLanguage1: subCategory.nameLanguage1,
      nameLanguage2: subCategory.nameLanguage2,
      nameLanguage3: subCategory.nameLanguage3,
      nameLanguage4: subCategory.nameLanguage4,
      imageExternalURL: subCategory.imageExternalURL,
      imageInternalName: subCategory.imageInternalName,
      inactive: subCategory.inactive,
      inactiveParent: subCategory.inactiveParent,
      inactiveOn: subCategory.inactiveOn,
      inactiveParentOn: subCategory.inactiveParentOn,
    }

    return await new ReturnData(EntitySubCategory).updateData(
      db.Subcategory.name,
    )
  }

  async Create(subCategory) {
    //const date = new Date().toISOString()
    const EntitySubCategory = {
      id: subCategory.id,
      supplierId: subCategory.supplierId,
      categoryId: subCategory.categoryId,
      subcategoryId: subCategory.subcategoryId,
      nameLanguage1: subCategory.nameLanguage1,
      nameLanguage2: subCategory.nameLanguage2,
      nameLanguage3: subCategory.nameLanguage3,
      nameLanguage4: subCategory.nameLanguage4,
      imageExternalURL: subCategory.imageExternalURL,
      imageInternalName: subCategory.imageInternalName,
      inactive: subCategory.inactive,
      inactiveParent: subCategory.inactiveParent,
      inactiveOn: subCategory.inactiveOn,
      inactiveParentOn: subCategory.inactiveParentOn,
    }
    return await new ReturnData(EntitySubCategory).saveData(
      db.Subcategory.name,
    )
  }
}

class Product {
  async updatePriceSupplierConsumer(supplierId, consumerId, obj) {
    const consumerIdProductIdUnit = {
      beginsWith: {
        consumerId,
        productId: obj.productId,
        unit: obj.unit,
      },
    }
    let products = await API.graphql(
      graphqlOperation(queries.listSupplierConsumerProducts, {
        supplierId,
        consumerIdProductIdUnit,
      }),
    )

    let source = new ReturnData(products).getDataObject()
    let entityProduct = {
      productId: obj.productId,
      supplierId: obj.supplierId,
      consumerId: consumerId,
      unit: obj.unit,
      price: parseFloat(obj.priceSpecial),
    }

    if (
      source === null ||
      source === undefined ||
      source.items.length < 1
    ) {
      return await new ReturnData(entityProduct).saveData(
        db.SupplierConsumerProduct.name,
      )
    } else {
      return await new ReturnData(entityProduct).updateData(
        db.SupplierConsumerProduct.name,
      )
    }
  }

  deletePriceSupplierConsumer = async (
    supplierId,
    consumerId,
    obj,
  ) => {
    const consumerIdProductIdUnit = {
      beginsWith: {
        consumerId,
        productId: obj.productId,
        unit: obj.unit,
      },
    }
    let products = await API.graphql(
      graphqlOperation(queries.listSupplierConsumerProducts, {
        supplierId,
        consumerIdProductIdUnit,
      }),
    )

    let source = new ReturnData(products).getDataObject()
    if (
      source !== null &&
      source !== undefined &&
      source.items.length > 0
    ) {
      await API.del(
        'backendapi',
        `/suppliers/${supplierId}/consumer/${consumerId}/product/${obj.productId}/unit/${obj.unit}`,
      )
    }

    return true
  }

  async saveImages(supplierId, productId, unit, images) {
    if (supplierId && productId && unit) {
      const entityProduct = await this.getById(
        supplierId,
        productId,
        unit,
      )
      entityProduct.imageInternalName = images
      return await new ReturnData(entityProduct).updateData(
        db.Product.name,
      )
    }
  }

  async List(supplierId) {
    let subcategories = await API.graphql(
      graphqlOperation(queries.listSubcategorys, {
        supplierId,
        limit: 10000,
      }),
    )

    let categories = await API.graphql(
      graphqlOperation(queries.listCategorys, {
        supplierId,
        limit: 10000,
      }),
    )

    let products = await API.graphql(
      graphqlOperation(queries.listProducts, {
        supplierId,
        limit: 10000,
      }),
    )

    return {
      categories: new ReturnData(categories).getDataList(),
      subcategories: new ReturnData(subcategories).getDataList(),
      products: new ReturnData(products).getDataList(),
    }
  }

  async ListProductsbySuppliers(supplierId) {
    let products = await API.graphql(
      graphqlOperation(queries.listProducts, {
        supplierId,
        limit: 10000,
      }),
    )

    return new ReturnData(products).getDataList()
  }

  async ListSupplierConsumerProductsByConsumer(
    supplierId,
    consumerId,
  ) {
    const consumerIdProductIdUnit = { beginsWith: { consumerId } }
    let products = await API.graphql(
      graphqlOperation(queries.listSupplierConsumerProducts, {
        supplierId,
        consumerIdProductIdUnit,
        limit: 10000,
      }),
    )
    return new ReturnData(products).getDataList()
  }

  async ListSupplierConsumer(supplierId, consumerId) {
    let subcategories = await API.graphql(
      graphqlOperation(queries.listSubcategorys, {
        supplierId,
        limit: 1000,
      }),
    )

    let categories = await API.graphql(
      graphqlOperation(queries.listCategorys, {
        supplierId,
        limit: 1000,
      }),
    )

    let products = await API.graphql(
      graphqlOperation(queries.listProducts, {
        supplierId,
        limit: 10000,
      }),
    )

    return {
      categories: new ReturnData(categories).getDataList(),
      subcategories: new ReturnData(subcategories).getDataList(),
      products: new ReturnData(products).getDataList(),
    }
  }

  async getById(supplierId, productId, unit) {
    let products = await API.graphql(
      graphqlOperation(queries.getProduct, {
        supplierId,
        productId,
        unit,
      }),
    )
    return new ReturnData(products).getDataObject()
  }

  async Create(prd) {
    try {
      const EntityProduct = {
        productId: prd.productId,
        supplierId: prd.supplierId,
        unit: prd.unit,
        subcategoryId: prd.subcategoryId,
        descriptionLanguage1: prd.descriptionLanguage1,
        descriptionLanguage2: prd.descriptionLanguage2,
        descriptionLanguage3: prd.descriptionLanguage3,
        descriptionLanguage4: prd.descriptionLanguage4,
        nameLanguage1: prd.nameLanguage1,
        nameLanguage2: prd.nameLanguage2,
        nameLanguage3: prd.nameLanguage3,
        nameLanguage4: prd.nameLanguage4,
        shortnameLanguage1: prd.shortnameLanguage1,
        shortnameLanguage2: prd.shortnameLanguage2,
        shortnameLanguage3: prd.shortnameLanguage3,
        shortnameLanguage4: prd.shortnameLanguage4,
        tags: prd.tags,
        price: prd.price,
        percentageDiscount: prd.percentageDiscount,
        amountDiscount: prd.amountDiscount,
        blockedForSales: prd.blockedForSales,
        blockedForSalesDate: prd.blockedForSalesDate,
        inactive: prd.inactive,
        inactiveParent: prd.inactiveParent,
        imageInternalName: prd.imageInternalName,
        imageExternalURL: prd.imageExternalURL,
        taxFree: prd.taxFree,
        inactiveOn: prd.inactiveOn,
        inactiveParentOn: prd.inactiveParentOn,
        barcode: prd.barcode,
        sameOrderClass: prd.sameOrderClass,
      }
      return await new ReturnData(EntityProduct).saveData(
        db.Product.name,
      )
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async Edit(prd) {
    const resultQuery = await API.graphql(
      graphqlOperation(queries.getProduct, {
        supplierId: prd.supplierId,
        productId: prd.productId,
        unit: prd.unit,
      }),
    )

    //const date = new Date()
    /* const FormatterDate =
            date.getFullYear() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getDate() +
            " " +
            date.getHours() +
            ":" +
            date.getMinutes() +
            ":" +
            date.getSeconds() */

    const source = new ReturnData(resultQuery).getDataObject()
    const entityProduct = {
      productId: prd.productId,
      supplierId: prd.supplierId,
      unit: prd.unit,
      subcategoryId: prd.subcategoryId,
      descriptionLanguage1: prd.descriptionLanguage1,
      descriptionLanguage2: prd.descriptionLanguage2,
      descriptionLanguage3: prd.descriptionLanguage3,
      descriptionLanguage4: prd.descriptionLanguage4,
      nameLanguage1: prd.nameLanguage1,
      nameLanguage2: prd.nameLanguage2,
      nameLanguage3: prd.nameLanguage3,
      nameLanguage4: prd.nameLanguage4,
      shortnameLanguage1: prd.shortnameLanguage1,
      shortnameLanguage2: prd.shortnameLanguage2,
      shortnameLanguage3: prd.shortnameLanguage3,
      shortnameLanguage4: prd.shortnameLanguage4,
      tags: prd.tags,
      price: prd.price,
      percentageDiscount: prd.percentageDiscount,
      amountDiscount: prd.amountDiscount,
      blockedForSales: prd.blockedForSales,
      blockedForSalesDate: prd.blockedForSalesDate,
      inactive: prd.inactive,
      inactiveParent: prd.inactiveParent,
      imageInternalName: source?.imageInternalName
        ? [...source.imageInternalName]
        : [],
      imageExternalURL: source?.imageExternalURL
        ? [...source.imageExternalURL]
        : [],
      taxFree: prd.taxFree,
      inactiveOn: prd.inactiveOn,
      inactiveParentOn: prd.inactiveParentOn,
      barcode: prd.barcode,
      sameOrderClass: prd.sameOrderClass,
    }
    return await new ReturnData(entityProduct).updateData(
      db.Product.name,
    )
  }
}

// Publicate all Properties for Class
class XData {
  Category = new Category()
  Subcategory = new Subcategory()
  Consumer = new Consumer()
  Suppliers = new Suppliers()
  User = new User()
  Label = new Label()
  Setup = new Setup()
  // Constants = new Constants()
  BackendAPI = new BackendAPI()
  Rol = new Rol()
  Statement = new Statement()
  Order = new Order()
  Product = new Product()
  ReturnData = ReturnData
  Constants = Constants
}

export let XSalesData = new XData()

function getTemplates(requestData) {
  let templates = { templateName: '', templatePush: '' }
  try {
    switch (requestData.status) {
      case db.StatusSupplierRequest.APPROVED:
        templates.templateName = 'consumer-consumer-approved-es'
        templates.templatePush = TemplatePush.ActivateConsumer

        // await XSalesConsumer.firePushNotification(
        //     tokenConsumer,
        //     TemplatePush.ActivateConsumer[languageConsumer].title,
        //     TemplatePush.ActivateConsumer[languageConsumer].body.replace("{SUPPLIER}", supplierConsumerOriginal.supplier.businessName),
        //     null
        // )
        break
      case db.StatusSupplierRequest.REJECTED:
        templates.templateName = 'consumer-consumer-rejected-es'
        templates.templatePush = TemplatePush.RejectedConsumer

        // await XSalesConsumer.firePushNotification(
        //     tokenConsumer,
        //     TemplatePush.RejectedConsumer[languageConsumer].title,
        //     TemplatePush.RejectedConsumer[languageConsumer].body.replace("{SUPPLIER}", supplierConsumerOriginal.supplier.businessName),
        //     null
        // )
        break
      case db.StatusSupplierRequest.VALIDATING:
        templates.templateName = 'ValidatingConsumer'
        templates.templatePush = TemplatePush.ValidatingConsumer

        // await XSalesConsumer.firePushNotification(
        //     tokenConsumer,
        //     TemplatePush.ValidatingConsumer[languageConsumer].title,
        //     TemplatePush.ValidatingConsumer[languageConsumer].body.replace("{SUPPLIER}", supplierConsumerOriginal.supplier.businessName),
        //     null
        // )
        break
      default:
        break
    }
  } catch (error) {
    console.error('Push Notification Error', error)
  }
  return templates
}
