import React from "react"
import { Tab, TabCell, LoaderWindow, Title } from "xsales-components"
import CustomerGeneral from "./GeneralInfo"
import CustomerPreferences from "./Preferences"
import CustomerAdditional from "./AdditionalInfo"
import CustomerBanner from "./Banners"
import DeliveryDays from "./DeliveryDays"
import { XSalesData } from "../../DataQuery/XSalesData"
import { XSalesConsumer } from "../../Utils/Utils"
import * as db from "./../../models"
import { I18n } from "aws-amplify"
import csc from "country-state-city"
import { ListPrice } from "./ListPrice"
import BreadcrumbPage from "../../componentsLayout/Breadcrumb"

let supplierConsumerId = ""
const shortid = require("shortid")
export default class Detail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pathRoot: "",
            standaloneMode: "false",
            loadWindow: true,
            sectionData: {},
            sectionDataMinimumOrder: {
                orderMinAmount: "",
                orderMinRule: "",
            },
            supplierConsumerId: "",
            sectionDataNetoBruto: [
                { Key: "net", Label: I18n.get("lblNeto"), value: true },
                { Key: "gross", Label: I18n.get("lblBruto"), value: false },
            ],
            sectionDataCatalog: [
                {
                    Key: "exclusive",
                    Label: I18n.get("lblExclusive"),
                    value: false,
                },
                { Key: "merge", Label: I18n.get("lblMerge"), value: false },
            ],
            tapOptions: [
                {
                    id: "dataGeneral",
                    label: I18n.get("lblGeneral"),
                    default: true,
                },
                {
                    id: "preference",
                    label: I18n.get("lblPreferences"),
                    default: false,
                },
                {
                    id: "banners",
                    label: I18n.get("lblBanners"),
                    default: false,
                },
                {
                    id: "delivery",
                    label: I18n.get("lblDeliveryDays"),
                    default: false,
                },
                {
                    id: "request",
                    label: I18n.get("lblRequest"),
                    default: false,
                },
                {
                    id: "ListPrice",
                    label: I18n.get("lblListPrices"),
                    default: false,
                },
            ],
        }
    }

    handleChangeState = (e) => {
        let sectionData = this.state.sectionData
        try {
            const { name, value } = e.target
            sectionData[name] = value
        } catch (error) {
            let obj = e.value
            sectionData["status"] = obj
        }
        this.setState({ sectionData: sectionData })
    }

    ChangeNetoBrutoConfig = async () => {
        let sdnb = this.state.sectionDataNetoBruto
        let calculation = sdnb.filter((x) => x.value === true)[0].Key
        let supplierId = await XSalesConsumer.getCurrentUser().then((data) => {
            return data.supplier.id
        })
        await XSalesData.Suppliers.SetSupplierConsumerCalculationMinimumOrder(supplierId, this.state.sectionData.consumerId, calculation)
            .then((data) => {
                //console.log(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    ChangeCurrencyConsumer = async () => {
        await XSalesData.Consumer.SetConsumerCurrency(
            this.state.sectionData.consumerId,
            this.state.sectionData.SelectedCurrency,
            this.state.sectionData.SelectedSymbol
        )
            .then((data) => {
                console.log(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleChangeGroup1 = (values) => {
        let sectionData = this.state.sectionData
        let arrayOld = sectionData.supplierGroup1
        if (values.isNew) arrayOld.push({ value: values.value, label: values.value })

        sectionData["supplierGroup1"] = arrayOld
        sectionData["group1"] = { value: values.value, label: values.value }
        this.setState({ sectionData: sectionData })
    }
    handleChangeGroup2 = (values) => {
        let sectionData = this.state.sectionData
        let arrayOld = sectionData.supplierGroup2
        if (values.isNew) arrayOld.push({ value: values.value, label: values.value })

        sectionData["supplierGroup2"] = arrayOld
        sectionData["group2"] = { value: values.value, label: values.value }
        this.setState({ sectionData: sectionData })
    }
    handleChangeGroup3 = (values) => {
        let sectionData = this.state.sectionData
        let arrayOld = sectionData.supplierGroup3
        if (values.isNew) arrayOld.push({ value: values.value, label: values.value })

        sectionData["supplierGroup3"] = arrayOld
        sectionData["group3"] = { value: values.value, label: values.value }
        this.setState({ sectionData: sectionData })
    }

    ChangePercentage = async () => {

        await XSalesData.Suppliers.SetSupplierConsumerPercentage(
            this.state.supplierConsumerId,
            this.state.sectionData.taxPercentage,
            this.state.sectionData.discountPercentage
        )
            .then((data) => {
                //console.log(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    ChangeCatalogConfig = async () => {
        let sdc = this.state.sectionDataCatalog
        let typeCat = sdc.filter((x) => x.value === true)[0].Key
        let supplierId = await XSalesConsumer.getCurrentUser().then((data) => {
            return data.supplier.id
        })

        await XSalesData.Suppliers.SetSupplierConsumerCatalogType(supplierId, this.state.sectionData.consumerId, typeCat)
            .then((data) => {
                //console.log(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    assignERPCode = async () => {
        let sd = this.state.sectionData
        if (sd.erpCode === undefined || sd.erpCode === null || sd.erpCode === "") {
            XSalesConsumer.fireMessage(I18n.get("lblERPCode"), I18n.get("msgFillErpCode"))
            return
        }
        //TODO: no mostrar boton de guardar hasta que obtenga la respuesta del API

        await XSalesData.Suppliers.assignERPCode(sd.id, sd.erpCode)
            .then((data) => {
                //console.log("assignERPCode data", data)
            })
            .catch((err) => {
                console.log("assignERPCode error", err)
            })
    }

    cancelERPCode = () => {
        // console.log('cancelERPCode', this.state.sectionData.erpCode)
    }

    CancelAssignStatus = () => {
        // console.log(e)
        // console.log('cancel assign status')
    }

    ChangeAssignStatus = async () => {
        let username = await XSalesConsumer.getCurrentUser().then((data) => {
            return data.username
        })
        let section = this.state.sectionData
        const standaloneMode = await XSalesData.Suppliers.GetSupplierStandaloneMode(section.supplierId)
        this.setState({ standaloneMode: standaloneMode })

        //if (section.erpCode === "" || section.erpCode === undefined || section.erpCode === null){
            /*if (section.status === db.StatusSupplierRequest.APPROVED  && standaloneMode === "false") {
                XSalesConsumer.fireMessage("lblAssignStatusConsumer", "msgSetErpCodeBeforeChangeStatus")
                return
            }*/
        //}else{
        // console.log("Consumer/Detail/ChangeAssignStatus [sectionData]", section)
        // console.log(" username", username)
            XSalesData.Consumer.GetSupplierConsumerByIds(section.id)
                .then(async (data) => {
                    // console.log(
                    //     "GetSupplierConsumerByIds",
                    //     data,
                    //     "section ",
                    //     section,
                    //     "data ",
                    //     data
                    // )
                    //const standaloneMode = await XSalesData.Suppliers.GetSupplierStandaloneMode(data.supplierId)
                    //this.setState({ standaloneMode: standaloneMode })

                    if (section.status === db.StatusSupplierRequest.APPROVED && !data.consumerIdERP && standaloneMode === "false") {
                        XSalesConsumer.fireMessage("lblAssignStatusConsumer", "msgSetErpCodeBeforeChangeStatus")
                        return
                    }

                    if (standaloneMode === "true" && (section.erpCode === "" || section.erpCode === undefined || section.erpCode === null)) {
                        let sd = this.state.sectionData
                        section.erpCode = shortid.generate()
                        await XSalesData.Suppliers.assignERPCode(sd.id, section.erpCode)
                    }

                    let obj = {
                        id: section.id,
                        responseComments: section.responseComments,
                        status: section.status,
                        username: username,
                        erpCode: section.erpCode,
                        responsedOn: new Date().toISOString(),
                    }
                    XSalesData.Suppliers.updateConsumerRequest(obj)
                        .then((data) => {
                            this.FillData()
                        })
                        .catch((err) => {
                            this.FillData()
                            console.log("assignStatus error", err)
                        })
                })
                .catch((err) => {
                    console.log("error ", err)
                })
        //}
    }

    CancelAssignDeliveryDays = () => {
        //console.log("not implemented CancelAssignDeliveryDays")
    }

    ChangeAssignDeliveryDays = async (objDaysDelivery) => {
        XSalesData.Suppliers.SetSupplierConsumerDaysDelivery(this.state.supplierConsumerId, objDaysDelivery)
            .then((data) => {
                //console.log("supplierConsumer saved", data)
            })
            .catch((err) => {
                console.log("Error saving SupplierConsumer", err)
            })
        // console.log("printing", objDaysDelivery)
        // console.log("not implemented ChangeAssignDeliveryDays")
    }

    ChangeNetoBruto = (e) => {
        const { id } = e.target
        let radioNetoBruto = this.state.sectionDataNetoBruto
        let rnb = radioNetoBruto.map((item) => {
            let obj = item
            obj.value = obj.Key.toUpperCase() === id.toUpperCase() ? true : false
            return obj
        })
        this.setState({ sectionDataNetoBruto: rnb })

        radioNetoBruto = this.state.sectionDataNetoBruto
    }

    handleChangeCatalogType = (e) => {
        const { id } = e.target
        let radioCatalog = this.state.sectionDataCatalog
        let rc = radioCatalog.map((item) => {
            let obj = item
            obj.value = obj.Key.toUpperCase() === id.toUpperCase() ? true : false
            return obj
        })
        this.setState({ sectionDataCatalog: rc })
    }

    handleChangeCatalogTypeExclusive = async () => {
        const id = "exclusive"
        let radioCatalog = this.state.sectionDataCatalog
        let rc = radioCatalog.map((item) => {
            let obj = item
            obj.value = obj.Key.toUpperCase() === id.toUpperCase() ? true : false
            return obj
        })

        this.setState({ sectionDataCatalog: rc })
    }

    handleChangeCurrency = (e) => {
        const { id } = e.target
        let radioCatalog = this.state.sectionDataCatalog
        console.log(radioCatalog)
        let rc = radioCatalog.map((item) => {
            let obj = item
            obj.value = obj.Key.toUpperCase() === id.toUpperCase() ? true : false
            return obj
        })
        this.setState({ sectionDataCatalog: rc })
    }

    //begin minimum order
    handleChangeMinimumOrder = (e) => {
        let sectionData = this.state.sectionDataMinimumOrder
        try {
            const { name, value } = e.target
            sectionData[name] = value
        } catch (error) {}
        this.setState({ sectionDataMinimumOrder: sectionData })
    }

    CancelMinimumConfig = () => {
        // console.log('Cancel drop size')
    }

    ChangeMinimumConfig = async () => {
        debugger
        let supplierId = await XSalesConsumer.getCurrentUser().then((data) => {
            return data.supplier.id
        })
        let sdmo = this.state.sectionDataMinimumOrder
        if (isNaN(sdmo.SetupMOAmount)){
            sdmo.SetupMOAmount= 0; 
        };

        await XSalesData.Suppliers.SetMinimumOrderConfiguration(supplierId, this.state.sectionData.consumerId, sdmo.orderMinAmount, sdmo.orderMinRule)
            .then((data) => {
                //console.log("data ChangeMinimumConfig", data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    //end minimum order

    //begin BlockedForSale
    handleChangeBlockedForSale = (e) => {
        let sectionData = this.state.sectionData
        sectionData["blockedForSale"] = e.value
        this.setState({ sectionData: sectionData })
    }

    CancelBlockedForSale = () => {
        // console.log('Cancel drop size')
    }

    ChangeBlockedForSale = async () => {
        let sd = this.state.sectionData
        await XSalesData.Suppliers.SetSupplierConsumerBlockedForSale(
            sd.id, //SupplierConsumer.id
            sd.blockedForSale
        )
            .then((data) => {
                this.FillData()
                // console.log(
                //     "data for SupplierConsumer.blockedForSale is ok",
                //     data
                // )
            })
            .catch((err) => {
                console.log(err)
            })
    }
    //end BlockedForSale

    getPhoneType = (listPhone, type) => {
        if (listPhone !== null) {
            let typePhone = listPhone.filter((x) => x.type.toString().toUpperCase() === type.toString().toUpperCase())
            if (typePhone.length > 0) {
                return typePhone[0].number
            }
            return ""
        } else {
            return ""
        }
    }

    getAddressId(id, type) {
        let value = ""
        switch (type) {
            case "city":
                value = csc.getCityById(id).name
                break
            case "state":
                value = csc.getStateById(id).name
                break
            case "country":
                value = csc.getCountryById(id).name
                break
            default:
                break
        }
        return value ? value : ""
    }

    formattAddress = (consumer) => {
        let addressFormatt = consumer.street1
        if (consumer.street2 !== undefined && consumer.street2 !== null && consumer.street2 !== "") {
            addressFormatt += ", " + consumer.street2
        }
        if (consumer.street3 !== undefined && consumer.street3 !== null && consumer.street3 !== "") {
            addressFormatt += ", " + consumer.street3
        }
        let city = consumer.city !== "" ? this.getAddressId(consumer.cityId, "city") : ""
        // console.log(city)
        let state = consumer.state !== "" ? (city !== "" ? ", " : " ") + this.getAddressId(consumer.stateId, "state") + ". " : ". "
        // console.log(state)
        let country = consumer.country !== "" ? this.getAddressId(consumer.countryId, "country") + ". " : ""
        // console.log(country)
        addressFormatt += ". " + city + state + consumer.zipcode + ". " + country

        return addressFormatt
    }

    FillData = async () => {
        supplierConsumerId = this.props.match.params.id
        let XSM = false
        XSM = await XSalesConsumer.getCurrentUser()
            .then((data) => {
                return data.type === "admin" ? true : false
            })
            .catch((err) => {
                return false
            })
        await this.setState({
            supplierConsumerId: supplierConsumerId,
            tabXSMActive: XSM,
        })

        if (XSM) {
            this.setState({
                tapOptions: [
                    {
                        id: "tapxsm",
                        label: I18n.get("lblXSM"),
                        default: true,
                    },
                ],
            })
        }

        await XSalesData.Consumer.GetSupplierConsumerByIds(supplierConsumerId)
            .then(async (data) => {
                const standaloneMode = await XSalesData.Suppliers.GetSupplierStandaloneMode(data.supplierId)
                this.setState({ standaloneMode: standaloneMode })
                let consumerSupplier = data
                let consumer = {
                    id: consumerSupplier.id,
                    businessName: consumerSupplier.consumer.businessName,
                    email: consumerSupplier.consumer.email,
                    createdAt: consumerSupplier.consumer.createdAt,
                    consumerId: consumerSupplier.consumer.id,
                    name: consumerSupplier.consumer.name,
                    summary: consumerSupplier.consumer.summary,
                    taxId: consumerSupplier.consumer.taxID,
                    website: consumerSupplier.consumer.website,
                    updatedAt: consumerSupplier.consumer.updatedAt,
                    officePhone: XSalesConsumer.getPhoneType(consumerSupplier.consumer.phones, db.PhoneType.WORK),
                    mobilePhone: XSalesConsumer.getPhoneType(consumerSupplier.consumer.phones, db.PhoneType.MOBILE),
                    address: consumerSupplier.consumer.address !== null ? this.formattAddress(consumerSupplier.consumer.address[0]) : "",
                    erpCode: consumerSupplier.consumerIdERP,
                    requestedOn: consumerSupplier.requestedOn,
                    requestedBy: consumerSupplier.requestedBy,
                    requestComments: consumerSupplier.requestComments,
                    responsedBy: consumerSupplier.responsedBy,
                    responsedOn: consumerSupplier.responsedOn,
                    responseComments: consumerSupplier.responseComments,
                    //status: I18n.get("lbl" + consumerSupplier.requestStatus),
                    status: consumerSupplier.requestStatus,
                    // blockedForSale: I18n.get(
                    //     "lbl" + consumerSupplier.blockedForSales
                    // ),
                    blockedForSale: consumerSupplier.blockedForSales,
                    daysDelivery: consumerSupplier.daysDelivery,
                    contactName: consumerSupplier.contactName,
                    contactEmail: consumerSupplier.contactEmail,
                    supplierName: consumerSupplier.supplier.name,
                    supplierBusinessName: consumerSupplier.supplier.businessName,
                    consumerSupplier: consumerSupplier.supplier.id,
                    taxPercentage: consumerSupplier.taxPercentage === null ? 0: (consumerSupplier.taxPercentage).toFixed(0),
                    discountPercentage: consumerSupplier.discountPercentage === null ? 0 : (consumerSupplier.discountPercentage).toFixed(0),
                    //taxPercentage: consumerSupplier.taxPercentage === null ? consumerSupplier.supplier.taxPercentage : consumerSupplier.taxPercentage,   Caso: XSMCONSUME-1406
                    //discountPercentage: consumerSupplier.discountPercentage === null ? consumerSupplier.supplier.discountPercentage : consumerSupplier.discountPercentage,   Caso: XSMCONSUME-1406
                    group1: { value: consumerSupplier.group1, label: consumerSupplier.group1 },
                    group2: { value: consumerSupplier.group2, label: consumerSupplier.group2 },
                    group3: { value: consumerSupplier.group3, label: consumerSupplier.group3 },
                    supplierGroup1:
                        consumerSupplier.supplier.groups1 === null ? [] : consumerSupplier.supplier.groups1.map((x) => ({ value: x, label: x })),
                    supplierGroup2:
                        consumerSupplier.supplier.groups2 === null ? [] : consumerSupplier.supplier.groups2.map((x) => ({ value: x, label: x })),
                    supplierGroup3:
                        consumerSupplier.supplier.groups3 === null ? [] : consumerSupplier.supplier.groups3.map((x) => ({ value: x, label: x })),
                    supplierId: consumerSupplier.supplier.id,
                    ListPriceMode: false,
                }

                let ListPriceMode = await XSalesData.Suppliers.GetSupplierListPriceMode(consumerSupplier.supplier.id)
                consumer.ListPriceMode = ListPriceMode

                this.setState({ sectionData: consumer, loadWindow: false })

                XSalesData.Consumer.GetConsumerMinimunOrderConfiguration(consumerSupplier.supplier.id, consumerSupplier.consumer.id)
                    .then((setup) => {
                        let sdmo = this.state.sectionDataMinimumOrder
                        sdmo.orderMinAmount = isNaN(setup.SetupMOAmount)? 0: setup.SetupMOAmount;
                        sdmo.orderMinRule = setup.SetupMOKey

                        this.setState({ sectionDataMinimumOrder: sdmo })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.setState({ loadWindow: false })
                    })

                XSalesData.Suppliers.GetSuppliersConsumerCalculation(consumerSupplier.supplier.id, consumerSupplier.consumer.id)
                    .then(async (data) => {
                        let currentState = this.state.sectionDataNetoBruto
                        let newState = currentState.map((item) => {
                            item.value = item.Key.toUpperCase() === data.toUpperCase() ? true : false
                            return item
                        })

                        await this.setState({ sectionDataNetoBruto: newState, loadWindow: false })

                        if (window.sessionStorage.getItem("pathRoot")) {
                            await this.setState({ pathRoot: window.sessionStorage.getItem("pathRoot") })
                        }
                    })
                    .catch((err) => {
                        console.log("err supplier GetSuppliersConsumerCalculation", err)
                        this.setState({ loadWindow: false })
                    })

                XSalesData.Suppliers.GetSuppliersConsumerCatalogType(consumerSupplier.supplier.id, consumerSupplier.consumer.id)
                    .then(async (data) => {
                        let currentState = this.state.sectionDataCatalog
                        let newState = currentState.map((item) => {
                            item.value = item.Key.toUpperCase() === data.toUpperCase() ? true : false
                            return item
                        })
                        await this.setState({ sectionDataCatalog: newState, loadWindow: false })
                    })
                    .catch((err) => {
                        console.log("err supplier GetSuppliersCatalogType", err)
                    })

                // await this.setState({ sectionData: consumer })
            })
            .catch((error) => {
                console.log("error component did mount detail.js", error)
                this.setState({ loadWindow: false })
            })
    }

    componentDidMount() {
        this.FillData()
    }

    render() {
        return (
            <>
                {this.state.loadWindow ? (
                    <LoaderWindow />
                ) : (
                    <>
                        <BreadcrumbPage pathLink={[{ path: this.state.pathRoot, linkName: "Consumers" }]}></BreadcrumbPage>
                        {/* <div><pre>{JSON.stringify(this.state.sectionData, null, 2) }</pre></div> */}
                        <Title label={this.state.sectionData.businessName} />
                        <Tab
                            options={
                                this.state.sectionData.ListPriceMode === "false"
                                    ? this.state.tapOptions.filter((item) => item.id !== "ListPrice")
                                    : this.state.tapOptions
                            }
                        >
                            <>
                                <TabCell show={true} id="dataGeneral">
                                    <div className="mt-3">
                                        <CustomerGeneral
                                            Data={this.state.sectionData}
                                            handleChange={this.handleChangeState}
                                            AllowEdit={false}
                                            RefreshData={this.FillData}
                                        />
                                    </div>
                                </TabCell>
                                <TabCell show={false} id="preference">
                                    <div className="mt-3">
                                        <CustomerPreferences
                                            Data={this.state}
                                            AllowEdit={false}
                                            handleChangeMinimumOrder={this.handleChangeMinimumOrder}
                                            CancelMinimumConfig={this.CancelMinimumConfig}
                                            ChangeMinimumConfig={this.ChangeMinimumConfig}
                                            handleChangeBlockedForSale={this.handleChangeBlockedForSale}
                                            CancelBlockedForSale={this.CancelBlockedForSale}
                                            ChangeBlockedForSale={this.ChangeBlockedForSale}
                                            RefreshData={this.FillData}
                                            DataNetoBruto={this.state.sectionDataNetoBruto}
                                            DataCatalog={this.state.sectionDataCatalog}
                                            handleChange={this.handleChangeState}
                                            ChangeAssignERPCode={this.assignERPCode}
                                            CancelAssignERPCode={this.cancelERPCode}
                                            handleChangeNetoBruto={this.ChangeNetoBruto}
                                            ChangeNetoBrutoConfig={this.ChangeNetoBrutoConfig}
                                            changeCurrencyConfig={this.changeCurrencyConfig}
                                            handleChangeCatalogType={this.handleChangeCatalogType}
                                            handleChangeCurrency={this.handleChangeCurrency}
                                            ChangeCatalogConfig={this.ChangeCatalogConfig}
                                            handleChangeTypeExclusive={this.handleChangeCatalogTypeExclusive}
                                            ChangeCurrencyConsumer={this.ChangeCurrencyConsumer}
                                        />
                                    </div>
                                </TabCell>
                                <TabCell show={false} id="banners">
                                    <div className="mt-3">
                                        <CustomerBanner Data={""} AllowEdit={false} RefreshData={this.FillData} />
                                    </div>
                                </TabCell>
                                <TabCell show={false} id="delivery">
                                    <div className="mt-3">
                                        <DeliveryDays
                                            Data={this.state}
                                            AllowEdit={false}
                                            CancelAssignDeliveryDays={this.CancelAssignDeliveryDays}
                                            ChangeAssignDeliveryDays={this.ChangeAssignDeliveryDays}
                                            RefreshData={this.FillData}
                                        />
                                    </div>
                                </TabCell>
                                <TabCell show={false} id="request">
                                    <div className="mt-3">
                                        <CustomerAdditional
                                            Data={this.state}
                                            handleChange={this.handleChangeState}
                                            CancelAssignStatus={this.CancelAssignStatus}
                                            ChangeAssignStatus={this.ChangeAssignStatus}
                                            AllowEdit={false}
                                            RefreshData={this.FillData}
                                            handleChangeMinimumOrder={this.handleChangeMinimumOrder}
                                            CancelMinimumConfig={this.CancelMinimumConfig}
                                            ChangeMinimumConfig={this.ChangeMinimumConfig}
                                            handleChangeBlockedForSale={this.handleChangeBlockedForSale}
                                            CancelBlockedForSale={this.CancelBlockedForSale}
                                            ChangeBlockedForSale={this.ChangeBlockedForSale}
                                            DataNetoBruto={this.state.sectionDataNetoBruto}
                                            DataCatalog={this.state.sectionDataCatalog}
                                            // handleChange={this.handleChangeState}
                                            ChangeAssignERPCode={this.assignERPCode}
                                            CancelAssignERPCode={this.cancelERPCode}
                                            handleChangeNetoBruto={this.ChangeNetoBruto}
                                            ChangeNetoBrutoConfig={this.ChangeNetoBrutoConfig}
                                            changeCurrencyConfig={this.changeCurrencyConfig}
                                            handleChangeCatalogType={this.handleChangeCatalogType}
                                            handleChangeCurrency={this.handleChangeCurrency}
                                            ChangeCatalogConfig={this.ChangeCatalogConfig}
                                            handleChangeTypeExclusive={this.handleChangeCatalogTypeExclusive}
                                            ChangeCurrencyConsumer={this.ChangeCurrencyConsumer}
                                            ChangePercentage={this.ChangePercentage}
                                            handleChangeGroup1={this.handleChangeGroup1}
                                            handleChangeGroup2={this.handleChangeGroup2}
                                            handleChangeGroup3={this.handleChangeGroup3}
                                        />
                                    </div>
                                </TabCell>
                                <TabCell show={false} id="ListPrice">
                                    <div className="mt-3">
                                        <ListPrice consumerId={supplierConsumerId} />
                                    </div>
                                </TabCell>
                            </>
                        </Tab>
                    </>
                )}
            </>
        )
    }
}
