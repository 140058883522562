import React from "react"
import { TabulatorTable, SeparateTop } from 'xsales-components'
import PreferenceDefault from "../../configConsumer/preferenceDefault.json"
import { XSalesData } from "../../DataQuery/XSalesData"
import { I18n } from "@aws-amplify/core"

class index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            objData: {
                InitialData: [
                    {
                        id: "",
                        crewId: "",
                        type: "",
                        description: "",
                    },
                ],
                Data: [],
                Filter: [],
                Columns: [
                    {
                        title: I18n.get("lblRolId"),
                        field: "id",
                        visible: false,
                    },
                    {
                        title: I18n.get("lblCrewId"),
                        field: "crewId",
                        visible: false,
                    },
                    {
                        title: I18n.get("lblRolDescription"),
                        field: "description",
                        widthGrow: 3,
                    },
                    {
                        title: I18n.get("lblRolType"),
                        field: "type",
                        widthGrow: 1,
                    },
                ],
                RowClick: this.rowClickEventConsumer,
            },
        }
    }

    rowClickEventConsumer = (e, row) => {
        let id = row.getData().id
        window.location.href = `/Roles/Detail/${id}`
    }

    componentDidMount() {
        let objData = this.state.objData
        let userLang = PreferenceDefault.Language
        XSalesData.Rol.GetRoles()
            .then(async (data) => {
                //console.log("roles/index/data ", data)
                objData.Data = data.map((item) => {
                    return {
                        id: item.id, //id de la tabla crew
                        crewId: item.crewId,
                        type: item.type,
                        description:
                            userLang === "ES"
                                ? item.nameLanguage2
                                : item.nameLanguage1,
                    }
                })
                await this.setState({ objData: objData })
            })
            .catch((err) => {
                objData.Data = objData.InitialData
                this.setState({ objData: objData })
            })
    }

    render() {
        return (
            <SeparateTop>
                <TabulatorTable
                    Title={{
                        label: I18n.get("lblRoles"),
                    }}
                    Data={this.state.objData.Data}
                    Columns={this.state.objData.Columns}
                    Filter={this.state.objData.Filter}
                    RowClick={this.state.objData.RowClick}
                    placeholder={I18n.get("lblLoadingData")}
                    labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                />
            </SeparateTop>
        )
    }
}

export default index
