import React from "react"
import CustomerGeneral from "./GeneralInfo"
//import CustomerPreferences from "../../Consumer/Preferences"
//import CustomerAdditional from "../../Consumer/AdditionalInfo"
//import CustomerBanner from "../../Consumer/Banners"
//import DeliveryDays from "../../Consumer/DeliveryDays"
import ConsumerXSM from "../Consumer/ConsumerXSM"
import { XSalesData } from "../../../DataQuery/XSalesData"
import { XSalesConsumer } from "../../../Utils/Utils"
import { I18n } from "aws-amplify"
import * as db from "../../../models"
import BreadcrumbPage from "../../../componentsLayout/Breadcrumb"

import { Tab, TabCell } from 'xsales-components'


export default class Detail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pathRoot:"Main",
            tabXSMActive: false,
            sectionData: {},
            consumerId: "",
            tapOptions: [
                {
                    id: "lblGeneral",
                    label: I18n.get("lblGeneral"),
                    default: true,
                },
                {
                    id: "lblisxsm",
                    label: I18n.get("lblXSM"),
                    default: false,
                }
            ]
        }
    }

    handleChangeState = (e) => {
        let sectionData = this.state.sectionData
        try {
            const { name, value } = e.target
            sectionData[name] = value
        } catch (error) {
            let obj = e.value
            sectionData["status"] = obj
        }
        this.setState({ sectionData: sectionData })
    }

    getPhoneType = (listPhone, type) => {
        if (listPhone !== null) {
            let typePhone = listPhone.filter(
                (x) =>
                    x.type.toString().toUpperCase() ===
                    type.toString().toUpperCase()
            )
            if (typePhone.length > 0) {
                return typePhone[0].number
            }
            return ""
        } else {
            return ""
        }
    }

    FillData = async () => {
        const consumerId = this.props.match.params.id
        let XSM = false
        XSM = await XSalesConsumer.getCurrentUser()
            .then((data) => {
                return data.type === "admin" ? true : false
            })
            .catch((err) => {
                return false
            })
        await this.setState({ consumerId: consumerId, tabXSMActive: XSM })
        await XSalesData.Consumer.GetConsumer(consumerId)
            .then(async (data) => {
                //console.log("detail consumer then execution", data)
                let consumer = {}
                Object.assign(consumer, data)
                consumer.address =
                    data.address !== null
                        ? XSalesConsumer.formattAddress(data.address[0])
                        : ""
                consumer.officePhone = XSalesConsumer.getPhoneType(
                    consumer.phones,
                    db.PhoneType.WORK
                )
                consumer.mobilePhone = XSalesConsumer.getPhoneType(
                    consumer.phones,
                    db.PhoneType.MOBILE
                )
                await this.setState({ sectionData: consumer })

                if(window.sessionStorage.getItem("pathRoot"))
                {
                    await this.setState({ pathRoot: window.sessionStorage.getItem("pathRoot")})
                }
                

            })
            .catch((error) => {
                console.log("error component did mount detail.js", error)
            })
    }

    async componentDidMount() {
        document.title = "Consumer:Detail"
        await this.FillData()
    }

    render() {
        return (
            <>
                <BreadcrumbPage
                    pathLink={[{path:this.state.pathRoot,linkName:"Consumers"}]}
                ></BreadcrumbPage>
                <Tab options={this.state.tapOptions}>
                    <TabCell show={true} id="lblGeneral">
                        <CustomerGeneral
                            Data={this.state.sectionData}
                            handleChange={this.handleChangeState}
                            AllowEdit={false}
                            RefreshData={this.FillData}
                        />
                    </TabCell>
                    {
                        this.state.tabXSMActive === true &&
                        <TabCell show={false} id="lblisxsm">
                            <ConsumerXSM
                                Data={""}
                                AllowEdit={false}
                                RefreshData={this.FillData}
                            />
                        </TabCell>
                    }

                </Tab>
            </>
        )
    }
}
