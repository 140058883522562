import React, { useState, useEffect } from "react"
import { SectionEditable, TextArea, Image, LoaderWindow } from 'xsales-components'
import imgCDN from "../../configConsumer/imagesDefaultCDN.json"
import SeparateTop from "../../componentsConsumer/SeparateTop"
import { useParams } from "react-router-dom"
import { I18n } from "@aws-amplify/core"
import { ImageUrlTypes, XSalesData } from "../../DataQuery/XSalesData"
import { XSalesConsumer } from "../../Utils/Utils"

export default function Banners(props) {
    if (props.Data === null || props.Data === undefined) {
        throw new Error(
            "You must indicate a [Data] props for GeneralInfo component"
        )
    }

    let { id } = useParams()

    /* eslint-disable no-unused-vars */
    const [supplierConsumerId, setSupplierConsumerId] = useState(id)
    /* eslint-enable no-unused-vars */
    const [isLoading, setIsLoading] = useState(false)
    const initialData = [
        {
            id: "",
            description: "",
            imageUrl: [imgCDN.SupplierBannerDefault],
            since: XSalesConsumer.getDateFormatter(new Date()),
            until: XSalesConsumer.getDateFormatter(new Date("31-12-9999")),
            active: true,
            imageExternalURL: [],
            imageInternalName: []
        },
        {
            id: "",
            description: "",
            imageUrl: [imgCDN.SupplierBannerDefault],
            since: XSalesConsumer.getDateFormatter(new Date()),
            until: XSalesConsumer.getDateFormatter(new Date("31-12-9999")),
            active: true,
            imageExternalURL: [],
            imageInternalName: []
        },
        {
            id: "",
            description: "",
            imageUrl: [imgCDN.SupplierBannerDefault],
            since: XSalesConsumer.getDateFormatter(new Date()),
            until: XSalesConsumer.getDateFormatter(new Date("31-12-9999")),
            active: true,
            imageExternalURL: [],
            imageInternalName: []
        },
    ]

    const [banners, setBanners] = useState(initialData)

    const [UpdateAction, setUpdateAction] = useState(false)
    const UpdateActionButton = () => {
        setUpdateAction(!UpdateAction)
    }

    async function getData(){
        setIsLoading(true)
        //const { supplier } = await XSalesConsumer.getCurrentUser()
        XSalesData.Suppliers.GetSupplierConsumerBanner(supplierConsumerId)
        .then(async (data) => {
           const {supplier} = await XSalesConsumer.getCurrentUser()
            if (data !== null) {
                
                // let modifyData = XSalesConsumer.getImageUrlList({
                //     imageInternalName: data.imageInternalName,
                //     imageExternalURL: data.imageExternalURL,
                //     supplierId: supplier.id,
                //     imageType: ImageUrlTypes.Types.banners
                // })
                let modifyData = data.map((x) => {
                    const dataImg = XSalesConsumer.getImageUrlList({
                        imageInternalName: x.imageInternalName,
                        imageExternalURL: x.imageExternalURL,
                        supplierId: supplier.id,
                        imageType: ImageUrlTypes.Types.banners
                    })

                    return{
                        ...x,
                        imageUrl: dataImg.length > 0 ? dataImg : [],
                        imageName: x.imageExternalURL ? x.imageExternalURL : x.imageInternalName
                    }
                })            
                
                setBanners(modifyData)
            } else {
                setBanners(initialData)
            }
            setIsLoading(false)
        })
        .catch((err) => {
            console.log(err)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getData()
    }, [id])// eslint-disable-line react-hooks/exhaustive-deps

    const imgOnError = (e) => {
        let target = e.target
        let index = target.dataset.value
        
        banners[index].imageUrl = new Array(imgCDN.SupplierConsumerBannerDefault)
        setBanners(prevState => [...prevState, banners])
    }

    const handleChangeBanner = (e) => {
        let target = e.target
        let value = target.value
        let indexHC = target.dataset.value
        let newBanners = []
        for (let index = 0; index < banners.length; index++) {
            let element = {...banners[index]}

            element.imageName =
            index.toString() === indexHC.toString()
                ? new Array(value)
                : banners[index].imageName
            newBanners.push(element)
        }
        setBanners(newBanners)
    }

    const CancelAssignBanners = async () => {
        setUpdateAction(false)
        props.RefreshData()
    }

    const sendDataBanners = async () => {
        setUpdateAction(false)
        let newBannerData = banners.map(x => ({
            ...x,
            imageInternalName: x.imageName
        }))
       
        for (const bannerData of newBannerData) {
            delete bannerData.imageUrl
            delete bannerData.imageName
        }
        setIsLoading(true)
        
        await XSalesData.Suppliers.SetSupplierConsumerBanner(
            supplierConsumerId,
            newBannerData
        )
            .then((data) => {
                getData()
                //console.log(data)
            })
            .catch((err) => {
                setIsLoading(false)
                console.log(err)
            })
    }

    return (
        <>
        
            {
                isLoading ?
                 <LoaderWindow />
                 :
                <div className="col-sm-12">
                 <SeparateTop>
                     <SectionEditable
                         label={I18n.get("lblAssignBanners")}
                         labelEdit={I18n.get("lblAssignBanners")}
                         Action={"UPDATE"}
                         UpdateAction={UpdateActionButton}
                         CancelAction={CancelAssignBanners}
                         SaveAction={sendDataBanners}
                         Collapse={false}
                         lblbtnSave={I18n.get("lblSave")}
                         lblbtnCancel={I18n.get("lblCancel")}
                     >
                         <div className="row">
                             <div className="col-sm-12 col-md-12">
                                 {
                                     UpdateAction ?
                                         <TextArea
                                             rows={3}
                                             id="banner1"
                                             name="banner1"
                                             value={banners[0].imageName}
                                             dataAttrValue="0"
                                             onChange={handleChangeBanner}
                                             maxLength="120"
                                             label={I18n.get("lblBanner1")}
                                             isEditable={UpdateAction}
                                         />
                                         :
                                         banners[0].imageUrl.map(url => (
                                             <Image
                                             src={url}
                                             className="img__consumer__banner"
                                             alt="Banner URL"
                                             OnError={imgOnError}
                                             figcaption={I18n.get("lblImageRequeriment")}
                                         />                             
                                         ))
                                 }
                             </div>
     
                             <div className="col-sm-12 col-md-12">
                                 {
                                     UpdateAction ?
                                         <TextArea
                                             rows={3}
                                             id="banner1"
                                             name="banner1"
                                             value={banners[1].imageName}
                                             dataAttrValue="1"
                                             onChange={handleChangeBanner}
                                             maxLength="120"
                                             label={I18n.get("lblBanner2")}
                                             isEditable={UpdateAction}
                                         />
                                         :
                                         banners[1].imageUrl.map(url => (
                                             <Image
                                             src={url}
                                             className="img__consumer__banner"
                                             alt="Banner URL"
                                             OnError={imgOnError}
                                             figcaption={I18n.get("lblImageRequeriment")}
                                             urlImgWarning={true}
                                         />                              
                                         ))
                                 
                                 }
     
                             </div>
     
                             <div className="col-sm-12 col-md-12">
                                 {
                                     UpdateAction ?
                                         <TextArea
                                             rows={3}
                                             id="banner1"
                                             name="banner1"
                                             value={banners[2].imageName}
                                             dataAttrValue="2"
                                             onChange={handleChangeBanner}
                                             maxLength="120"
                                             label={I18n.get("lblBanner3")}
                                             isEditable={UpdateAction}
                                         />
                                         :
                                         banners[2].imageUrl.map(url => (
                                             <Image
                                             src={url}
                                             className="img__consumer__banner"
                                             alt="Banner URL"
                                             OnError={imgOnError}
                                             figcaption={I18n.get("lblImageRequeriment")}
                                         />                                
                                         ))
                                 }                            
                             </div>
                         </div>
                     </SectionEditable>
                 </SeparateTop>
             </div>
            }
        </>
       
    )
}
