import React, { useEffect, useState } from "react"
import { XSalesConsumer } from "../../../Utils/Utils"
import {
    SeparateTop,
    InputText,
    SelectCombo,
    Switch,
    TextMessage
} from "xsales-components"
import { I18n } from "@aws-amplify/core"
import { XSalesData } from "../../../DataQuery/XSalesData"
import styled from "styled-components"
import { useForm, Controller } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

//import { Form, FormGroup, Label, Input, Button,  } from "reactstrap"

export function FormSubCategory(props) {
    const {
        handleSubmit,
       formState: { errors },
        control,
        setValue,
    } = useForm({
        shouldFocusError: true,
        defaultValues: {
            subcategoryId: props.data.subcategoryId,
            categoryId: props.data.categoryId,
            nameLanguage1: props.data.nameLanguage1,
            nameLanguage2: props.data.nameLanguage2,
            nameLanguage3: props.data.nameLanguage3,
            nameLanguage4: props.data.nameLanguage4,
            inactive: props.data.inactive,
            imageExternalURL: props.data.imageExternalURL,
            imageInternalName: props.data.imageInternalName,
            inactiveParent: props.data.inactiveParent,
            supplierId: props.data.supplierId,
            inactiveOn: props.data.inactiveOn,
            inactiveParentOn : props.data.inactiveParentOn,
        },
    })

    const [listCategories, setListCategories] = useState([])
    const [categoryRequired, setCategoryRequired] = useState(false)
    const getErrorMessage = (name) => (
        <ErrorMessage errors={errors} name={name} />
    )
    const [inactive, setInactive] = useState(false)
    const [inactiveParent, setInactiveParent] = useState(false)

    useEffect(() => {
        document.title = "Subcategory:Detail"
        setTimeout(() => {
            XSalesConsumer.hideLanguagesControls()
        }, 0)
    }, [])

    useEffect(() => {
        setValue("subcategoryId", props.data.subcategoryId)
        setValue("categoryId", props.data.categoryId)
        setValue("nameLanguage1", props.data.nameLanguage1)
        setValue("nameLanguage2", props.data.nameLanguage2)
        setValue("nameLanguage3", props.data.nameLanguage3)
        setValue("nameLanguage4", props.data.nameLanguage4)
        setValue("inactive", props.data.inactive)
        setInactive(!props.data.inactive)

        setValue("imageExternalURL", props.data.imageExternalURL)
        setValue("imageInternalName", props.data.imageInternalName)
        setValue("inactiveParent", props.data.inactiveParent)
        setInactiveParent(!props.data.inactiveParent)
        setValue("supplierId", props.data.supplierId)
        setValue("inactiveOn", props.data.inactiveOn)
        setValue("inactiveParentOn", props.data.inactiveParentOn)

        setListCategories([])
        XSalesData.Suppliers.getListCategories(props.supplier.id)
            .then((data) => {
                const Category = []
                data.items.map((x) => (
                    Category.push({
                        label: x.categoryId,
                        value: x.categoryId,
                    })
                ))

                setListCategories(Category)
            })
            .catch((err) => {
                console.log("Admin List Categories error ", err)
            })
    }, [props.data])// eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmitValidate = (data) => {
        if (data.Category === "-1" || data.Category === null) {
            setCategoryRequired(true)
        } else {
            props.handleSubmit(data)
        }
    }

    const handlechangeCategory = (e) => {
        setValue("categoryId", e.value)
        setCategoryRequired(false)
    }

    return (
        <div className="col-sm-12">
            <SeparateTop>
                <form
                    ref={props.setRef}
                    onSubmit={handleSubmit(handleSubmitValidate)}
                    id="form-Subcategory"
                >
                    <div className="row">
                        <div className="col-sm-12 col-md-7 offset-md-1">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    {
                                        <div>
                                            <Controller
                                                control={control}
                                                name="subcategoryId"
                                                rules={{
                                                    required: I18n.get(
                                                        "lblrequiredField"
                                                    ),
                                                    maxLength: {
                                                        value: 15,
                                                        message: I18n.get(
                                                            "lblmaxLengthField"
                                                        ).replace(
                                                            "{length}",
                                                            "15"
                                                        ),
                                                    },
                                                }}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value,
                                                        name,
                                                        ref,
                                                        onFocus,
                                                    },
                                                    fieldState: {
                                                        invalid,
                                                        isTouched,
                                                        isDirty,
                                                        error,
                                                    },
                                                    formState,
                                                }) => (
                                                    <>
                                                        <InputText
                                                            /* requiredField={props.mode === "CREATE" ? true : false} */
                                                            id="subcategoryId"
                                                            name={name}
                                                            label="subcategoryId"
                                                            value={value}
                                                            /*  onChange={props.handleChange} */
                                                            isEditable={
                                                                props.mode ===
                                                                "CREATE"
                                                                    ? true
                                                                    : false
                                                            }
                                                            messageError={getErrorMessage(
                                                                "subcategoryId"
                                                            )}
                                                            setRef={ref}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            onFocus={onFocus}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    }

                                    {}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <Controller
                                        control={control}
                                        name="nameLanguage1"
                                        rules={{
                                            maxLength: {
                                                value: 45,
                                                message: I18n.get(
                                                    "lblmaxLengthField"
                                                ).replace("{length}", "45"),
                                            },
                                        }}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                name,
                                                ref,
                                                onFocus,
                                            },
                                            fieldState: {
                                                invalid,
                                                isTouched,
                                                isDirty,
                                                error,
                                            },
                                            formState,
                                        }) => (
                                            <>
                                                <InputText
                                                    name={name}
                                                    setRef={ref}
                                                    label={I18n.get(
                                                        "lbllnamelanguage1"
                                                    )}
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    onFocus={onFocus}
                                                    messageError={getErrorMessage(
                                                        "nameLanguage1"
                                                    )}
                                                    isEditable={props.readOnly}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <Controller
                                        control={control}
                                        name="nameLanguage2"
                                        rules={{
                                            maxLength: {
                                                value: 45,
                                                message: I18n.get(
                                                    "lblmaxLengthField"
                                                ).replace("{length}", "45"),
                                            },
                                        }}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                name,
                                                ref,
                                                onFocus,
                                            },
                                            fieldState: {
                                                invalid,
                                                isTouched,
                                                isDirty,
                                                error,
                                            },
                                            formState,
                                        }) => (
                                            <>
                                                <InputText
                                                    name={name}
                                                    setRef={ref}
                                                    label={I18n.get(
                                                        "lbllnamelanguage2"
                                                    )}
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    onFocus={onFocus}
                                                    messageError={getErrorMessage(
                                                        "nameLanguage2"
                                                    )}
                                                    isEditable={props.readOnly}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <Controller
                                        control={control}
                                        name="nameLanguage3"
                                        rules={{
                                            maxLength: {
                                                value: 45,
                                                message: I18n.get(
                                                    "lblmaxLengthField"
                                                ).replace("{length}", "45"),
                                            },
                                        }}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                name,
                                                ref,
                                                onFocus,
                                            },
                                            fieldState: {
                                                invalid,
                                                isTouched,
                                                isDirty,
                                                error,
                                            },
                                            formState,
                                        }) => (
                                            <>
                                                <InputText
                                                    name={name}
                                                    setRef={ref}
                                                    label={I18n.get(
                                                        "lbllnamelanguage3"
                                                    )}
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    onFocus={onFocus}
                                                    messageError={getErrorMessage(
                                                        "nameLanguage3"
                                                    )}
                                                    isEditable={props.readOnly}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <Controller
                                        control={control}
                                        name="nameLanguage4"
                                        rules={{
                                            maxLength: {
                                                value: 45,
                                                message: I18n.get(
                                                    "lblmaxLengthField"
                                                ).replace("{length}", "45"),
                                            },
                                        }}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                name,
                                                ref,
                                                onFocus,
                                            },
                                            fieldState: {
                                                invalid,
                                                isTouched,
                                                isDirty,
                                                error,
                                            },
                                            formState,
                                        }) => (
                                            <>
                                                <InputText
                                                    name={name}
                                                    setRef={ref}
                                                    label={I18n.get(
                                                        "lbllnamelanguage4"
                                                    )}
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    onFocus={onFocus}
                                                    messageError={getErrorMessage(
                                                        "nameLanguage4"
                                                    )}
                                                    isEditable={props.readOnly}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <Controller
                                        control={control}
                                        name="categoryId"
                                        rules={{ required: true }}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                name,
                                                ref,
                                                onFocus,
                                            },
                                            fieldState: {
                                                invalid,
                                                isTouched,
                                                isDirty,
                                                error,
                                            },
                                            formState,
                                        }) => (
                                            <>
                                                {
                                                    <ContainerSelectCombo>
                                                        {
                                                            <SelectCombo
                                                                name="categoryId"
                                                                options={
                                                                    listCategories
                                                                }
                                                                optionSelected={
                                                                    value
                                                                }
                                                                defaultValue={
                                                                    ""
                                                                }
                                                                value={value}
                                                                isEditable={props.readOnly}
                                                                label="List Categories"
                                                                onChange={
                                                                    handlechangeCategory
                                                                }
                                                            />
                                                        }
                                                        <small className="text-danger">
                                                            {categoryRequired &&
                                                                I18n.get(
                                                                    "lblrequiredField"
                                                                )}
                                                        </small>
                                                    </ContainerSelectCombo>
                                                }
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <Controller
                                        control={control}
                                        name="inactive"
                                        rules={{}}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                name,
                                                ref,
                                            },
                                            fieldState: {
                                                invalid,
                                                isTouched,
                                                isDirty,
                                                error,
                                            },
                                            formState,
                                        }) => (
                                            <>
                                                <Switch
                                                    id="inactive"
                                                    setRef={ref}
                                                    name={name}
                                                    label={I18n.get(
                                                        "lblactive"
                                                    )}
                                                    onChange={(e) => {
                                                        setInactive(e.target.checked)
                                                        setValue("inactiveOn",(!e.target.checked)?new Date().toISOString():"")
                                                        onChange(!e.target.checked)
                                                    }}
                                                    onBlur={onBlur}
                                                    value={inactive}
                                                    isEditable={props.readOnly}
                                                />
                                               
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6">    
                                <Controller
                                control={control}
                                name="inactiveOn"
                                rules={{   }}
                               
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>

                                    {
                                      (props.mode !== "CREATE" && props.data.inactive === true ) &&
                                      <TextMessage label={XSalesConsumer.formatShortDatetime(props.data.inactiveOn)}/> 
                                    }       
                                    </>        
                               
                                  )}
                            /> 
                    
                                     </div>
                            </div>
                            <div className="row"> 
                            <div className="col-sm-12 col-md-6">
                                    <Controller
                                        control={control}
                                        name="inactiveParent"
                                        rules={{}}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                name,
                                                ref,
                                            },
                                            fieldState: {
                                                invalid,
                                                isTouched,
                                                isDirty,
                                                error,
                                            },
                                            formState,
                                        }) => (
                                            <>
                                                <Switch
                                                    id="inactiveParent"
                                                    setRef={ref}
                                                    name={name}
                                                    label={I18n.get(
                                                        "lblactiveCategory"
                                                    )}
                                                    onChange={(e) => {
                                                        setInactiveParent(e.target.checked)
                                                        setValue("inactiveParentOn",(!e.target.checked)?new Date().toISOString():"")
                                                        onChange(
                                                            !e.target.checked
                                                        )
                                                    }}
                                                    onBlur={onBlur}
                                                    value={inactiveParent}
                                                    isEditable={false}
                                                />
                                              
                                            </>
                                        )}
                                    />

                                </div>
                                <div className="col-sm-12 col-md-6">    
                                <Controller
                                control={control}
                                name="inactiveParentOn"
                                rules={{   }}
                               
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>

                                    {
                                      (props.mode !== "CREATE" && props.data.inactiveParent === true ) &&
                                      <TextMessage label={XSalesConsumer.formatShortDatetime(props.data.inactiveParentOn)}/> 
                                    }       
                                    </>        
                               
                                  )}
                            /> 
                    </div>
                            </div>
                            <div className="col-sm-12 col-md-12">
                            <p className="text_information">
                                                    {I18n.get(
                                                        "lblmsginactivetext"
                                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </SeparateTop>
        </div>
    )
}
const ContainerSelectCombo = styled.div`
    margin-bottom: 1.5rem;
`
