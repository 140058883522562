import React, { useEffect, useState } from "react"
import {
    SelectCombo,
    CardDonutSupplierTop,
    LoaderWindow,
} from "xsales-components"
import { I18n } from "@aws-amplify/core"
import { XSalesData } from "../../../DataQuery/XSalesData"
import moment from "moment"
import { XSalesConsumer } from "../../../Utils/Utils"
import NoDataAvailable from "../StatementComponents/NoDataAvailable"

let chart1 = null
let chart2 = null
let chart3 = null
let chart4 = null
let chart5 = null

const enumOptions = {
    transactionsPerMonth: "1",
    estimatedBill: "2",
}
const widthCard = "175"
const heightCard = "130"
const locale = navigator.language

const TopFiveSuppliers = (props) => {
    const [supplier1Data, setSupplier1Data] = useState({
        transactionValue: 0,
        billedAmountValue: 0,
        supplierName: "Supplier 1",
        data: [],
    })
    const [supplier2Data, setSupplier2Data] = useState({
        transactionValue: 0,
        billedAmountValue: 0,
        supplierName: "Supplier 2",
        data: [],
    })
    const [supplier3Data, setSupplier3Data] = useState({
        transactionValue: 0,
        billedAmountValue: 0,
        supplierName: "Supplier 3",
        data: [],
    })
    const [supplier4Data, setSupplier4Data] = useState({
        transactionValue: 0,
        billedAmountValue: 0,
        supplierName: "Supplier 4",
        data: [],
    })
    const [supplier5Data, setSupplier5Data] = useState({
        transactionValue: 0,
        billedAmountValue: 0,
        supplierName: "Supplier 5",
        data: [],
    })
    const [dataPerMonth, setDataPerMonth] = useState({ data: [] })
    const [dataEstimatedBill, setDataEstimatedBill] = useState({ data: [] })
    const [dataApiHistory, setDataApiHistory] = useState({ data: [] })
    const [selectionValue, setSelectionValue] = useState(1)
    const [optionSelected, setOptionSelected] = useState("")
    const [comboOptions, setComboOptions] = useState([])
    //const [dataTopSuppliers, setDataTopSuppliers] = useState([])
    const [showCardSupplier1, setShowCardSupplier1] = useState(false)
    const [showCardSupplier2, setShowCardSupplier2] = useState(false)
    const [showCardSupplier3, setShowCardSupplier3] = useState(false)
    const [showCardSupplier4, setShowCardSupplier4] = useState(false)
    const [showCardSupplier5, setShowCardSupplier5] = useState(false)
    const [loader, setLoader] = useState(true)
    // const [showCardSupplier5, setShowCardSupplier5] = useState(false)
    const [
        buildInformationTransactions,
        setBuildInformationTransactions,
    ] = useState({ data: [] })
    const [buildInformationBilled, setBuildInformationBilled] = useState({
        data: [],
    })

    const getData = async () => {
        const { years, periods } = getLastSixPeriods()
        const combo = []
        let data = []
        //const monthsNames = []
        let counterMounths = 1
        for (const year of years) {
            const months = []

            for (const period of periods.filter((x) => x.year === year)) {
                months.push(period.month)
                const monthValue = XSalesConsumer.getMonth(period.month, "long")
                combo.push({ label: monthValue, value: counterMounths })
                counterMounths++
            }
            const min = Math.min(...months)
            const max = Math.max(...months)
            let dataApi = await XSalesData.Statement.getStatementByYearAndPeriod(
                year,
                { between: [min, max] }
            )
            dataApi = dataApi.filter((x) => x.contractDetail.fees != null)
            if (dataApi.length > 0) data = [...data, dataApi]
        }
        setComboOptions(combo)
        setOptionSelected(combo[0].label)
        const dataHistory = dataApiHistory
        dataHistory.data = data
        await setDataApiHistory(dataHistory)
    }

    const sumPeriod = (rpt, ySupplier, dataSupplier, namePeriod) => {
        if (rpt === enumOptions.transactionsPerMonth) {
            dataSupplier[namePeriod] +=
                ySupplier.count.order +
                ySupplier.count.deposit +
                ySupplier.count.payment
        } else if (rpt === enumOptions.estimatedBill) {
            dataSupplier[namePeriod] +=
                ySupplier.amount.orderUSD +
                ySupplier.amount.depositUSD +
                ySupplier.amount.paymentUSD
        }
    }

    const buildInformation = (rpt, callbackHook) => {
        // let index = 0;
        // dataApiHistory.monthsNames.forEach((name,) => {
        //     let indexColumn = index + 2;
        //     columnsTable[indexColumn].title = name.toUpperCase()
        //     index++;
        // })
        const suppliers = [
            ...new Set(dataApiHistory.data.flat().map((x) => x.supplierId)),
        ]
        const dataTable = []
        suppliers.forEach((supplierId) => {
            const dataSupplier = {
                id: supplierId,
                name: "",
                month1: 0,
                month2: 0,
                month3: 0,
                month4: 0,
                month5: 0,
                month6: 0,
                total: 0,
            }

            let { periods } = getLastSixPeriods()
            periods = periods.map((x) => x.month)
            dataApiHistory.data
                .flat()
                .filter((x) => x.supplierId === supplierId)
                .forEach((ySupplier) => {
                    dataSupplier.name = ySupplier.supplier.businessName
                    let indexMonth = periods.indexOf(ySupplier.month)
                    if (indexMonth > -1) {
                        indexMonth++
                    }
                    switch (indexMonth) {
                        case 1:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month1")
                            break
                        case 2:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month2")
                            break
                        case 3:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month3")
                            break
                        case 4:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month4")
                            break
                        case 5:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month5")
                            break
                        case 6:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month6")
                            break
                        default:
                            break
                    }
                })
            dataSupplier.total +=
                dataSupplier.month1 +
                dataSupplier.month2 +
                dataSupplier.month3 +
                dataSupplier.month4 +
                dataSupplier.month5 +
                dataSupplier.month6
            dataTable.push(dataSupplier)
        })
        //updateChartLabels(dataApiHistory.monthsNames)
        callbackHook(dataTable)
    }

    const getLastSixPeriods = () => {
        const now = moment()
        const periods = [] //{ year: 2020, month }
        let years = [] // years
        Array.from({ length: 6 }, (_, i) => i).forEach((_, index) => {
            const date = index === 0 ? now : now.subtract(1, "months")
            const year = date.year()
            const month = date.month()
            periods.push({ year: year, month: month + 1 })
            years.push(year)
        })
        years = [...new Set(years)]
        return { years, periods }
    }

    const getTopFiveValues = (values, selected) => {
        // values = [{m:1},{m:22},{m:33},{m:1},{m:44},{m:5},{m:3},{m:77}];
        const monthName = getMonthByNumber(selected)
        const topValues = values
            .sort((a, b) => b[monthName] - a[monthName])
            .slice(0, 5)
        return topValues
    }

    const formatNumber = (value) => {
        return new Intl.NumberFormat(locale, { style: "decimal" }).format(value)
    }

    const setDataSuppliers = async (
        dataTransactions,
        dataEstimatedBill,
        month
    ) => {
        if (
            dataTransactions.data.length > 0 &&
            dataEstimatedBill.data.length > 0
        ) {
            const totalTransactions = dataTransactions.data
                .map((x) => x[month])
                .reduce((a, b) => a + b, 0)
            const totalEstimatedBill = dataEstimatedBill.data
                .map((x) => x[month])
                .reduce((a, b) => a + b, 0)
            const total = totalTransactions + totalEstimatedBill

            if (dataTransactions.data[0]) {
                // supplier 1
                setShowCardSupplier1(true)
                const supplier1 = { ...supplier1Data }
                supplier1.supplierName = dataTransactions.data[0].name
                supplier1.transactionValue = formatNumber(
                    dataTransactions.data[0][month]
                )
                supplier1.billedAmountValue = formatNumber(
                    dataEstimatedBill.data[0][month]
                )
                const total1 =
                    dataTransactions.data[0][month] +
                    dataEstimatedBill.data[0][month]
                const percent1 =
                isNaN(XSalesConsumer.getPercent(total1, total, 0)) 
                        ? 0
                        : XSalesConsumer.getPercent(total1, total, 0)
                supplier1.data = [percent1, 100 - percent1]

                // if (chart1) {
                //     chart1.data.datasets[0].data = [percent1, (100 - percent1)]
                //     chart1.update();
                // }

                if (percent1 === 0) {
                    await setShowCardSupplier1(false)
                } else {
                    await setShowCardSupplier1(true)
                    setSupplier1Data(supplier1)
                    if (chart1) {
                        chart1.data.datasets[0].data = [
                            percent1,
                            100 - percent1,
                        ]
                        chart1.update()
                    }
                }
            } else {
                setShowCardSupplier1(false)
            }

            if (dataTransactions.data[1]) {
                // supplier 2
                // setShowCardSupplier2(true)
                const supplier2 = { ...supplier2Data }
                supplier2.supplierName = dataTransactions.data[1].name
                supplier2.transactionValue = formatNumber(
                    dataTransactions.data[1][month]
                )
                supplier2.billedAmountValue = formatNumber(
                    dataEstimatedBill.data[1][month]
                )
                const total2 =
                    dataTransactions.data[1][month] +
                    dataEstimatedBill.data[1][month]
                const percent2 =
                isNaN(XSalesConsumer.getPercent(total2, total, 0)) 
                        ? 0
                        : XSalesConsumer.getPercent(total2, total, 0)
                supplier2.data = [percent2, 100 - percent2]
                if (percent2 === 0) {
                    await setShowCardSupplier2(false)
                } else {
                    await setShowCardSupplier2(true)
                    setSupplier2Data(supplier2)
                    if (chart2) {
                        chart2.data.datasets[0].data = [
                            percent2,
                            100 - percent2,
                        ]
                        chart2.update()
                    }
                }
            } else {
                setShowCardSupplier2(false)
            }

            if (dataTransactions.data[2]) {
                // supplier 3
                // setShowCardSupplier3(true)
                const supplier3 = supplier3Data
                supplier3.supplierName = dataTransactions.data[2].name
                supplier3.transactionValue = formatNumber(
                    dataTransactions.data[2][month]
                )
                supplier3.billedAmountValue = formatNumber(
                    dataEstimatedBill.data[2][month]
                )
                const total3 =
                    dataTransactions.data[2][month] +
                    dataEstimatedBill.data[2][month]
                const percent3 = XSalesConsumer.getPercent(total3, total, 0)
                supplier3.data = [percent3, 100 - percent3]
                if (percent3 === 0) {
                    await setShowCardSupplier3(false)
                } else {
                    await setShowCardSupplier3(true)
                    setSupplier3Data(supplier3)
                    if (chart3) {
                        chart3.data.datasets[0].data = [
                            percent3,
                            100 - percent3,
                        ]
                        chart3.update()
                    }
                }
            } else {
                setShowCardSupplier3(false)
            }

            if (dataTransactions.data[3]) {
                // supplier 4
                // setShowCardSupplier4(true)
                const supplier4 = supplier4Data
                supplier4.supplierName = dataTransactions.data[3].name
                supplier4.transactionValue = formatNumber(
                    dataTransactions.data[3][month]
                )
                supplier4.billedAmountValue = formatNumber(
                    dataEstimatedBill.data[3][month]
                )
                const total4 =
                    dataTransactions.data[3][month] +
                    dataEstimatedBill.data[3][month]
                const percent4 = XSalesConsumer.getPercent(total4, total, 0)
                supplier4.data = [percent4, 100 - percent4]
                if (percent4 === 0) {
                    await setShowCardSupplier4(false)
                } else {
                    await setShowCardSupplier4(true)
                    setSupplier4Data(supplier4)
                    if (chart4) {
                        chart4.data.datasets[0].data = [
                            percent4,
                            100 - percent4,
                        ]
                        chart4.update()
                    }
                }
            } else {
                setShowCardSupplier4(false)
            }

            if (dataTransactions.data[4]) {
                // supplier 5
                // setShowCardSupplier5(true)
                const supplier5 = supplier5Data
                supplier5.supplierName = dataTransactions.data[4].name
                supplier5.transactionValue = formatNumber(
                    dataTransactions.data[4][month]
                )
                supplier5.billedAmountValue = formatNumber(
                    dataEstimatedBill.data[4][month]
                )
                const total5 =
                    dataTransactions.data[4][month] +
                    dataEstimatedBill.data[4][month]
                const percent5 = XSalesConsumer.getPercent(total5, total, 0)
                supplier5.data = [percent5, 100 - percent5]

                if (percent5 === 0) {
                    await setShowCardSupplier5(false)
                } else {
                    await setShowCardSupplier5(true)
                    setSupplier5Data(supplier5)
                    if (chart5) {
                        chart5.data.datasets[0].data = [
                            percent5,
                            100 - percent5,
                        ]
                        chart5.update()
                    }
                }
            } else {
                setShowCardSupplier5(false)
            }
        }
    }

    useEffect(() => {
        const monthValue = getMonthByNumber(selectionValue)
        getData()
            .then(() => {
                setLoader(false)
                buildInformation(enumOptions.transactionsPerMonth, (_data) => {
                    const data = dataPerMonth
                    data.data = getTopFiveValues(_data, selectionValue)
                    setDataPerMonth(data)
                    const _dataTable = buildInformationTransactions
                    _dataTable.data = _data
                    setBuildInformationTransactions(_dataTable)
                })
                buildInformation(enumOptions.estimatedBill, (_data) => {
                    const data = dataEstimatedBill
                    data.data = getTopFiveValues(_data, selectionValue)
                    setDataEstimatedBill(data)
                    const _dataTable = buildInformationBilled
                    _dataTable.data = _data
                    setBuildInformationBilled(_dataTable)
                })
                //buildInformationPerMonth(2)
                setDataSuppliers(dataPerMonth, dataEstimatedBill, monthValue)
            })
            .catch((err) => {
                setLoader(false)
                console.error(err)
            })
        return () => {
            if (chart1) chart1.destroy()
            if (chart2) chart2.destroy()
            if (chart3) chart3.destroy()
            if (chart4) chart4.destroy()
            if (chart5) chart5.destroy()
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const handleOnChangeSelect = (e) => {
        const monthValue = getMonthByNumber(e.value)
        setSelectionValue(e.value)

        const _dataPerMonth = dataPerMonth
        _dataPerMonth.data = getTopFiveValues(
            buildInformationTransactions.data,
            e.value
        )
        setDataPerMonth(_dataPerMonth)

        const _dataEstimatedBill = dataEstimatedBill
        _dataEstimatedBill.data = getTopFiveValues(
            buildInformationBilled.data,
            e.value
        )
        setDataEstimatedBill(_dataEstimatedBill)

        setDataSuppliers(_dataPerMonth, _dataEstimatedBill, monthValue)
    }

    const getMonthByNumber = (parameter) => {
        switch (parameter) {
            case 1:
                return "month1"
            case 2:
                return "month2"
            case 3:
                return "month3"
            case 4:
                return "month4"
            case 5:
                return "month5"
            case 6:
                return "month6"
            default:
                return "month1"
        }
    }

    return (
        <>
            {loader ? (
                <LoaderWindow />
            ) : (
                <div className="row">
                    <div className="wrapper_top_supplier__options">
                        <div
                            className="title__section_statement"
                            style={{ marginTop: "18px", marginRight: "18px" }}
                        >
                            <img
                                src={
                                    "https://cdn.xsalesconsumer.com/console/emoji_events-24px.svg"
                                }
                                alt=""
                                loading="lazy"
                            />
                            <h4>{I18n.get("lbltopfivesupplier")}</h4>
                        </div>
                        <div className="wrapper__select_top_suppliers">
                            <SelectCombo
                                id="TopFiveSelect"
                                name="TopFiveSelect"
                                label={""}
                                options={comboOptions}
                                optionSelected={optionSelected}
                                onChange={handleOnChangeSelect}
                                isEditable={true}
                                value={selectionValue}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="wrapper_top_suppliers">
                            <div className="container__top_suppliers">
                                {!showCardSupplier1 &&
                                !showCardSupplier2 &&
                                !showCardSupplier3 &&
                                !showCardSupplier4 &&
                                !showCardSupplier5 ? (
                                    <>
                                        <NoDataAvailable />
                                    </>
                                ) : (
                                    <>
                                        {showCardSupplier1 && (
                                            <CardDonutSupplierTop
                                                id="top1"
                                                labels={["1", "2"]}
                                                data={[0, 100]}
                                                colorOne={"#3766FB"}
                                                colorTwo={"#D4E8EF"}
                                                setRefChart={(chart) =>
                                                    (chart1 = chart)
                                                }
                                                width={widthCard}
                                                height={heightCard}
                                                supplierName={
                                                    supplier1Data.supplierName
                                                }
                                                transactionLabel={I18n.get(
                                                    "lbltransactions"
                                                )}
                                                transactionsValue={
                                                    supplier1Data.transactionValue
                                                }
                                                billedAmountLabel={I18n.get(
                                                    "lblbilledamount"
                                                )}
                                                billedAmountValue={`$${supplier1Data.billedAmountValue}`}
                                                showLabel={false}
                                            />
                                        )}
                                        {showCardSupplier2 && (
                                            <CardDonutSupplierTop
                                                id="top2"
                                                labels={["1", "2"]}
                                                data={supplier2Data.data}
                                                colorOne={"#7DD2D6"}
                                                colorTwo={"#D4E8EF"}
                                                setRefChart={(chart) =>
                                                    (chart2 = chart)
                                                }
                                                width={widthCard}
                                                height={heightCard}
                                                supplierName={
                                                    supplier2Data.supplierName
                                                }
                                                transactionLabel={I18n.get(
                                                    "lbltransactions"
                                                )}
                                                transactionsValue={
                                                    supplier2Data.transactionValue
                                                }
                                                billedAmountLabel={I18n.get(
                                                    "lblbilledamount"
                                                )}
                                                billedAmountValue={`$${supplier2Data.billedAmountValue}`}
                                                showLabel={false}
                                            />
                                        )}

                                        {showCardSupplier3 && (
                                            <CardDonutSupplierTop
                                                id="top3"
                                                labels={["1", "2"]}
                                                data={supplier3Data.data}
                                                colorOne={"#2B395A"}
                                                colorTwo={"#D4E8EF"}
                                                setRefChart={(chart) =>
                                                    (chart3 = chart)
                                                }
                                                width={widthCard}
                                                height={heightCard}
                                                supplierName={
                                                    supplier3Data.supplierName
                                                }
                                                transactionLabel={I18n.get(
                                                    "lbltransactions"
                                                )}
                                                transactionsValue={
                                                    supplier3Data.transactionValue
                                                }
                                                billedAmountLabel={I18n.get(
                                                    "lblbilledamount"
                                                )}
                                                billedAmountValue={`$${supplier3Data.billedAmountValue}`}
                                                showLabel={false}
                                            />
                                        )}

                                        {showCardSupplier4 && (
                                            <CardDonutSupplierTop
                                                id="top4"
                                                labels={["1", "2"]}
                                                data={supplier4Data.data}
                                                colorOne={"#631BA7"}
                                                colorTwo={"#D4E8EF"}
                                                setRefChart={(chart) =>
                                                    (chart4 = chart)
                                                }
                                                width={widthCard}
                                                height={heightCard}
                                                supplierName={
                                                    supplier4Data.supplierName
                                                }
                                                transactionLabel={I18n.get(
                                                    "lbltransactions"
                                                )}
                                                transactionsValue={
                                                    supplier4Data.transactionValue
                                                }
                                                billedAmountLabel={I18n.get(
                                                    "lblbilledamount"
                                                )}
                                                billedAmountValue={`$${supplier4Data.billedAmountValue}`}
                                                showLabel={false}
                                            />
                                        )}

                                        {showCardSupplier5 && (
                                            <CardDonutSupplierTop
                                                id="top5"
                                                labels={["1", "2"]}
                                                data={supplier5Data.data}
                                                colorOne={"#9A67CB"}
                                                colorTwo={"#D4E8EF"}
                                                setRefChart={(chart) =>
                                                    (chart5 = chart)
                                                }
                                                width={widthCard}
                                                height={heightCard}
                                                supplierName={
                                                    supplier5Data.supplierName
                                                }
                                                transactionLabel={I18n.get(
                                                    "lbltransactions"
                                                )}
                                                transactionsValue={
                                                    supplier5Data.transactionValue
                                                }
                                                billedAmountLabel={I18n.get(
                                                    "lblbilledamount"
                                                )}
                                                billedAmountValue={`$${supplier5Data.billedAmountValue}`}
                                                showLabel={false}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default TopFiveSuppliers
