import React, { useEffect, useState } from 'react'
import DashboardStatementSupplier from '../../componentsConsumer/Dashboard/StatementSupplier'
//import DashboardStatementXsm from '../../componentsConsumer/Dashboard/Statement'
import { XSalesConsumer } from '../../Utils/Utils'
import DashboardAdminSupport from './../Admin/Dashboard/AdminSupportDashboard'
export function DashboardConsumer() {
    const [roles, setRoles] = useState('')
    useEffect(() => {
        const ac = new AbortController()

        XSalesConsumer.getCurrentUser().then(data => {
            setRoles(data.type.toUpperCase())
        }).catch(err => {
            setRoles('')
        })

        return () => {
            ac.abort()
        }
    }, [])
    return (
        <div>
            {roles === "SUPPLIER" && <DashboardStatementSupplier />}
            {roles === "ADMIN" && <DashboardAdminSupport />}
            {roles === "SUPPORT" && <DashboardAdminSupport />}
        </div>
    )
}

