import React from 'react'
import CountSupplier from '../../../componentsConsumer/Dashboard/Admin/CountSupplier'
import CountConsumer from '../../../componentsConsumer/Dashboard/Admin/CountConsumer'

export default function AdminSupportDashboard() {

    return (
        <div className="container-fluid">
            <div className="row">
                <CountConsumer Layout="4" />
                <CountSupplier Layout="4" />
            </div>
        </div>
    )
}
