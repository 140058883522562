import React, { useEffect, useState } from 'react'
import { Switch, InputText, SectionEditable } from 'xsales-components'
import { I18n } from '@aws-amplify/core'

//let array = []
export function PaymentMethodConfig(props) {
  const [enabled, setEnabled] = useState(false)
  const [paymentForm, setpaymentForm] = useState([])

  const FillData = () => {
    if (
      props.paymentMethods !== null &&
      props.paymentMethods !== undefined
    ) {
      let obj = props.paymentMethods.map((x) => ({
        objkey: x.key,
        objvalue: x.value,
        objvalueLanguage1: x.valueLanguage1,
        objvalueLanguage2: x.valueLanguage2,
        objvalueLanguage3: x.valueLanguage3,
        objvalueLanguage4: x.valueLanguage4,
        objdefault: x.default,
      }))
      setpaymentForm(obj)
    } else {
      setpaymentForm([
        {
          objkey: '',
          objvalue: '',
          objvalueLanguage1: '',
          objvalueLanguage2: '',
          objvalueLanguage3: '',
          objvalueLanguage4: '',
          objdefault: false,
        },
      ])
    }
  }

  useEffect(() => {
    FillData()
  }, [props.paymentMethods]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickAdd = (e) => {
    const obj = paymentForm
    obj.push({
      objkey: '',
      objvalue: '',
      objvalueLanguage1: '',
      objvalueLanguage2: '',
      objvalueLanguage3: '',
      objvalueLanguage4: '',
      objdefault: false,
    })
    setpaymentForm([...obj])
    document
      .querySelectorAll('.divDelete')
      .forEach((a) => (a.style.display = 'block'))
  }

  const handleClickDeletePaymentMethods = (e, index) => {
    const obj = paymentForm
    obj.splice(index, 1)
    setpaymentForm([...obj])

    if (paymentForm.length < 1) {
      setpaymentForm([])
    }
  }

  const handleChangePaymentMethods = (e, index) => {
    const element = e.target
    const obj = paymentForm

    switch (element.id) {
      case `key${index}`:
        obj[index].objkey = element.value
        break
      case `value${index}`:
        obj[index].objvalue = element.value
        obj[index].objvalueLanguage1 = element.value
        break
      case `valueLanguage2${index}`:
        obj[index].objvalueLanguage2 = element.value
        break
      case `valueLanguage3${index}`:
        obj[index].objvalueLanguage3 = element.value
        break
      case `valueLanguage4${index}`:
        obj[index].objvalueLanguage4 = element.value
        break
      case `default${index}`:
        obj.map((item) => (item.objdefault = false))

        obj[index].objdefault = element.checked
        break
      default:
        break
    }
    setpaymentForm([...obj])
  }

  const UpdateActionPaymentMethod = () => {
    document.getElementById('divAdd').style.display = 'block'
    document
      .querySelectorAll('.divDelete')
      .forEach((a) => (a.style.display = 'block'))
    setEnabled(!enabled)
  }

  const CancelPaymentMethod = async () => {
    FillData()
    document.getElementById('divAdd').style.display = 'none'
    document
      .querySelectorAll('.divDelete')
      .forEach((a) => (a.style.display = 'none'))
    setEnabled(false)
  }

  const ChangePaymentMethod = async () => {
    props.ChangePaymentMethods(paymentForm)
  }

  return (
    <>
      <SectionEditable
        label={I18n.get('lblPaymentMethodsConfig')}
        labelEdit={I18n.get('lblPaymentMethodsConfig')}
        Action={'UPDATE'}
        UpdateAction={UpdateActionPaymentMethod}
        CancelAction={CancelPaymentMethod}
        SaveAction={ChangePaymentMethod}
        Collapse={false}
        lblbtnSave={I18n.get('lblSave')}
        lblbtnCancel={I18n.get('lblCancel')}
      >
        <div className="col-sm-12">
          <div className="row mt-4">
            <div
              className="col-sm-7"
              id="divAdd"
              style={{ display: 'none' }}
            >
              {/* <h6>{I18n.get("lblPaymentMethodsConfig")}</h6> */}
              <i
                className="fas fa-plus"
                style={{ cursor: 'pointer' }}
                onClick={handleClickAdd}
              ></i>
            </div>
            <hr />
          </div>
          {paymentForm && (
            <div>
              {paymentForm.map((obj, index) => {
                return (
                  <div key={index} className="row">
                    <div className="col-sm-2">
                      <InputText
                        id={`key${index}`}
                        name={`key${index}`}
                        label="Key"
                        maxLength="20"
                        value={paymentForm[index].objkey}
                        onChange={(e) =>
                          handleChangePaymentMethods(e, index)
                        }
                        isEditable={enabled}
                      />
                    </div>
                    <div className="col-sm-2">
                      <InputText
                        id={`value${index}`}
                        name={`value${index}`}
                        label="Value"
                        maxLength="20"
                        value={paymentForm[index].objvalue}
                        onChange={(e) =>
                          handleChangePaymentMethods(e, index)
                        }
                        isEditable={enabled}
                      />
                    </div>
                    <div className="col-sm-2">
                      <InputText
                        id={`valueLanguage2${index}`}
                        name={`valueLanguage2${index}`}
                        label={I18n.get('lbllanguage2')}
                        value={paymentForm[index].objvalueLanguage2}
                        onChange={(e) =>
                          handleChangePaymentMethods(e, index)
                        }
                        isEditable={enabled}
                      />
                    </div>
                    <div className="col-sm-2">
                      <InputText
                        id={`valueLanguage3${index}`}
                        name={`valueLanguage3${index}`}
                        label={I18n.get('lbllanguage3')}
                        value={paymentForm[index].objvalueLanguage3}
                        onChange={(e) =>
                          handleChangePaymentMethods(e, index)
                        }
                        isEditable={enabled}
                      />
                    </div>
                    <div className="col-sm-2">
                      <InputText
                        id={`valueLanguage4${index}`}
                        name={`valueLanguage4${index}`}
                        label={I18n.get('lbllanguage4')}
                        value={paymentForm[index].objvalueLanguage4}
                        onChange={(e) =>
                          handleChangePaymentMethods(e, index)
                        }
                        isEditable={enabled}
                      />
                    </div>
                    <div
                      className="col-lg-2 col-md-3 col-sm-3"
                      style={{ maxWidth: '120px' }}
                    >
                      <Switch
                        id={`default${index}`}
                        name={`default${index}`}
                        label="Default"
                        onChange={(e) =>
                          handleChangePaymentMethods(e, index)
                        }
                        value={paymentForm[index].objdefault}
                        isEditable={enabled}
                      />
                    </div>

                    <div
                      className="divDelete"
                      style={{
                        display: enabled ? 'block' : 'none',
                      }}
                    >
                      <i
                        className="fas fa-minus-circle"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) =>
                          handleClickDeletePaymentMethods(e, index)
                        }
                      ></i>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          {/*      <div><pre>{JSON.stringify(paymentForm, null, 2) }</pre></div> 
                        <div><pre>{JSON.stringify(props.paymentMethods, null, 2) }</pre></div>   */}
        </div>
      </SectionEditable>
    </>
  )
}
