import React, { useEffect, useState } from 'react'
import { SeparateTop, InputText, Switch, ImageMultiDropzone, TextMessage } from 'xsales-components'
import { I18n } from "@aws-amplify/core"
import { XSalesConsumer } from '../../../Utils/Utils'
import { ErrorMessage } from '@hookform/error-message';
import { useForm, Controller } from "react-hook-form"
export function FormCategory(props) {
    const { handleSubmit, formState: { errors },control, setValue  } = useForm({
        shouldFocusError: true,
        defaultValues:{
            categoryId: props.data.categoryId,
            nameLanguage1: props.data.nameLanguage1,
            nameLanguage2: props.data.nameLanguage2,
            nameLanguage3: props.data.nameLanguage3,
            nameLanguage4: props.data.nameLanguage4,
            inactive: props.data.inactive,
            imageInternalName: props.data.imageInternalName,
            inactiveOn : props.data.inactiveOn
        }
    });
    const [ inactive, setInactive ] = useState(false)
    
    useEffect(() => {
        
            setValue("categoryId", props.data.categoryId);    
            setValue("nameLanguage1", props.data.nameLanguage1);    
            setValue("nameLanguage2", props.data.nameLanguage2);    
            setValue("nameLanguage3", props.data.nameLanguage3);    
            setValue("nameLanguage4", props.data.nameLanguage4);   
            setValue("inactive", !props.data.inactive);    
            setInactive(!props.data.inactive);
            setValue("imageInternalName", props.data.imageInternalName);  
            setValue("inactiveOn",props.data.inactiveOn);
    }, [props.data]);// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {   
        document.title = "Category:Detail"
        setTimeout(() => {
            XSalesConsumer.hideLanguagesControls()
        },0)
    },[])// eslint-disable-line react-hooks/exhaustive-deps

    const getErrorMessage =  (name) => <ErrorMessage errors={errors} name={name} /> 

   
    
    return (
        <div className="col-sm-12">
        <SeparateTop>
            <form ref={props.setRef} onSubmit={handleSubmit(props.handleSubmit)} id='form-category'>
               <div className='row'>
                <div className='col-sm-12 col-md-6 offset-md-1'>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">   
                        {
                        <div>
                            <Controller
                                control={control}
                                name="categoryId"
                                rules={{ required: I18n.get('lblrequiredField'), maxLength: { value: 15, message: I18n.get('lblmaxLengthField').replace('{length}','15') }   }}
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref, onFocus },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>
                                     <InputText
                                        label={I18n.get('lblcategoryid')}
                                        value={value}
                                        setRef={ref}
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        onFocus={onFocus}
                                        messageError={getErrorMessage('categoryId')}
                                        isEditable={props.mode === "CREATE"} />            
                                    </>
                                  )}
                                />
                        </div>
                        }
                        </div> 
                </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">   
                        <Controller
                                control={control}
                                name="nameLanguage1"
                                rules={{maxLength: { value: 45, message: I18n.get('lblmaxLengthField').replace('{length}','45') }}}
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref, onFocus },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>
                                        <InputText
                                            label={I18n.get('lbllnamelanguage1')}
                                            value={value}
                                            setRef={ref}
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onFocus={onFocus}
                                            messageError={getErrorMessage('nameLanguage1')}
                                            isEditable={props.readOnly}/> 
                                    </>        
                               
                                  )}
                            /> 
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-12 col-md-12">   
                        <Controller
                                control={control}
                                name="nameLanguage2"
                                rules={{ maxLength:{ value: 45, message: I18n.get('lblmaxLengthField').replace('{length}','45') } }}
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref, onFocus },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>
                                        <InputText
                                            label={I18n.get('lbllnamelanguage2')}
                                            value={value}
                                            setRef={ref}
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onFocus={onFocus}
                                            messageError={getErrorMessage('nameLanguage2')}
                                            isEditable={props.readOnly}/> 
                                    </>        
                               
                                  )}
                            /> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">   
                        <Controller
                                control={control}
                                name="nameLanguage3"
                                rules={{ maxLength:{ value: 45, message: I18n.get('lblmaxLengthField').replace('{length}','45') } }}
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref, onFocus },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>
                                        <InputText
                                            label={I18n.get('lbllnamelanguage3')}
                                            value={value}
                                            setRef={ref}
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onFocus={onFocus}
                                            messageError={getErrorMessage('nameLanguage3')}
                                            isEditable={props.readOnly}/> 
                                    </>        
                               
                                  )}
                            /> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">   
                        <Controller
                                control={control}
                                name="nameLanguage4"
                                rules={{ maxLength:{ value: 45, message: I18n.get('lblmaxLengthField').replace('{length}','45') } }}
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref, onFocus },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>
                                        <InputText
                                            label={I18n.get('lbllnamelanguage4')}
                                            value={value}
                                            setRef={ref}
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onFocus={onFocus}
                                            messageError={getErrorMessage('nameLanguage4')}
                                            isEditable={props.readOnly}/> 
                                    </>        
                               
                                  )}
                            /> 
                        </div>                  
                    </div>
                    <div className='row'>
                        <div className="col-sm-12 col-md-4">   
                        <Controller
                                control={control}
                                name="inactive"
                                rules={{   }}
                               
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>
                                        <Switch    
                                            id="inactive"             
                                            setRef={ref}
                                            name={name}
                                            label={I18n.get("lblactive")}
                                            onChange={(e) =>{
                                                setInactive(e.target.checked)
                                                setValue("inactiveOn",(e.target.checked)?new Date().toISOString():"")
                                                onChange(e.target.checked)
                                            }}
                                            onBlur={onBlur}
                                            value={inactive}
                                            isEditable={props.readOnly}/> 
                                         {/* <TextMessage label={I18n.get("lblmsginactivetext")}/>  */}       
                                    </>        
                               
                                  )}
                            /> 
                         
                           
                        </div>
                        <div className="col-sm-12 col-md-8">    

                        <Controller
                                control={control}
                                name="inactiveOn"
                                rules={{   }}
                               
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>

                                    {
                                      (props.mode !== "CREATE" && props.data.inactive === true ) &&
                                      <TextMessage label={XSalesConsumer.formatShortDatetime(props.data.inactiveOn)}/> 
                                    }       
                                    </>        
                               
                                  )}
                            /> 

                   
                    
                    </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-12 col-md-8">
                        <TextMessage label={I18n.get("lblmsginactivetext")}/>  
                        </div>
                    </div>

                    </div>
                    <div className='col-sm-12 col-md-4 col-md-pull-1'>
                    <div className="row">
                        <div className="col-sm-12">   
                        {/* <ImageDropzone 
                            src={props.imageUrl}
                            onChange={props.handleChangeImage}
                            isEditable={props.updateAction}
                            messageDrop={I18n.get("lbldropzone")}
                        /> */}
                        <ImageMultiDropzone 
                             files={props.data.imageFiles}
                             isEditable={props.readOnly}
                             handleOnChange={props.handleOnChangeImage}
                             handleUpload={props.handleUploadImage}
                             width="250px"
                             height="180px"
                             lblTitleModal="Upload Images"
                             alertMessage={props.alertMessageImage} />
                         </div>
                    </div>
                       
                    </div>
               </div> 
              {/* <div><pre>{JSON.stringify(props.readOnly, null, 2) }</pre></div>  */}
            </form>  
        </SeparateTop>
        </div>
    )
}


