import React, { useEffect, useState } from "react"
import { SectionEditable, InputText, SelectCombo, Warning } from "xsales-components"
import { API, I18n } from "aws-amplify"
import { XSalesData } from "../../DataQuery/XSalesData"
import { useParams } from "react-router-dom"
// import Warning from "../../componentsConsumer/Warning"
export function CurrencyConfig(props) {
    const [UpdateAction, setUpdateAction] = useState(false)
    const [UpdateActionInternal, setUpdateActionInternal] = useState(false)
    const [isoSelected, setIsoSelected] = useState("")
    const [isoInternalSelected, setIsoInternalSelected] = useState("")
    const [symbolSelected, setSymbolSelected] = useState("")
    const [currencyISOInternl, setCurrencyISOInternal] = useState([])
    let { id } = useParams()

    useEffect(() => {
        getDataAPI()
            .then((data) => {
                const isos = []
                data.forEach((x) => {
                    isos.push({ label: x.currency, value: x.currency })
                })
                setCurrencyISOInternal(isos)
                getDataSupplier(id)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function getDataSupplier(id) {
        id = id != null ? id : props.id
        debugger
        const { currencyISOErp, currencySymbol, currencyISO } = await XSalesData.Suppliers.GetSupplier(id)
        setIsoSelected(currencyISOErp)
        setSymbolSelected(currencySymbol)
        setIsoInternalSelected(currencyISO)
    }

    const handleChangeCurrencySymbol = (e) => {
        setSymbolSelected(e.target.value)
    }

    const handleChangeCurrencyISO = (e) => {
        setIsoSelected(e.target.value)
    }

    const handleChangeCurrencyISOInternal = (e) => {
        setIsoInternalSelected(e.value)
    }

    const UpdateActionButton = () => {
        setUpdateAction(props.disabledEdit ? false : !UpdateAction)
    }

    const UpdateActionButtonInternal = () => {
        setUpdateActionInternal(props.disabledEdit ? false : !UpdateActionInternal)
    }

    async function getDataAPI() {
        const data = await API.get("backendapi", "/exchangeRates/currencySymbols")
        return data
    }

    const CancelSection = async () => {
        setUpdateAction(false)
        await getDataSupplier(id)
    }

    const CancelSectionInternal = async () => {
        setUpdateActionInternal(false)
        await getDataSupplier(id)
    }
    const ChangeSection = async () => {
        setUpdateAction(false)
        if (id === null || id === undefined) {
            id = props.id
        }
        await XSalesData.Suppliers.SetSupplierCurrency(id, isoSelected, symbolSelected)
            .then((data) => {
                //console.log(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const ChangeSectionInternal = async () => {
        setUpdateActionInternal(false)
        await XSalesData.Suppliers.SetSupplierCurrencyInternal(id, isoInternalSelected)
            .then((data) => {
                //console.log(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <SectionEditable
                        label={I18n.get("lblconfigcurrency")}
                        labelEdit={I18n.get("lblconfigcurrency")}
                        Action={props.disabledEdit ? "VIEW" : "UPDATE"}
                        UpdateAction={UpdateActionButton}
                        CancelAction={CancelSection}
                        SaveAction={ChangeSection}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <InputText
                                    id="currencyISO"
                                    name="currencyISO"
                                    maxLength="5"
                                    label={I18n.get("lblcurrencyiso")}
                                    value={isoSelected}
                                    isEditable={UpdateAction}
                                    onChange={handleChangeCurrencyISO}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <InputText
                                    id="currencySymbol"
                                    name="currencySymbol"
                                    maxLength="32"
                                    label={I18n.get("lblcurrencysymbol")}
                                    value={symbolSelected}
                                    isEditable={UpdateAction}
                                    onChange={handleChangeCurrencySymbol}
                                />
                            </div>
                        </div>
                    </SectionEditable>
                </div>
            </div>
            {props.modeAdmin && (
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <SectionEditable
                            label={I18n.get("lblconfigcurrencyInternal")}
                            labelEdit={I18n.get("lblconfigcurrencyInternal")}
                            Action={"UPDATE"}
                            UpdateAction={UpdateActionButtonInternal}
                            CancelAction={CancelSectionInternal}
                            SaveAction={ChangeSectionInternal}
                            Collapse={false}
                            lblbtnSave={I18n.get("lblSave")}
                            lblbtnCancel={I18n.get("lblCancel")}
                        >
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <SelectCombo
                                        id="currencyISOInternal"
                                        name="currencyISOInternal"
                                        label={I18n.get("lblcurrencyisoInternal")}
                                        labelDefault={I18n.get("lblcurrencyisoInternal")}
                                        options={currencyISOInternl}
                                        //defaultValue={""}
                                        value={isoInternalSelected}
                                        optionSelected={isoInternalSelected}
                                        onChange={handleChangeCurrencyISOInternal}
                                        isEditable={UpdateActionInternal}
                                    />
                                </div>
                                <Warning>{I18n.get("msgInternalCurrency")}</Warning>
                            </div>
                        </SectionEditable>
                    </div>
                </div>
            )}
        </>
    )
}
