import React, { useState, useEffect } from "react"
import { InputEmail, InputPass, Button, CheckStatus  } from 'xsales-components'
import Loader from '../common/loader'
import PreferenceDefault from "../../configConsumer/preferenceDefault.json"
import ImageCDN from "../../configConsumer/imagesDefaultCDN.json"
import { I18n } from "@aws-amplify/core"
import { Auth } from "aws-amplify"
import ConfigAuth from "../../configConsumer/authenticationRules.json"
import { XSalesConsumer } from "./../../Utils/Utils"

function SignUp(props) {
    const [showLoader, setShowLoader] = useState(false)
    const sendData = async () => {
        if (
            sectionData.email.trim() !== "" &&
            sectionData.password === sectionData.confirmPassword
        ) {
            setShowLoader(true)
            await Auth.signUp({
                username: sectionData.email.trim().toLowerCase(),
                password: sectionData.password,
                attributes: {
                    "custom:userType": "2",
                },
            })
                .then(async (data) => {
                    window.location.href = `/Validation/${sectionData.email}`
                })
                .catch(async (err) => {
                    if (err.code === "UsernameExistsException") {
                        XSalesConsumer.fireMessage(
                            "lblSignUp",
                            "msgEmailAlreadyExist",
                            false
                        )
                        await Auth.resendSignUp(sectionData.email)
                            .then(async (data) => {
                                window.location.href = "/SignIn"
                            })
                            .catch(async (err) => {
                                if (
                                    err.CodeDeliveryDetails !== null &&
                                    err.CodeDeliveryDetails !== undefined
                                ) {
                                    window.location.href = `/Validation/${sectionData.email}`
                                    return
                                }
                            })
                    }
                })
        } else {
            XSalesConsumer.fireMessage("lblSignUp", "msgPassNotBeEquals")
        }
        setShowLoader(false)
    }

    const sendBackSignIn = async () => {
        props.history.push("/SignIn")
    }
    //Load configuration in /src/configConsumer/authenticationRules.json
    const [requiredMinimunLength] = useState(ConfigAuth.requiredMinimunLength)
    const [requiredNumber] = useState(ConfigAuth.requiredNumber)
    const [requiredSpecialCharacter] = useState(
        ConfigAuth.requiredSpecialCharacter
    )
    const [requiredUpperCase] = useState(ConfigAuth.requiredUpperCase)
    const [requiredLowerCase] = useState(ConfigAuth.requiredLowerCase)
    const [requiredEqualPass] = useState(ConfigAuth.requiredEqualPass)

    //Variables by User KeyPress value for Password and confirm Password
    const [containNumber, setContainNumber] = useState(false)
    const [containSpecialCharacter, setContainSpecialCharacter] = useState(
        false
    )
    const [containUpperCase, setContainUpperCase] = useState(false)
    const [containLowerCase, setContainLowerCase] = useState(false)
    const [containMinimumLength, setContainMinimumLength] = useState(false)
    const [containDifferencePass, setContainDifferencePass] = useState(false)

    const [sectionData, setSectionData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
    })

    useEffect(() => {
        setSectionData((prevState) => {
            return prevState
        })
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        setSectionData((prevState) => ({
            ...prevState,
            [name]: value
        }))    
    }
 
    const handleChangePassword = (e) => {
        handleChange(e)
        handleOnBlur(e)
    }

    const handleOnBlur = (e) => {
        const {  value } = e.target
        if (requiredNumber) {
            let rn = new RegExp("(?=.*?[0-9])")
            setContainNumber(rn.test(value))
        }

        if (requiredUpperCase) {
            let ruc = new RegExp("(?=.*?[ÑA-Z])")
            setContainUpperCase(isNaN(value) && ruc.test(value))
        }

        if (requiredLowerCase) {
            let rlc = new RegExp("(?=.*?[ña-z])")
            setContainLowerCase(isNaN(value) && rlc.test(value))
        }

        if (requiredSpecialCharacter) {
            let rsc = new RegExp(/\W|_/g)
            setContainSpecialCharacter(isNaN(value) && rsc.test(value))
        }

        if (requiredEqualPass) {
            setContainDifferencePass(() => {
                return sectionData.password === sectionData.confirmPassword
            })
        }

        if (requiredMinimunLength > 0) {
            let rml = value.length >= requiredMinimunLength
            setContainMinimumLength(rml)
        }
    }

    const handleValidationBeforeSend = () => {
        if (
            (requiredLowerCase && !containLowerCase) ||
            (requiredUpperCase && !containUpperCase) ||
            (requiredMinimunLength > 0 && !containMinimumLength) ||
            (requiredSpecialCharacter && !containSpecialCharacter) ||
            (requiredNumber && !containNumber) ||
            (requiredEqualPass && !containDifferencePass)
        ) {
            return
        }
        sendData()
    }

    const handleSubmit = e => {
        e.preventDefault();
        handleValidationBeforeSend();
    }

    return (
        <>
            <section className="login__container">
                <div className="login__box">
                    <a href={PreferenceDefault.XSalesConsumerWEB} target="_blank" rel="noopener noreferrer">
                        <img src={ImageCDN.XSalesConsumerWebLogo} alt="Logo Consumer" />
                    </a>
                    <h4>{I18n.get("lblSignUpIndication")}</h4>
                    <form className="login__form" onSubmit={handleSubmit}>
                        <InputEmail
                            id="email"
                            name="email"
                            maxLength="30"
                            label={I18n.get("lblEmail")}
                            value={sectionData.email}
                            onChange={handleChange}
                            onBlur={handleOnBlur}
                            required={true}
                        />
                        <InputPass
                            id="password"
                            name="password"
                            maxLength="30"
                            label={I18n.get("lblPassword")}
                            value={sectionData.password}
                            onChange={handleChangePassword}
                            onBlur={handleOnBlur}
                            required={true}
                            Icon={true}
                        />
                        <InputPass
                            id="confirmPassword"
                            name="confirmPassword"
                            maxLength="30"
                            label={I18n.get("lblconfirmPassword")}
                            value={sectionData.confirmPassword}
                            onChange={handleChangePassword}
                            onBlur={handleOnBlur}
                            onKeyPress={handleOnBlur}
                            required={true} 
                            Icon={true}
                        />
                        <div>
                            {requiredMinimunLength && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containMinimumLength}                                
                                        label={I18n.get("lblCheckMinimum")}
                                    />
                                </div>
                            )}

                            {requiredSpecialCharacter && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containSpecialCharacter}
                                        label={I18n.get("lblCheckSpecial")}
                                    />
                                </div>
                            )}

                            {requiredNumber && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containNumber}
                                        label={I18n.get("lblCheckNumber")}
                                    />
                                </div>
                            )}

                            {requiredLowerCase && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containLowerCase}
                                        label={I18n.get("lblCheckLowerCase")}
                                    />
                                </div>
                            )}

                            {requiredUpperCase && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containUpperCase}
                                        label={I18n.get("lblCheckUpperCase")}
                                    />
                                </div>
                            )}

                            {requiredEqualPass && (
                                <div className="col-sm-12">
                                    <CheckStatus
                                        condition={containDifferencePass}
                                        label={I18n.get("lblCheckPassDifUser")}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="login__buttons">
                            <Button
                                id="back"
                                name="back"
                                label={I18n.get("lblback")}
                                className="btn btn-outline-secondary bounds-button-cancel"
                                onClick={() => sendBackSignIn()}
                                />
                             <Button
                                id="send"
                                name="send"
                                type="submit"
                                label={I18n.get("lblSignUp")}
                                className="btn btn-outline-primary bounds-button"
                            />
                        </div>
                    </form>
                    {
                        showLoader &&
                        <Loader />
                    }
                </div>
            </section>
        </>
    )
}

export default SignUp
