import React, { useState } from "react"
import {
    InputEmail,
    InputPass,
    Button,
    Loader
} from "xsales-components"
//import * as msRestNodeAuth from "@azure/ms-rest-nodeauth"
// import Container from "../Login/Container"
// import Card from "../Login/Card"
// import InputEmail from "../InputEmail"
// import InputPass from "../InputPass"
// import Button from "../Button"
// import SeparateTop from "../SeparateTop"

//import loading from "./../../assets/img/preloader.gif"
import PreferenceDefault from "../../configConsumer/preferenceDefault.json"
import ImageCDN from "../../configConsumer/imagesDefaultCDN.json"
import { I18n } from "@aws-amplify/core"
import { API } from "aws-amplify"
import { XSalesConsumer } from "./../../Utils/Utils"
import { XSalesData } from "../../DataQuery/XSalesData"
import {analyticsApp} from '../../firebase/firebaseApp'
import { logEvent } from "firebase/analytics";
import Linked from "../../componentsConsumer/Link"

const loading = ImageCDN.loader



function SignIn(props) {
    const [sectionData, setSectionData] = useState({
        email: "",
        password: "",
    })

    const [showLoader, setShowLoader] = useState(false)
    const [message, setMessage] = useState("")

    const sendDataAuth = async () => {
        // e.preventeDefault();
        if (
            sectionData.email.trim() !== "" &&
            sectionData.password.trim() !== ""
        ) {
            const body = {
                body: {
                    userId: sectionData.email.trim(),
                    password: sectionData.password.trim(),
                },
            }
            const response = await API.post("backendapi", `/azure/login`, body)
            if (response.isAuthenticated) {
                let objUserAdmin = {}
                objUserAdmin.user = sectionData.email
                objUserAdmin.email = sectionData.email
                objUserAdmin.type = "admin"
                objUserAdmin.username = ""
                objUserAdmin.language = "EN"
                XSalesConsumer.setCurrentUser(objUserAdmin)
                    .then(async (data) => {
                        logEvent(analyticsApp, 'admin-login', {
                            email: sectionData.email.trim().toLowerCase()
                          });

                        // XSalesConsumer.fireMessage(
                        //     "lblLogin",
                        //     "msgWelcometoConsumer"
                        // )
                        // props.history.push('/Main')
                        const setupsCdn = await XSalesData.Setup.getPathsCDNSetups()
                        localStorage.setItem("cdnSetups",JSON.stringify(setupsCdn))
                        window.location.href = "/Admin/Main"
                    })
                    .catch((err) => {
                        console.log(err)
                        console.log(
                            "Showing error AdminSupport/SignIn setConsumer"
                        )
                        // XSalesConsumer.fireMessage(
                        //     "lblLogin",
                        //     "msgErrorValidatingUserPass"
                        // )
                    })
            } else {
                // XSalesConsumer.fireMessage("lblLogin", "msgNotUseAdmin")
                setMessage("Invalid user")
            }
            setShowLoader(false)
        } else {
            setShowLoader(false)
            return false
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.currentTarget
        setSectionData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setShowLoader(true)
        setMessage("")
        sendDataAuth()
    }

    return (
        // <Card label={I18n.get("lblSigInIndication")}>
        <section className="login__container">
            <div className="login__box">
                <a href={PreferenceDefault.XSalesConsumerWEB}
                        target="_blank" rel="noopener noreferrer"
                    >
                        <img
                            src={ImageCDN.XSalesConsumerWebIsotipo}
                            alt="Logo Consumer"
                        /><br />
                        <img
                            src={ImageCDN.XSalesConsumerWebLogo}
                            alt="Logo Consumer"
                        />
                    </a>
                    <div className="container-fluid">
                        <div className="row mt-3">
                            <div className="col-sm-12 col-md-12 text-center">
                                <h4 className="login_sigInIndication">
                                    {I18n.get("lblSigInIndication")}
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <form className="login__form" onSubmit={handleSubmit}>
                                <InputEmail
                                    id="email"
                                    name="email"
                                    maxLength="30"
                                    label={I18n.get("lblEmail")}
                                    value={sectionData.email}
                                    onChange={handleChange}
                                    requiredField={true}
                                />
                                <InputPass
                                    id="password"
                                    name="password"
                                    maxLength="30"
                                    label={I18n.get("lblPassword")}
                                    value={sectionData.password}
                                    onChange={handleChange}
                                    requiredField={true}
                                />
                                <Button
                                    id="btn-login"
                                    label={I18n.get("lblSend")}
                                    name={I18n.get("lblSend")}
                                    size="lg"
                                    color="primary"
                                    type="submit"
                                    className="login__btn btn btn-primary btn-block"
                                    outline={false}
                                    // dataDismiss='modal'
                                    // onClick={() => alert("")}
                                />
                            </form>
                        </div>
                        <div className="row login__Linked">
                            <div className="col-sm-6 col-md-6 login__options text-left">
                                <Linked
                                    label={I18n.get("lblForgotPass")}
                                    href="/Forgot"
                                />
                            </div>
                        </div>
                        <div className="row mt-3 text-center">
                            <div style={{width: "100%"}}>* Only for Admin/Support XSM authorized</div>
                                {
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <p style={{ color: "red" }}>{message}</p>
                                    </div>
                                }
                                {showLoader && (
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Loader
                                            center={true}
                                            src={loading}
                                            style={{ height: "60px", width: "60px" }}
                                        />
                                    </div>
                                )}
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 login__Version">
                                <span>Version {PreferenceDefault.XSalesConsumerWEBVersion}</span>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
    )
}

export default SignIn
