import { I18n } from 'aws-amplify'
import React from 'react'
import { Bar } from './Bar'

export function OrderCharts(props) {
    return (
        <>
            {
                props.reportType === "orders" ?
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 mt-4'>
                            <Bar
                                headerLabel={I18n.get('lblOrdMaPerDeliveryDate')}
                                chartOptions={{
                                    showLegend: false,
                                    labels: props.dataPerDeliveryDate.labels.map(x => x.shortDate),
                                    datasets: props.dataPerDeliveryDate.datasets,
                                    yLabel: I18n.get('lblOrdMaOrderQty'),
                                    handleClickBar: props.handleClickOrderByDate,
                                    formatter: props.formatterPerDeliveryDate
                                }}
                            />
                        </div>
                        <div className='col-sm-12 col-md-6 mt-4'>
                            <Bar
                                headerLabel={I18n.get('lblOrdMaPerClientGroup')}
                                chartOptions={{
                                    showLegend: false,
                                    labels: props.dataPerClientGroup.labels,
                                    datasets: props.dataPerClientGroup.datasets,
                                    yLabel: I18n.get('lblOrdMaOrderQty'),
                                    formatter: props.formatterPerClientGroup
                                }}
                            />
                        </div>
                    </div>
                    :
                    <>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 mt-4'>
                                <Bar
                                    headerLabel={I18n.get('lblOrdMaPerDeliveryDate')}
                                    chartOptions={{
                                        showLegend: true,
                                        labels: props.dataPerDeliveryDateProduct.labels.map(x => x.shortDate),
                                        datasets: props.dataPerDeliveryDateProduct.datasets,
                                        yLabel: I18n.get('lblOrdMaOrderQty'),
                                        handleClickBar: props.handleClickOrderProductByDate,
                                        formatter: props.formatterDeliveryDateProduct
                                    }}
                                />
                            </div>
                            <div className='col-sm-12 col-md-6 mt-4'>
                                <Bar
                                    headerLabel={I18n.get('lblOrdMaPerClientGroup')}
                                    chartOptions={{
                                        showLegend: true,
                                        labels: props.dataPerClientGroupProduct.labels,
                                        datasets: props.dataPerClientGroupProduct.datasets,
                                        yLabel: I18n.get('lblOrdMaOrderQty'),
                                        formatter: props.formatterPerClientGroupProduct
                                    }}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-8 offset-md-2 mt-5'>
                                <Bar
                                    headerLabel={I18n.get('lblOrdMaPerProduct')}
                                    chartOptions={{
                                        showLegend: true,
                                        labels: props.dataPerProduct.labels,
                                        datasets: props.dataPerProduct.datasets,
                                        yLabel: I18n.get('lblOrdMaOrderQty'),
                                        formatter: props.formatterPerProduct
                                    }}
                                />
                            </div>
                        </div>
                    </>
            }
        </>

    )
}

