import React, { useEffect, useState } from 'react'
import { XSalesConsumer } from '../../../Utils/Utils'
import { XSalesData } from '../../../DataQuery/XSalesData'
import {
  SeparateTop,
  SelectCombo,
  InputText,
  ImageMultiDropzone,
  Switch,
  InputNumber,
  InputTag,
  TextMessage,
  InputCurrency,
} from 'xsales-components'
import styled from 'styled-components'
import { I18n } from '@aws-amplify/core'

import { ErrorMessage } from '@hookform/error-message'
import { useForm, Controller } from 'react-hook-form'

export function FormProduct(props) {
  //const[tagsSelects, setTagsSelects] = useState([])
  //const[stateSelectSubcategory, setStateSelectSubcategory] = useState(false)
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    shouldFocusError: true,
    defaultValues: {
      supplierId: props.data.supplierId,
      subcategoryId: props.data.subcategoryId,
      categoryId: props.data.categoryId,
      unit: props.data.unit,
      descriptionLanguage1: props.data.descriptionLanguage1,
      descriptionLanguage2: props.data.descriptionLanguage2,
      descriptionLanguage3: props.data.descriptionLanguage3,
      descriptionLanguage4: props.data.descriptionLanguage4,
      nameLanguage1: props.data.nameLanguage1,
      nameLanguage2: props.data.nameLanguage2,
      nameLanguage3: props.data.nameLanguage3,
      nameLanguage4: props.data.nameLanguage4,
      shortnameLanguage1: props.data.shortnameLanguage1,
      shortnameLanguage2: props.data.shortnameLanguage2,
      shortnameLanguage3: props.data.shortnameLanguage3,
      shortnameLanguage4: props.data.shortnameLanguage4,
      tags: props.data.tags,
      price: props.data.price,
      percentageDiscount: props.data.percentageDiscount,
      amountDiscount: props.data.amountDiscount,
      blockedForSales: props.data.blockedForSales,
      blockedForSalesDate: props.data.blockedForSalesDate,
      inactive: props.data.inactive,
      inactiveParent: props.data.inactiveParent,
      imageInternalName: props.data.imageInternalName,
      imageExternalURL: props.data.imageExternalURL,
      taxFree: props.data.taxFree,
      productId: props.data.productId,
      imageFiles: props.data.imageFiles,
      inactiveOn: props.data.inactiveOn,
      inactiveParentOn: props.data.inactiveParentOn,
      barcode: props.data.barcode,
      sameOrderClass: props.data.sameOrderClass,
    },
  })

  const [inactive, setInactive] = useState(false)
  const [inactiveParent, setInactiveParent] = useState(false)
  const [taxFree, setTaxFree] = useState(false)
  const [lockedForSales, setLockedForSales] = useState(false)
  const [optionsCategories, setOptionsCategories] = useState([])
  const [categoriesValues, setCategoriesValues] = useState([])
  const [optionSubcategories, setOptionsSubcategories] = useState([])
  const [subcategoriesValues, setSubcategoriesValues] = useState([])

  //const [listsubCategories, setListSubcategories] = useState([])

  useEffect(() => {
    setTimeout(() => {
      XSalesConsumer.hideLanguagesControls()
    }, 0)
  }, [])

  useEffect(async () => {
    setValue('supplierId', props.data.supplierId)
    setValue('subcategoryId', props.data.subcategoryId)
    setValue('categoryId', props.data.categoryId)
    setValue('unit', props.data.unit)
    setValue('descriptionLanguage1', props.data.descriptionLanguage1)
    setValue('descriptionLanguage2', props.data.descriptionLanguage2)
    setValue('descriptionLanguage3', props.data.descriptionLanguage3)
    setValue('descriptionLanguage4', props.data.descriptionLanguage4)
    setValue('nameLanguage1', props.data.nameLanguage1)
    setValue('nameLanguage2', props.data.nameLanguage2)
    setValue('nameLanguage3', props.data.nameLanguage3)
    setValue('nameLanguage4', props.data.nameLanguage4)
    setValue('shortnameLanguage1', props.data.shortnameLanguage1)
    setValue('shortnameLanguage2', props.data.shortnameLanguage2)
    setValue('shortnameLanguage3', props.data.shortnameLanguage3)
    setValue('shortnameLanguage4', props.data.shortnameLanguage4)
    setValue('tags', props.data.tagValues)
    setValue('price', props.data.price)
    setValue('percentageDiscount', props.data.percentageDiscount)
    setValue('amountDiscount', props.data.amountDiscount)
    setValue('blockedForSalesDate', props.data.blockedForSalesDate)
    setValue('imageInternalName', props.data.imageInternalName)
    setValue('imageExternalURL', props.data.imageExternalURL)
    setValue('productId', props.data.productId)
    setValue('imageFiles', props.data.imageFiles)
    setValue('taxFree', props.data.taxFree)
    setTaxFree(props.data.taxFree)
    setValue('blockedForSales', props.data.blockedForSales)
    setLockedForSales(!props.data.blockedForSales)
    setValue('inactiveParent', props.data.inactiveParent)
    setInactiveParent(!props.data.inactiveParent)
    setValue('inactive', props.data.inactive)
    setInactive(!props.data.inactive)
    setValue('inactiveOn', props.data.inactiveOn)
    setValue('inactiveParentOn', props.data.inactiveParentOn)
    setValue('barcode', props.data.barcode)
    setValue('sameOrderClass', props.data.sameOrderClass)

    await getDataCategories()
    await getDataSubCategories(
      props.data.categoryId,
      props.data.subcategoryId,
    )
  }, [props.data]) // eslint-disable-line react-hooks/exhaustive-deps

  async function getDataCategories() {
    try {
      const data = await XSalesData.Category.list(
        props.data.supplierId,
      )
      const list = data.map((x) => ({
        label: x.categoryId,
        value: x.categoryId,
      }))
      setOptionsCategories(list)
      if (props.data.categoryId) {
        return setCategoriesValues(
          list.filter((x) => x.value === props.data.categoryId),
        )
        //return
      }
      setValue('categoryId', '')
    } catch (error) {
      //console.error(error)
    }
  }

  async function getDataSubCategories(categoryId, subcategoryId) {
    try {
      const data = await XSalesData.Subcategory.ListByCategory(
        props.data.supplierId,
        categoryId,
      )
      const list = data.map((x) => ({
        label: x.subcategoryId,
        value: x.subcategoryId,
      }))
      setOptionsSubcategories(list)
      if (subcategoryId) {
        return setSubcategoriesValues(
          list.filter((x) => x.value === subcategoryId),
        )
        //return
      }
      setValue('subcategoryId', '')
    } catch (error) {
      //console.log(error)
    }
  }

  const getErrorMessage = (name) => (
    <ErrorMessage errors={errors} name={name} />
  )

  const handlechangeCategory = async (values) => {
    setValue('categoryId', values.value)
    setCategoriesValues({ ...values })
    props.data.categoryId = values.value
    await getDataSubCategories(values.value, props.data.subcategoryId)
  }

  const handlechangeSubCategory = (value) => {
    setValue('subcategoryId', value.value)
    props.data.subcategoryId = value.value
    //setSubcategoriesValues({...value})
  }

  const handleblurPercentageDiscount = (e) => {
    let value = e.target.value
    if (value > 100) {
      setValue('percentageDiscount', 100)
    }
    if (
      value < 0 ||
      value === '' ||
      value === undefined ||
      value === null
    ) {
      setValue('percentageDiscount', 0)
    }
  }

  const handleSubmitValidate = (data) => props.handleSubmit(data)

  return (
    <div className="col-sm-12">
      <SeparateTop>
        <form
          ref={props.setRef}
          onSubmit={handleSubmit(handleSubmitValidate)}
          id="form-Product"
        >
          <div className="row">
            <div className="col-sm-12 col-md-7">
              <div className="row">
                <div className="col-sm-12 col-md-6 mt-1">
                  <Controller
                    control={control}
                    name="productId"
                    rules={{
                      required: I18n.get('lblrequiredField'),
                      maxLength: {
                        value: 15,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '15'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <Container>
                          <InputText
                            label="Id"
                            setRef={ref}
                            name={name}
                            onChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            maxLength={15}
                            messageError={getErrorMessage(
                              'productId',
                            )}
                            isEditable={props.mode === 'CREATE'}
                          />
                        </Container>
                      </>
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <Controller
                    control={control}
                    name="categoryId"
                    rules={{ required: I18n.get('lblrequiredField') }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        {
                          <ContainerSelectCombo>
                            {
                              <SelectCombo
                                name="categoryId"
                                options={optionsCategories}
                                value={value}
                                //optionSelected={value}
                                // optionSelected = {props.CategorySelected}
                                //defaultValue={""}
                                isEditable={props.readOnly}
                                label="List Categories"
                                onChange={handlechangeCategory}
                                messageError={getErrorMessage(
                                  'subcategoryId',
                                )}
                              />
                            }
                          </ContainerSelectCombo>
                        }
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6 mt-1">
                  <Controller
                    control={control}
                    name="unit"
                    rules={{
                      required: I18n.get('lblrequiredField'),
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <Container>
                          <InputText
                            name={name}
                            setRef={ref}
                            label={I18n.get('lblunitId')}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            messageError={getErrorMessage('unit')}
                            isEditable={props.mode === 'CREATE'}
                          />
                        </Container>
                      </>
                    )}
                  />
                </div>

                <div className="col-sm-12 col-md-6">
                  <Controller
                    control={control}
                    name="subcategoryId"
                    rules={{ required: I18n.get('lblrequiredField') }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        {
                          <ContainerSelectCombo>
                            {
                              <SelectCombo
                                name="subcategoryId"
                                options={optionSubcategories}
                                value={value}
                                isEditable={props.readOnly}
                                label="List Sub-Categories"
                                onChange={handlechangeSubCategory}
                                messageError={getErrorMessage(
                                  'subcategoryId',
                                )}
                              />
                            }
                          </ContainerSelectCombo>
                        }
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 offset-md-1">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <ImageMultiDropzone
                    files={props.data.imageFiles}
                    isEditable={props.readOnly}
                    handleOnChange={props.handleOnChangeImage}
                    handleUpload={props.handleUploadImage}
                    width="250px"
                    height="180px"
                    isMulti
                    lblTitleModal="Upload Images"
                    alertMessage={props.alertMessageImage}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <Controller
                    control={control}
                    name="nameLanguage1"
                    rules={{
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lbllnamelanguage1')}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'nameLanguage1',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <Controller
                    control={control}
                    name="shortnameLanguage1"
                    rules={{
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lblshortnameLanguage1')}
                          value={value ? value : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'shortnameLanguage1',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <Controller
                    control={control}
                    name="nameLanguage2"
                    rules={{
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lbllnamelanguage2')}
                          value={value ? value : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'nameLanguage2',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <Controller
                    control={control}
                    name="shortnameLanguage2"
                    rules={{
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lblshortnameLanguage2')}
                          value={value ? value : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'shortnameLanguage2',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <Controller
                    control={control}
                    name="nameLanguage3"
                    rules={{
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lbllnamelanguage3')}
                          value={value ? value : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'nameLanguage3',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>

                <div className="col-sm-12 col-md-6">
                  <Controller
                    control={control}
                    name="shortnameLanguage3"
                    rules={{
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lblshortnameLanguage3')}
                          value={value ? value : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'shortnameLanguage3',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <Controller
                    control={control}
                    name="nameLanguage4"
                    rules={{
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lbllnamelanguage4')}
                          value={value ? value : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'nameLanguage4',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <Controller
                    control={control}
                    name="shortnameLanguage4"
                    rules={{
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lblshortnameLanguage4')}
                          value={value ? value : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'shortnameLanguage4',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <Controller
                    control={control}
                    name="descriptionLanguage1"
                    rules={{
                      maxLength: {
                        value: 200,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lbldescriptionLanguage1')}
                          value={value ? value : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'descriptionLanguage1',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-12">
                  <Controller
                    control={control}
                    name="descriptionLanguage2"
                    rules={{
                      maxLength: {
                        value: 200,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lbldescriptionLanguage2')}
                          value={value ? value : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'descriptionLanguage2',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-12">
                  <Controller
                    control={control}
                    name="descriptionLanguage3"
                    rules={{
                      maxLength: {
                        value: 200,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lbldescriptionLanguage3')}
                          value={value ? value : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'descriptionLanguage3',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-12">
                  <Controller
                    control={control}
                    name="descriptionLanguage4"
                    rules={{
                      maxLength: {
                        value: 200,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputText
                          name={name}
                          setRef={ref}
                          label={I18n.get('lbldescriptionLanguage4')}
                          value={value ? value : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'descriptionLanguage4',
                          )}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-3">
                  <Controller
                    control={control}
                    name="price"
                    rules={{
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputCurrency
                          id={name}
                          label={I18n.get('lblprice')}
                          name={name}
                          /* style: { width: "450px"  }, */
                          cultureInfo="en-US"
                          currencySymbol="USD"
                          defaultValue={value ? value : 0}
                          decimalSeparator="."
                          thousandSeparator=","
                          isEditable={props.readOnly}
                          decimalScale={4}
                          value={value}
                          onChange={(e) => {
                            setValue('price', e)
                          }}
                        />

                        {/*  <div id= {"div-"+name} className="form__group">
                            <input 
                                id={name} 
                                className="form__input"
                                type="text" 
                                name={name} 
                                value={XSalesConsumer.formatterCurrency(value,"USD","en-US")} 
                                onFocus={(e) =>{
                                    document.getElementById("div-"+name).style.display = "none";
                                    document.getElementById("div-T"+name).style.display = "block";
                                }}
                              />
                             </div> 
                             
                             <div id={"div-T"+name} style={{ display: "none"}} className="form__group">
                                <input 
                                id={name+"Text"} 
                                className="form__input"
                                type="number" 
                                name={name} 
                                value={value} 
                                onBlur={(e) =>{ 
                                    setValue("price", e.target.value); 
                                    document.getElementById("div-T"+name).style.display = "none" ; 
                                    document.getElementById("div-"+name).style.display = "block";
                                }} 
                                onChange={onChange}
                                    />
                                </div> */}
                      </>
                    )}
                  />
                </div>

                <div className="col-sm-12 col-md-3">
                  <Controller
                    control={control}
                    name="percentageDiscount"
                    rules={{
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputNumber
                          name={name}
                          setRef={ref}
                          label={I18n.get('lblpercentageDiscount')}
                          defaultValue={
                            props.data.percentageDiscount
                              ? props.data.percentageDiscount
                              : 0
                          }
                          onChange={onChange}
                          onBlur={handleblurPercentageDiscount}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'percentageDiscount',
                          )}
                          isEditable={props.readOnly}
                          value={value}
                          min={0}
                          max={100}
                        />
                      </>
                    )}
                  />
                </div>
                <div
                  className="col-sm-12 col-md-3"
                  style={{ display: 'none' }}
                >
                  <Controller
                    control={control}
                    name="amountDiscount"
                    rules={{
                      maxLength: {
                        value: 45,
                        message: I18n.get(
                          'lblmaxLengthField',
                        ).replace('{length}', '45'),
                      },
                    }}
                    render={({
                      field: {
                        onChange,
                        onBlur,
                        value,
                        name,
                        ref,
                        onFocus,
                      },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <InputNumber
                          id={name}
                          name={name}
                          setRef={ref}
                          label={I18n.get('lblamountDiscount')}
                          decimalScale={2}
                          value={value ? value : 0}
                          //onChange={({floatValue, formattedValue, value}) => onChange(value)}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          messageError={getErrorMessage(
                            'amountDiscount',
                          )}
                          isEditable={props.readOnly}
                          thousandSeparator={true}
                        />
                      </>
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-3">
                  {
                    <Controller
                      control={control}
                      name="barcode"
                      rules={{}}
                      render={({
                        field: {
                          onChange,
                          onBlur,
                          value,
                          name,
                          ref,
                          onFocus,
                        },
                        fieldState: {
                          invalid,
                          isTouched,
                          isDirty,
                          error,
                        },
                        formState,
                      }) => (
                        <>
                          <InputText
                            name={name}
                            setRef={ref}
                            label={I18n.get('lblbarcode')}
                            value={value ? value : ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            messageError={getErrorMessage('barcode')}
                            isEditable={props.readOnly}
                          />
                        </>
                      )}
                    />
                  }
                </div>
                <div className="col-sm-12 col-md-3">
                  <Controller
                    control={control}
                    name="taxFree"
                    rules={{}}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <Switch
                          id="taxFree"
                          setRef={ref}
                          name={name}
                          label={I18n.get('lbltaxFree')}
                          onChange={(e) => {
                            setTaxFree(e.target.checked)
                            onChange(e.target.checked)
                          }}
                          onBlur={onBlur}
                          value={taxFree}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-3">
                  {
                    <Controller
                      control={control}
                      name="sameOrderClass"
                      rules={{}}
                      render={({
                        field: {
                          onChange,
                          onBlur,
                          value,
                          name,
                          ref,
                          onFocus,
                        },
                        fieldState: {
                          invalid,
                          isTouched,
                          isDirty,
                          error,
                        },
                        formState,
                      }) => (
                        <>
                          {/* 
                                       <InputText
                                                name={name}
                                                setRef={ref}
                                                label={I18n.get('lblsameOrderClass')}
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                onFocus={onFocus}
                                                messageError={getErrorMessage('sameOrderClass')}
                                                isEditable={props.readOnly}
                                            /> */}

                          <InputTag
                            isDisabled={!props.readOnly}
                            onChange={
                              props.handleChangeSameOrderClasses
                            }
                            options={props.listSameOrderClasses}
                            id={'sameOrderClass'}
                            name={'sameOrderClass'}
                            label={I18n.get('lblsameOrderClass')}
                            isMulti={false}
                            values={props.SameOrderClassesValues}
                            defaultValue={
                              props.SameOrderClassesValues
                            }
                          />
                        </>
                      )}
                    />
                  }
                </div>
                <div className="col-sm-12 col-md-9">
                  {
                    <Controller
                      control={control}
                      name="tags"
                      rules={{}}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: {
                          invalid,
                          isTouched,
                          isDirty,
                          error,
                        },
                        formState,
                      }) => (
                        <>
                          <InputTag
                            isDisabled={!props.readOnly}
                            options={props.listTags}
                            onChange={props.handleChangeTags}
                            id={'tags'}
                            name={'tags'}
                            noResultMessage={null}
                            label={'Tags'}
                            values={props.tagValues}
                            isMulti={true}
                            defaultValue={props.tagValues}
                            // selectedValues={tagsSelects}
                          />
                        </>
                      )}
                    />
                  }
                </div>
              </div>
              <Container>
                <div className="row">
                  <div className="col-sm-12 col-md-2">
                    <Controller
                      control={control}
                      name="blockedForSales"
                      rules={{}}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: {
                          invalid,
                          isTouched,
                          isDirty,
                          error,
                        },
                        formState,
                      }) => (
                        <>
                          <Switch
                            id="blockedForSales"
                            setRef={ref}
                            name={name}
                            label={I18n.get('lblavailableForSale')}
                            onChange={(e) => {
                              setLockedForSales(e.target.checked)
                              setValue(
                                'blockedForSalesDate',
                                !e.target.checked
                                  ? new Date().toISOString()
                                  : '',
                              )
                              onChange(!e.target.checked)
                            }}
                            onBlur={onBlur}
                            value={lockedForSales}
                            isEditable={props.readOnly}
                          />
                        </>
                      )}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6">
                    {
                      <Controller
                        control={control}
                        name="blockedForSalesDate"
                        rules={{}}
                        render={({
                          field: {
                            onChange,
                            onBlur,
                            value,
                            name,
                            ref,
                          },
                          fieldState: {
                            invalid,
                            isTouched,
                            isDirty,
                            error,
                          },
                          formState,
                        }) => (
                          <>
                            {props.mode !== 'CREATE' &&
                              props.data.blockedForSales === true && (
                                <TextMessage
                                  label={XSalesConsumer.formatShortDatetime(
                                    props.data.blockedForSalesDate,
                                  )}
                                />
                              )}
                          </>
                        )}
                      />
                    }
                  </div>
                </div>
              </Container>
              <div className="row">
                <div className="col-sm-12 col-md-2">
                  <Controller
                    control={control}
                    name="inactive"
                    rules={{}}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <Switch
                          id="inactive"
                          setRef={ref}
                          name={name}
                          label={I18n.get('lblactive')}
                          onChange={(e) => {
                            setInactive(e.target.checked)
                            setValue(
                              'inactiveOn',
                              !e.target.checked
                                ? new Date().toISOString()
                                : '',
                            )
                            onChange(!e.target.checked)
                          }}
                          onBlur={onBlur}
                          value={inactive}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>

                <Controller
                  control={control}
                  name="inactiveOn"
                  rules={{}}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: {
                      invalid,
                      isTouched,
                      isDirty,
                      error,
                    },
                    formState,
                  }) => (
                    <>
                      {props.mode !== 'CREATE' &&
                        props.data.inactive === true && (
                          <div className="col-sm-12 col-md-2">
                            <TextMessage
                              label={XSalesConsumer.formatShortDatetime(
                                props.data.inactiveOn,
                              )}
                            />
                          </div>
                        )}
                    </>
                  )}
                />

                <div className="col-sm-12 col-md-2">
                  <Controller
                    control={control}
                    name="inactiveParent"
                    rules={{}}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: {
                        invalid,
                        isTouched,
                        isDirty,
                        error,
                      },
                      formState,
                    }) => (
                      <>
                        <Switch
                          id="inactiveParent"
                          setRef={ref}
                          name={name}
                          label={I18n.get('lblactiveSubCategory')}
                          onChange={(e) => {
                            setInactiveParent(e.target.checked)
                            setValue(
                              'inactiveParentOn',
                              !e.target.checked
                                ? new Date().toISOString()
                                : '',
                            )
                            onChange(!e.target.checked)
                          }}
                          onBlur={onBlur}
                          value={inactiveParent}
                          isEditable={props.readOnly}
                        />
                      </>
                    )}
                  />
                </div>
                <Controller
                  control={control}
                  name="inactiveParentOn"
                  rules={{}}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: {
                      invalid,
                      isTouched,
                      isDirty,
                      error,
                    },
                    formState,
                  }) => (
                    <>
                      {props.mode !== 'CREATE' &&
                        props.data.inactiveParent === true && (
                          <div className="col-sm-12 col-md-2">
                            <TextMessage
                              label={XSalesConsumer.formatShortDatetime(
                                props.data.inactiveParentOn,
                              )}
                            />
                          </div>
                        )}
                    </>
                  )}
                />
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <TextMessage
                    label={I18n.get('lblmsginactivetext')}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </SeparateTop>
      {/* <div><pre>{JSON.stringify(props.tagValues, null, 2) }</pre></div>  */}
    </div>
  )
}
const ContainerSelectCombo = styled.div`
  margin-bottom: 2rem;
`
const Container = styled.div`
  padding-top: 25px;
`
/* const divHidden = styled.div`
    display: none;
` */
