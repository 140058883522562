import React, { useEffect, useState } from 'react'
import { InputText} from 'xsales-components'
import { XSalesData } from '../../DataQuery/XSalesData'
import { I18n } from "aws-amplify"



export default function CurrencyConfigConsumer(props) {
    

    const [currencySelected, setCurrencySelected] = useState("")
    const [symbolSelected, setSymbolSelected] = useState("")

    useEffect(() => {

        getDataSupplier(props.Data.consumerSupplier)
        getDataConsumer(props.Data.consumerId)
       
    },[])// eslint-disable-line react-hooks/exhaustive-deps

   

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function getDataSupplier(SupplierId){
      const  {currencyISOErp, currencySymbol}  = await XSalesData.Suppliers.GetSupplier(SupplierId)
      props.Data["SupplierCurrency"] = currencyISOErp
      props.Data["SupplierSymbol"] = currencySymbol
      setCurrencySelected(currencyISOErp)
      setSymbolSelected(currencySymbol)

}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function getDataConsumer(ConsumerId){
        const { currencyISOErp, currencySymbol}  = await XSalesData.Consumer.GetConsumer(ConsumerId)
        setCurrencySelected(currencyISOErp)
        setSymbolSelected(currencySymbol)
        props.Data["ConsumerCurrency"] = currencyISOErp
        props.Data["ConsumerSymbol"] = currencySymbol
        props.Data["SelectedCurrency"] = currencyISOErp
        props.Data["SelectedSymbol"] =  currencySymbol
    }

    function handleChangeCurrencySymbol(e) {
        setSymbolSelected(e.target.value)
        props.Data["SelectedSymbol"] =  e.target.value
    }
    function handleChangeCurrencyISO(e) {
     
        setCurrencySelected(e.target.value)
        props.Data["SelectedCurrency"] = e.target.value
       
    }

return (
<>
   {
       <div className="row">
       <div className="col-md-6">
               <InputText
                            id="currencyISO"
                            name="currencyISO"
                            maxLength="32"
                            label={I18n.get("lblcurrencyiso")}
                            value={currencySelected}
                            isEditable={props.UpdateAction}
                            onChange = {handleChangeCurrencyISO}
                            />
       </div>

        <div className="col-md-6">
                           <InputText
                            id="currencySymbol"
                            name="currencySymbol"
                            maxLength="32"
                            label={I18n.get("lblcurrencysymbol")}
                            value={symbolSelected}
                            isEditable={props.UpdateAction}
                            onChange = {handleChangeCurrencySymbol}
                            />
                           
          </div>
          </div>    
    }
    </>
    
        )
}