/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateProductSupplier = /* GraphQL */ `
  subscription OnUpdateProductSupplier($supplierId: ID!) {
    onUpdateProductSupplier(supplierId: $supplierId) {
      id
      productId
      supplierId
      unit
      subcategoryId
      descriptionLanguage1
      descriptionLanguage2
      descriptionLanguage3
      descriptionLanguage4
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      tags {
        tag
      }
      price
      percentageDiscount
      amountDiscount
      blockedForSales
      blockedForSalesDate
      inactive
      inactiveOn
      inactiveParent
      inactiveParentOn
      imageInternalName
      imageExternalURL
      taxFree
      barcode
      sameOrderClass
      stock
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCategorySupplier = /* GraphQL */ `
  subscription OnUpdateCategorySupplier($supplierId: ID!) {
    onUpdateCategorySupplier(supplierId: $supplierId) {
      id
      supplierId
      categoryId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      inactive
      inactiveOn
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSubcategorySupplier = /* GraphQL */ `
  subscription OnUpdateSubcategorySupplier($supplierId: ID!) {
    onUpdateSubcategorySupplier(supplierId: $supplierId) {
      id
      supplierId
      categoryId
      subcategoryId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      inactive
      inactiveOn
      inactiveParent
      inactiveParentOn
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupplierConsumerByConsumer = /* GraphQL */ `
  subscription OnUpdateSupplierConsumerByConsumer($consumerId: ID!) {
    onUpdateSupplierConsumerByConsumer(consumerId: $consumerId) {
      id
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      consumerId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      requestedOn
      requestedBy
      requestComments
      responsedOn
      responsedBy
      responseComments
      consumerIdERP
      requestStatus
      blockedForSales
      daysDelivery {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        sameDayDelivery
        sameDayDeliveryUntil
        sameDayDeliveryTimeZone
      }
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      taxPercentage
      discountPercentage
      group1
      group2
      group3
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupplierSetupBySupplier = /* GraphQL */ `
  subscription OnUpdateSupplierSetupBySupplier($supplierId: ID!) {
    onUpdateSupplierSetupBySupplier(supplierId: $supplierId) {
      id
      supplierId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupplierConsumerSetupByConsumer = /* GraphQL */ `
  subscription OnUpdateSupplierConsumerSetupByConsumer($consumerId: ID!) {
    onUpdateSupplierConsumerSetupByConsumer(consumerId: $consumerId) {
      id
      supplierId
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      username
      name
      lastName
      alternateEmail
      lastSessionDate
      lastIP
      lastOS
      lastDevice
      lastBrowser
      token
      email
      userType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      username
      name
      lastName
      alternateEmail
      lastSessionDate
      lastIP
      lastOS
      lastDevice
      lastBrowser
      token
      email
      userType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      username
      name
      lastName
      alternateEmail
      lastSessionDate
      lastIP
      lastOS
      lastDevice
      lastBrowser
      token
      email
      userType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReasonUnsubscribe = /* GraphQL */ `
  subscription OnCreateReasonUnsubscribe {
    onCreateReasonUnsubscribe {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      commentRequired
      inactive
      inactiveOn
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReasonUnsubscribe = /* GraphQL */ `
  subscription OnUpdateReasonUnsubscribe {
    onUpdateReasonUnsubscribe {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      commentRequired
      inactive
      inactiveOn
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReasonUnsubscribe = /* GraphQL */ `
  subscription OnDeleteReasonUnsubscribe {
    onDeleteReasonUnsubscribe {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      commentRequired
      inactive
      inactiveOn
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserUnsubscribed = /* GraphQL */ `
  subscription OnCreateUserUnsubscribed {
    onCreateUserUnsubscribed {
      username
      name
      lastName
      alternateEmail
      email
      userType
      verificationRequestDate
      verificationCode
      verificationConfirmationDate
      reasonId
      reasonComments
      requestDate
      status
      scheduleUnsubscribe
      scheduleUnsubscribeName
      scheduleUnsubscribeDate
      scheduleReminderName
      scheduleReminderDate
      unsubscribedProcessedDate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserUnsubscribed = /* GraphQL */ `
  subscription OnUpdateUserUnsubscribed {
    onUpdateUserUnsubscribed {
      username
      name
      lastName
      alternateEmail
      email
      userType
      verificationRequestDate
      verificationCode
      verificationConfirmationDate
      reasonId
      reasonComments
      requestDate
      status
      scheduleUnsubscribe
      scheduleUnsubscribeName
      scheduleUnsubscribeDate
      scheduleReminderName
      scheduleReminderDate
      unsubscribedProcessedDate
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserUnsubscribed = /* GraphQL */ `
  subscription OnDeleteUserUnsubscribed {
    onDeleteUserUnsubscribed {
      username
      name
      lastName
      alternateEmail
      email
      userType
      verificationRequestDate
      verificationCode
      verificationConfirmationDate
      reasonId
      reasonComments
      requestDate
      status
      scheduleUnsubscribe
      scheduleUnsubscribeName
      scheduleUnsubscribeDate
      scheduleReminderName
      scheduleReminderDate
      unsubscribedProcessedDate
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSupplier = /* GraphQL */ `
  subscription OnCreateSupplier {
    onCreateSupplier {
      id
      name
      businessName
      username
      taxID
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      status
      historyStatus {
        date
        status
      }
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      contactName
      contactEmail
      latitude
      longitude
      summary
      createdAt
      approvedAt
      industries {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencySymbol
      currencyISOErp
      contract {
        current {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        future {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        history {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
      }
      isXSM
      imageInternalName
      imageExternalURL
      taxPercentage
      discountPercentage
      groups1
      groups2
      groups3
      productTags
      paymentMethods {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      productSameOrderClasses
      updatedAt
    }
  }
`;
export const onUpdateSupplier = /* GraphQL */ `
  subscription OnUpdateSupplier {
    onUpdateSupplier {
      id
      name
      businessName
      username
      taxID
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      status
      historyStatus {
        date
        status
      }
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      contactName
      contactEmail
      latitude
      longitude
      summary
      createdAt
      approvedAt
      industries {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencySymbol
      currencyISOErp
      contract {
        current {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        future {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        history {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
      }
      isXSM
      imageInternalName
      imageExternalURL
      taxPercentage
      discountPercentage
      groups1
      groups2
      groups3
      productTags
      paymentMethods {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      productSameOrderClasses
      updatedAt
    }
  }
`;
export const onDeleteSupplier = /* GraphQL */ `
  subscription OnDeleteSupplier {
    onDeleteSupplier {
      id
      name
      businessName
      username
      taxID
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      status
      historyStatus {
        date
        status
      }
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      contactName
      contactEmail
      latitude
      longitude
      summary
      createdAt
      approvedAt
      industries {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencySymbol
      currencyISOErp
      contract {
        current {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        future {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        history {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
      }
      isXSM
      imageInternalName
      imageExternalURL
      taxPercentage
      discountPercentage
      groups1
      groups2
      groups3
      productTags
      paymentMethods {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      productSameOrderClasses
      updatedAt
    }
  }
`;
export const onCreateSupplierIndustry = /* GraphQL */ `
  subscription OnCreateSupplierIndustry {
    onCreateSupplierIndustry {
      id
      supplierId
      industryId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupplierIndustry = /* GraphQL */ `
  subscription OnUpdateSupplierIndustry {
    onUpdateSupplierIndustry {
      id
      supplierId
      industryId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSupplierIndustry = /* GraphQL */ `
  subscription OnDeleteSupplierIndustry {
    onDeleteSupplierIndustry {
      id
      supplierId
      industryId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateIndustry = /* GraphQL */ `
  subscription OnCreateIndustry {
    onCreateIndustry {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      active
      suppliers {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      consumers {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateIndustry = /* GraphQL */ `
  subscription OnUpdateIndustry {
    onUpdateIndustry {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      active
      suppliers {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      consumers {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteIndustry = /* GraphQL */ `
  subscription OnDeleteIndustry {
    onDeleteIndustry {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      active
      suppliers {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      consumers {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConsumer = /* GraphQL */ `
  subscription OnCreateConsumer {
    onCreateConsumer {
      id
      name
      businessName
      username
      taxID
      status
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      summary
      contactName
      contactEmail
      latitude
      longitude
      industries {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isXSM
      currencyISO
      currencySymbol
      currencyISOErp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConsumer = /* GraphQL */ `
  subscription OnUpdateConsumer {
    onUpdateConsumer {
      id
      name
      businessName
      username
      taxID
      status
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      summary
      contactName
      contactEmail
      latitude
      longitude
      industries {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isXSM
      currencyISO
      currencySymbol
      currencyISOErp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConsumer = /* GraphQL */ `
  subscription OnDeleteConsumer {
    onDeleteConsumer {
      id
      name
      businessName
      username
      taxID
      status
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      summary
      contactName
      contactEmail
      latitude
      longitude
      industries {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isXSM
      currencyISO
      currencySymbol
      currencyISOErp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConsumerIndustry = /* GraphQL */ `
  subscription OnCreateConsumerIndustry {
    onCreateConsumerIndustry {
      id
      consumerId
      industryId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConsumerIndustry = /* GraphQL */ `
  subscription OnUpdateConsumerIndustry {
    onUpdateConsumerIndustry {
      id
      consumerId
      industryId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConsumerIndustry = /* GraphQL */ `
  subscription OnDeleteConsumerIndustry {
    onDeleteConsumerIndustry {
      id
      consumerId
      industryId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      industry {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSupplierConsumer = /* GraphQL */ `
  subscription OnCreateSupplierConsumer {
    onCreateSupplierConsumer {
      id
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      consumerId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      requestedOn
      requestedBy
      requestComments
      responsedOn
      responsedBy
      responseComments
      consumerIdERP
      requestStatus
      blockedForSales
      daysDelivery {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        sameDayDelivery
        sameDayDeliveryUntil
        sameDayDeliveryTimeZone
      }
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      taxPercentage
      discountPercentage
      group1
      group2
      group3
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupplierConsumer = /* GraphQL */ `
  subscription OnUpdateSupplierConsumer {
    onUpdateSupplierConsumer {
      id
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      consumerId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      requestedOn
      requestedBy
      requestComments
      responsedOn
      responsedBy
      responseComments
      consumerIdERP
      requestStatus
      blockedForSales
      daysDelivery {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        sameDayDelivery
        sameDayDeliveryUntil
        sameDayDeliveryTimeZone
      }
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      taxPercentage
      discountPercentage
      group1
      group2
      group3
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSupplierConsumer = /* GraphQL */ `
  subscription OnDeleteSupplierConsumer {
    onDeleteSupplierConsumer {
      id
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      consumerId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      requestedOn
      requestedBy
      requestComments
      responsedOn
      responsedBy
      responseComments
      consumerIdERP
      requestStatus
      blockedForSales
      daysDelivery {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        sameDayDelivery
        sameDayDeliveryUntil
        sameDayDeliveryTimeZone
      }
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      taxPercentage
      discountPercentage
      group1
      group2
      group3
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSupplierConsumerProduct = /* GraphQL */ `
  subscription OnCreateSupplierConsumerProduct {
    onCreateSupplierConsumerProduct {
      id
      productId
      supplierId
      consumerId
      unit
      price
      percentageDiscount
      amountDiscount
      stock
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupplierConsumerProduct = /* GraphQL */ `
  subscription OnUpdateSupplierConsumerProduct {
    onUpdateSupplierConsumerProduct {
      id
      productId
      supplierId
      consumerId
      unit
      price
      percentageDiscount
      amountDiscount
      stock
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSupplierConsumerProduct = /* GraphQL */ `
  subscription OnDeleteSupplierConsumerProduct {
    onDeleteSupplierConsumerProduct {
      id
      productId
      supplierId
      consumerId
      unit
      price
      percentageDiscount
      amountDiscount
      stock
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWindow = /* GraphQL */ `
  subscription OnCreateWindow {
    onCreateWindow {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      messages {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWindow = /* GraphQL */ `
  subscription OnUpdateWindow {
    onUpdateWindow {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      messages {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWindow = /* GraphQL */ `
  subscription OnDeleteWindow {
    onDeleteWindow {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      messages {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLabel = /* GraphQL */ `
  subscription OnCreateLabel {
    onCreateLabel {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLabel = /* GraphQL */ `
  subscription OnUpdateLabel {
    onUpdateLabel {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLabel = /* GraphQL */ `
  subscription OnDeleteLabel {
    onDeleteLabel {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSecurity = /* GraphQL */ `
  subscription OnCreateSecurity {
    onCreateSecurity {
      id
      securityId
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      securityXSM
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSecurity = /* GraphQL */ `
  subscription OnUpdateSecurity {
    onUpdateSecurity {
      id
      securityId
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      securityXSM
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSecurity = /* GraphQL */ `
  subscription OnDeleteSecurity {
    onDeleteSecurity {
      id
      securityId
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      securityXSM
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSetup = /* GraphQL */ `
  subscription OnCreateSetup {
    onCreateSetup {
      id
      setupId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSetup = /* GraphQL */ `
  subscription OnUpdateSetup {
    onUpdateSetup {
      id
      setupId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSetup = /* GraphQL */ `
  subscription OnDeleteSetup {
    onDeleteSetup {
      id
      setupId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSupplierSetup = /* GraphQL */ `
  subscription OnCreateSupplierSetup {
    onCreateSupplierSetup {
      id
      supplierId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupplierSetup = /* GraphQL */ `
  subscription OnUpdateSupplierSetup {
    onUpdateSupplierSetup {
      id
      supplierId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSupplierSetup = /* GraphQL */ `
  subscription OnDeleteSupplierSetup {
    onDeleteSupplierSetup {
      id
      supplierId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConsumerSetup = /* GraphQL */ `
  subscription OnCreateConsumerSetup {
    onCreateConsumerSetup {
      id
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConsumerSetup = /* GraphQL */ `
  subscription OnUpdateConsumerSetup {
    onUpdateConsumerSetup {
      id
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConsumerSetup = /* GraphQL */ `
  subscription OnDeleteConsumerSetup {
    onDeleteConsumerSetup {
      id
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSupplierConsumerSetup = /* GraphQL */ `
  subscription OnCreateSupplierConsumerSetup {
    onCreateSupplierConsumerSetup {
      id
      supplierId
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupplierConsumerSetup = /* GraphQL */ `
  subscription OnUpdateSupplierConsumerSetup {
    onUpdateSupplierConsumerSetup {
      id
      supplierId
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSupplierConsumerSetup = /* GraphQL */ `
  subscription OnDeleteSupplierConsumerSetup {
    onDeleteSupplierConsumerSetup {
      id
      supplierId
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAudit = /* GraphQL */ `
  subscription OnCreateAudit {
    onCreateAudit {
      id
      date
      type
      code
      message
      systemMessage
      application
      process
      format
      username
      url
      ipAddress
      appVersion
      errorNumber
      duration
      supplierId
      consumerId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAudit = /* GraphQL */ `
  subscription OnUpdateAudit {
    onUpdateAudit {
      id
      date
      type
      code
      message
      systemMessage
      application
      process
      format
      username
      url
      ipAddress
      appVersion
      errorNumber
      duration
      supplierId
      consumerId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAudit = /* GraphQL */ `
  subscription OnDeleteAudit {
    onDeleteAudit {
      id
      date
      type
      code
      message
      systemMessage
      application
      process
      format
      username
      url
      ipAddress
      appVersion
      errorNumber
      duration
      supplierId
      consumerId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCrew = /* GraphQL */ `
  subscription OnCreateCrew {
    onCreateCrew {
      id
      crewId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCrew = /* GraphQL */ `
  subscription OnUpdateCrew {
    onUpdateCrew {
      id
      crewId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCrew = /* GraphQL */ `
  subscription OnDeleteCrew {
    onDeleteCrew {
      id
      crewId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCrewSecurity = /* GraphQL */ `
  subscription OnCreateCrewSecurity {
    onCreateCrewSecurity {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      securityId
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCrewSecurity = /* GraphQL */ `
  subscription OnUpdateCrewSecurity {
    onUpdateCrewSecurity {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      securityId
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCrewSecurity = /* GraphQL */ `
  subscription OnDeleteCrewSecurity {
    onDeleteCrewSecurity {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      securityId
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCrewUser = /* GraphQL */ `
  subscription OnCreateCrewUser {
    onCreateCrewUser {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCrewUser = /* GraphQL */ `
  subscription OnUpdateCrewUser {
    onUpdateCrewUser {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCrewUser = /* GraphQL */ `
  subscription OnDeleteCrewUser {
    onDeleteCrewUser {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      supplierId
      consumerId
      consumerIdERP
      orderCodeERP
      date
      dateERP
      deliveryOn
      deliveryExpectedOn
      requestComment
      orderComments
      shipping
      status
      statusERP
      historyStatusERP {
        datetimeERP
        statusERP
      }
      grossAmount
      discountAmount
      taxAmount
      netAmount
      orderProducts {
        items {
          id
          orderId
          productId
          unitId
          price
          quantity
          grossAmount
          discountAmount
          taxAmount
          netAmount
          shortnameLanguage1
          shortnameLanguage2
          shortnameLanguage3
          shortnameLanguage4
          imageInternalName
          imageExternalURL
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencyISOErpRequest
      currencyISOErp
      currencySymbol
      exchangeRate
      posted
      postedOn
      itemCount
      paymentCondition
      taxes {
        name
        amount
      }
      discounts {
        name
        amount
      }
      paymentMethod {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      shippingAddress {
        key
        value
        default
      }
      group1
      group2
      group3
      consumerName
      sameOrderClass
      dateTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      supplierId
      consumerId
      consumerIdERP
      orderCodeERP
      date
      dateERP
      deliveryOn
      deliveryExpectedOn
      requestComment
      orderComments
      shipping
      status
      statusERP
      historyStatusERP {
        datetimeERP
        statusERP
      }
      grossAmount
      discountAmount
      taxAmount
      netAmount
      orderProducts {
        items {
          id
          orderId
          productId
          unitId
          price
          quantity
          grossAmount
          discountAmount
          taxAmount
          netAmount
          shortnameLanguage1
          shortnameLanguage2
          shortnameLanguage3
          shortnameLanguage4
          imageInternalName
          imageExternalURL
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencyISOErpRequest
      currencyISOErp
      currencySymbol
      exchangeRate
      posted
      postedOn
      itemCount
      paymentCondition
      taxes {
        name
        amount
      }
      discounts {
        name
        amount
      }
      paymentMethod {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      shippingAddress {
        key
        value
        default
      }
      group1
      group2
      group3
      consumerName
      sameOrderClass
      dateTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      supplierId
      consumerId
      consumerIdERP
      orderCodeERP
      date
      dateERP
      deliveryOn
      deliveryExpectedOn
      requestComment
      orderComments
      shipping
      status
      statusERP
      historyStatusERP {
        datetimeERP
        statusERP
      }
      grossAmount
      discountAmount
      taxAmount
      netAmount
      orderProducts {
        items {
          id
          orderId
          productId
          unitId
          price
          quantity
          grossAmount
          discountAmount
          taxAmount
          netAmount
          shortnameLanguage1
          shortnameLanguage2
          shortnameLanguage3
          shortnameLanguage4
          imageInternalName
          imageExternalURL
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencyISOErpRequest
      currencyISOErp
      currencySymbol
      exchangeRate
      posted
      postedOn
      itemCount
      paymentCondition
      taxes {
        name
        amount
      }
      discounts {
        name
        amount
      }
      paymentMethod {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      shippingAddress {
        key
        value
        default
      }
      group1
      group2
      group3
      consumerName
      sameOrderClass
      dateTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrderProduct = /* GraphQL */ `
  subscription OnCreateOrderProduct {
    onCreateOrderProduct {
      id
      orderId
      productId
      unitId
      price
      quantity
      grossAmount
      discountAmount
      taxAmount
      netAmount
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrderProduct = /* GraphQL */ `
  subscription OnUpdateOrderProduct {
    onUpdateOrderProduct {
      id
      orderId
      productId
      unitId
      price
      quantity
      grossAmount
      discountAmount
      taxAmount
      netAmount
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrderProduct = /* GraphQL */ `
  subscription OnDeleteOrderProduct {
    onDeleteOrderProduct {
      id
      orderId
      productId
      unitId
      price
      quantity
      grossAmount
      discountAmount
      taxAmount
      netAmount
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSyncLog = /* GraphQL */ `
  subscription OnCreateSyncLog {
    onCreateSyncLog {
      id
      supplierId
      consumerId
      date
      dateStart
      dateEnd
      syncId
      process
      typeProcess
      status
      isOk
      isWarning
      message
      errors
      systemMessage
      sourceCount
      inserted
      updated
      noupdated
      errored
      errorNumber
      totalDuration
      extractDuration
      transformDuration
      loadDuration
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSyncLog = /* GraphQL */ `
  subscription OnUpdateSyncLog {
    onUpdateSyncLog {
      id
      supplierId
      consumerId
      date
      dateStart
      dateEnd
      syncId
      process
      typeProcess
      status
      isOk
      isWarning
      message
      errors
      systemMessage
      sourceCount
      inserted
      updated
      noupdated
      errored
      errorNumber
      totalDuration
      extractDuration
      transformDuration
      loadDuration
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSyncLog = /* GraphQL */ `
  subscription OnDeleteSyncLog {
    onDeleteSyncLog {
      id
      supplierId
      consumerId
      date
      dateStart
      dateEnd
      syncId
      process
      typeProcess
      status
      isOk
      isWarning
      message
      errors
      systemMessage
      sourceCount
      inserted
      updated
      noupdated
      errored
      errorNumber
      totalDuration
      extractDuration
      transformDuration
      loadDuration
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStatement = /* GraphQL */ `
  subscription OnCreateStatement {
    onCreateStatement {
      id
      year
      month
      paddedMonth
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      contractDetail {
        since
        plan
        fees {
          deposit
          payment
          order
        }
        minTransaction {
          deposit
          payment
          order
        }
        maxTransaction
      }
      count {
        deposit
        payment
        order
      }
      amount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      avgItem {
        deposit
        payment
        order
      }
      avgAmount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      itemCount {
        deposit
        payment
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStatement = /* GraphQL */ `
  subscription OnUpdateStatement {
    onUpdateStatement {
      id
      year
      month
      paddedMonth
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      contractDetail {
        since
        plan
        fees {
          deposit
          payment
          order
        }
        minTransaction {
          deposit
          payment
          order
        }
        maxTransaction
      }
      count {
        deposit
        payment
        order
      }
      amount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      avgItem {
        deposit
        payment
        order
      }
      avgAmount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      itemCount {
        deposit
        payment
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStatement = /* GraphQL */ `
  subscription OnDeleteStatement {
    onDeleteStatement {
      id
      year
      month
      paddedMonth
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      contractDetail {
        since
        plan
        fees {
          deposit
          payment
          order
        }
        minTransaction {
          deposit
          payment
          order
        }
        maxTransaction
      }
      count {
        deposit
        payment
        order
      }
      amount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      avgItem {
        deposit
        payment
        order
      }
      avgAmount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      itemCount {
        deposit
        payment
        order
      }
      createdAt
      updatedAt
    }
  }
`;
