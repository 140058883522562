import React, { useState } from "react"
import {
    TextArea,
    InputText,
    InputPass,
    SelectCombo,
    SectionEditable,
    SeparateTop,
} from "xsales-components"

import { I18n } from "@aws-amplify/core"

export default function EndpointConfiguration(props) {

    const listAuthType = [];

    const CancelSupplierEndpoint = (e) => {
        props.CancelSupplierEndpoint(e)
        setUpdateActionSupplierEndpoint(false)
        props.RefreshData()
    }

    const ChangeSupplierEndpoint = (e) => {
        props.ChangeSupplierEndpoint(e)

        setUpdateActionSupplierEndpoint(false)
    }

    const UpdateActionButtonSupplierEndpoint = () => {
        setUpdateActionSupplierEndpoint(!UpdateActionSupplierEndpoint)
    }

    const [
        UpdateActionSupplierEndpoint,
        setUpdateActionSupplierEndpoint,
    ] = useState(false)

    const authType = listAuthType

    function getAuthType(value) {
        if (listAuthType.length===0){setListAuthType();}
        if (value !== undefined && value !== "" && value !== null) {
            const item = listAuthType.find((o) => o.value === value)
            if (item) {
                return item.value
            } else {
                return getAuthType("0")
            }
        }

    }
    function  setListAuthType() {
        listAuthType.push({ label: I18n.get("lblAuthType_NoAuth"), value: "0" });
        listAuthType.push({ label: I18n.get("lblAuthType_ApiKey"), value: "1" });
        listAuthType.push({ label: I18n.get("lblAuthType_Basic"), value: "2" });
    }

    return (
        <SeparateTop>
            <div className="row">
                <div className="col-sm-6">
                    <div className="divTitle">{props.Data.businessName}</div>
                </div>

                <div className="col-12">
                    <SectionEditable
                        label={I18n.get("lblConfigERPEndpoint")}
                        labelEdit={I18n.get("lblConfigERPEndpoint")}
                        Action={"UPDATE"}
                        UpdateAction={UpdateActionButtonSupplierEndpoint}
                        CancelAction={CancelSupplierEndpoint}
                        SaveAction={ChangeSupplierEndpoint}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="col-12">
                            <TextArea
                                rows={"2"}
                                id="endpointSupplierApi"
                                name="endpointSupplierApi"
                                maxLength="300"
                                label={I18n.get("lblendpointSupplierApi")}
                                value={props.Data.endpointSupplierApi}
                                onChange={props.handleChange}
                                isEditable={UpdateActionSupplierEndpoint}
                            />
                        </div>

                        <div className="col-12">
                            <SelectCombo
                                id="authType"
                                name="authType"
                                label={I18n.get("lblendpointAuthType")}
                                options={authType}
                                value={getAuthType(
                                    props.Data.endpointAuthType
                                )}
                                onChange={props.handleChangeEndpointAuthType}
                                isEditable={UpdateActionSupplierEndpoint}
                            />
                        </div>

                        {props.Data.endpointAuthType === "1" && (
                            <div className="col-12 mt-4">
                                <TextArea
                                    rows={"1"}
                                    id="endpointSupplierApiKey"
                                    name="endpointSupplierApiKey"
                                    maxLength="300"
                                    label={I18n.get(
                                        "lblendpointSupplierApiKey"
                                    )}
                                    value={props.Data.endpointSupplierApiKey}
                                    onChange={props.handleChange}
                                    isEditable={UpdateActionSupplierEndpoint}
                                />
                            </div>
                        )}

                        {props.Data.endpointAuthType === "2" && (
                            <div>
                                <div className="col-12  mt-4">
                                    <InputText
                                        id="endpointAuthBasicUser"
                                        name="endpointAuthBasicUser"
                                        maxLength="32"
                                        label={I18n.get(
                                            "lblendpointAuthBasicUser"
                                        )}
                                        value={props.Data.endpointAuthBasicUser}
                                        onChange={props.handleChange}
                                        isEditable={
                                            UpdateActionSupplierEndpoint
                                        }
                                    />
                                </div>
                                <div className="col-12  mt-4">
                                    <InputPass
                                        id="endpointAuthBasicPass"
                                        name="endpointAuthBasicPass"
                                        maxLength="128"
                                        label={I18n.get(
                                            "lblendpointAuthBasicPass"
                                        )}
                                        value={props.Data.endpointAuthBasicPass}
                                        onChange={props.handleChange}
                                        isEditable={
                                            UpdateActionSupplierEndpoint
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {/* <div className="col-12">
                            <TextArea
                                rows={"3"}
                                id="endpointSupplierApiSectretKey"
                                name="endpointSupplierApiSectretKey"
                                maxLength="300"
                                label={I18n.get(
                                    "lblendpointSupplierApiSectretKey"
                                )}
                                value={props.Data.endpointSupplierApiSectretKey}
                                onChange={props.handleChange}
                                isEditable={UpdateActionSupplierEndpoint}
                            />
                        </div> */}
                    </SectionEditable>
                </div>
            </div>
        </SeparateTop>
    )
}