import React, { useState, createContext, useEffect } from "react";
import { I18n } from "aws-amplify"
// Create Context Object
export const MyAppContext = createContext({
    language: "EN"
});

// Create a provider for components to consume and subscribe to changes
export const ContextProvider = ({ children }) => {
    const [language, setLanguage] = useState("EN");

    useEffect(() => {
        setLanguageApp()
    }, [])

    async function setLanguageApp() {
        try {
            let lang = localStorage.getItem("languageApp")
            if (!lang) {
                const [lang1] = navigator.language.split('-')
                lang = lang1.toUpperCase()
            }

            setLanguage(lang)
            I18n.setLanguage(lang.toUpperCase())
        } catch (error) {
            setLanguage('EN')
            I18n.setLanguage('EN')
            console.log(error.message)
        }
    }
    return (
        <MyAppContext.Provider value={{ language: language, setLanguage, setLanguageApp }}>
            {children}
        </MyAppContext.Provider>
    );
};
