import { I18n } from "aws-amplify"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { XSalesConsumer } from "../../../Utils/Utils"
// import { ImageUrlTypes } from '../../../DataQuery/XSalesData'

// const mock = [
//     {
//         productId: "0001",
//         image: "https://cdn.xsalesconsumer.com/suppliers/850bce29-0a1a-4ff9-8075-94f32bb99c83/logos/xsm-logotipo-color-web-es.png",
//         name: "SODA 250 ML",
//         unitId: "ea",
//         quantity: 55,
//         price: 1.6,
//         total: 100
//     },
//     {
//         productId: "0002",
//         image: "https://cdn.xsalesconsumer.com/suppliers/850bce29-0a1a-4ff9-8075-94f32bb99c83/logos/xsm-logotipo-color-web-es.png",
//         name: "SODA 250 ML",
//         unitId: "ea",
//         quantity: 55,
//         price: 1.6,
//         total: 100
//     },
//     {
//         productId: "0003",
//         image: "https://cdn.xsalesconsumer.com/suppliers/850bce29-0a1a-4ff9-8075-94f32bb99c83/logos/xsm-logotipo-color-web-es.png",
//         name: "SODA 250 ML",
//         unitId: "ea",
//         quantity: 55,
//         price: 1.6,
//         total: 100
//     }
// ]
export function TableProductDetail(props) {
    const [tableData, setTableData] = useState([])
    // const [hideImage, setHideImage] = useState(false)
    const [totals, setTotals] = useState({
        totalGrossAmount: 0,
        totalDiscountAmount: 0,
        subTotal: 0,
        taxes: 0,
        total: 0,
    })

    useEffect(() => {
        buildData(props.data)
    }, [props])

    function buildData(datatable) {
        if (!datatable) return

        // const { supplier } = XSalesConsumer.getCurrentUserNew()
        const totalGrossAmount = datatable.grossAmount
        const totalDiscountAmount = datatable.discountAmount
        const totalSubTotal = totalGrossAmount + totalDiscountAmount
        const taxesTotal = datatable.taxAmount
        // datatable.taxes.forEach(item => {
        //     taxesTotal += item.amount
        // })

        const total = totalSubTotal + taxesTotal
        setTotals((prevState) => ({
            ...prevState,
            totalGrossAmount: parseFloat(Number(totalGrossAmount).toFixed(2)),
            totalDiscountAmount: parseFloat(Number(totalDiscountAmount).toFixed(2)),
            subTotal: parseFloat(Number(totalSubTotal).toFixed(2)),
            taxes: parseFloat(Number(taxesTotal).toFixed(2)),
            total,
        }))

        setTableData(datatable.orderProducts)
    }

    return (
        <Container>
            {
                <div className="">
                    <Table className="table table-condensed table-hover table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{I18n.get("lblOrdMaCode")}</th>
                                {!props.hideImage && <th scope="col">{I18n.get("lblOrdMaImage")}</th>}

                                <th scope="col">{I18n.get("lblOrdMaName")}</th>
                                <th scope="col">{I18n.get("lblOrdMaUnit")}</th>
                                <th scope="col">{I18n.get("lblOrdMaQty")}</th>
                                <th scope="col">{I18n.get("lblOrdMaPrice")}</th>
                                <th scope="col">{I18n.get("lblOrdMaTotal")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row) => (
                                <tr style={{ lineHeight: "20px" }}>
                                    <td>{row.productId}</td>
                                    {!props.hideImage && (
                                        <td>
                                            <Image src={row.image} loading="lazy" alt="not Found" />
                                        </td>
                                    )}
                                    <td>{row.name}</td>
                                    <td>{row.unitId}</td>
                                    <td>{row.quantity}</td>
                                    <td>{row.price}</td>
                                    <td>{row.total}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <ContainerTotal>
                        <WrapperTotal>
                            <Divisor />
                            <div className="row p-0 m-0">
                                <div className="col-sm-12 col-md-6 p-0 m-0">{I18n.get("lblOrdMaGrossAmount")}</div>
                                <div className="col-sm-12 col-md-6 p-0 pr-2 m-0 text-right">{totals.totalGrossAmount}</div>
                            </div>

                            <div className="row p-0 m-0">
                                <div className="col-sm-12 col-md-6 p-0 m-0">{I18n.get("lblOrdMaDiscountAmount")}</div>
                                <div className="col-sm-12 col-md-6 p-0 pr-2 m-0 text-right">{totals.totalDiscountAmount}</div>
                            </div>
                            <Divisor />
                            <div className="row p-0 m-0">
                                <div className="col-sm-12 col-md-6 p-0 m-0">
                                    <TotalBold> {I18n.get("lblOrdMaSubTotal")}</TotalBold>
                                </div>
                                <div className="col-sm-12 col-md-6 p-0 pr-2 m-0 text-right">{totals.subTotal}</div>
                            </div>

                            <div className="row p-0 m-0">
                                <div className="col-sm-12 col-md-6 p-0 m-0">{I18n.get("lblOrdMaSubTaxes")}</div>
                                <div className="col-sm-12 col-md-6 p-0 pr-2 m-0 text-right">{totals.taxes}</div>
                            </div>
                            <Divisor />
                            <div className="row p-0 m-0">
                                <div className="col-sm-12 col-md-6 p-0 m-0">
                                    <TotalBold> {I18n.get("lblOrdMaTotal")}</TotalBold>
                                </div>
                                <div className="col-sm-12 col-md-6 p-0 pr-2 m-0 text-right">
                                    <TotalAmount>{XSalesConsumer.formatterCurrency(totals.total, props?.data?.currencyISOErp)}</TotalAmount>
                                </div>
                            </div>
                        </WrapperTotal>
                    </ContainerTotal>
                </div>
            }
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    overflow-y: auto;
    display: block;
`

const Table = styled.table`
    width: 100%;
    border: 2px solid #dddddd;
    border-right-width: 0px;
    border-left-width: 0px;
    && thead > tr {
        border-bottom: 2px solid #dddddd;
    }
    && tbody > tr {
        border-bottom: 1px solid #dddddd;
    }
    && tr > td {
        vertical-align: middle;
    }
    && tr > td > img {
        width: 115px;
        height: 50px;
        object-fit: contain;
        vertical-align: center;
    }
    /* height: 40vh; */
`

const ContainerTotal = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`

const WrapperTotal = styled.div`
    padding: 10px;
    width: 40%;
    flex-direction: column;
    justify-content: end;
    /* align-items: flex-end; */
`
const Divisor = styled.hr`
    margin: 0;
    padding: 0;
`
const TotalBold = styled.p`
    font-weight: bold;
    color: #66686c;
    margin: 0;
    padding: 0;
`

const TotalAmount = styled.p`
    margin: 0;
    padding: 0;
    color: #5e32b2;
    font-weight: bold;
    font-size: 18px;
`

const Image = styled.img`
    width: 40px;
    height: 40px;
    object-fit: contain;
`
