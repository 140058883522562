import React, { useEffect, useRef, useState } from 'react'
import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper';

import exportFromJSON from 'export-from-json'
import templates from './templates.json'
import { Button, TabulatorTable, AlertMessage, Loader, TextArea } from 'xsales-components'
import { API, I18n } from 'aws-amplify';
import { XSalesConsumer } from '../../Utils/Utils';
import * as xlsx from 'xlsx';
// import Tabulator from "tabulator-tables"
import papaparse from 'papaparse'
import { ImageUrlTypes } from '../../DataQuery/XSalesData';
import * as XLSX from 'xlsx';

let stepper = null;
let tabulatorRef = null
let msgErr= "";
let msgErrVisible= false;

function getFileName(name) {
    //let timeSpan = new Date().getTime().toString();
    let sheetName = name || "ExportResult";
    let fileName = `${sheetName}`;
    return {
        sheetName,
        fileName
    };
};

function exportToExcel(dataSource, name, wscols) {
    let { sheetName, fileName } = getFileName(name);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataSource);
    if (wscols) {
        ws['!cols'] = wscols;
    }
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    /* generate buffer */
    XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export function WizardImport({ setStepperRef, table }) {
    const [tabulatorProps, setTabulatorProps] = useState({
        filter: [],
        data: [],
        languages: [],
        columns: []
    });
    const [loader, setLoader] = useState(false)
    const [type, setType] = useState("")
    const [savedData, setSavedData] = useState(false)
    const [filePath, setFilePath] = useState(null);
    const refInputFile = useRef(null)
    function parserExcel(e) {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
               //console.log(json);
                setTabulatorProps(prevState => ({
                    ...prevState,
                    data: json
                }))
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    function parserCsv(e) {
        e.preventDefault();
        if (e.target.files) {
            papaparse.parse(e.target.files[0], {
                header: true,
                dynamicTyping: true,
                complete: (results) => {
                    //console.log(results.data);
                    setTabulatorProps(prevState => ({
                        ...prevState,
                        data: results.data
                    }))
                }
            });
        }
    }

    async function callApiUploadData(supplierId, type, data) {
        msgErr="";
        try {
            /**
            * type categories: 1
            * type subcategories: 2
            * type products: 3
            * type logos: 4
            * type banners: 5 
            */

            let validation = ValidateData(type, data);

            if (validation===true){
                const result = await API.post(
                    "backendapi",
                    "/files/upload",
                    {
                        body: {
                            supplierId,
                            type,
                            data
                        },
                    }
                )
                return result;
            }else{
                return null;
            }
            
        } catch (error) {
            console.error(error)
            return null
        }
    }

    function ValidateData(type, data){
         /**
        * type categories: 1
        * type subcategories: 2
        * type products: 3
        * type logos: 4
        * type banners: 5 
        */

        try{
            let error= "";
            if (type===3){
                
                    data.map(function(item, i){
                    var RE = /^\d*\.?\d*$/;
                    
                    if (!RE.test(item.price)) {
                        error= error + `Field: Price  Invalid value: ${item.price}  Line: ${(i+2)}  Format: 00.0 \n`;
                    }
                return null
                
                })
            }
            if (error){
                msgErr= error;
                msgErrVisible= true;
                return false;
            }else{
                msgErrVisible= false;
                return true
            }
        } catch (error) {
            console.error(error)
            return false
        }
    }

    function handleChangeFile(e) {
        if (e.target.files) {
            const { name } = e.target.files[0];
            setFilePath(name);
            const [, ext] = name.split('.');
            if (ext === "xls" || ext === "xlsx") {
                parserExcel(e);
            } else if (ext === "csv") {
                parserCsv(e);
            }
        }
    }

    useEffect(() => {
        stepper = new Stepper(document.querySelector('#stepper1'), {
            linear: false,
            animation: true
        })

        if (setStepperRef) {
            setStepperRef(stepper)
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        setTabulatorProps(prevState => ({
            ...prevState,
            columns: buildColumns(templates[table]),
            languages: getLanguageTabulator()
        }))

        switch (table) {
            case "category":
                setType(ImageUrlTypes.Types.categories)
                break;
            case "product":
                setType(ImageUrlTypes.Types.products)
                break;
            case "subcategory":
                setType(ImageUrlTypes.Types.subcategories)
                break;
            default:
                break;
        }
    }, [table])

    function handleDownloadTemplate(type) {
        switch (type) {
            case exportFromJSON.types.xls:
                exportToExcel(templates[table], table) 
                break;
            case exportFromJSON.types.csv:
                downloadTemplate({ data: templates[table], fileName: table, exportType: type })
                break;
            default:
                break;
        }
      
    }

    function downloadTemplate({ data, fileName, exportType }) {
        exportFromJSON({ data: data, fileName: fileName, exportType: exportType })
    }

    function buildColumns(data) {
        const columns = []
        if (columns){
            if (data){
                if (data.length > 0) {
                    Object.keys(data[0]).forEach(key => {
                        columns.push({
                            title: key,
                            field: key,
                            editor: "input"
                        })
                    });
                }
            }
        }
        return columns;
    }


    function getLanguageTabulator() {
        const languages = XSalesConsumer.getLanguagesSupplierPreferences();
        return languages.split(',')
    }

    function nextStepTo3() {
        if (tabulatorProps.data!==null && tabulatorProps.data!==undefined){
            if (tabulatorProps.data.length > 0) {
                stepper.next();
                setTimeout(() => {
                    tabulatorRef.redraw(true);
                }, 300);
            }
        }
    }

    async function saveData(e) {
        setLoader(true)
        try {
            const { supplier } = XSalesConsumer.getCurrentUserNew();
            if (supplier){
                const result = await callApiUploadData(supplier.id, type, tabulatorRef.getData())
                setSavedData(result ? true : false)
            }
        } catch (error) {
            console.log(error)
        }
        setLoader(false)
    }


    function resetValues() {
        setFilePath(null);
        refInputFile.current.value = null;
        setTabulatorProps(prevState => ({
            ...prevState,
            data: []
        }));
        setSavedData(false);
    }


    return (
        <div>
            <div id="stepper1" className="bs-stepper">
                <div className="bs-stepper-header">
                    <div className="step" data-target="#test-l-1">
                        <button className="step-trigger">
                            <span className="bs-stepper-circle">1</span>
                            <span className="bs-stepper-label">{I18n.get('lbldownloadtemplatewizard')}</span>
                        </button>
                    </div>
                    <div className="line"></div>
                    <div className="step" data-target="#test-l-2">
                        <button className="step-trigger">
                            <span className="bs-stepper-circle">2</span>
                            <span className="bs-stepper-label">{I18n.get('lbluploadfilewizard')}</span>
                        </button>
                    </div>
                    <div className="line"></div>
                    <div className="step" data-target="#test-l-3">
                        <button className="step-trigger">
                            <span className="bs-stepper-circle">3</span>
                            <span className="bs-stepper-label">{I18n.get('lblpreviewwizard')}</span>
                        </button>
                    </div>
                    <div className="line"></div>
                    <div className="step" data-target="#test-l-4">
                        <button className="step-trigger">
                            <span className="bs-stepper-circle">4</span>
                            <span className="bs-stepper-label">{I18n.get('lblsavedatawizard')}</span>
                        </button>
                    </div>
                </div>
                <div className="bs-stepper-content">

                    <div id="test-l-1" className="content">
                        <div className='mb-2'>
                            <Button
                                id="btn-download-xls"
                                label={I18n.get("lbldownloadxlswizard")}
                                name="downloadTemplate"
                                type="button"
                                className="mb-2"
                                outline
                                size='lg'
                                color='blue'
                                onClick={() => handleDownloadTemplate(exportFromJSON.types.xls)}
                            />
                            <Button
                                id="btn-download-csv"
                                label={I18n.get("lbldownloadcsvwizard")}
                                name="downloadTemplatecsv"
                                type="button"
                                className="ml-3 mb-2"
                                outline
                                size='lg'
                                color='blue'
                                onClick={() => handleDownloadTemplate(exportFromJSON.types.csv)}
                            />

                        </div>
                        <Button
                            label="Next"
                            name="downloadTemplate"
                            size='md'
                            color='primary'
                            onClick={() => {
                                resetValues();
                                stepper.next();
                            }}
                        />
                    </div>
                    <div id="test-l-2" className="content">
                        <div className='row'>
                            <div className='col sm-12'>
                                <div className="form-group">
                                    <label htmlFor="input-file-wizard">{I18n.get("lbluploadfilewizard")}</label>
                                    <div className="input-group">
                                        <div className="custom-file">
                                            <input type="file"
                                                accept=".csv,.xlsx,.xls"
                                                onChange={handleChangeFile}
                                                ref={refInputFile}
                                                className="custom-file-input"
                                                id="input-file-wizard" />
                                            <label className="custom-file-label" htmlFor="input-file-wizard">{filePath}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            label={I18n.get("lblbtnbackwizard")}
                            name="downloadTemplate"
                            size='md'
                            className='mr-2'
                            color='primary'
                            onClick={() => stepper.previous()}
                        />
                        <Button
                            label={I18n.get("lblbtnnextwizard")}
                            name="downloadTemplate"
                            size='md'
                            color='primary'
                            onClick={nextStepTo3}
                        />
                    </div>
                    <div id="test-l-3" className="content">
                        <div className="col-sm-12">
                            <TabulatorTable
                                id="previewImport"
                                Data={tabulatorProps.data}
                                Columns={tabulatorProps.columns}
                                Filter={tabulatorProps.filter}
                                placeholder={I18n.get("lblLoadingData")}
                                Search={true}
                                labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                                columnsIcon={false}
                                languages={tabulatorProps.languages}
                                RowClick={(e) => { }}
                                paginationSize={10}
                                Height='400px'
                                setRefTable={(ref) => tabulatorRef = ref}
                                lblSearch= {I18n.get("lblSearch")}
                            />
                        </div>
                        <Button
                            label={I18n.get("lblbtnbackwizard")}
                            name="backPreview"
                            size='md'
                            className='mr-2'
                            color='primary'
                            onClick={() => stepper.previous()}
                        />
                        <Button
                            label={I18n.get("lblbtnnextwizard")}
                            name="nextPreview"
                            size='md'
                            color='primary'
                            onClick={() => stepper.next()}
                        />
                    </div>
                    <div id="test-l-4" className="content">
                        {
                        msgErrVisible ?
                            <div className="col-sm-12">
                                <TextArea
                                    id='msgErr'
                                    value={msgErr}
                                    rows={"10"}
                                    isEditable={false}
                                    style={{ height: '150px', color: 'red'}}
                                />
                            </div>
                            :<div></div>
                        }
                        <div className="col-sm-12 d-flex justify-content-center">
                            {
                                loader ?
                                    <Loader style={{width: "80px", height: "80px"}} />
                                    :
                                    savedData ?
                                        <AlertMessage
                                            show={savedData}
                                            message={I18n.get("msgimportedwizard")}
                                            type='success'
                                            title={I18n.get("lblresultwizard")}
                                        /> :
                                        <Button
                                            id="btn-save-wizard"
                                            label={I18n.get("lblsavedatawizard")}
                                            name="guardar"
                                            type="button"
                                            className=""
                                            outline
                                            size='lg'
                                            color='primary'
                                            onClick={saveData}
                                        />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}