import { XSalesData } from "../../../DataQuery/XSalesData";

export async function getDataOrdersByDate(supplierId, startDate, endDate){
    try {
        return await XSalesData.Order.getOrderBySupplierByDeliveryDate(supplierId, startDate, endDate)
    } catch (error) {
        return []
    }
   
}

export async function getConsumersGroups(supplierId){
    try {
        return await XSalesData.Suppliers.getGroups(supplierId)
    } catch (error) {
        return []
    } 
}