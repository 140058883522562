import { I18n } from "aws-amplify"
import React, { Fragment, useState } from "react"
import { InputText, TextArea } from 'xsales-components' 
// import InputText from "../../componentsConsumer/InputText"
// import TextArea from "../../componentsConsumer/TextArea"
import imgCDN from "./../../configConsumer/imagesDefaultCDN.json"
import * as db from "./../../models"
import { XSalesConsumer } from "../../Utils/Utils"

export default function GeneralInfo(props) {
    if (props.Data === null || props.Data === undefined) {
        throw new Error(
            "You must indicate a [Data] props for GeneralInfo component"
        )
    }
    const allowEdit =
        props.AllowEdit === null || props.AllowEdit === undefined
            ? true
            : props.AllowEdit

    const onError = () => {
        let sfbtemp = sectionFallback
        if (!sfbtemp.errored) {
            sfbtemp.errored = true
            sfbtemp.value = imgCDN.SupplierBannerDefault
            setSectionFallback(sfbtemp)
        }
    }

    const [sectionFallback, setSectionFallback] = useState({
        value: "",
        errored: false,
    })

    return (
        <Fragment>
            <div className="row">
                <div className="col-3">
                    <InputText
                        id="createdAt"
                        name="createdAt"
                        label={I18n.get("lblCreated")}
                        maxLength="15"
                        value={XSalesConsumer.formatShortDatetime(props.Data.createdAt)}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-3">
                    <InputText
                        id="approvedAt"
                        name="approvedAt"
                        label={I18n.get("lblapproved")}
                        maxLength="15"
                        value={XSalesConsumer.formatShortDatetime(props.Data.approvedAt)}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-8">
                    <InputText
                        id="name"
                        name="name"
                        label={I18n.get("lblSupName")}
                        maxLength="30"
                        value={props.Data.name}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-4">
                    <InputText
                        id="taxID"
                        name="taxID"
                        label={I18n.get("lblSupTaxID")}
                        maxLength="30"
                        value={props.Data.taxID}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                {props.Data.address &&
                    props.Data.address.map((item, index) => {
                        const addressMain =
                            item.street1 +
                            ", " +
                            item.street2 +
                            ", " +
                            item.street3 +
                            ". " +
                            item.city +
                            ". " +
                            item.state +
                            ". " +
                            item.country +
                            ". " +
                            item.zipcode
                        return (
                            <div className="col-12">
                                <TextArea
                                    rows={3}
                                    id="address"
                                    name="address"
                                    maxLength="300"
                                    label={I18n.get("lblSupAddress")}
                                    labelDefault={"Address " + (index + 1)}
                                    value={addressMain}
                                    onChange={props.handleChange}
                                    isEditable={allowEdit}
                                />
                            </div>
                        )
                    })}
                <div className="col-12">
                    <div className="row">
                        {props.Data.phones &&
                            props.Data.phones
                                .filter((x) => x.type === db.PhoneType.WORK)
                                .map((item) => {
                                    return (
                                        <div className="col-4">
                                            <InputText
                                                id="workPhone"
                                                name="workPhone"
                                                label={I18n.get(
                                                    "lblofficeNumber"
                                                )}
                                                maxLength="30"
                                                value={item.number}
                                                onChange={props.handleChange}
                                                isEditable={allowEdit}
                                            />
                                        </div>
                                    )
                                })}
                        {props.Data.phones &&
                            props.Data.phones
                                .filter((x) => x.type === db.PhoneType.MOBILE)
                                .map((item) => {
                                    return (
                                        <div className="col-4">
                                            <InputText
                                                id="mobilePhone"
                                                name="mobilePhone"
                                                label={I18n.get(
                                                    "lblmobileNumber"
                                                )}
                                                maxLength="30"
                                                value={item.number}
                                                onChange={props.handleChange}
                                                isEditable={allowEdit}
                                            />
                                        </div>
                                    )
                                })}
                        {props.Data.phones &&
                            props.Data.phones
                                .filter((x) => x.type === db.PhoneType.HOME)
                                .map((item) => {
                                    return (
                                        <div className="col-4">
                                            <InputText
                                                id="homePhone"
                                                name="homePhone"
                                                label={I18n.get(
                                                    "lblhomeNumber"
                                                )}
                                                maxLength="30"
                                                value={item.number}
                                                onChange={props.handleChange}
                                                isEditable={allowEdit}
                                            />
                                        </div>
                                    )
                                })}
                    </div>
                </div>
                <div className="col-6">
                    <InputText
                        id="website"
                        name="website"
                        label={I18n.get("lblwebsite")}
                        maxLength="20"
                        value={props.Data.website}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-6">
                    <InputText
                        id="email"
                        name="email"
                        label={I18n.get("lblEmail")}
                        maxLength="20"
                        value={props.Data.email}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-12">
                    <TextArea
                        id="summary"
                        name="summary"
                        label={I18n.get("lblSummary")}
                        maxLength="250"
                        value={props.Data.summary}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>

                <div className="col-sm-6">
                    <div className="col-12">
                        <h5>{I18n.get("lblContactSalesPerson")}</h5>
                        <hr />
                    </div>
                    <div className="col-12">
                        <InputText
                            id="contactName"
                            name="contactName"
                            maxLength="20"
                            label={I18n.get("lblcontactName")}
                            value={props.Data.contactName}
                            onChange={props.handleChange}
                            isEditable={allowEdit}
                        />
                    </div>
                    <div className="col-12">
                        <InputText
                            id="contactEmail"
                            name="contactEmail"
                            maxLength="60"
                            label={I18n.get("lblcontactEmail")}
                            value={props.Data.contactEmail}
                            onChange={props.handleChange}
                            isEditable={allowEdit}
                        />
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="col-12">
                        <h5>{I18n.get("lblCompanyBanner")}</h5>
                        <hr />
                    </div>
                    <div className="col-12">
                        <div className="logo-picture">
                            <img
                                onError={onError}
                                src={sectionFallback.value}
                                className="picture-image-logo"
                                alt="Banner URL"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
