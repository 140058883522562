import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { defaultFilters } from './commons'
import { RadioButton, InputTag, DatePicker, Switch } from 'xsales-components'
import { XSalesData } from '../../../DataQuery/XSalesData'
import { XSalesConsumer } from '../../../Utils/Utils'
import { DownloadHtmlToPdf } from './DownloadHtmlToPdf'
import { I18n } from 'aws-amplify'

export function Filters({ handleChangeFilters, pdfRef, handleBeforeGetContent, handleAfterPrint, hidePdf }) {
    const [showRange, setShowRange] = useState(false)
    const [dataFilters, setDataFilters] = useState(null)
    const [hideControls, setHideControls] = useState(false)
    const [hideImage, setHideImage] = useState(false)


    async function getDataCombos(dataFilters) {
        if (!dataFilters?.groups) return
        const { supplier } = XSalesConsumer.getCurrentUserNew()
        return await XSalesData.Suppliers.getGroups(supplier.id)
    }

    function handleChangeRadioButton(property, e) {
        const element = e.target
        const idx = dataFilters[property].findIndex(x => x.Key === element.id)
        let newData = dataFilters[property]
        if (element.checked) {
            newData = dataFilters[property].map(x => ({
                ...x,
                value: false
            }))
        }
        let showRange
        if (newData[idx].Key === "range" && element.checked) {
            showRange = true
        } else {
            showRange = false
        }
        setShowRange(showRange)
        newData[idx].value = element.checked
        setDataFilters(prevState => ({
            ...prevState,
            showRange: showRange,
            [property]: [...newData]
        }))
    }


    function handleChangeDate(name, date) {
        setDataFilters(prevState => ({
            ...prevState,
            [name]: date.toDate().toISOString()
        }))
    }

    function handleClickResize(e) {
        setHideControls(!hideControls)
    }

    useEffect(() => {
        setFilterFromSession()
        // getDataCombos()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (handleChangeFilters) handleChangeFilters(dataFilters)

        // getDataCombos()
        saveFilterIntoSession(dataFilters)
        showSection(dataFilters)
    }, [dataFilters])// eslint-disable-line react-hooks/exhaustive-deps


    function showSection(dataFilters) {
        if (dataFilters) {
            const [element] = dataFilters?.itemsRadioDeliveryDate.filter(x => x.Key === "range")
            if (element?.value) {
                setShowRange(element.value)
            }
        }
    }

    async function setFilterFromSession() {
        let filters = window.sessionStorage.getItem("filtersOrderRpt")
        filters = filters === 'null' || filters === null ? defaultFilters : JSON.parse(filters)
        const { groups1, groups2, groups3 } = await getDataCombos(filters)
        filters.groups = {
            group1: {
                values: "",
                options: groups1?.map(x => ({ value: x, label: x }))
            },
            group2: {
                values: "",
                options: groups2?.map(x => ({ value: x, label: x }))
            },
            group3: {
                values: "",
                options: groups3?.map(x => ({ value: x, label: x }))
            },
        }
        setHideImage(filters?.hideImage)
        setDataFilters(filters)
    }

    function saveFilterIntoSession(filters) {
        window.sessionStorage.setItem("filtersOrderRpt", JSON.stringify(filters))
    }

    function handleChangeCombo(name, value) {
        setDataFilters(prevState => ({
            ...prevState,
            groups: {
                ...prevState.groups,
                [name]: {
                    ...prevState.groups[name],
                    values: value
                }
            }
        }))
    }

    function handleHideImage(value) {
        setDataFilters(prevState => ({
            ...prevState,
            hideImage: value
        }))

        setHideImage(value)
    }

    return (
        <div>
              <div className='ml-auto text-right'>
                                <Title>William</Title>
                            </div>
            <Title>{I18n.get("lblOrdMaOrders")}</Title>
            {
                !hideControls && (
                    <Container>
                        <div className='row'>
                            <div  style={{ maxWidth:"none",position:"static" }}>
                                <FiltersContainer>
                                    <RadioButton
                                        Title={I18n.get('lblOrdMaDeliveryDate')}
                                        name="deliveryDate"
                                        horizontal
                                        isEditable
                                        handleChange={(e) => handleChangeRadioButton("itemsRadioDeliveryDate", e)}
                                        Items={dataFilters?.itemsRadioDeliveryDate ? dataFilters?.itemsRadioDeliveryDate : []} />
                                </FiltersContainer>

                                
                            </div>
                            {
                                showRange &&
                                (
                                    <>
                                        <div className='col-sm-12 col-md-2' style={{ margin: "0" }}>
                                            <DatePicker
                                                id={`deliveryDateFrom`}
                                                name="dateFrom"
                                                value={dataFilters?.dateFrom}
                                                label={I18n.get('lblOrdMaFrom')}
                                                onChange={(date) => handleChangeDate("dateFrom", date)}
                                                isEditable={true}
                                            />
                                        </div>

                                        <div className='col-sm-12 col-md-2' style={{ margin: "0" }}>
                                            <DatePicker
                                                id={`deliveryDateTo`}
                                                name="dateTo"
                                                value={dataFilters?.dateTo}
                                                label={I18n.get('lblOrdMaTo')}
                                                onChange={(date) => handleChangeDate("dateTo", date)}
                                                isEditable={true}
                                            />
                                        </div>
                                    </>
                                )
                            }
                          
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12'>
                                <FiltersContainer>
                                    <RadioButton
                                        Title={I18n.get('lblOrdMaStatusOrder')}
                                        name="orderStatus"
                                        horizontal
                                        isEditable
                                        handleChange={(e) => handleChangeRadioButton("itemsRadioOrderStatus", e)}
                                        Items={dataFilters?.itemsRadioOrderStatus || []} />
                                </FiltersContainer>
                            </div>
                        </div>
                        <div className='row' >
                            <div className='col-sm-12 col-md-12'>
                                <FiltersContainer>
                                    <RadioButton
                                        Title={I18n.get('lblOrdMaShippingType')}
                                        name="shippingType"
                                        horizontal
                                        isEditable
                                        handleChange={(e) => handleChangeRadioButton("itemsRadioShippingType", e)}
                                        Items={dataFilters?.itemsRadioShippingType || []} />
                                </FiltersContainer>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-12 col-md-2">
                                <InputTag
                                    isDisabled={false}
                                    isMulti={false}
                                    isClearable
                                    options={dataFilters?.groups?.group1?.options || []}
                                    onChange={(value) => handleChangeCombo("group1", value)}
                                    name="group1"
                                    label={I18n.get('lblOrdMagroup1')}
                                    noResultMessage={null}
                                    values={dataFilters?.groups?.group1?.values}
                                // selectedValues={tagsSelects}
                                />
                            </div>
                            <div className="col-sm-12 col-md-2">
                                <InputTag
                                    isDisabled={false}
                                    isMulti={false}
                                    isClearable
                                    options={dataFilters?.groups?.group2?.options || []}
                                    onChange={(value) => handleChangeCombo("group2", value)}
                                    name="group2"
                                    label={I18n.get('lblOrdMagroup2')}
                                    noResultMessage={null}
                                    values={dataFilters?.groups?.group2?.values}
                                // selectedValues={tagsSelects}
                                />
                            </div>
                            <div className="col-sm-12 col-md-2">
                                <InputTag
                                    isDisabled={false}
                                    isMulti={false}
                                    isClearable
                                    options={dataFilters?.groups?.group3?.options || []}
                                    onChange={(value) => handleChangeCombo("group3", value)}
                                    name="group3"
                                    label={I18n.get('lblOrdMagroup3')}
                                    noResultMessage={null}
                                    values={dataFilters?.groups?.group3?.values}
                                // selectedValues={tagsSelects}
                                />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className="col-sm-12 col-md-6">
                                <FiltersContainer>
                                    <RadioButton
                                        Title={I18n.get('lblOrdMaReportBy')}
                                        name="reportBy"
                                        horizontal
                                        isEditable
                                        handleChange={(e) => handleChangeRadioButton("itemsRadioReportBy", e)}
                                        Items={dataFilters?.itemsRadioReportBy || []} />
                                </FiltersContainer>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col">
                                <FiltersContainer>
                                    <RadioButton
                                        Title={I18n.get('lblOrdMaType')}
                                        name="type"
                                        horizontal
                                        isEditable
                                        handleChange={(e) => handleChangeRadioButton("itemsRadioType", e)}
                                        Items={dataFilters?.itemsRadioType || []} />
                                </FiltersContainer>
                            </div>
                            {
                                !hidePdf &&
                                <>
                                    <div className='mt-1'>
                                        {
                                            <Switch
                                                id='hideImageFilters'
                                                name='hideImageFilter'
                                                label={I18n.get("lblOrdMaShowImageProduct")}
                                                onChange={(e) => handleHideImage(!e.target.checked)}
                                                value={!hideImage}
                                                isEditable={true}
                                            />
                                        }
                                    </div>
                                    <div className=' ml-2'>
                                        <DownloadHtmlToPdf
                                            handleBeforeGetContent={handleBeforeGetContent}
                                            handleAfterPrint={handleAfterPrint}
                                            componentRef={pdfRef} />
                                    </div>
                                </>
                            }

                        </div>
                    </Container>
                )
            }

            <SeparatorContainer>
                <IconArrow className={`fa fa-arrow-circle-${hideControls ? "down" : "up"}`} onClick={handleClickResize}></IconArrow>
            </SeparatorContainer>
        </div>
    )
}

const IconArrow = styled.i`
    color: #5E35B1;
    cursor: pointer;
    font-size: 20px;
`
const SeparatorContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid rgba(222,222,222,0.6);

    position: relative;
    > i {
        position: absolute;
    }
`

const Container = styled.div`
    margin-left: 20px;
`

const FiltersContainer = styled.div`
    width: 100%;
   
`
const Title = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: var(--gray);
`







