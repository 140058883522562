import React from "react"
import { Link } from "react-router-dom"

function Linked(props) {
    const isOnClick = props.onClick ? true : false
    const classLinked = props.className
        ? props.className + ""//" link-xconsumer"
        : "" //"text-center link-xconsumer"

    return (
        <>
            {isOnClick ? (
                <Link to="/" onClick={props.onClick} className={classLinked}>
                    {props.label}
                </Link>
            ) : (
                <Link
                    to={props.href !== undefined ? props.href : "/"}
                    className={classLinked}
                >
                    {props.label}
                </Link>
            )}
        </>
    )
}

export default Linked
