import React, { useEffect, useState } from "react"
//import { useHistory, useLocation } from 'react-router-dom'
import { TabulatorTable, LoaderWindow } from "xsales-components"
import { I18n } from "@aws-amplify/core"
import "../../../assets/css/detailedStatement1.css"
import moment from "moment"
import { XSalesData } from "../../../DataQuery/XSalesData"
import DetailStatementHeader from "./DetailStatementHeader"
//const today = moment()
//let tabulator = null

const {
    supplier: { currencySymbol },
} =
    JSON.parse(localStorage.getItem("ConsumerUser"))?.supplier != null
        ? JSON.parse(localStorage.getItem("ConsumerUser"))
        : { supplier: { currencyISO: "", currencySymbol: "$" } }

const DetailStatement2 = (props) => {
    // console.log(props.match.params.month)
    // console.log(props.match.params.supplierID)
    const { type } = JSON.parse(localStorage.getItem("ConsumerUser"))
    const [loader, setLoader] = useState(true)
    const [businessName, setBusinessName] = useState("")
    const [licensesType, setLicensesType] = useState("")
    const [dataTable, setDataTable] = useState([])
    const [fee, seFee] = useState(0)
    //const placeHolderTable = useState(I18n.get("lblLoadingData"))
    const [minTransaction, setMinTrasaction] = useState(0)
    const startOfMonth = moment(`${props.match.params.month}`, "M").startOf("month")
    const endOfMonth = moment(`${props.match.params.month}`, "M").endOf("month")

    const columns = [
        {
            title: "orderId",
            field: "orderId",
            widthGrow: 1,
            cellClick: function (e, cell) {
                //history.push('DetailStatement1/4/2021/80e8c927-516f-48ef-b307-df0ce096fce7')
            },
        },
        {
            title: "productId",
            field: "productId",
            widthGrow: 1,
        },
        {
            title: I18n.get("lblcolumnQuantity"),
            field: "quantity",
            widthGrow: 1,
            formatter: "money",
            align: "right",
            bottomCalc: "sum",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cssClass: "tabulator__total__statement",
        },
        {
            title: I18n.get("lblcolumnShortNameLanguage1"),
            field: "shortnameLanguage1",
            widthGrow: 1,
            // formatter:"datetime"
        },
        {
            title: I18n.get("lblcolumnShortNameLanguage2"),
            field: "shortnameLanguage2",
            widthGrow: 1,
            // formatter:"datetime"
        },
        {
            title: I18n.get("lblcolumnPrice"),
            field: "price",
            widthGrow: 1,
            formatter: "money",
            align: "right",
            bottomCalc: "sum",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cssClass: "tabulator__total__statement",
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
        {
            title: I18n.get("lblcolumngrossamount"),
            field: "grossAmount",
            widthGrow: 1,
            formatter: "money",
            align: "right",
            bottomCalc: "sum",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cssClass: "tabulator__total__statement",
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
    ]

    const getDataStatement = async () => {
        const { month, year, supplierID } = props.match.params
        return await XSalesData.Statement.getStatementBySupplier(supplierID, { eq: { year: year, month: month } })
    }

    const getOrderDetail = async () => {
        const { orderId, orderCodeERP } = props.match.params
        const { exchangeRate } = await XSalesData.Order.getOrder(orderId)
        const data = await XSalesData.Order.getOrderDetail(orderId)
        const returnData = data.map((obj) => {
            if (type === "admin") {
                const rate = exchangeRate >= 0 ? 1 : exchangeRate
                return {
                    orderId: orderCodeERP ? orderCodeERP : "",
                    productId: obj.productId,
                    quantity: obj.quantity,
                    shortnameLanguage1: obj.shortnameLanguage1,
                    shortnameLanguage2: obj.shortnameLanguage2,
                    shortnameLanguage3: obj.shortnameLanguage3,
                    shortnameLanguage4: obj.shortnameLanguage4,
                    price: obj.price / rate,
                    grossAmount: obj.grossAmount / rate,
                }
            }
            return {
                orderId: orderCodeERP ? orderCodeERP : "",
                productId: obj.productId,
                quantity: obj.quantity,
                shortnameLanguage1: obj.shortnameLanguage1,
                shortnameLanguage2: obj.shortnameLanguage2,
                shortnameLanguage3: obj.shortnameLanguage3,
                shortnameLanguage4: obj.shortnameLanguage4,
                price: obj.price,
                grossAmount: obj.grossAmount,
            }
        })
        return returnData
    }

    useEffect(() => {
        getDataStatement().then((data) => {
            if (data.length > 0) {
                setBusinessName(data[0].supplier.businessName)
                setLicensesType(data[0].contractDetail.plan)
                seFee(data[0].contractDetail.fees.order)
                setMinTrasaction(data[0].contractDetail.minTransaction.order)
            }
            setLoader(false)
        })
        getOrderDetail().then((data) => {
            setLoader(false)
            setDataTable(data)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const boxes = document.querySelectorAll(".tabulator-row")
        boxes.forEach((box) => {
            box.classList.remove("tabulator-selectable")
        })
    })

    /*  const formatNumber = (value) => {
        const locale = navigator.language
        return new Intl.NumberFormat(locale, { style: 'decimal' }).format(value)
    } */

    /*  const handleClickDownload = () => {
        if (tabulator) {
            tabulator.download("pdf", "data.pdf", {
                orientation: "portrait", //set page orientation to portrait
                title: "", //add title to report
            });
        }
    } */

    return (
        <div className="detail__statement__container">
            {loader ? (
                <LoaderWindow />
            ) : (
                <>
                    <DetailStatementHeader
                        businessName={businessName}
                        startOfMonth={startOfMonth.format("L")}
                        endOfMonth={endOfMonth.format("L")}
                        fee={fee}
                        minTransaction={minTransaction}
                        licensesType={licensesType}
                    />
                    <div className="separator__line_detail"></div>
                    <div className="detail_statement__body" style={{ cursor: "context-menu" }}>
                        <div className="content__detail__table">
                            <TabulatorTable
                                Title={""}
                                Data={dataTable}
                                Columns={columns}
                                Height="530px"
                                // Filter={[]}
                                //RowClick={() => { }}
                                //placeholder={placeHolderTable}
                                placeholder={I18n.get("lblLoadingData")}
                                downloadIcon={false}
                                moreIcon={false}
                                Search={false}
                                columnsIcon={false}
                                //setRefTable={table => tabulator = table}
                                labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                                lblSearch= {I18n.get("lblSearch")}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default DetailStatement2
