import React,{ useEffect, useRef, useState} from 'react'
import { TabulatorTable, LoaderWindow, Title, Modal, Confirm, AlertMessage, Toastify, useToast, TextMessage } from 'xsales-components'
import { I18n } from "@aws-amplify/core"
//import imgCDN from "../../../configConsumer/imagesDefaultCDN.json"
import { XSalesData } from '../../../DataQuery/XSalesData'
import { XSalesConsumer } from '../../../Utils/Utils'
import { v4 as uuidv4 } from 'uuid';
import { FormSubCategory } from './FormSubCategory'

//import styled from 'styled-components'

let supplierg = {}
export function SubCategory()
{

    const [stateRead, setStateRead] = useState(false)
    
    
    const { notifyAsync } = useToast({
        pending: I18n.get('lblprocess'),
        success: I18n.get('lblsuccess'),
        error: I18n.get('lblprocesserror')
    })
    const formRef = useRef(null) 
       
    const [supplier, setSupplier] = useState(null)
    const [loader, setLoader] = useState(true)
    const [showAlertMessage,setShowAlertMessage] = useState(false)
    const[dataModal, setDataModal] = useState({
        title: "",
        mode: "CREATE",

    })
    const [updateAction,setUpdateAction] = useState(false)
    const[dataSubCategory, setDataSubCategory] = useState({
        id:"",
        categoryId: "-1",
        subcategoryId:"",
        supplierId: "",
        nameLanguage1: "",
        nameLanguage2: "",
        nameLanguage3: "",
        nameLanguage4: "",
        imageExternalURL: "",
        imageInternalName : "",
        inactive: false,
        inactiveParent : false
    })
    const [tabulatorProps, setTabulatorProps] = useState({
        filter:[],
        data:[],
        languages:[],
        columns: []
    })
    async function getColumsTabulator(){
        let columns= [
            {
                title: "Id",
                field: "subcategoryId",
                visible: true,
                widthGrow: 0.2,
                
            },
           /*  {
                title: I18n.get("lblimageUrl"),
                field: "imageInternalName",
                widthGrow: 0.2,
                cellClick: (e, cell) => {

                },
                formatter: (cell, formatterParams, onRenderedm) => {
                    const src = cell.getValue() ? cell.getValue() : imgCDN.SupplierConsumerBannerDefault
                    return ` <i class='fas fa-eye' data-toggle="tooltip" title="<img style='width:40px;height:40px;' src='${src}' />"></i> `
                }     
            }, */
            {
                title: I18n.get("lbllanguage1"),
                field: "nameLanguage1",
                widthGrow: 0.5,
            },
            {
                title: I18n.get("lbllanguage2"),
                field: "nameLanguage2",
                widthGrow: 0.5,
            },
            {
                title: I18n.get("lbllanguage3"),
                field: "nameLanguage3",
                widthGrow: 0.5,
            },
            {
                title: I18n.get("lbllanguage4"),
                field: "nameLanguage4",
                widthGrow: 0.5,
            },
            {
                title: I18n.get("lblOnlyRead"),
                field: "readOnly",
                widthGrow: 1,
                visible:false
            },
            {
                title: I18n.get("lblactive"),
                field: "inactive",
                widthGrow: 0.150,
                cellClick: async (e, cell) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const data = cell.getData()
                    cell.setValue(!data.inactive)
                    if(!data.readOnly)
                    {
                    const message = !data.inactive ? I18n.get('lblmsgconfirmactive') : I18n.get('lblmsgconfirminactive') 

                    const confirm = await Confirm({
                        title: I18n.get('lblconfirmation'),
                        message: message
                    })

                    if(!confirm){
                        cell.setValue(!data.inactive)
                        return
                    }
               
                    data.supplierId = supplierg.id
                    data.inactiveOn = (data.inactive) ? new Date().toISOString() : ""
                    await XSalesData.Subcategory.Edit(data)
                    getData()
                }
                else
                {
                    cell.setValue(!data.inactive)
                    return
                }
                },
                formatter: (cell, formatterParams, onRenderedm) => {
                    const id = uuidv4();
                    const { inactive } = cell.getData()
                    return `<div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" ${!inactive && "checked" } id="${id}" />
                    <label className="custom-control-label"  for='${id}' ></label>
                  </div>`
                }   
            }
        ]
        setTabulatorProps(prevState => ({
            ...prevState,
            columns: columns
        }))
    }
    useEffect(() => {
        document.title = "Subategory:List"
        getLanguage()
        getData()
    },[])

    useEffect(() => {
        createTooltips()
    })
    useEffect(() => {
        getColumsTabulator()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        createTooltips()
        const buttons = document.querySelectorAll("button.tabulator-page")
        buttons.forEach((btn) => {
            btn.addEventListener('click', () => {
                createTooltips()
            })
        })
    },[loader])


    const handleSave = () => {
        // const form = formRef.current
        // form.dispatchEvent(new Event('submit',{ cancelable: true, bubbles:true }))      
    }
    

    async function getData(){
        try {
            const{ supplier } =  await XSalesConsumer.getCurrentUser()
            setSupplier(supplier)
            supplierg = supplier
            let dataFinal = []

            const CW019 = await XSalesData.Suppliers.GetSupplierStandaloneMode(supplier.id)
            const CW024 = await XSalesData.Suppliers.GetSupplierAdminProductsMode(supplier.id)
           
            if (CW019 && CW024){
                if (CW019 === "true" && CW024 === "true") {
                    setStateRead(true)
                }
            }
        
                XSalesData.Subcategory.List(supplier.id)
                .then(async (data) =>{
                    let ResultSubCategory = data.subcategories
                    let ResultCategory = data.categories
                ResultSubCategory.map((subcat) =>{
                        ResultCategory.map((cat)=>{
                            if (subcat !== null && cat !== null && cat.categoryId === subcat.categoryId) {
                            dataFinal.push({
                                id:cat.id,
                                categoryId: cat.categoryId,
                                imageExternalURL: subcat.imageExternalURL,
                                imageInternalName : subcat.imageInternalName,
                                inactive: subcat.inactive,
                                inactiveParent : subcat.inactiveParent,
                                nameLanguage1: (subcat.nameLanguage1===null ? "" : subcat.nameLanguage1),
                                nameLanguage2: (subcat.nameLanguage2===null ? "" : subcat.nameLanguage2),
                                nameLanguage3: (subcat.nameLanguage3===null ? "" : subcat.nameLanguage3),
                                nameLanguage4: (subcat.nameLanguage4===null ? "" : subcat.nameLanguage4),
                                subcategoryId: subcat.subcategoryId,
                                supplierId: subcat.supplierId,
                                catnameLanguage1: cat.nameLanguage1,
                                inactiveOn: subcat.inactiveOn,
                                inactiveParentOn : subcat.inactiveParentOn,
                                readOnly: (CW019 === "false" && CW024 === "true" ) ? true : false
                                
                            })
                        }
                        return null
                        })
                        return null
                    })
                 
                    setTabulatorProps(prevState => ({
                        ...prevState,
                        data: dataFinal
                    }))
                    setLoader(false)
                    
                   
                    }).catch((err) => {
                        console.log("Admin SubCategories componentdidmount error ", err)
                        this.setState({ loadWindow: false })
                    })
            

            
        } catch (error) {
            console.error(error)
            setLoader(false)
        }
    }
    

    function createTooltips(){
        window.$('[data-toggle="tooltip"]').tooltip({
            animated: 'fade',
            placement: 'top',
            html: true
        });
    }

    async function getLanguage() {
        try {
            let lang = localStorage.getItem("languageApp");
            if (!lang) {
              const [lang1] = navigator.language.split('-');
              lang = lang1.toUpperCase();
            }
            I18n.setLanguage(lang.toUpperCase());
            const languages = XSalesConsumer.getLanguagesSupplierPreferences()
            setTabulatorProps(prevState => ({
                ...prevState,
                languages: languages.split(',')
            }))
          } catch (error) {
            I18n.setLanguage('EN');
            console.log(error.message);
          }
    }

   /*  const handleUpdateAction= () => {
        setUpdateAction(!updateAction)
    } */

  

    const handleClickRow = (e, row) =>  {
        
        const SubCategory = row.getData()
        setDataSubCategory(prevState => ({
            ...prevState,
            ...SubCategory
        }))
        setDataModal(prevState => ({
            ...prevState,
            title:  I18n.get('lbledit'),
            mode: "UPDATE"
        }))
        setUpdateAction(!updateAction)
        window.$(`#modalSubCategory`).modal('toggle')

    }

    function handleClickCreate(e){
        setDataSubCategory(preveState => ({
            ...preveState,
            id:"",
            categoryId: "-1",
            supplierId: supplier.id,
            subcategoryId:"",
            nameLanguage1: "",
            nameLanguage2: "",
            nameLanguage3: "",
            nameLanguage4: "",
            imageExternalURL: "",
            imageInternalName : "",
            inactive: false,
            inactiveParent : false,
            inactiveOn: "",
            inactiveParentOn : ""

           

        }))
        setDataModal(prevState => ({
            ...prevState,
            title:  I18n.get('lblcreate'),
            mode: "CREATE"
        }))
        setUpdateAction(true)
        window.$(`#modalSubCategory`).modal('toggle')
    } 

    /* const handleCancelAction = (e) => {
        setUpdateAction(!updateAction)
    } */

    /* const handleChange = (e) => {
        const { value, name } = e.target
        setDataSubCategory(preveState => ({
                ...preveState,
                 [name]: value
             }))
    } */



    /* const handleChangeCategory = (e) => {
        const field = "categoryId"
      setDataSubCategory(preveState => ({
                ...preveState,
                 [field]: e.value
             }))
    } */


    /* const handleSaveAction = (e) => {
        setUpdateAction(!updateAction)
    } */

    async function handleSubmit(e) {
        //e.preventDefault()
        setShowAlertMessage(false)
        if(dataModal.mode === "CREATE"){
            //validate if exists category
            const Subcategory = await XSalesData.Subcategory.getById(supplier.id, e.subcategoryId)
            if(Subcategory!== null){
                setShowAlertMessage(true)
                return
            }
            //create
            await notifyAsync(XSalesData.Subcategory.Create(e))
        }else{
            //edit
            await notifyAsync(XSalesData.Subcategory.Edit(e))
        }
        window.$(`#modalSubCategory`).modal('hide')
        
        setLoader(true)
        setTimeout(() => {
            getData()
        }, 1000);
    }


    /* const handleChangeSwitch = (e) => {

    const { checked, name } = e.target
    setDataSubCategory(preveState => ({
              ...preveState,
               [name]: checked
           }))
    } */

    return (
        <div>
      
        {
            loader  ?
             <LoaderWindow />
             :
             <>
        {/* <ContainerButton>
             <Title label={I18n.get('lblsubcategoryTable')} />
            <Button 
                   id="btn example create"
                   label="Create"
                   name="create"
                   type="button"
                   className=""
                   size='md'
                   color='primary'
                   onClick={handleClickCreate}
            />
        </ContainerButton> */}
        <Title label={I18n.get("lblsubcategoryTable")} />
        <TextMessage label={I18n.get("lblMsgOnlyRead")}/>
        <TabulatorTable
                    id='SubCategory'
                    // Title={{ label: "Category"}}
                    Data={tabulatorProps.data}
                    Columns={tabulatorProps.columns}
                    Filter={tabulatorProps.filter}
                    placeholder={I18n.get("lblLoadingData")}
                    Icons={false}
                    Search={true}
                    labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                    columnsIcon={true}        
                    languages={tabulatorProps.languages}       
                    RowClick={handleClickRow}
                    lblSearch= {I18n.get("lblSearch")}
                    Height='50vh'
                    GroupBy={{
                        Key: "catnameLanguage1",
                        Collapse: true,
                    }}
                    groupToggleElement="header"
                    showCreateButton={stateRead ? true : false}
                    lblCreateButton= {I18n.get("lblcreate")}
                    onClickButtonCreate= {handleClickCreate}
                />
                 <Modal
                  id="modalSubCategory"
                  title={dataModal.title}
                  lblbtnSave= {I18n.get('lblSave')}
                  lblbtnCancel=  {I18n.get('lblCancel')}
                  handleSave={handleSave}
                  handleCancel={(e) => false}
                  //open={openModal}
                  size='lg'
                 submitButton={true}
                 formId={"form-Subcategory"}>
                
                <div className='col-sm-12'>
                <AlertMessage
                    message={I18n.get("lblmsgrecordexists")}
                    show={showAlertMessage}
                    type="danger"
                />
                </div>
                <FormSubCategory
                    mode={dataModal.mode}
                    label={""}
                    setRef={formRef}
                    supplier = {supplier}
                    handleSubmit={handleSubmit}
                    data={dataSubCategory}
                    readOnly={stateRead? true: false}
                    
                />
                 </Modal>
                <Toastify />
               {/*  <div><pre>{JSON.stringify(tabulatorProps.data, null, 2) }</pre></div> */}
             </>
        }
       
    </div>
           
    )
}
/* const ContainerButton = styled.div`
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
` */