import React, { useEffect, useState } from 'react'
import { SeparateTop } from 'xsales-components'
import { I18n } from "@aws-amplify/core"
// import { XSalesConsumer } from '../../../Utils/Utils'
import { ErrorMessage } from '@hookform/error-message';
import { useForm, Controller } from "react-hook-form"
import { DatePicker } from 'xsales-components'
import styled from 'styled-components'
import { TableProductDetail } from './TableProductDetail'
import { XSalesConsumer } from '../../../Utils/Utils';
import { ImageUrlTypes } from "../../../DataQuery/XSalesData"
export function FormOrderDetail(props) {
    const { handleSubmit,  formState: { errors }, control,  setValue } = useForm({
        shouldFocusError: true,
        defaultValues: {
            deliveryOn: new Date().toISOString()
        }
    });
    const [dataDetail, setDataDetail] = useState(null)

    useEffect(() => {
        const { supplier } = XSalesConsumer.getCurrentUserNew()
        if (props?.data?.orderProducts) {

            setValue("deliveryOn", props.data.deliveryOn);
            let products = []

            props.data.orderProducts.items.forEach(x => {
                const url = XSalesConsumer.getImageUrlList({
                    imageInternalName: x.imageInternalName,
                    imageExternalURL: x.imageExternalURL,
                    supplierId: supplier.id,
                    imageType: ImageUrlTypes.Types.products,
                })
                products.push({
                    productId: x.productId,
                    //image: "",
                    name: x.shortnameLanguage1,
                    unitId: x.unitId,
                    quantity: parseFloat(x.quantity.toFixed(2)),
                    price: parseFloat(x.price.toFixed(2)),
                    total: parseFloat((x.quantity * x.price).toFixed(2)),
                    image: url.at(0)
                })
            })

            setDataDetail({
                ...props.data,
                orderProducts: products
            })
        }
    }, [props]);// eslint-disable-line react-hooks/exhaustive-deps


    const getErrorMessage = (name) => <ErrorMessage errors={errors} name={name} />

    return (
        <div style={{ padding: "10px" }}  >
            <SeparateTop>
                <form onSubmit={handleSubmit((formData) => props.handleSubmit(dataDetail?.id, formData))} id='form-edit-order'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-9'>
                            <TextTitle>{I18n.get('lblOrdMaOrder')}#</TextTitle>
                            <TextValue>{dataDetail?.orderCodeERP}</TextValue>
                        </div>
                        <div className='col-sm-12 col-md-3'>
                            <TextTitle>{I18n.get('lblOrdMaTotal')}: <TotalAmount>{XSalesConsumer.formatterCurrency(dataDetail?.netAmount, dataDetail?.currencyISOErp)}</TotalAmount></TextTitle>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <TextTitle>{I18n.get('lblOrdMaCustomer')}:</TextTitle>
                            <TextValue>{dataDetail?.consumerName}</TextValue>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-md-9'>
                            <TextTitle>{I18n.get('lblOrdMaDeliveryAddress')}:</TextTitle>
                            <TextValue>{dataDetail?.shippingAddress?.key && `${dataDetail?.shippingAddress?.key} - ${dataDetail?.shippingAddress?.value}`}</TextValue>
                        </div>
                        <div className='col-sm-12 col-md-3'>
                            <TextTitle>{I18n.get('lblOrdMaItemsQty')}: {dataDetail?.itemCount}</TextTitle>

                        </div>
                    </div>
                    <div className='row'>
                         <div className='col-sm-12 col-md-4'>
                            <TextTitle>{I18n.get('lblOrdMaCreatedOn')}:</TextTitle>
                            <TextValue>{dataDetail?.createdAt && XSalesConsumer.formatShortDatetime(dataDetail?.createdAt)}</TextValue>
                        </div>
                        
                        <div className='col-sm-12 col-md-5'>
                            <TextTitle>{I18n.get('lblOrdMaShippingType')}:</TextTitle>
                            <TextValue>{dataDetail?.shipping}</TextValue>
                        </div>
                        <div className='col-sm-12 col-md-3'>
                            <TextTitle>{I18n.get('lblOrdMaStatusOrder')}:</TextTitle>
                            <TextValue>{props.data.status}</TextValue>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-sm-12 col-md-4'>
                            <TextTitle>{I18n.get('lblOrdMaComments')}:</TextTitle>
                            <TextValue>{dataDetail?.comments}</TextValue>
                        </div>
                        <div className='col-sm-12 col-md-5'>
                            <div className="col-sm-12 col-md-7 mt-2 p-0">
                                {
                                    <div>
                                        <Controller
                                            control={control}
                                            name="deliveryOn"
                                            rules={{
                                                required: I18n.get('lblrequiredField')
                                            }}
                                            render={(
                                                { field: { onChange, onBlur, value, name, ref, onFocus },
                                                    fieldState: { invalid, isTouched, isDirty, error },
                                                    formState
                                                }
                                            ) => (
                                                <>
                                                    <DatePicker
                                                        id={`deliveryOn`}
                                                        name="deliveryOn"
                                                        value={value}
                                                        label={I18n.get('lblOrdMaDeliveryDate')}
                                                        onChange={ date => onChange(date.toDate().toISOString())}
                                                        isEditable={true}
                                                        messageError={getErrorMessage('deliveryOn')}
                                                    />

                                                </>
                                            )}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-3'>
                            <TextTitle>{I18n.get('lblOrdMapaymentMethod')}:</TextTitle>
                            <TextValue>{dataDetail?.paymentMethod?.key && `${dataDetail?.paymentMethod?.key} - ${dataDetail?.paymentMethod?.value}`}</TextValue>
                        </div>
                       
                    </div>
                    <TableProductDetail data={dataDetail} />
                </form>
            </SeparateTop>
        </div>
    )
}

const TotalAmount = styled.span`
    margin: 0;
    padding: 0;
    color: #5E32B2;
    font-weight: bold;
    font-size: 20px;
`

const TextTitle = styled.p`
    font-weight: bold;
    font-size: 16px;
    color:#66686C;
    margin: 0;
    padding: 0;
`


const TextValue = styled.p`
    font-size: 14px;
    color: #79849C;
    margin: 0;
    padding: 0;
`

