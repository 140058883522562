import React from 'react'
import ReactToPrint from "react-to-print"
//import { GrDocumentPdf } from 'react-icons/gr'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid';
import IconPdf from '../../../assets/img/icon_pdf.svg'
export function DownloadHtmlToPdf({ componentRef, handleAfterPrint, handleBeforeGetContent }) {
    const onBeforeGetContentResolve = React.useRef(null)

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);// eslint-disable-line react-hooks/exhaustive-deps

    const onAfterPrint = React.useCallback(() => {
        //console.log("`onAfterPrint` called");
        handleAfterPrint()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleOnBeforeGetContent = React.useCallback(() => {
        //console.log("`onBeforeGetContent` called");
        handleBeforeGetContent()
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            // setTimeout(() => {
            resolve();
            // }, 0);
        });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const onBeforePrint = React.useCallback(() => {
        //console.log("`onBeforePrint` called");

    }, []);


    const reactToPrintTrigger = React.useCallback(() => {
        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
        // to the root node of the returned component as it will be overwritten.

        // Bad: the `onClick` here will be overwritten by `react-to-print`
        // return <button onClick={() => alert('This will not work')}>Print this out!</button>;
        //console.log(IconPdf)
        // Good
        return (
            <ButtonStyled>
                <ImagePdf src={IconPdf} alt="NOT FOUND " />
            </ButtonStyled>
        ); // eslint-disable-line max-len
    }, []);

    return (
        <ReactToPrint
            content={reactToPrintContent}
            documentTitle={uuidv4()}
            onAfterPrint={onAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={onBeforePrint}
            removeAfterPrint
            trigger={reactToPrintTrigger}
        />
    )
}

const ButtonStyled = styled.div`
    border: 0;
    cursor: pointer;
    font-size: 17px;
`

const ImagePdf = styled.img`
    width: 24px;
`