import React from 'react'

export default function Content(props) {
    return (
        <div className="content-wrapper">
            <div className="col-sm-12">
                <div className="container__wrapper__main">
                    {
                        props.children
                    }
                </div>
            </div>
        </div>
    )
}