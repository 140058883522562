import React, { useEffect, useState } from 'react'
import { Filters } from './Filters'
import { XSalesConsumer } from '../../../Utils/Utils'
import { LoaderWindow, useToast, Toastify } from 'xsales-components'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { OrderCharts } from './OrderCharts'
import { filterData, groupByProductByUnit, sanatizeText, maxDays, defaultFilters } from './commons'
import { I18n } from 'aws-amplify'

const barThickness = undefined
const barPercentage = 0.7
const categoryPercentage = 1


let gFilters 
const randomColor = XSalesConsumer.randomColor()
export function OrderManagment() {
    const componentRef = React.useRef(null);
    const [filters, setFilters] = useState({ ...defaultFilters })
    const [dataPerDeliveryDate, setDataDeliveryDate] = useState({ labels: [], data: [], labelsDate: [], datasets: [] })
    const [dataPerClientGroup, setDataPerClientGroup] = useState({ labels: [], data: [], datasets: [] })
    const [dataPerDeliveryDateProduct, setDataDeliveryDateProduct] = useState({ labels: [], datasets: [], labelsDate: [] })
    const [dataPerClientGroupProduct, setDataPerClientGroupProduct] = useState({ labels: [], datasets: [] })
    const [dataPerProduct, setDataPerProduct] = useState({ labels: [], datasets: [] })
    const [supplierId, setSupplierId] = useState("")
    const [loader, setLoader] = useState(false)
    const [reportType, setReportType] = useState("orders")
    const dataComplete = []
    const router = useHistory()
    const { notify } = useToast()
    // const [groupSelected, setGroupSelected] = useState("group1")

    useEffect(() => {
        main()
    }, [])

    useEffect(() => {
        if (supplierId !== "") getData(gFilters)
    }, [filters])// eslint-disable-line react-hooks/exhaustive-deps

    async function main() {
         setLoader(true)
        const { supplier } = await XSalesConsumer.getCurrentUser()
        setSupplierId(supplier.id)
        // const dataOrders = await getOrdersFromCache()
        // const dataOrders = await getDataOrdersByDate(supplier.id, queryOrders.startDate, queryOrders.endDate)
        // setDataComplete([...dataOrders])
        // buildDataChartOrders(dataOrders)
        // getData()
        // setLoader(false)
        //console.log("supplier")
        //console.log(supplier)
    }

    function sortAsc(a, b) {
        if (a > b.key) return -1
        if (a < b.key) return 1
        return 0
    }

    function buildChartDatasetPerDeliveryDate(listOfDate, orders) {
        const dataQty = []
        const dataset = []
        const totalByCurrency = []

        listOfDate.forEach((date) => {
            const filterOrdersByDate = orders.filter(x => moment(x.deliveryOn).format("YYYY-MM-DD") === date.date)
            let currencys = filterOrdersByDate.map(x => x.currencyISOErp)
            currencys = [...new Set(currencys)] // monedas distintas
            //const acumByDate = filterOrdersByDate.reduce((a, { grossAmount }) => a + grossAmount, 0)
            dataQty.push(filterOrdersByDate.length)

            currencys.forEach((currency) => {
                const dataByCurrency = filterOrdersByDate.filter(x => x.currencyISOErp === currency)
                const qtyByCurrency = dataByCurrency.reduce((a, { grossAmount }) => a + grossAmount, 0)
                totalByCurrency.push({ date, currency, total: qtyByCurrency })
            })


        })
        dataset.push({
            label: '',
            data: dataQty.map(x => x),
            backgroundColor: randomColor(),
            barThickness : barThickness,
            barPercentage: barPercentage,
            categoryPercentage: categoryPercentage,
            labels: listOfDate,
            totalByCurrency: totalByCurrency
        })

        return dataset
    }

    function buildChartDatasetPerClientGroup(orders, groupType) {
        const groups = {}
        const datasets = []
        const totalByCurrency = []
        let currencys = []
        orders.forEach(order => {
            if (!groups[order[groupType]]) {
                groups[order[groupType]] = null
            }
            currencys.push(order.currencyISOErp)
        })
        currencys = [...new Set(currencys)] // distinct monedas
        // se totaliza por grupo
        const dataGroup = []
        Object.keys(groups).forEach(key => {
            let count = orders.filter(x => x[groupType] === key).length//reduce((a, { grossAmount }) => a + grossAmount, 0)
            // amount += orders.filter(x => x.group2 === key).length//reduce((a, { grossAmount }) => a + grossAmount, 0)
            // amount += orders.filter(x => x.group3 === key).length//reduce((a, { grossAmount }) => a + grossAmount, 0)
            groups[key] = count
            dataGroup.push({ label: key, value: count })
            //currencys= null
            currencys.forEach((currency) => {
                const dataByCurrency = orders.filter(x => x.currencyISOErp === currency && x[groupType] === key)
                const qtyByCurrency = dataByCurrency.reduce((a, { grossAmount }) => a + grossAmount, 0)
                if (qtyByCurrency > 0) totalByCurrency.push({ group: key, currency, total: qtyByCurrency })
            })
        })
        //Order by Desc
        dataGroup.sort((a, b) => {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1
            return 0
        })
        const labels = dataGroup.slice(0, 10).map(x => x.label)
        datasets.push({
            label: '',
            data: dataGroup.slice(0, 10).map(x => x.value),
            backgroundColor: randomColor(),
             barThickness : barThickness,
            barPercentage: barPercentage,
            categoryPercentage: categoryPercentage,
            totalByCurrency: totalByCurrency,
            labels: labels
        })

        return { datasetPerClientGroup: datasets, labelsPerClientGroup: labels }
    }
    function buildChartDatasetPerProduct(orders) {
        const datasets = []
        const units = {}
        let labels = []

        const products = groupByProductByUnit(orders)// [{ "0001": [ { unit: "ea", label: "coca cola", value: 1544.22 } ]}]

        Object.keys(products).forEach(key => {
            products[key].forEach(({ unit, label, value }) => {
                if (!units[key]) {
                    units[key] = { [unit]: value }
                } else {
                    if (!units[key][unit]) {
                        units[key][unit] = value
                    } else {
                        units[key][unit] += value
                    }
                }
                labels.push(label)
            })
        })

        labels = [...new Set(labels)].map(x => sanatizeText(x, 10))

        // { esto de como deberia quedar la estructura de la variable units
        //     "000001": [{ key: "ca", value: 21 }, { key: "pza", value: 18 }],
        //     "000002": [{ key: "ea", value: 18 }, { key: "ca", value: 25 }, { key: "pza", value: 45 }]
        // }
        Object.keys(units).forEach((key) => {
            const dataUnit = []
            Object.keys(units[key]).forEach(keyUnit => {
                dataUnit.push({ key: keyUnit, value: units[key][keyUnit] })
            })
            units[key] = dataUnit
        })

        let unitIds = []
        Object.keys(units).forEach((key) => {
            units[key].forEach(x => unitIds.push(x.key))
        })
        unitIds = [...new Set(unitIds)] //se eliminan las unidades duplicadas
        unitIds.sort(sortAsc)
        unitIds.forEach((x) => {
            datasets.push({
                label: x, data: [],
                backgroundColor: randomColor(),
                barPercentage: barPercentage,
                 barThickness : barThickness,
                categoryPercentage: categoryPercentage
            })
        })
        // ahora se construyen los dataset para el grafico por unidad
        unitIds.forEach((unit, index) => {
            Object.keys(units).forEach(dateKey => {
                const [result] = units[dateKey].filter(x => x.key === unit)
                let value = 0
                if (result) value = result.value

                datasets[index].data.push(value)
            })
        })
        return { datasetPerProduct: datasets, labelsPerProduct: labels }
    }

    function buildChartDatasetProductByGroup(orders, groupType) {
        const datasets = []
        const units = {}

        const groups = {}
        const groupList = []
        orders.forEach(order => {
            if (!groups[order[groupType]]) {
                groups[order[groupType]] = null
            }
        })

        Object.keys(groups).forEach(x => groupList.push(x)) // ["truck1", "truck2", "truck3"]
        groupList.sort(function (a, b) {
            if (a > b) return 1
            if (a < b) return -1
            return 0
        })

        // se totaliza por grupo
        const dataGroup = []
        groupList.forEach(key => {
            let amount = 0
            amount = orders.filter(x => x.group1 === key).reduce((a, { grossAmount }) => a + grossAmount, 0)
            amount += orders.filter(x => x.group2 === key).reduce((a, { grossAmount }) => a + grossAmount, 0)
            amount += orders.filter(x => x.group3 === key).reduce((a, { grossAmount }) => a + grossAmount, 0)
            // groups[key] = amount
            dataGroup.push({ label: key, value: amount }) // [{ label: "truck1", value: 2000 }]
        })


        dataGroup.forEach(({ label }) => {
            const filterOrders = orders.filter(x => x[groupType] === label)
            for (let order of filterOrders) {
                for (let orderProduct of order.orderProducts) {
                    if (!units[label]) {
                        units[label] = { [orderProduct.unitId]: orderProduct.quantity }
                    } else {
                        if (!units[label][orderProduct.unitId]) {
                            units[label][orderProduct.unitId] = orderProduct.quantity
                        } else {
                            units[label][orderProduct.unitId] += orderProduct.quantity
                        }
                    }
                }
            }
        })

        // { esto de como deberia quedar la estructura de la variable units
        //     "truck1": [{ key: "ca", value: 21 }, { key: "pza", value: 18 }],
        //     "truck2": [{ key: "ea", value: 18 }, { key: "ca", value: 25 }, { key: "pza", value: 45 }]
        // }

        Object.keys(units).forEach((key) => {
            const dataUnit = []
            Object.keys(units[key]).forEach(keyUnit => {
                dataUnit.push({ key: keyUnit, value: units[key][keyUnit] })
            })
            units[key] = dataUnit
        })

        let unitIds = []
        Object.keys(units).forEach((key) => {
            units[key].forEach(x => unitIds.push(x.key))
        })
        unitIds = [...new Set(unitIds)] //se eliminan las unidades duplicadas
        unitIds.sort(sortAsc)
        unitIds.forEach((x) => {
            datasets.push({
                label: x,
                data: [],
                backgroundColor: randomColor(),
                barPercentage: barPercentage,
                 barThickness : barThickness,
                categoryPercentage: categoryPercentage
            })
        })
        // ahora se construyen los dataset para el grafico por unidad
        unitIds.forEach((unit, index) => {
            Object.keys(units).forEach(dateKey => {
                const [result] = units[dateKey].filter(x => x.key === unit)
                let value = 0
                if (result) {
                    value = result.value
                }
                datasets[index].data.push(value)
            })
        })
        return { datasetProductGroup: datasets, labelsProductGroup: dataGroup.slice(0, 10).map(x => x.label) }
    }

    function buildChartDatasetUnitByDate(listOfDate, orders) {
        const datasets = []
        const units = {}
        listOfDate.forEach((date) => {
            const filterOrdersByDate = orders.filter(x => moment(x.deliveryOn).format("YYYY-MM-DD") === date.date)
            for (let order of filterOrdersByDate) {
                for (let orderProduct of order.orderProducts) {
                    if (!units[date.date]) {
                        units[date.date] = { [orderProduct.unitId]: orderProduct.quantity }
                    } else {
                        if (!units[date.date][orderProduct.unitId]) {
                            units[date.date][orderProduct.unitId] = orderProduct.quantity
                        } else {
                            units[date.date][orderProduct.unitId] += orderProduct.quantity
                        }
                    }
                }
            }
        })

        // { esto de como deberia quedar la estructura de la variable units
        //     "2022-01-16": [{ key: "ca", value: 21 }, { key: "pza", value: 18 }],
        //     "2022-01-17": [{ key: "ea", value: 18 }, { key: "ca", value:  25 }, { key: "pza", value: 45 }]
        // }

        Object.keys(units).forEach((key) => {
            const dataUnit = []
            Object.keys(units[key]).forEach(keyUnit => {
                dataUnit.push({ key: keyUnit, value: units[key][keyUnit] })
            })
            units[key] = dataUnit
        })

        let unitIds = []
        Object.keys(units).forEach((key) => {
            units[key].forEach(x => unitIds.push(x.key))
        })
        unitIds = [...new Set(unitIds)] //se eliminan las unidades duplicadas
        unitIds.sort(sortAsc)
        unitIds.forEach((x) => {
            datasets.push({
                label: x,
                data: [],
                backgroundColor: randomColor(),
                barPercentage: barPercentage,
             barThickness : barThickness,
                categoryPercentage: categoryPercentage
            })
        })
        // ahora se construyen los dataset para el grafico por unidad
        unitIds.forEach((unit, index) => {
            Object.keys(units).forEach(dateKey => {
                const [result] = units[dateKey].filter(x => x.key === unit)
                let value = 0
                if (result) {
                    value = result.value
                }
                datasets[index].data.push(value)
            })
        })
        return datasets
    }

    function buildCharts(orders, groupType) {
        const labels = []

        orders.sort((a, b) => {
            return new Date(a.deliveryOn) - new Date(b.deliveryOn)
        })
        orders.forEach(order => {
            const date = moment(order.deliveryOn).format("YYYY-MM-DD")
            if (!labels.find(x => x.date === date)) {
                labels.push({ shortDate: XSalesConsumer.formatShortDate(order.deliveryOn), date: date, shortDatetime: XSalesConsumer.formatShortDatetime(order.deliveryOn) })
            }
        })

        const datasetPerDeliveryDate = buildChartDatasetPerDeliveryDate(labels, orders)
        const datasetsUniProducttByDate = buildChartDatasetUnitByDate(labels, orders)
        const { datasetPerClientGroup, labelsPerClientGroup } = buildChartDatasetPerClientGroup(orders, groupType)
        const { datasetProductGroup, labelsProductGroup } = buildChartDatasetProductByGroup(orders, groupType)
        const { datasetPerProduct, labelsPerProduct } = buildChartDatasetPerProduct(orders)

        setDataDeliveryDate(prevState => ({
            ...prevState,
            labels,
            datasets: datasetPerDeliveryDate
        }))

        setDataDeliveryDateProduct(prevState => ({
            ...prevState,
            labels,
            datasets: datasetsUniProducttByDate
        }))

        setDataPerClientGroup(prevState => ({
            ...prevState,
            labels: labelsPerClientGroup,
            datasets: datasetPerClientGroup
        }))

        setDataPerClientGroupProduct(prevState => ({
            ...prevState,
            labels: labelsProductGroup,
            datasets: datasetProductGroup
        }))

        setDataPerProduct(prevState => ({
            ...prevState,
            labels: labelsPerProduct,
            datasets: datasetPerProduct
        }))
    }

    function handleClickOrderBar(event, element) {
        if (element?.length > 0) {
            const idx = element[0].index
            const row = dataPerDeliveryDate.labels[idx]
            router.push(`/order/managment/detail/order/${row.date}`)
        }
    }

    function handleClickOrderProductByDate(event, element) {
        if (element?.length > 0) {
            const idx = element[0].index
            const row = dataPerDeliveryDate.labels[idx]
            router.push(`/order/managment/detail/product/${row.date}`)
        }
    }

    function handleClickGroupBar(event, element) {
        // if (element?.length > 0) {
        //     const idx = element[0].index
        //     const row = dataPerDeliveryDate.labels[idx]
        // }
    }


    async function getData(filters) {
        setLoader(true)
        const { dataFiltered, valueGroupType } = await filterData(filters)
        // setGroupSelected(valueGroupType)
        buildCharts([...dataFiltered], valueGroupType)
        setLoader(false)
    }

    function handleChangeFilters(dataFilters) {
        if (!dataFilters) return
        const [value] = dataFilters.itemsRadioType.filter(x => x.value === true)

        if (supplierId !== "" && dataFilters.showRange) {
            const dateFrom = moment(dataFilters.dateFrom)
            const dateTo = moment(dataFilters.dateTo)
            const diff = dateTo.diff(dateFrom, 'days')
            if (diff > maxDays) {
                notify("warning", I18n.get('msgOrdMaMaxDays'))
                setLoader(false)
                return
            }
        }

        if (value.Key === "products") {
            setReportType(value.Key)
        } else {
            setReportType("orders")
        }

        setFilters({ ...dataFilters })
        gFilters = { ...dataFilters }

        if (dataComplete.length > 0 && supplierId !== "") {
            getData(dataFilters)
        }
    }




    return (
        <div>
            <Filters filterName="filtermanagment" handleChangeFilters={handleChangeFilters} pdfRef={componentRef} hidePdf={true} />
            {
                loader && <LoaderWindow center={true} />
            }
            <div ref={componentRef}>
                {
                    !loader && (
                        <OrderCharts
                            dataPerDeliveryDate={dataPerDeliveryDate}
                            dataPerClientGroup={dataPerClientGroup}
                            dataPerClientGroupProduct={dataPerClientGroupProduct}
                            dataPerProduct={dataPerProduct}
                            dataPerDeliveryDateProduct={dataPerDeliveryDateProduct}
                            reportType={reportType}
                            handleClickOrderByDate={handleClickOrderBar}
                            handleClickOrderProductByDate={handleClickOrderProductByDate}
                            handleClickOrderGroup={handleClickGroupBar}
                            formatterPerDeliveryDate={(value, context) => {
                                const { totalByCurrency, labels } = context.dataset
                                let text = ''
                                totalByCurrency.sort((a, b) => a.total - b.total)
                                    .filter(x => x.date === labels[context.dataIndex])
                                    .forEach(x => {
                                        if (x.currency!=null){
                                            text += `${x.currency} ${XSalesConsumer.formatterCurrency(x.total, x.currency)}\n`
                                        }
                                    })
                                return text
                            }}
                            formatterPerClientGroup={(value, context) => {
                                const { totalByCurrency, labels } = context.dataset
                                const group = labels[context.dataIndex]
                                let text = ''
                                totalByCurrency.sort((a, b) => a.total - b.total)
                                    .filter(x => x.group === group)
                                    .forEach(x => {
                                        if (x.currency!=null){
                                            text += `${x.currency} ${XSalesConsumer.formatterCurrency(x.total, x.currency)}\n`
                                        }
                                    })
                                return text
                            }}
                            formatterDeliveryDateProduct={(value, context) => `${value} ${context.dataset.label}`}
                            formatterPerClientGroupProduct={(value, context) => `${value} ${context.dataset.label}`}
                            formatterPerProduct={(value, context) => `${value} ${context.dataset.label}`}
                        />
                    )
                }
            </div>
            <Toastify autoClose={6000} />
            {/* <pre>
                {
                  //  JSON.stringify(reportType, null, 2)
                }
            </pre> */}
        </div>
    )
}
