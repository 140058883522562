import React from "react"
import {
    Tab,
    TabCell,
    SeparateTop,
    LoaderWindow,
    Title,
    Carrousel
} from "xsales-components"
import SupplierGeneral from "./GeneralInfo"
import SupplierAdditional from "./AdditionalInfo"
//import SupplierBanner from "./Banners"
import SupplierEndpoint from "./EndpointConfiguration"
//import Tabs from "../../componentsConsumer/Tabs"
import { ImageUrlTypes,XSalesData } from "../../DataQuery/XSalesData"
//import { XSalesConsumer } from "../../Utils/Utils"
import { I18n } from "aws-amplify"
//import Breadcrumb from "../../componentsLayout/Breadcrumb"
import { CurrencyConfig } from "./CurrencyConfig"
import { ContractDefinition } from "./ContractDefinition"
import { XSalesConsumer } from "../../Utils/Utils"
// import firebaseConfig from '../../firebase/firebaseConfig'

//import { analyticsApp } from "../../firebase/firebaseApp"
//import { ajaxPrefilter } from "jquery"

import { API } from "aws-amplify"
import BreadcrumbPage from "../../componentsLayout/Breadcrumb"
import DefaultPreference from '../../configConsumer/preferenceDefault.json'
import moment from 'moment'

// firebaseConfig.analytics().setCurrentScreen('Supplier-Detail')
// firebaseConfig.analytics().logEvent("add_to_wishlist")


export default class Detail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            promotions:[],
            pathRoot:"",
            sectionData: {},
            loadWindow: true,
            supplierIdSelected: "",
            sectionDataSupplierEndpoint: {
                endpointSupplierApi: "",
                endpointSupplierApiKey: "",
                endpointSupplierApiSectretKey: "",
                endpointAuthType: "",
                endpointAuthBasicUser: "",
                endpointAuthBasicPass: "",
            },
            tapOptions: [
                {
                    id: "lblGeneral",
                    label: I18n.get("lblGeneral"),
                    default: true,
                },
                {
                    id: "lblAdditional",
                    label: I18n.get("lblAdditional"),
                    default: false,
                },

                {
                    id: "lblConfigERPEndpoint",
                    label: I18n.get("lblConfigERPEndpoint"),
                    default: false,
                },

                {
                    id: "lblBanner",
                    label: I18n.get("lblBanner"),
                    default: false,
                },
                {
                    id: "lblContract",
                    label: I18n.get("lblcontract"),
                    default: false,
                },
                {
                    id: "lblcurrency",
                    label: I18n.get("lblcurrencytab"),
                    default: false,
                },
            ],
            contract:{
                since: moment().add(1, 'month').startOf('month'),
                fees: {
                    payment: 0,
                    deposit: 0,
                    order: DefaultPreference.Plans.Basic.fee
                },
                minTransaction: {
                    payment: 0,
                    deposit: 0,
                    order: DefaultPreference.Plans.Basic.minTransactions
                },
                plan: "No ERP Integration"
            }
        }
    }

    
    handleChangeSupplierEndpoint = (e) => {
        let sd = this.state.sectionDataSupplierEndpoint
        const { name, value } = e.target
        sd[name] = value
        this.setState({ sectionDataSupplierEndpoint: sd })
    }

    handleChangeEndpointAuthType = (e) => {
        const { value } = e

        this.setState({
            sectionDataSupplierEndpoint: {
                ...this.state.sectionDataSupplierEndpoint,
                endpointAuthType: value,
            },
        }) //https://stackoverflow.com/questions/58399919/react-does-not-change-state-after-updating-state-in-usestate-hook-in-custom-fun
        //Because you are still using the same object. prevState in your example is still the same object. You should create a new object with the changed data – tic Oct 16 '19 at 20:22
    }

    handleChangeState = (e) => {
        let sectionData = this.state.sectionData
        try {
            const { name, value } = e.target
            sectionData[name] = value
        } catch (error) {
            sectionData["status"] = e.value
        }
        this.setState({ sectionData: sectionData })
    }

    handleChangeStateXSM = (e) => {
        let sectionData = this.state.sectionData
        sectionData.isXSM = !sectionData.isXSM
        this.setState({ sectionData: sectionData })
    }

    handleChangeStateStandAlone = () => {
        //firebaseConfig.analytics().logEvent("set_checkout_option")
        //console.log("handleChangeStateStandAlone")
        let sectionData = this.state.sectionData
        sectionData.standAlone = !sectionData.standAlone
        this.setState({ sectionData: sectionData })
    }

    handleChangeOrderManagement = () => {
        let sectionData = this.state.sectionData
        sectionData.orderManagement = !sectionData.orderManagement
        this.setState({ sectionData: sectionData })
    }

    handleChangePromotions = () => {
        let sectionData = this.state.sectionData
        sectionData.ActivePromotions = !sectionData.ActivePromotions
        this.setState({ sectionData: sectionData })
    }

    handleChangeListPrice = () => {
        let sectionData = this.state.sectionData
        sectionData.ActiveListPrice = !sectionData.ActiveListPrice
        this.setState({ sectionData: sectionData })
    }

    handleChangeAdminProducts = () => {
        let sectionData = this.state.sectionData
        sectionData.ActiveAdminProducts = !sectionData.ActiveAdminProducts
        this.setState({ sectionData: sectionData })
    }


    handleChangeMinimunStock = () =>
    {
        let sectionData = this.state.sectionData
        sectionData.ActiveMinimunStock = !sectionData.ActiveMinimunStock
        this.setState({ sectionData: sectionData })
    }

    handleChangeBarcodeScanner = () =>
    {
        let sectionData = this.state.sectionData
        sectionData.ActivebarcodeScanner = !sectionData.ActivebarcodeScanner
        this.setState({ sectionData: sectionData })
    }

    handleChangeCheckCustomerCatalog = () =>
    {
        let sectionData = this.state.sectionData
        sectionData.ActiveCustomerCatalog = !sectionData.ActiveCustomerCatalog
        this.setState({ sectionData: sectionData })
    }

    handleChangeProductDiscount = () =>
    {
        let sectionData = this.state.sectionData
        sectionData.ActiveProductDiscount = !sectionData.ActiveProductDiscount
        this.setState({ sectionData: sectionData })
    }

    handleChangeSameOrderClass = () =>
    {
        let sectionData = this.state.sectionData
        sectionData.ActiveSameOrderClass = !sectionData.ActiveSameOrderClass
        this.setState({ sectionData: sectionData })
    }

    handleChangeShippingAddress = () =>
    {
        let sectionData = this.state.sectionData
        sectionData.ActiveShippingAddress = !sectionData.ActiveShippingAddress
        this.setState({ sectionData: sectionData })
    }

    handleChangePaymentMethod = () =>
    {
        let sectionData = this.state.sectionData
        sectionData.ActivePaymentMethod = !sectionData.ActivePaymentMethod
        this.setState({ sectionData: sectionData })
    }

    CancelisXSM = () => {}

    ChangeisXSM = async (e) => {
        let section = this.state.sectionData
        await XSalesData.Suppliers.SetSupplierXSM(section.id, section.isXSM)
            .then((data) => {
                //console.log("saved ok in method SetSupplierXSM", data)
            })
            .catch((err) => {
                console.log("error running SetSupplierXSM method", err)
            })
    }

    ChangeOrdenManagement = async (e) => {
        let section = this.state.sectionData
        const supplierId = section.id
        const orderManagement = section.orderManagement ? "true" : "false"
        await XSalesData.Suppliers.SetSupplierOrderManagement(
            supplierId,
            orderManagement
        )
            .then((data) => {
                /*console.log(
                    "saved ok in method SetSupplierOrderManagement",
                    data
                )*/
                this.FillData()
            })
            .catch((err) => {
                console.log(
                    "error running SetSupplierOrderManagement method",
                    err
                )
            })
    }

    ChangePromotions = async (e) => {
        let section = this.state.sectionData
        const supplierId = section.id
        const ActivePromotions = section.ActivePromotions ? "true" : "false"
        await XSalesData.Suppliers.SetSupplierActivePromotions(
            supplierId,
            ActivePromotions
        )
            .then((data) => {
                /*console.log(
                    "saved ok in method SetSupplierActivePromotions",
                    data
                )*/
                this.FillData()
            })
            .catch((err) => {
                console.log(
                    "error running SetSupplierActivePromotions method",
                    err
                )
            })
    }

    ChangeListPrice = async (e) => {
        let section = this.state.sectionData
        const supplierId = section.id
        const ActiveListPrice = section.ActiveListPrice ? "true" : "false"
        await XSalesData.Suppliers.SetSupplierActiveListPrice(
            supplierId,
            ActiveListPrice
        )
            .then((data) => {
                /*console.log(
                    "saved ok in method SetSupplierActiveListPrice",
                    data
                )*/
                this.FillData()
            })
            .catch((err) => {
                console.log(
                    "error running SetSupplierActiveListPrice method",
                    err
                )
            })
    }

    ChangeAdminProducts = async (e) => {
        let section = this.state.sectionData
        const supplierId = section.id
        const ActiveAdminProducts = section.ActiveAdminProducts
            ? "true"
            : "false"
        await XSalesData.Suppliers.SetSupplierActiveAdminProducts(
            supplierId,
            ActiveAdminProducts
        )
            .then((data) => {
                /*console.log(
                    "saved ok in method SetSupplierActiveAdminProducts",
                    data
                )*/
                this.FillData()
            })
            .catch((err) => {
                console.log(
                    "error running SetSupplierActiveAdminProducts method",
                    err
                )
            })
    }

    ChangeMinimunStock = async (e) => {
        
        let section = this.state.sectionData
        const supplierId = section.id
        const ActiveMinimunStock = section.ActiveMinimunStock
            ? "true"
            : "false"
        await XSalesData.Suppliers.SetSupplierActiveMinimunStock(
            supplierId,
            ActiveMinimunStock
        )
            .then((data) => {
                /*console.log(
                    "saved ok in method SetSupplierActiveMinimunStock",
                    data
                )*/
                this.FillData()
            })
            .catch((err) => {
                console.log(
                    "error running SetSupplierActiveMinimunStock method",
                    err
                )
            })
    }

    ChangeActiveShippingAddress = async (e) => {
        
        
        let section = this.state.sectionData
        const supplierId = section.id
        const ActiveShippingAddress = section.ActiveShippingAddress
            ? "true"
            : "false"
        await XSalesData.Suppliers.SetSupplierActiveShippingAddress(
            supplierId,
            ActiveShippingAddress
        )
            .then((data) => {
                /*console.log(
                    "saved ok in method SetSupplierActiveShippingAddress",
                    data
                )*/
                this.FillData()
            })
            .catch((err) => {
                console.log(
                    "error running SetSupplierActiveShippingAddress method",
                    err
                )
            })
    }

    ChangeActivePaymentMethod = async (e) => {
        let section = this.state.sectionData
        const supplierId = section.id
        const ActivePaymentMethod= section.ActivePaymentMethod
            ? "true"
            : "false"
        await XSalesData.Suppliers.SetSupplierActivePaymentMethod(
            supplierId,
            ActivePaymentMethod
        )
            .then((data) => {
                /*console.log(
                    "saved ok in method SetSupplierActiveShippingAddress",
                    data
                )*/
                this.FillData()
            })
            .catch((err) => {
                console.log(
                    "error running SetSupplierActivePaymentMethod method",
                    err
                )
            })
    }

    ChangeBarcodeScanner = async (e) => {
        
        
        let section = this.state.sectionData
        const supplierId = section.id
        const ActivebarcodeScanner = section.ActivebarcodeScanner
            ? "true"
            : "false"
        await XSalesData.Suppliers.SetSupplierActiveBarcodeScanner(
            supplierId,
            ActivebarcodeScanner
        )
            .then((data) => {
                /*console.log(
                    "saved ok in method SetSupplierActiveBarcodeScanner",
                    data
                )*/
                this.FillData()
            })
            .catch((err) => {
                console.log(
                    "error running SetSupplierActiveBarcodeScanner method",
                    err
                )
            })
    }

    ChangeCustomerCatalog  = async (e) => {
        
        
        let section = this.state.sectionData
        const supplierId = section.id
        const ActiveCustomerCatalog = section.ActiveCustomerCatalog
            ? "true"
            : "false"
        await XSalesData.Suppliers.SetSupplierCheckCustomerCatalog(
            supplierId,
            ActiveCustomerCatalog
        )
            .then((data) => {
                /*console.log(
                    "saved ok in method SetSupplierCheckCustomerCatalog",
                    data
                )*/
                this.FillData()
            })
            .catch((err) => {
                console.log(
                    "error running SetSupplierCheckCustomerCatalog method",
                    err
                )
            })
    }

    ChangeActiveProductDiscount  = async (e) => {
        
        
        let section = this.state.sectionData
        const supplierId = section.id
        const ActiveProductDiscount = section.ActiveProductDiscount
            ? "true"
            : "false"
        await XSalesData.Suppliers.SetSupplierActiveProductDiscount(
            supplierId,
            ActiveProductDiscount
        )
            .then((data) => {
                /*console.log(
                    "saved ok in method SetSupplierActiveProductDiscount",
                    data
                )*/
                this.FillData()
            })
            .catch((err) => {
                console.log(
                    "error running SetSupplierActiveProductDiscount method",
                    err
                )
            })
    }

    ChangeActiveSameOrderClass = async (e) => {
        
        
        let section = this.state.sectionData
        const supplierId = section.id
        const ActiveSameOrderClass = section.ActiveSameOrderClass
            ? "true"
            : "false"
        await XSalesData.Suppliers.SetSupplierActiveSameOrderClass(
            supplierId,
            ActiveSameOrderClass
        )
            .then((data) => {
                /*console.log(
                    "saved ok in method SetSupplierActiveSameOrderClass",
                    data
                )*/
                this.FillData()
            })
            .catch((err) => {
                console.log(
                    "error running SetSupplierActiveSameOrderClass method",
                    err
                )
            })
    }


    ChangeStandAlone = async (e) => {
        let section = this.state.sectionData
        const supplierId = section.id
        const standaloneMode = section.standAlone ? "true" : "false"
        await XSalesData.Suppliers.SetSupplierStandAlone(
            supplierId,
            standaloneMode
        )
            .then((data) => {
                //console.log("saved ok in method SetSupplierStandAlone", data)
                this.FillData()
            })
            .catch((err) => {
                console.log("error running SetSupplierStandAlone method", err)
            })

        // si el supplier es modo standalone, se le debe asignar el endpoint de manera automatica
        if (standaloneMode === "true") {
            //asignación del endpoint standalone
            const apiname = await API.endpoint("backendapi")
            let supplierEndpoint = {}
            supplierEndpoint.endpointSupplierApi = `${apiname}/standalone/${supplierId}`
            supplierEndpoint.endpointSupplierApiKey = ""
            supplierEndpoint.endpointAuthType = "0"
            supplierEndpoint.endpointAuthBasicUser = ""
            supplierEndpoint.endpointAuthBasicPass = ""

            //AQUI TENDRIA QUE ASIGNAR EL API KEY RESPECTIVO
            //DE MOMENTO SIN AUTH

            await XSalesData.Suppliers.SetSupplierEndpoint(
                supplierId,
                supplierEndpoint
            )
        }
    }
    ChangeLenguage = async (LanguageSelected) => {
        let section = this.state.sectionData

        await XSalesData.Suppliers.SetSupplierLenguage(
            section.id,
            LanguageSelected
        )
            .then((data) => {
                //console.log("saved ok in method SetSupplierStandAlone", data)
            })
            .catch((err) => {
                console.log("error running SetSupplierStandAlone method", err)
            })
    }

    CancelAssignStatus = () => {}

    ChangeAssignStatus = async (e) => {
        let section = this.state.sectionData
        let contract= this.state.contract
        let obj = {
            id: section.id,
            status: section.status,
        }
        await XSalesData.Suppliers.SetSupplierStatusChange(obj)
            .then(async (data) => {
                //console.log("change status ok", data)
                if (obj.status==="ACTIVE"){
                    //Asignar automaticamente el plan por defecto
                    await XSalesData.Suppliers.SetSupplierContract(obj.id, contract, "current")
                    .then((datacontract) => {

                    }).catch((err) => {
                        console.log("Contract creating error", err)
                    })
                }
                this.FillData()
            })
            .catch((err) => {
                console.log("change status error", err)
            })
    }

    CancelSupplierEndpoint = () => {}

    ChangeSupplierEndpoint = () => {
        let sdcendpoint = this.state.sectionDataSupplierEndpoint
        const supplierId = this.state.supplierIdSelected

        XSalesData.Suppliers.SetSupplierEndpoint(supplierId, sdcendpoint)
            .then((data) => {})
            .catch((err) => {
                console.log("ChangeSupplierEndpoint Error", err)
            })
    }

    FillData = async () => {
        let supplierId = this.props.match.params.id
        let standAloneMode =
            await XSalesData.Suppliers.GetSupplierStandaloneMode(supplierId)

        let orderManagement =
            await XSalesData.Suppliers.GetSupplierOrderManagement(supplierId)
       /*  let LenguageConfigured = await XSalesData.Suppliers.GetSupplierLanguage(
            supplierId
        ) */

        let ActivePromotions =
            await XSalesData.Suppliers.GetSupplierActivePromotions(supplierId)

        let ActiveListPrice =
            await XSalesData.Suppliers.GetSupplierListPriceMode(supplierId)

        let ActiveAdminProducts =
            await XSalesData.Suppliers.GetSupplierAdminProductsMode(supplierId)

        let ActiveMinimunStock =
            await XSalesData.Suppliers.GetSupplierMinimunStock(supplierId)

        let ActivebarcodeScanner =
            await XSalesData.Suppliers.GetSupplierBarcodeScanner(supplierId)

        let ActiveCustomerCatalog =
            await XSalesData.Suppliers.GetSupplierCheckCustomerCatalog(supplierId)

            let ActiveProductDiscount =
            await XSalesData.Suppliers.GetSupplierActiveProductDiscount(supplierId)

            let ActiveSameOrderClass =
            await XSalesData.Suppliers.GetSupplierActiveSameOrderClass(supplierId)

            let ActiveShippingAddress =
            await XSalesData.Suppliers.GetSupplierActiveShippingAddress(supplierId)
            
            let ActivePaymentMethod =
            await XSalesData.Suppliers.GetSupplierActivePaymentMethod(supplierId)

        await this.setState({ supplierIdSelected: supplierId })
        await XSalesData.Suppliers.GetSupplier(this.state.supplierIdSelected)
            .then((data) => {
                let objTemp = {}
                Object.assign(objTemp, data)
                //console.log("data about supplier", objTemp)
                
                //objTemp.createdAt = objTemp.createdAt
                //objTemp.approvedAt = objTemp.approvedAt

                if (standAloneMode === "true") {
                    objTemp.standAlone = true
                } else {
                    objTemp.standAlone = false
                }

                if (orderManagement === "true") {
                    objTemp.orderManagement = true
                } else {
                    objTemp.orderManagement = false
                }

                if (ActivePromotions === "true") {
                    objTemp.ActivePromotions = true
                } else {
                    objTemp.ActivePromotions = false
                }

                if (ActiveListPrice === "true") {
                    objTemp.ActiveListPrice = true
                } else {
                    objTemp.ActiveListPrice = false
                }

                if (ActiveAdminProducts === "true") {
                    objTemp.ActiveAdminProducts = true
                } else {
                    objTemp.ActiveAdminProducts = false
                }

                if (ActiveMinimunStock === "true") {
                    objTemp.ActiveMinimunStock = true
                } else {
                    objTemp.ActiveMinimunStock = false
                }

                if (ActivebarcodeScanner === "true") {
                    objTemp.ActivebarcodeScanner = true
                } else {
                    objTemp.ActivebarcodeScanner = false
                }

                if (ActiveCustomerCatalog === "true") {
                    objTemp.ActiveCustomerCatalog = true
                } else {
                    objTemp.ActiveCustomerCatalog = false
                }

                if (ActiveProductDiscount === "true") {
                    objTemp.ActiveProductDiscount = true
                } else {
                    objTemp.ActiveProductDiscount = false
                }
                
                if (ActiveSameOrderClass === "true") {
                    objTemp.ActiveSameOrderClass = true
                } else {
                    objTemp.ActiveSameOrderClass = false
                }
                
                if (ActiveShippingAddress === "true") {
                    objTemp.ActiveShippingAddress = true
                } else {
                    objTemp.ActiveShippingAddress = false
                }
                
                if (ActivePaymentMethod === "true") {
                    objTemp.ActivePaymentMethod = true
                } else {
                    objTemp.ActivePaymentMethod = false
                }

                this.setState({ sectionData: objTemp })
            })
            .catch((err) => {
                console.log("error component didmount detail.js", err)
            })
        await XSalesData.Suppliers.GetSupplierEndpoint(supplierId)
            .then((data) => {
                let sd = this.state.sectionDataSupplierEndpoint
                sd.endpointSupplierApi = data.CW007
                sd.endpointSupplierApiKey = data.CW008
                sd.endpointSupplierApiSectretKey = data.CW009 || ""
                sd.endpointAuthType = data.CW016
                sd.endpointAuthBasicUser = data.CW017 || ""
                sd.endpointAuthBasicPass = data.CW018 || ""
                this.setState({ sectionDataSupplierEndpoint: sd })
                //console.log("FillData in Detail", data)
            })
            .catch((err) => {
                console.log(err)
            })

        this.setState({ loadWindow: false })

        if(window.sessionStorage.getItem("pathRoot"))
        {
            await this.setState({ pathRoot: window.sessionStorage.getItem("pathRoot")})
        }

        let promotions = await XSalesData.Suppliers.GetSupplierBanner(supplierId)

        promotions = promotions?.map((data) => {
            const dataImg = XSalesConsumer.getImageUrlList({
                imageInternalName: data.imageInternalName,
                imageExternalURL: data.imageExternalURL,
                supplierId: supplierId,
                imageType: ImageUrlTypes.Types.banners,
            })
            return {
                ...data,
                imageUrl: dataImg.length > 0 ? dataImg.at(0) : "",
            }
        })

        this.setState({ promotions: promotions })
        

    }

    componentDidMount() {
        document.title = "Suppliers:Detail"
        this.FillData()
    }

    render() {
        return (
            <>
                {this.state.loadWindow ? (
                    <LoaderWindow />
                ) : (
                    <>
                    <BreadcrumbPage
                    pathLink={[{path:"/Admin"+this.state.pathRoot,linkName:"Suppliers"}]}
                ></BreadcrumbPage>
                        <Title label={this.state.sectionData.businessName} />
                        <Tab
                            options={
                                this.state.sectionData.standAlone
                                    ? this.state.tapOptions.filter(
                                          (item) =>
                                              item.id !== "lblConfigERPEndpoint"
                                      )
                                    : this.state.tapOptions
                            }
                        >
                            <TabCell show={true} id="lblGeneral">
                                <SeparateTop>
                                    <SupplierGeneral
                                        Data={this.state.sectionData}
                                        handleChange={this.handleChangeState}
                                        AllowEdit={false}
                                        RefreshData={this.FillData}
                                    />
                                </SeparateTop>
                            </TabCell>
                            <TabCell show={false} id="lblAdditional">
                                <SeparateTop>
                                    <SupplierAdditional
                                        Data={this.state.sectionData}
                                        handleChange={this.handleChangeState}
                                        handleChangeStateXSM={
                                            this.handleChangeStateXSM
                                        }
                                        CancelAssignStatus={
                                            this.CancelAssignStatus
                                        }
                                        ChangeAssignStatus={
                                            this.ChangeAssignStatus
                                        }
                                        CancelisXSM={this.CancelisXSM}
                                        ChangeisXSM={this.ChangeisXSM}
                                        AllowEdit={false}
                                        RefreshData={this.FillData}
                                        handleChangeStateStandAlone={
                                            this.handleChangeStateStandAlone
                                        }
                                        ChangeStandAlone={this.ChangeStandAlone}
                                        ChangeLenguage={this.ChangeLenguage}
                                        handleChangeOrderManagement={
                                            this.handleChangeOrderManagement
                                        }
                                        ChangeOrdenManagement={
                                            this.ChangeOrdenManagement
                                        }
                                        handleChangePromotions={
                                            this.handleChangePromotions
                                        }
                                        ChangePromotions={this.ChangePromotions}
                                        handleChangeListPrice={
                                            this.handleChangeListPrice
                                        }
                                        ChangeListPrice={this.ChangeListPrice}
                                        handleChangeAdminProducts={
                                            this.handleChangeAdminProducts
                                        }
                                        ChangeAdminProducts={
                                            this.ChangeAdminProducts
                                        }
                                        handleChangeMinimunStock={
                                            this.handleChangeMinimunStock
                                        }
                                        ChangeMinimunStock={
                                            this.ChangeMinimunStock
                                        }
                                        handleChangeBarcodeScanner = {this.handleChangeBarcodeScanner}
                                        ChangeBarcodeScanner = {this.ChangeBarcodeScanner}
                                        handleChangeCheckCustomerCatalog = {this.handleChangeCheckCustomerCatalog}
                                        ChangeCustomerCatalog = {this.ChangeCustomerCatalog}
                                        handleChangeProductDiscount = {this.handleChangeProductDiscount}
                                        ChangeActiveProductDiscount = {this.ChangeActiveProductDiscount}
                                        handleChangeSameOrderClass = {this.handleChangeSameOrderClass}
                                        ChangeActiveSameOrderClass = {this.ChangeActiveSameOrderClass}
                                        handleChangeShippingAddress = {this.handleChangeShippingAddress}
                                        ChangeActiveShippingAddress = {this.ChangeActiveShippingAddress}
                                        handleChangePaymentMethod = {this.handleChangePaymentMethod}
                                        ChangeActivePaymentMethod = {this.ChangeActivePaymentMethod}
                                />
                                </SeparateTop>
                            </TabCell>

                            <TabCell show={false} id="lblConfigERPEndpoint">
                                <SeparateTop>
                                    <SupplierEndpoint
                                        Data={
                                            this.state
                                                .sectionDataSupplierEndpoint
                                        }
                                        handleChange={
                                            this.handleChangeSupplierEndpoint
                                        }
                                        handleChangeEndpointAuthType={
                                            this.handleChangeEndpointAuthType
                                        }
                                        CancelSupplierEndpoint={
                                            this.CancelSupplierEndpoint
                                        }
                                        ChangeSupplierEndpoint={
                                            this.ChangeSupplierEndpoint
                                        }
                                        AllowEdit={false}
                                        RefreshData={this.FillData}
                                    />
                                </SeparateTop>
                            </TabCell>
                            <TabCell show={false} id="lblBanner">
                                <SeparateTop>

                                {Array.isArray(this.state.promotions) && (
                            <Carrousel
                                dataBanners={
                                    Array.isArray(this.state.promotions ) ? this.state.promotions : []
                                }
                                lblactive={I18n.get("lblactivebanner")}
                                lblinactive={I18n.get("lblinactivebanner")}
                                lblsince={I18n.get("lblsince")}
                                lbluntil={I18n.get("lbluntil")}
                                width="328px"
                                height="122px"
                            />
                        )}

                                    {/* <SupplierBanner
                                        Data={""}
                                        AllowEdit={false}
                                        RefreshData={this.FillData}
                                    /> */}
                                </SeparateTop>
                            </TabCell>
                            <TabCell show={false} id="lblContract">
                                <SeparateTop>
                                    <ContractDefinition />
                                </SeparateTop>
                            </TabCell>
                            <TabCell show={false} id="lblcurrency">
                                <SeparateTop>
                                    <CurrencyConfig modeAdmin={true} />
                                </SeparateTop>
                            </TabCell>
                        </Tab>
                    </>
                )}
            </>
        )
    }
}