import React, { useState, useEffect, useRef } from "react"
import { SectionEditable, InputText, SelectCombo, CheckBox, Switch, TimePicker } from 'xsales-components'
import { useParams } from "react-router-dom"
import { XSalesData } from "../../DataQuery/XSalesData"
import { I18n } from "aws-amplify"
import * as db from "../../models"
import { configTimezones } from "../../configConsumer/timeZones"
import { XSalesConsumer } from "../../Utils/Utils"

export default function DeliveryDays(props) {
    const refSwitch = useRef(null)
    let { id } = useParams()
    const allowEdit =
        props.AllowEdit === null || props.AllowEdit === undefined
            ? true
            : props.AllowEdit

    const [days, setDays] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        sameDayDelivery: false,
        sameDayDeliveryUntil: "",
        sameDayDeliveryTimeZone: "",
    })

    /* eslint-disable no-unused-vars */
    const [daysTimezones, setDaysTimezones] = useState(
        configTimezones.map((item) => {
            return { label: item, value: item }
        })
    )
    /* eslint-disable no-unused-vars */

    const CancelAssignDeliveryDays = (e) => {
        props.CancelAssignDeliveryDays(e)
        setUpdateChangeDeliveryDays(false)
        props.RefreshData()
    }

    const ChangeAssignDeliveryDays = (e) => {
        if (days.sameDayDelivery === true) {
            if (
                days.sameDayDeliveryUntil === undefined ||
                days.sameDayDeliveryUntil === null ||
                days.sameDayDeliveryUntil === ""
            ) {
                XSalesConsumer.fireMessage(
                    I18n.get("lblDeliveryDays"),
                    I18n.get("msgFillDeliveryUntil")
                )
                props.RefreshData()
                setUpdateChangeDeliveryDays(false)
                return
            }
            if (
                days.sameDayDeliveryTimeZone === undefined ||
                days.sameDayDeliveryTimeZone === null ||
                days.sameDayDeliveryTimeZone === ""
            ) {
                XSalesConsumer.fireMessage(
                    I18n.get("lblDeliveryDays"),
                    I18n.get("msgFillDeliveryTimezone")
                )
                props.RefreshData()
                setUpdateChangeDeliveryDays(false)
                return
            }
        }

        let objDaysDelivery = new db.DaysDelivery({
            monday: days.monday,
            tuesday: days.tuesday,
            wednesday: days.wednesday,
            thursday: days.thursday,
            friday: days.friday,
            saturday: days.saturday,
            sunday: days.sunday,
            sameDayDelivery: days.sameDayDelivery,
            sameDayDeliveryUntil: days.sameDayDeliveryUntil,
            sameDayDeliveryTimeZone: days.sameDayDeliveryTimeZone,
        })
        props.ChangeAssignDeliveryDays(objDaysDelivery)
        setUpdateChangeDeliveryDays(false)
    }

    const [updateChangeDeliveryDays, setUpdateChangeDeliveryDays] = useState(
        false
    )

    const UpdateActionButtonDeliveryDays = () => {
        setUpdateChangeDeliveryDays(!updateChangeDeliveryDays)
    }

    const handleChangeDays = async (e) => {
        let target = e.target
        let name = target.name
        let daysTemp = {}
        Object.assign(daysTemp, days)
        daysTemp[name] = target.checked
        setDays(daysTemp)
    }

    const handleChangeSameDayUntil = async ({ date, oldDate }) => {
        // console.log("New date", date.format("HH:mm"));
        // console.log("Old date", oldDate.format("HH:mm"));
        const newDate = date.format("HH:mm")
        let daysTemp = {}
        Object.assign(daysTemp, days)
        daysTemp["sameDayDeliveryUntil"] = newDate
        setDays(daysTemp)
    }

    const handleChangeTimeZone = async (e) => {
        let daysTemp = {}
        Object.assign(daysTemp, days)
        //console.log("handleChangeTimeZone", e)
        daysTemp["sameDayDeliveryTimeZone"] = e.value
        setDays(daysTemp)
    }

    const loadDeliveryDaysBySupplierConsumer = (SupplierConsumerId) => {
        // console.log("loadDeliveryDaysBySupplierConsumer execution")
        XSalesData.Consumer.GetSupplierConsumerByIds(SupplierConsumerId)
            .then((data) => {
                // console.log(
                //     "loadDeliveryDaysBySupplierConsumer data then",
                //     data
                // )
                if (data.daysDelivery) {
                    setDays(data.daysDelivery)
                    if(refSwitch){   
                        refSwitch.current.checked = data.daysDelivery.sameDayDelivery
                    }
                }
            })
            .catch((err) => {
                console.log("error loading Delivery Days for SupplierConsumer")
            })
    }

    useEffect(() => {
        loadDeliveryDaysBySupplierConsumer(id)
    }, [id])

    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <InputText
                        id="status"
                        name="status"
                        label={I18n.get("lblconStatus")}
                        maxLength="20"
                        value={props.Data.sectionData.status}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <InputText
                        id="ERPCode"
                        name="ERPCode"
                        label={I18n.get("lblconERPCode")}
                        maxLength="20"
                        value={props.Data.sectionData.erpCode}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <InputText
                        id="name"
                        name="name"
                        label={I18n.get("lblconname")}
                        maxLength="20"
                        value={props.Data.sectionData.name}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-sm-12 col-md-12">
                    <SectionEditable
                        label={I18n.get("lblDelivery")}
                        labelEdit={I18n.get("lblDelivery")}
                        Action={"UPDATE"}
                        UpdateAction={UpdateActionButtonDeliveryDays}
                        CancelAction={CancelAssignDeliveryDays}
                        SaveAction={ChangeAssignDeliveryDays}
                        Collapse={false}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                    >
                        <div className="row">
                            <div className="col-sm-6">
                                <CheckBox
                                    id="monday"
                                    name="monday"
                                    value={days.monday}
                                    onChange={handleChangeDays}
                                    label={I18n.get("lblMonday")}
                                    isEditable={updateChangeDeliveryDays}
                                />

                                <CheckBox
                                    id="tuesday"
                                    name="tuesday"
                                    value={days.tuesday}
                                    onChange={handleChangeDays}
                                    label={I18n.get("lblTuesday")}
                                    isEditable={updateChangeDeliveryDays}
                                />

                                <CheckBox
                                    id="wednesday"
                                    name="wednesday"
                                    value={days.wednesday}
                                    onChange={handleChangeDays}
                                    label={I18n.get("lblWednesday")}
                                    isEditable={updateChangeDeliveryDays}
                                />

                                <CheckBox
                                    id="thursday"
                                    name="thursday"
                                    value={days.thursday}
                                    onChange={handleChangeDays}
                                    label={I18n.get("lblThursday")}
                                    isEditable={updateChangeDeliveryDays}
                                />

                                <CheckBox
                                    id="friday"
                                    name="friday"
                                    value={days.friday}
                                    onChange={handleChangeDays}
                                    label={I18n.get("lblFriday")}
                                    isEditable={updateChangeDeliveryDays}
                                />

                                <CheckBox
                                    id="saturday"
                                    name="saturday"
                                    value={days.saturday}
                                    onChange={handleChangeDays}
                                    label={I18n.get("lblSaturday")}
                                    isEditable={updateChangeDeliveryDays}
                                />

                                <CheckBox
                                    id="sunday"
                                    name="sunday"
                                    value={days.sunday}
                                    onChange={handleChangeDays}
                                    label={I18n.get("lblSunday")}
                                    isEditable={updateChangeDeliveryDays}
                                />
                            </div>

                            <div className="col-sm-6">
                                <Switch
                                    setRef={refSwitch}
                                    id="sameDayDelivery"
                                    name="sameDayDelivery"
                                    label={I18n.get("lblSameOrderDay")}
                                    onChange={handleChangeDays}
                                    value={days.sameDayDelivery}
                                    isEditable={updateChangeDeliveryDays}
                                />
                                <TimePicker
                                    label="Time Picker"
                                    id="sameDayDeliveryUntil"
                                    name="sameDayDeliveryUntil"
                                    value={days.sameDayDeliveryUntil}
                                    Label={I18n.get("lblSameOrderDayUntil")}
                                    onChange={handleChangeSameDayUntil}
                                    isEditable={
                                        updateChangeDeliveryDays &&
                                        days.sameDayDelivery
                                    }
                                />

                                <SelectCombo
                                    id="sameDayDeliveryTimeZone"
                                    name="sameDayDeliveryTimeZone"
                                    label={I18n.get("lblsameDayDeliveryTimeZone")}
                                    options={daysTimezones}
                                    optionSelected={days.sameDayDeliveryTimeZone}
                                    value={days.sameDayDeliveryTimeZone}
                                    onChange={handleChangeTimeZone}
                                    isEditable={updateChangeDeliveryDays && days.sameDayDelivery}
                                    defaultValue={""}
                                    />
                            </div>
                        </div>
                    </SectionEditable>
                </div>
            </div>
           {/*  {
                 <pre>
                     {
                       JSON.stringify(days, 1, 1)
                   }
                 </pre>
            }    */}
        </>
    )
}
