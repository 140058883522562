import axios from "axios"
import { XSalesData } from "../DataQuery/XSalesData"
//import { XSalesConsumer } from "../Utils/Utils"
import MailerConfig from "../configConsumer/mailer-config.json"

export default class MailerServices {
    constructor(supplierSetups) {
        //this.supplierId = supplierId
        this.supplierSetups = supplierSetups
    }

    // checkEmailServiceDisable = async () => {
    //     let flag = false
    //     try {
    //         const result = await XSalesData.Setup.getById("SY011")
    //         if (result != null) {
    //             flag = result.value
    //         }
    //     } catch (err) {
    //         console.log(err)
    //     }
    //     return flag
    // }
    checkEmailServiceDisableSystem = async () => {
        let flag = false
        try {
            const result = await XSalesData.Setup.getById("SY011")
            if (result != null) {
                flag = result.value
            }
        } catch (err) {
            console.log(err)
        }
        return flag
    }
    checkEmailServiceDisableSupplier = async () => {
        let flag = false
        try {
            const result = XSalesData.Suppliers.GetSetupValue(this.supplierSetups, XSalesData.Constants.Setup.SY011, false, "false")
            if (result != null) {
                flag = result
            }
        } catch (err) {
            console.log(err)
        }
        return flag
    }

    emailNotification = async (emailDTO) => {
        //console.log("parameter received", token, title, body, data)
        // const isEmailServiceDisableSystem = await this.checkEmailServiceDisableSystem()
        // const isEmailServiceDisableSupplier = await this.checkEmailServiceDisableSupplier()
        // if (XSalesConsumer.isTrue(isEmailServiceDisableSystem) || XSalesConsumer.isTrue(isEmailServiceDisableSupplier)) {
        //     console.warn("WARNING: Email services disable")
        //     return
        // }

        const headers = {
            Accept: "*/*",
            "Content-Type": "application/json",
        }

        const bodyToSend = JSON.stringify({
            TO: emailDTO.TO,
            CC: emailDTO.CC,
            TemplateName: emailDTO.TemplateName,
            TemplateData: emailDTO.TemplateData,
        })

        try {
            const aresult = await axios({
                method: "POST",
                url: MailerConfig.URL, // "https://ic5rvfb5vj.execute-api.us-east-2.amazonaws.com/Prod/email/",
                headers: headers,
                data: bodyToSend,
            })
            console.log(aresult)

            // let response = axios
            //     .request(reqOptions)
            //     .then(function (response) {
            //         console.log(response)
            //     })
            //     .catch(function (error) {
            //         console.log(error)
            //     })
        } catch (err) {
            console.log(`ERROR emailNotification: ${JSON.stringify(err)}`)
            return { err }
        }
    }

    emailApproveRequestNotification = async (emailDTO, supplierName, consumerName) => {
        emailDTO.TemplateName = "consumer-consumer-approved-es"
        emailDTO.TemplateData = {
            supplier: supplierName,
            consumer: consumerName,
        }
        return this.emailNotification(emailDTO)
        //console.log("parameter received", token, title, body, data)
    }
}
