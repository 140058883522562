import React from "react"
import ReactDOM from "react-dom"
import "xsales-components/dist/assets/bootstrap/css/index.css"
import "./assets/admin-lte/plugins/fontawesome-free/css/all.min.css"
import "./assets/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css"
import "./assets/admin-lte/dist/css/adminlte.css"
import "./index.css"
import "./assets/css/custom.css"
import "./assets/css/responsive.css" 
import App from "./App"
// import * as serviceWorker from "../public/serviceWorker"

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
// const registeredServiceWorker = sw.register("/sw.js", "/")
// serviceWorker.enablePush("BLx__NGvdasMNkjd6VYPdzQJVBkb2qafh")
