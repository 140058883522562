import React, { useEffect } from 'react'
import '../../../assets/css/dbStatement.css'
import CurrentMonth from './CurrentMonth'
import ActivityHistorySupplier from './ActivityHistorySupplier'
import SummarySupplier from './SummarySupplier'

const DashboardStatement = (props) => {

    useEffect(() => {

    }, [])

    return <div className="statement__container__dashboard">
        <CurrentMonth />
        <ActivityHistorySupplier />
        <SummarySupplier /> 
    </div>
}

export default DashboardStatement;