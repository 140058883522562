//import moment from 'moment'
import React,{useState} from 'react'
import { InputNumber, Button, SelectCombo } from 'xsales-components'
import '../../assets/css/cardPlan.css'
import { I18n } from "@aws-amplify/core" 
//import { useEffect } from 'react'




//{ id, title,sinceValue,handleClickCard,handleClickEditIcon, feeValue, minTransactionValue, planValue, handleChange, handleChangeSelect, handleClick, isCurrent, isFuture, isCustom, isEditable, planDescriptions = [], planSelect }
export default function CardPlan (props) {

   
    const [planSelected, setPlanSelected] = useState(props.planSelect)
    const [feesSelected, setFeesSelected] = useState(props.feeValue)
    const [minTransaction, setMinTransaction] = useState(props.minTransactionValue)

   /*  const [formValues, setFormValues] = useState({
        fees: props.feeValue,
        minTransaction: props.minTransactionValue,
        planSelected: props.planSelect
    }) */

      
    const  handleChangeSelect=(e)=>{
        setPlanSelected(e.value)
        props.handleChangeSelect(e)
    }

    const  handleChange = (e) => {
        const element = e.target;
        switch (element.name) {
            case "fee-input":
                setFeesSelected(Number(element.value))
                props.handleChange(e)
                
                break;
            case "min-transaction-input":
                setMinTransaction(Number(element.value))
                props.handleChange(e)
                
                break;
                
            default:
                break;
        }
    }

 return (
    <div className={`card__plan__wrapper ${props.isCurrent && "card__plan__selected"} ${props.isFuture && "card__plan__future"}`} onClick={props.handleClickCard} data-plan={props.planValue}>
        {
            (!props.isEditable && props.isCustom) &&
            <i id={`${props.id}-edit`} className="fas fa-edit card__plan__icon--edit" onClick={props.handleClickEditIcon}></i>
        }
        {
            props.isCurrent &&
            <i className="fas fa-check-circle card__plan__icon--check"></i>
        }

        <div className={`card__plan__section__one ${!props.isEditable && "card__plan__color--gray"} ${props.isFuture && "card__plan__future__section"}`}>
            {
                props.isEditable ?
                    <>
                        <p className="card__plan--title">{props.title}</p>
                        <SelectCombo
                            isEditable={props.isEditable}
                            id={`plan-select`}
                            label="Plan"
                            name={`plan-select`}
                            onChange={handleChangeSelect}
                            value={planSelected}
                            options={[
                                { label: "SAP ECC or S/4HANA Integration", value: "SAP ECC or S/4HANA Integration" },
                                { label: "No ERP Integration", value: "No ERP Integration" }
                            ]}
                        />

                    </>
                    :
                    <>
                        {
                            (props.isFuture ===  true) ?
                                <>
                                    <p className="card__plan--title">{props.title}</p>
                                    <p className="card__plan--title" style={{ fontSize: "1rem" }}>{props.planValue}</p>
                                </>
                                :
                                <p className="card__plan--title">{props.planValue}</p>
                        }
                    </>
            }
            {
                props.isEditable ?
                    <div className="mt-4">
                        <InputNumber
                            id={`fee-input`}
                            name={`fee-input`}
                            label={"Fee"}
                            labelDefault="Fee"
                            maxLength="20"
                            value={feesSelected}
                            onChange={handleChange}
                            isEditable={props.isEditable}
                        />
                    </div>
                    :
                    <p className="card__plan--price">{Number(props.feeValue).toFixed(2)}</p>
            }

            <p className="card__plan--price"><small>{I18n.get("lblusdtransactions")}</small></p>

            <p className="card__plan--price">{Number(props.setupValue).toFixed(2)}</p>
            <p className="card__plan--price"><small>{I18n.get("lblsetupfreetransactions")}</small></p>
            {
                props.isFuture &&
                <>        
                    <p className="card__plan--price"><small>{I18n.get("lblmintransactionscard")} {Number(props.minTransactionValue).toFixed(2)}</small></p>
                    <p className="card__plan--price"><small>{I18n.get("lbleffectiveplan")}  <strong>{props.sinceValue}</strong></small></p>
                </>
            }
        </div>
        {
           
            <div className="card__plan_section__two">
                {
                    props.isEditable ?
                        <>
                            <InputNumber
                                id={`min-transaction-input`}
                                name={`min-transaction-input`}
                                label={I18n.get("lblmintransactions")}
                                labelDefault="Min Transaction"
                                maxLength="20"
                                value={minTransaction}
                                onChange={handleChange}
                                isEditable={props.isEditable}
                            />
                            <div className="card__plan__buttons">
                                <Button
                                    id="btnCancel"
                                    label={I18n.get("lblCancel")}
                                    name="btnCancel"
                                    type="button"
                                    className="ml-2"
                                    outline={true}
                                    size='md'
                                    color='default'
                                    onClick={props.handleClick}
                                />
                                <Button
                                    id="btnSave"
                                    label={I18n.get("lblSave")}
                                    name="btnSave"
                                    type="button"
                                    className="ml-2"
                                    outline={true}
                                    size='md'
                                    color='secondary'
                                    onClick={props.handleClick}
                                />
                            </div>
                        </>
                        :
                        <ul className="card__plan--list">
                            {
                                props.planDescriptions.map((text) => { 
                                    if (text==="lblmintransactionsdesccard"){
                                        text= I18n.get(text).replace("{min}",props.minTransactionValue);
                                        text= I18n.get(text).replace("{max}",props.maxTransactionValue);
                                    }else{
                                        text= I18n.get(text)
                                    }
                                    return <li>{text}</li>
                                })
                            }
                            <li>{I18n.get("lblBilledfreetransactions")}</li>
                            <li>{I18n.get("lblHostedawsusa")}</li>
                        </ul>
                }
            </div>
        }

    </div>
)
}