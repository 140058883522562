import React from "react"
import {
    InputEmail,
    Button,
    InputText,
    TextArea,
    Autocomplete,
} from "xsales-components"
import Loader from "../common/loader"

import { XSalesConsumer } from "../../Utils/Utils"
import { XSalesData } from "../../DataQuery/XSalesData"
import csc from "country-state-city"

import { I18n } from "@aws-amplify/core"
import ImageCDN from "../../configConsumer/imagesDefaultCDN.json"
import $ from "jquery"

import "../../assets/css/registration.css"

import * as db from "../../models"
class Registration extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            country: [],
            state: [],
            city: [],
            sectionFallback: {
                value: "",
                errored: false,
                message: "",
            },
            sectionDataUser: {
                username: "",
                name: "",
                lastName: "",
                alternateEmail: "",
                lastSessionDate: "",
                lastIP: "",
                lastOS: "",
                lastDevice: "",
                lastBrowser: "",
                email: "",
                userType: "2", //register as supplier
            },
            sectionDataSupplier: {
                email: "",
                businessName: "",
                username: "",
                name: "",
                taxID: "",
                summary: "",
                imageInternalName: [],
                contactName: "",
                contactEmail: "",
                currencyISO: "USD",
                currencySymbol: "$",
                address: {
                    type: "MAIN",
                    street1: "",
                    street2: "",
                    street3: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    country: "",
                    countryId: "",
                    cityId: "",
                    stateId: "",
                    default: true,
                },
                phones: [
                    {
                        number: "",
                        default: true,
                        type: "WORK",
                    },
                    {
                        number: "",
                        default: false,
                        type: "MOBILE",
                    },
                ],
                website: "",
                status: "",
                latitude: 0,
                longitude: 0,
                isXSM: false,
            },
            showLoader: false,
            userSaved: false,
            supplierSaved: false,
        }
    }

    onError = (ev) => {
        let imgFallback = this.state.sectionFallback
        let sectionDataSupplier = this.state.sectionDataSupplier
        imgFallback.message = ""
        if (!imgFallback.errored) {
            imgFallback.errored = true
            imgFallback.message = I18n.get("lblmessageImgError")
            imgFallback.value = ImageCDN.SupplierBannerDefault
            this.setState({
                sectionFallback: imgFallback,
                sectionDataSupplier: sectionDataSupplier,
            })
        }
        this.setState({
            sectionFallback: imgFallback,
        })
    }

    onErrorCheck = () => {
        let imgFallback = this.state.sectionFallback
        let sectionDataSupplier = this.state.sectionDataSupplier
        imgFallback.message = ""
        if (imgFallback.errored === true) {
            sectionDataSupplier.imageUrl = ""
            imgFallback.message = I18n.get("lblmessageImgError")
            imgFallback.errored = false
            this.setState({
                sectionFallback: imgFallback,
                sectionDataSupplier: sectionDataSupplier,
            })
        }
        this.setState({
            sectionFallback: imgFallback,
        })
    }

    countryList = async () => {
        return await csc.getAllCountries().map((item) => {
            return {
                label: item.name,
                value: item.id,
            }
        })
    }

    stateList = async (countryCode) => {
        return await csc.getStatesOfCountry(countryCode).map((item) => {
            return {
                label: item.name,
                value: item.id,
            }
        })
    }

    cityList = async (stateCode) => {
        return await csc.getCitiesOfState(stateCode).map((item) => {
            return {
                label: item.name,
                value: item.id,
            }
        })
    }

    handleChangeCountry = async (e) => {
        await this.setState({ state: [], city: [] })
        let statesByCountry = await this.stateList(e.value)
        let sectionDataSupplier = this.state.sectionDataSupplier
        sectionDataSupplier.address.country = e.label
        sectionDataSupplier.address.countryId = e.value
        sectionDataSupplier.address.state = ""
        sectionDataSupplier.address.stateId = ""
        sectionDataSupplier.address.city = ""
        sectionDataSupplier.address.cityId = ""
        this.setState({
            state: statesByCountry,
            sectionDataSupplier: sectionDataSupplier,
        })
    }

    handleChangeState = async (e) => {
        await this.setState({ city: [] })
        let cityByState = await this.cityList(e.value)
        let sectionDataSupplier = this.state.sectionDataSupplier
        sectionDataSupplier.address.state = e.label
        sectionDataSupplier.address.stateId = e.value
        sectionDataSupplier.address.city = ""
        sectionDataSupplier.address.cityId = ""
        this.setState({
            city: cityByState,
            sectionDataSupplier: sectionDataSupplier,
        })
    }

    handleChangeCity = (e) => {
        let sectionDataSupplier = this.state.sectionDataSupplier
        sectionDataSupplier.address.city = e.label
        sectionDataSupplier.address.cityId = e.value
        this.setState({
            sectionDataSupplier: sectionDataSupplier,
        })
    }

    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((data) => {
                const { latitude, longitude } = data.coords
                let sds = this.state.sectionDataSupplier
                sds.latitude = latitude
                sds.longitude = longitude
                this.setState({ sectionDataSupplier: sds })
            })
        }
    }

    componentDidMount() {
        XSalesConsumer.getCurrentUser()
            .then((user) => {
                let sdataSupplier = this.state.sectionDataSupplier
                sdataSupplier.username = user.username
                sdataSupplier.email = user.email.trim().toLowerCase()
                sdataSupplier.status = db.StatusSupplier.VALIDATING
                let sdataUser = this.state.sectionDataUser
                sdataUser.username = user.username
                sdataUser.email = user.email.trim().toLowerCase()
                sdataUser.userType = "2" // register as supplier
                this.countryList()
                    .then((data) => {
                        this.setState({
                            sectionDataSupplier: sdataSupplier,
                            sectionDataUser: sdataUser,
                            country: data,
                        })
                        this.getLocation()
                    })
                    .catch((err) => {
                        console.log("writting error in componentDidMount", err)
                    })
            })
            .catch((err) => {
                console.log("error in componentdidmount registration.js", err)
                throw err
            })
    }

    handleChangeSupplier = (e) => {
        let field = e.target.name
        let value = e.target.value
        let supplier = this.state.sectionDataSupplier

        if (field === "imageInternalName") {
            supplier.imageUrl = value
            let imgFallback = this.state.sectionFallback
            imgFallback.value = value
            imgFallback.errored = false
            this.setState({
                sectionFallback: imgFallback,
            })
        }

        supplier[field] = value
        this.setState({
            sectionDataSupplier: supplier,
        })
    }

    handleChangeUserData = (e) => {
        const { name, value } = e.target
        let data = this.state.sectionDataUser
        if (name === "nameUser") {
            data["name"] = value
        } else if (name === "lastNameUser") {
            data["lastName"] = value
        }

        this.setState({
            sectionDataUser: data,
        })
    }

    handleChangeSupplierAddress = (e) => {
        let field = e.target.name
        let value = e.target.value
        let add = this.state.sectionDataSupplier
        add.address[field] = value
        this.setState({
            sectionDataSupplier: add,
        })
    }

    handleChangeSupplierPhone = (e) => {
        let field = e.target.name
        let value = e.target.value
        let ph = this.state.sectionDataSupplier
        if (field === "officeNumber") {
            ph.phones[0].number = value
        } else if (field === "mobileNumber") {
            ph.phones[1].number = value
        }
        this.setState({
            sectionDataSupplier: ph,
        })
    }

    sendDataBack = async () => {
        window.location.href = "/SignIn"
    }

    validateField = (field, fieldName, label) => {
        if (
            field === undefined ||
            field === null ||
            field === "" ||
            field.trim() === ""
        ) {
            $("#" + fieldName)
                .addClass("is-invalid")
                .removeClass("is-valid")
                .focus()
            // $("#" + fieldName).invalid()
            throw new Error(label).message
        } else {
            $("#" + fieldName)
                .addClass("is-valid")
                .removeClass("is-invalid")
        }
    }

    validateFormRegistration = () => {
        let allFieldsIsOK = true
        //method for validate all fields in form of registration
        let sd = this.state.sectionDataSupplier
        let sdu = this.state.sectionDataUser
        try {
            this.validateField(
                sd.businessName,
                "businessName",
                I18n.get("lblbusinessname")
            )
            this.validateField(sd.name, "name", I18n.get("lblcomercialname"))
            this.validateField(sd.taxID, "taxID", I18n.get("lbltaxid"))
            this.validateField(sd.website, "website", I18n.get("lblwebsite"))
            // this.validateField(sd.imageUrl, "imageUrl", I18n.get("lblimageUrl")) XSMCONSUME-341
            this.validateField(
                sd.summary,
                "summary",
                I18n.get("lblsummarybusiness")
            )
            this.validateField(
                sd.address.country,
                "country",
                I18n.get("lblCountry")
            )
            this.validateField(sd.address.state, "state", I18n.get("lblstate"))
            if (this.state.city.length > 0) {
                this.validateField(sd.address.city, "city", I18n.get("lblcity"))
            }
            this.validateField(
                sd.address.zipcode,
                "zipcode",
                I18n.get("lblzipcode")
            )
            this.validateField(
                sd.address.street1,
                "street1",
                I18n.get("lblstreet1")
            )
            // this.validateField(
            //     sd.address.street2,
            //     "street2",
            //     I18n.get("lblstreet2")
            // ) XSMCONSUME-341
            // this.validateField(
            //     sd.address.street3,
            //     "street3",
            //     I18n.get("lblstreet3")
            // ) XSMCONSUME-341
            this.validateField(
                sd.phones[0].number,
                "officeNumber",
                I18n.get("lblofficeNumber")
            )
            this.validateField(
                sd.phones[1].number,
                "mobileNumber",
                I18n.get("lblmobileNumber")
            )
            this.validateField(
                sd.contactName,
                "contactName",
                I18n.get("lblcontactName")
            )
            this.validateField(
                sd.contactEmail,
                "contactEmail",
                I18n.get("lblEmail")
            )
            this.validateField(sdu.name, "name", I18n.get("lblname"))
            this.validateField(
                sdu.lastName,
                "lastName",
                I18n.get("lbllastName")
            )
        } catch (error) {
            allFieldsIsOK = false
            throw new Error(error).message
        }

        return allFieldsIsOK
    }

    sendDataForRegistration = async () => {
        this.setState({ showLoader: true })
        //let arrImage= [];
        try {
            const data = this.validateFormRegistration()
            if (data!==undefined && data!== null) {
                    //all fields is OK
                    //Se modifica user para permitir indicar el name y lastName de la persona
                    await XSalesData.User.SetUser(this.state.sectionDataUser)
                        .then(async (data) => {
                            debugger
                            this.setState({ userSaved: true })
                            if (this.state.sectionDataSupplier.imageInternalName.length>0){
                                let sectionDataSupplier = this.state.sectionDataSupplier;
                                sectionDataSupplier.imageInternalName = new Array(this.state.sectionDataSupplier.imageInternalName)
                                this.setState({
                                    sectionDataSupplier: sectionDataSupplier,
                                })
                            }
                            await XSalesData.Suppliers.SetSupplier(
                                this.state.sectionDataSupplier
                            )
                                .then((sup) => {
                                    localStorage.clear("ConsumerUser")
                                    this.setState({
                                        supplierSaved: true,
                                        showLoader: false,
                                    })
                                    XSalesConsumer.fireMessage(
                                        "lblRegister",
                                        "msgRegisterOk"
                                    )
                                    window.location.href = "/SignIn"
                                })
                                .catch((err) => {
                                    this.setState({
                                        supplierSaved: false,
                                        showLoader: false,
                                    })
                                    console.log(err)
                                })
                        })
                        .catch((err) => {
                            debugger
                            console.log(err)
                            this.setState({ userSaved: false, showLoader: false })
                            XSalesConsumer.fireMessage(
                                "lblLogin",
                                "msgErrorSavingData"
                            )
                        })
            } else {
                    //error validating all fields
                throw new Error(
                    "Error validating Form for Register a Supplier"
                )
            }
            
        } catch (error) {
            XSalesConsumer.fireMessage(`error: ${error}`, "")
            this.setState({
                showLoader: false,
            })
            return
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.sendDataForRegistration()
    }

    render() {
        return (
            <div className="registration__wrapper">
                <div className="registration__container">
                    <img
                        src={ImageCDN.XSalesConsumerWebLogo}
                        alt="logo Consumer"
                        className="logoConsumerCenter logoConsumerWeb registration__logo"
                    />
                    <p className="registration__title">
                        {I18n.get("lblCreateAccount")}
                    </p>

                    <div className="row__container--registration">
                        <form onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <InputText
                                        id="businessName"
                                        name="businessName"
                                        required={true}
                                        maxLength="60"
                                        requiredField={true}
                                        label={I18n.get("lblbusinessname")}
                                        value={
                                            this.state.sectionDataSupplier
                                                .businessName
                                        }
                                        onChange={this.handleChangeSupplier}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <InputText
                                        id="name"
                                        name="name"
                                        required={true}
                                        requiredField={true}
                                        maxLength="60"
                                        label={I18n.get("lblcomercialname")}
                                        value={
                                            this.state.sectionDataSupplier.name
                                        }
                                        onChange={this.handleChangeSupplier}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-4">
                                    <InputText
                                        id="taxID"
                                        name="taxID"
                                        required={true}
                                        requiredField={true}
                                        maxLength="12"
                                        label={I18n.get("lbltaxid")}
                                        value={
                                            this.state.sectionDataSupplier.taxid
                                        }
                                        onChange={this.handleChangeSupplier}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <InputText
                                        id="website"
                                        name="website"
                                        required={true}
                                        maxLength="60"
                                        requiredField={true}
                                        label={I18n.get("lblwebsite")}
                                        value={
                                            this.state.sectionDataSupplier
                                                .website
                                        }
                                        onChange={this.handleChangeSupplier}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <InputText
                                        id="imageInternalName"
                                        name="imageInternalName"
                                        required={false}
                                        maxLength="300"
                                        label={I18n.get("lblimageUrl")}
                                        value={
                                            this.state.sectionDataSupplier
                                                .imageInternalName
                                        }
                                        onChange={this.handleChangeSupplier}
                                        onBlur={this.onErrorCheck}
                                        messageError={
                                            this.state.sectionFallback.message
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <h5>{I18n.get("lblSummary")}</h5>
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <TextArea
                                        rows={3}
                                        id="summary"
                                        name="summary"
                                        required={true}
                                        requiredField={true}
                                        maxLength="300"
                                        label={I18n.get("lblsummarybusiness")}
                                        value={
                                            this.state.sectionDataSupplier
                                                .summary
                                        }
                                        onChange={this.handleChangeSupplier}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-8">
                                    <div className="col-sm-12">
                                        <h5>{I18n.get("lblAddress")}</h5>
                                        <hr />
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <Autocomplete
                                                    id="country"
                                                    name="country"
                                                    required={true}
                                                    label={I18n.get(
                                                        "lblCountry"
                                                    )}
                                                    options={this.state.country}
                                                    onChange={
                                                        this.handleChangeCountry
                                                    }
                                                    isEditable={true}
                                                />
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <Autocomplete
                                                    id="state"
                                                    name="state"
                                                    required={true}
                                                    label={I18n.get("lblstate")}
                                                    options={this.state.state}
                                                    onChange={
                                                        this.handleChangeState
                                                    }
                                                    isEditable={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <Autocomplete
                                                    id="city"
                                                    name="city"
                                                    required={
                                                        this.state.city.length >
                                                        0
                                                    }
                                                    label={I18n.get("lblcity")}
                                                    options={this.state.city}
                                                    onChange={
                                                        this.handleChangeCity
                                                    }
                                                    isEditable={
                                                        this.state.city.length >
                                                        0
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-12 col-md-6 mt-4">
                                                <InputText
                                                    id="zipcode"
                                                    name="zipcode"
                                                    required={true}
                                                    requiredField={true}
                                                    maxLength="20"
                                                    label={I18n.get(
                                                        "lblzipcode"
                                                    )}
                                                    value={
                                                        this.state
                                                            .sectionDataSupplier
                                                            .address.zipcode
                                                    }
                                                    onChange={
                                                        this
                                                            .handleChangeSupplierAddress
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-4">
                                                        <TextArea
                                                            id="street1"
                                                            rows={4}
                                                            name="street1"
                                                            required={true}
                                                            requiredField={true}
                                                            maxLength="150"
                                                            label={I18n.get(
                                                                "lblstreet1"
                                                            )}
                                                            value={
                                                                this.state
                                                                    .sectionDataSupplier
                                                                    .address
                                                                    .street1
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChangeSupplierAddress
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-sm-12 col-md-4">
                                                        <TextArea
                                                            id="street2"
                                                            rows={4}
                                                            name="street2"
                                                            required={false}
                                                            maxLength="150"
                                                            label={I18n.get(
                                                                "lblstreet2"
                                                            )}
                                                            value={
                                                                this.state
                                                                    .sectionDataSupplier
                                                                    .address
                                                                    .street2
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChangeSupplierAddress
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-sm-12 col-md-4">
                                                        <TextArea
                                                            id="street3"
                                                            rows={4}
                                                            name="street3"
                                                            required={false}
                                                            maxLength="150"
                                                            label={I18n.get(
                                                                "lblstreet3"
                                                            )}
                                                            value={
                                                                this.state
                                                                    .sectionDataSupplier
                                                                    .address
                                                                    .street3
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChangeSupplierAddress
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="col-sm-12">
                                        <h5>{I18n.get("lblPhone")}</h5>
                                        <hr />
                                    </div>
                                    <div className="col-sm-12">
                                        <InputText
                                            id="officeNumber"
                                            name="officeNumber"
                                            required={true}
                                            requiredField={true}
                                            maxLength="15"
                                            label={I18n.get("lblofficeNumber")}
                                            value={
                                                this.state.sectionDataSupplier
                                                    .phones[0].number
                                            }
                                            onChange={
                                                this.handleChangeSupplierPhone
                                            }
                                        />
                                        <InputText
                                            id="mobileNumber"
                                            name="mobileNumber"
                                            required={true}
                                            requiredField={true}
                                            maxLength="15"
                                            label={I18n.get("lblmobileNumber")}
                                            value={
                                                this.state.sectionDataSupplier
                                                    .phones[1].number
                                            }
                                            onChange={
                                                this.handleChangeSupplierPhone
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-12">
                                        <h5>{I18n.get("lblContactPerson")}</h5>
                                        <hr />
                                    </div>
                                    <div className="col-sm-12">
                                        <InputText
                                            id="contactName"
                                            name="contactName"
                                            required={true}
                                            requiredField={true}
                                            maxLength="20"
                                            label={I18n.get("lblcontactName")}
                                            value={
                                                this.state.sectionDataSupplier
                                                    .contactName
                                            }
                                            onChange={this.handleChangeSupplier}
                                        />
                                        <InputEmail
                                            id="contactEmail"
                                            name="contactEmail"
                                            required={true}
                                            requiredField={true}
                                            maxLength="60"
                                            label={I18n.get("lblEmail")}
                                            value={
                                                this.state.sectionDataSupplier
                                                    .contactEmail
                                            }
                                            onChange={this.handleChangeSupplier}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <h5>{I18n.get("lblAboutYou")}</h5>
                                    <hr />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <InputText
                                        id="nameUser"
                                        name="nameUser"
                                        required={true}
                                        requiredField={true}
                                        maxLength="30"
                                        label={I18n.get("lblname")}
                                        value={this.state.sectionDataUser.name}
                                        onChange={this.handleChangeUserData}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <InputText
                                        id="lastNameUser"
                                        name="lastNameUser"
                                        required={true}
                                        requiredField={true}
                                        maxLength="30"
                                        label={I18n.get("lbllastName")}
                                        value={
                                            this.state.sectionDataUser.lastName
                                        }
                                        onChange={this.handleChangeUserData}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <hr />
                                    {this.state.showLoader ? (
                                        <Loader />
                                    ) : (
                                        <div className="registration__buttons">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6 text-center mb-1">
                                                    <Button
                                                        id="send"
                                                        name="send"
                                                        type="submit"
                                                        label={I18n.get(
                                                            "lblSendRequest"
                                                        )}
                                                        className="btn  btn__custom__lg-primary"
                                                    />
                                                </div>
                                                <div className="col-sm-12 col-md-6 text-center mb-1">
                                                    <Button
                                                        id="back"
                                                        name="back"
                                                        label={I18n.get(
                                                            "lblBackRequest"
                                                        )}
                                                        className="btn btn__custom__lg-outline-primary"
                                                        onClick={
                                                            this.sendDataBack
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/* <pre>
                    {
                        JSON.stringify(this.state.sectionDataSupplier, null, 2)
                    }
                </pre> */}
            </div>
        )
    }
}

export default Registration
