import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { TabulatorTable, LoaderWindow } from "xsales-components"
import { I18n } from "@aws-amplify/core"
import "../../../assets/css/detailedStatement1.css"
import moment from "moment"
import { XSalesData } from "../../../DataQuery/XSalesData"
import DetailStatementHeader from "./DetailStatementHeader"

//const today = moment()
let tabulator = null

const {
    supplier: {  currencySymbol },
} =
    JSON.parse(localStorage.getItem("ConsumerUser"))?.supplier != null
        ? JSON.parse(localStorage.getItem("ConsumerUser"))
        : { supplier: { currencyISO: "", currencySymbol: "$" } }

const DetailStatement1 = (props) => {
    const { type } = JSON.parse(localStorage.getItem("ConsumerUser"))
    const [loader, setLoader] = useState(true)
    const [businessName, setBusinessName] = useState("")
    const [licensesType, setLicensesType] = useState("")
    const [dataTable, setDataTable] = useState([])
    const [fee, seFee] = useState(0)
    const [minTransaction, setMinTrasaction] = useState(0)
    const [transactionsQuantity, setTransactionsQuantity] = useState(0)
    const [avgOrdersAmount, setAvgOrdersAmount] = useState(0)
    const [avgItemsAmount, setAvgItemsAmount] = useState(0)
    const [estimatedBill, setEstimatedBill] = useState(0)
    //const [dataStatement, setDataStatement] = useState({ data: [] })
    const startOfMonth = moment(`${props.match.params.month}`, "M").startOf(
        "month"
    )
    const endOfMonth = moment(`${props.match.params.month}`, "M").endOf("month")

    const history = useHistory()
    const columns = [
        {
            title: "orderId",
            field: "orderIdErp",
            widthGrow: 1,
            cellClick: function (e, cell) {
                const { month, year, supplierID } = props.match.params
                const { orderId, orderIdErp } = cell.getData()
                //console.log(cell.getData())
                const user =
                    localStorage.getItem("ConsumerUser") != null
                        ? JSON.parse(localStorage.getItem("ConsumerUser"))
                        : null
                if (user) {
                    if (user.type === "admin")
                        history.push(
                            `/Admin/DetailStatement2/${month}/${year}/${supplierID}/${orderId}/${orderIdErp}`
                        )
                    else
                        history.push(
                            `/DetailStatement2/${month}/${year}/${supplierID}/${orderId}/${orderIdErp}`
                        )
                }
            },
        },
        {
            title: "CustomerId",
            field: "customerId",
            widthGrow: 1,
        },
        {
            title: I18n.get("lblcolumncreatedon"),
            field: "date",
            widthGrow: 1,
            // formatter:"datetime"
        },
        {
            title: I18n.get("lblcolumnexpeteddelivery"),
            field: "deliveryExpectedOn",
            widthGrow: 1,
        },
        {
            title: I18n.get("lblcolumngrossamount"),
            field: "grossAmount",
            widthGrow: 1,
            formatter: "money",
            align: "right",
            bottomCalc: "sum",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cssClass: "tabulator__total__statement",
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
        {
            title: I18n.get("lblcolumndiscountamount"),
            field: "discountAmount",
            widthGrow: 1,
            formatter: "money",
            align: "right",
            bottomCalc: "sum",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cssClass: "tabulator__total__statement",
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
        {
            title: I18n.get("lblcolumntaxamount"),
            field: "taxAmount",
            widthGrow: 1,
            formatter: "money",
            align: "right",
            bottomCalc: "sum",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cssClass: "tabulator__total__statement",
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
        {
            title: I18n.get("lblcolumnnetamount"),
            field: "netAmount",
            widthGrow: 1,
            formatter: "money",
            align: "right",
            bottomCalc: "sum",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cssClass: "tabulator__total__statement",
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
        {
            title: I18n.get("lblcolumnstatus"),
            field: "status",
            widthGrow: 1,
        },
        {
            title: I18n.get("lblexchangerate"),
            field: "exchangeRate",
            widthGrow: 1,
            formatter: "money",
            align: "right",
        },
        {
            title: I18n.get("lblamountusd"),
            field: "grossAmountUSD",
            widthGrow: 1,
            formatter: "money",
            align: "right",
            bottomCalc: "sum",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cssClass: "tabulator__total__statement",
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
    ]

    const getDataStatement = async () => {
        const { month, year, supplierID } = props.match.params
        return await XSalesData.Statement.getStatementBySupplier(supplierID, {
            eq: { year: year, month: month },
        })
    }

    const getDataOrders = async () => {
        const { supplierID, year, month } = props.match.params
        const momentInit = moment(`${year}-${month}-01`, "YYYY-MM-DD")
        const firstDay = momentInit.startOf("month").format("YYYY-MM-DD")
        const lastDay = momentInit.endOf("month").format("YYYY-MM-DD")
        const data = await XSalesData.Order.getOrderBySupplierByDate(
            supplierID,
            firstDay,
            lastDay
        )
        const returnData = data
            .filter((x) => x.status === "PLACED")
            .map((obj) => {
                const rate =
                    obj.exchangeRate <= 0 || obj.exchangeRate == null
                        ? 1
                        : obj.exchangeRate
                if (type === "admin") {
                    return {
                        orderId: obj.id,
                        orderIdErp: obj.orderCodeERP,
                        customerId: obj.consumerIdERP,
                        date: obj.date,
                        deliveryExpectedOn: obj.deliveryExpectedOn,
                        grossAmount: obj.grossAmount / rate,
                        discountAmount: obj.discountAmount / rate,
                        taxAmount: obj.taxAmount / rate,
                        netAmount: obj.netAmount / rate,
                        status: obj.status,
                        exchangeRate: rate,
                        grossAmountUSD: obj.grossAmount / rate,
                    }
                }

                return {
                    orderId: obj.id,
                    orderIdErp: obj.orderCodeERP,
                    customerId: obj.consumerIdERP,
                    date: obj.date,
                    deliveryExpectedOn: obj.deliveryExpectedOn,
                    grossAmount: obj.grossAmount,
                    discountAmount: obj.discountAmount,
                    taxAmount: obj.taxAmount,
                    netAmount: obj.netAmount,
                    status: obj.status,
                    exchangeRate: rate,
                    grossAmountUSD: obj.grossAmount / rate,
                }
            })

        return returnData
    }

    useEffect(() => {
        getDataStatement().then((data) => {
            if (data.length > 0) {
                setBusinessName(data[0].supplier.businessName)
                setLicensesType(data[0].contractDetail.plan)
                seFee(data[0].contractDetail.fees.order)
                setMinTrasaction(data[0].contractDetail.minTransaction.order)
                setTransactionsQuantity(
                    data[0].count.deposit +
                        data[0].count.order +
                        data[0].count.payment
                )
                if (type === "admin") {
                    setAvgOrdersAmount(
                        data[0].avgAmount.depositUSD +
                            data[0].avgAmount.orderUSD +
                            data[0].avgAmount.paymentUSD
                    )
                    setEstimatedBill(
                        data[0].amount.depositUSD +
                            data[0].amount.orderUSD +
                            data[0].amount.paymentUSD
                    )
                } else {
                    setAvgOrdersAmount(
                        data[0].avgAmount.deposit +
                            data[0].avgAmount.order +
                            data[0].avgAmount.payment
                    )
                    setEstimatedBill(
                        data[0].amount.deposit +
                            data[0].amount.order +
                            data[0].amount.payment
                    )
                }
                setAvgItemsAmount(
                    data[0].avgItem.deposit +
                        data[0].avgItem.order +
                        data[0].avgItem.payment
                )
            }
            setLoader(false)
        })
        getDataOrders().then((data) => {
            setLoader(false)
            const dataMap = data.map((x) => {
                return {
                    ...x,
                    date: moment(x.date).format("L"),
                    deliveryExpectedOn: x.deliveryExpectedOn
                        ? moment(x.deliveryExpectedOn).format("L")
                        : "",
                }
            })
            setDataTable(dataMap)
        })
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const formatNumber = (value) => {
        const locale = navigator.language
        return new Intl.NumberFormat(locale, { style: "decimal" }).format(value)
    }

    const handleClickDownload = () => {
        if (tabulator) {
            tabulator.download("pdf", "data.pdf", {
                orientation: "portrait", //set page orientation to portrait
                title: "", //add title to report
            })
        }
    }

    return (
        <div className="detail__statement__container">
            {loader ? (
                <LoaderWindow />
            ) : (
                <>
                    <DetailStatementHeader
                        businessName={businessName}
                        startOfMonth={startOfMonth.format("L")}
                        endOfMonth={endOfMonth.format("L")}
                        fee={fee}
                        minTransaction={minTransaction}
                        licensesType={licensesType}
                    />
                    <div className="separator__line_detail"></div>
                    <div className="detail_statement__body">
                        <div className="content__detail__table">
                            <TabulatorTable
                                Title={""}
                                Data={dataTable}
                                Columns={columns}
                                Height="530px"
                                // Filter={[]}
                                RowClick={() => {}}
                                placeholder={I18n.get("lblLoadingData")}
                                downloadIcon={false}
                                moreIcon={false}
                                Search={false}
                                setRefTable={(table) => (tabulator = table)}
                                labelEmptyData={I18n.get(
                                    "lblnodataavailabletabulator"
                                )}
                            />
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="detail_summary_statement">
                                    <ul className="detail__list_summary">
                                        <li>
                                            <span className="detail__list_summary-title">
                                                {I18n.get(
                                                    "lbltransactionsQuantity"
                                                )}
                                                :
                                            </span>{" "}
                                            <span className="detail__list_summary-value">
                                                {formatNumber(
                                                    transactionsQuantity
                                                )}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="detail__list_summary-title">
                                                {I18n.get("lblavgorderamount")}:
                                            </span>{" "}
                                            <span className="detail__list_summary-value">{`${currencySymbol} ${formatNumber(
                                                avgOrdersAmount
                                            )}`}</span>
                                        </li>
                                        <li>
                                            <span className="detail__list_summary-title">
                                                {I18n.get(
                                                    "lblavgitemsperorder"
                                                )}
                                                :
                                            </span>{" "}
                                            <span className="detail__list_summary-value">
                                                {formatNumber(avgItemsAmount)}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="detail__list_summary-title">
                                                {I18n.get("lblestimatedbilled")}
                                                :
                                            </span>{" "}
                                            <span className="detail__list_summary-value">{`${currencySymbol} ${formatNumber(
                                                estimatedBill
                                            )}`}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-8">
                                <div className="detail__container__icon__download">
                                    <img
                                        onClick={handleClickDownload}
                                        className="icon-tabulator"
                                        src="https://cdn.xsalesconsumer.com/console/download-24px.svg"
                                        alt=""
                                    />
                                    <p>PDF</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default DetailStatement1
