/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($username: String!) {
    getUser(username: $username) {
      id
      username
      name
      lastName
      alternateEmail
      lastSessionDate
      lastIP
      lastOS
      lastDevice
      lastBrowser
      token
      email
      userType
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $username: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        username
        name
        lastName
        alternateEmail
        lastSessionDate
        lastIP
        lastOS
        lastDevice
        lastBrowser
        token
        email
        userType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReasonUnsubscribe = /* GraphQL */ `
  query GetReasonUnsubscribe($id: ID!) {
    getReasonUnsubscribe(id: $id) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      commentRequired
      inactive
      inactiveOn
      createdAt
      updatedAt
    }
  }
`;
export const listReasonUnsubscribes = /* GraphQL */ `
  query ListReasonUnsubscribes(
    $filter: ModelReasonUnsubscribeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReasonUnsubscribes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        commentRequired
        inactive
        inactiveOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserUnsubscribed = /* GraphQL */ `
  query GetUserUnsubscribed($username: String!) {
    getUserUnsubscribed(username: $username) {
      username
      name
      lastName
      alternateEmail
      email
      userType
      verificationRequestDate
      verificationCode
      verificationConfirmationDate
      reasonId
      reasonComments
      requestDate
      status
      scheduleUnsubscribe
      scheduleUnsubscribeName
      scheduleUnsubscribeDate
      scheduleReminderName
      scheduleReminderDate
      unsubscribedProcessedDate
      createdAt
      updatedAt
    }
  }
`;
export const listUserUnsubscribeds = /* GraphQL */ `
  query ListUserUnsubscribeds(
    $username: String
    $filter: ModelUserUnsubscribedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserUnsubscribeds(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        name
        lastName
        alternateEmail
        email
        userType
        verificationRequestDate
        verificationCode
        verificationConfirmationDate
        reasonId
        reasonComments
        requestDate
        status
        scheduleUnsubscribe
        scheduleUnsubscribeName
        scheduleUnsubscribeDate
        scheduleReminderName
        scheduleReminderDate
        unsubscribedProcessedDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSupplier = /* GraphQL */ `
  query GetSupplier($id: ID!) {
    getSupplier(id: $id) {
      id
      name
      businessName
      username
      taxID
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      status
      historyStatus {
        date
        status
      }
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      contactName
      contactEmail
      latitude
      longitude
      summary
      createdAt
      approvedAt
      industries {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencySymbol
      currencyISOErp
      contract {
        current {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        future {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        history {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
      }
      isXSM
      imageInternalName
      imageExternalURL
      taxPercentage
      discountPercentage
      groups1
      groups2
      groups3
      productTags
      paymentMethods {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      productSameOrderClasses
      updatedAt
    }
  }
`;
export const listSuppliers = /* GraphQL */ `
  query ListSuppliers(
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIndustry = /* GraphQL */ `
  query GetIndustry($id: ID!) {
    getIndustry(id: $id) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      active
      suppliers {
        items {
          id
          supplierId
          industryId
          supplier {
            id
            name
            businessName
            username
            taxID
            promotions {
              id
              description
              imageInternalName
              imageExternalURL
              since
              until
              active
            }
            status
            historyStatus {
              date
              status
            }
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            contactName
            contactEmail
            latitude
            longitude
            summary
            createdAt
            approvedAt
            industries {
              nextToken
            }
            currencyISO
            currencySymbol
            currencyISOErp
            isXSM
            imageInternalName
            imageExternalURL
            taxPercentage
            discountPercentage
            groups1
            groups2
            groups3
            productTags
            paymentMethods {
              key
              value
              valueLanguage1
              valueLanguage2
              valueLanguage3
              valueLanguage4
              default
            }
            productSameOrderClasses
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      consumers {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listIndustrys = /* GraphQL */ `
  query ListIndustrys(
    $filter: ModelIndustryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndustrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        active
        suppliers {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        consumers {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConsumer = /* GraphQL */ `
  query GetConsumer($id: ID!) {
    getConsumer(id: $id) {
      id
      name
      businessName
      username
      taxID
      status
      address {
        type
        street1
        street2
        street3
        city
        cityId
        state
        stateId
        zipcode
        country
        countryId
        default
        updatedOn
      }
      phones {
        type
        number
        default
        updatedOn
      }
      website
      email
      summary
      contactName
      contactEmail
      latitude
      longitude
      industries {
        items {
          id
          consumerId
          industryId
          consumer {
            id
            name
            businessName
            username
            taxID
            status
            address {
              type
              street1
              street2
              street3
              city
              cityId
              state
              stateId
              zipcode
              country
              countryId
              default
              updatedOn
            }
            phones {
              type
              number
              default
              updatedOn
            }
            website
            email
            summary
            contactName
            contactEmail
            latitude
            longitude
            industries {
              nextToken
            }
            isXSM
            currencyISO
            currencySymbol
            currencyISOErp
            createdAt
            updatedAt
          }
          industry {
            id
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            active
            suppliers {
              nextToken
            }
            consumers {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isXSM
      currencyISO
      currencySymbol
      currencyISOErp
      createdAt
      updatedAt
    }
  }
`;
export const listConsumers = /* GraphQL */ `
  query ListConsumers(
    $filter: ModelConsumerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsumers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSupplierConsumer = /* GraphQL */ `
  query GetSupplierConsumer($id: ID!) {
    getSupplierConsumer(id: $id) {
      id
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      consumerId
      consumer {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      requestedOn
      requestedBy
      requestComments
      responsedOn
      responsedBy
      responseComments
      consumerIdERP
      requestStatus
      blockedForSales
      daysDelivery {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        sameDayDelivery
        sameDayDeliveryUntil
        sameDayDeliveryTimeZone
      }
      promotions {
        id
        description
        imageInternalName
        imageExternalURL
        since
        until
        active
      }
      taxPercentage
      discountPercentage
      group1
      group2
      group3
      createdAt
      updatedAt
    }
  }
`;
export const listSupplierConsumers = /* GraphQL */ `
  query ListSupplierConsumers(
    $filter: ModelSupplierConsumerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupplierConsumers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        supplier {
          id
          name
          businessName
          username
          taxID
          promotions {
            id
            description
            imageInternalName
            imageExternalURL
            since
            until
            active
          }
          status
          historyStatus {
            date
            status
          }
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          contactName
          contactEmail
          latitude
          longitude
          summary
          createdAt
          approvedAt
          industries {
            items {
              id
              supplierId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          currencyISO
          currencySymbol
          currencyISOErp
          contract {
            current {
              since
              plan
              maxTransaction
            }
            future {
              since
              plan
              maxTransaction
            }
            history {
              since
              plan
              maxTransaction
            }
          }
          isXSM
          imageInternalName
          imageExternalURL
          taxPercentage
          discountPercentage
          groups1
          groups2
          groups3
          productTags
          paymentMethods {
            key
            value
            valueLanguage1
            valueLanguage2
            valueLanguage3
            valueLanguage4
            default
          }
          productSameOrderClasses
          updatedAt
        }
        consumerId
        consumer {
          id
          name
          businessName
          username
          taxID
          status
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          summary
          contactName
          contactEmail
          latitude
          longitude
          industries {
            items {
              id
              consumerId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          isXSM
          currencyISO
          currencySymbol
          currencyISOErp
          createdAt
          updatedAt
        }
        requestedOn
        requestedBy
        requestComments
        responsedOn
        responsedBy
        responseComments
        consumerIdERP
        requestStatus
        blockedForSales
        daysDelivery {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          sameDayDelivery
          sameDayDeliveryUntil
          sameDayDeliveryTimeZone
        }
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        taxPercentage
        discountPercentage
        group1
        group2
        group3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($supplierId: ID!, $categoryId: String!) {
    getCategory(supplierId: $supplierId, categoryId: $categoryId) {
      id
      supplierId
      categoryId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      inactive
      inactiveOn
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $supplierId: ID
    $categoryId: ModelStringKeyConditionInput
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategorys(
      supplierId: $supplierId
      categoryId: $categoryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        supplierId
        categoryId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        inactive
        inactiveOn
        imageInternalName
        imageExternalURL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubcategory = /* GraphQL */ `
  query GetSubcategory($supplierId: ID!, $subcategoryId: String!) {
    getSubcategory(supplierId: $supplierId, subcategoryId: $subcategoryId) {
      id
      supplierId
      categoryId
      subcategoryId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      inactive
      inactiveOn
      inactiveParent
      inactiveParentOn
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const listSubcategorys = /* GraphQL */ `
  query ListSubcategorys(
    $supplierId: ID
    $subcategoryId: ModelStringKeyConditionInput
    $filter: ModelSubcategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubcategorys(
      supplierId: $supplierId
      subcategoryId: $subcategoryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        supplierId
        categoryId
        subcategoryId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        inactive
        inactiveOn
        inactiveParent
        inactiveParentOn
        imageInternalName
        imageExternalURL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($supplierId: ID!, $productId: ID!, $unit: String!) {
    getProduct(supplierId: $supplierId, productId: $productId, unit: $unit) {
      id
      productId
      supplierId
      unit
      subcategoryId
      descriptionLanguage1
      descriptionLanguage2
      descriptionLanguage3
      descriptionLanguage4
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      tags {
        tag
      }
      price
      percentageDiscount
      amountDiscount
      blockedForSales
      blockedForSalesDate
      inactive
      inactiveOn
      inactiveParent
      inactiveParentOn
      imageInternalName
      imageExternalURL
      taxFree
      barcode
      sameOrderClass
      stock
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $supplierId: ID
    $productIdUnit: ModelProductPrimaryCompositeKeyConditionInput
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProducts(
      supplierId: $supplierId
      productIdUnit: $productIdUnit
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        productId
        supplierId
        unit
        subcategoryId
        descriptionLanguage1
        descriptionLanguage2
        descriptionLanguage3
        descriptionLanguage4
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        shortnameLanguage1
        shortnameLanguage2
        shortnameLanguage3
        shortnameLanguage4
        tags {
          tag
        }
        price
        percentageDiscount
        amountDiscount
        blockedForSales
        blockedForSalesDate
        inactive
        inactiveOn
        inactiveParent
        inactiveParentOn
        imageInternalName
        imageExternalURL
        taxFree
        barcode
        sameOrderClass
        stock
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSupplierConsumerProduct = /* GraphQL */ `
  query GetSupplierConsumerProduct(
    $supplierId: ID!
    $consumerId: ID!
    $productId: ID!
    $unit: String!
  ) {
    getSupplierConsumerProduct(
      supplierId: $supplierId
      consumerId: $consumerId
      productId: $productId
      unit: $unit
    ) {
      id
      productId
      supplierId
      consumerId
      unit
      price
      percentageDiscount
      amountDiscount
      stock
      createdAt
      updatedAt
    }
  }
`;
export const listSupplierConsumerProducts = /* GraphQL */ `
  query ListSupplierConsumerProducts(
    $supplierId: ID
    $consumerIdProductIdUnit: ModelSupplierConsumerProductPrimaryCompositeKeyConditionInput
    $filter: ModelSupplierConsumerProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSupplierConsumerProducts(
      supplierId: $supplierId
      consumerIdProductIdUnit: $consumerIdProductIdUnit
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        productId
        supplierId
        consumerId
        unit
        price
        percentageDiscount
        amountDiscount
        stock
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWindow = /* GraphQL */ `
  query GetWindow($id: ID!) {
    getWindow(id: $id) {
      id
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      messages {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listWindows = /* GraphQL */ `
  query ListWindows(
    $filter: ModelWindowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWindows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        messages {
          items {
            id
            windowId
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            audit {
              createdBy
              updatedBy
              deletedBy
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        labels {
          items {
            id
            windowId
            nameLanguage1
            nameLanguage2
            nameLanguage3
            nameLanguage4
            audit {
              createdBy
              updatedBy
              deletedBy
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        security {
          id
          securityId
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          type
          securityXSM
          deleted
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLabel = /* GraphQL */ `
  query GetLabel($id: ID!) {
    getLabel(id: $id) {
      id
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLabels = /* GraphQL */ `
  query ListLabels(
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSecurity = /* GraphQL */ `
  query GetSecurity($id: ID!) {
    getSecurity(id: $id) {
      id
      securityId
      windowId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      securityXSM
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSecuritys = /* GraphQL */ `
  query ListSecuritys(
    $filter: ModelSecurityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecuritys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSetup = /* GraphQL */ `
  query GetSetup($setupId: String!) {
    getSetup(setupId: $setupId) {
      id
      setupId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const listSetups = /* GraphQL */ `
  query ListSetups(
    $setupId: String
    $filter: ModelSetupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSetups(
      setupId: $setupId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        setupId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSupplierSetup = /* GraphQL */ `
  query GetSupplierSetup($supplierId: ID!, $setupId: String!) {
    getSupplierSetup(supplierId: $supplierId, setupId: $setupId) {
      id
      supplierId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const listSupplierSetups = /* GraphQL */ `
  query ListSupplierSetups(
    $supplierId: ID
    $setupId: ModelStringKeyConditionInput
    $filter: ModelSupplierSetupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSupplierSetups(
      supplierId: $supplierId
      setupId: $setupId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        supplierId
        setupId
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConsumerSetup = /* GraphQL */ `
  query GetConsumerSetup($consumerId: ID!, $setupId: String!) {
    getConsumerSetup(consumerId: $consumerId, setupId: $setupId) {
      id
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const listConsumerSetups = /* GraphQL */ `
  query ListConsumerSetups(
    $consumerId: ID
    $setupId: ModelStringKeyConditionInput
    $filter: ModelConsumerSetupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listConsumerSetups(
      consumerId: $consumerId
      setupId: $setupId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        consumerId
        setupId
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSupplierConsumerSetup = /* GraphQL */ `
  query GetSupplierConsumerSetup(
    $supplierId: ID!
    $consumerId: ID!
    $setupId: String!
  ) {
    getSupplierConsumerSetup(
      supplierId: $supplierId
      consumerId: $consumerId
      setupId: $setupId
    ) {
      id
      supplierId
      consumerId
      setupId
      value
      createdAt
      updatedAt
    }
  }
`;
export const listSupplierConsumerSetups = /* GraphQL */ `
  query ListSupplierConsumerSetups(
    $supplierId: ID
    $consumerIdSetupId: ModelSupplierConsumerSetupPrimaryCompositeKeyConditionInput
    $filter: ModelSupplierConsumerSetupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSupplierConsumerSetups(
      supplierId: $supplierId
      consumerIdSetupId: $consumerIdSetupId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        supplierId
        consumerId
        setupId
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAudit = /* GraphQL */ `
  query GetAudit($id: ID!) {
    getAudit(id: $id) {
      id
      date
      type
      code
      message
      systemMessage
      application
      process
      format
      username
      url
      ipAddress
      appVersion
      errorNumber
      duration
      supplierId
      consumerId
      createdAt
      updatedAt
    }
  }
`;
export const listAudits = /* GraphQL */ `
  query ListAudits(
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        type
        code
        message
        systemMessage
        application
        process
        format
        username
        url
        ipAddress
        appVersion
        errorNumber
        duration
        supplierId
        consumerId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCrew = /* GraphQL */ `
  query GetCrew($crewId: String!) {
    getCrew(crewId: $crewId) {
      id
      crewId
      nameLanguage1
      nameLanguage2
      nameLanguage3
      nameLanguage4
      type
      deleted
      audit {
        createdBy
        updatedBy
        deletedBy
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCrews = /* GraphQL */ `
  query ListCrews(
    $crewId: String
    $filter: ModelCrewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCrews(
      crewId: $crewId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCrewSecurity = /* GraphQL */ `
  query GetCrewSecurity($crewId: String!, $securityId: ID!) {
    getCrewSecurity(crewId: $crewId, securityId: $securityId) {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      securityId
      security {
        id
        securityId
        windowId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        securityXSM
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCrewSecuritys = /* GraphQL */ `
  query ListCrewSecuritys(
    $crewId: String
    $securityId: ModelIDKeyConditionInput
    $filter: ModelCrewSecurityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCrewSecuritys(
      crewId: $crewId
      securityId: $securityId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        crewId
        crew {
          id
          crewId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          type
          deleted
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        securityId
        security {
          id
          securityId
          windowId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          type
          securityXSM
          deleted
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCrewUser = /* GraphQL */ `
  query GetCrewUser($crewId: String!, $username: String!) {
    getCrewUser(crewId: $crewId, username: $username) {
      id
      crewId
      crew {
        id
        crewId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        type
        deleted
        audit {
          createdBy
          updatedBy
          deletedBy
        }
        createdAt
        updatedAt
      }
      username
      createdAt
      updatedAt
    }
  }
`;
export const listCrewUsers = /* GraphQL */ `
  query ListCrewUsers(
    $crewId: String
    $username: ModelStringKeyConditionInput
    $filter: ModelCrewUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCrewUsers(
      crewId: $crewId
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        crewId
        crew {
          id
          crewId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          type
          deleted
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        username
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      supplierId
      consumerId
      consumerIdERP
      orderCodeERP
      date
      dateERP
      deliveryOn
      deliveryExpectedOn
      requestComment
      orderComments
      shipping
      status
      statusERP
      historyStatusERP {
        datetimeERP
        statusERP
      }
      grossAmount
      discountAmount
      taxAmount
      netAmount
      orderProducts {
        items {
          id
          orderId
          productId
          unitId
          price
          quantity
          grossAmount
          discountAmount
          taxAmount
          netAmount
          shortnameLanguage1
          shortnameLanguage2
          shortnameLanguage3
          shortnameLanguage4
          imageInternalName
          imageExternalURL
          createdAt
          updatedAt
        }
        nextToken
      }
      currencyISO
      currencyISOErpRequest
      currencyISOErp
      currencySymbol
      exchangeRate
      posted
      postedOn
      itemCount
      paymentCondition
      taxes {
        name
        amount
      }
      discounts {
        name
        amount
      }
      paymentMethod {
        key
        value
        valueLanguage1
        valueLanguage2
        valueLanguage3
        valueLanguage4
        default
      }
      shippingAddress {
        key
        value
        default
      }
      group1
      group2
      group3
      consumerName
      sameOrderClass
      dateTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        supplierId
        consumerId
        consumerIdERP
        orderCodeERP
        date
        dateERP
        deliveryOn
        deliveryExpectedOn
        requestComment
        orderComments
        shipping
        status
        statusERP
        historyStatusERP {
          datetimeERP
          statusERP
        }
        grossAmount
        discountAmount
        taxAmount
        netAmount
        orderProducts {
          items {
            id
            orderId
            productId
            unitId
            price
            quantity
            grossAmount
            discountAmount
            taxAmount
            netAmount
            shortnameLanguage1
            shortnameLanguage2
            shortnameLanguage3
            shortnameLanguage4
            imageInternalName
            imageExternalURL
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencyISOErpRequest
        currencyISOErp
        currencySymbol
        exchangeRate
        posted
        postedOn
        itemCount
        paymentCondition
        taxes {
          name
          amount
        }
        discounts {
          name
          amount
        }
        paymentMethod {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        shippingAddress {
          key
          value
          default
        }
        group1
        group2
        group3
        consumerName
        sameOrderClass
        dateTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderProduct = /* GraphQL */ `
  query GetOrderProduct($id: ID!) {
    getOrderProduct(id: $id) {
      id
      orderId
      productId
      unitId
      price
      quantity
      grossAmount
      discountAmount
      taxAmount
      netAmount
      shortnameLanguage1
      shortnameLanguage2
      shortnameLanguage3
      shortnameLanguage4
      imageInternalName
      imageExternalURL
      createdAt
      updatedAt
    }
  }
`;
export const listOrderProducts = /* GraphQL */ `
  query ListOrderProducts(
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        productId
        unitId
        price
        quantity
        grossAmount
        discountAmount
        taxAmount
        netAmount
        shortnameLanguage1
        shortnameLanguage2
        shortnameLanguage3
        shortnameLanguage4
        imageInternalName
        imageExternalURL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSyncLog = /* GraphQL */ `
  query GetSyncLog($id: ID!) {
    getSyncLog(id: $id) {
      id
      supplierId
      consumerId
      date
      dateStart
      dateEnd
      syncId
      process
      typeProcess
      status
      isOk
      isWarning
      message
      errors
      systemMessage
      sourceCount
      inserted
      updated
      noupdated
      errored
      errorNumber
      totalDuration
      extractDuration
      transformDuration
      loadDuration
      createdAt
      updatedAt
    }
  }
`;
export const listSyncLogs = /* GraphQL */ `
  query ListSyncLogs(
    $filter: ModelSyncLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSyncLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        supplierId
        consumerId
        date
        dateStart
        dateEnd
        syncId
        process
        typeProcess
        status
        isOk
        isWarning
        message
        errors
        systemMessage
        sourceCount
        inserted
        updated
        noupdated
        errored
        errorNumber
        totalDuration
        extractDuration
        transformDuration
        loadDuration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStatement = /* GraphQL */ `
  query GetStatement($id: ID!) {
    getStatement(id: $id) {
      id
      year
      month
      paddedMonth
      supplierId
      supplier {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      contractDetail {
        since
        plan
        fees {
          deposit
          payment
          order
        }
        minTransaction {
          deposit
          payment
          order
        }
        maxTransaction
      }
      count {
        deposit
        payment
        order
      }
      amount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      avgItem {
        deposit
        payment
        order
      }
      avgAmount {
        deposit
        payment
        order
        orderUSD
        paymentUSD
        depositUSD
      }
      itemCount {
        deposit
        payment
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const listStatements = /* GraphQL */ `
  query ListStatements(
    $filter: ModelStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        month
        paddedMonth
        supplierId
        supplier {
          id
          name
          businessName
          username
          taxID
          promotions {
            id
            description
            imageInternalName
            imageExternalURL
            since
            until
            active
          }
          status
          historyStatus {
            date
            status
          }
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          contactName
          contactEmail
          latitude
          longitude
          summary
          createdAt
          approvedAt
          industries {
            items {
              id
              supplierId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          currencyISO
          currencySymbol
          currencyISOErp
          contract {
            current {
              since
              plan
              maxTransaction
            }
            future {
              since
              plan
              maxTransaction
            }
            history {
              since
              plan
              maxTransaction
            }
          }
          isXSM
          imageInternalName
          imageExternalURL
          taxPercentage
          discountPercentage
          groups1
          groups2
          groups3
          productTags
          paymentMethods {
            key
            value
            valueLanguage1
            valueLanguage2
            valueLanguage3
            valueLanguage4
            default
          }
          productSameOrderClasses
          updatedAt
        }
        contractDetail {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        count {
          deposit
          payment
          order
        }
        amount {
          deposit
          payment
          order
          orderUSD
          paymentUSD
          depositUSD
        }
        avgItem {
          deposit
          payment
          order
        }
        avgAmount {
          deposit
          payment
          order
          orderUSD
          paymentUSD
          depositUSD
        }
        itemCount {
          deposit
          payment
          order
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const SupplierByName = /* GraphQL */ `
  query SupplierByName(
    $businessName: String
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SupplierByName(
      businessName: $businessName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      nextToken
    }
  }
`;
export const SupplierByUser = /* GraphQL */ `
  query SupplierByUser(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SupplierByUser(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      nextToken
    }
  }
`;
export const SupplierByStatus = /* GraphQL */ `
  query SupplierByStatus(
    $status: StatusSupplier
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SupplierByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        businessName
        username
        taxID
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        status
        historyStatus {
          date
          status
        }
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        contactName
        contactEmail
        latitude
        longitude
        summary
        createdAt
        approvedAt
        industries {
          items {
            id
            supplierId
            industryId
            supplier {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              contactName
              contactEmail
              latitude
              longitude
              summary
              createdAt
              approvedAt
              currencyISO
              currencySymbol
              currencyISOErp
              isXSM
              imageInternalName
              imageExternalURL
              taxPercentage
              discountPercentage
              groups1
              groups2
              groups3
              productTags
              productSameOrderClasses
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencySymbol
        currencyISOErp
        contract {
          current {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          future {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
          history {
            since
            plan
            fees {
              deposit
              payment
              order
            }
            minTransaction {
              deposit
              payment
              order
            }
            maxTransaction
          }
        }
        isXSM
        imageInternalName
        imageExternalURL
        taxPercentage
        discountPercentage
        groups1
        groups2
        groups3
        productTags
        paymentMethods {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        productSameOrderClasses
        updatedAt
      }
      nextToken
    }
  }
`;
export const ConsumerByUser = /* GraphQL */ `
  query ConsumerByUser(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelConsumerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ConsumerByUser(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ConsumerByEmail = /* GraphQL */ `
  query ConsumerByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelConsumerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ConsumerByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        businessName
        username
        taxID
        status
        address {
          type
          street1
          street2
          street3
          city
          cityId
          state
          stateId
          zipcode
          country
          countryId
          default
          updatedOn
        }
        phones {
          type
          number
          default
          updatedOn
        }
        website
        email
        summary
        contactName
        contactEmail
        latitude
        longitude
        industries {
          items {
            id
            consumerId
            industryId
            consumer {
              id
              name
              businessName
              username
              taxID
              status
              website
              email
              summary
              contactName
              contactEmail
              latitude
              longitude
              isXSM
              currencyISO
              currencySymbol
              currencyISOErp
              createdAt
              updatedAt
            }
            industry {
              id
              nameLanguage1
              nameLanguage2
              nameLanguage3
              nameLanguage4
              active
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isXSM
        currencyISO
        currencySymbol
        currencyISOErp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const SuppliersByConsumer = /* GraphQL */ `
  query SuppliersByConsumer(
    $consumerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierConsumerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SuppliersByConsumer(
      consumerId: $consumerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        supplier {
          id
          name
          businessName
          username
          taxID
          promotions {
            id
            description
            imageInternalName
            imageExternalURL
            since
            until
            active
          }
          status
          historyStatus {
            date
            status
          }
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          contactName
          contactEmail
          latitude
          longitude
          summary
          createdAt
          approvedAt
          industries {
            items {
              id
              supplierId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          currencyISO
          currencySymbol
          currencyISOErp
          contract {
            current {
              since
              plan
              maxTransaction
            }
            future {
              since
              plan
              maxTransaction
            }
            history {
              since
              plan
              maxTransaction
            }
          }
          isXSM
          imageInternalName
          imageExternalURL
          taxPercentage
          discountPercentage
          groups1
          groups2
          groups3
          productTags
          paymentMethods {
            key
            value
            valueLanguage1
            valueLanguage2
            valueLanguage3
            valueLanguage4
            default
          }
          productSameOrderClasses
          updatedAt
        }
        consumerId
        consumer {
          id
          name
          businessName
          username
          taxID
          status
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          summary
          contactName
          contactEmail
          latitude
          longitude
          industries {
            items {
              id
              consumerId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          isXSM
          currencyISO
          currencySymbol
          currencyISOErp
          createdAt
          updatedAt
        }
        requestedOn
        requestedBy
        requestComments
        responsedOn
        responsedBy
        responseComments
        consumerIdERP
        requestStatus
        blockedForSales
        daysDelivery {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          sameDayDelivery
          sameDayDeliveryUntil
          sameDayDeliveryTimeZone
        }
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        taxPercentage
        discountPercentage
        group1
        group2
        group3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ConsumersBySupplier = /* GraphQL */ `
  query ConsumersBySupplier(
    $supplierId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierConsumerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ConsumersBySupplier(
      supplierId: $supplierId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        supplier {
          id
          name
          businessName
          username
          taxID
          promotions {
            id
            description
            imageInternalName
            imageExternalURL
            since
            until
            active
          }
          status
          historyStatus {
            date
            status
          }
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          contactName
          contactEmail
          latitude
          longitude
          summary
          createdAt
          approvedAt
          industries {
            items {
              id
              supplierId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          currencyISO
          currencySymbol
          currencyISOErp
          contract {
            current {
              since
              plan
              maxTransaction
            }
            future {
              since
              plan
              maxTransaction
            }
            history {
              since
              plan
              maxTransaction
            }
          }
          isXSM
          imageInternalName
          imageExternalURL
          taxPercentage
          discountPercentage
          groups1
          groups2
          groups3
          productTags
          paymentMethods {
            key
            value
            valueLanguage1
            valueLanguage2
            valueLanguage3
            valueLanguage4
            default
          }
          productSameOrderClasses
          updatedAt
        }
        consumerId
        consumer {
          id
          name
          businessName
          username
          taxID
          status
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          summary
          contactName
          contactEmail
          latitude
          longitude
          industries {
            items {
              id
              consumerId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          isXSM
          currencyISO
          currencySymbol
          currencyISOErp
          createdAt
          updatedAt
        }
        requestedOn
        requestedBy
        requestComments
        responsedOn
        responsedBy
        responseComments
        consumerIdERP
        requestStatus
        blockedForSales
        daysDelivery {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          sameDayDelivery
          sameDayDeliveryUntil
          sameDayDeliveryTimeZone
        }
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        taxPercentage
        discountPercentage
        group1
        group2
        group3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ConsumerSupplierRealation = /* GraphQL */ `
  query ConsumerSupplierRealation(
    $supplierId: ID
    $consumerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierConsumerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ConsumerSupplierRealation(
      supplierId: $supplierId
      consumerId: $consumerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        supplier {
          id
          name
          businessName
          username
          taxID
          promotions {
            id
            description
            imageInternalName
            imageExternalURL
            since
            until
            active
          }
          status
          historyStatus {
            date
            status
          }
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          contactName
          contactEmail
          latitude
          longitude
          summary
          createdAt
          approvedAt
          industries {
            items {
              id
              supplierId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          currencyISO
          currencySymbol
          currencyISOErp
          contract {
            current {
              since
              plan
              maxTransaction
            }
            future {
              since
              plan
              maxTransaction
            }
            history {
              since
              plan
              maxTransaction
            }
          }
          isXSM
          imageInternalName
          imageExternalURL
          taxPercentage
          discountPercentage
          groups1
          groups2
          groups3
          productTags
          paymentMethods {
            key
            value
            valueLanguage1
            valueLanguage2
            valueLanguage3
            valueLanguage4
            default
          }
          productSameOrderClasses
          updatedAt
        }
        consumerId
        consumer {
          id
          name
          businessName
          username
          taxID
          status
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          summary
          contactName
          contactEmail
          latitude
          longitude
          industries {
            items {
              id
              consumerId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          isXSM
          currencyISO
          currencySymbol
          currencyISOErp
          createdAt
          updatedAt
        }
        requestedOn
        requestedBy
        requestComments
        responsedOn
        responsedBy
        responseComments
        consumerIdERP
        requestStatus
        blockedForSales
        daysDelivery {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          sameDayDelivery
          sameDayDeliveryUntil
          sameDayDeliveryTimeZone
        }
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        taxPercentage
        discountPercentage
        group1
        group2
        group3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ConsumerIdERPSupplierRelation = /* GraphQL */ `
  query ConsumerIdERPSupplierRelation(
    $supplierId: ID
    $consumerIdERP: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierConsumerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ConsumerIdERPSupplierRelation(
      supplierId: $supplierId
      consumerIdERP: $consumerIdERP
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        supplier {
          id
          name
          businessName
          username
          taxID
          promotions {
            id
            description
            imageInternalName
            imageExternalURL
            since
            until
            active
          }
          status
          historyStatus {
            date
            status
          }
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          contactName
          contactEmail
          latitude
          longitude
          summary
          createdAt
          approvedAt
          industries {
            items {
              id
              supplierId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          currencyISO
          currencySymbol
          currencyISOErp
          contract {
            current {
              since
              plan
              maxTransaction
            }
            future {
              since
              plan
              maxTransaction
            }
            history {
              since
              plan
              maxTransaction
            }
          }
          isXSM
          imageInternalName
          imageExternalURL
          taxPercentage
          discountPercentage
          groups1
          groups2
          groups3
          productTags
          paymentMethods {
            key
            value
            valueLanguage1
            valueLanguage2
            valueLanguage3
            valueLanguage4
            default
          }
          productSameOrderClasses
          updatedAt
        }
        consumerId
        consumer {
          id
          name
          businessName
          username
          taxID
          status
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          summary
          contactName
          contactEmail
          latitude
          longitude
          industries {
            items {
              id
              consumerId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          isXSM
          currencyISO
          currencySymbol
          currencyISOErp
          createdAt
          updatedAt
        }
        requestedOn
        requestedBy
        requestComments
        responsedOn
        responsedBy
        responseComments
        consumerIdERP
        requestStatus
        blockedForSales
        daysDelivery {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          sameDayDelivery
          sameDayDeliveryUntil
          sameDayDeliveryTimeZone
        }
        promotions {
          id
          description
          imageInternalName
          imageExternalURL
          since
          until
          active
        }
        taxPercentage
        discountPercentage
        group1
        group2
        group3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const SubcategoriesbyCategory = /* GraphQL */ `
  query SubcategoriesbyCategory(
    $supplierId: ID
    $categoryId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SubcategoriesbyCategory(
      supplierId: $supplierId
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        categoryId
        subcategoryId
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        inactive
        inactiveOn
        inactiveParent
        inactiveParentOn
        imageInternalName
        imageExternalURL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ProductsbySubcategory = /* GraphQL */ `
  query ProductsbySubcategory(
    $supplierId: ID
    $subcategoryIdProductId: ModelProductProductsbySubcategoryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductsbySubcategory(
      supplierId: $supplierId
      subcategoryIdProductId: $subcategoryIdProductId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        supplierId
        unit
        subcategoryId
        descriptionLanguage1
        descriptionLanguage2
        descriptionLanguage3
        descriptionLanguage4
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        shortnameLanguage1
        shortnameLanguage2
        shortnameLanguage3
        shortnameLanguage4
        tags {
          tag
        }
        price
        percentageDiscount
        amountDiscount
        blockedForSales
        blockedForSalesDate
        inactive
        inactiveOn
        inactiveParent
        inactiveParentOn
        imageInternalName
        imageExternalURL
        taxFree
        barcode
        sameOrderClass
        stock
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const SupplierConsumerProductsByConsumer = /* GraphQL */ `
  query SupplierConsumerProductsByConsumer(
    $supplierId: ID
    $consumerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierConsumerProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SupplierConsumerProductsByConsumer(
      supplierId: $supplierId
      consumerId: $consumerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        supplierId
        consumerId
        unit
        price
        percentageDiscount
        amountDiscount
        stock
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const SupplierConsumerProductsByProduct = /* GraphQL */ `
  query SupplierConsumerProductsByProduct(
    $supplierId: ID
    $productId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierConsumerProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SupplierConsumerProductsByProduct(
      supplierId: $supplierId
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        supplierId
        consumerId
        unit
        price
        percentageDiscount
        amountDiscount
        stock
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const SupplierConsumerSetupByConsumer = /* GraphQL */ `
  query SupplierConsumerSetupByConsumer(
    $consumerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierConsumerSetupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SupplierConsumerSetupByConsumer(
      consumerId: $consumerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        consumerId
        setupId
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const CrewsByUser = /* GraphQL */ `
  query CrewsByUser(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelCrewUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CrewsByUser(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        crewId
        crew {
          id
          crewId
          nameLanguage1
          nameLanguage2
          nameLanguage3
          nameLanguage4
          type
          deleted
          audit {
            createdBy
            updatedBy
            deletedBy
          }
          createdAt
          updatedAt
        }
        username
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const OrdersBySupplierByDate = /* GraphQL */ `
  query OrdersBySupplierByDate(
    $supplierId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrdersBySupplierByDate(
      supplierId: $supplierId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        consumerId
        consumerIdERP
        orderCodeERP
        date
        dateERP
        deliveryOn
        deliveryExpectedOn
        requestComment
        orderComments
        shipping
        status
        statusERP
        historyStatusERP {
          datetimeERP
          statusERP
        }
        grossAmount
        discountAmount
        taxAmount
        netAmount
        orderProducts {
          items {
            id
            orderId
            productId
            unitId
            price
            quantity
            grossAmount
            discountAmount
            taxAmount
            netAmount
            shortnameLanguage1
            shortnameLanguage2
            shortnameLanguage3
            shortnameLanguage4
            imageInternalName
            imageExternalURL
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencyISOErpRequest
        currencyISOErp
        currencySymbol
        exchangeRate
        posted
        postedOn
        itemCount
        paymentCondition
        taxes {
          name
          amount
        }
        discounts {
          name
          amount
        }
        paymentMethod {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        shippingAddress {
          key
          value
          default
        }
        group1
        group2
        group3
        consumerName
        sameOrderClass
        dateTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const OrdersBySupplierByConsumer = /* GraphQL */ `
  query OrdersBySupplierByConsumer(
    $supplierId: ID
    $consumerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrdersBySupplierByConsumer(
      supplierId: $supplierId
      consumerId: $consumerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        consumerId
        consumerIdERP
        orderCodeERP
        date
        dateERP
        deliveryOn
        deliveryExpectedOn
        requestComment
        orderComments
        shipping
        status
        statusERP
        historyStatusERP {
          datetimeERP
          statusERP
        }
        grossAmount
        discountAmount
        taxAmount
        netAmount
        orderProducts {
          items {
            id
            orderId
            productId
            unitId
            price
            quantity
            grossAmount
            discountAmount
            taxAmount
            netAmount
            shortnameLanguage1
            shortnameLanguage2
            shortnameLanguage3
            shortnameLanguage4
            imageInternalName
            imageExternalURL
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencyISOErpRequest
        currencyISOErp
        currencySymbol
        exchangeRate
        posted
        postedOn
        itemCount
        paymentCondition
        taxes {
          name
          amount
        }
        discounts {
          name
          amount
        }
        paymentMethod {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        shippingAddress {
          key
          value
          default
        }
        group1
        group2
        group3
        consumerName
        sameOrderClass
        dateTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const OrdersBySupplierByConsumerByDate = /* GraphQL */ `
  query OrdersBySupplierByConsumerByDate(
    $supplierId: ID
    $consumerIdDate: ModelOrderBySupplierByConsumerByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrdersBySupplierByConsumerByDate(
      supplierId: $supplierId
      consumerIdDate: $consumerIdDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        consumerId
        consumerIdERP
        orderCodeERP
        date
        dateERP
        deliveryOn
        deliveryExpectedOn
        requestComment
        orderComments
        shipping
        status
        statusERP
        historyStatusERP {
          datetimeERP
          statusERP
        }
        grossAmount
        discountAmount
        taxAmount
        netAmount
        orderProducts {
          items {
            id
            orderId
            productId
            unitId
            price
            quantity
            grossAmount
            discountAmount
            taxAmount
            netAmount
            shortnameLanguage1
            shortnameLanguage2
            shortnameLanguage3
            shortnameLanguage4
            imageInternalName
            imageExternalURL
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencyISOErpRequest
        currencyISOErp
        currencySymbol
        exchangeRate
        posted
        postedOn
        itemCount
        paymentCondition
        taxes {
          name
          amount
        }
        discounts {
          name
          amount
        }
        paymentMethod {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        shippingAddress {
          key
          value
          default
        }
        group1
        group2
        group3
        consumerName
        sameOrderClass
        dateTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const OrdersBySupplierByDeliveryDate = /* GraphQL */ `
  query OrdersBySupplierByDeliveryDate(
    $supplierId: ID
    $deliveryOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrdersBySupplierByDeliveryDate(
      supplierId: $supplierId
      deliveryOn: $deliveryOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        consumerId
        consumerIdERP
        orderCodeERP
        date
        dateERP
        deliveryOn
        deliveryExpectedOn
        requestComment
        orderComments
        shipping
        status
        statusERP
        historyStatusERP {
          datetimeERP
          statusERP
        }
        grossAmount
        discountAmount
        taxAmount
        netAmount
        orderProducts {
          items {
            id
            orderId
            productId
            unitId
            price
            quantity
            grossAmount
            discountAmount
            taxAmount
            netAmount
            shortnameLanguage1
            shortnameLanguage2
            shortnameLanguage3
            shortnameLanguage4
            imageInternalName
            imageExternalURL
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencyISOErpRequest
        currencyISOErp
        currencySymbol
        exchangeRate
        posted
        postedOn
        itemCount
        paymentCondition
        taxes {
          name
          amount
        }
        discounts {
          name
          amount
        }
        paymentMethod {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        shippingAddress {
          key
          value
          default
        }
        group1
        group2
        group3
        consumerName
        sameOrderClass
        dateTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const OrdersByConsumerByDate = /* GraphQL */ `
  query OrdersByConsumerByDate(
    $consumerId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrdersByConsumerByDate(
      consumerId: $consumerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        consumerId
        consumerIdERP
        orderCodeERP
        date
        dateERP
        deliveryOn
        deliveryExpectedOn
        requestComment
        orderComments
        shipping
        status
        statusERP
        historyStatusERP {
          datetimeERP
          statusERP
        }
        grossAmount
        discountAmount
        taxAmount
        netAmount
        orderProducts {
          items {
            id
            orderId
            productId
            unitId
            price
            quantity
            grossAmount
            discountAmount
            taxAmount
            netAmount
            shortnameLanguage1
            shortnameLanguage2
            shortnameLanguage3
            shortnameLanguage4
            imageInternalName
            imageExternalURL
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencyISOErpRequest
        currencyISOErp
        currencySymbol
        exchangeRate
        posted
        postedOn
        itemCount
        paymentCondition
        taxes {
          name
          amount
        }
        discounts {
          name
          amount
        }
        paymentMethod {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        shippingAddress {
          key
          value
          default
        }
        group1
        group2
        group3
        consumerName
        sameOrderClass
        dateTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const OrderProductsByDetail = /* GraphQL */ `
  query OrderProductsByDetail(
    $orderId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrderProductsByDetail(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        productId
        unitId
        price
        quantity
        grossAmount
        discountAmount
        taxAmount
        netAmount
        shortnameLanguage1
        shortnameLanguage2
        shortnameLanguage3
        shortnameLanguage4
        imageInternalName
        imageExternalURL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const SyncLogBySupplier = /* GraphQL */ `
  query SyncLogBySupplier(
    $supplierId: ID
    $dateStatus: ModelSyncLogSyncLogBySupplierCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSyncLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SyncLogBySupplier(
      supplierId: $supplierId
      dateStatus: $dateStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supplierId
        consumerId
        date
        dateStart
        dateEnd
        syncId
        process
        typeProcess
        status
        isOk
        isWarning
        message
        errors
        systemMessage
        sourceCount
        inserted
        updated
        noupdated
        errored
        errorNumber
        totalDuration
        extractDuration
        transformDuration
        loadDuration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const StatementBySupplier = /* GraphQL */ `
  query StatementBySupplier(
    $supplierId: ID
    $monthYear: ModelStatementBySupplierCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StatementBySupplier(
      supplierId: $supplierId
      monthYear: $monthYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        month
        paddedMonth
        supplierId
        supplier {
          id
          name
          businessName
          username
          taxID
          promotions {
            id
            description
            imageInternalName
            imageExternalURL
            since
            until
            active
          }
          status
          historyStatus {
            date
            status
          }
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          contactName
          contactEmail
          latitude
          longitude
          summary
          createdAt
          approvedAt
          industries {
            items {
              id
              supplierId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          currencyISO
          currencySymbol
          currencyISOErp
          contract {
            current {
              since
              plan
              maxTransaction
            }
            future {
              since
              plan
              maxTransaction
            }
            history {
              since
              plan
              maxTransaction
            }
          }
          isXSM
          imageInternalName
          imageExternalURL
          taxPercentage
          discountPercentage
          groups1
          groups2
          groups3
          productTags
          paymentMethods {
            key
            value
            valueLanguage1
            valueLanguage2
            valueLanguage3
            valueLanguage4
            default
          }
          productSameOrderClasses
          updatedAt
        }
        contractDetail {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        count {
          deposit
          payment
          order
        }
        amount {
          deposit
          payment
          order
          orderUSD
          paymentUSD
          depositUSD
        }
        avgItem {
          deposit
          payment
          order
        }
        avgAmount {
          deposit
          payment
          order
          orderUSD
          paymentUSD
          depositUSD
        }
        itemCount {
          deposit
          payment
          order
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const StatementBySupplierYearPaddedMonth = /* GraphQL */ `
  query StatementBySupplierYearPaddedMonth(
    $supplierId: ID
    $yearPaddedMonth: ModelStatementBySupplierYearPaddedMonthCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StatementBySupplierYearPaddedMonth(
      supplierId: $supplierId
      yearPaddedMonth: $yearPaddedMonth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        month
        paddedMonth
        supplierId
        supplier {
          id
          name
          businessName
          username
          taxID
          promotions {
            id
            description
            imageInternalName
            imageExternalURL
            since
            until
            active
          }
          status
          historyStatus {
            date
            status
          }
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          contactName
          contactEmail
          latitude
          longitude
          summary
          createdAt
          approvedAt
          industries {
            items {
              id
              supplierId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          currencyISO
          currencySymbol
          currencyISOErp
          contract {
            current {
              since
              plan
              maxTransaction
            }
            future {
              since
              plan
              maxTransaction
            }
            history {
              since
              plan
              maxTransaction
            }
          }
          isXSM
          imageInternalName
          imageExternalURL
          taxPercentage
          discountPercentage
          groups1
          groups2
          groups3
          productTags
          paymentMethods {
            key
            value
            valueLanguage1
            valueLanguage2
            valueLanguage3
            valueLanguage4
            default
          }
          productSameOrderClasses
          updatedAt
        }
        contractDetail {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        count {
          deposit
          payment
          order
        }
        amount {
          deposit
          payment
          order
          orderUSD
          paymentUSD
          depositUSD
        }
        avgItem {
          deposit
          payment
          order
        }
        avgAmount {
          deposit
          payment
          order
          orderUSD
          paymentUSD
          depositUSD
        }
        itemCount {
          deposit
          payment
          order
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const StatementByPeriod = /* GraphQL */ `
  query StatementByPeriod(
    $month: Int
    $year: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StatementByPeriod(
      month: $month
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        month
        paddedMonth
        supplierId
        supplier {
          id
          name
          businessName
          username
          taxID
          promotions {
            id
            description
            imageInternalName
            imageExternalURL
            since
            until
            active
          }
          status
          historyStatus {
            date
            status
          }
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          contactName
          contactEmail
          latitude
          longitude
          summary
          createdAt
          approvedAt
          industries {
            items {
              id
              supplierId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          currencyISO
          currencySymbol
          currencyISOErp
          contract {
            current {
              since
              plan
              maxTransaction
            }
            future {
              since
              plan
              maxTransaction
            }
            history {
              since
              plan
              maxTransaction
            }
          }
          isXSM
          imageInternalName
          imageExternalURL
          taxPercentage
          discountPercentage
          groups1
          groups2
          groups3
          productTags
          paymentMethods {
            key
            value
            valueLanguage1
            valueLanguage2
            valueLanguage3
            valueLanguage4
            default
          }
          productSameOrderClasses
          updatedAt
        }
        contractDetail {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        count {
          deposit
          payment
          order
        }
        amount {
          deposit
          payment
          order
          orderUSD
          paymentUSD
          depositUSD
        }
        avgItem {
          deposit
          payment
          order
        }
        avgAmount {
          deposit
          payment
          order
          orderUSD
          paymentUSD
          depositUSD
        }
        itemCount {
          deposit
          payment
          order
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const StatementByYearAndPeriod = /* GraphQL */ `
  query StatementByYearAndPeriod(
    $year: Int
    $month: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StatementByYearAndPeriod(
      year: $year
      month: $month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        month
        paddedMonth
        supplierId
        supplier {
          id
          name
          businessName
          username
          taxID
          promotions {
            id
            description
            imageInternalName
            imageExternalURL
            since
            until
            active
          }
          status
          historyStatus {
            date
            status
          }
          address {
            type
            street1
            street2
            street3
            city
            cityId
            state
            stateId
            zipcode
            country
            countryId
            default
            updatedOn
          }
          phones {
            type
            number
            default
            updatedOn
          }
          website
          email
          contactName
          contactEmail
          latitude
          longitude
          summary
          createdAt
          approvedAt
          industries {
            items {
              id
              supplierId
              industryId
              createdAt
              updatedAt
            }
            nextToken
          }
          currencyISO
          currencySymbol
          currencyISOErp
          contract {
            current {
              since
              plan
              maxTransaction
            }
            future {
              since
              plan
              maxTransaction
            }
            history {
              since
              plan
              maxTransaction
            }
          }
          isXSM
          imageInternalName
          imageExternalURL
          taxPercentage
          discountPercentage
          groups1
          groups2
          groups3
          productTags
          paymentMethods {
            key
            value
            valueLanguage1
            valueLanguage2
            valueLanguage3
            valueLanguage4
            default
          }
          productSameOrderClasses
          updatedAt
        }
        contractDetail {
          since
          plan
          fees {
            deposit
            payment
            order
          }
          minTransaction {
            deposit
            payment
            order
          }
          maxTransaction
        }
        count {
          deposit
          payment
          order
        }
        amount {
          deposit
          payment
          order
          orderUSD
          paymentUSD
          depositUSD
        }
        avgItem {
          deposit
          payment
          order
        }
        avgAmount {
          deposit
          payment
          order
          orderUSD
          paymentUSD
          depositUSD
        }
        itemCount {
          deposit
          payment
          order
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        productId
        supplierId
        unit
        subcategoryId
        descriptionLanguage1
        descriptionLanguage2
        descriptionLanguage3
        descriptionLanguage4
        nameLanguage1
        nameLanguage2
        nameLanguage3
        nameLanguage4
        shortnameLanguage1
        shortnameLanguage2
        shortnameLanguage3
        shortnameLanguage4
        tags {
          tag
        }
        price
        percentageDiscount
        amountDiscount
        blockedForSales
        blockedForSalesDate
        inactive
        inactiveOn
        inactiveParent
        inactiveParentOn
        imageInternalName
        imageExternalURL
        taxFree
        barcode
        sameOrderClass
        stock
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        supplierId
        consumerId
        consumerIdERP
        orderCodeERP
        date
        dateERP
        deliveryOn
        deliveryExpectedOn
        requestComment
        orderComments
        shipping
        status
        statusERP
        historyStatusERP {
          datetimeERP
          statusERP
        }
        grossAmount
        discountAmount
        taxAmount
        netAmount
        orderProducts {
          items {
            id
            orderId
            productId
            unitId
            price
            quantity
            grossAmount
            discountAmount
            taxAmount
            netAmount
            shortnameLanguage1
            shortnameLanguage2
            shortnameLanguage3
            shortnameLanguage4
            imageInternalName
            imageExternalURL
            createdAt
            updatedAt
          }
          nextToken
        }
        currencyISO
        currencyISOErpRequest
        currencyISOErp
        currencySymbol
        exchangeRate
        posted
        postedOn
        itemCount
        paymentCondition
        taxes {
          name
          amount
        }
        discounts {
          name
          amount
        }
        paymentMethod {
          key
          value
          valueLanguage1
          valueLanguage2
          valueLanguage3
          valueLanguage4
          default
        }
        shippingAddress {
          key
          value
          default
        }
        group1
        group2
        group3
        consumerName
        sameOrderClass
        dateTimestamp
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
