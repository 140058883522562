import React, { Fragment } from "react"
import { Filters, TabulatorTable, Title } from "xsales-components"
import { XSalesData } from "../../DataQuery/XSalesData"
// import Filters from "../../componentsConsumer/Filters"
import { XSalesConsumer } from "../../Utils/Utils"
import { I18n } from "@aws-amplify/core"

class index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lblLoadingData:"",
            objDataSetup:[],
            objData: {
                InitialData: [
                    {
                        businessName: "",
                        status: "",
                        requestedOn: "",
                        responsedOn: "",
                    },
                ],
                Data: [],
                Filter: [],
                Columns: [],
                RowClick: this.rowClickEventSupplier,
                dataFilters: []
            }
        }
    }

    rowClickEventSupplier = (e, row) => {
        let id = row.getData().id
        window.sessionStorage.setItem("pathRoot", this.props.match.path)
        this.props.history.push(`/Suppliers/Detail/${id}`)
    }

  
     AddStandAlone  = async (data)=> {
        let objData = this.state.objData.Data
        for (const item of data) {
            item["standAlone"] = (await XSalesData.Suppliers.GetSupplierStandaloneMode(item.id)) ;
            objData.push(item)
        }
       return objData
      }
    

    getLanguage  = async ()=> {
        try {
            let lang = localStorage.getItem("languageApp");
            if (!lang) {
              const [lang1] = navigator.language.split('-');
              lang = lang1.toUpperCase();
            }
            I18n.setLanguage(lang.toUpperCase());
          } catch (error) {
            I18n.setLanguage('EN');
            console.log(error.message);
          }
    }

    componentDidMount() {
        document.title = "Suppliers:List"
        this.getLanguage();
        this.setState({lblLoadingData: I18n.get("lblLoadingData")})
        const showOnlyXSM =
            this.props.match.params.XSM && this.props.match.params.XSM === "XSM"
                ? true
                : false
        let objData = this.state.objData
        let columns= [
            {
                title: I18n.get("lblSupName"),
                field: "businessName",
                widthGrow: 4,
            },
            {
                title: I18n.get("lblSupStatus"),
                field: "status",
                widthGrow: 1,
            },
            {
                title: I18n.get("lblSupRequestDate"),
                field: "requestedOn",
                widthGrow: 2,
                formatter:  (cell, formatterParams, onRendered) => (cell.getValue() ? XSalesConsumer.formatShortDate(cell.getValue()): cell.getValue())   
            },
            {
                title: I18n.get("lblSupApprovalDate"),
                field: "responsedOn",
                widthGrow: 2,
                 formatter:  (cell, formatterParams, onRendered) => (cell.getValue() ? XSalesConsumer.formatShortDate(cell.getValue()): cell.getValue())  
            },
            {
                title: I18n.get("lblStandAlone"),
                field: "standAlone",
                widthGrow: 1,
            },
        ]
        let dataFilters= [
            {
                label: I18n.get("lblALL"),
                handleClick: this.handleClickFilters,
                className:
                    "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "ALL",
                name: "ALL",
                type: "button",
                active: true,
            },
            {
                label: I18n.get("lblVALIDATING"),
                handleClick: this.handleClickFilters,
                className:
                    "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblVALIDATING",
                name: "lblVALIDATING",
                type: "button",
                active: false,
            },
            {
                label: I18n.get("lblCONFIGURING"),
                handleClick: this.handleClickFilters,
                className:
                    "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblCONFIGURING",
                name: "lblCONFIGURING",
                type: "button",
                active: false,
            },
            {
                label: I18n.get("lblACTIVE"),
                handleClick: this.handleClickFilters,
                className:
                    "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblACTIVE",
                name: "lblACTIVE",
                type: "button",
                active: false,
            },
            {
                label: I18n.get("lblINACTIVE"),
                handleClick: this.handleClickFilters,
                className:
                    "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblINACTIVE",
                name: "lblINACTIVE",
                type: "button",
                active: false,
            },
            {
                label: I18n.get("lblDELETED"),
                handleClick: this.handleClickFilters,
                className:
                    "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblDELETED",
                name: "lblDELETED",
                type: "button",
                active: false,
            },
            {
                label: I18n.get("lblREJECTED"),
                handleClick: this.handleClickFilters,
                className:
                    "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblREJECTED",
                name: "lblREJECTED",
                type: "button",
                active: false,
            },
            {
                label:String(I18n.get("lblStandAlone")).toUpperCase(),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblStandAlone",
                name: "lblStandAlone",
                type: "button",
                active: false
            },
            
        ]
        objData.Columns= columns;
        objData.dataFilters= dataFilters;
        XSalesData.Suppliers.GetSuppliers()
            .then(  async (data) => {
         
                if (showOnlyXSM) {
                    data = data.filter((x) => x.isXSM === true)
                }
               let arraydata = await this.AddStandAlone(data)
               
                objData.Data = arraydata.map((item)  => {

                    return {
                        id: item.id,
                        businessName: item.businessName,
                        status: I18n.get("lbl" + item.status),
                        requestedOn: item.createdAt,
                        responsedOn: item.approvedAt,
                        standAlone:item.standAlone,
                      
                    }

                })

            })
            .catch((error) => {
                objData.Data = error.data.listSuppliers.items.map((item) => {
                    return {
                        id: item.id,
                        businessName: item.businessName,
                        status: I18n.get("lbl" + item.status),
                        requestedOn: XSalesConsumer.getDateFormatter(
                            item.createdAt
                        ),
                        responsedOn: XSalesConsumer.getDateFormatter(
                            item.approvedAt
                        ),
                        standAlone:objData.Data.standAlone,
                    }
                })
                // this.setState({ objData: objData })
            })
            .finally((fin) => {
            this.setState({ objData: objData });

            let filters = window.sessionStorage.getItem("filtersSuppliers")
                if(filters !== 'null' && filters !== null)
                {
                    this.EventFilterChange(filters)
                    const elementFilter = document.getElementById(filters)
                    if(elementFilter !== null)
                    {
                        elementFilter.classList.add("btn__filters__active--component")
                    }
                    
                } 
            
          })
    }

    EventFilterChange=(id)=>
    {
        const buttons = document.querySelectorAll(".btn__filters__active--component")
        buttons.forEach((btn) => {
            
            if (btn.id === id)
            {
                btn.classList.add("btn__filters__active--component")
            }
            else
            {
                btn.classList.remove("btn__filters__active--component")
            }
        })
        let objData = this.state.objData;
        switch (id) {
            case "ALL":
                objData.Filter = []
                break;
            case "lblVALIDATING":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblVALIDATING")] },
                ]
                break;
            case "lblCONFIGURING":
                objData.Filter = [
                    {
                        field: "status",
                        type: "in",
                        value: [I18n.get("lblCONFIGURING")],
                    },
                ]
                break;
            case "lblACTIVE":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblACTIVE")] },
                ]
                break;
            case "lblINACTIVE":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblINACTIVE")] },
                ]
                break;
            case "lblDELETED":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblDELETED")] },
                ]
                break;
            case "lblREJECTED":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblREJECTED")] },
                ]
                break;
            case "lblStandAlone":
                objData.Filter = [
                    { field: "standAlone", type: "in", value: ["true"] },
                ]
                break;
            default:
                objData.Filter = []
                break;
        }

        this.setState({ objData: objData })
    }


    handleClickFilters= (e) =>{
        let { id } = e.target; 
        if (id!=='ALL'){
            const hasClass = e.target.classList.contains('btn__filters__active--component');
            if (hasClass===false){
                id="ALL";
            } 
        }
        this.EventFilterChange(id)

       
        window.sessionStorage.setItem("filtersSuppliers", id)
    }

   

    /*onlyValidating = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            { field: "status", type: "in", value: [I18n.get("lblVALIDATING")] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyConfiguring = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            {
                field: "status",
                type: "in",
                value: [I18n.get("lblCONFIGURING")],
            },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyActive = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            { field: "status", type: "in", value: [I18n.get("lblACTIVE")] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyInactive = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            { field: "status", type: "in", value: [I18n.get("lblINACTIVE")] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyDeleted = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            { field: "status", type: "in", value: [I18n.get("lblDELETED")] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyRejected = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            { field: "status", type: "in", value: [I18n.get("lblREJECTED")] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }
    onlyStandAlone = (e) => {
        XSalesConsumer.ChangeColorFilter(e)

        let objData = this.state.objData
        objData.Filter = [
            { field: "standAlone", type: "in", value: ["true"] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    
    ALL = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = []
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }*/

    lista = () => {
        return (
            <Fragment>
                <li className="lista" onClick={this.ALL}>
                    {I18n.get("lblALL")}
                </li>
                <li className="lista" onClick={this.onlyValidating}>
                    {I18n.get("lblVALIDATING")}
                </li>
                <li className="lista" onClick={this.onlyConfiguring}>
                    {I18n.get("lblCONFIGURING")}
                </li>
                <li className="lista" onClick={this.onlyActive}>
                    {I18n.get("lblACTIVE")}
                </li>
                <li className="lista" onClick={this.onlyInactive}>
                    {I18n.get("lblINACTIVE")}
                </li>
                <li className="lista" onClick={this.onlyDeleted}>
                    {I18n.get("lblDELETED")}
                </li>
                <li className="lista" onClick={this.onlyRejected}>
                    {I18n.get("lblREJECTED")}
                </li>
                <li className="lista" onClick={this.onlyStandAlone}>
                    {I18n.get("lblStandAlone")}
                </li>
            </Fragment>
        )
    }

    render() {
        return (
            <Fragment>
            
                
                    <Title label={ I18n.get("lblSuppliers")} />
                    <Filters filterName="filterSuppliers" data={this.state.objData.dataFilters}/>
                
                 <div style={{marginTop:"30px"}}>
                    <TabulatorTable
                        Title=""
                        Data={this.state.objData.Data}
                        Columns={this.state.objData.Columns}
                        Filter={this.state.objData.Filter}
                        RowClick={this.state.objData.RowClick}
                        placeholder={this.state.lblLoadingData}
                        Icons={false}
                        Search={true}
                        labelEmptyData={I18n.get("lblnodataavailabletabulator") }
                        downloadIcon={true}   
                        lblSearch= {I18n.get("lblSearch")} 
                   />
               </div>    
                   </Fragment>
           
        )
    }
}

export default index
