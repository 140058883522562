import React, { useEffect, useState, Fragment } from 'react'
import { XSalesConsumer } from '../../../Utils/Utils'
import { XSalesData } from '../../../DataQuery/XSalesData'
import { I18n } from 'aws-amplify'

export default function CountSupplier(props) {

    const [isAllowed, setIsAllowed] = useState(false)
    const [supplierTotal, setSupplierTotal] = useState(0)
    const [supplierXSM, setSupplierXSM] = useState(0)

    useEffect(() => {
        const isAllowed = XSalesConsumer.getCurrentUser().then(data => {
            return data.type.toUpperCase() === "ADMIN" ? true : false 
        }).catch(err => {
            console.log('you are not admin or support role')
            return false
        })
        setIsAllowed(isAllowed)
        XSalesData.Suppliers.GetSuppliers().then(data => {
            setSupplierTotal(data.length)
            setSupplierXSM(data.filter(x => x.isXSM === true).length)
        }).catch()
    }, [])

    return (
        <Fragment>
            {
                (isAllowed) 
                ?   
                <div className={"col-" + props.Layout}>
                    <div className="col-sm-12">
                        <div className="info-box">
                            <span className="info-box-icon bg-success elevation-1">
                                <i className="fas fa-users" />
                            </span>
                            <div className="info-box-content">
                            <span className="info-box-text">{I18n.get('lblSuppliers')}</span>
                            <span className="info-box-number">
                                <div className="row">
                                    <a className="col-9" href='/Suppliers/Index'>
                                        {I18n.get('lblSupplierTotal')}
                                    </a>
                                    <div className="col-3" style={{ textAlign: 'right' }}>
                                        {supplierTotal}
                                    </div>
                                </div>
                                <div className="row">
                                    <a className="col-9" href='/Suppliers/Index/XSM'>
                                        {I18n.get('lblIsSupplierXSM')}
                                    </a>
                                    <div className="col-3" style={{ textAlign: 'right' }}>
                                        {supplierXSM}
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                : ""
            }
        </Fragment>
    )
}
