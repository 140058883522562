import React, { useState, useEffect, Fragment } from "react"
import Button from "./Button"

import { XSalesConsumer } from "./../Utils/Utils"
import { XSalesData } from "./../DataQuery/XSalesData"
import * as db from "../models"
import { I18n } from "aws-amplify"

//import loadingLine from "../assets/img/loadingLine.gif"
import PreferencesDefault from "../configConsumer/preferenceDefault.json"
import ImageCDN from "../configConsumer/imagesDefaultCDN.json"
const loadingLine = ImageCDN.loadingLine
export default function SyncData(props) {
    const [syncLoad, setSyncLoad] = useState(true)
    const [syncMessage, setSyncMessage] = useState("")

    const sendToSignIn = async () => {
        window.location.href = PreferencesDefault.XSalesConsumerWEB
        // props.history.push(PreferencesDefault.XSalesConsumerWEB)
    }

    const validation = async () => {
        try {
            setSyncLoad(true)
            const userCurrent = await XSalesConsumer.getCurrentUser()
            const [
                supplierByUser,
            ] = await XSalesData.Suppliers.GetSupplierByUser(
                userCurrent.username
            )
            if (supplierByUser) {
                userCurrent.supplier = supplierByUser

            //actualizar setups por default
            await XSalesData.Setup.SetSystemSetupDefaults();            

            userCurrent.language = await XSalesData.Suppliers.GetSupplierSetup(userCurrent.supplier.id,"CW001")
            //if (Object.keys(userCurrent.language).length > 0) {
            if (userCurrent.language){
                    I18n.setLanguage(userCurrent.language.value)
                } else {
                    I18n.setLanguage(PreferencesDefault.Language)
                }

                await XSalesConsumer.saveLanguagesSupplierPreferences(
                    userCurrent.supplier.id
                )
                const setupsCdn = await XSalesData.Setup.getPathsCDNSetups()
                localStorage.setItem("cdnSetups", JSON.stringify(setupsCdn))
            
            await XSalesData.Setup.getSetups(userCurrent?.supplier?.id)

                XSalesConsumer.setCurrentUser(userCurrent)
                if (userCurrent.supplier) {
                    if (
                        userCurrent.supplier.status ===
                            db.StatusSupplier.ACTIVE ||
                        userCurrent.supplier.status ===
                            db.StatusSupplier.CONFIGURING
                    ) {
                        await setLanguage()
                        window.location.href = "/Main"
                    } else if (
                        userCurrent.supplier.status ===
                        db.StatusSupplier.VALIDATING
                    ) {
                        setSyncLoad(false)
                        setSyncMessage(I18n.get("lblWaitForAcceptedI"))
                    } else if (
                        userCurrent.supplier.status ===
                            db.StatusSupplier.DELETED ||
                        userCurrent.supplier.status ===
                            db.StatusSupplier.INACTIVE ||
                        userCurrent.supplier.status ===
                            db.StatusSupplier.DRAFT ||
                        userCurrent.supplier.status ===
                            db.StatusSupplier.REJECTED
                    ) {
                        setSyncLoad(false)
                        setSyncMessage(I18n.get("lblSupplierDeleted"))
                    }
                } else {
                    props.history.push("/Registration")
                    return
                }
            } else {
                let obj = {}
                obj.user = userCurrent.email
                obj.email = userCurrent.email
                obj.type = "SUPPLIER"
                obj.username = userCurrent.username
                obj.language = "EN"
                obj.supplier = {}
                XSalesConsumer.setCurrentUser(obj)
                // window.location.href = "/Registration"
                props.history.push("/Registration")
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        validation()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    async function setLanguage() {
        try {
            const id = await XSalesConsumer.getCurrentUser()
                .then((data) => {
                    if (data.type.toUpperCase() !== "SUPPLIER") {
                        return data.user
                    }
                    return data.supplier.id
                })
                .catch((err) => {
                    console.error(err)
                    return ""
                })

            const preferences = await XSalesData.Suppliers.GetPreferences(id)
            if (preferences.language) {
                localStorage.setItem("languageApp", preferences.language)
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    return (
        <Fragment>
            <div
                style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "10%",
                }}
            >
                <img
                    style={{ width: "200px" }}
                    src={ImageCDN.XSalesConsumerWebLogo}
                    alt="Consumer logo"
                />
            </div>

            <div
                style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "10%",
                }}
            >
                {syncLoad ? (
                    <Fragment>
                        <img src={loadingLine} alt="Loading gif" />
                        <p style={{ height: "2rem" }}>
                            {I18n.get("lblSincronizing")}
                        </p>
                    </Fragment>
                ) : (
                    <div
                        style={{
                            width: "100%",
                            textAlign: "center",
                            marginTop: "10%",
                        }}
                    >
                        <p className="waitAcceptedMessage">
                            {syncMessage}
                            <a
                                href={
                                    "mailto:" +
                                    PreferencesDefault.EmailAccountSupport
                                }
                            >
                                {PreferencesDefault.EmailAccountSupport}
                            </a>
                            {I18n.get("lblWaitForAcceptedII")}
                            <a href={PreferencesDefault.XSalesConsumerWEB}>
                                {PreferencesDefault.XSalesConsumerWEB}
                            </a>
                        </p>
                        <div
                            className="col-sm-12"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                id="goToSignIn"
                                name="goToSignIn"
                                label={I18n.get("lblGoToConsumerPage")}
                                className="col-sm-2 btn btn-block btn-outline-primary bounds-button"
                                onClick={() => sendToSignIn()}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    )
}
