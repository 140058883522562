import React, { useEffect, useState } from 'react'
import  CardPlan  from './CardPlan'
// import styled from 'styled-components'
import { LoaderWindow, Modal } from 'xsales-components'
// import { XSalesConsumer } from '../../Utils/Utils'
import { XSalesData } from '../../DataQuery/XSalesData'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import DefaultPreference from '../../configConsumer/preferenceDefault.json'
import { I18n } from '@aws-amplify/core'


const TableHistory = ({ dataH = [] }) => (
    <table className="table table-sm">
        <thead>
            <tr>
                <th>{I18n.get("lblplancontract")}</th>
                <th>{I18n.get("lblfeecontract")}</th>
                <th>{I18n.get("lblmintransactioncontract")}</th>
                <th>{I18n.get("lblsincecontract")}</th>
            </tr>
        </thead>
        <tbody>
            {
                dataH.map((obj) => (
                    <tr>
                        <td>{obj.plan}</td>
                        <td>{`${Number(obj.fee).toFixed(2)} USD`}</td>
                        <td>{Number(obj.minTransaction).toFixed(2)}</td>
                        <td>{moment(obj.since).format("L")}</td>
                    </tr>
                ))
            }
        </tbody>
    </table>
)

const firstDayOfMonth = moment().startOf('month').toDate().toISOString()
const firstDayOfNextMonth = moment().add(1, 'month').startOf('month')
export const ContractDefinition = (props) => {
    const [elementClick, setElementClick] = useState(null)
    const [isCustom, setIsCustom] = useState(false)
    const [formValues, setFormValues] = useState({
        since: firstDayOfNextMonth.toDate().toISOString(),
        fees: {
            payment: 0,
            deposit: 0,
            order: DefaultPreference.Plans.Basic.fee
        },
        minTransaction: {
            payment: 0,
            deposit: 0,
            order: DefaultPreference.Plans.Basic.minTransactions
        },
        plan: "SAP ECC or S/4HANA Integration"
    })
    const [planBasic, setPlanBasic] = useState({
        title: "Current",
        id: "basic",
        sinceValue: "",
        planValue: "No ERP Integration",
        feeValue: `${DefaultPreference.Plans.Basic.fee}`,
        setupValue:`${DefaultPreference.Plans.Basic.setupValue}`,
        minTransactionValue: `${DefaultPreference.Plans.Basic.minTransactions}`,
        maxTransactionValue: `${DefaultPreference.Plans.Basic.maxTransactions}`,
        planDescriptions: DefaultPreference.Plans.Basic.descriptions,
        handleChange: handleChange,
        handleChangeSelect: handleChangeSelect,
        handleClick: handleClick,
        handleClickCard: handleClickPlans,
        isCustom: false,
        isCurrent: false,
        isFuture: false,
        isEditable: false,
        handleClickEditIcon: handleClickEditIcon,
        planSelect: formValues.plan
    })
    const [planPremium, setPlanPremium] = useState({
        title: "Current",
        id: "premium",
        sinceValue: "",
        planValue: "SAP ECC or S/4HANA Integration",
        feeValue: `${DefaultPreference.Plans.Premium.fee}`,
        setupValue:`${DefaultPreference.Plans.Premium.setupValue}`,
        minTransactionValue: `${DefaultPreference.Plans.Premium.minTransactions}`,
        maxTransactionValue: `${DefaultPreference.Plans.Premium.maxTransactions}`,
        planDescriptions: DefaultPreference.Plans.Premium.descriptions,
        handleChange: handleChange,
        handleClick: handleClick,
        handleClickCard: handleClickPlans,
        isCustom: false,
        isCurrent: false,
        isFuture: false,
        isEditable: false,
        handleClickEditIcon: handleClickEditIcon,
        planSelect: formValues.plan
    })
    const [planFuture, setPlanFuture] = useState({
        title: I18n.get("lblfuture"),
        id: "future",
        sinceValue: "",
        planValue: "",
        feeValue: `${DefaultPreference.Plans.Premium.fee}`,
        setupValue:`${DefaultPreference.Plans.Premium.setupValue}`,
        minTransactionValue: `${DefaultPreference.Plans.Premium.minTransactions}`,
        maxTransactionValue: `${DefaultPreference.Plans.Premium.maxTransactions}`,
        planDescriptions: DefaultPreference.Plans.Premium.descriptions,
        handleChange: handleChange,
        handleChangeSelect: handleChangeSelect,
        handleClick: handleClick,
        handleClickCard: () => { },
        isCustom: false,
        isCurrent: false,
        isFuture: true,
        isEditable: false,
        handleClickEditIcon: handleClickEditIcon,
        planSelect: formValues.plan
    })
    const [showFuture, setShowFuture] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showSeeMore, setShowSeeMore] = useState(false)
    const [dataHistory, setDataHistory] = useState([])
    const { id } = useParams();

    function handleClickEditIcon(e) {
        /*const objCustom = planCustom;
        objCustom.isEditable = true
        setPlanCustom({ ...objCustom })*/
    }

    function handleChangeSelect(e) {
        const obj = formValues
        obj.plan = e.value;
        setFormValues(obj)
    }

    function handleChange(e) {
        const element = e.target;
        const obj = formValues
        switch (element.name) {
            case "fee-input":
                obj.fees.order = Number(element.value)
                setFormValues(obj)
                break;
            case "min-transaction-input":
                obj.minTransaction.order = Number(element.value)
                setFormValues(obj)
                break;
            default:
                break;
        }
    }

    async function handleClick(e) {
        const { target } = e;
        switch (target.name) {
            case "btnSave":
                try {
                    setIsCustom(true)
                    window.$("#modalConfirm").modal("toggle")
                } catch (err) {
                    console.log(err)
                }
                break;
            case "btnCancel":
                break;
            default:
                break;
        }
       /* const objCustom = planCustom;
        objCustom.isEditable = false
        setPlanCustom({ ...objCustom })*/
    }

    useEffect(function () {
        //setPlanBasic({isCurrent: true});
        getData()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    function getData() {
        XSalesData.Suppliers.GetSupplierContract(id)
            .then((data) => {
                setPlanFromApi(data)
            }).catch((err) => {
                console.error("error", err)
            })
    }

    function setPlanFromApi(data) {
        if (data.contract) {
            let plan = data.contract.current.plan;
            switch (plan) {
                case "No ERP Integration":
                    const basicObj = planBasic;
                    basicObj.title= "No ERP Integration"
                    basicObj.feeValue = data.contract.current.fees.order
                    basicObj.minTransactionValue = data.contract.current.minTransaction.order
                    basicObj.maxTransactionValue =DefaultPreference.Plans.Basic.maxTransactions
                    basicObj.sinceValue = moment(data.contract.current.since).format("L")
                    basicObj.planValue = "No ERP Integration" //data.contract.current.plan
                    basicObj.isCurrent = true;
                    setPlanBasic({ ...basicObj })
                    break;
                case "SAP ECC or S/4HANA Integration":
                    const premiumObj = planPremium;
                    premiumObj.title= "SAP ECC or S/4HANA Integration"
                    premiumObj.feeValue = data.contract.current.fees.order
                    premiumObj.minTransactionValue = data.contract.current.minTransaction.order
                    premiumObj.maxTransactionValue = DefaultPreference.Plans.Premium.maxTransactions
                    premiumObj.sinceValue = moment(data.contract.current.since).format("L")
                    premiumObj.planValue = "SAP ECC or S/4HANA Integration" //data.contract.current.plan
                    premiumObj.isCurrent = true;
                    setPlanPremium({ ...premiumObj })
                    break;
                default:
                    break;
            }

            if (data.contract.future) {
                setShowFuture(true)
                const objFuture = planFuture;
                objFuture.feeValue = data.contract.future.fees.order
                objFuture.minTransactionValue = data.contract.future.minTransaction.order
                objFuture.maxTransactionValue = data.contract.future.maxTransaction
                objFuture.sinceValue = moment(data.contract.future.since).format("L")
                objFuture.planValue = data.contract.future.plan
                setPlanFuture({ ...objFuture })
            }
        }
    }

    async function handleClickCard(element) {
        //const element = e.currentTarget;
        const { plan } = element.dataset
        const objPremium = planPremium;
        const objBasic = planBasic;
        const objFuture = planFuture;
        let contractType = "current"
        try {
            setLoading(true)
            const data = await XSalesData.Suppliers.GetSupplierContract(id)
            if (data.contract == null) {
                data.contract = {
                    current: null,
                    future: null,
                    history: []
                }
            }
            if (data.contract.current){
                contractType = "future";
            }
            const since = contractType === "current" ? firstDayOfMonth : firstDayOfNextMonth.toDate().toISOString()
            switch (plan) {
                case "SAP ECC or S/4HANA Integration":
                    const callbackStateP = contractType === "current" ? setPlanPremium : setPlanFuture
                    let objP = contractType === "current" ? objPremium : objFuture
                    objP.isCurrent = contractType === "current" ? true : false
                    objP.isFuture = contractType === "future" ? true : false
                    try {
                        const values = { ...formValues };
                        values.plan = "SAP ECC or S/4HANA Integration";
                        values.minTransaction.order = Number(objPremium.minTransactionValue);
                        values.maxTransaction = Number(objPremium.maxTransactionValue);
                        values.fees.order = Number(objPremium.feeValue);
                        values.since = since;
                        await XSalesData.Suppliers.SetSupplierContract(id, values, contractType);
                        if (contractType === "future") {
                            objP.minTransactionValue = Number(objPremium.minTransactionValue)
                            objP.maxTransactionValue = Number(objPremium.maxTransactionValue)
                            objP.feeValue = Number(objPremium.feeValue)
                            objP.sinceValue = firstDayOfNextMonth.format("L")
                            objP.planValue = "SAP ECC or S/4HANA Integration"
                        }
                        callbackStateP({ ...objP })
                    } catch (error) {
                        console.log(error)
                    }
                    objPremium.isCurrent= true;
                    objBasic.isCurrent= false;
                    break;
                case "No ERP Integration":
                    const callbackStateB = contractType === "current" ? setPlanBasic : setPlanFuture
                    let objB = contractType === "current" ? objBasic : objFuture
                    objB.isCurrent = contractType === "current" ? true : false
                    objB.isFuture = contractType === "future" ? true : false
                    try {
                        const values = { ...formValues };
                        values.plan = "No ERP Integration";
                        values.minTransaction.order = Number(objBasic.minTransactionValue);
                        values.maxTransaction = Number(objBasic.maxTransactionValue);
                        values.fees.order = Number(objBasic.feeValue);
                        values.since = since;
                        await XSalesData.Suppliers.SetSupplierContract(id, values, contractType);
                        if (contractType === "future") {
                            objB.minTransactionValue = Number(objBasic.minTransactionValue)
                            objB.maxTransactionValue = Number(objBasic.maxTransactionValue)
                            objB.feeValue = Number(objBasic.feeValue)
                            objB.sinceValue = firstDayOfNextMonth.format("L")
                            objB.planValue = "No ERP Integration"
                        }
                        callbackStateB({ ...objB })
                    } catch (error) {
                        console.log(error)
                    }
                    objPremium.isCurrent= false;
                    objBasic.isCurrent= true;
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error.message)
        }
        setLoading(false)
        if (contractType === "future") setShowFuture(true)
    }

    async function handleClickSeeMore(e) {
        e.preventDefault()
        setShowSeeMore(!showSeeMore)
        try {
            const data = await XSalesData.Suppliers.GetSupplierContract(id)
            if (data.contract) {
                const dataContrat = data.contract.history.map(x => {
                    return {
                        plan: x.plan,
                        fee: x.fees.order,
                        minTransaction: x.minTransaction.order,
                        since: x.since
                    }
                })
                dataContrat.sort((a, b) => new Date(b.since).getTime() - new Date(a.since).getTime());
                setDataHistory(dataContrat.slice(0, 5))
            }
        } catch (err) {
            console.log(err)
        }
    }

    function handleCancelModal() {
        return
    }

    async function handleSaveModal(e) {
        debugger
        if (isCustom) {
            setLoading(true)
            if (formValues.plan === "") {
                return
            }
            await XSalesData.Suppliers.SetSupplierContract(id, formValues);
            await getData()
            setLoading(false)
            setIsCustom(false)
        }else{
            handleClickCard(elementClick)
        }
    }

    function handleClickPlans(e) {
        const element = e.currentTarget;
        setElementClick(element)
        window.$("#modalConfirm").modal("toggle")
    }

    return (
        <div>
            {
                loading ?
                    <LoaderWindow />
                    :
                    <>
                        <div className="main__section__cards" style={{display: '-webkit-box'}}>
                            <CardPlan {...planBasic}/>
                           { /*<CardPlan {...planPremium} />*/
                           showFuture &&
                           <div style={{marginLeft: "40px"}}>
                                <CardPlan {...planFuture} />
                           </div>
                           }
                        </div>
                        {
                           /* showFuture &&
                            <div className="future__section_cards">
                                
                            </div>*/
                        }
                        <div>
                            <span style={{ textDecoration: "underline", cursor: "pointer", color: "#3766FB" }} onClick={handleClickSeeMore}>{I18n.get("lblhistory")}...</span>
                        </div>
                        {
                            showSeeMore &&
                            <div className="mt-5" style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                <div style={{ width: "40%", textAlign: "left" }}>
                                    <h5>{I18n.get("lblhistory")}</h5>
                                    <TableHistory dataH={dataHistory} />
                                </div>
                            </div>
                        }

                    </>
            }
            <Modal
                id="modalConfirm"
                title={I18n.get("lblconfirm")}
                lblbtnCancel={I18n.get("lblCancel")}
                lblbtnSave={I18n.get("lblcontinue")}
                handleCancel={handleCancelModal}
                handleSave={handleSaveModal}
                noAllowSave={false}
                size="normal"
            >
                <p>{I18n.get("lblmessageconfirmcontract")}</p>
            </Modal>
        </div>
    )
}