import React, { useEffect, useRef, useState } from "react"
import { I18n } from "@aws-amplify/core"
import { TabulatorTable, LoaderWindow, Title, Modal, Confirm, AlertMessage, Toastify, useToast, TextMessage } from "xsales-components"
import { ImageUrlTypes, XSalesData } from "../../../DataQuery/XSalesData"
import { XSalesConsumer } from "../../../Utils/Utils"
//import imgCDN from "../../../configConsumer/imagesDefaultCDN.json"
//import styled from "styled-components"
import { FormCategory } from "./FormCategory"
import { v4 as uuidv4 } from "uuid"

let supplierg = {}

export function Category() {
    const [alertMessageImage, setAlertMessageImage] = useState({
        title: "",
        message: "",
        show: false,
        type: "warning",
    })
    // const History = useHistory()
    const { notifyAsync } = useToast({
        pending: I18n.get("lblprocess"),
        success: I18n.get("lblsuccess"),
        error: I18n.get("lblprocesserror"),
    })
    const formRef = useRef(null)
    //const [estSw, setestSw] = useState(false)
    const [UpdateAction, setUpdateAction] = useState(true)
    const [showAlertMessage, setShowAlertMessage] = useState({
        show: false,
        lblMessage: "",
        type: "danger",
    })
    const [supplier, setSupplier] = useState(null)
    const [loader, setLoader] = useState(true)
    //const [imageFile, setImageFile] = useState(null)
    const [stateRead, setStateRead] = useState(false)

    const [stateImageLoad, setStateImageLoad] = useState(false)

    const [dataCategory, setDataCategory] = useState({
        categoryId: "",
        supplierId: "",
        nameLanguage1: "",
        nameLanguage2: "",
        nameLanguage3: "",
        nameLanguage4: "",
        inactive: false,
        imageInternalName: [],
        imageExternalURL: [],
        imageFiles: [],
        inactiveOn: "",
    })
    const [dataModal, setDataModal] = useState({
        title: "",
        mode: "CREATE",
    })
    const [tabulatorProps, setTabulatorProps] = useState({
        filter: [],
        data: [],
        languages: [],
        columns: [],
    })
    async function getColumsTabulator() {
        let columns = [
            {
                title: I18n.get("lblDashboard"),
                field: "categoryId",
                widthGrow: 0.2,
            },
            {
                title: I18n.get("lbllnamelanguage1"),
                field: "nameLanguage1",
                widthGrow: 1,
            },
            {
                title: I18n.get("lbllnamelanguage2"),
                field: "nameLanguage2",
                widthGrow: 1,
            },
            {
                title: I18n.get("lbllnamelanguage3"),
                field: "nameLanguage3",
                widthGrow: 1,
            },
            {
                title: I18n.get("lbllnamelanguage4"),
                field: "nameLanguage4",
                widthGrow: 1,
            },
            {
                title: I18n.get("lblOnlyRead"),
                field: "readOnly",
                widthGrow: 1,
                visible: false,
            },
            {
                title: I18n.get("lblactive"),
                field: "inactive",
                widthGrow: 0.15,
                cellClick: async (e, cell) => {
                    e.preventDefault()
                    e.stopPropagation()
                    const data = cell.getData()
                    cell.setValue(!data.inactive)

                    if (!data.readOnly) {
                        const message = !data.inactive ? I18n.get("lblmsgconfirmactive") : I18n.get("lblmsgconfirminactive")

                        const confirm = await Confirm({
                            title: I18n.get("lblconfirmation"),
                            message: message,
                        })

                        if (!confirm) {
                            cell.setValue(!data.inactive)

                            return
                        }

                        data.supplierId = supplierg.id
                        data.inactiveOn = data.inactive ? new Date().toISOString() : ""
                        await XSalesData.Category.edit(data)
                        getData()
                    } else {
                        cell.setValue(!data.inactive)
                        return
                    }
                },
                formatter: (cell, formatterParams, onRenderedm) => {
                    const id = uuidv4()
                    const { inactive } = cell.getData()
                    return `<div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" ${!inactive && "checked"}  id="${id}" />
                    <label className="custom-control-label"  for='${id}' ></label>
                  </div>`
                },
            },
        ]
        setTabulatorProps((prevState) => ({
            ...prevState,
            columns: columns,
        }))
    }
    async function getData() {
        try {
            const { supplier } = await XSalesConsumer.getCurrentUser()
            setSupplier(supplier)
            supplierg = supplier

            const CW019 = await XSalesData.Suppliers.GetSupplierStandaloneMode(supplier.id)
            const CW024 = await XSalesData.Suppliers.GetSupplierAdminProductsMode(supplier.id)
            if (CW019 && CW024){
                if (CW019 === "true" && CW024 === "true") {
                    setStateRead(true)
                }
            }
            const data = await XSalesData.Category.list(supplier.id)

            const dataTabulator = data.map((x) => ({
                categoryId: x.categoryId,
                nameLanguage1: x.nameLanguage1,
                nameLanguage2: x.nameLanguage2,
                nameLanguage3: x.nameLanguage3,
                nameLanguage4: x.nameLanguage4,
                inactive: x.inactive,
                imageInternalName: x.imageInternalName,
                imageExternalURL: x.imageExternalURL,
                imageFiles: XSalesConsumer.getImageFiles(supplier.id, x.imageInternalName, x.imageExternalURL, ImageUrlTypes.Types.categories),
                inactiveOn: x.inactiveOn,
                readOnly: CW019 === "true" && CW024 === "true" ? true : false,
            }))
            setTabulatorProps((prevState) => ({
                ...prevState,
                data: dataTabulator,
            }))

            setLoader(false)
        } catch (error) {
            console.error(error)
            setLoader(false)
        }
    }

    async function getLanguage() {
        try {
            let lang = localStorage.getItem("languageApp")
            if (!lang) {
                const [lang1] = navigator.language.split("-")
                lang = lang1.toUpperCase()
            }
            I18n.setLanguage(lang.toUpperCase())
            const languages = XSalesConsumer.getLanguagesSupplierPreferences()
            setTabulatorProps((prevState) => ({
                ...prevState,
                languages: languages.split(","),
            }))
        } catch (error) {
            I18n.setLanguage("EN")
            console.log(error.message)
        }
    }

    useEffect(() => {
        document.title = "Category:List"
        getData()
    }, [])

    function createTooltips() {
        window.$('[data-toggle="tooltip"]').tooltip({
            animated: "fade",
            placement: "top",
            html: true,
        })
    }

    useEffect(() => {
        createTooltips()
        getLanguage()
        //getColumsTabulator();
        const buttons = document.querySelectorAll("button.tabulator-page")
        buttons.forEach((btn) => {
            btn.addEventListener("click", () => {
                createTooltips()
            })
        })
    }, [loader])

    useEffect(() => {
        getColumsTabulator()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function handleClickCreate(e) {
        setDataCategory((preveState) => ({
            ...preveState,
            categoryId: "",
            supplierId: supplierg.id,
            nameLanguage1: "",
            nameLanguage2: "",
            nameLanguage3: "",
            nameLanguage4: "",
            inactive: false,
            imageInternalName: "",
            imageExternalURL: "",
            imageFiles: [],
            inactiveOn: "",
        }))
        setDataModal((prevState) => ({
            ...prevState,
            title: I18n.get("lblcreate"),
            mode: "CREATE",
        }))
        setShowAlertMessage((prevState) => ({
            ...prevState,
            show: false,
            lblMessage: "",
            type: "danger",
        }))
        //setImageFile(null)
        // setOpenModal(true)
        window.$(`#modalCategory`).modal("toggle")
    }

    const handleUpdateAction = () => {
        setUpdateAction(!UpdateAction)
    }

    const handleCancelAction = (e) => {
        setUpdateAction(false)
    }

    const handleSaveAction = (e) => {
        setUpdateAction(false)
    }

    const handleChange = (e) => {
        const { value, name, checked } = e.target
        if (e.target.type === "checkbox") {
            setDataCategory((preveState) => ({
                ...preveState,
                [name]: !checked,
            }))
        } else {
            setDataCategory((preveState) => ({
                ...preveState,
                [name]: value,
            }))
        }
    }

    const handleChangeImage = (file) => {
        //console.log(file)
        //setImageFile(file)
    }

    const edit = async (category) => {
        category.supplierId = supplier.id
        await XSalesData.Category.edit(category)
    }

    const create = async (category) => {
        category.supplierId = supplier.id
        await XSalesData.Category.create(category)
    }

    const handleClickRow = (e, row) => {
        const category = row.getData()
        //setestSw(category.inactive);
        setDataCategory((prevState) => ({
            ...prevState,
            ...category,
        }))
        setDataModal((prevState) => ({
            ...prevState,
            title: I18n.get("lbledit"),
            mode: "UPDATE",
        }))
        setAlertMessageImage((prevState) => ({ ...prevState, show: false, lblMessage: "" }))
        // setOpenModal(true)
        setStateImageLoad(true)
        window.$(`#modalCategory`).modal("toggle")
    }

    const handleSave = () => {
        // const form = formRef.current
        // form.dispatchEvent(new Event('submit',{ cancelable: true, bubbles:true }))
    }

    async function submitProcess(values) {
        //setLoader(true)
        if (values.inactive === true) {
            values.inactive = false
        } else {
            values.inactive = true
        }
        const newEntity = { ...values }
        if (dataModal.mode === "CREATE") {
            //validate if exists category
            const category = await XSalesData.Category.getById(supplier.id, newEntity.categoryId)
            if (category !== null) {
                setShowAlertMessage((prevState) => ({
                    ...prevState,
                    show: true,
                    lblMessage: "lblmsgrecordexists",
                    type: "danger",
                }))
                return
            }
            //create
            await create(newEntity)
        } else {
            //edit
            await edit(newEntity)
        }

        window.$(`#modalCategory`).modal("hide")
        setStateImageLoad(false)
        setLoader(true)
        setTimeout(() => {
            getData()
        }, 1000)
    }

    async function onSubmit(values) {
        setShowAlertMessage((prevState) => ({
            ...prevState,
            show: false,
            lblMessage: "",
            type: "danger",
        }))
        await notifyAsync(submitProcess(values))
    }

    async function handleUploadImage(file, replace, copy, useExisting) {
        const newFile = { ...file }
        //implement here....
        // this method need return { isUpload: boolean , isExists: boolean }
        setAlertMessageImage((prevState) => ({ ...prevState, show: false, message: "" }))

        const [result] = await XSalesConsumer.uploadFile(file, supplier.id, ImageUrlTypes.Types.categories, replace, copy)
        console.log([result])

        let isUpload = false
        let isExists = false

        const messageCodes = {
            API001: "API001", // exists
            API002: "API002", // content Inappropriate
            API003: "API003", //  dimensions warn
        }

        if (result) {
            //const exists = result.messages.findIndex((x) => x.msgCode === messageCodes.API001)
            const exists = result.messages.find(obj => {
                return obj.msgCode === messageCodes.API001;
            });
            if (exists !== -1) {
                isExists = true
            }

            //const contentInappropriate = result.messages.findIndex((x) => x.msgCode === messageCodes.API002)
            const contentInappropriate = result.messages.find(obj => {
                return obj.msgCode === messageCodes.API002;
            });
            if (contentInappropriate !== -1 && contentInappropriate!== undefined && contentInappropriate!== null) {
                setAlertMessageImage((prevState) => ({
                    ...prevState, 
                    show: true,
                    message: contentInappropriate.message, // "Contenido Inapropiado",
                    type: "danger",
                }))
            }

            //const wrongDimentions = result.messages.find((x) => x.msgCode === messageCodes.API003)
            const wrongDimentions = result.messages.find(obj => {
                return obj.msgCode === messageCodes.API003;
            });
            if (wrongDimentions !== null && wrongDimentions!== undefined) {
                setAlertMessageImage((prevState) => ({
                    ...prevState,
                    show: true,
                    message: wrongDimentions.message,
                    type: "warning",
                }))
            }

            isUpload = result.isUploaded
            if (isUpload) newFile.name = result.imageName

            //no hacemos nada con el Warning de las imagenes
        }

        return { isUpload, isExists, imageName: newFile.name }
    }

    async function handleOnChangeImage(files) {
        if (Array.isArray(files) && stateImageLoad) {
            const images = files.map((x) => x.name.toLowerCase())
            if (dataModal.mode === "UPDATE") {
                await XSalesData.Category.saveImages(supplier.id, dataCategory.categoryId, images)
            } else {
                setDataCategory((prevState) => ({
                    ...prevState,
                    imageInternalName: images,
                }))
            }
        }
    }

    return (
        <div>
            {loader ? (
                <LoaderWindow />
            ) : (
                <>
                    <Title label={I18n.get("lblCategory")} />
                    <TextMessage label={I18n.get("lblMsgOnlyRead")} />
                    <TabulatorTable
                        id="category"
                        Title={""}
                        showCreateButton={stateRead ? true : false}
                        onClickButtonCreate={handleClickCreate}
                        Data={tabulatorProps.data}
                        Columns={tabulatorProps.columns}
                        Filter={tabulatorProps.filter}
                        placeholder={I18n.get("lblLoadingData")}
                        Icons={false}
                        Search={true}
                        labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                        columnsIcon={true}
                        languages={tabulatorProps.languages}
                        RowClick={handleClickRow}
                        Height="50vh"
                        lblCreateButton= {I18n.get("lblcreate")}
                        lblSearch= {I18n.get("lblSearch")}
                    />

                    <Modal
                        id="modalCategory"
                        title={dataModal.title}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                        handleSave={handleSave}
                        handleCancel={(e) => false}
                        //open={openModal}
                        size="lg"
                        submitButton={stateRead ? true : false}
                        formId={"form-category"}
                    >
                        <div className="col-sm-12">
                            <AlertMessage message={I18n.get(showAlertMessage.lblMessage)} show={showAlertMessage.show} type={showAlertMessage.type} />
                        </div>
                        <FormCategory
                            mode={dataModal.mode}
                            label={""}
                            setRef={formRef}
                            handleUpdateAction={handleUpdateAction}
                            handleCancelAction={handleCancelAction}
                            handleSaveAction={handleSaveAction}
                            handleChange={handleChange}
                            data={dataCategory}
                            handleChangeImage={handleChangeImage}
                            updateAction={UpdateAction}
                            handleSubmit={onSubmit}
                            handleUploadImage={handleUploadImage}
                            alertMessageImage={alertMessageImage}
                            handleOnChangeImage={handleOnChangeImage}
                            readOnly={stateRead ? true : false}
                        />
                    </Modal>
                    <Toastify />
                    {
                        //  openViewerImg &&
                        //     <Lightbox
                        //     medium={imgUrlModal}
                        //     large={imgUrlModal}
                        //     alt="Viewer"
                        //     onClose={closeLightbox}
                        //     />
                    }
                </>
            )}
        </div>
    )
}

/* const ContainerButton = styled.div`
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
` */
