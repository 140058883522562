import React, { useEffect, useRef, useState } from 'react'
import { I18n } from '@aws-amplify/core'
import {
  TabulatorTable,
  LoaderWindow,
  Title,
  AlertMessage,
  Toastify,
  useToast,
  Modal,
  Confirm,
  TextMessage,
  Filters,
} from 'xsales-components'
import { XSalesConsumer } from '../../../Utils/Utils'
import {
  ImageUrlTypes,
  XSalesData,
} from '../../../DataQuery/XSalesData'
//import styled from 'styled-components'
import { FormProduct } from './FormProduct'
//import imgCDN from "../../../configConsumer/imagesDefaultCDN.json"
import { v4 as uuidv4 } from 'uuid'
//import { label } from 'aws-amplify'
//import { set } from 'react-hook-form'

let supplierg = {}
let listUpdateLot = []
let ResultSubCategoryg = null
let ResultCategoryg = null
export function Product() {
  const [stateRead, setStateRead] = useState(false)
  const [stateReadOnly, setStateReadOnly] = useState(false)

  const { notifyAsync } = useToast({
    pending: I18n.get('lblprocess'),
    success: I18n.get('lblsuccess'),
    error: I18n.get('lblprocesserror'),
  })
  const formRef = useRef(null)
  const [loader, setLoader] = useState(true)
  const [supplier, setSupplier] = useState(null)
  /* const [openModal,setOpenModal] = useState(false) */
  const [stateSelects, setStateSelects] = useState(false)
  const updateAction = true
  const [enabledBtnBach, setEnabledBtnBach] = useState(false)
  const [enabledCheckAll, setEnabledCheckAll] = useState(false)
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [dataModal, setDataModal] = useState({
    title: '',
    mode: 'CREATE',
  })
  const [stateImageLoad, setStateImageLoad] = useState(false)

  const [inputTagValues, setInputTagValues] = useState([])
  const [inputCategoryValues, setInputCategoryValues] = useState(null)
  const [
    inputSameOrderClassesValues,
    setInputSameOrderClassesValues,
  ] = useState([])
  /*  const [messageLogs, setMessageLogs]= useState( {
        lblColumnCode: "Code",
        lblColumnType: "Type",
        lblColumnMessage: "Log",
        data: []
    }) */

  const [alertMessageImage, setAlertMessageImage] = useState({
    title: '',
    message: '',
    show: false,
    type: 'warning',
  })

  const [dataProduct, setDataProduct] = useState({
    supplierId: '',
    subcategoryId: '',
    categoryId: '',
    unit: '',
    descriptionLanguage1: '',
    descriptionLanguage2: '',
    descriptionLanguage3: '',
    descriptionLanguage4: '',
    nameLanguage1: '',
    nameLanguage2: '',
    nameLanguage3: '',
    nameLanguage4: '',
    shortnameLanguage1: '',
    shortnameLanguage2: '',
    shortnameLanguage3: '',
    shortnameLanguage4: '',
    tags: null,
    price: 0.0,
    percentageDiscount: 0,
    amountDiscount: '0',
    blockedForSales: '',
    blockedForSalesDate: '',
    inactive: false,
    inactiveParent: false,
    imageInternalName: [],
    imageExternalURL: [],
    taxFree: '',
    productId: '',
    imageFiles: [],
  })
  const [optionsCategory, setOptionsCategory] = useState([])
  const [optionsSubcategory, setOptionsSubcategory] = useState([])
  const [tabulatorProps, setTabulatorProps] = useState({
    filter: [],
    data: [],
    languages: [],
    columns: [],
    dataFilters: [],
  })

  async function getColumsTabulator() {
    let columns = [
      {
        title: `<input type='checkbox' id='select_all-check'  ${
          enabledCheckAll && 'checked'
        } />` /* */,
        type: 'check',
        widthGrow: 0.1,
        formatter: (cell, formatterPatterns, onRenderedm) => {
          const id = uuidv4()
          const data = cell.getData()
          const checked = listUpdateLot.findIndex(
            (x) => x.productId === data.productId,
          )
          return `<input type="checkbox" id="${id}" name="chkProduct" productId="${
            data.productId
          }" unit="${data.unit}" ${checked !== -1 && 'checked'} />`
        },

        cellClick: (e, cell) => {
          e.stopPropagation()
          handleClickCheckbox(e, cell)
        },
      },
      {
        title: 'Id',
        field: 'productId',
        visible: true,
        widthGrow: 0.3,
      },
      {
        title: I18n.get('lblunit'),
        field: 'unit',
        visible: true,
        widthGrow: 0.2,
      },
      {
        title: I18n.get('lblnameLanguage1'),
        field: 'nameLanguage1',
        visible: true,
        widthGrow: 0.5,
      },
      {
        title: I18n.get('lblnameLanguage2'),
        field: 'nameLanguage2',
        visible: true,
        widthGrow: 0.5,
      },
      {
        title: I18n.get('lblnameLanguage3'),
        field: 'nameLanguage3',
        visible: true,
        widthGrow: 0.5,
      },
      {
        title: I18n.get('lblnameLanguage4'),
        field: 'nameLanguage4',
        visible: true,
        widthGrow: 0.5,
      },
      {
        title: I18n.get('lblprice'),
        field: 'price',
        visible: true,
        widthGrow: 0.3,
        formatter: (cell, formatterParams, onRenderedm) => {
          const { price } = cell.getData()
          return XSalesConsumer.formatterCurrency(
            price,
            'USD',
            'en-US',
          )
        },
      },
      {
        title: I18n.get('lblpercentageDiscount'),
        field: 'percentageDiscount',
        visible: true,
        widthGrow: 0.5,
      },
      {
        title: I18n.get('lbltags'),
        field: 'tags',
        visible: false,
        widthGrow: 0.5,
      },
      {
        title: I18n.get('lblamountDiscount'),
        field: 'amountDiscount',
        visible: false,
        widthGrow: 0.5,
      },
      {
        title: I18n.get('lblBlockedForSaleDate'),
        field: 'blockedForSalesDate',
        visible: false,
        widthGrow: 0.5,
      },
      {
        title: I18n.get('lblinactiveParent'),
        field: 'inactiveParent',
        visible: false,
        widthGrow: 0.5,
      },
      {
        title: 'imageExternalURL',
        field: 'imageExternalURL',
        visible: false,
        widthGrow: 0.5,
      },
      {
        title: I18n.get('lbltaxFree'),
        field: 'taxFree',
        visible: false,
        widthGrow: 0.5,
      },
      {
        title: I18n.get('lblbarcode'),
        field: 'barcode',
        widthGrow: 1,
        visible: false,
      },
      {
        title: I18n.get('lblsameOrderClass'),
        field: 'sameOrderClass',
        widthGrow: 1,
        visible: false,
      },
      {
        title: I18n.get('lblOnlyRead'),
        field: 'readOnly',
        widthGrow: 1,
        visible: false,
      },
      {
        title: I18n.get('lblactive'),
        field: 'inactive',
        widthGrow: 0.25,
        cellClick: async (e, cell) => {
          e.preventDefault()
          e.stopPropagation()
          const data = cell.getData()
          cell.setValue(!data.inactive)
          if (!data.readOnly) {
            const message = !data.inactive
              ? I18n.get('lblmsgconfirmactive')
              : I18n.get('lblmsgconfirminactive')

            const confirm = await Confirm({
              title: I18n.get('lblconfirmation'),
              message: message,
            })

            if (!confirm) {
              cell.setValue(!data.inactive)
              return
            }

            data.supplierId = supplierg.id
            data.inactiveOn = data.inactive
              ? new Date().toISOString()
              : ''
            await XSalesData.Product.Edit(data)
            getData('ALL')
          } else {
            cell.setValue(!data.inactive)
            return
          }
        },
        formatter: (cell, formatterParams, onRenderedm) => {
          const id = uuidv4()
          const { inactive } = cell.getData()
          return `<div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" ${
                      !inactive && 'checked'
                    } id="${id}"  />
                    <label className="custom-control-label"  for='${id}' ></label>
                  </div>`
        },
      },
      {
        title: I18n.get('lblBlockedForSale'),
        field: 'blockedForSales',
        visible: true,
        widthGrow: 0.5,
        formatter: (cell, formatterParams, onRenderedm) => {
          const id = uuidv4()
          const { blockedForSales } = cell.getData()
          return `<div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" ${
                      !blockedForSales && 'checked'
                    } id="${id}"  />
                    <label className="custom-control-label"  for='${id}' ></label>
                  </div>`
        },
      },
    ]
    setTabulatorProps((prevState) => ({
      ...prevState,
      columns: columns,
    }))

    let dataFilters = [
      {
        label: I18n.get('lblALL'),
        handleClick: handleClickFilters,
        className:
          'btn btn-outline-primary bounds-button btn__filters__active--component',
        id: 'ALL',
        name: 'ALL',
        type: 'button',
        active: true,
      },
      {
        label: I18n.get('lblACTIVE'),
        handleClick: handleClickFilters,
        className: 'btn btn-outline-primary bounds-button',
        id: 'ACTIVE',
        name: 'ACTIVE',
        type: 'button',
        active: false,
      },
      {
        label: I18n.get('lblINACTIVE'),
        handleClick: handleClickFilters,
        className: 'btn btn-outline-primary bounds-button',
        id: 'INACTIVE',
        name: 'INACTIVE',
        type: 'button',
        active: false,
      },
      {
        label: I18n.get('lblAVAILABLE'),
        handleClick: handleClickFilters,
        className: 'btn btn-outline-primary bounds-button ',
        id: 'AVAILABLE',
        name: 'AVAILABLE',
        type: 'button',
        active: false,
      },
      {
        label: I18n.get('lblUNAVAILABLE'),
        handleClick: handleClickFilters,
        className: 'btn btn-outline-primary bounds-button',
        id: 'UNAVAILABLE',
        name: 'UNAVAILABLE',
        type: 'button',
        active: false,
      },
    ]
    setTabulatorProps((prevState) => ({
      ...prevState,
      dataFilters: dataFilters,
    }))
  }

  async function getLanguage() {
    try {
      let lang = localStorage.getItem('languageApp')
      if (!lang) {
        const [lang1] = navigator.language.split('-')
        lang = lang1.toUpperCase()
      }
      I18n.setLanguage(lang.toUpperCase())
      const languages =
        XSalesConsumer.getLanguagesSupplierPreferences()
      setTabulatorProps((prevState) => ({
        ...prevState,
        languages: languages.split(','),
      }))
    } catch (error) {
      I18n.setLanguage('EN')
      console.log(error.message)
    }
  }

  function checkAll(e) {
    let value = false
    let dataTab = tabulatorProps.data
    listUpdateLot = []
    if (e.target.checked) {
      value = true
      setEnabledCheckAll(true)
    } else {
      setEnabledCheckAll(false)
    }

    const ArrayCheck = document.querySelectorAll(
      "input[name='chkProduct']",
    )
    ArrayCheck.forEach((checkbox) => {
      document.getElementById(checkbox.id).checked = value

      if (value) {
        const { productId, unit } = checkbox.attributes
        const item = dataTab.find(
          (element) =>
            element.productId === productId.value &&
            element.unit === unit.value,
        )
        item && listUpdateLot.push(item)
      }
    })

    setEnabledBtnBach(!listUpdateLot.length === 0)
  }

  function handleClickAllCheck() {
    let element = document.getElementById('select_all-check')
    if (element) {
      element.addEventListener('click', (e) => {
        checkAll(e)
      })
    }
  }

  function handleClickFilters(e) {
    let { id } = e.target
    const hasClass = e.target.classList.contains(
      'btn__filters__active--component',
    )

    if (id !== 'ALL') {
      if (hasClass === false) {
        id = 'ALL'
      }
    }
    getData(id)
  }

  useEffect(() => {
    getLanguage()
    getColumsTabulator()
    //RefreshCheckall(enabledCheckAll)
    handleClickAllCheck()
  }, [tabulatorProps.data, enabledBtnBach]) // eslint-disable-line react-hooks/exhaustive-deps

  const [listTags, setListTags] = useState([])
  const [listTagsNew, setListTagsNew] = useState([])
  const [listSameOrderClassNew, setListSameOrderClassNew] = useState(
    [],
  )
  const [listSameOrderClasses, setListSameOrderClasses] = useState([])

  async function getListTags_OrderClassSupplier() {
    await XSalesData.Suppliers.GetSupplier(supplierg.id)
      .then((data) => {
        if (data.productTags !== null) {
          setListTags(
            data.productTags.map((x) => ({ label: x, value: x })),
          )
        }
        if (data.productSameOrderClasses !== null) {
          var dataFinal = data.productSameOrderClasses.reduce(
            function (result, val) {
              if (val !== null) {
                result.push(val)
              }
              return result
            },
            [],
          )
          setListSameOrderClasses(
            dataFinal.map((x) => ({ label: x, value: x })),
          )
        }
      })
      .catch((err) => {
        //console.log(err);
      })
  }

  const handleClickCheckbox = (e, cell) => {
    // e.preventDefault()
    const element = e.target
    if (element.tagName !== 'INPUT') return
    const data = cell.getData()

    if (!element.checked) {
      listUpdateLot = listUpdateLot.filter((item) => item !== data)
    } else {
      listUpdateLot.push(data)
    }

    const ArrayCheck = document.querySelectorAll(
      "input[name='chkProduct']",
    )
    const CheckAllActive = listUpdateLot.length === ArrayCheck.length
    setEnabledBtnBach(!listUpdateLot.length === 0)
    setEnabledCheckAll(CheckAllActive)

    RefreshCheckall(CheckAllActive)
  }

  const RefreshCheckall = (CheckAllActive) => {
    tabulatorProps.columns[0].title = `<input type='checkbox' id='select_all-check' ${
      CheckAllActive && 'checked'
    } />`
    setTabulatorProps((prevState) => ({
      ...prevState,
      columns: [...tabulatorProps.columns],
    }))
  }

  useEffect(() => {
    document.title = 'Product:List'
    getData('ALL')
  }, [])

  useEffect(() => {
    getListTags_OrderClassSupplier()
  }, [loader])

  useEffect(() => {
    createTooltips()
    handleClickAllCheck()
  })

  useEffect(() => {
    createTooltips()
    const buttons = document.querySelectorAll('button.tabulator-page')
    buttons.forEach((btn) => {
      btn.addEventListener('click', () => {
        createTooltips()
      })
    })
  }, [loader])

  const handleSaveUpdateForLot = async (field, value) => {
    if (
      await Confirm({
        title: I18n.get('lblconfirmation'),
        message: I18n.get('lblmessageconfirmcontract'),
        confirmText: I18n.get('lblConfirmText'),
        cancelText: I18n.get('lblcancelText'),
        confirmColor: 'primary',
        cancelColor: 'link text-danger',
      })
    ) {
      listUpdateLot.forEach(async (item) => {
        const Entity = { ...item, [field]: value }

        try {
          XSalesData.Product.Edit(Entity)
          setEnabledCheckAll(false)
          setEnabledBtnBach(false)
          RefreshCheckall(false)
        } catch (e) {
          console.log(e)
        }
      })
      listUpdateLot = []
      getData('ALL')
    }
  }

  async function getData(id) {
    try {
      await XSalesConsumer.waitFor(100)
      const { supplier } = await XSalesConsumer.getCurrentUser()
      setSupplier(supplier)
      supplierg = supplier

      const CW019 =
        await XSalesData.Suppliers.GetSupplierStandaloneMode(
          supplier.id,
        )
      const CW024 =
        await XSalesData.Suppliers.GetSupplierAdminProductsMode(
          supplier.id,
        )
      if (CW019 && CW024) {
        if (CW019 === 'true' && CW024 === 'true') {
          setStateReadOnly(true)
          setStateRead(true)
        } else {
          setStateReadOnly(false)
          setStateRead(false)
        }
      }
      let dataFinal = []
      XSalesData.Product.List(supplier.id)
        .then(async (data) => {
          let ResultSubCategory = data.subcategories
          let ResultCategory = data.categories
          ResultSubCategoryg = data.subcategories
          ResultCategoryg = data.categories
          let ResultProducts = data.products
          setOptionsCategory(
            ResultCategory.map((x) => ({
              value: x.categoryId,
              label: x.categoryId,
            })),
          )
          setOptionsSubcategory(
            ResultSubCategory.map((x) => ({
              value: x.subcategoryId,
              label: x.subcategoryId,
            })),
          )
          ResultSubCategory.map((subcat) => {
            ResultCategory.map((cat) => {
              if (
                subcat !== null &&
                cat !== null &&
                cat.categoryId === subcat.categoryId
              ) {
                ResultProducts.map((prd) => {
                  if (
                    prd !== null &&
                    subcat !== null &&
                    subcat.subcategoryId === prd.subcategoryId
                  ) {
                    // const imageFiles = [
                    //     { src: "https://cdn-xsalesconsumer.s3.us-east-2.amazonaws.com/categories/bebidas.png",
                    //       name: "bebidas.png"
                    //   }
                    // ] // TODO implementar logica para extraer la url

                    const imageFiles = XSalesConsumer.getImageFiles(
                      prd.supplierId,
                      prd.imageInternalName,
                      prd.imageExternalURL,
                      ImageUrlTypes.Types.products,
                    )
                    dataFinal.push({
                      productId: prd.productId,
                      supplierId: prd.supplierId,
                      unit: prd.unit,
                      subcategoryId: prd.subcategoryId,
                      descriptionLanguage1: prd.descriptionLanguage1,
                      descriptionLanguage2: prd.descriptionLanguage2,
                      descriptionLanguage3: prd.descriptionLanguage3,
                      descriptionLanguage4: prd.descriptionLanguage4,
                      nameLanguage1: prd.nameLanguage1,
                      nameLanguage2: prd.nameLanguage2,
                      nameLanguage3: prd.nameLanguage3,
                      nameLanguage4: prd.nameLanguage4,
                      shortnameLanguage1: prd.shortnameLanguage1,
                      shortnameLanguage2: prd.shortnameLanguage2,
                      shortnameLanguage3: prd.shortnameLanguage3,
                      shortnameLanguage4: prd.shortnameLanguage4,
                      tags: prd.tags ? prd.tags : [],
                      price: prd.price,
                      percentageDiscount: prd.percentageDiscount
                        ? prd.percentageDiscount
                        : 0,
                      amountDiscount: prd.amountDiscount
                        ? prd.amountDiscount
                        : 0,
                      blockedForSales: prd.blockedForSales,
                      blockedForSalesDate: prd.blockedForSalesDate,
                      inactive: prd.inactive,
                      inactiveParent: prd.inactiveParent,
                      imageInternalName: prd.imageInternalName,
                      imageExternalURL: prd.imageExternalURL,
                      taxFree: prd.taxFree,
                      categoryName: cat.nameLanguage1,
                      SubcategoryName: subcat.nameLanguage1,
                      categoryId: cat.categoryId,
                      imageFiles: imageFiles,
                      inactiveOn: prd.inactiveOn,
                      inactiveParentOn: prd.inactiveParentOn,
                      barcode: prd.barcode,
                      sameOrderClass: prd.sameOrderClass,
                    })
                  }
                  return null
                })
              }
              return null
            })
            return null
          })

          let filtered = []
          switch (id) {
            case 'ACTIVE':
              filtered = dataFinal.filter(
                (dataFinal) =>
                  dataFinal.inactive === false ||
                  dataFinal.inactive === null,
              )
              dataFinal = filtered
              break
            case 'INACTIVE':
              filtered = dataFinal.filter(
                (dataFinal) => dataFinal.inactive === true,
              )
              dataFinal = filtered
              break
            case 'AVAILABLE':
              filtered = dataFinal.filter(
                (dataFinal) =>
                  dataFinal.blockedForSales === false ||
                  dataFinal.blockedForSales === null,
              )
              dataFinal = filtered
              break
            case 'UNAVAILABLE':
              filtered = dataFinal.filter(
                (dataFinal) => dataFinal.blockedForSales === true,
              )
              dataFinal = filtered
              break
            default:
              break
          }

          setTabulatorProps((prevState) => ({
            ...prevState,
            data: dataFinal,
          }))
          setLoader(false)
        })
        .catch((err) => {
          console.log(
            'Admin SubCategories componentdidmount error ',
            err,
          )
          setLoader(false)
        })
    } catch (error) {
      console.error(error)
      setLoader(false)
    }
  }

  const handleClickRow = async (e, row) => {
    //await setDataProducDefault();
    let Product = row.getData()

    window.$(`#modalProduct`).modal('toggle')
    setDataProduct((prevState) => ({
      ...prevState,
      ...Product,
    }))

    setDataModal((prevState) => ({
      ...prevState,
      title: I18n.get('lbledit'),
      mode: 'UPDATE',
    }))

    setInputTagValues(
      Product?.tags.map((x) => ({ value: x.tag, label: x.tag })),
    )
    setInputSameOrderClassesValues([])
    if (
      Product.sameOrderClass !== null &&
      Product.sameOrderClass !== ''
    ) {
      setInputSameOrderClassesValues({
        label: Product.sameOrderClass,
        value: Product.sameOrderClass,
      })
    } else {
      const container = document.querySelector(`#sameOrderClass`)
      if (container) {
        const matches = container.querySelectorAll(
          `.select__single-value`,
        )
        matches.forEach((el) => {
          el.textContent = 'Select...'
        })
      }
    }
    await setOptionsCategory({
      value: Product.categoryId,
      label: Product.categoryId,
    })
    await setOptionsSubcategory(
      ResultSubCategoryg.map((x) => ({
        value: Product.subcategoryId,
        label: Product.subcategoryId,
      })),
    )
    setStateImageLoad(true)
    // setOpenModal(true)
  }

  async function setDataProducDefault() {
    setDataProduct((preveState) => ({
      ...preveState,
      supplierId: supplier.id,
      unit: '',
      descriptionLanguage1: '',
      descriptionLanguage2: '',
      descriptionLanguage3: '',
      descriptionLanguage4: '',
      nameLanguage1: '',
      nameLanguage2: '',
      nameLanguage3: '',
      nameLanguage4: '',
      shortnameLanguage1: '',
      shortnameLanguage2: '',
      shortnameLanguage3: '',
      shortnameLanguage4: '',
      tags: [],
      price: 0.0,
      percentageDiscount: 0,
      amountDiscount: '0',
      blockedForSales: '',
      blockedForSalesDate: '',
      inactive: false,
      inactiveParent: false,
      imageInternalName: [],
      imageExternalURL: [],
      taxFree: '',
      productId: '',
      imageFiles: [],
      inactiveOn: '',
      inactiveParentOn: '',
      barcode: '',
      sameOrderClass: '',
    }))
  }
  async function handleClickCreate(e) {
    setInputTagValues([])
    setListTagsNew([])
    setInputCategoryValues(null)
    setInputSameOrderClassesValues([])
    //const form = formRef.current
    await getListTags_OrderClassSupplier()
    setDataProduct((preveState) => ({
      ...preveState,
      supplierId: supplier.id,
      subcategoryId: null,
      categoryId: null,
      unit: '',
      descriptionLanguage1: '',
      descriptionLanguage2: '',
      descriptionLanguage3: '',
      descriptionLanguage4: '',
      nameLanguage1: '',
      nameLanguage2: '',
      nameLanguage3: '',
      nameLanguage4: '',
      shortnameLanguage1: '',
      shortnameLanguage2: '',
      shortnameLanguage3: '',
      shortnameLanguage4: '',
      tags: [],
      price: 0.0,
      percentageDiscount: 0,
      amountDiscount: '0',
      blockedForSales: '',
      blockedForSalesDate: '',
      inactive: false,
      inactiveParent: false,
      imageInternalName: [],
      imageExternalURL: [],
      taxFree: '',
      productId: '',
      SubcategoryName: '',
      categoryName: '',
      imageFiles: [],
      inactiveOn: '',
      inactiveParentOn: '',
      barcode: '',
      sameOrderClass: '',
    }))

    setDataModal((prevState) => ({
      ...prevState,
      title: I18n.get('lblcreate'),
      mode: 'CREATE',
    }))

    setStateSelects(false)
    window.$(`#modalProduct`).modal('toggle')
  }

  const handleSubmit = async (e) => {
    setShowAlertMessage(false)
    //Save New Tags productTags
    let entity = { ...e }

    if (listTagsNew?.length > 0) {
      const newTags = listTagsNew.map((x) => x.value)
      await XSalesData.Suppliers.SetSupplierProductTags(
        supplier.id,
        newTags,
      )
    }

    let arrayTag = []
    inputTagValues.forEach((item) => {
      arrayTag.push({ tag: item.value })
    })
    setInputTagValues([])
    entity.tags = arrayTag
    entity.sameOrderClass = inputSameOrderClassesValues.value

    const container = document.querySelector(`#sameOrderClass`)
    if (container) {
      const matches = container.querySelectorAll(
        `.select__single-value`,
      )
      if (matches.length === 0) {
        entity.sameOrderClass = ''
      }
    }

    if (
      listSameOrderClassNew?.value !== null &&
      listSameOrderClassNew?.value !== undefined
    ) {
      await XSalesData.Suppliers.SetSupplierProductSameOrderClasses(
        supplier.id,
        inputSameOrderClassesValues,
      )
    }
    if (dataModal.mode === 'CREATE') {
      //validate if exists category
      const Products = await XSalesData.Product.getById(
        supplier.id,
        dataProduct.productId,
        dataProduct.unit,
      )
      if (Products !== null) {
        setShowAlertMessage(true)
        return
      }
      //create
      await notifyAsync(XSalesData.Product.Create(entity))
    } else {
      //edit
      await notifyAsync(XSalesData.Product.Edit(entity))
    }
    setListTagsNew([])
    window.$(`#modalProduct`).modal('hide')
    setLoader(true)
    setStateImageLoad(false)
    setTimeout(() => {
      getData('ALL')
    }, 1000)
    await setDataProducDefault()
  }

  const handleChange = (e) => {
    const { value, name } = e.target
    setDataProduct((preveState) => ({
      ...preveState,
      [name]: value,
    }))
  }

  const handleChangeSwitch = (e) => {
    const { checked, name } = e.target
    setDataProduct((preveState) => ({
      ...preveState,
      [name]: checked,
    }))
  }

  const handleChangeDiscountAmount = (e) => {
    const { value, name } = e.target
    setDataProduct((preveState) => ({
      ...preveState,
      [name]: value,
    }))

    const percentageDiscount = 'percentageDiscount'
    setDataProduct((preveState) => ({
      ...preveState,
      [percentageDiscount]: value,
    }))
  }

  const handleChangeDiscountPercentage = (e) => {
    const { value, name } = e.target
    setDataProduct((preveState) => ({
      ...preveState,
      [name]: value,
    }))
    const amountDiscount = 'amountDiscount'
    setDataProduct((preveState) => ({
      ...preveState,
      [amountDiscount]: value,
    }))
  }

  function createTooltips() {
    window.$('[data-toggle="tooltip"]').tooltip({
      animated: 'fade',
      placement: 'top',
      html: true,
    })
  }

  async function handleUploadImage(file, replace, copy, useExisting) {
    //implement here....
    // this method need return { isUpload: boolean , isExists: boolean }
    setAlertMessageImage((prevState) => ({
      ...prevState,
      show: false,
      message: '',
    }))
    const [result] = await XSalesConsumer.uploadFile(
      file,
      supplier.id,
      ImageUrlTypes.Types.products,
      replace,
      copy,
    )
    let isUpload = false
    let isExists = false
    let imageName = ''
    const messageCodes = {
      API001: 'API001', // exists
      API002: 'API002', // content Inappropriate
      API003: 'API003', //  dimensions warn
    }

    if (result) {
      //const exists = result.messages.findIndex(x => x.msgCode === messageCodes.API001)
      const exists = result.messages.find((obj) => {
        return obj.msgCode === messageCodes.API001
      })
      if (exists !== -1) {
        isExists = true
      }

      //const contentInappropriate = result.messages.findIndex(x => x.msgCode === messageCodes.API002)
      const contentInappropriate = result.messages.find((obj) => {
        return obj.msgCode === messageCodes.API002
      })
      if (
        contentInappropriate !== -1 &&
        contentInappropriate !== undefined &&
        contentInappropriate !== null
      ) {
        setAlertMessageImage((prevState) => ({
          ...prevState,
          show: true,
          message: 'Contenido Inapropiado',
          type: 'danger',
        }))
      }
      isUpload = result.isUploaded
      imageName = result.imageName
    }
    return { isUpload, isExists, imageName }
  }

  async function handleOnChangeImage(files) {
    setAlertMessageImage((prevState) => ({
      ...prevState,
      show: false,
    }))
    if (Array.isArray(files) && stateImageLoad) {
      const images = files.map((x) => x.name.toLowerCase())
      if (dataModal.mode === 'UPDATE') {
        await XSalesData.Product.saveImages(
          supplier.id,
          dataProduct.productId,
          dataProduct.unit,
          images,
        )
      } else {
        setDataProduct((prevState) => ({
          ...prevState,
          imageInternalName: images,
        }))
      }
    }
  }

  const handleChangeCombo = () => {}

  const handleChangeTags = (values) => {
    setListTagsNew(values.filter((x) => x?.isNew === true))
    setInputTagValues([...values])
  }

  const handleChangeSameOrderClasses = (values) => {
    setListSameOrderClassNew([])
    if (values.isNew) {
      setListSameOrderClassNew({
        value: values.value,
        label: values.value,
      })
    }
    setInputSameOrderClassesValues(
      values === null
        ? []
        : { value: values.value, label: values.value },
    )
  }

  return (
    <div>
      {loader ? (
        <LoaderWindow />
      ) : (
        <>
          <Title label={I18n.get('lblproductTable')} />
          <TextMessage label={I18n.get('lblMsgOnlyRead')} />

          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-12">
              <Filters
                filterName="filterproduct"
                data={tabulatorProps.dataFilters}
              />
            </div>
          </div>

          <TabulatorTable
            id="Product"
            Data={tabulatorProps.data}
            Columns={tabulatorProps.columns}
            Filter={tabulatorProps.filter}
            placeholder={I18n.get('lblLoadingData')}
            Icons={false}
            Search={true}
            paginationSize={500}
            labelEmptyData={I18n.get('lblnodataavailabletabulator')}
            columnsIcon={true}
            languages={tabulatorProps.languages}
            RowClick={handleClickRow}
            Height="70vh"
            GroupBy={{
              Key: ['categoryName', 'SubcategoryName'],
              Collapse: true,
            }}
            lblSearch={I18n.get('lblSearch')}
            showCreateButton={stateRead ? true : false}
            lblCreateButton={I18n.get('lblcreate')}
            onClickButtonCreate={handleClickCreate}
            showDropdownButton={enabledBtnBach}
            groupToggleElement="header"
            groupStartOpen={true}
            Pagination={null}
            dropdowButtonProps={{
              label: I18n.get('lblBatchProcess'),
              size: 'md',
              color: 'default',
              groupBy: 'ASC',
              options: [
                {
                  onClick: (e) => {
                    handleSaveUpdateForLot('inactive', false)
                  },
                  label: I18n.get('lblactive'),
                  group: '1',
                },
                {
                  onClick: (e) => {
                    handleSaveUpdateForLot('inactive', true)
                  },
                  label: I18n.get('lblinactive'),
                  group: '1',
                },
                {
                  onClick: (e) => {
                    handleSaveUpdateForLot('blockedForSales', false)
                  },
                  label: I18n.get('lblUnlockedForSale'),
                  group: '2',
                },
                {
                  onClick: (e) => {
                    handleSaveUpdateForLot('blockedForSales', true)
                  },
                  label: I18n.get('lblBlockedForSale'),
                  group: '2',
                },
              ],
            }}
          />

          <Modal
            id="modalProduct"
            title={dataModal.title}
            lblbtnSave={I18n.get('lblSave')}
            lblbtnCancel={I18n.get('lblCancel')}
            handleSave={false}
            handleCancel={(e) => true}
            size="xl"
            submitButton={true}
            formId={'form-Product'}
          >
            <div className="col-sm-12">
              <AlertMessage
                message={I18n.get('lblmsgrecordexists')}
                show={showAlertMessage}
                type="danger"
              />
            </div>
            <FormProduct
              setRef={formRef}
              mode={dataModal.mode}
              supplier={supplier}
              data={dataProduct}
              stateSelects={stateSelects}
              handleChangeCombo={handleChangeCombo}
              handleChange={handleChange}
              updateAction={updateAction}
              handleSubmit={handleSubmit}
              handleChangeDiscountAmount={handleChangeDiscountAmount}
              handleChangeDiscountPercentage={
                handleChangeDiscountPercentage
              }
              handleChangeSwitch={handleChangeSwitch}
              handleUploadImage={handleUploadImage}
              alertMessageImage={alertMessageImage}
              handleOnChangeImage={handleOnChangeImage}
              handleChangeTags={handleChangeTags}
              tagValues={inputTagValues}
              CategorySelected={inputCategoryValues}
              listTags={listTags}
              listCategories={optionsCategory}
              listSubcategories={optionsSubcategory}
              readOnly={stateReadOnly}
              listSameOrderClasses={listSameOrderClasses}
              handleChangeSameOrderClasses={
                handleChangeSameOrderClasses
              }
              SameOrderClassesValues={inputSameOrderClassesValues}
            />
          </Modal>

          <Toastify />
        </>
      )}
    </div>
  )
}
/* const ContainerButton = styled.div`
    width: 95%;
    display: flex;
    justify-content: end;
    margin-bottom: 2rem;
` */
