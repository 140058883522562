import { I18n } from "aws-amplify"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { ImageUrlTypes } from "../../../DataQuery/XSalesData"
import { XSalesConsumer } from "../../../Utils/Utils"
import "../../../assets/css/reports.css"

// const mock = [{
//     id: "yyy4",
//     group1: "truck1",
//     group2: "truck2",
//     group3: "truck3",
//     productId: "30",
//     image: "https://cdn.xsalesconsumer.com/suppliers/850bce29-0a1a-4ff9-8075-94f32bb99c83/logos/xsm-logotipo-color-web-es.png",
//     name: "SODA 250 ML",
//     unitId: "ea",
//     quantity: 55,
// },
// {
//     id: "yyy2",
//     group1: "truck1",
//     group2: "truck22",
//     group3: "truck333",
//     productId: "32",
//     image: "https://cdn.xsalesconsumer.com/suppliers/850bce29-0a1a-4ff9-8075-94f32bb99c83/logos/xsm-logotipo-color-web-es.png",
//     name: "SODA 350 ML",
//     unitId: "ea",
//     quantity: 30,
// },
// {
//     id: "yyy3",
//     group1: "truck2",
//     group2: "truck222",
//     group3: "truck333",
//     productId: "45",
//     image: "https://cdn.xsalesconsumer.com/suppliers/850bce29-0a1a-4ff9-8075-94f32bb99c83/logos/xsm-logotipo-color-web-es.png",
//     name: "SODA 750 ML",
//     unitId: "pza",
//     quantity: 50,
// }]
export function SummaryProduct({ groupType, data, hideImage }) {
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        buildData(data)
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    function buildData(mapData) {
        const datatable = []
        const newArr = []
        const { supplier } = XSalesConsumer.getCurrentUserNew()
        mapData.forEach((x) => {
            const entity = {
                id: x.id,
                group1: x.group1,
                group2: x.group2,
                group3: x.group3,
                productId: "",
                image: "",
                name: "",
                unitId: "",
                quantity: 0,
            }

            x.orderProducts.forEach((product) => {
                const url = XSalesConsumer.getImageUrlList({
                    imageInternalName: product.imageInternalName,
                    imageExternalURL: product.imageExternalURL,
                    supplierId: supplier.id,
                    imageType: ImageUrlTypes.Types.products,
                })
                datatable.push({
                    ...entity,
                    productId: product.productId,
                    name: product.shortnameLanguage1,
                    unitId: product.unitId,
                    quantity: product.quantity,
                    image: url.at(0),
                })
            })
        })

        let groupList = []
        const datatableList = []
        datatable.forEach((x) => groupList.push(x[groupType]))
        groupList = [...new Set(groupList)] // saca los distintos
        groupList.sort((a, b) => {
            if (a < b) return -1
            if (a > b) return 1
            return 0
        })

        groupList.forEach((key) => {
            const units = {}
            const filterData = datatable.filter((x) => x[groupType] === key)
            filterData.forEach((x) => {
                if (!units[x.unitId]) {
                    units[x.unitId] = x.quantity
                } else {
                    units[x.unitId] += x.quantity
                }
            })
            const totalUnits = Object.keys(units).map((key) => ({
                unitId: key,
                total: units[key],
            })) // { unitId: "ca", total: 55 }
            datatableList.push({
                group: key,
                data: filterData,
                unitTotals: totalUnits,
            }) // { group: "truck1", data: [Orders], unitTotals: [{ unitId: "ca", total: 55 }] }
            newArr.push({
                group: key,
                data: [],
                unitTotals: totalUnits,
            }) 
        })

        if (datatableList.length>0){
            let exist= false;
            datatableList.forEach(el => {
                el.data.forEach((product) => {
                    newArr.forEach(ul => {
                        ul.data.forEach((productnew) => {
                            if (productnew.productId===product.productId){
                                productnew.quantity += product.quantity;
                                exist=true;
                            }
                        })
                    })
                    if (exist===false) {
                        newArr[0].data.push(product)
                    }else{
                        exist=false;
                    }
                })
            })
            setTableData(newArr)
        }
    }

    return (
        <Container>
            {tableData.map((item) => (
                <div key={item.id}>
                    <TitleOfTable>{item.group}</TitleOfTable>
                    <Table className="table table-borderless table-striped">
                        <thead>
                            <tr>
                                <th className="th_table">
                                    {I18n.get("lblOrdMaCode")}
                                </th>
                                {!hideImage && (
                                    <th className="th_table">
                                        {I18n.get("lblOrdMaImage")}
                                    </th>
                                )}
                                <th className="th_table">
                                    {I18n.get("lblOrdMaName")}
                                </th>
                                <th className="th_table">
                                    {I18n.get("lblOrdMaUnit")}
                                </th>
                                <th className="th_table">
                                    {I18n.get("lblOrdMaQty")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {item.data.map((row, index) => (
                                <tr>
                                    <td className="td_table">{row.productId}</td>
                                    {!hideImage && (
                                        <td>
                                            <img
                                                src={row.image}
                                                loading="lazy"
                                                alt="not Found"
                                            />
                                        </td>
                                    )}
                                    <td className="td_table text_td_black">
                                        {row.name}
                                    </td>
                                    <td className="td_table text_td_gray">
                                        {row.unitId}
                                    </td>
                                    <td className="td_table text_td_gray">
                                        {row.quantity}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <ContainerTotal>
                        <TableTotal className="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        {I18n.get("lblOrdMaTotal")}
                                    </th>
                                    <th scope="col">
                                        {I18n.get("lblOrdMaQty")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.unitTotals.map((unitItem, index) => (
                                    <tr
                                        key={index}
                                        style={{ lineHeight: "4px" }}
                                    >
                                        <td className="td_table text_td_gray">
                                            {unitItem.unitId}
                                        </td>
                                        <td className="td_table text_td_gray">
                                            {unitItem.total}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </TableTotal>
                    </ContainerTotal>
                </div>
            ))}
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 60vh;
    overflow-y: auto;
    display: block;
    margin-bottom: 20px;
`
const Table = styled.table`
    width: 100%;
    border: 2px solid #dddddd;
    border-right-width: 0px;
    border-left-width: 0px;
    && thead > tr {
        border-bottom: 2px solid #dddddd;
    }
    && tbody > tr {
        border-bottom: 1px solid #dddddd;
    }
    && tr > td {
        vertical-align: middle;
    }
    && tr > td > img {
        width: 115px;
        height: 50px;
        object-fit: contain;
        vertical-align: center;
    }
    /* height: 40vh; */
`

const ContainerTotal = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`

const TableTotal = styled.table`
    width: 50%;
    border: 1px solid #dddddd;
    border-right-width: 0px;
    border-left-width: 0px;

    && thead > tr {
        border: none;
    }
    && tr {
        border-top: 1px solid #ccc;
    }
    && tr > td {
        vertical-align: middle;
    }
`

// const Th = styled.th`
//     width:${props => props.width || "40px"};
//     height:${props => props.height || "40px"};
// `
const TitleOfTable = styled.h5`
    margin: 20px 0 20px 0;
`