import React from "react"
import   "../assets/css/button.css"
function Button(props) {
    const handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (props.onKeyPress !== undefined && props.onKeyPress !== null) {
            if (e.keyCode === 13) {
                props.onKeyPress()
            }
        } else {
            console.log("Se presiono pero no se activo")
        }
    }

    return (
        <button
            type={props.type ? props.type : "button"}
            className={
                props.className
                    ? props.className
                    : "mb-2 mr-2 btn-transition btn btn-outline-primary bounds-button"
            }
            name={props.name}
            id={props.id}
            onClick={props.onClick}
            onBlur={props.onBlur}
            onKeyPress={handleKeypress}
        >
            {props.label}
        </button>
    )
}

export default Button
