import React, { useEffect, useState, Fragment } from 'react'
import { XSalesConsumer } from '../../../Utils/Utils'
import { XSalesData } from '../../../DataQuery/XSalesData'
import { I18n } from 'aws-amplify'

export default function CountConsumer(props) {

    const [isAllowed, setIsAllowed] = useState(false)
    const [consumerTotal, setConsumerTotal] = useState(0)
    const [consumerXSM, setConsumerXSM] = useState(0)

    useEffect(() => {
        const isAllowed = XSalesConsumer.getCurrentUser().then(data => {
            return (data.type.toUpperCase() === "ADMIN" || data.type.toUpperCase() === "SUPPORT") ? true : false 
        }).catch(err => {
            console.log('you are not admin or support role')
            return false
        })
        setIsAllowed(isAllowed)
        XSalesData.Consumer.GetConsumers().then(data => {
            setConsumerTotal(data.length)
            setConsumerXSM(data.filter(x => x.isXSM === true).length)
        }).catch()
    }, [])

    return (
        <Fragment>
            {
                (isAllowed) 
                ? 
                <div className={"col-" + props.Layout}>
                    <div className="col-sm-12">
                        <div className="info-box">
                            <span className="info-box-icon bg-info elevation-1">
                                <i className="fas fa-users" />
                            </span>
                            <div className="info-box-content">
                            <span className="info-box-text">{I18n.get('lblConsumers')}</span>
                            <span className="info-box-number">
                                <div className="row">
                                    <a className="col-9" href='/Consumer/Listing'>
                                        {I18n.get('lblConsumerTotal')}
                                    </a>
                                    <div className="col-3" style={{ textAlign: 'right' }}>
                                        {consumerTotal}
                                    </div>
                                </div>
                                <div className="row">
                                    <a className="col-9" href='/Consumer/Listing/XSM'>
                                        {I18n.get('lblConsumerXSM')}
                                    </a>
                                    <div className="col-3" style={{ textAlign: 'right' }}>
                                        {consumerXSM}
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                : ""
            }
        </Fragment>
    )
}
