import React, { Fragment } from "react"
import { TabulatorTable, Filters, Title } from 'xsales-components'
//import { XSalesConsumer } from "../../../Utils/Utils"
import { XSalesData } from "./../../../DataQuery/XSalesData"
import { I18n } from "aws-amplify"
class index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            objData: {
                InitialData: [
                    {
                        id: '',
                        name: '',
                        businessName: '',
                        username: '',
                        taxID: '',
                        status: '',
                        address: [],
                        phones: [],
                        website: '',
                        email: '',
                        summary: '',
                        contactName: '',
                        contactEmail: '',
                        latitude: '',
                        longitude: '',
                        isXSM: false
                    },
                ],
                Data: [],
                Filter: [],
                Columns: [],
                RowClick: this.rowClickEventConsumer,
                dataFilters: []
            }
        }
    }



    EventFilterChange=(id)=>
    {
        const buttons = document.querySelectorAll(".btn__filters__active--component")
        buttons.forEach((btn) => {
            
            if (btn.id === id)
            {
                btn.classList.add("btn__filters__active--component")
            }
            else
            {
                btn.classList.remove("btn__filters__active--component")
            }
        })
        let objData = this.state.objData;
        switch (id) {
            case "ALL":
                objData.Filter = []
                break;
            case "lblACTIVE":
                objData.Filter = [{ field: "status", type: "in", value: [I18n.get("lblACTIVE")] }]
                break;
            case "lblINACTIVE":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblINACTIVE")] },
                ]
                break;
            case "lblDELETED":
                objData.Filter = [{ field: "status", type: "in", value: [I18n.get("lblDELETED")] }]
                break;
            default:
                objData.Filter = []
                break;
        }
        this.setState({ objData: objData });
        
    }

    handleClickFilters= (e) =>{
        let { id } = e.target; 
        
        if (id!=='ALL'){
            const hasClass = e.target.classList.contains('btn__filters__active--component');
            if (hasClass===false){
                id="ALL";
            } 
        }
        this.EventFilterChange(id)

       
        window.sessionStorage.setItem("filtersAdminConsumer", id)
    }

    rowClickEventConsumer = (e, row) => {
        debugger
        const consumerId = row.getData().id
        window.sessionStorage.setItem("pathRoot", this.props.match.path)
        this.props.history.push(`/Admin/Consumer/Detail/${consumerId}`)

        
        
    }

    getLanguage = async () => {
        try {
            let lang = localStorage.getItem("languageApp");
            if (!lang) {
              const [lang1] = navigator.language.split('-');
              lang = lang1.toUpperCase();
            }
            I18n.setLanguage(lang.toUpperCase());
          } catch (error) {
            I18n.setLanguage('EN');
            console.log(error.message);
          }
    }
    componentDidMount() {
        document.title = "Consumer:List"
        this.getLanguage();
        let objData= this.state.objData;
        let columns=  [
            {
                title: I18n.get("lblConsumerID"),
                field: "id",
                visible: false,
                widthGrow: 2,
            },
            {
                title: I18n.get("lblConsumerName"),
                field: "name",
                visible: true,
                widthGrow: 2,
            },
            {
                title: I18n.get("lblconsumerBusinessName"),
                field: "businessName",
                visible: true,
                widthGrow: 2,
            },
            {
                title: I18n.get("lblEmail"),
                field: "email",
                widthGrow: 2,
            },
            {
                title: I18n.get("lbltaxId"),
                field: "taxID",
                visible: true,
                widthGrow: 1,
            },
            {
                title: I18n.get("lblconStatus"),
                field: "status",
                widthGrow: 1,
            },
        ]
        let dataFilters= [
            {
                label: I18n.get("lblALL"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "ALL",
                name: "ALL",
                type: "button",
                active: true
            },
            {
                label: I18n.get("lblACTIVE"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblACTIVE",
                name: "lblACTIVE",
                type: "button",
                active: false
            },
            {
                label: I18n.get("lblINACTIVE"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblINACTIVE",
                name: "lblINACTIVE",
                type: "button",
                active: false
            },
            {
                label: I18n.get("lblDELETED"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblDELETED",
                name: "lblDELETED",
                type: "button",
                active: false
            }
        ]
        objData.Columns= columns;
        objData.dataFilters= dataFilters;
        
        const showOnlyXSM = this.props.match.params.XSM && this.props.match.params.XSM === "XSM" ? true : false
        XSalesData.Consumer.GetConsumers()
            .then(async (data) => {
                if (showOnlyXSM) {
                    data = data.filter(x => x.isXSM === true)
                }
                let dataFinal = []
                let objData = this.state.objData

                data.map((con) => (
                    dataFinal.push({
                        id: con.id,
                        name: con.name,
                        businessName: con.businessName, 
                        email: con.email,
                        status: I18n.get("lbl" +  con.status),
                        taxID: con.taxID,
                    })
                ))

                objData.Data = dataFinal
                this.setState({ objData: objData })
               
                let filters = window.sessionStorage.getItem("filtersAdminConsumer")
                if(filters !== 'null' && filters !== null)
                {
                    this.EventFilterChange(filters)
                    document.getElementById(filters).classList.add("btn__filters__active--component")
                }

            })
            .catch((err) => {
                console.log("admin componentdidmount error ", err)
            })
    }

    /*onlyActive = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [{ field: "status", type: "in", value: [I18n.get("lblACTIVE")] }]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyInactive = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            { field: "status", type: "in", value: [I18n.get("lblINACTIVE")] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyDeleted = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [{ field: "status", type: "in", value: [I18n.get("lblDELETED")] }]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    ALL = (e) => {
        XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = []
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }*/

    lista = () => {
        return (
            <Fragment>
                <li className="lista" onClick={this.ALL}>
                    {I18n.get("lblALL")}
                </li>
                <li className="lista" onClick={this.onlyActive}>
                    {I18n.get("lblACTIVE")}
                </li>
                <li className="lista" onClick={this.onlyInactive}>
                    {I18n.get("lblINACTIVE")}
                </li>
                <li className="lista" onClick={this.onlyDeleted}>
                    {I18n.get("lblDELETED")}
                </li>
            </Fragment>
        )
    }

    render = () => {
        return (
            <Fragment>

                <Title label={ I18n.get("lblConsumers")} />
                <Filters filterName="filteConsumers" data={this.state.objData.dataFilters} />
                <TabulatorTable
                    Title=""
                    Data={this.state.objData.Data}
                    Columns={this.state.objData.Columns}
                    Filter={this.state.objData.Filter}
                    RowClick={this.state.objData.RowClick}
                    labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                />
            </Fragment>
        )
    }
}

export default index
