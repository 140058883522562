import React from "react"
import { InputText, TextArea, Warning } from 'xsales-components'
// import Information from "../../../componentsConsumer/Information"
import { I18n } from "aws-amplify"
// import Warning from "../../../componentsConsumer/Warning"

export default function GeneralInfo(props) {
    if (props.Data === null || props.Data === undefined) {
        throw new Error(
            "You must indicate a [Data] props for GeneralInfo component"
        )
    }
    const allowEdit =
        props.AllowEdit === null || props.AllowEdit === undefined
            ? true
            : props.AllowEdit

    return (
        <div className="mt-4">
            <div className="row">
                <div className="col-sm-12">
                    {props.Data.isXSM && (
                        <Warning>{I18n.get("lblIsActiveConsumerXSM")}</Warning>
                    )}
                </div>
                <div className="col-sm-6">
                    <div className="divTitle">{props.Data.businessName}</div>
                </div>
                <div className="col-2">
                    <InputText
                        id="taxId"
                        name="taxId"
                        label={I18n.get("lblSupTaxID")}
                        maxLength="20"
                        value={props.Data.taxId}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-4">
                    <InputText
                        id="erpCode"
                        name="erpCode"
                        label={I18n.get("lblERPCode")}
                        maxLength="20"
                        value={props.Data.erpCode}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-4">
                    <InputText
                        id="name"
                        name="name"
                        label={I18n.get("lblconname")}
                        maxLength="30"
                        value={props.Data.name}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-4">
                    <InputText
                        id="officePhone"
                        name="officePhone"
                        label={I18n.get("lblofficeNumber")}
                        maxLength="20"
                        value={props.Data.officePhone}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-4">
                    <InputText
                        id="mobilePhone"
                        name="mobilePhone"
                        label={I18n.get("lblmobileNumber")}
                        maxLength="20"
                        value={props.Data.mobilePhone}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-6">
                    <InputText
                        id="email"
                        name="email"
                        label={I18n.get("lblEmail")}
                        maxLength="20"
                        value={props.Data.email}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-6">
                    <InputText
                        id="website"
                        name="website"
                        label={I18n.get("lblwebsite")}
                        maxLength="20"
                        value={props.Data.website}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-12">
                    <TextArea
                        id="address"
                        name="address"
                        label={I18n.get("lblAddress")}
                        maxLength="200"
                        value={props.Data.address}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>
                <div className="col-12">
                    <TextArea
                        id="summary"
                        name="summary"
                        label={I18n.get("lblSummary")}
                        maxLength="200"
                        value={props.Data.summary}
                        onChange={props.handleChange}
                        isEditable={allowEdit}
                    />
                </div>

                <div className="col-12">
                    {I18n.get("lblContactInformation")}
                    <hr />
                    <div className="row">
                        <div className="col-6">
                            <InputText
                                id="contactName"
                                name="contactName"
                                label={I18n.get("lblcontactName")}
                                maxLength="20"
                                value={props.Data.contactName}
                                onChange={props.handleChange}
                                isEditable={allowEdit}
                            />
                        </div>
                        <div className="col-6">
                            <InputText
                                id="contactEmail"
                                name="contactEmail"
                                label={I18n.get("lblcontactEmail")}
                                maxLength="20"
                                value={props.Data.contactEmail}
                                onChange={props.handleChange}
                                isEditable={allowEdit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
