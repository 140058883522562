// prettier-ignore
export const dict =  {
    'EN': {
		'lblHello': 'Hello',
		'lbldownloadtemplatewizard': 'Download Template',
		'lbluploadfilewizard': 'Upload File',
		'lblpreviewwizard': 'Preview',
		'lblsavedatawizard': 'Save Data',
		'lbldownloadxlswizard':'Download Template (.xlsx)',
		'lbldownloadcsvwizard':'Download Template (.csv)',
		'lblbtnbackwizard': 'Back',
		'lblbtnnextwizard': 'Next',
		'msgimportedwizard': 'Please view the log table for to see the result of process.',
		'lblresultwizard': 'Result',
		'lblimportwizard': 'Import Wizard',
		'lblOrdersToDelivery': 'Orders To Delivery',
		'lblProductsToDelivery': 'Products To Delivery',
		'lblOrdMaStatusOrder': 'Order Status',
		'lblOrdMaReportBy':'Report By',
		'lblOrdMaShippingType': 'Shipping Type',
		'lblOrdMaType': 'Type',
		'lblOrdMaFrom': 'From',
		'lblOrdMaTo': 'To',
		'lblOrdMagroup1':'Group1',
		'lblOrdMagroup2':'Group2',
		'lblOrdMagroup3':'Group3',
		'lblOrdMaCustomer':'Customer',
		'lblOrdMaOrder':'Order',
		'lblOrdMaCreatedOn':'Created On',
		'lblOrdMaItemsQty':'Items',
		'lblOrdMaNetAmount':'Net Amount',
		'lblOrdMaDelivered':'Delivered',
		'lblOrdMaOrders': 'Orders',
		'lblOrdMaDeliveryDate': 'Delivery Date',
		'lblToday': 'Today',
		'lblYesterday': 'Yesterday',
		'lblTomorrow': 'Tomorrow',
		'lblRange': 'Range',
		'lblPlaced': 'Placed',
		'lblDelivered': 'Delivered',
		'lblAll': 'All',
		'lblShippingDelivery': 'Delivery',
		'lblShippingPickup': 'Pickup',
		'lblClientGroup1':'Client Group 1',
		'lblClientGroup2':'Client Group 2',
		'lblClientGroup3':'Client Group 3',
		'lblOrders': 'Orders',
		'msgOrdMaMaxDays':'The range must be a maximum of 7 days',
		'lblOrdMaTotal': 'Total',
		'lblOrdMaDeliveryAddress': 'Delivery Address',
		'lblOrdMaComments': 'Comments',
		'lblOrdMapaymentMethod': 'Payment Method',
		'lblOrdMaCode': 'Code',
		'lblOrdMaName': 'Name',
		'lblOrdMaImage': 'Image',
		'lblOrdMaUnit': 'Unit',
		'lblOrdMaQty': 'Quantity',
		'lblOrdMaPrice': 'Price',
		'lblOrdMaGrossAmount': 'Gross Amount',
		'lblOrdMaDiscountAmount': 'Discount Amount',
		'lblOrdMaSubTotal': 'Sub-Total',
		'lblOrdMaSubTaxes': 'Taxes',
		'lblOrdMaPerDeliveryDate': 'Per Delivery Date',
		'lblOrdMaPerClientGroup': 'Per Client Group',
		'lblOrdMaPerProduct': 'Per Product',
		'lblOrdMaOrderQty': 'Order Quantity',
		'lblOrdMaCurrencyISO': 'Currency ISO',
		'lblOrdMaLastDay': 'Last Day',
		'lblOrdMaNextDay': 'Next Day',
		'lblOrdMaBack': 'Back',
		'lblOrdMaShowImageProduct': 'Enable Image Product',
		'lblOrderMenu': 'Order',
		'lblordermanagment': 'Managment',

		'msgMarkDeliveredOrder': 'Are you sure, to mark the order delivered?',
		'msgUnmarkDeliveredOrder': 'Are you sure you want to uncheck the delivered order?',
		'lblcategoryid':'Id',
		'lblrequiredField':'Field is required',
		'lblmaxLengthField': 'Length greater than {length}',
		'lblimginappropriatecontent': 'The image contains inappropriate content',
		'lbllogo': 'Logo',
		'lblfuture': 'Future',
		'lblplancontract': 'Plan',
		'lblfeecontract': 'Fee',
		'lblmintransactioncontract':'Min Transaction',
		'lblsincecontract': 'Since',
		'lblmessageconfirmcontract': 'Are you sure you want to continue?',
		'lblcontinue':'Continue',
		'lblconfirm':'Confirmation',
		'lblconfigcurrency':'Currency and Symbol settings',
		'lblcurrencyiso':'Currency',
		'lblcurrencysymbol':'Symbol',
		'lblcurrencytab':'Currency',
		'lblexchangerate': 'Rate',
		'lblamountusd': 'Amount USD',
		'lblinactivebanner': 'Inactive',
		'lblactivebanner': 'Active',
		'lblgeneralinfo': 'General Information',
		'lblpreferencessup': 'Preferences',
		'lblbannerssup':'Banners',
		'lblpromotionalbanners': 'Promotional Banners',
		'lbllatestrates': 'Latest Rates',
		'lblfrom': 'From',
		'lblto': 'To',
		'lblrate': 'Rate',
		'lbleffectiveDate': 'Effective Date',
		'lblclear': 'Clear',
		'lblmessagepostorder': `Warning:
		This is a testing module for the POST / order and POST / quote API.
		The transactions carried out in this module are not registered in the Consumer system, but they can be registered in the corresponding ERP.
		Conduct your tests with that consideration.`,
		'lblpostorderTableSchema': 'Post Order',
		'lblpostquoteTableSchema': 'Post Quote',
		'lblclose': 'Close',
		'lbltestpostorder': 'Test Post Order',
		'lbltestpostquote': 'Test Post Quote',
		'lblcustomerId': 'Customer',
		'lblpaymentcondition': 'payment Condition',
		'lbldeliverydate': 'Delivery Date',
		'lblshipping': 'Shipping',
		'lblorderproduct': 'Order Product',
		'lblproductId': 'Product',
		'lblunitId': 'Unit',
		'lblquantity': 'Quantity',
		'lblexecute': 'Execute',
		'lblClear': 'Clear',
		'lbldefaultaddress': 'Default Address',
		'lbladdresss': 'Address',
		'lblnodataavailabletabulator': 'No data available', 
		'lblsupplierbannerTable': 'Supplier Banners',
		'lblid': 'Id',
		'lbldescription': 'Description',
		'lblsince': 'Since',
		'lbluntil': 'Until',
		'lblactive': 'Active',
		'lblsupplierbannerTableSchema': 'Banner',
        'lblhistory':'History',
        'lblusdtransactions':'USD / flat-fee per transaction',
        'lbleffectiveplan':'Plan Effective from:',
        'lblmintransactionscard':'Minimum Transactions:',
        'lblcontracttarifacard': 'Mobile Advertising (m-Ads)',
        'lblcontractpricesfobcard': 'Prices FOB Miami',
        'lblrequiredcontractcard': '3 year Suppliers Agreement required',
        'lblmintransactionsdesccard': 'Minimum required transactions: {min}/month - {max}/month',
        'lblcontractpublishcard':'In-app advertising (mobile ads)',
		'lblsetupfreetransactions' : 'USD / setup fee / one time',
		'lblBilledfreetransactions' : 'Billed monthly in USD based on #transactions',
		'lblHostedawsusa' : 'Hosted on AWS USA Region',
        'lblcontract': 'Plans',
        'lblcolumngrossamount': 'Gross Amount',
        'lblcolumncreatedon': 'Created On',
        'lblcolumnexpeteddelivery': 'Expected Delivery',
        'lblcolumndiscountamount': 'Discount Amount',
        'lblcolumntaxamount': 'Tax Amount',
        'lblcolumnnetamount': 'Net Amount',
        'lblcolumnstatus': 'Status',
        'lblcolumnQuantity': 'Quantity',
        'lblcolumnShortNameLanguage1': 'Short name',
        'lblcolumnShortNameLanguage2': 'Short name 2',
        'lblcolumnPrice': 'Price',
        'lbltransactionsQuantity':'Transactions Quantity',
        'lblaccountStatement':'Account statement',
        'lbllicenseType':'License Type',
        'lblfee':'Fee',
        'lblmintransactions': 'Min Transactions',
        'lblsummarymonth':'{day1} of {day2} {month}',
		'lblminTransactionsPayment':'{var1} of {var2} Min.Transactions required',
        'lblminPayment':'{amount1} of {amount2} Payment required',
        'lblmonth':'Current Month',
        'lblsummaryDashboard':'Summary',
        'lblbillingHistory':'Billing History',
        'lbltotalbilledamount':'Total Billed Amonth',
        'lblbestbillingmonth':'Best Billing Month',
        'lblbestbillingsupplier':'Best Billing Supplier',
        'lbldaysleft':'{days} left',
        'lblcurrentperiod':'Current Period',
        'lblplan':'Plan',
        'lbloptions':'Options',
        'lbltotalTransactions':'Total Transactions',
        'lblnameColumn':'Name',
        'lbltransactionspermonth':'Transactions per month',
        'lblestimatedbilled':'Estimated Billed',
        'lblavgorderamount':'Average order amount',
        'lblavgitemsperorder':'Average items per order',
        'lblactivityhistory':'Activity History',
        'lbltopfivesupplier':'Top 5 Suppliers',
        'lbltransactions':'Transactions',
        'lblbilledamount':'Billed amount',
        'lbldataavailableChartOption':'Select an option in the table to display the graph',
        'lbldataavailable':'No data available',
        'lblTableEmptyData':'Data Empty',
        'lblReports':'Reports',
        'lblStatementDashboard': 'Statement Account',	
		'lblFormatPreferences': 'Format preferences',
		'lblminversionappa': 'Min mobile app version Android',
		'lblminversionappios': 'Min mobile app version IOS',
		'lblConfigGeneral': 'General Configuration',
		'lblImageRequeriment': 'It is suggested to use the container specific measurements (360 x 160) px.',	
		'lblEmail': 'Email',
		'lblPassword': 'Password',
		'lblSend': 'Send',
		'lblRegisterNewUser': 'New user? Register here',
		'lblSigInIndication': 'Welcome',
		'lblGoToConsumerPage': 'Go to Consumer',
		'lblSupplierDeleted': 'Your Supplier is disabled, please validate with the platform administrator through email',
		'lblWaitForAcceptedI': 'We are validating your information at this time. Once we finish, you will receive an email notification. If you need to contact us, write to us at ',
		'lblWaitForAcceptedII': ' or visit the Consumer website, for more details on the validation procedure. ',
		'lblSetEmailConsumer': 'Email Consumer',
		'lblLoadingData': 'Loading Data...',
		'lblConsumerListing': 'Consumers',
		'lblErrorSupplierConsumerCatch': 'Error creating Supplier - Consumer relationship',
		'lblSetEmailConsumerInfo': 'Indicate email of Consumer to associate as [Consumer Demo] of Supplier',
		'lblAddConsumerDemo': 'Add Demo Consumer',
		'lblRequestCommentDemo': 'Automatic process for Demo Consumer',
		'lblAutomaticAssignDemo': 'Automatic Process',
		'lblconfirmEmail': 'Confirm Email',
		'lblCheckMinimum': 'Minimum 8 character',
		'lblCheckSpecial': 'Contain special character',
		'lblCheckNumber': 'Contain numbers',
		'lblCheckLowerCase': 'Contain lowercase',
		'lblCheckUpperCase': 'Contain uppercase',
		'lblCheckPassDifUser': 'Password are equals',
		'lblback': 'Back',
		'lblSignUp': 'Send',
		'lblSignUpIndication':'Register User',
		'lblconfirmPassword': 'Confirm Password',
		'lblForgotIndication': 'Forgot your password',
		'lblCode': 'Validation Code',
		'lblValidateCode': 'Validate Code',
		'lblValidationCode': 'Validation Code',
		'lblSendRequest': 'SEND REQUEST',
		'lblbusinessname': 'Company Name',
		'lblcomercialname': 'Company Commercial Name',
		'lblConsumerDemo': ' Demo Consumer',
		'lblErrorSupplierConsumerExist': 'Relationship Supplier - Consumer already exist!',
		'lblErrorSupplierConsumerEmailNotExist': 'Consumer Email not exist, please validate it',
		'lblConsumerDemoCreated': 'Supplier - Consumer created',
		'lbltaxid': 'Tax ID',
		'lblwebsite': 'Website',
		'lblimageUrl': 'Image',
		'lblSummary': 'Summary',
		'lblsummarybusiness': 'Company Summary',
		'lblAddress': 'Address',
		'lblRegisterIndication': 'Welcome to Register Supplier',
		'lblRegistration': 'Register Supplier',
		'lblCountry': 'Country',
		'lblstate': 'State',
		'lblcity': 'City',
		'lblzipcode': 'Zip Code',
		'lblstreet1': 'Address Line I',
		'lblstreet2': 'Address Line II',
		'lblstreet3': 'Address Line III',
		'lblPhone': 'Phone',
		'lblofficeNumber': 'Work Number',
		'lblmobileNumber': 'Mobile Number',
		'lblhomeNumber': 'Home Number',
		'lblContactPerson': 'Contact Person',
		'lblcontactName': 'Person',
		'lblAboutYou':'About you',
		'lblname': 'First Name',
		'lbllastName': 'Last Name',
		'lblSincronizing': 'Loading...',
		'lblForgotPass': 'Forgot your password?',
		'lblDashboard': 'Board',
		'lblConsumers': 'Consumers',
		'lblList': 'To List',
		'lblListAll': 'Supplier - Consumer',
		'lblSuppliers': 'Suppliers',
		'lblMyInfo': 'My Information',
		'lblSetting': 'Configuration',
		'lblconname': 'Name',
		'lblconemail':'Email',
		'lblconRequestDate': 'Requested',
		'lblconApprovalDate': 'Approved',
		'lblconERPCode': 'ERP Code',
		'lblNeto': 'Net',
		'lblBruto': 'Gross',
		'lblExclusive': 'Exclusive',
		'lblMerge': 'Merge',
		'lblBanner': 'Banner',
		'lblAdditional': 'Additional',
		'lblPreferences': 'Preferences',
		'lblERPCode': 'ERP Code',
		'lblItemSuppliers': 'Supplier',
		'lblItemsSuppliers': 'Suppliers',
		'lblAssignBanners': 'Assign Banners',
		'lblXSMSupplierInfo': 'By enabling Supplier as XSM, only XSM Consumers will be able to see it from mobile application',
		'lblXSMConsumerInfo': 'By enabling Consumer as XSM, only XSM Suppliers will be able to see it from mobile application',
		'lblSupplierXSM': 'Additional Settings',
		'lblisXSM': 'Supplier as XSM team',
		'lblisConsumerXSM': 'Consumer as XSM team',
		'lblIsActiveConsumerXSM': 'This Consumer is active as XSM team',
		'lblXSM': 'Are you XSM?',
		'lblConsumerTotal': 'Total',
		'lblConsumerXSM': 'XSM',
		'lblIsSupplierXSM': 'XSM',
		'lblSupplierTotal': 'Total',
		'lblItems': 'Items',
		'lbltaxId': 'Tax ID',
		'lblBanner1': 'Banner I',
		'lblBanner2': 'Banner II',
		'lblBanner3': 'Banner III',
		'lblVALIDATING': 'VALIDATING',
		'lblAPPROVED': 'APPROVED',
		'lblREJECTED': 'REJECTED',
		'lblCONFIGURING': 'CONFIGURING',
		'lblRequest': 'Request',
		'lblrequestedBy': 'Requested by',
		'lblrequestedon': 'Requested on',
		'lblrequestComments': 'Request comments',
		'lblDelivery': 'Delivery Day',
		'lblSameOrderDay': 'Same Day Delivery',
		'lblSameOrderDayUntil': 'Time until, Same Day Delivery',
		'lblsameDayDeliveryTimeZone': 'Time Zone Time Until',
		'lblSelectAMPM': 'Select  AM/PM',
		'lblclearAriaLabel': 'Clear',
		'lblclockAriaLabel': '',
		'lblConfigcodeNetoBrtuto': 'Consumer ERP Code',
		'lblAssignErpCode': 'Assign ERP Code',
		'lblSetERPCpde': 'ERP Code',
		'lblValidate': 'Validate',
		'lblSetMinimumOrder': 'Amount Type',
		'lblsettypecatalog': 'Catalog Type',
		'lblAssignStatus': 'Change Status',
		'lblTablesSchemas': 'Tables',
		'lblProductTitle': 'Products',
		'lblCategoryTitle': 'Categories',
		'lblSubcategoryTitle': 'Subcategories',
		'lblresponsedBy': 'Answered by',
		'lblresponsedon': ' Answered on',
		'lblNoDataAvailable': 'No Data Available',
		'lblresponseComments': 'Answer Comments',
		'lblconStatus': 'Status',
		'lblMinimumOrderConfig': 'Minimum Order Amount',
		'lblorderMinAmount': 'Amount ',
		'lblorderMinRule': ' Minimum Order Rule',
		'lblALL': 'ALL',
		'lblMonday': 'Monday',
		'lblTuesday': 'Tuesday',
		'lblWednesday': 'Wednesday',
		'lblThursday': 'Thursday',
		'lblFriday': 'Friday',
		'lblSaturday': 'Saturday',
		'lblSunday': 'Sunday',
		'lblTitleDashSupCon': '* Supplier - Consumer relationship status',
		'lblloadinglayout': 'Loading the UI',
		'lblGeneral': 'General Data',
		'lblBanners': 'Banners',
		'lblCreated': 'Created',
		'lblapproved': 'Approved',
		'lblConsumerID': 'Consumer',
		'lblConsumerName': 'Commercial Consumer Name',
		'lblconsumerBusinessName': 'Name',
		'lblSupplierID': 'Supplier ID',
		'lblSupName': 'Supplier Name',
		'lblSupStatus': 'Status',
		'lblDetail': 'Detail',
		'lblConsumer': 'Consumer',
		'lblSupRequestDate': 'Requested day',
		'lblSupApprovalDate': 'Approved day',
		'lblSupTaxID': 'Tax ID',
		'lblContactInformation': ' Contact Information',
		'lblRestorePass': 'Enter your new password, a validation code will be sent to your email, the code will be requested on the next step to complete the password restore',
		'lblSupAddress': 'Address',
		'lblContactSalesPerson': 'Sales Person Contact',
		'lblcontactEmail': 'Email',
		'lblCompanyBanner': 'Company Banner',
		'lblDRAFT': 'DRAFT',
		'lblDELETED': 'DELETED',
		'lblAVAILABLE': 'AVAILABLE',
		'lblUNAVAILABLE': 'UNAVAILABLE',
		'lblACTIVE': 'ACTIVE',
		'lblINACTIVE': 'INACTIVE',
		'lblWork': 'Office',
		'lblMobile': 'Mobile',
		'lblHome': 'Home',
		'lblsecurityallowed': 'Available permissions',
		'lblsecurityassigned': 'Assigned permissions',
		'lblRemoveAll':'Remove All',
		'lblRole':'Delete',
		'lblSearch':'Search',
		'lblnamelanguage': 'Description',
		'lblmessageImgError': 'No Valid URL, Please Validate the URL is correct or have permissions',
		'lblAddAll': 'Add All',
		'lblAdd': 'Add',
		'lblSave': 'Save',
		'lblBasicInfo': 'Basic Information',
		'lblContactInfo': 'Contact Information',
		'lblDefaultPhone': 'Set Default Phone',
		'lblConfigERPEndpoint': 'Configure ERP Endpoint',
		'lblendpointSupplierApi': 'URL',
		'lblendpointSupplierApiKey': 'Supplier Endpoint API Key',
		'lblendpointAuthType': 'Authentication type',
		'lblendpointAuthBasicUser': 'User',
		'lblendpointAuthBasicPass': 'Password',		
		'lblendpointSupplierApiSectretKey': 'Supplier Endpoint API Secret Key',
		'lblCreateAccount': 'Create Account',
		'lblBackRequest': 'GO BACK',
		'lblEspanol': 'Spanish',
		'lblEnglish': 'English',
		'lblLanguage': ' Language ',
		'lbltimezone': 'Time Zone',
		'lblValidateERP': 'Validate ERP Code',
		'lblCancel': 'Cancel',
		'lblAccept': 'Accept',
		'lblConfigCatOrd': 'Catálog and Currency',
		'lblBlockedForSale': 'Unavailable for Sale',
		'lblBlockedForSaleDate': 'Date Locked for Sale',
		'lblconStatusSales': 'Status for Sale',
		'lblLOCKED': 'LOCKED',
		'lblUNLOCKED': 'UNLOCKED',
		'lblRoles': 'Roles',
		'lblRolId': 'Role Id',
		'lblCrewId': 'Role Id',
		'lblRolType': 'Role Type',
		'lblRolDescription': 'Description',
		'lblSupplierAdvanced': 'Advanced Configuration',
		'lblProductTableSchema': 'Products',
		'lblCategoryTableSchema': 'Category',
		'lblSubCategoryTableSchema': 'Subcategory',
		'lblSyncLogs': 'Synchronization Logs',
		'lblSyncLogsShowLast5days': 'Showing information from the last 5 days',
		'lblError': 'Error',
		'msgErrorUpdating': 'An error occurred modifying the data',
		'msgErrorCreating': 'An error occurred creating the data',
		'lblDeliveryDays': 'Delivery Days',
		'msgFillDeliveryUntil': 'Must enter Time until, Same Day Delivery ',
		'msgFillDeliveryTimezone': 'Must enter Time Zone Time Until ',
		'lblSyncId': 'ID',
		'lblSyncDate': 'Date',
		'lblSyncSyncId': 'Sync ID',
		'lblSyncProcess': 'Process',
		'lblSyncStatus': 'Status',
		'lblSyncMessage': 'Message',
		'lblSyncErrors': 'Errors',
		'lblSyncSourceCount': 'Records at Origin',
		'lblSyncInserted': 'Qty. Inserted',
		'lblSyncUpdated': 'Qty. Modified',
		'lblSyncNoupdated': 'Qty. No Modified',
		'lblSyncErrored': 'Qty. Errors',
		'lblSyncErrorNumber': ' Error Number',
		'lblSyncTotalDuration': 'Total Duration',
		'lblTODO': 'QUEUED',
		'lblPROCESSING': 'IN PROCESS',
		'lblDONE': 'DONE',
		'lblSyncLogFilter': 'STATUS',
		'lblnameLanguage1': 'Name',
		'lblnameLanguage2': 'Name 2',
		'lblnameLanguage3': 'Name 3',
		'lblnameLanguage4': 'Name 4',
		'lblsubcategoryId': 'ID',
		'lblsupplierId': 'Supplier ID',
		'lbladditionalImageUrl': 'Additional Image',
		'lblamountDiscount': 'Discount Amount',
		'lbldescriptionLanguage1': 'Description',
		'lbldescriptionLanguage2': 'Description 2',
		'lbldescriptionLanguage3': 'Description 3',
		'lbldescriptionLanguage4': 'Description 4',
		'lblpercentageDiscount': 'Percentage Discount',
		'lblprice': 'Price',
		'lblshortnameLanguage1': 'Short name',
		'lblshortnameLanguage2': 'Short name 2',
		'lblshortnameLanguage3': 'Short name 3',
		'lblshortnameLanguage4': 'Short name 4',
		'lbltags': 'Labels',
		'lblunit': 'Unit',
		'lblcolumnsHide': 'Hide Columns ',
		'lblShowError':'Errors',
		'lblproductTableSchema': 'Products',
		'lblproductTable': 'Products',
		'lblcategoryTable': 'Categories',
		'lblsubcategoryTable': 'Subcategories',
		'lblcategoryTableSchema': 'Categories',
		'lblsubcategoryTableSchema': 'Subcategories',
		'lblconsumerproductTableSchema': 'Products by Customer',
		'lblcustomerbannerTableSchema': 'Customer Banner',
		'msgEndpointNotConfig': '[Endpoint API] no configured',
		'msgCustomerIdNotFound': 'Customer does not exist in the ERP, validate with your administrator',
		'lblAssignStatusConsumer': 'Assign Consumer Status',
		'msgSetErpCodeBeforeChangeStatus': 'Set the ERP Code before changing status',
		'lblLogin': 'Login',
		'msgEmailIsaConsumer': 'This email is registered as a Consumer, please use another email',
		'msgWelcometoConsumer': 'Welcome XSales Consumer',
		'msgErrorValidatingUserPass': 'Error validating User/Password',
		'msgNotUseAdmin': 'If you are not an Administrator, do not use this SignIn page',
		'lblForgot': 'Forgot Password',
		'msgEmailSendAgain': 'The Email was forwarded',
		'msgPleaseAgain': 'Please try again',
		'msgPassNotBeEquals': 'Passwords and Confirm Password must be equal and the email must not be empty',
		'lblRegister': 'Register',
		'msgRegisterOk': 'You have been registered successfully',
		'msgErrorSavingData': 'There was an error saving data, please try again',
		'msgUserNotConfirmetYet': 'User not confirmed yet',
		'msgResendingCode': 'Forwarding the code',
		'msgLoginFail': 'Authentication process has failed, please try again',
		'msdCodeVerified': 'Code Verified',
		'msgUserRegisteredAsConsumer': 'User registered as a Consumer',
		'msgLoginUserNotExist': 'User not exists, please validate your email',
		'msgUserDisabled': 'Your user is disabled, please contact XSM Support',
		'msgUserPassIncorrect': 'Incorrect User or Password',
		'lblActivateSupplier': 'Activate Supplier',
		'msgEmailAlreadyExist': 'Email already exists in XSales Consumer',
		'msgErrorValidatingCode': 'Error validating code',
		'msgErrorOcurred': 'There was an error, please try again',
		'msgPassChanged': 'Password changed successfully',
		'msgCodeExpired': 'The code has expired, please try again',
		'msgCodeIsNotValid': 'The code is not valid',
		'msgFillErpCode': 'Enter the customer code in the ERP',
		'msgValidateErpCode': 'Must validate the Customer ERP code in the ERP',
		'lblAuthType_NoAuth': 'No Authentication',
		'lblAuthType_ApiKey': 'API Key',
		'lblAuthType_Basic': 'Basic Authentication (user/password)',
		'lblCurrencySymbol': 'Currency Symbol',
		'msgCurrencyInherit': 'Inherit from Supplier',
		'msgChangeCatalogExclusive': 'The set Catalog will be changed to Exclusive, do you want to continue?',
		'msgCatalogExclusive':'If the customer handles a currency symbol other than the Supplier, it is appropriate to handle a catalog of exclusive products in the customers currency',
		'lblcancelText':'No',
		'lblConfirmText':'Yes',
		'lblConfirmTittle':'Confirm',
		'lblStandAlone':'Standalone',
		'lblModeStandAlone':'Mode Standalone',
		'lblEnableStandAlone':'ModeStandAlone',
		'msgEnableStandAlone':'Use internal API',
		'lblLanguages': 'Additional Languages',
		'msgLanguages': 'You can configure up to 3 additional languages',
		'lblcurrencyisoInternal': 'Internal Currency',
		'lblconfigcurrencyInternal':'Currency Internal settings',
		'lbllanguage1': 'Language 1',
		'lbllanguage2': 'Language 2',
		'lbllanguage3': 'Language 3',
		'lbllanguage4': 'Language 4',
		'msgInternalCurrency':'This parameter is used to calculate the statement of accounts for internal use',
		'lblProducts': 'Products',
		'lblProduct': 'Product',
		'lblCategory': 'Category',
		'lblSubCategory': 'SubCategory',
		'lblNewSubCategory': 'Add Category',
		'lblmsginactivetext': 'This parameter allows inactivating the record for viewing on the mobile',
		'lblmsgrecordexists': 'Record already exists',
		'lbllnamelanguage1': 'Name',
		'lbllnamelanguage2': 'Name 2',
		'lbllnamelanguage3': 'Name 3',
		'lbllnamelanguage4': 'Name 4',
		'lblmsgconfirminactive': 'Are you sure you want to inactive ?',
		'lblmsgconfirmactive': 'Are you sure you want to active ?',
		'lblconfirmation': 'Confirmation',
		'lbldropzone':'Click or Drop',
		'lblinactive': 'Inactive',
		'lbledit': 'Edit',
		'lblcreate': 'Create',
		'lblsuccess':'Saved successfully',
		'lblprocess':'Processing...',
		'lblprocesserror': 'Processing error please consult the administrator',
		'lblinactiveParent': 'Inactive Parent',
		'lbltaxFree': 'Tax Free',
		'lblUpdateforLot': 'Update for Lot',
		'lblBatchProcess': 'Batch Process',
		'lblUnlockedForSale': 'Available for Sale',
		'lblpercentageTax': 'Percentage Tax',
		'lblConfigurePercentage': 'Configure Percentage',
		'lblConfigGroup': "Configure Groups",
		'lblActiveParent': 'Active Parent',
		'lblPaymentMethodsConfig': 'Configure Payment Methods',
		'lblactiveSubCategory' : 'SubCategory Active',
		'lblactiveCategory' : 'Category Active',
		'lblavailableForSale' : 'Available for Sale',
		'lblActiveOrderManagement': 'Available Order Management',
		'lblActiveBanners': 'Available Banners',
		'lblPrices':'Prices',
		'lblListPrices': 'List Prices',
		'lblpriceSpecial': 'Special Price',
		'lblChecked': 'CHECKED',
		'lblUnChecked': 'UNCHECKED',
		'lblActiveListPrice': 'Available List Prices',
		'lblActiveAdminProducts': 'Available Admin Products',
		'lblSync': 'Sync',
		'lblUpload': 'Import',
		'lblPostOrder': 'Post Order',
		'lblPostQuote': 'Post Quote',
		'lblOnlyRead': 'Only Read',
		'lblMsgOnlyRead': 'Information from the Integration, shown here for reference only',
		'lblbarcode': 'Barcode',
		'lblsameOrderClass': 'Same Order Class',
		'lblEnableOrderManagement':'Order Management',
		'lblEnablePromotions':'Promotions',
		'lblEnableAdminProducts':'Admin Products',
		'lblEnableListPrice':'List Prices',
		'lblEnableMinimunStock': 'Minimun Stock',
		'lblEnableBarcodeScanner': 'BarCode Scanner',
		'lblEnableCustomerCatalog': 'Customer Catalog',
		'lblEnableProductDiscount': 'Percentaje Discounts',
		'lblEnableSameOrderClass': 'SOC Products',
		'lblEnableShippingAddress': 'Shipping Address',
		'lblEnablePaymentMethod': 'Payment Method',

		'lblhelpmodestandalone': 'Offline mode, without connection to an API',
		'lblhelpordermanagement': 'View orders and schedule their delivery',
		'lblhelppromotions': 'Promotion settings',
		'lbladminproducts': 'Product Configuration and Management',
		'lblhelplistprices': 'Configuration of price lists',
		'lblhelpminimunstock': 'Minimum Stock configuration in your products',
		'lblhelpbarCodescanner': 'Barcode scanning on your products',
		'Customercatalog': 'Product Catalog Configuration',
		'Percentajediscounts': 'Discount Percentage Settings',
		'lblhelpsocproducts': 'Sorting of products according to the same class',
		'lblhelpshippingaddress': 'View delivery addresses',
		'lblhelpPaymentMethod': 'Configuration of payment methods',
		'lblrequiredImage': 'Image is Required',
	},
    'ES':	{
		'lblHello': 'Hola',
		'lbldownloadtemplatewizard': 'Descargar Plantilla',
		'lbluploadfilewizard': 'Cargar Archivo',
		'lblpreviewwizard': 'Visualizar',
		'lblsavedatawizard': 'Guardar Data',
		'lbldownloadxlswizard':'Descargar Plantilla (.xlsx)',
		'lbldownloadcsvwizard':'Descargar Plantilla (.csv)',
		'lblbtnbackwizard': 'Regresar',
		'lblbtnnextwizard': 'Siguiente',
		'msgimportedwizard': 'Por favor visualizar la tabla de log para ver el resultado del proceso.',
		'lblresultwizard': 'Resultado',
		'lblimportwizard': 'Asistente de importación',
		'lblOrdMaPerDeliveryDate': 'Por Fecha de entrega',
		'lblOrdMaPerClientGroup': 'Por Grupo de Cliente',
		'lblOrdMaPerProduct': 'Por Producto',
		'msgOrdMaMaxDays':'El rango no debe ser mayor a 7 dias',
		'lblOrdMaProductsToDelivery': 'Productos a entregar',
		'lblOrdMaOrdersToDelivery': 'Ordenes a entregar',
		'lblOrdMaStatusOrder': 'Estatus Orden',
		'lblOrdMaReportBy':'Reporte Por',
		'lblOrdMaShippingType': 'Tipo de Envio',
		'lblOrdMaType': 'Tipo',
		'lblOrdMaFrom': 'Desde',
		'lblOrdMaTo': 'Hasta',
		'lblOrdMagroup1':'Grupo1',
		'lblOrdMagroup2':'Grupo2',
		'lblOrdMagroup3':'Grupo3',
		'lblOrdMaCustomer':'Cliente',
		'lblOrdMaOrder':'Orden',
		'lblOrdMaCreatedOn':'Creado el',
		'lblOrdMaItemsQty':'Articulos',
		'lblOrdMaDelivered':'Entregado',
		'lblOrdMaOrders': 'Ordenes',
		'lblOrdMaDeliveryDate': 'Fecha Entrega',
		'lblToday': 'Hoy',
		'lblYesterday': 'Ayer',
		'lblTomorrow': 'Mañana',
		'lblRange': 'Rango',
		'lblPlaced': 'Realizados',
		'lblDelivered': 'Entregados',
		'lblAll': 'Todos',
		'lblShippingDelivery': 'Entrega',
		'lblShippingPickup': 'Recoger',
		'lblClientGroup1':'Grupo Cliente 1',
		'lblClientGroup2':'Grupo Cliente 2',
		'lblClientGroup3':'Grupo Cliente 3',
		'lblOrders': 'Ordenes',
		'lblOrdMaDeliveryAddress': 'Dirección Entrega',
		'lblOrdMaComments': 'Comentarios',
		'lblOrdMapaymentMethod': 'Método de pago',
		'lblOrdMaCode': 'Código',
		'lblOrdMaName': 'Nombre',
		'lblOrdMaImage': 'Imagen',
		'lblOrdMaUnit': 'Unidad',
		'lblOrdMaQty': 'Cantidad',
		'lblOrdMaPrice': 'Precio',
		'lblOrdMaTotal': 'Total',
		'lblOrdMaGrossAmount': 'Monto Bruto',
		'lblOrdMaDiscountAmount': 'Descuento',
		'lblOrdMaSubTotal': 'Sub-Total',
		'lblOrdMaSubTaxes': 'Impuestos',
		'lblOrdMaNetAmount':'Monto Neto',
		'lblOrdMaOrderQty': 'Cantidad Orden',
		'lblOrdMaCurrencyISO': 'ISO Moneda',
		'lblOrdMaLastDay': 'Día Anterior',
		'lblOrdMaNextDay': 'Siguiente Día',
		'lblOrdMaBack': 'Regresar',
		'lblOrdMaShowImageProduct': 'Habilitar Imagen de producto',
		'lblOrderMenu': 'Orden',
		'lblordermanagment': 'Manejo de Ordenes',


		'msgMarkDeliveredOrder': '¿Esta seguro de marcar la orden entregada?',
		'msgUnmarkDeliveredOrder': '¿Esta seguro de desmarcar la orden entregada?',
		'lblcategoryid':'Id',
		'lblrequiredField': 'Campo es requerido',
		'lblmaxLengthField': 'Longitud superior a {length}',
		'lblimginappropriatecontent': 'La imagen contiene contenido inapropiado',
		'lbllogo': 'Logo',
        'lblfuture': 'Futuro',
        'lblplancontract': 'Plan',
        'lblfeecontract': 'Tarifa',
        'lblmintransactioncontract':'Transacciones Minimas',
        'lblsincecontract': 'Hasta',
        'lblmessageconfirmcontract': '¿Esta seguro que desea continuar?',
        'lblcontinue':'Continuar',
        'lblconfirm':'Confirmación',
        'lblconfigcurrency':'Configuración de moneda y simbolo',
        'lblcurrencyiso':'Moneda',
        'lblcurrencysymbol':'Simbolo',
        'lblcurrencytab':'Moneda',
        'lblexchangerate': 'Tasa de cambio',
        'lblamountusd': 'Importe USD',
        'lblinactivebanner': 'Inactivo',
        'lblactivebanner': 'Activo',
        'lblgeneralinfo': 'Información General',
        'lblpreferencessup': 'Preferencias',
        'lblbannerssup':'Pancartas',
        'lblpromotionalbanners': 'Pancartas Promocionales',
        'lbllatestrates': 'Últimas Tasas',
        'lblfrom': 'Desde',
        'lblto': 'Hasta',
        'lblrate': 'Tasa',
        'lbleffectiveDate': 'Fecha Efectiva',
        'lblmessagepostorder': `Advertencia:
        Esto es un modulo de pruebas del API POST /order y POST /quote.
        Las transacciones realizadas en este modulo no quedan registradas en el sistema Consumer pero si pueden quedar registradas en el ERP correspondiente.
        Realice sus pruebas con esa consideración.`,
        'lblpostorderTableSchema': 'Post Order',
        'lblpostquoteTableSchema': 'Post Quote',
        'lblclose': 'Close',
        'lbltestpostorder': 'Prueba Post Order',
        'lbltestpostquote': 'Prueba Post Quote',
        'lblcustomerId': "Cliente",
        'lblpaymentcondition': "Condición de pago",
        'lbldeliverydate': 'Fecha Entrega',
        'lblshipping': 'Envio',
        'lblorderproduct': 'Productos',
        'lblproductId': 'Producto',
        'lblunitId': 'Unidad',
        'lblquantity': 'Cantidad',
        'lblexecute': 'Ejecutar',
        'lbldefaultaddress': 'Por defecto',
        'lbladdresss': 'Direcciones',
        'lblclear': 'Limpiar',
        'lblnodataavailabletabulator': 'No hay data disponible',
        'lblsupplierbannerTable': 'Banner',	
		'lblid': 'Id',
		'lbldescription': 'Descripción',
		'lblsince': 'Desde',
		'lbluntil': 'Hasta',
		'lblactive': 'Activo',
		'lblsupplierbannerTableSchema': 'Pancarta',
        'lblhistory':'Historia',
        'lblusdtransactions':'USD / tarifa plana por transacción',
        'lbleffectiveplan':'Plan Efectivo desde:',
        'lblmintransactionscard':'Transacciones Minimas:',
        'lblcontracttarifacard': 'Publicidad móvil (m-Ads)',
        'lblcontractpricesfobcard': 'Precios FOB Miami',
        'lblrequiredcontractcard': 'Requiere contrato de proveedores de 3 años',
        'lblmintransactionsdesccard': 'Mínimo de transacciones requeridas: {min}/mes - {max}/mes',
        'lblcontractpublishcard':'Publicidad In-App (m-Ads)',
		'lblsetupfreetransactions': 'USD / tarifa de instalación / una vez',
		'lblBilledfreetransactions' : 'Facturado mensualmente en USD según #transacciones',
		'lblHostedawsusa' : 'Alojado en la región de EE. UU. de AWS',
        'lblcontract': 'Planes',
        "lblcolumngrossamount": "Importe Bruto",
        "lblcolumncreatedon": "Creado en",
        "lblcolumnexpeteddelivery": "Entrega esperada",
        "lblcolumndiscountamount": "Importe de descuento",
        "lblcolumntaxamount": "Impuesto",
        "lblcolumnnetamount": "Importe neto",
        "lblcolumnstatus": "Estatus",
        "lblcolumnQuantity": "Cantidad",
        "lblcolumnShortNameLanguage1": "Nombre L1",
        "lblcolumnShortNameLanguage2": "Nombre L2",
        "lblcolumnPrice": "Precio",
        "lbltransactionsQuantity":"Cantidad de transacciones",
        "lblaccountStatement":"Estado de cuenta",
        "lbllicenseType":"Tipo de licencia",
        "lblfee":"Tarifa",
        "lblmintransactions": "Transacciones minimas",
        "lblsummarymonth":"{day1} de {day2} {month}",
        "lblminTransactionsPayment":"{var1} de {var2} Min.Transacciones requeridas",
        "lblminPayment":'{amount1} de {amount2} Pago requerido', 
        "lblmonth":"Mes Actual",
        "lblsummaryDashboard":"Resumen",
        "lblbillingHistory":"Historial de estado de cuenta",
        "lbltotalbilledamount":"Estimado del mes",
        "lblbestbillingmonth":"Mejor mes",
        "lblbestbillingsupplier":"Mejor proveedor",
        "lbldaysleft":"Quedan {days} días",
        "lblcurrentperiod":"Periodo Actual",
        "lblplan":"Plan",
        "lbloptions":"Opciones",
        "lbltotalTransactions":"Total Transacciones",
        "lblnameColumn":"Nombre",
        "lbltransactionspermonth":"Transacciones por mes",
        "lblestimatedbilled":"Monto Estimado",
        "lblavgorderamount":"Monto promedio de la orden",
        "lblavgitemsperorder":"Artículos promedio por pedido",
        "lblactivityhistory":"Historial de Actividad",
        "lbltopfivesupplier":"Top 5 Proveedores",
        "lbltransactions":"Transacciones",
        "lblbilledamount":"Monto Estimado",
        "lbldataavailable":"No hay datos disponibles",
        "lbldataavailableChartOption":"Seleccione una opcion en la tabla para mostrar el grafico",
        "lblTableEmptyData":"Sin Datos",
        "lblReports":"Reportes",
        "lblStatementDashboard": "Estado de cuenta",
		'lblFormatPreferences': 'Preferencias de formato',
		'lblminversionappa': 'Versión mínima de la aplicación móvil Android',
		'lblminversionappios': 'Versión mínima de la aplicación móvil IOS',
		'lblConfigGeneral': 'Configuración General',
		'lblImageRequeriment': 'Se sugiere utilizar las medidas específicas del contenedor (360 x 160) px.',
		'lblEmail': 'Correo',
		'lblPassword': 'Contraseña',
		'lblSend': 'Enviar',
		'lblRegisterNewUser': '¿Nuevo Usuario? Registrarse aquí',
		'lblSigInIndication': 'Bienvenido',
		'lblGoToConsumerPage': 'Ir a Consumer',
		'lblSupplierDeleted': 'Su Proveedor se encuentra desactivado, favor valide con el administrador de la plataforma a través del correo ',
		'lblWaitForAcceptedI': 'En este momento nos encontramos validando tu información. Una vez que terminemos, recibirás un email de notificación. Si necesitas contactarnos, escríbenos a ',
		'lblWaitForAcceptedII': ' ó visita la página web de Consumer, para conocer más detalles del procedimiento de validación. ',
		'lblSetEmailConsumer': 'Correo Consumer',
		'lblLoadingData': 'Cargando Datos...',
		'lblConsumerListing': 'Consumidores',
		'lblErrorSupplierConsumerCatch': 'Error creando relación Proveedor - Consumidor',
		'lblSetEmailConsumerInfo': 'Indique el correo del Consumidor a asociar como [Consumidor Demo] del Proveedor',
		'lblAddConsumerDemo': 'Agregar Consumer Demo',
		'lblRequestCommentDemo': 'Proceso Automático para Consumidor Demo',
		'lblAutomaticAssignDemo': 'Proceso Automático',
		'lblconfirmEmail': 'Confirmar Correo',
		'lblCheckMinimum': 'Mínimo 8 caracteres',
		'lblCheckSpecial': 'Contiene caracteres especiales',
		'lblCheckNumber': 'Contiene números',
		'lblCheckLowerCase': 'Contiene minúsculas',
		'lblCheckUpperCase': 'Contiene mayúsculas',
		'lblCheckPassDifUser': 'Contraseña son iguales',
		'lblback': 'Atrás',
		'lblSignUp': 'Enviar',
		'lblSignUpIndication':'Registrar Usuario',
		'lblconfirmPassword': 'Confirmar Contraseña',
		'lblForgotIndication': 'Olvidó su Contraseña',
		'lblCode': 'Código Validación',
		'lblValidateCode': 'Validar Código',
		'lblValidationCode': 'Código Validación',
		'lblSendRequest': 'ENVIAR SOLICITUD',
		'lblbusinessname': 'Nombre Empresa',
		'lblcomercialname': 'Nombre Comercial',
		'lblConsumerDemo': 'Consumidor Demo',
		'lblErrorSupplierConsumerExist': '¡Relación Proveedor - Consumidor ya existe!',
		'lblErrorSupplierConsumerEmailNotExist': 'El Correo del Consumidor no existe, favor valide',
		'lblConsumerDemoCreated': 'Proveedor - Consumidor creado',
		'lbltaxid': 'Impuesto ID',
		'lblwebsite': 'Sitio Web',
		'lblimageUrl': 'Imagen',
		'lblSummary': 'Resumen',
		'lblsummarybusiness': 'Resumen Empresa',
		'lblAddress': 'Dirección',
		'lblRegisterIndication': 'Bienvenido a Registrar Proveedor',
		'lblRegistration': 'Registrar Proveedor',
		'lblCountry': 'País',
		'lblstate': 'Estado',
		'lblcity': 'Ciudad',
		'lblzipcode': 'Código Postal',
		'lblstreet1': 'Línea de Dirección I',
		'lblstreet2': 'Línea de Dirección II',
		'lblstreet3': 'Línea de Dirección III',
		'lblPhone': 'Teléfono',
		'lblofficeNumber': 'Número Oficina',
		'lblmobileNumber': 'Número Móvil',
		'lblhomeNumber': 'Número Hogar',
		'lblContactPerson': 'Persona Contacto',
		'lblcontactName': 'Persona',
		'lblAboutYou':'Acerca de Ti',
		'lblname': 'Nombres',
		'lbllastName': 'Apellidos',
		'lblSincronizing': 'Cargando...',
		'lblForgotPass': '¿Olvidó su Contraseña?',
		'lblDashboard': 'Tablero',
		'lblConsumers': 'Consumidores',
		'lblList': 'Listar',
		'lblListAll': 'Proveedores - Consumidores',
		'lblSuppliers': 'Proveedores',
		'lblMyInfo': 'Mi Información',
		'lblSetting': 'Configuración',
		'lblconname': 'Nombre',
		'lblconemail': 'Correo',
		'lblconRequestDate': 'Día Solicitado',
		'lblconApprovalDate': 'Día Aprobado',
		'lblconERPCode': 'Código ERP',
		'lblNeto': 'Neto',
		'lblBruto': 'Bruto',
		'lblExclusive': 'Exclusivo',
		'lblMerge': 'Unión',
		'lblBanner': 'Pancarta',
		'lblAdditional': 'Adicionales',
		'lblPreferences': 'Preferencias',
		'lblERPCode': 'Código ERP',
		'lblItemSuppliers': 'Proveedor',
		'lblItemsSuppliers': 'Proveedores',
		'lblAssignBanners': 'Asignar Pancartas',
		'lblXSMSupplierInfo': 'Al habilitar el Proveedor como XSM, solo los Consumidores XSM lo podrán ver desde la aplicación móvil',
		'lblXSMConsumerInfo': 'Al habilitar el Consumidor como XSM, solo podrá visualizar Proveedores XSM desde la aplicación móvil',
		'lblSupplierXSM': 'Configuraciones Adicionales',
		'lblisXSM': 'Proveedor como parte del equipo XSM',
		'lblisConsumerXSM': 'Consumidor como parte del equipo XSM',
		'lblIsActiveConsumerXSM': 'Este Consumidor está activo como parte del Equipo XSM',
		'lblXSM': 'Es XSM?',
		'lblConsumerTotal': 'Total',
		'lblConsumerXSM': 'XSM',
		'lblIsSupplierXSM': 'XSM',
		'lblSupplierTotal': 'Total',
		'lblItems': 'Artículos',
		'lbltaxId': 'ID Impuesto',
		'lblBanner1': 'Pancarta I',
		'lblBanner2': 'Pancarta II',
		'lblBanner3': 'Pancarta III',
		'lblVALIDATING': 'VALIDANDO',
		'lblAPPROVED': 'APROBADO',
		'lblREJECTED': 'RECHAZADO',
		'lblCONFIGURING': 'CONFIGURANDO',
		'lblRequest': 'Solicitud',
		'lblrequestedBy': 'Solicitado por ',
		'lblrequestedon': 'Solicitado el día',
		'lblrequestComments': 'Comentario de la solicitud',
		'lblDelivery': 'Día de Entrega',
		'lblSameOrderDay': 'Entrega mismo Día',
		'lblSameOrderDayUntil': 'Hora Hasta, Entrega mismo Día',
		'lblsameDayDeliveryTimeZone': 'Zona Horaria Hora Hasta',
		'lblSelectAMPM': 'Seleccione AM/PM',
		'lblclearAriaLabel': 'Limpiar',
		'lblclockAriaLabel': '',
		'lblConfigcodeNetoBrtuto': 'Código ERP del Consumidor',
		'lblAssignErpCode': 'Asignar código ERP',
		'lblSetERPCpde': 'Indicar código ERP',
		'lblValidate': 'Validar',
		'lblSetMinimumOrder': 'Tipo de Monto',
		'lblsettypecatalog': 'tipo de Catálogo',
		'lblAssignStatus': 'Cambiar Estatus',
		'lblTablesSchemas': 'Tablas',
		'lblProductTitle': 'Productos',
		'lblCategoryTitle': 'Categorías',
		'lblSubcategoryTitle': 'Subcategorías',
		'lblresponsedBy': 'Respondido por',
		'lblresponsedon': 'Respondido el día',
		'lblNoDataAvailable': 'Datos no disponibles',
		'lblresponseComments': 'Comentarios Repuesta',
		'lblconStatus': 'Estatus',
		'lblMinimumOrderConfig': 'Cantidad Mínima Orden',
		'lblorderMinAmount': 'Cantidad',
		'lblorderMinRule': 'Regla Mínima Orden',
		'lblALL': 'TODOS',
		'lblMonday': 'Lunes',
		'lblTuesday': 'Martes',
		'lblWednesday': 'Miércoles',
		'lblThursday': 'Jueves',
		'lblFriday': 'Viernes',
		'lblSaturday': 'Sábado',
		'lblSunday': 'Domingo',
		'lblTitleDashSupCon': '* Estatus relación Proveedores - Consumidores',
		'lblloadinglayout': 'Cargando el UI',
		'lblGeneral': 'Datos Generales',
		'lblBanners': 'Pancartas',
		'lblCreated': 'Creado',
		'lblapproved': 'Aprobado',
		'lblConsumerID': 'Consumidor',
		'lblConsumerName': 'Nombre Comercial Consumidor',
		'lblconsumerBusinessName': 'Nombre',
		'lblSupplierID': 'Proveedor ID',
		'lblSupName': 'Nombre Proveedor',
		'lblSupStatus': 'Estatus',
		'lblDetail': 'Detalle',
		'lblConsumer': 'Consumidor',
		'lblSupRequestDate': 'Día Solicitado',
		'lblSupApprovalDate': 'Día Aprobado',
		'lblSupTaxID': 'Impuesto ID',
		'lblContactInformation': 'Información de Contacto',
		'lblRestorePass': 'Ingrese su nueva contraseña, un código de validación será enviado a tu email, el código será solicitado en el siguiente paso para completar la restauración de clave',
		'lblSupAddress': 'Dirección',
		'lblContactSalesPerson': 'Contacto Personal de Ventas',
		'lblcontactEmail': 'Correo',
		'lblCompanyBanner': 'Pancarta Compañía',
		'lblDRAFT': 'BORRADOR',
		'lblDELETED': 'BORRADO',
		'lblAVAILABLE': 'DISPONIBLE',
		'lblUNAVAILABLE': 'NO DISPONIBLE',
		'lblACTIVE': 'ACTIVO',
		'lblINACTIVE': 'INACTIVO',
		'lblWork': 'Oficina',
		'lblMobile': 'Móvil',
		'lblHome': 'Hogar',
		'lblsecurityallowed': 'Permisos Disponibles',
		'lblsecurityassigned': 'Permisos Asignados',
		'lblRemoveAll':'Remover Todos',
		'lblRole':'Eliminar',
		'lblSearch':'Buscar',
		'lblnamelanguage': 'Descripción',
		'lblmessageImgError': 'URL No Valida, favor valide la dirección URL sea correcta o que tenga permisos',
		'lblClear': 'Limpiar',
		'lblAddAll': 'Agregar Todos',
		'lblAdd': 'Agregar',
		'lblSave': 'Guardar',
		'lblBasicInfo': 'Información Básica',
		'lblContactInfo': 'Información Contacto',
		'lblDefaultPhone': 'Configurar Teléfono por Defecto',
		'lblConfigERPEndpoint': 'Configuración del API',
		'lblendpointSupplierApi': 'URL',
		'lblendpointSupplierApiKey': 'API Key',
		'lblendpointAuthType': 'Tipo de autenticación',
		'lblendpointAuthBasicUser': 'Usuario',
		'lblendpointAuthBasicPass': 'Contraseña',
		'lblendpointSupplierApiSectretKey': '[Endpoint API Secret Key] del Proveedor',
		'lblAuthType_NoAuth': 'Sin autenticación',
		'lblAuthType_ApiKey': 'API Key',
		'lblAuthType_Basic': 'Autenticación Básica (usuario/contraseña)',
		'lblCreateAccount': 'Crear una Cuenta',
		'lblBackRequest': 'REGRESAR',
		'lblEspanol': 'Español',
		'lblEnglish': 'Ingles',
		'lblLanguage': 'Idioma',
		'lbltimezone': 'Zonas Horarias',
		'lblValidateERP': 'Validar Código ERP',
		'lblCancel': 'Cancelar',
		'lblAccept': 'Aceptar',
		'lblConfigCatOrd': 'Catálogo y Moneda',
		'lblBlockedForSale': 'Bloqueo para venta',
		'lblBlockedForSaleDate': 'Fecha Bloqueo para venta',
		'lblconStatusSales': 'Estatus para Venta',
		'lblLOCKED': 'BLOQUEADO',
		'lblUNLOCKED': 'DESBLOQUEADO',
		'lblRoles': 'Roles',
		'lblRolId': 'ID de Rol',
		'lblCrewId': 'ID de Rol',
		'lblRolType': 'Tipo de Rol',
		'lblRolDescription': 'Descripción',
		'lblSupplierAdvanced': 'Config. Avanzada',
		'lblProductTableSchema': 'Productos',
		'lblCategoryTableSchema': 'Categoría',
		'lblproductTable': 'Productos',
		'lblcategoryTable': 'Categorías',
		'lblsubcategoryTable': 'Subcategorías',
		'lblSubCategoryTableSchema': 'Sub Categoría',
		'lblSyncLogs': 'Registros de Sincronización',
		'lblSyncLogsShowLast5days': 'Mostrando informacion de los ultimos 5 dias',
		'lblError': 'Error',
		'msgErrorUpdating': 'Ocurrió un error al modificar la data',
		'msgErrorCreating': 'Ocurrió un error creando la data',
		'lblDeliveryDays': 'Días Entrega',
		'msgFillDeliveryUntil': 'Debe colocar Hora Hasta, Entrega mismo Día',
		'msgFillDeliveryTimezone': 'Debe colocar Zona Horaria Hora Hasta',
		'lblSyncId': 'ID',
		'lblSyncDate': 'Fecha',
		'lblSyncSyncId': 'ID de Sincronización',
		'lblSyncProcess': 'Proceso',
		'lblSyncStatus': 'Estatus',
		'lblSyncMessage': 'Mensaje',
		'lblSyncErrors': 'Errores',
		'lblSyncSourceCount': 'Registros en el Origen',
		'lblSyncInserted': 'Cant. Insertados',
		'lblSyncUpdated': 'Cant. Modificados',
		'lblSyncNoupdated': 'Cant. No Modificados',
		'lblSyncErrored': 'Cant. Errores',
		'lblSyncErrorNumber': 'Número del Error',
		'lblSyncTotalDuration': 'Duración Total',
		'lblTODO': 'EN COLA',
		'lblPROCESSING': 'EN PROCESO',
		'lblDONE': 'LISTO',
		'lblSyncLogFilter': 'ESTATUS',
		'lblnameLanguage1': 'Nombre',
		'lblnameLanguage2': 'Nombre 2',
		'lblnameLanguage3': 'Nombre 3',
		'lblnameLanguage4': 'Nombre 4',
		'lblsubcategoryId': 'ID',
		'lblsupplierId': 'ID Proveedor',
		'lbladditionalImageUrl': 'Imagen Adicional',
		'lblamountDiscount': 'Cantidad Descuento',
		'lbldescriptionLanguage1': 'Descripción',
		'lbldescriptionLanguage2': 'Descripción 2',
		'lbldescriptionLanguage3': 'Descripción 3',
		'lbldescriptionLanguage4': 'Descripción 4',
		'lblpercentageDiscount': 'Porcentaje Descuento',
		'lblprice': 'Precio',
		'lblshortnameLanguage1': 'Nombre corto',
		'lblshortnameLanguage2': 'Nombre corto 2',
		'lblshortnameLanguage3': 'Nombre corto 3',
		'lblshortnameLanguage4': 'Nombre corto 4',
		'lbltags': 'Etiquetas',
		'lblunit': 'Unidad',
		'lblcolumnsHide': 'Ocultar Columnas',
		'lblShowError':'Errores',
		'lblproductTableSchema': 'Productos',
		'lblcategoryTableSchema': 'Categorías',
		'lblsubcategoryTableSchema': 'Subcategorías',
		'lblconsumerproductTableSchema': 'Productos por Cliente',
		'lblcustomerbannerTableSchema': 'Pancarta Cliente',
		'msgEndpointNotConfig': '[Endpoint API] no configurado',
		'msgCustomerIdNotFound': 'El cliente no existe en el ERP, valide con su administrador',
		'lblAssignStatusConsumer': 'Asignar estatus Consumidor',
		'msgSetErpCodeBeforeChangeStatus': 'Indica el código del ERP antes de cambiar el Estatus',
		'lblLogin': 'Iniciar Sesión',
		'msgEmailIsaConsumer': 'Este correo está registrado como un Consumidor por favor use otro correo',
		'msgWelcometoConsumer': 'Bienvenido a XSales Consumer',
		'msgErrorValidatingUserPass': 'Error validando Usuario/Contraseña',
		'msgNotUseAdmin': 'Si no es Administrador, no use esta pagina de ingreso',
		'lblForgot': 'Olvido su Contraseña',
		'msgEmailSendAgain': 'El Correo ha sido reenviado',
		'msgPleaseAgain': 'Por favor, intente de nuevo',
		'msgPassNotBeEquals': 'Contraseña y Confirmar Contraseña deben ser iguales y el correo no debe estar vacío',
		'lblRegister': 'Registro',
		'msgRegisterOk': 'Ha sido registrado satisfactoriamente',
		'msgErrorSavingData': 'Ha ocurrido un error salvando los datos, favor intente de nuevo',
		'msgUserNotConfirmetYet': 'Usuario no confirmado aún',
		'msgResendingCode': 'Reenviando el Código',
		'msgLoginFail': 'Ha fallado el proceso de autenticación, inténtalo de nuevo',
		'msdCodeVerified': 'Código Verificado',
		'msgUserRegisteredAsConsumer': 'Usuario registrado como Consumidor',
		'msgLoginUserNotExist': 'Usuario No Existe, favor valide su correo',
		'msgUserDisabled': 'Tu Usuario esta Deshabilitado, por favor contacta a soporte',
		'msgUserPassIncorrect': 'Usuario o Contraseña incorrecto',
		'lblActivateSupplier': 'Activar Proveedor',
		'msgEmailAlreadyExist': 'Correo ya existe en XSales Consumer',
		'msgErrorValidatingCode': 'Error verificando código',
		'msgErrorOcurred': 'Ha ocurrido un error, por favor inténtelo de nuevo',
		'msgPassChanged': 'La Contraseña ha sido cambiada satisfactoriamente',
		'msgCodeExpired': 'El código ha expirado, favor inténtelo de nuevo',
		'msgCodeIsNotValid': 'El Código no es válido',
		'msgFillErpCode': 'Ingrese el código del cliente en el ERP',
		'msgValidateErpCode': 'Debe validar el código del cliente en el ERP',
		'lblCurrencySymbol': 'Simbolo de Moneda',
		'msgCurrencyInherit': 'Heredar del Proveedor',
		'msgChangeCatalogExclusive': 'El tipo de Catálogo se cambiará a Exclusivo, ¿Desea Continuar ?',
		'msgCatalogExclusive':'Si el cliente maneja un simbolo de moneda diferente al Proveedor, es apropiado manejar un catálogo de productos exclusivos en la moneda del cliente.',
		'lblcancelText':'No',
		'lblConfirmText':'Si',
		'lblConfirmTittle':'Confirmar',
		'lblStandAlone':'Autónomo',
		'lblModeStandAlone':'Modo Standalone',
		'lblEnableStandAlone':'Modo Autónomo',
		'msgEnableStandAlone':'Usa el API interno',
		'lblLanguages': 'Idiomas adicionales',
		'msgLanguages': 'Puede configurar hasta 3 idiomas adicionales',
		'lblcurrencyisoInternal': 'Moneda Interna',
		'lblconfigcurrencyInternal':'Configuración Moneda Interna',
		'lbllanguage1': 'Idioma 1',
		'lbllanguage2': 'Idioma 2',
		'lbllanguage3': 'Idioma 3',
		'lbllanguage4': 'Idioma 4',
		'msgInternalCurrency':'Esta parámetro se utiliza para calcular el estado de cuenta de uso interno',
		'lblProducts': 'Productos',
		'lblProduct': 'Producto',
		'lblCategory': 'Categoria',
		'lblSubCategory': 'SubCategoria',
		'lblNewSubCategory': 'Añadir Categoría',
		'lblmsginactivetext': 'Este parámetro permite inactivar el registro para su visualización en la app móvil',
		'lblmsgrecordexists': 'El registro ya existe',
		'lbllnamelanguage1': 'Nombre',
		'lbllnamelanguage2': 'Nombre 2',
		'lbllnamelanguage3': 'Nombre 3',
		'lbllnamelanguage4': 'Nombre 4',
		'lblconfirmation': 'Confirmación',
		'lblmsgconfirminactive': '¿Está seguro que desea inactivar?',
		'lblmsgconfirmactive': '¿Está seguro que desea activar?',
		'lbldropzone':'Haga click o Arrastre',
		'lblinactive': 'Inactivo',
		'lbledit': 'Editar',
		'lblcreate': 'Crear',
		'lblsuccess':'Guardado con éxito',
		'lblprocess':'Procesando...',
		'lblprocesserror': 'Error procesando, por favor consulte al administrador',
		'lblinactiveParent': 'Padre Inactivo',
		'lbltaxFree': 'Libre de Impuesto',
		'lblUpdateforLot': 'Actualizar por Lote',
		'lblBatchProcess': 'Proceso por Lote',
		'lblUnlockedForSale': 'Desbloqueado para la venta',
		'lblpercentageTax': 'Porcentaje Impuesto',
		'lblConfigurePercentage': 'Configuración de Porcentajes',
		'lblConfigGroup': "Configuración de Grupos",
		'lblActiveParent': 'Padre Activo',
		'lblPaymentMethodsConfig': 'Configuracion Métodos de pago',
		'lblactiveSubCategory' : 'Subcategoria Activa',
		'lblactiveCategory' : 'Categoría Activa',
		'lblavailableForSale' : 'Habilitado para Venta',
		'lblActiveOrderManagement': 'Habilitar administración de Pedidos',
		'lblActiveBanners': 'Habilitar Banner promocionales',
		'lblPrices':'Precios',
		'lblListPrices': 'Lista de Precios',
		'lblpriceSpecial': 'Precio Especial',
		'lblChecked': 'MARCADOS',
		'lblUnChecked': 'DESMARCADOS',
		'lblActiveListPrice': 'Habilitar Lista de Precios',
		'lblActiveAdminProducts': 'Habilitar Administración de Productos',
		'lblSync': 'Sincronizar',
		'lblUpload': 'Importar',
		'lblPostOrder': 'Probar Pedido',
		'lblPostQuote': 'Calcular Pedido',
		'lblOnlyRead': 'Sólo lectura',
		'lblMsgOnlyRead': 'Información proveniente de la Integración, aquí se muestra sólo para referencia',
		'lblbarcode': 'Código de Barra',
		'lblsameOrderClass': 'Mismo Clase de Pedido',
		'lblEnableOrderManagement':'Administración de Pedidos',

		'lblEnablePromotions':'Promociones',
		'lblEnableAdminProducts':'Administración Productos',
		'lblEnableListPrice':'Lista de Precios',
		'lblEnableMinimunStock': 'Mínimo Inventario',
		'lblEnableBarcodeScanner': 'Escaneo Código Barra',
		'lblEnableCustomerCatalog': 'Catálago de Productos',
		'lblEnableProductDiscount': 'Porcentaje de Descuentos',
		'lblEnableSameOrderClass': 'SOC de Productos',
		'lblEnableShippingAddress': 'Direcciones de Pagos',
		'lblEnablePaymentMethod': 'Método de Pagos',
		
		'lblhelpmodestandalone': 'Modalidad offline, sin conexión a un API',
		'lblhelpordermanagement': 'Visualizar las órdenes y programar su entrega',
		'lblhelppromotions': 'Configuración de promociones',
		'lbladminproducts': 'Configuración y administración de productos',
		'lblhelplistprices': 'Configuración de listas de precios',
		'lblhelpminimunstock': 'Configuración de Stock mínimo en sus productos',
		'lblhelpbarCodescanner': 'Escaneo de código de barras en sus productos',
		'Customercatalog': 'Configuración de catálogo de productos',
		'Percentajediscounts': 'Configuración de porcentaje de descuento',
		'lblhelpsocproducts': 'ordenamiento de productos según la misma clase',
		'lblhelpshippingaddress': 'Visualizar direcciones de entrega',
		'lblhelpPaymentMethod': 'Configuración de métodos de pagos',
		'lblrequiredImage': 'Imagen es Requerida',
	}
}