import AWS from "aws-sdk"
// import credentials from "./credentials-aws-ses.json"
//TODO: eliminar archivo de credenciales y subir y probar sin colocar config alguna
//const ses = new AWS.SES()
//Se coloco en virginia porque en ohio no estaba disponible al dia de hoy y Eduardo indico que se creara en virginia
//ses.config.region = "us-east-1"
AWS.config.update({ region: "us-east-1" })

class Destination {
    /**
     *
     * @param String destination email
     * @param Object data with the specific property for template AWS ses email
     */
    constructor(destination, data) {
        let dest = []
        dest.push(destination)
        this.Destination = {
            ToAddresses: dest,
        }
        this.ReplacementTemplateData = JSON.stringify(data)
    }
}

class params {
    /**
     *
     * @param String with the email
     * @param List of string with the email addresses
     * @param String with the template name
     * @param Object with all properties that template use
     */
    constructor(source, destination, template, defaultData) {
        this.Source = source
        this.Destinations = destination
        this.Template = template
        this.DefaultTemplateData = JSON.stringify(defaultData)
    }
}

class SendMailDispatcher {
    constructor(parameters) {
        this.param = parameters
    }

    sendMail = (callback) => {
        //var sendPromise = new AWS.SES({ apiVersion: "2010-12-01" }).sendEmail(params).promise()

        new AWS.SES({ apiVersion: "2010-12-01" }).sendBulkTemplatedEmail(this.param, (err, data) => {
            if (err) {
                console.error("Failed to send the email")
                callback(err, data)
            } else {
                console.info("Sucessfully sent the email")
                callback(null, data)
            }
        })
    }
}

class XDataEmail {
    Destination = Destination
    param = params
    SendMailDispatcher = SendMailDispatcher
}

export let XSalesEmail = new XDataEmail()
