import React, { useEffect, useState } from "react"
import {
    TabulatorTable,
    LoaderWindow,
    Title,
    Modal,
    Confirm,
    Toastify,
    InputText,
    ImageMultiDropzone,
    SeparateTop,
    Switch,
    DatePicker,
    AlertMessage
} from "xsales-components"
import { I18n } from "@aws-amplify/core"
import { v4 as uuidv4 } from "uuid"
import { useForm, Controller } from "react-hook-form"
import { ErrorMessage } from '@hookform/error-message';
import { ImageUrlTypes, XSalesData } from '../../DataQuery/XSalesData'
import { XSalesConsumer } from "../../Utils/Utils"

let supplierg = {}
export function AdminBanners(props)
{

    const [loader, setLoader] = useState(true)
    const [dataModal, setDataModal] = useState({
        title: "",
        mode: "CREATE",
    })
    const [showAlertMessage, setShowAlertMessage] = useState({
        show: false,
        lblMessage: "",
        type: "danger",
    })

    const { handleSubmit, clearErrors , formState: { errors },control,  setValue  } = useForm({
        shouldFocusError: true,
        defaultValues:{
            id: "",
            description: "",
            imageInternalName: [],
            imageExternalURL: [],
            since: "",
            until: "",
            active: true,
            imageFiles:[]
        }
    });

//const [imageInternalName, setImageInternalName] = useState([])
const [supplier, setSupplier] = useState(null)
const [imageRequired, setImageRequired] = useState(false)
const getErrorMessage =  (name) => <ErrorMessage errors={errors} name={name} /> 
const [tabulatorProps, setTabulatorProps] = useState({
    filter: [],
    data: [],
    languages: [],
    columns: [],
})

async function getColumsTabulator(){
    let columns=[
        {
            title: I18n.get("lblid"),
            field: "id",
            widthGrow: 0.1,
            visible: false,
        },
        {
            title: I18n.get("lbldescription"),
            field: "description",
            widthGrow: 0.5,
        },
        /* {
            title: I18n.get("lblimageInternalName"),
            field: "imageInternalName",
            visible: false,
            widthGrow: 1,
        },
        {
            title: I18n.get("lblimageExternalURL"),
            field: "imageExternalURL",
            visible: false,
            widthGrow: 1,
        }, */
        {
            title: I18n.get("lblsince"),
            field: "since",
            widthGrow: 0.3,
            formatter:  (cell, formatterParams, onRendered) => XSalesConsumer.formatShortDatetime(cell.getValue())
        },
        {
            title: I18n.get("lbluntil"),
            field: "until",
            widthGrow: 0.3,
            formatter:  (cell, formatterParams, onRendered) => XSalesConsumer.formatShortDatetime(cell.getValue())
        },
        {
            title: I18n.get("lblactive"),
            field: "active",
            widthGrow: 0.15,
            cellClick: async (e, cell) => {
                
                let tabulatorData = cell.getTable().getData()

                e.preventDefault()
                e.stopPropagation()
                const data = cell.getData()
                cell.setValue(!data.active)

                const message = data.active
                    ? I18n.get("lblmsgconfirmactive")
                    : I18n.get("lblmsgconfirminactive")

                const confirm = await Confirm({
                    title: I18n.get("lblconfirmation"),
                    message: message,
                })

                if (!confirm) {
                    cell.setValue(!data.active)

                    return
                }
               
                UpdateBanner("UPDATE",data,tabulatorData)
            },
            formatter: (cell, formatterParams, onRenderedm) => {
                const id = uuidv4()
                const { active } = cell.getData()
                return `<div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" ${
                    active && "checked"
                } id="${id}" />
                <label className="custom-control-label"  for='${id}' ></label>
              </div>`
            },
        },
    ]
    setTabulatorProps(prevState => ({
        ...prevState,
        columns: columns
    }))
}

const [alertMessageImage, setAlertMessageImage ] = useState({
    title: "",
    message: "",
    show: false,
    type: "warning" 
 })

const handleClickRow = (e, row) => {
    setImageRequired(true)
    const promotion = row.getData()
  
    setDataModal((prevState) => ({
        ...prevState,
        title: I18n.get("lbledit"),
        mode: "UPDATE",
    }))
    setShowAlertMessage((prevState) => ({
        ...prevState,
        show: false,
        lblMessage: "",
        type: "danger",
    }))
    // setOpenModal(true)


    setValue("id",promotion.id);
    setValue("description",promotion.description); 
    setValue("imageInternalName",promotion.imageInternalName);
    setValue("imageExternalURL",promotion.imageExternalURL);   
    setValue("since",promotion.since);   
    setValue("until",promotion.until);   
    setValue("active",promotion.active);  

    setValue("imageFiles", XSalesConsumer.getImageFiles(supplier.id,promotion.imageInternalName, promotion.imageExternalURL,ImageUrlTypes.Types.banners))
    window.$(`#modalPromotion`).modal("toggle")
}


function handleClickCreate(e) {
    clearForms()
    setDataModal((prevState) => ({
        ...prevState,
        title: I18n.get("lblcreate"),
        mode: "CREATE",
    }))
    setShowAlertMessage((prevState) => ({
        ...prevState,
        show: false,
        lblMessage: "",
        type: "danger",
    }))
  
    window.$(`#modalPromotion`).modal("toggle")
    }

useEffect(() => {
    document.title = "Promotion:List"
    getLanguage()
    //getColumsTabulator()
    getData()
}, [])

useEffect(() => {
    getColumsTabulator()
}, []) // eslint-disable-line react-hooks/exhaustive-deps

async function getLanguage() {
    try {
        let lang = localStorage.getItem("languageApp");
        if (!lang) {
          const [lang1] = navigator.language.split('-');
          lang = lang1.toUpperCase();
        }
        I18n.setLanguage(lang.toUpperCase());
      } catch (error) {
        I18n.setLanguage('EN');
        console.log(error.message);
      }
}


function clearForms() {
    setValue("id","");
    setValue("description",""); 
    setValue("imageInternalName",[]);
    setValue("imageExternalURL",[]);   
    setValue("since",new Date());   
    setValue("until",new Date());   
    setValue("active",true);   
    setValue("imageFiles",[]);

    clearErrors("id")
    setImageRequired(false)
    //([])
}

async  function  getData() {
    try {
        
        const { supplier } =  XSalesConsumer.getCurrentUserNew()
        setSupplier(supplier)
        supplierg = supplier
        
        const data = await XSalesData.Suppliers.GetSupplier(supplier.id)
        if(data.promotions)
        {
                let Promotions =   data.promotions.map(data => ({
                    id: data.id,
                    description: data.description,
                    imageInternalName: data.imageInternalName,
                    imageExternalURL: data.imageExternalURL,
                    since: data.since,
                    until: data.until,
                    active: data.active,
                    //imageFiles: XSalesConsumer.getImageFiles(props.supplierId,data.imageInternalName, data.imageExternalURL,ImageUrlTypes.Types.banners),
                  
                }))

                 setTabulatorProps(prevState => ({
                    ...prevState,
                    data: Promotions,
                })) 
            
        }

        setLoader(false)
    } catch (error) {
        console.error(error)
        setLoader(false)
    }
}

const handleSave = () => {
    
}


const UpdateBanner = async (mode, values, data) =>
{
    let newPromotions = data
    if(mode === "CREATE")
    {
        values.id = uuidv4();
        newPromotions.find(element => element.id === values.id );
        
    }
    else
    {
        newPromotions = newPromotions.filter(item => item.id !== values.id)
    }

    newPromotions.push({ 
        id : values.id,
        description: values.description,
        imageInternalName: values.imageInternalName,
        imageExternalURL: values.imageExternalURL,
        since: values.since,
        until: values.until,
        active: values.active,
    })

    await SavePromotions(newPromotions)
    getData()
        
}


const Submit = (values) => {
   if(values.imageInternalName.length > 0)
   {
    UpdateBanner(dataModal.mode,values, tabulatorProps.data)
    window.$(`#modalPromotion`).modal('hide')
   }
   else
   {
    setImageRequired(true)
   }
}


async function handleOnChangeImage(files) {
     if (Array.isArray(files)) {
        setImageRequired(false)
        const images = files.map((x) => x.name.toLowerCase())
        //setImageInternalName(images)
        setValue("imageInternalName",images);

    } 
}


function createTooltips() {
    window.$('[data-toggle="tooltip"]').tooltip({
        animated: "fade",
        placement: "top",
        html: true,
    })
}

useEffect(() => {
    createTooltips()
    const buttons = document.querySelectorAll("button.tabulator-page")
    buttons.forEach((btn) => {
        btn.addEventListener("click", () => {
            createTooltips()
        })
    })
}, [loader])

useEffect(() => {
    createTooltips()
})

async function handleUploadImage(file, replace, copy, useExisting) {
     const newFile = { ...file }
    setAlertMessageImage((prevState) => ({ ...prevState, show: false }))
    const [result] = await XSalesConsumer.uploadFile(
        file,
        supplier.id,
        ImageUrlTypes.Types.banners,
        replace,
        copy
    )
    
    let isUpload = false
    let isExists = false

    const messageCodes = {
        API001: "API001", // exists
        API002: "API002", // content Inappropriate
        API003: "API003", //  dimensions warn
    }

    if (result) {
        const exists = result.messages.findIndex(
            (x) => x.msgCode === messageCodes.API001
        )
        if (exists !== -1) {
            isExists = true
        }

        const contentInappropriate = result.messages.findIndex(
            (x) => x.msgCode === messageCodes.API002
        )
        if (contentInappropriate !== -1) {
            setAlertMessageImage((prevState) => ({
                ...prevState,
                show: true,
                message: "Contenido Inapropiado",
                type: "danger",
            }))
        }
        isUpload = result.isUploaded
        if (isUpload) newFile.name = result.imageName
    }

    return { isUpload, isExists, imageName: newFile.name } 
}


const SavePromotions = async (promotions) => {
    await XSalesData.Suppliers.SetSupplierPromotions(supplierg.id, promotions)
        .then((data) => {
            //console.log(data)
        })
        .catch((err) => {
            console.log(err)
        })
}


    return (
        <div>
        {loader ? (
            <LoaderWindow />
        ) : (
            <>
    	
              
                   
               <Title label={I18n.get("lblpromotionalbanners")} />
               <TabulatorTable
                        id="Banners"
                        Title={""}
                        showCreateButton
                        onClickButtonCreate={handleClickCreate}
                        Data={tabulatorProps.data}
                        Filter={tabulatorProps.filter}
                        Columns={tabulatorProps.columns}
                        paginationSize={10}
                        placeholder={I18n.get("lblLoadingData")}
                        Icons={false}
                        Search
                        labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                        columnsIcon={true}
                        languages={tabulatorProps.languages}
                        RowClick={handleClickRow}
                        lblCreateButton= {I18n.get("lblcreate")}
                        lblSearch= {I18n.get("lblSearch")}
                        // Height="70vh"
                    />
         
                
                <Modal
                        id="modalPromotion"
                        title={dataModal.title}
                        lblbtnSave={I18n.get("lblSave")}
                        lblbtnCancel={I18n.get("lblCancel")}
                        handleSave={handleSave}
                        handleCancel={(e) => false}
                        //open={openModal}
                        size="lg"
                        submitButton={true}
                        formId={"form-Promotions"}
                    >
                        
                        <div className="col-sm-12">
        <SeparateTop>
            <form onSubmit={handleSubmit(Submit)} id='form-Promotions'>
               <div className='row'>
                <div className='col-sm-12 col-md-5 offset-md-1'>
                   <div className="row">
                        <div className="col-sm-12 col-md-12">   
                        <Controller
                                control={control}
                                name="description"
                                rules={{maxLength: { value: 45, message: I18n.get('lblmaxLengthField').replace('{length}','45') }}}
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref, onFocus },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>
                                        <InputText
                                            label={I18n.get('lbldescription')}
                                            value={value}
                                            setRef={ref}
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onFocus={onFocus}
                                            messageError={getErrorMessage('description')}
                                            isEditable={true}/> 
                                    </>        
                               
                                  )}
                            /> 
                        </div>
                    </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6">   
                        <Controller
                                control={control}
                                name="since"
                                rules={{ maxLength:{ value: 45, message: I18n.get('lblmaxLengthField').replace('{length}','45') } }}
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref, onFocus },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>

                                        <DatePicker
                                         label=  { I18n.get("lblsince")}
                                         id= "since"
                                         name=  "since"
                                         value=  {value}
                                         onChange={(e) =>{
                                           
                                            onChange(e._d)
                                        }}
                                         isEditable= {true}
                                         className= ""
                                        
                                        />
                                      
                                    </>        
                               
                                  )}
                            /> 
                        </div>
                        <div className="col-sm-12 col-md-6">   
                        <Controller
                                control={control}
                                name="until"
                                rules={{ maxLength:{ value: 45, message: I18n.get('lblmaxLengthField').replace('{length}','45') } }}
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref, onFocus },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>
                                       <DatePicker
                                         label=  { I18n.get("lbluntil")}
                                         id= "until"
                                         name=  "until"
                                         value=  {value}
                                         onChange={(e) =>{
                                           
                                            onChange(e._d)
                                        }}
                                         isEditable= {true}
                                         className= ""
                                        
                                        />
                                    </>        
                               
                                  )}
                            /> 
                        </div>
                </div>
                <div className='row'>
                        <div className="col-sm-12 col-md-4">   
                        <Controller
                                control={control}
                                name="active"
                                rules={{   }}
                               
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>
                                        <Switch    
                                            id="active"             
                                            setRef={ref}
                                            name={name}
                                            label={I18n.get("lblactive")}
                                            onChange={(e) =>{
                                               onChange(e.target.checked)
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            isEditable={true}
                                        />
                                              
                                    </>        
                               
                                  )}
                            /> 
                         
                           
                        </div>
                    </div>
                   

                </div>
                <div className='col-sm-12 col-md-4 col-md-pull-1'>
                    <div className="row">
                        <div className="col-sm-12">   
                        <Controller
                                control={control}
                                name="imageFiles"
                                rules={{   }}
                               
                                render={(
                                    {  field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState
                                    }
                                ) => (
                                    <>
                                       <ImageMultiDropzone 
                                            files={value}
                                            isEditable={true}
                                            handleOnChange={handleOnChangeImage}
                                            handleUpload={handleUploadImage}
                                            width="328px"
                                            height="122px"
                                            lblTitleModal="Upload Images"
                                            alertMessage={alertMessageImage} />  
                                              
                                    </>        
                               
                                  )}
                            /> 
                        
                         </div>
                         {
                            imageRequired && (
                            <>
                                <div className="col-sm-12 required"> *{I18n.get("lblrequiredImage")}</div>
                            </>
                            )
                         }
                    </div>
                       
                    </div>
               </div> 
             
            </form>  
        </SeparateTop>
        </div>
        </Modal>
        <div className="col-sm-12">
                            <AlertMessage
                                message={I18n.get(showAlertMessage.lblMessage)}
                                show={showAlertMessage.show}
                                type={showAlertMessage.type}
                            />
                        </div>
        <Toastify />       
     {/*  <div><pre>{JSON.stringify(tabulatorProps.data, null, 2) }</pre></div> */}
        
                </>
            )}
        </div>
    )
}