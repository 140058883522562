import React,{useState,useEffect} from 'react';
import {TabulatorTable, Toastify, LoaderWindow,Title,SeparateTop,useToast,Filters } from 'xsales-components'
import { I18n } from "@aws-amplify/core"
import { XSalesData } from "../../DataQuery/XSalesData"
import { XSalesConsumer } from '../../Utils/Utils'
import { v4 as uuidv4 } from 'uuid';

let dataProductsConsumers = []
let dataProducts = []
let supplierg = {}
let consumerId = ""
export function ListPrice (props)
{

    const { notify } = useToast()
    const [loader, setLoader] = useState(false)
    //const [enabledCheckAll, setEnabledCheckAll] = useState(false) 

    const editCheck =  (cell)=>{
        const item = cell.getRow().getData();
        const exist = dataProductsConsumers.findIndex(x => x.productId === item.productId) 
        return exist !== -1 ? true : false; 
    }

    const dateEditor = (cell, onRendered, success, cancel, editorParams)=>{
        
         
        let cellValue = cell.getValue(),
        datacell = cell.getData(),
        input = document.createElement("input");
    
        input.setAttribute("type", "number");
        input.style.width = "100%";
        input.value = typeof cellValue !== "undefined" ? cellValue : "";
    
        onRendered(()=>{
            input.style.height = "100%";
            input.focus();
        });
    
        async function UpdateCellValue(e)
        {
            if(((cellValue === null || typeof cellValue === "undefined") && input.value !== "") || input.value !== cellValue){
                success(input.value);
            }else{
                cancel();
            }
        }
        function onChange(e){
            UpdateCellValue(e)
        }
        
        async  function onblur(e){
            await UpdateCellValue(e)
            if (datacell.priceSpecial !== "" ) 
            {
                 await XSalesData.Product.updatePriceSupplierConsumer(supplierg.id,consumerId,datacell )
            }
            else
            {
                notify("error",`Precio no puede estar en blanco`)
            }
        
        } 
         
        input.addEventListener("change", onChange);
        input.addEventListener("blur", onblur);
        input.addEventListener("keydown", (e)=>{
            switch(e.key){
                case 13:
                    success(input.value);
                break;
    
                case 27:
                    cancel();
                break;
                default:;
            }
        });
    
        return input;
    }

 
    const handleClickFilters = (e)=>{
        
        const { id } = e.target;
        const objData = tabulatorProps
        let arrayProduct = []
        
        
        switch (id) {
            case "ALL":
                objData.filter = []
                break;
            case "CHECKED":
                
                dataProductsConsumers.map((item) => arrayProduct.push(item.productId))
                objData.filter = [{ field: "productId", type: "in", value: [...arrayProduct] }]
               
                break;
            case "UNCHECKED":
                    let arrayProductUnchecked = []
                    arrayProduct = dataProducts
                    dataProductsConsumers.map((item) => (arrayProduct = arrayProduct.filter(prd => prd.productId !== item.productId)))

                    arrayProduct.map((item) => arrayProductUnchecked.push(item.productId))
                    objData.filter = [{ field: "productId", type: "in", value: [...arrayProductUnchecked] }]
                break;
           
            default:
                
                break;
        }

        setTabulatorProps(prevState => ({
            ...prevState,
            filter: [...objData.filter]
        }))   

    } 
        
    const [tabulatorProps, setTabulatorProps] = useState({
        filter: [],
        data: [],
        languages: [],
        columns: [
            {
                title: ``, /*`<input type='checkbox' id='select_all-check'  ${enabledCheckAll  && "checked"} />`, */
                type: "check",
                widthGrow: 0.1,
                formatter: (cell, formatterPatterns,onRenderedm) => {
                    const id = uuidv4();
                    const data = cell.getData()
                    const checked = dataProductsConsumers.findIndex(x => x.productId === data.productId) 
                    
                    return `<input type="checkbox" id="${id}" name="chkProduct" productId="${data.productId}" unit="${data.unit}" ${checked !== -1 && "checked" } />`
                },
                cellClick: (e,cell) => 
                {
                    e.stopPropagation();
                     handleClickCheckbox(e,cell) 
                }
            },
            {
                title: I18n.get("lblproductId"),
                field: "productId",
                widthGrow: 0.2,
            },
            {
                title: I18n.get("lbllnamelanguage1"),
                field: "nameLanguage1",
                widthGrow: 1,
            },
            {
                title: I18n.get("lblunit"),
                field: "unit",
                widthGrow: 0.2,
            },
            
            {
                title: I18n.get("lblprice"),
                field: "price",
                widthGrow: 0.5,
            },
            {
                title: I18n.get("lblpriceSpecial"),
                field: "priceSpecial",
                widthGrow: 0.5,
                editor:dateEditor,
                editable:editCheck

            }            
        ],
        dataFilters: [
            {
                label: I18n.get("lblALL"),
                handleClick: handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "ALL",
                name: "ALL",
                type: "button",
                active: true
            },
            {
                label: I18n.get("lblChecked"),
                handleClick: handleClickFilters,
                className: "btn btn-outline-primary bounds-button",
                id: "CHECKED",
                name: "CHECKED",
                type: "button",
                active: false
            },
            {
                label: I18n.get("lblUnChecked"),
                handleClick: handleClickFilters,
                className: "btn btn-outline-primary bounds-button",
                id: "UNCHECKED",
                name: "UNCHECKED",
                type: "button",
                active: false
            }]
    })

    useEffect(() => {
        document.title = "Prices:List"
        //getLanugage()
        getData()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    async function getData() {
        try {
            const { supplier } = await XSalesConsumer.getCurrentUserNew()
            consumerId = props.consumerId
            supplierg = supplier
            
            if (supplierg)
            {
           await XSalesData.Product.ListProductsbySuppliers(supplierg.id).then(async (data) =>{
                    data.map((prd)=> (  
                        dataProducts.push({
                                        productId: prd.productId,
                                        supplierId: prd.supplierId,
                                        unit: prd.unit,
                                        nameLanguage1: prd.nameLanguage1,
                                        price: prd.price,
                                        priceSpecial: ""
                                     })
                    ))
                    
                   await XSalesData.Product.ListSupplierConsumerProductsByConsumer(
                        supplierg.id, 
                        consumerId).then((items) => {
                            
                            let objIndex = 0
                            items.map((prodcons)=> { 
                            if (!prodcons._deleted)
                            {    
                            dataProductsConsumers.push({
                                                productId: prodcons.productId,
                                                supplierId: prodcons.supplierId,
                                                unit: prodcons.unit,
                                                nameLanguage1: prodcons.nameLanguage1,
                                                price: prodcons.price,
                                                priceSpecial: ""
                                             })
                                             
                                         objIndex = dataProducts.findIndex((obj => obj.productId === prodcons.productId && obj.unit ===prodcons.unit ));
                                         dataProducts[objIndex].priceSpecial = prodcons.price
                                }     
                            return null            
                            })
                        })
                        .catch((err) => {
                            console.log(err)
                            setLoader(false)
                        })


                       
                        setTabulatorProps(prevState => ({
                            ...prevState,
                            data: dataProducts
                        }))
    
                        setLoader(false)


                }).catch((err) => {
                    console.log("Admin ListPrices error ", err)
                    this.setState({ loadWindow: false })
                })
            }
        } catch (error) {
            console.error(error)
            setLoader(false)
        }
    }

   /*  function getLanugage(){
        const languages = XSalesConsumer.getLanguagesSupplierPreferences()
        setTabulatorProps(prevState => ({
            ...prevState,
            languages: languages.split(',')
        }))
    } */

    const handleClickCheckbox = (e, cell) => {
        
        const element = e.target      
        if (element.tagName !== "INPUT") return
        
        let data = cell.getData()
        if(!element.checked)
        {
            let arrayProduct = []
            arrayProduct = dataProductsConsumers.filter(prd => prd.productId !== data.productId)
            dataProductsConsumers = arrayProduct
            data.priceSpecial = "" //reset value 
            handleDeletePriceConsumer(data)
        }
        else
        {
            dataProductsConsumers.push(data)
        }
        
       
        const ArrayCheck = document.querySelectorAll("input[name='chkProduct']")
        const CheckAllActive =  dataProductsConsumers.length === ArrayCheck.length 
        //setEnabledCheckAll(CheckAllActive)
        RefreshCheckall(CheckAllActive) 


    }

    const handleDeletePriceConsumer = async  (datacell) => await XSalesData.Product.deletePriceSupplierConsumer(supplierg.id,consumerId,datacell )
    

    const RefreshCheckall =(CheckAllActive) =>
    {

        //tabulatorProps.columns[0].title = `<input type='checkbox' id='select_all-check' ${CheckAllActive  && "checked"} />` 
        setTabulatorProps(prevState => ({
            ...prevState,
            columns: [...tabulatorProps.columns]
        }))   

    } 

    

    return (
        <div>
            {loader ? (
                <LoaderWindow />
            ) : (
                <>
                <SeparateTop>
                    <Title label={I18n.get("lblListPrices")} />
                    <Filters filterName="filterPrices" data={tabulatorProps.dataFilters} />
                    <TabulatorTable
                        id="listPrices"
                        Title={""}
                        Data={tabulatorProps.data}
                        Columns={tabulatorProps.columns}
                        Filter={tabulatorProps.filter}
                        placeholder={I18n.get("lblLoadingData")}
                        Icons={false}
                        Search
                        labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                        languages={tabulatorProps.languages}
                        Height="50vh"
                    />
                    </SeparateTop>
                    <Toastify />
                    {/*  <div><pre>{JSON.stringify(dataProductsConsumers, null, 2) }</pre></div>  */}
                </>
            )}
        </div>
    )
 
} 