import { I18n } from "aws-amplify"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
// import { CheckBox } from 'xsales-components'
import { OrderStatus } from "../../../models"
import { XSalesConsumer } from "../../../Utils/Utils"
import "../../../assets/css/reports.css"

export function TableSummary({ data = [], groupType, handleClickDelivered, handleClickRow }) {
    const [tableData, setTableData] = useState([])

    const groupBy = (key) => (array) =>
        array.reduce(
            (objectsByKeyValue, obj) => ({
                ...objectsByKeyValue,
                [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
            }),
            {}
        )

    useEffect(() => {
        const groupByType = groupBy(groupType)
        const modifyData = groupByData(groupByType(data))
        setTableData(modifyData)
        //console.log(modifyData)
    }, [data, groupType]) // eslint-disable-line react-hooks/exhaustive-deps

    function groupByData(data) {
        const entity = {
            id: "",
            group1: "",
            group2: "",
            group3: "",
            customer: "",
            order: "",
            createOn: "",
            itemQty: "",
            netAmount: "",
            currencyISO: "",
            delivered: false,
            groupBy: true,
            totalLine: true,
        }

        const dataNew = []
        let currency = {}
        Object.keys(data)
            .sort(sortAsc)
            .forEach((key) => {
                data[key].forEach((row) => {
                    dataNew.push({
                        id: row.id,
                        group1: row.group1,
                        group2: row.group2,
                        group3: row.group3,
                        customer: row.customer,
                        order: row.order,
                        createOn: row.createOn,
                        itemQty: row.itemQty,
                        netAmount: XSalesConsumer.formattDecimal(row.netAmount),
                        currencyISO: row.currencyISOErp,
                        delivered: row.status === OrderStatus.DELIVERED ? true : false,
                        groupBy: false,
                    })
                    if (!currency[row.currencyISOErp]) {
                        currency[row.currencyISOErp] = row.netAmount
                    } else {
                        currency[row.currencyISOErp] += row.netAmount
                    }
                })
                Object.keys(currency).forEach((keyCurrency, index) => {
                    dataNew.push({
                        ...entity,
                        netAmount: currency[keyCurrency], //data[key].reduce((a, b) => a + b.netAmount, 0),
                        currencyISO: keyCurrency,
                        groupBy: index === 0,
                        order: index === 0 ? data[key].length : "",
                    })
                })
                currency = {}
                dataNew.push({
                    ...entity,
                    groupBy: false,
                })
            })
        return dataNew
    }

    function sortAsc(a, b) {
        if (a < b) return -1
        if (a > b) return 1
        return 0
    }

    function TotalRowDecimal(label, value) {
        return (
            <RowTotalDecimal>
                {label && <TextWord>{label}</TextWord>}
                {XSalesConsumer.formattDecimal(value)}
            </RowTotalDecimal>
        )
    }

    function TotalRow(label, value) {
        return (
            <RowTotal>
                {label && <TextWord>{label}</TextWord>}
                {value}
            </RowTotal>
        )
    }

    return (
        <ContainerTable>
            <Table className="table table-borderless table-striped">
                <thead>
                    <tr>
                        <th className="th_table">{I18n.get(`lblOrdMa${groupType}`)}</th>
                        <th className="th_table">{I18n.get(`lblOrdMaCustomer`)}</th>
                        <th className="th_table">{I18n.get(`lblOrdMaOrder`)}</th>
                        <th className="th_table">{I18n.get(`lblOrdMaCreatedOn`)}</th>
                        <th className="th_table">{I18n.get(`lblOrdMaItemsQty`)}</th>
                        <th className="th_table">{I18n.get(`lblOrdMaNetAmount`)}</th>
                        <th className="th_table">{I18n.get(`lblOrdMaCurrencyISO`)}</th>
                        <th className="th_table">{I18n.get(`lblOrdMaDelivered`)}</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((row, index) => (
                        <Row
                            key={index}
                            data-code={row.id}
                            onClick={(e) => {
                                if (e.target?.type === "checkbox") return

                                if (e.currentTarget.dataset.code) {
                                    handleClickRow(e.currentTarget.dataset.code)
                                }
                            }}
                        >
                            <th scope="row">{row[groupType]}</th>
                            <td>{row.customer}</td>
                            {row.groupBy ? TotalRow(I18n.get("lblOrdMaOrders"), row.order) : <td>{row.order}</td>}
                            <td>{row.createOn}</td>
                            <td>{row.itemQty}</td>
                            {row.groupBy ? TotalRowDecimal("", row.netAmount) : <RowDecimal>{row.netAmount}</RowDecimal>}

                            {row.groupBy ? TotalRow("", row.currencyISO) : <td>{row.currencyISO}</td>}
                            <th scope="row">
                                {!row?.totalLine && (
                                    <input
                                        type="checkbox"
                                        id={`check${index}`}
                                        name={`check${index}`}
                                        data-code={row.id}
                                        onClick={handleClickDelivered}
                                        defaultChecked={row.delivered}
                                    />
                                )}
                            </th>
                        </Row>
                    ))}
                </tbody>
            </Table>
        </ContainerTable>
    )
}

const ContainerTable = styled.div`
    width: 100%;
    height: 60vh;
    overflow-y: auto;
`
const Table = styled.table`
    width: 100%;
    border: 1px solid #dddddd;
    border-right-width: 0px;
    border-left-width: 0px;
    && > tbody > tr {
        cursor: pointer;
    }
`
const Row = styled.tr``

const RowTotalDecimal = styled.td`
    border-top: 0.5px solid black !important;
    text-align: right;
`

const RowTotal = styled.td`
    border-top: 0.5px solid black !important;
`

const RowDecimal = styled.td`
    text-align: right;
`

const TextWord = styled.span`
    color: var(--gray-xs-color);
    margin-right: 5px;
`

export default TableSummary
