import React, { useEffect, useState } from "react"
import { TabulatorTable, SelectCombo, VerticalBarChart, LoaderWindow } from "xsales-components"
import { I18n } from "@aws-amplify/core"
import { XSalesData } from "../../../DataQuery/XSalesData"
import moment from "moment"
import { XSalesConsumer } from "../../../Utils/Utils"
import { useHistory } from "react-router-dom"
//import NoDataAvailable from '../StatementComponents/NoDataAvailable'
//import DetailHeader from '../StatementComponents/DetailStatementHeader'

let chartBarRef = null
//let tabulatorRef = null
const enumOptions = {
    transactionsPerMonth: "1",
    estimatedBill: "2",
    averageOrdersAmount: "3",
    averageItemsPerOrder: "4",
}

const objMonths = {}
const { currencySymbol } = {
    //currencyISO: "USD",
    currencySymbol: "$",
}

const ActivityHistorySupplier = (props) => {
    const history = useHistory()
    const [dataLabelsChart, setDataLabelsChart] = useState([])
    const [dataBarChart, setDataBarChart] = useState([])
    const [loader, setLoader] = useState(true)
    const [dataApiHistory, setDataApiHistory] = useState({
        data: [],
        monthsNames: [],
    })
    /* const [supplierCurrency, setSupplierCurrency] = useState({
        currency: currencyISO,
        symbol: currencySymbol,
    }) */
    const [dataHistory, setDataHistory] = useState({ data: [] })
    const [columnsTable, setColumnsTable] = useState([
        {
            title: "",
            formatter: (cell, formatterPatterns) => checkboxIcon(cell, formatterPatterns, dataHistory),
            width: 10,
            id: "history",
            headerSort: false,
            cellClick: handleClickCheckbox,
        },
        {
            title: I18n.get("lblnameColumn"),
            field: "name",
            widthGrow: 1,
        },
        {
            title: "MONTH 1",
            field: "month1",
            widthGrow: 1,
            bottomCalc: "sum",
            align: "right",
            formatter: "money",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cellClick: handleClickCell,
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
        {
            title: "MONTH 2",
            field: "month2",
            widthGrow: 1,
            bottomCalc: "sum",
            formatter: "money",
            align: "right",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cellClick: handleClickCell,
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
        {
            title: "MONTH 3",
            field: "month3",
            widthGrow: 1,
            formatter: "money",
            align: "right",
            bottomCalc: "sum",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cellClick: handleClickCell,
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
        {
            title: "MONTH 4",
            field: "month4",
            widthGrow: 1,
            bottomCalc: "sum",
            align: "right",
            formatter: "money",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cellClick: handleClickCell,
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
        {
            title: "MONTH 5",
            field: "month5",
            widthGrow: 1,
            bottomCalc: "sum",
            align: "right",
            formatter: "money",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cellClick: handleClickCell,
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
        {
            title: "MONTH 6",
            field: "month6",
            widthGrow: 1,
            bottomCalc: "sum",
            formatter: "money",
            align: "right",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            cellClick: handleClickCell,
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
        },
        {
            title: "TOTAL",
            field: "total",
            widthGrow: 1,
            formatter: "money",
            align: "right",
            bottomCalc: "sum",
            bottomCalcParams: { precision: 2 },
            bottomCalcFormatter: "money",
            bottomCalcFormatterParams: {
                symbol: currencySymbol,
                symbolAfter: false,
                precision: 2,
            },
            cssClass: "tabulator__total__statement",
        },
    ])
    const [barColor, setBarColor] = useState([
        { color: "#3766FB", apply: false },
        { color: "#7DD2D6", apply: false },
        { color: "#2B395A", apply: false },
        { color: "#631BA7", apply: false },
        { color: "#9A67CB", apply: false },
        { color: "#FAED89", apply: false },
    ])
    const [selectionValue, setSelectionValue] = useState(enumOptions.transactionsPerMonth)

    const getColorBar = () => {
        const colors = barColor
        let condition = true
        let color = "#631BA7"
        let counter = 0
        while (condition && counter < 100) {
            const random = Math.floor(Math.random() * colors.length)
            if (colors[random].apply === false) {
                colors[random].apply = !colors[random].apply
                color = colors[random].color
                condition = false
            }
            counter++
        }
        setBarColor(colors)
        return color
    }

    const activityComboOptions = [
        {
            label: I18n.get("lbltransactionspermonth"),
            value: enumOptions.transactionsPerMonth,
        },
        {
            label: I18n.get("lblestimatedbilled"),
            value: enumOptions.estimatedBill,
        },
        {
            label: I18n.get("lblavgorderamount"),
            value: enumOptions.averageOrdersAmount,
        },
        {
            label: I18n.get("lblavgitemsperorder"),
            value: enumOptions.averageItemsPerOrder,
        },
    ]

    function handleClickCell(e, cell) {
        const columnTitle = cell.getColumn().getDefinition().title
        const month = objMonths[columnTitle]
        const { year, supplierId } = cell.getData()
        history.push(`DetailStatement1/${month}/${year}/${supplierId}`)
    }

    function checkboxIcon(cell, formatterPatterns, dataHistory) {
        const { id } = cell._cell.column.definition
        const data = cell.getData()
        const index = dataHistory.data.findIndex((x) => x.id === data.id)
        if (index !== -1) {
            if (dataHistory.data[index].check) {
                return `<input type='checkbox' name='checkbox' checked  className='fileCompare_Chkbx checkbox-${id}' style='margin-left:15px;' />`
            }
        }
        return `<input type='checkbox' name='checkbox'  className='fileCompare_Chkbx checkbox-${id}' style='margin-left:15px;' />`
    }

    function handleClickCheckbox(e, cell) {
        const data = cell.getData()
        const element = e.target
        if (element.tagName !== "INPUT") return
        if (!element.checked) {
            // cell.getRow().deselect();
            // cell.getData().IsSelected = false;
            if (chartBarRef) {
                const currentDataset = chartBarRef.data.datasets.filter((x) => x.id === data.id)
                const color = currentDataset[0].backgroundColor
                const barColors = barColor
                const index = barColors.findIndex((x) => x.color === color)
                barColors[index].apply = false
                setBarColor(barColors)
                const newDatasets = chartBarRef.data.datasets.filter((x) => x.id !== data.id)
                setDataBarChart(newDatasets)
                chartBarRef.data.datasets = newDatasets
                chartBarRef.update()
            }
        } else {
            // cell.getRow().select();
            // cell.getData().IsSelected = true;
            if (chartBarRef) {
                if (chartBarRef.data.datasets.length >= 3) {
                    element.checked = !element.checked
                    return
                }
                const newDataset = {
                    id: data.id,
                    supplierId: data.supplierId,
                    year: data.year,
                    label: data.name,
                    data: [data.month1, data.month2, data.month3, data.month4, data.month5, data.month6],
                    backgroundColor: getColorBar(),
                    borderColor: [],
                    borderWidth: 1,
                }
                setDataBarChart([...dataBarChart, newDataset])
                chartBarRef.data.datasets = [...chartBarRef.data.datasets, newDataset]
                chartBarRef.update()
            }
        }
        const objData = dataHistory
        const index = objData.data.findIndex((x) => x.id === data.id)
        if (index !== -1) {
            objData.data[index].check = element.checked
            setDataHistory(objData)
        }
    }

    const getData = async (supplierID) => {
        const { years, periods } = getLastSixPeriods()
        let data = []
        const monthsNames = []
        for (const year of years) {
            const months = []
            for (const period of periods.filter((x) => x.year === year)) {
                months.push(period.month)
                let monthName = XSalesConsumer.getMonth(period.month).toUpperCase()
                objMonths[monthName] = period.month
                monthsNames.push(monthName)
            }
            const minMonth = Math.min(...months)
                .toString()
                .padStart(2, "0")
            //const max = Math.max(...months)
            const maxMonth = Math.max(...months)
                .toString()
                .padStart(2, "0")
            let dataApi = await XSalesData.Statement.getStatementBySupplierPaddedMonth(supplierID, {
                between: [
                    { year: year, paddedMonth: minMonth },
                    { year: year, paddedMonth: maxMonth },
                ],
            })
            dataApi = dataApi.filter((x) => x.contractDetail.fees != null)
            if (dataApi.length > 0) data = [...data, dataApi]
        }

        const dataApiH = dataApiHistory
        dataApiH.data = data
        dataApiH.monthsNames = monthsNames
        setDataApiHistory(dataApiH)
    }

    const sumPeriod = (rpt, ySupplier, dataSupplier, namePeriod) => {
        if (rpt === enumOptions.transactionsPerMonth) {
            dataSupplier[namePeriod] += ySupplier.count.order + ySupplier.count.deposit + ySupplier.count.payment
        } else if (rpt === enumOptions.estimatedBill) {
            dataSupplier[namePeriod] += ySupplier.amount.orderUSD + ySupplier.amount.depositUSD + ySupplier.amount.paymentUSD
        } else if (rpt === enumOptions.averageOrdersAmount) {
            dataSupplier[namePeriod] += ySupplier.avgAmount.orderUSD + ySupplier.avgAmount.depositUSD + ySupplier.avgAmount.paymentUSD
        } else if (rpt === enumOptions.averageItemsPerOrder) {
            dataSupplier[namePeriod] += ySupplier.avgItem.order + ySupplier.avgItem.deposit + ySupplier.avgItem.payment
        } else {
            dataSupplier[namePeriod] += ySupplier.count.order + ySupplier.count.deposit + ySupplier.count.payment
        }
        setTableSymbol(rpt)
    }

    const buildInformation = (rpt) => {
        let index = 0
        dataApiHistory.monthsNames.forEach((name) => {
            let indexColumn = index + 2
            columnsTable[indexColumn].title = name.toUpperCase()
            index++
        })

        const suppliers = [...new Set(dataApiHistory.data.flat().map((x) => x.supplierId))]
        const dataTable = []
        let { periods } = getLastSixPeriods()
        periods = periods.map((x) => x.month)

        suppliers.forEach((supplierId) => {
            const dataSupplier = {
                id: supplierId,
                name: "",
                month1: 0,
                month2: 0,
                month3: 0,
                month4: 0,
                month5: 0,
                month6: 0,
                total: 0,
                supplierId: "",
                year: 0,
            }

            dataApiHistory.data
                .flat()
                .filter((x) => x.supplierId === supplierId)
                .forEach((ySupplier) => {
                    dataSupplier.name = ySupplier.supplier.businessName
                    dataSupplier.year = ySupplier.year
                    dataSupplier.supplierId = ySupplier.supplierId
                    let indexMonth = periods.indexOf(ySupplier.month)
                    if (indexMonth > -1) {
                        indexMonth++
                    }
                    switch (indexMonth) {
                        case 1:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month1")
                            break
                        case 2:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month2")
                            break
                        case 3:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month3")
                            break
                        case 4:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month4")
                            break
                        case 5:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month5")
                            break
                        case 6:
                            sumPeriod(rpt, ySupplier, dataSupplier, "month6")
                            break
                        default:
                            break
                    }
                })
            dataSupplier.total +=
                dataSupplier.month1 + dataSupplier.month2 + dataSupplier.month3 + dataSupplier.month4 + dataSupplier.month5 + dataSupplier.month6
            dataTable.push(dataSupplier)
        })
        updateChartLabels(dataApiHistory.monthsNames)
        for (let index = 0; index < dataTable.length; index++) {
            dataTable[index]["check"] = false
        }
        const obj = dataHistory
        obj.data = dataTable
        setDataHistory({ ...obj })
    }

    const getLastSixPeriods = () => {
        const now = moment()
        const periods = [] //{ year: 2020, month }
        let years = [] // years
        Array.from({ length: 6 }, (_, i) => i).forEach((_, index) => {
            const date = index === 0 ? now : now.subtract(1, "months")
            const year = date.year()
            const month = date.month()
            periods.push({ year: year, month: month })
            years.push(year)
        })
        years = [...new Set(years)]
        return { years, periods }
    }

    const updateChartLabels = (labels) => {
        setDataLabelsChart(labels)
        const colors = barColor
        colors.forEach((x) => (x.apply = false))
        setBarColor(colors)
        if (chartBarRef) {
            chartBarRef.data.labels = labels
            chartBarRef.data.datasets = []
            chartBarRef.update()
        }
    }

    useEffect(() => {
        const user = localStorage.getItem("ConsumerUser") ? JSON.parse(localStorage.getItem("ConsumerUser")) : {}
        if (user.supplier) {
            getData(user.supplier.id)
                .then(() => {
                    setLoader(false)
                    buildInformation(selectionValue)
                })
                .catch((err) => {
                    setLoader(false)
                    console.error(err)
                })
        }
        return () => {
            // unregister eventListener once
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnChangeSelect = (e) => {
        setSelectionValue(e.value)
        buildInformation(e.value)
    }

    const handleClickBar = function (e, i) {
        const element = this.getElementAtEvent(e)
        if (element[0] && chartBarRef) {
            const keyLabel = chartBarRef.data.labels[element[0]._index]
            const month = objMonths[keyLabel]
            const { supplierId, year } = chartBarRef.data.datasets[element[0]._datasetIndex]
            history.push(`DetailStatement1/${month}/${year}/${supplierId}`)
        }
    }

    const setTableSymbol = (rpt) => {
        const objColumns = columnsTable
        if (rpt === enumOptions.transactionsPerMonth) {
            objColumns[2].bottomCalcFormatterParams.symbol = ""
            objColumns[3].bottomCalcFormatterParams.symbol = ""
            objColumns[4].bottomCalcFormatterParams.symbol = ""
            objColumns[5].bottomCalcFormatterParams.symbol = ""
            objColumns[6].bottomCalcFormatterParams.symbol = ""
            objColumns[7].bottomCalcFormatterParams.symbol = ""
            objColumns[8].bottomCalcFormatterParams.symbol = ""
        } else if (rpt === enumOptions.estimatedBill) {
            objColumns[2].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[3].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[4].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[5].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[6].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[7].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[8].bottomCalcFormatterParams.symbol = currencySymbol
        } else if (rpt === enumOptions.averageOrdersAmount) {
            objColumns[2].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[3].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[4].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[5].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[6].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[7].bottomCalcFormatterParams.symbol = currencySymbol
            objColumns[8].bottomCalcFormatterParams.symbol = currencySymbol
        } else if (rpt === enumOptions.averageItemsPerOrder) {
            objColumns[2].bottomCalcFormatterParams.symbol = ""
            objColumns[3].bottomCalcFormatterParams.symbol = ""
            objColumns[4].bottomCalcFormatterParams.symbol = ""
            objColumns[5].bottomCalcFormatterParams.symbol = ""
            objColumns[6].bottomCalcFormatterParams.symbol = ""
            objColumns[7].bottomCalcFormatterParams.symbol = ""
            objColumns[8].bottomCalcFormatterParams.symbol = ""
        } else {
            objColumns[2].bottomCalcFormatterParams.symbol = ""
            objColumns[3].bottomCalcFormatterParams.symbol = ""
            objColumns[4].bottomCalcFormatterParams.symbol = ""
            objColumns[5].bottomCalcFormatterParams.symbol = ""
            objColumns[6].bottomCalcFormatterParams.symbol = ""
            objColumns[7].bottomCalcFormatterParams.symbol = ""
            objColumns[8].bottomCalcFormatterParams.symbol = ""
        }
        setColumnsTable(objColumns)
    }

    return (
        <>
            {loader ? (
                <LoaderWindow />
            ) : (
                // dataApiHistory.data.length == 0 ?
                //     ""
                //     :
                <>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="title__section_statement">
                                <img src={"https://cdn.xsalesconsumer.com/console/history-24px.svg"} alt="" loading="lazy" />
                                <h4>{I18n.get("lblactivityhistory")}</h4>
                            </div>
                            <div className="col-sm-4">
                                <SelectCombo
                                    id="activityHistorySelect"
                                    name="activityHistorySelect"
                                    label={""}
                                    options={activityComboOptions}
                                    optionSelected={enumOptions.transactionsPerMonth}
                                    onChange={handleOnChangeSelect}
                                    isEditable={true}
                                    value={selectionValue}
                                />
                            </div>
                            <TabulatorTable
                                id="ActivityHistorySupplier"
                                Title={""}
                                Data={dataHistory.data}
                                Columns={columnsTable}
                                Filter={[]}
                                RowClick={() => {}}
                                placeholder={I18n.get("lblLoadingData")}
                                Pagination={false}
                                Height="200px"
                                downloadIcon={true}
                                moreIcon={false}
                                Search={true}
                                //setRefTable={(table) => (tabulatorRef = table)}
                                labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                                lblSearch= {I18n.get("lblSearch")}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6" style={{ overflowX: "auto" }}>
                            <div className="statement__chart__bar__container">
                                {
                                    <VerticalBarChart
                                        id="VerticalBarChartHistory"
                                        labels={dataLabelsChart}
                                        datasets={dataBarChart}
                                        // maxYValue={100}
                                        minYValue={0}
                                        // stepSize={10000}
                                        stacked={false}
                                        showLabelBar={false}
                                        showLegend={true}
                                        handleClick={handleClickBar}
                                        setRefChart={(chart) => (chartBarRef = chart)}
                                        // width="100"
                                        height="200"
                                        barPercentage={0.5}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="statement__section_separator"></div>
                </>
            )}
        </>
    )
}

export default ActivityHistorySupplier
