import React from 'react'

export default function SeparateTop(props) {
    return (
        <div className="separateTop">
            {
                props.children
            }
        </div>
    )
}
