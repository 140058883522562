import React from "react"
import { TabulatorTable, Title, Filters, LoaderWindow } from 'xsales-components'
import { XSalesConsumer } from "../../Utils/Utils"
import { XSalesData } from "../../DataQuery/XSalesData"
import { API, graphqlOperation, I18n } from "aws-amplify"
import * as subscription from '../../graphql/subscriptions'


class index extends React.Component {
    constructor(props) {
        super(props)
        this.subscription = null
        this.state = {
            loadWindow: true,
            objData: {
                InitialData: [],
                Data: [],
                Filter: [],
                Columns: [],
                RowClick: this.rowClickEventConsumer,
            },
            dataFilters: []
        }
    }

    getLanguage = async () => {
        try {
            let lang = localStorage.getItem("languageApp");
            if (!lang) {
              const [lang1] = navigator.language.split('-');
              lang = lang1.toUpperCase();
            }
            I18n.setLanguage(lang.toUpperCase());
          } catch (error) {
            I18n.setLanguage('EN');
            console.log(error.message);
          }
    }
    
    rowClickEventConsumer = (e, row) => {

        let id = row.getData().id
        this.props.history.push(`/Consumers/Detail/${id}`);
        window.sessionStorage.setItem("pathRoot", this.props.match.path)
    }


    EventFilterChange=(id)=>
    {
        const buttons = document.querySelectorAll(".btn__filters__active--component")
        buttons.forEach((btn) => {
            
            if (btn.id === id)
            {
                btn.classList.add("btn__filters__active--component")
            }
            else
            {
                btn.classList.remove("btn__filters__active--component")
            }
        })
        let objData = this.state.objData;
        switch (id) {
            case "ALL":
                objData.Filter = [{ field: "status", type: "!=", value: [I18n.get("lblDELETED")]}]
                break;
            case "lblAPPROVED":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblAPPROVED")] },
                ]
                break;
            case "lblVALIDATING":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblVALIDATING")] },
                ]
                break;
            case "lblDELETED":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblDELETED")] },
                ]
                break;
            case "lblREJECTED":
                objData.Filter = [
                    { field: "status", type: "in", value: [I18n.get("lblREJECTED")] },
                ]
                break;
            default:
                objData.Filter = [{ field: "status", type: "!=", value: [I18n.get("lblDELETED")]}]
                break;
        }
        this.setState({ objData: objData });
        
    }

    handleClickFilters= (e) =>{
        let { id } = e.target; 
        
        if (id!=='ALL'){
            const hasClass = e.target.classList.contains('btn__filters__active--component');
            if (hasClass===false){
                id="ALL";
            } 
        }
        this.EventFilterChange(id)

       
        window.sessionStorage.setItem("filtersConsumer", id)
        
    }

    componentDidMount() {
        document.title = "Consumer:List"
        this.getLanguage();
        let columns=[
            {
                title: I18n.get("lblconname"),
                field: "businessName",
                widthGrow: 3,
            },
            {
                title: I18n.get("lblconemail"),
                field: "email",
                widthGrow: 3,
            },
            {
                title: I18n.get("lblconStatus"),
                field: "status",
                widthGrow: 1,
            },
            {
                title: I18n.get("lblconRequestDate"),
                field: "requestedOn",
                widthGrow: 2,
               formatter:  (cell, formatterParams, onRendered) => XSalesConsumer.formatShortDate(cell.getValue()) 
            },
            /*{
                title: I18n.get("lblconApprovalDate"),
                field: "responsedOn",
                widthGrow: 2,
                formatter:  (cell, formatterParams, onRendered) => XSalesConsumer.formatShortDate(cell.getValue())
            },*/
            {
                title: I18n.get("lbltaxId"),
                field: "taxId",
                align: "right",
                widthGrow: 1,
            },
            {
                title: I18n.get("lblconERPCode"),
                align: "right",
                field: "erpCode",
                widthGrow: 1,
            },
        ]
        let dataFilters= [
            {
                label: I18n.get("lblALL"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "ALL",
                name: "ALL",
                type: "button",
                active: true
            },
            {
                label: I18n.get("lblAPPROVED"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblAPPROVED",
                name: "APPROVED",
                type: "button",
                active: false
            },
            {
                label: I18n.get("lblVALIDATING"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblVALIDATING",
                name: "VALIDATING",
                type: "button",
                active: false
            },
            {
                label: I18n.get("lblREJECTED"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblREJECTED",
                name: "REJECTED",
                type: "button",
                active: false
            },
            {
                label: I18n.get("lblDELETED"),
                handleClick: this.handleClickFilters,
                className: "btn btn-outline-primary bounds-button btn__filters__active--component",
                id: "lblDELETED",
                name: "DELETED",
                type: "button",
                active: false
            }
        ]
        let objData = this.state.objData
        objData.Data = objData.InitialData
        objData.Columns = columns;
        this.setState({ dataFilters: dataFilters })
        XSalesConsumer.getCurrentUser()
            .then((data) => {
                this.fetchSupplierConsumer()
            })
            .catch((err) => {
                objData.Data = objData.InitialData
                this.setState({ loadWindow: false })
                throw new Error('ID of Consumer not supplied in request')
                
            })

         
        objData.showLoaderDt = false;
        
        let status = this.props.match.params.status
        if (status !== undefined) {
            //you must apply a specific filter
            switch (status.toLowerCase()) {
                case "rejected":
                    this.onlyRejected()
                    break
                case "approved":
                    this.onlyApproved()
                    break
                case "validating":
                    this.onlyValidating()
                    break
                case "deleted":
                    this.onlyDeleted()
                    break
                default:
                    this.ALL()
                    break
            }
        }
        this.subscription = API.graphql(
            graphqlOperation(subscription.onCreateSupplierConsumer)
        ).subscribe(() => {
            this.fetchSupplierConsumer()
        })            
            let filters = window.sessionStorage.getItem("filtersConsumer")
                if(filters !== 'null' && filters !== null)
                {
                    this.EventFilterChange(filters)
                    const elementFilter = document.getElementById(filters)
                    if(elementFilter !== null)
                    {
                        elementFilter.classList.add("btn__filters__active--component")
                    }
                    
                } 
    }

    fetchSupplierConsumer = async () => {
        try {
            let objData = this.state.objData
            XSalesConsumer.getCurrentUser()
                .then(async (data) => {
                    const supplierId = data.supplier.id;
                    XSalesData.Consumer.GetConsumersBySupplier(supplierId)
                    .then(async (data) => {
                        console.log(data)
                        objData.Filter = [{ field: "status", type: "!=", value: [I18n.get("lblDELETED")]}]
                        objData.Data = data.filter(x => x.consumer !== null).map((item) => {
                            return {
                                id: item.id, //id de la tabla supplierConsumer
                                businessName: item.consumer?.businessName,
                                email: item.consumer?.email,
                                status: I18n.get("lbl" + item.requestStatus),
                                requestedOn: item.requestedOn,
                                //responsedOn: item.responsedOn,
                                erpCode: item.consumerIdERP,
                                consumer: item.consumer,
                                taxId: item.consumer.taxID
                            }
                        }) 
                    })
                    .catch((err) => {
                        objData.Data = objData.InitialData
                          this.setState({loadWindow: false})
                    }).finally(async t => {
                        await this.setState({ objData: objData, loadWindow: false }) 
                    })
                })
                .catch((err) => {
                    console.log("supplierConsumer not available")
                })
            // XSalesData.Suppliers.GetSyncLogTop()
        } catch (error) {
            console.log("error fetchSupplierConsumer")
        }
    }

    onlyApproved = (e) => {
        if (e !== undefined) XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            { field: "status", type: "in", value: [I18n.get("lblAPPROVED")] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyValidating = (e) => {
        if (e !== undefined) XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            { field: "status", type: "in", value: [I18n.get("lblVALIDATING")] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyDeleted = (e) => {
        if (e !== undefined) XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            { field: "status", type: "in", value: [I18n.get("lblDELETED")] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    onlyRejected = (e) => {
        if (e !== undefined) XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [
            { field: "status", type: "in", value: [I18n.get("lblREJECTED")] },
        ]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    ALL = (e) => {
        if (e !== undefined) XSalesConsumer.ChangeColorFilter(e)
        let objData = this.state.objData
        objData.Filter = [{ field: "status", type: "!=", value: [I18n.get("lblDELETED")]}]
        this.setState({ objData: objData })
        XSalesConsumer.CloseFilter()
    }

    lista = () => {
        return (
            <>
                <li className="lista" onClick={this.ALL}>
                    {I18n.get("lblALL")}
                </li>
                <li className="lista" onClick={this.onlyApproved}>
                    {I18n.get("lblAPPROVED")}
                </li>
                <li className="lista" onClick={this.onlyValidating}>
                    {I18n.get("lblVALIDATING")}
                </li>
                <li className="lista" onClick={this.onlyDeleted}>
                    {I18n.get("lblDELETED")}
                </li>
                <li className="lista" onClick={this.onlyRejected}>
                    {I18n.get("lblREJECTED")}
                </li>
            </>
        )
    }

    render() {
        return (
            <>
                {
                    this.state.loadWindow ?
                        <LoaderWindow />
                        :
                        <>
                            <Title label={I18n.get("lblConsumers")} />
                            <Filters filterName="filterconsumer" data={this.state.dataFilters} />
                            <TabulatorTable
                                Title={""}
                                Data={this.state.objData.Data}
                                Columns={this.state.objData.Columns}
                                Filter={this.state.objData.Filter}
                                RowClick={this.state.objData.RowClick}
                                placeholder={I18n.get("lblLoadingData")}
                                Icons={false}
                                Search={true}
                                lblSearch= {I18n.get("lblSearch")}
                                labelEmptyData={I18n.get("lblnodataavailabletabulator")}
                                
                            />
                        </>
                }
            </>
        )
    }
}

export default index
