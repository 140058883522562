import React from "react"
import { I18n } from "@aws-amplify/core"
const NoDataAvailable = (props) => (
    <div className="flex__container__center">
        <div className="statement__box_message">
            <img
                className=""
                alt=""
                src="https://cdn.xsalesconsumer.com/console/leaderboard-24px.svg"
            />
            <p style={{ textAlign: "center" }}>
                {props.label
                    ? I18n.get(props.label)
                    : I18n.get("lbldataavailable")}
            </p>
        </div>
    </div>
)

export default NoDataAvailable
