import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import Draggable from 'react-draggable';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export function ChartBar({ labels = [], dataSet1 = { data: [], label: '' }, dataSet2 = { data: [], label: '' }, yLabel = '', handleClickBar, datasets, formatter, showLegend, rotationLabels }) {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        onClick: handleClickBar ,
        showAllTooltips: true,
        layout: {
            padding: {                
              top: 40                
            }
          },
        plugins: {
            legend: {
                position: 'right',
                display: showLegend || true,
                labels:{
                    fontSize: window.innerWidth > 350 ? 20 : 10
                }
            },
            title: {
                display: true,
                text: ' ',
            },
            datalabels: {
                color: '#333333',
                align:"end",
                anchor: "end",
                clamp : true, 
                formatter: formatter,
                autoSkip: true,
                rotation: function(value){
                    let rotation= 0;
                    if (value.dataset){
                        if (value.dataset.data){
                            if (value.dataset.data.length>5){
                                rotation= 270;
                            }
                        }
                    }
                    return rotation;
                },
                labels: {
                  title: {
                    font: {
                    //   weight: 'bold',
                    //   size: 10,
                    //   family:'Verdana'
                    }
                  },
                  value: {
                    color: '#333333'
                  }
                }
              }
        },
         scaleShowValues: true,
        scales: {
            x: {
                stacked: false,
                beginAtZero: true,
                ticks: {
                    autoSkip: true,
                    font: {
                        size: 10,
                        family:'Verdana'
                    }
                },
                grid: {
                    display: false
                }
            },
            y: {
            //   stacked: true,
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: yLabel
                },
                beginAtZero: true,
                 suggestedMin: 0,
                //  suggestedMax: 500,
                 scaleSteps: 2,
                // min: 0,
                // max: 10,
                ticks: {
                    major: {
                        enabled: true
                    },
                    color: (context) => context.tick && context.tick.major && '#FF0000',
                    beginAtZero: true,
                    stepSize: 500
                }
            }
        }
    };

    const data = {
        labels,
        datasets: []
    };
    

    if(dataSet1?.data?.length > 0) {
        data.datasets.push({
            label: dataSet1.label,
            data: dataSet1.data,
            backgroundColor: '#5E32B2',
            barPercentage: 0.2
        })
    }
   
    if(dataSet2?.data?.length > 0) {
        data.datasets.push({
            label: dataSet2.label,
            data: dataSet2.data,
            backgroundColor: '#5E32B2',
            barPercentage: 0.2
        })
    }
    
    if(datasets){
        data.datasets = datasets
    }
    

    return <Bar options={options} data={data} />
}