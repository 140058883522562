import React from 'react'
import styled from 'styled-components'
import { ChartBar } from './ChartBar'
// import Draggable from 'react-draggable';
const defaultChartOptions = { labels: [], dataSet1: { data: [], label: '' }, dataSet2: { data: [], label: '' }, yLabel: '', handleClickBar: null }
export function Bar({ headerLabel, chartOptions = defaultChartOptions }) {
    return (
        <Container>
            <Header>
                <TitleChart>{headerLabel}</TitleChart>
            </Header>
            <ContainerChart>
                <ChartBar
                    {...chartOptions}
                    handleClickBar={chartOptions.handleClickBar}
                />
            </ContainerChart>


        </Container>
    )
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    /* max-width:100%; */
    max-height:420px;
    width: 100%;
    height: 400px;
    border: 1px solid var(--gray-xs-color);
`

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #fff;
    height:28px;
    width: 100%;
    background-color: #5E32B2;
`

const TitleChart = styled.p`
    margin-top: 1rem;
`

const ContainerChart = styled.div`
    height: 400px;
    width: 100%;
`





