import React from 'react'
import '../../../assets/css/dbStatement.css'
import CurrentPeriod from './CurrentPeriod'
import ActivityHistory from './ActivityHistory'
import TopFiveSuppliers from './TopFiveSuppliers'
import SummarySupplierXsm from './SummarySupplierXsm'

const DashboardStatementXSM = (props) => {
    return <div className="statement__container__dashboard">
                <CurrentPeriod />
                <div className="statement__section_separator"></div>
                <ActivityHistory />
                <div className="statement__section_separator"></div>
                <TopFiveSuppliers />
                <div className="statement__section_separator"></div>
                <SummarySupplierXsm />
           </div>
}

export default DashboardStatementXSM;