export const schema = {
    models: {
        Product: {
            name: "Product",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                productId: {
                    name: "productId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                supplierId: {
                    name: "supplierId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                unit: {
                    name: "unit",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                subcategoryId: {
                    name: "subcategoryId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                descriptionLanguage1: {
                    name: "descriptionLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                descriptionLanguage2: {
                    name: "descriptionLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                descriptionLanguage3: {
                    name: "descriptionLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                descriptionLanguage4: {
                    name: "descriptionLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage1: {
                    name: "nameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage2: {
                    name: "nameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage3: {
                    name: "nameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage4: {
                    name: "nameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                shortnameLanguage1: {
                    name: "shortnameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                shortnameLanguage2: {
                    name: "shortnameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                shortnameLanguage3: {
                    name: "shortnameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                shortnameLanguage4: {
                    name: "shortnameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                tags: {
                    name: "tags",
                    isArray: true,
                    type: {
                        nonModel: "Tag",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                price: {
                    name: "price",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                percentageDiscount: {
                    name: "percentageDiscount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                amountDiscount: {
                    name: "amountDiscount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                blockedForSales: {
                    name: "blockedForSales",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                blockedForSalesDate: {
                    name: "blockedForSalesDate",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                inactive: {
                    name: "inactive",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                inactiveOn: {
                    name: "inactiveOn",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                inactiveParent: {
                    name: "inactiveParent",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                inactiveParentOn: {
                    name: "inactiveParentOn",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                imageInternalName: {
                    name: "imageInternalName",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                imageExternalURL: {
                    name: "imageExternalURL",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                taxFree: {
                    name: "taxFree",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                barcode: {
                    name: "barcode",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                sameOrderClass: {
                    name: "sameOrderClass",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                stock: {
                    name: "stock",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "Products",
            attributes: [
                {
                    type: "model",
                    properties: {
                        subscriptions: null,
                    },
                },
                {
                    type: "searchable",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        fields: ["supplierId", "productId", "unit"],
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "ProductsbySubcategory",
                        fields: ["supplierId", "subcategoryId", "productId"],
                        queryField: "ProductsbySubcategory",
                    },
                },
            ],
        },
        Category: {
            name: "Category",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                supplierId: {
                    name: "supplierId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                categoryId: {
                    name: "categoryId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage1: {
                    name: "nameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage2: {
                    name: "nameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage3: {
                    name: "nameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage4: {
                    name: "nameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                inactive: {
                    name: "inactive",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                inactiveOn: {
                    name: "inactiveOn",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                imageInternalName: {
                    name: "imageInternalName",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                imageExternalURL: {
                    name: "imageExternalURL",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
            },
            syncable: true,
            pluralName: "Categories",
            attributes: [
                {
                    type: "model",
                    properties: {
                        subscriptions: null,
                    },
                },
                {
                    type: "key",
                    properties: {
                        fields: ["supplierId", "categoryId"],
                    },
                },
            ],
        },
        Subcategory: {
            name: "Subcategory",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                supplierId: {
                    name: "supplierId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                categoryId: {
                    name: "categoryId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                subcategoryId: {
                    name: "subcategoryId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage1: {
                    name: "nameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage2: {
                    name: "nameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage3: {
                    name: "nameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage4: {
                    name: "nameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                inactive: {
                    name: "inactive",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                inactiveOn: {
                    name: "inactiveOn",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                inactiveParent: {
                    name: "inactiveParent",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                inactiveParentOn: {
                    name: "inactiveParentOn",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                imageInternalName: {
                    name: "imageInternalName",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                imageExternalURL: {
                    name: "imageExternalURL",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
            },
            syncable: true,
            pluralName: "Subcategories",
            attributes: [
                {
                    type: "model",
                    properties: {
                        subscriptions: null,
                    },
                },
                {
                    type: "key",
                    properties: {
                        fields: ["supplierId", "subcategoryId"],
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "SubcategoriesbyCategory",
                        fields: ["supplierId", "categoryId"],
                        queryField: "SubcategoriesbyCategory",
                    },
                },
            ],
        },
        SupplierConsumer: {
            name: "SupplierConsumer",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                supplier: {
                    name: "supplier",
                    isArray: false,
                    type: {
                        model: "Supplier",
                    },
                    isRequired: false,
                    attributes: [],
                    association: {
                        connectionType: "BELONGS_TO",
                        targetName: "supplierId",
                    },
                },
                consumer: {
                    name: "consumer",
                    isArray: false,
                    type: {
                        model: "Consumer",
                    },
                    isRequired: false,
                    attributes: [],
                    association: {
                        connectionType: "BELONGS_TO",
                        targetName: "consumerId",
                    },
                },
                requestedOn: {
                    name: "requestedOn",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                requestedBy: {
                    name: "requestedBy",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                requestComments: {
                    name: "requestComments",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                responsedOn: {
                    name: "responsedOn",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                responsedBy: {
                    name: "responsedBy",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                responseComments: {
                    name: "responseComments",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                consumerIdERP: {
                    name: "consumerIdERP",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                requestStatus: {
                    name: "requestStatus",
                    isArray: false,
                    type: {
                        enum: "StatusSupplierRequest",
                    },
                    isRequired: false,
                    attributes: [],
                },
                blockedForSales: {
                    name: "blockedForSales",
                    isArray: false,
                    type: {
                        enum: "StatusForSales",
                    },
                    isRequired: false,
                    attributes: [],
                },
                daysDelivery: {
                    name: "daysDelivery",
                    isArray: false,
                    type: {
                        nonModel: "DaysDelivery",
                    },
                    isRequired: false,
                    attributes: [],
                },
                promotions: {
                    name: "promotions",
                    isArray: true,
                    type: {
                        nonModel: "Promotions",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                taxPercentage: {
                    name: "taxPercentage",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                discountPercentage: {
                    name: "discountPercentage",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                group1: {
                    name: "group1",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                group2: {
                    name: "group2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                group3: {
                    name: "group3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "SupplierConsumers",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        name: "ByConsumer",
                        fields: ["consumerId"],
                        queryField: "SuppliersByConsumer",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "BySupplier",
                        fields: ["supplierId"],
                        queryField: "ConsumersBySupplier",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "BySupplierConsumer",
                        fields: ["supplierId", "consumerId"],
                        queryField: "ConsumerSupplierRealation",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "BySupplierConsumerIdERP",
                        fields: ["supplierId", "consumerIdERP"],
                        queryField: "ConsumerIdERPSupplierRelation",
                    },
                },
            ],
        },
        Supplier: {
            name: "Supplier",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                name: {
                    name: "name",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                businessName: {
                    name: "businessName",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                username: {
                    name: "username",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                taxID: {
                    name: "taxID",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                promotions: {
                    name: "promotions",
                    isArray: true,
                    type: {
                        nonModel: "Promotions",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                status: {
                    name: "status",
                    isArray: false,
                    type: {
                        enum: "StatusSupplier",
                    },
                    isRequired: false,
                    attributes: [],
                },
                historyStatus: {
                    name: "historyStatus",
                    isArray: true,
                    type: {
                        nonModel: "HistoryStatusSupplier",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                address: {
                    name: "address",
                    isArray: true,
                    type: {
                        nonModel: "Address",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                phones: {
                    name: "phones",
                    isArray: true,
                    type: {
                        nonModel: "Phone",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                website: {
                    name: "website",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                email: {
                    name: "email",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                contactName: {
                    name: "contactName",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                contactEmail: {
                    name: "contactEmail",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                latitude: {
                    name: "latitude",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                longitude: {
                    name: "longitude",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                summary: {
                    name: "summary",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                createdAt: {
                    name: "createdAt",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                approvedAt: {
                    name: "approvedAt",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                industries: {
                    name: "industries",
                    isArray: true,
                    type: {
                        model: "SupplierIndustry",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                    association: {
                        connectionType: "HAS_MANY",
                        associatedWith: "supplier",
                    },
                },
                currencyISO: {
                    name: "currencyISO",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                currencySymbol: {
                    name: "currencySymbol",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                currencyISOErp: {
                    name: "currencyISOErp",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                contract: {
                    name: "contract",
                    isArray: false,
                    type: {
                        nonModel: "Contract",
                    },
                    isRequired: false,
                    attributes: [],
                },
                isXSM: {
                    name: "isXSM",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                imageInternalName: {
                    name: "imageInternalName",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                imageExternalURL: {
                    name: "imageExternalURL",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                taxPercentage: {
                    name: "taxPercentage",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                discountPercentage: {
                    name: "discountPercentage",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                groups1: {
                    name: "groups1",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                groups2: {
                    name: "groups2",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                groups3: {
                    name: "groups3",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                productTags: {
                    name: "productTags",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                paymentMethods: {
                    name: "paymentMethods",
                    isArray: true,
                    type: {
                        nonModel: "paymentMethodType",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                productSameOrderClasses: {
                    name: "productSameOrderClasses",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
            },
            syncable: true,
            pluralName: "Suppliers",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        name: "ByName",
                        fields: ["businessName"],
                        queryField: "SupplierByName",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "ByUser",
                        fields: ["username"],
                        queryField: "SupplierByUser",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "ByStatus",
                        fields: ["status"],
                        queryField: "SupplierByStatus",
                    },
                },
            ],
        },
        SupplierIndustry: {
            name: "SupplierIndustry",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                supplier: {
                    name: "supplier",
                    isArray: false,
                    type: {
                        model: "Supplier",
                    },
                    isRequired: false,
                    attributes: [],
                    association: {
                        connectionType: "BELONGS_TO",
                        targetName: "supplierId",
                    },
                },
                industry: {
                    name: "industry",
                    isArray: false,
                    type: {
                        model: "Industry",
                    },
                    isRequired: true,
                    attributes: [],
                    association: {
                        connectionType: "BELONGS_TO",
                        targetName: "industryId",
                    },
                },
            },
            syncable: true,
            pluralName: "SupplierIndustries",
            attributes: [
                {
                    type: "model",
                    properties: {
                        queries: null,
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "IndustriesBySupplier",
                        fields: ["supplierId", "industryId"],
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "SuppliersByIndustry",
                        fields: ["industryId", "supplierId"],
                    },
                },
            ],
        },
        Industry: {
            name: "Industry",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage1: {
                    name: "nameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage2: {
                    name: "nameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage3: {
                    name: "nameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage4: {
                    name: "nameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                active: {
                    name: "active",
                    isArray: false,
                    type: "Boolean",
                    isRequired: true,
                    attributes: [],
                },
                suppliers: {
                    name: "suppliers",
                    isArray: true,
                    type: {
                        model: "SupplierIndustry",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                    association: {
                        connectionType: "HAS_MANY",
                        associatedWith: "industry",
                    },
                },
                consumers: {
                    name: "consumers",
                    isArray: true,
                    type: {
                        model: "ConsumerIndustry",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                    association: {
                        connectionType: "HAS_MANY",
                        associatedWith: "industry",
                    },
                },
            },
            syncable: true,
            pluralName: "Industries",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
            ],
        },
        ConsumerIndustry: {
            name: "ConsumerIndustry",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                consumer: {
                    name: "consumer",
                    isArray: false,
                    type: {
                        model: "Consumer",
                    },
                    isRequired: true,
                    attributes: [],
                    association: {
                        connectionType: "BELONGS_TO",
                        targetName: "consumerId",
                    },
                },
                industry: {
                    name: "industry",
                    isArray: false,
                    type: {
                        model: "Industry",
                    },
                    isRequired: true,
                    attributes: [],
                    association: {
                        connectionType: "BELONGS_TO",
                        targetName: "industryId",
                    },
                },
            },
            syncable: true,
            pluralName: "ConsumerIndustries",
            attributes: [
                {
                    type: "model",
                    properties: {
                        queries: null,
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "IndustriesByConsumer",
                        fields: ["consumerId", "industryId"],
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "ConsumersByIndustry",
                        fields: ["industryId", "consumerId"],
                    },
                },
            ],
        },
        Consumer: {
            name: "Consumer",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                name: {
                    name: "name",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                businessName: {
                    name: "businessName",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                username: {
                    name: "username",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                taxID: {
                    name: "taxID",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                status: {
                    name: "status",
                    isArray: false,
                    type: {
                        enum: "StatusConsumer",
                    },
                    isRequired: false,
                    attributes: [],
                },
                address: {
                    name: "address",
                    isArray: true,
                    type: {
                        nonModel: "Address",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                phones: {
                    name: "phones",
                    isArray: true,
                    type: {
                        nonModel: "Phone",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                website: {
                    name: "website",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                email: {
                    name: "email",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                summary: {
                    name: "summary",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                contactName: {
                    name: "contactName",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                contactEmail: {
                    name: "contactEmail",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                latitude: {
                    name: "latitude",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                longitude: {
                    name: "longitude",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                industries: {
                    name: "industries",
                    isArray: true,
                    type: {
                        model: "ConsumerIndustry",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                    association: {
                        connectionType: "HAS_MANY",
                        associatedWith: "consumer",
                    },
                },
                isXSM: {
                    name: "isXSM",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                currencyISO: {
                    name: "currencyISO",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                currencySymbol: {
                    name: "currencySymbol",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                currencyISOErp: {
                    name: "currencyISOErp",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "Consumers",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        name: "ByUser",
                        fields: ["username"],
                        queryField: "ConsumerByUser",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "ByEmail",
                        fields: ["email"],
                        queryField: "ConsumerByEmail",
                    },
                },
            ],
        },
        SupplierSetup: {
            name: "SupplierSetup",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                supplierId: {
                    name: "supplierId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                setupId: {
                    name: "setupId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                value: {
                    name: "value",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "SupplierSetups",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        fields: ["supplierId", "setupId"],
                    },
                },
            ],
        },
        SupplierConsumerSetup: {
            name: "SupplierConsumerSetup",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                supplierId: {
                    name: "supplierId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                consumerId: {
                    name: "consumerId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                setupId: {
                    name: "setupId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                value: {
                    name: "value",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "SupplierConsumerSetups",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        fields: ["supplierId", "consumerId", "setupId"],
                    },
                },
            ],
        },
        User: {
            name: "User",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                username: {
                    name: "username",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                name: {
                    name: "name",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                lastName: {
                    name: "lastName",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                alternateEmail: {
                    name: "alternateEmail",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                lastSessionDate: {
                    name: "lastSessionDate",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                lastIP: {
                    name: "lastIP",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                lastOS: {
                    name: "lastOS",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                lastDevice: {
                    name: "lastDevice",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                lastBrowser: {
                    name: "lastBrowser",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                token: {
                    name: "token",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                email: {
                    name: "email",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                userType: {
                    name: "userType",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "Users",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        fields: ["username"],
                    },
                },
            ],
        },
        SupplierConsumerProduct: {
            name: "SupplierConsumerProduct",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                productId: {
                    name: "productId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                supplierId: {
                    name: "supplierId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                consumerId: {
                    name: "consumerId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                unit: {
                    name: "unit",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                price: {
                    name: "price",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                percentageDiscount: {
                    name: "percentageDiscount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                amountDiscount: {
                    name: "amountDiscount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                stock: {
                    name: "stock",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "SupplierConsumerProducts",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        fields: [
                            "supplierId",
                            "consumerId",
                            "productId",
                            "unit",
                        ],
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "ByConsumer",
                        fields: ["supplierId", "consumerId"],
                        queryField: "SupplierConsumerProductsByConsumer",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "ByProduct",
                        fields: ["supplierId", "productId"],
                        queryField: "SupplierConsumerProductsByProduct",
                    },
                },
            ],
        },
        Window: {
            name: "Window",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage1: {
                    name: "nameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage2: {
                    name: "nameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage3: {
                    name: "nameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage4: {
                    name: "nameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                type: {
                    name: "type",
                    isArray: false,
                    type: {
                        enum: "TypeApp",
                    },
                    isRequired: true,
                    attributes: [],
                },
                messages: {
                    name: "messages",
                    isArray: true,
                    type: {
                        model: "Message",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                    association: {
                        connectionType: "HAS_MANY",
                        associatedWith: "windowId",
                    },
                },
                labels: {
                    name: "labels",
                    isArray: true,
                    type: {
                        model: "Label",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                    association: {
                        connectionType: "HAS_MANY",
                        associatedWith: "windowId",
                    },
                },
                security: {
                    name: "security",
                    isArray: false,
                    type: {
                        model: "Security",
                    },
                    isRequired: false,
                    attributes: [],
                    association: {
                        connectionType: "BELONGS_TO",
                        targetName: "windowSecurityId",
                    },
                },
            },
            syncable: true,
            pluralName: "Windows",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
            ],
        },
        Message: {
            name: "Message",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                windowId: {
                    name: "windowId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage1: {
                    name: "nameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage2: {
                    name: "nameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage3: {
                    name: "nameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage4: {
                    name: "nameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                audit: {
                    name: "audit",
                    isArray: false,
                    type: {
                        nonModel: "AuditFields",
                    },
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "Messages",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        name: "MessageByWindow",
                        fields: ["windowId"],
                    },
                },
            ],
        },
        Label: {
            name: "Label",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                windowId: {
                    name: "windowId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage1: {
                    name: "nameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage2: {
                    name: "nameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage3: {
                    name: "nameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage4: {
                    name: "nameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                audit: {
                    name: "audit",
                    isArray: false,
                    type: {
                        nonModel: "AuditFields",
                    },
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "Labels",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        name: "LabelByWindow",
                        fields: ["windowId"],
                    },
                },
            ],
        },
        Security: {
            name: "Security",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                securityId: {
                    name: "securityId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                windowId: {
                    name: "windowId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage1: {
                    name: "nameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage2: {
                    name: "nameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage3: {
                    name: "nameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage4: {
                    name: "nameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                type: {
                    name: "type",
                    isArray: false,
                    type: {
                        enum: "TypeApp",
                    },
                    isRequired: true,
                    attributes: [],
                },
                securityXSM: {
                    name: "securityXSM",
                    isArray: false,
                    type: "Boolean",
                    isRequired: true,
                    attributes: [],
                },
                deleted: {
                    name: "deleted",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                audit: {
                    name: "audit",
                    isArray: false,
                    type: {
                        nonModel: "AuditFields",
                    },
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "Securities",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        name: "SecurityByWindow",
                        fields: ["windowId"],
                    },
                },
            ],
        },
        Setup: {
            name: "Setup",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                setupId: {
                    name: "setupId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage1: {
                    name: "nameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage2: {
                    name: "nameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage3: {
                    name: "nameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage4: {
                    name: "nameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                type: {
                    name: "type",
                    isArray: false,
                    type: {
                        enum: "DataType",
                    },
                    isRequired: true,
                    attributes: [],
                },
                value: {
                    name: "value",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "Setups",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        fields: ["setupId"],
                    },
                },
            ],
        },
        ConsumerSetup: {
            name: "ConsumerSetup",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                consumerId: {
                    name: "consumerId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                setupId: {
                    name: "setupId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                value: {
                    name: "value",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "ConsumerSetups",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        fields: ["consumerId", "setupId"],
                    },
                },
            ],
        },
        Audit: {
            name: "Audit",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                date: {
                    name: "date",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                type: {
                    name: "type",
                    isArray: false,
                    type: {
                        enum: "AuditType",
                    },
                    isRequired: true,
                    attributes: [],
                },
                code: {
                    name: "code",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                message: {
                    name: "message",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                systemMessage: {
                    name: "systemMessage",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                application: {
                    name: "application",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                process: {
                    name: "process",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                format: {
                    name: "format",
                    isArray: false,
                    type: {
                        enum: "AuditFormat",
                    },
                    isRequired: true,
                    attributes: [],
                },
                username: {
                    name: "username",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                url: {
                    name: "url",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                ipAddress: {
                    name: "ipAddress",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                appVersion: {
                    name: "appVersion",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                errorNumber: {
                    name: "errorNumber",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                duration: {
                    name: "duration",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                supplierId: {
                    name: "supplierId",
                    isArray: false,
                    type: "ID",
                    isRequired: false,
                    attributes: [],
                },
                consumerId: {
                    name: "consumerId",
                    isArray: false,
                    type: "ID",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "Audits",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
            ],
        },
        Crew: {
            name: "Crew",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                crewId: {
                    name: "crewId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage1: {
                    name: "nameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                nameLanguage2: {
                    name: "nameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage3: {
                    name: "nameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                nameLanguage4: {
                    name: "nameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                type: {
                    name: "type",
                    isArray: false,
                    type: {
                        enum: "TypeApp",
                    },
                    isRequired: true,
                    attributes: [],
                },
                deleted: {
                    name: "deleted",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                audit: {
                    name: "audit",
                    isArray: false,
                    type: {
                        nonModel: "AuditFields",
                    },
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "Crews",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        fields: ["crewId"],
                    },
                },
            ],
        },
        CrewSecurity: {
            name: "CrewSecurity",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                crewId: {
                    name: "crewId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                crew: {
                    name: "crew",
                    isArray: false,
                    type: {
                        model: "Crew",
                    },
                    isRequired: false,
                    attributes: [],
                    association: {
                        connectionType: "HAS_ONE",
                        associatedWith: "crewId",
                        targetName: "crewId",
                    },
                },
                securityId: {
                    name: "securityId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                security: {
                    name: "security",
                    isArray: false,
                    type: {
                        model: "Security",
                    },
                    isRequired: false,
                    attributes: [],
                    association: {
                        connectionType: "HAS_ONE",
                        associatedWith: "id",
                        targetName: "securityId",
                    },
                },
            },
            syncable: true,
            pluralName: "CrewSecurities",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        fields: ["crewId", "securityId"],
                    },
                },
            ],
        },
        CrewUser: {
            name: "CrewUser",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                crewId: {
                    name: "crewId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                crew: {
                    name: "crew",
                    isArray: false,
                    type: {
                        model: "Crew",
                    },
                    isRequired: false,
                    attributes: [],
                    association: {
                        connectionType: "HAS_ONE",
                        associatedWith: "crewId",
                        targetName: "crewId",
                    },
                },
                username: {
                    name: "username",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "CrewUsers",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        fields: ["crewId", "username"],
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "CrewsByUser",
                        fields: ["username"],
                        queryField: "CrewsByUser",
                    },
                },
            ],
        },
        Order: {
            name: "Order",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                supplierId: {
                    name: "supplierId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                consumerId: {
                    name: "consumerId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                consumerIdERP: {
                    name: "consumerIdERP",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                orderCodeERP: {
                    name: "orderCodeERP",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                date: {
                    name: "date",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                dateERP: {
                    name: "dateERP",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                deliveryOn: {
                    name: "deliveryOn",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                deliveryExpectedOn: {
                    name: "deliveryExpectedOn",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                requestComment: {
                    name: "requestComment",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                orderComments: {
                    name: "orderComments",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                shipping: {
                    name: "shipping",
                    isArray: false,
                    type: {
                        enum: "ShippingType",
                    },
                    isRequired: false,
                    attributes: [],
                },
                status: {
                    name: "status",
                    isArray: false,
                    type: {
                        enum: "OrderStatus",
                    },
                    isRequired: false,
                    attributes: [],
                },
                statusERP: {
                    name: "statusERP",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                historyStatusERP: {
                    name: "historyStatusERP",
                    isArray: true,
                    type: {
                        nonModel: "historyStatusERP",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                grossAmount: {
                    name: "grossAmount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                discountAmount: {
                    name: "discountAmount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                taxAmount: {
                    name: "taxAmount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                netAmount: {
                    name: "netAmount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                orderProducts: {
                    name: "orderProducts",
                    isArray: true,
                    type: {
                        model: "OrderProduct",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                    association: {
                        connectionType: "HAS_MANY",
                        associatedWith: "orderId",
                    },
                },
                currencyISO: {
                    name: "currencyISO",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                currencyISOErpRequest: {
                    name: "currencyISOErpRequest",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                currencyISOErp: {
                    name: "currencyISOErp",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                currencySymbol: {
                    name: "currencySymbol",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                exchangeRate: {
                    name: "exchangeRate",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                posted: {
                    name: "posted",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                postedOn: {
                    name: "postedOn",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                itemCount: {
                    name: "itemCount",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                paymentCondition: {
                    name: "paymentCondition",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                taxes: {
                    name: "taxes",
                    isArray: true,
                    type: {
                        nonModel: "TaxType",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                discounts: {
                    name: "discounts",
                    isArray: true,
                    type: {
                        nonModel: "DiscountType",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                paymentMethod: {
                    name: "paymentMethod",
                    isArray: false,
                    type: {
                        nonModel: "paymentMethodType",
                    },
                    isRequired: false,
                    attributes: [],
                },
                shippingAddress: {
                    name: "shippingAddress",
                    isArray: false,
                    type: {
                        nonModel: "shippingAddressType",
                    },
                    isRequired: false,
                    attributes: [],
                },
                group1: {
                    name: "group1",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                group2: {
                    name: "group2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                group3: {
                    name: "group3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                consumerName: {
                    name: "consumerName",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                sameOrderClass: {
                    name: "sameOrderClass",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "Orders",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        name: "bySupplierByDate",
                        fields: ["supplierId", "date"],
                        queryField: "OrdersBySupplierByDate",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "bySupplierByConsumer",
                        fields: ["supplierId", "consumerId"],
                        queryField: "OrdersBySupplierByConsumer",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "bySupplierByConsumerByDate",
                        fields: ["supplierId", "consumerId", "date"],
                        queryField: "OrdersBySupplierByConsumerByDate",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "bySupplierByConsumerByDate",
                        fields: ["supplierId", "consumerId", "date"],
                        queryField: "OrdersBySupplierByConsumerByDate",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "bySupplierByDeliveryDate",
                        fields: ["supplierId", "deliveryOn"],
                        queryField: "OrdersBySupplierByDeliveryDate",
                    },
                },
            ],
        },
        OrderProduct: {
            name: "OrderProduct",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                orderId: {
                    name: "orderId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                productId: {
                    name: "productId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                unitId: {
                    name: "unitId",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                price: {
                    name: "price",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                quantity: {
                    name: "quantity",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                grossAmount: {
                    name: "grossAmount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                discountAmount: {
                    name: "discountAmount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                taxAmount: {
                    name: "taxAmount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                netAmount: {
                    name: "netAmount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                shortnameLanguage1: {
                    name: "shortnameLanguage1",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                shortnameLanguage2: {
                    name: "shortnameLanguage2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                shortnameLanguage3: {
                    name: "shortnameLanguage3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                shortnameLanguage4: {
                    name: "shortnameLanguage4",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                imageInternalName: {
                    name: "imageInternalName",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                imageExternalURL: {
                    name: "imageExternalURL",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
            },
            syncable: true,
            pluralName: "OrderProducts",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        name: "OrderProductsByDetail",
                        fields: ["orderId"],
                    },
                },
            ],
        },
        SyncLog: {
            name: "SyncLog",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                supplierId: {
                    name: "supplierId",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                consumerId: {
                    name: "consumerId",
                    isArray: false,
                    type: "ID",
                    isRequired: false,
                    attributes: [],
                },
                date: {
                    name: "date",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                dateStart: {
                    name: "dateStart",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                dateEnd: {
                    name: "dateEnd",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                syncId: {
                    name: "syncId",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                process: {
                    name: "process",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                typeProcess: {
                    name: "typeProcess",
                    isArray: false,
                    type: {
                        enum: "TypeProcess",
                    },
                    isRequired: false,
                    attributes: [],
                },
                status: {
                    name: "status",
                    isArray: false,
                    type: {
                        enum: "StatusSync",
                    },
                    isRequired: true,
                    attributes: [],
                },
                isOk: {
                    name: "isOk",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                isWarning: {
                    name: "isWarning",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                message: {
                    name: "message",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                errors: {
                    name: "errors",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                systemMessage: {
                    name: "systemMessage",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                sourceCount: {
                    name: "sourceCount",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                inserted: {
                    name: "inserted",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                updated: {
                    name: "updated",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                noupdated: {
                    name: "noupdated",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                errored: {
                    name: "errored",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                errorNumber: {
                    name: "errorNumber",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                totalDuration: {
                    name: "totalDuration",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                extractDuration: {
                    name: "extractDuration",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                transformDuration: {
                    name: "transformDuration",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                loadDuration: {
                    name: "loadDuration",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "SyncLogs",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        name: "SyncLogBySupplier",
                        fields: ["supplierId", "date", "status"],
                        queryField: "SyncLogBySupplier",
                    },
                },
            ],
        },
        Statement: {
            name: "Statement",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                year: {
                    name: "year",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                month: {
                    name: "month",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                supplier: {
                    name: "supplier",
                    isArray: false,
                    type: {
                        model: "Supplier",
                    },
                    isRequired: false,
                    attributes: [],
                    association: {
                        connectionType: "BELONGS_TO",
                        targetName: "supplierId",
                    },
                },
                contractDetail: {
                    name: "contractDetail",
                    isArray: false,
                    type: {
                        nonModel: "ContractDetail",
                    },
                    isRequired: false,
                    attributes: [],
                },
                count: {
                    name: "count",
                    isArray: false,
                    type: {
                        nonModel: "TransactionCount",
                    },
                    isRequired: false,
                    attributes: [],
                },
                amount: {
                    name: "amount",
                    isArray: false,
                    type: {
                        nonModel: "TransactionTotalAmount",
                    },
                    isRequired: false,
                    attributes: [],
                },
                avgItem: {
                    name: "avgItem",
                    isArray: false,
                    type: {
                        nonModel: "TransactionAvgItem",
                    },
                    isRequired: false,
                    attributes: [],
                },
                avgAmount: {
                    name: "avgAmount",
                    isArray: false,
                    type: {
                        nonModel: "TransactionAvgAmount",
                    },
                    isRequired: false,
                    attributes: [],
                },
                itemCount: {
                    name: "itemCount",
                    isArray: false,
                    type: {
                        nonModel: "TransactionSumItem",
                    },
                    isRequired: false,
                    attributes: [],
                },
            },
            syncable: true,
            pluralName: "Statements",
            attributes: [
                {
                    type: "model",
                    properties: {},
                },
                {
                    type: "key",
                    properties: {
                        name: "BySupplier",
                        fields: ["supplierId", "month", "year"],
                        queryField: "StatementBySupplier",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "ByPeriod",
                        fields: ["month", "year"],
                        queryField: "StatementByPeriod",
                    },
                },
                {
                    type: "key",
                    properties: {
                        name: "ByYearAndPeriod",
                        fields: ["year", "month"],
                        queryField: "StatementByYearAndPeriod",
                    },
                },
            ],
        },
    },
    enums: {
        StatusSupplier: {
            name: "StatusSupplier",
            values: [
                "DRAFT",
                "VALIDATING",
                "CONFIGURING",
                "ACTIVE",
                "INACTIVE",
                "DELETED",
                "REJECTED",
            ],
        },
        AddressType: {
            name: "AddressType",
            values: ["MAIN"],
        },
        PhoneType: {
            name: "PhoneType",
            values: ["WORK", "HOME", "MOBILE"],
        },
        StatusConsumer: {
            name: "StatusConsumer",
            values: ["ACTIVE", "INACTIVE", "DELETED"],
        },
        Plans: {
            name: "Plans",
            values: ["BASIC", "PREMIUM"],
        },
        StatusSupplierRequest: {
            name: "StatusSupplierRequest",
            values: ["VALIDATING", "APPROVED", "REJECTED", "DELETED"],
        },
        StatusForSales: {
            name: "StatusForSales",
            values: ["LOCKED", "UNLOCKED"],
        },
        TypeApp: {
            name: "TypeApp",
            values: ["MOBILE", "WEB"],
        },
        DataType: {
            name: "DataType",
            values: ["STRING", "INT", "BOOL", "FLOAT"],
        },
        AuditType: {
            name: "AuditType",
            values: [
                "INFO",
                "WARNING",
                "ERROR",
                "TRACE",
                "AUDIT",
                "CREATE",
                "UPDATE",
                "DELETE",
            ],
        },
        AuditFormat: {
            name: "AuditFormat",
            values: ["TEXT", "JSON", "XML"],
        },
        ShippingType: {
            name: "ShippingType",
            values: ["PICKUP", "DELIVERY"],
        },
        OrderStatus: {
            name: "OrderStatus",
            values: ["DRAFT", "CALCULATED", "PLACED", "DELIVERED"],
        },
        TypeProcess: {
            name: "TypeProcess",
            values: ["MANUAL", "AUTOMATIC", "MOBILE"],
        },
        StatusSync: {
            name: "StatusSync",
            values: ["TODO", "PROCESSING", "DONE"],
        },
    },
    nonModels: {
        Tag: {
            name: "Tag",
            fields: {
                tag: {
                    name: "tag",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        Promotions: {
            name: "Promotions",
            fields: {
                id: {
                    name: "id",
                    isArray: false,
                    type: "ID",
                    isRequired: true,
                    attributes: [],
                },
                description: {
                    name: "description",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                imageInternalName: {
                    name: "imageInternalName",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                imageExternalURL: {
                    name: "imageExternalURL",
                    isArray: true,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
                since: {
                    name: "since",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                until: {
                    name: "until",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                active: {
                    name: "active",
                    isArray: false,
                    type: "Boolean",
                    isRequired: true,
                    attributes: [],
                },
            },
        },
        HistoryStatusSupplier: {
            name: "HistoryStatusSupplier",
            fields: {
                date: {
                    name: "date",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                status: {
                    name: "status",
                    isArray: false,
                    type: {
                        enum: "StatusSupplier",
                    },
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        Address: {
            name: "Address",
            fields: {
                type: {
                    name: "type",
                    isArray: false,
                    type: {
                        enum: "AddressType",
                    },
                    isRequired: true,
                    attributes: [],
                },
                street1: {
                    name: "street1",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                street2: {
                    name: "street2",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                street3: {
                    name: "street3",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                city: {
                    name: "city",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                cityId: {
                    name: "cityId",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                state: {
                    name: "state",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                stateId: {
                    name: "stateId",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                zipcode: {
                    name: "zipcode",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                country: {
                    name: "country",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                countryId: {
                    name: "countryId",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                default: {
                    name: "default",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                updatedOn: {
                    name: "updatedOn",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        Phone: {
            name: "Phone",
            fields: {
                type: {
                    name: "type",
                    isArray: false,
                    type: {
                        enum: "PhoneType",
                    },
                    isRequired: true,
                    attributes: [],
                },
                number: {
                    name: "number",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                default: {
                    name: "default",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                updatedOn: {
                    name: "updatedOn",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        Contract: {
            name: "Contract",
            fields: {
                current: {
                    name: "current",
                    isArray: false,
                    type: {
                        nonModel: "ContractDetail",
                    },
                    isRequired: false,
                    attributes: [],
                },
                future: {
                    name: "future",
                    isArray: false,
                    type: {
                        nonModel: "ContractDetail",
                    },
                    isRequired: false,
                    attributes: [],
                },
                history: {
                    name: "history",
                    isArray: true,
                    type: {
                        nonModel: "ContractDetail",
                    },
                    isRequired: false,
                    attributes: [],
                    isArrayNullable: true,
                },
            },
        },
        ContractDetail: {
            name: "ContractDetail",
            fields: {
                since: {
                    name: "since",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                plan: {
                    name: "plan",
                    isArray: false,
                    type: {
                        enum: "Plans",
                    },
                    isRequired: false,
                    attributes: [],
                },
                fees: {
                    name: "fees",
                    isArray: false,
                    type: {
                        nonModel: "TransactionFee",
                    },
                    isRequired: false,
                    attributes: [],
                },
                minTransaction: {
                    name: "minTransaction",
                    isArray: false,
                    type: {
                        nonModel: "TransactionMinimum",
                    },
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        TransactionFee: {
            name: "TransactionFee",
            fields: {
                deposit: {
                    name: "deposit",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                payment: {
                    name: "payment",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                order: {
                    name: "order",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        TransactionMinimum: {
            name: "TransactionMinimum",
            fields: {
                deposit: {
                    name: "deposit",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                payment: {
                    name: "payment",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                order: {
                    name: "order",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        paymentMethodType: {
            name: "paymentMethodType",
            fields: {
                key: {
                    name: "key",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                value: {
                    name: "value",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                default: {
                    name: "default",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        DaysDelivery: {
            name: "DaysDelivery",
            fields: {
                monday: {
                    name: "monday",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                tuesday: {
                    name: "tuesday",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                wednesday: {
                    name: "wednesday",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                thursday: {
                    name: "thursday",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                friday: {
                    name: "friday",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                saturday: {
                    name: "saturday",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                sunday: {
                    name: "sunday",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
                sameDayDelivery: {
                    name: "sameDayDelivery",
                    isArray: false,
                    type: "Boolean",
                    isRequired: true,
                    attributes: [],
                },
                sameDayDeliveryUntil: {
                    name: "sameDayDeliveryUntil",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
                sameDayDeliveryTimeZone: {
                    name: "sameDayDeliveryTimeZone",
                    isArray: false,
                    type: "String",
                    isRequired: true,
                    attributes: [],
                },
            },
        },
        AuditFields: {
            name: "AuditFields",
            fields: {
                createdBy: {
                    name: "createdBy",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                updatedBy: {
                    name: "updatedBy",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                deletedBy: {
                    name: "deletedBy",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        historyStatusERP: {
            name: "historyStatusERP",
            fields: {
                datetimeERP: {
                    name: "datetimeERP",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                statusERP: {
                    name: "statusERP",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        TaxType: {
            name: "TaxType",
            fields: {
                name: {
                    name: "name",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                amount: {
                    name: "amount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        DiscountType: {
            name: "DiscountType",
            fields: {
                name: {
                    name: "name",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                amount: {
                    name: "amount",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        shippingAddressType: {
            name: "shippingAddressType",
            fields: {
                key: {
                    name: "key",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                value: {
                    name: "value",
                    isArray: false,
                    type: "String",
                    isRequired: false,
                    attributes: [],
                },
                default: {
                    name: "default",
                    isArray: false,
                    type: "Boolean",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        TransactionCount: {
            name: "TransactionCount",
            fields: {
                deposit: {
                    name: "deposit",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                payment: {
                    name: "payment",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                order: {
                    name: "order",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        TransactionTotalAmount: {
            name: "TransactionTotalAmount",
            fields: {
                deposit: {
                    name: "deposit",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                payment: {
                    name: "payment",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                order: {
                    name: "order",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                orderUSD: {
                    name: "orderUSD",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                paymentUSD: {
                    name: "paymentUSD",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                depositUSD: {
                    name: "depositUSD",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        TransactionAvgItem: {
            name: "TransactionAvgItem",
            fields: {
                deposit: {
                    name: "deposit",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                payment: {
                    name: "payment",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                order: {
                    name: "order",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        TransactionAvgAmount: {
            name: "TransactionAvgAmount",
            fields: {
                deposit: {
                    name: "deposit",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                payment: {
                    name: "payment",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                order: {
                    name: "order",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                orderUSD: {
                    name: "orderUSD",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                paymentUSD: {
                    name: "paymentUSD",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
                depositUSD: {
                    name: "depositUSD",
                    isArray: false,
                    type: "Float",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
        TransactionSumItem: {
            name: "TransactionSumItem",
            fields: {
                deposit: {
                    name: "deposit",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                payment: {
                    name: "payment",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
                order: {
                    name: "order",
                    isArray: false,
                    type: "Int",
                    isRequired: false,
                    attributes: [],
                },
            },
        },
    },
    codegenVersion: "3.3.1",
    version: "2ba851e90037bbcd9b5f8dae414e5a2e",
}
