import React, { useEffect, useState } from "react"
import { SectionEditable, Title, SelectCombo, LoaderWindow, InputText } from 'xsales-components'
//import SeparateTop from "../../componentsConsumer/SeparateTop"
// import EditableData from "../../componentsConsumer/common/Section"
// import SelectCombo from "../../componentsConsumer/SelectCombo"
import { configTimezones } from "../../configConsumer/timeZones"
import { XSalesConsumer } from "../../Utils/Utils"
import { XSalesData } from "../../DataQuery/XSalesData"
import PreferenceDefault from "./../../configConsumer/preferenceDefault.json"
import { I18n } from "aws-amplify"
//import { MyAppContext } from '../../context'
// import Title from '../../componentsConsumer/common/Title'


export default function Settings(props) {
    const [state, setState] = useState(
        {
            loader: true,
            timezonesActive: "",
            languagesActive: "",
            versionMinAppa: "",
            versionMinAppios: "",
            updateChangeLanguageStatus: false,
            updateChangeFormatPreferenceStatus: false,
            updateChangeVersionMinApp: false,
            languages: [],
            timezones: [],
            setups: [],
        }
    )
    //const { language, setLanguage } =  useContext(MyAppContext)
    const optionsLanguage = [
        { label: I18n.get("lblEspanol"), value: "ES" },
        { label: I18n.get("lblEnglish"), value: "EN" },
    ]

    const [userRole, setUserRole] = useState("SUPPLIER")
  
    useEffect(() => {
        getRoleToApply();
        const optionsTimezone = configTimezones.map((item) => {
            return { label: item, value: item }
        }).sort(function (a, b) {
            if (a.label > b.label) {
                return 1;
            }
            if (a.label < b.label) {
                return -1;
            }
            return 0;
        });
        setState((prevState) => ({
            ...prevState,
            timezones: optionsTimezone
          }));
          const load = async () => {
            let supplierId = await XSalesConsumer.getCurrentUser().then((data) => {
                if (data.type.toUpperCase() !== "SUPPLIER") {
                    return data.user
                }
                return data.supplier.id
            }).catch(err => {
                return ''
            })
            XSalesData.Suppliers.GetPreferences(supplierId)
                .then((data) => {
                    console.log("getpreferences in action", data)
                    setState((prevState) => ({
                        ...prevState,
                        languagesActive: data.language,
                        timezonesActive:  data.timezone,
                        loader: false
                    }));
                })
                .catch((err) => {
                    setState((prevState) => ({
                        ...prevState,
                        languagesActive: PreferenceDefault.Language,
                        timezonesActive:  PreferenceDefault.TimeZone,
                        loader: false
                    }));
                }) 
        }
        load();
        getMinVersionAppa();
        getMinVersionAppios();
    }, [])

    async function getRoleToApply() {
        let rol = await XSalesConsumer.getCurrentUser()
            .then((data) => {
                return data.type
            })
            .catch((err) => {
                return "SUPPLIER"
            })
        setUserRole(rol)
    }


    const getMinVersionAppa= async () => {
        await XSalesData.Setup.getById("SY009")
        .then((data) => {
            if (data!==null){
                setState((prevState) => ({
                    ...prevState,
                    versionMinAppa: data.value,
                }));
            }
            setState((prevState) => ({
                ...prevState,
                updateChangeVersionMinApp: false,
            }));
        })
        .catch((err) => {
            console.log("error Obteniendo la version minima de la aplicacion", err)
        })
    }
    const getMinVersionAppios= async () => {
        await XSalesData.Setup.getById("SY010")
        .then((data) => {
            if (data!==null){
                setState((prevState) => ({
                    ...prevState,
                    versionMinAppios: data.value,
                }));
            }
            
            setState((prevState) => ({
                ...prevState,
                updateChangeVersionMinApp: false,
            }));
        })
        .catch((err) => {
            console.log("error Obteniendo la version minima de la aplicacion", err)
        })
    }
   /*  const sendDataLogin = () => {
        //console.log("send  data login AD")
    } */

    const CancelLanguagePreference = async () => {
        //console.log("CancelLanguagePreference")
        setState((prevState) => ({
            ...prevState,
            updateChangeLanguageStatus: false,
          }));
    }

    const CancelFormatPreference = async () => {
        //console.log("CancelFormatPreference")
        setState((prevState) => ({
            ...prevState,
            updateChangeFormatPreferenceStatus: false,
          }));
    }

    const CancelVersionMinApp= async () => {
        getMinVersionAppa();
        getMinVersionAppios();
        setState((prevState) => ({
            ...prevState,
            updateChangeVersionMinApp: false,
        }));
    }

    const ChangeLanguagePreference = async () => {
        //const obj = state
        let supplierId = await XSalesConsumer.getCurrentUser().then((data) => {
            if (data.type.toUpperCase() !== "SUPPLIER") {
                return data.user
            }
            return data.supplier.id
        })
        const { timezonesActive, languagesActive } = state
        await XSalesData.Suppliers.SetPreferences(
            supplierId,
            languagesActive,
            timezonesActive
        )
            .then((data) => {
                //console.log("guardando preferences del supplier", data) 
                I18n.setLanguage(languagesActive)
                //setLanguage(languagesActive)
                setState((prevState) => ({
                    ...prevState,
                    updateChangeLanguageStatus: false,
                  }));
                localStorage.setItem("languageApp", languagesActive)
            })
            .catch((err) => {
                console.log("error guardando preferences del supplier", err)
            })
        setState((prevState) => ({
            ...prevState,
            updateChangeLanguageStatus: false,
          }));
    }

    const ChangeFormatPreference = async () => {
        //console.log("ChangeLanguagePreference")
        //const obj = state
        let supplierId = await XSalesConsumer.getCurrentUser().then((data) => {
            return data.supplier?.id
        })
        const { timezonesActive, languagesActive } = state
        await XSalesData.Suppliers.SetPreferences(
            supplierId,
            languagesActive,
            timezonesActive
        )
            .then((data) => {
                //console.log("guardando preferences del supplier", data)
                setState((prevState) => ({
                    ...prevState,
                    updateChangeFormatPreferenceStatus: false,
                  }));
            })
            .catch((err) => {
                console.log("error guardando preferences del supplier", err)
            })
        setState((prevState) => ({
            ...prevState,
            updateChangeFormatPreferenceStatus: false,
          }));
    }

    const ChangeVersionMinApp = async () => {
        ChangeVersionMinAppa();
        ChangeVersionMinAppios();
        setState((prevState) => ({
            ...prevState,
            updateChangeVersionMinApp: false,
        }));
    }

    const ChangeVersionMinAppa = async () => {
        let objSetup= {
            setupId: "SY009",
            nameLanguage1: "Min Version App Android",
            type: "STRING",
            value: state.versionMinAppa.toString()
        }

        await XSalesData.Setup.setSetup(objSetup)
        .then((data) => {
        })
        .catch((err) => {
            console.log("error guardando preferences del supplier", err)
        })
    }
    const ChangeVersionMinAppios = async () => {
        let objSetup= {
            setupId: "SY010",
            nameLanguage1: "Min Version App IOS",
            type: "STRING",
            value: state.versionMinAppios.toString()
        }

        await XSalesData.Setup.setSetup(objSetup)
        .then((data) => {
        })
        .catch((err) => {
            console.log("error guardando preferences del supplier", err)
        })
    }

    const UpdateActionLanguageStatus = () => {
        setState((prevState) => ({
            ...prevState,
            updateChangeLanguageStatus: !state.updateChangeLanguageStatus,
          }));
    }

    const UpdateActionFormatPreferenceStatus = () => {
        setState((prevState) => ({
            ...prevState,
            updateChangeFormatPreferenceStatus: !state.updateChangeFormatPreferenceStatus,
          }));
    }
    const UpdateActionVersionMinAppStatus = () => {
        setState((prevState) => ({
            ...prevState,
            updateChangeVersionMinApp: !state.updateChangeVersionMinApp,
          }));
    }
    

    const handleChangeLanguage = async (e) => {
        //console.log("handleChangeLanguage", e)
        let value = e.value
        //I18n.setLanguage(value) 
        setState((prevState) => ({
            ...prevState,
            languagesActive: value
          }));
    }

    const handleChangeTimeZone = (e) => {
        //console.log("handleChangeTimeZone", e)
        let value = e.value
        setState((prevState) => ({
            ...prevState,
            timezonesActive: value
          }));
    }
    const  handleChangeMinVersiona = (e) => {
        const element = e.target;
        setState((prevState) => ({
            ...prevState,
            versionMinAppa: element.value,
        }));
    }

    const  handleChangeMinVersionios = (e) => {
        const element = e.target;
        setState((prevState) => ({
            ...prevState,
            versionMinAppios: element.value,
        }));
    }

    return (
        <>
            {
                state.loader ?
                    <LoaderWindow />
                    :
                    <>
                        <Title label={I18n.get("lblSetting")} style={{ marginBottom: '25px' }} />
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <SectionEditable
                                    label={I18n.get("lblPreferences")}
                                    labelEdit={I18n.get("lblPreferences")}
                                    Action={"UPDATE"}
                                    UpdateAction={UpdateActionLanguageStatus}
                                    CancelAction={CancelLanguagePreference}
                                    SaveAction={ChangeLanguagePreference}
                                    Collapse={false}
                                    lblbtnSave={I18n.get("lblSave")}
                                    lblbtnCancel={I18n.get("lblCancel")}
                                >
                                    <SelectCombo
                                        id="language"
                                        name="language"
                                        label={I18n.get("lblLanguage")}
                                        options={optionsLanguage}
                                        optionSelected={state.languagesActive}
                                        value={state.languagesActive}
                                        onChange={handleChangeLanguage}
                                        isEditable={state.updateChangeLanguageStatus}
                                    />
                                </SectionEditable>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <SectionEditable
                                    label={I18n.get("lblFormatPreferences")}
                                    labelEdit={I18n.get("lblFormatPreferences")}
                                    Action={"UPDATE"}
                                    UpdateAction={UpdateActionFormatPreferenceStatus}
                                    CancelAction={CancelFormatPreference}
                                    SaveAction={ChangeFormatPreference}
                                    Collapse={false}
                                    lblbtnSave={I18n.get("lblSave")}
                                    lblbtnCancel={I18n.get("lblCancel")}
                                >
                                    <SelectCombo
                                        id="timezone"
                                        name="timezone"
                                        label={I18n.get("lbltimezone")}
                                        options={state.timezones}
                                        optionSelected={state.timezonesActive}
                                        value={state.timezonesActive}
                                        onChange={handleChangeTimeZone}
                                        isEditable={state.updateChangeFormatPreferenceStatus}
                                    />
                                </SectionEditable>
                            </div>
                        </div>
                        {(userRole === "admin" || userRole === "support") && (
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <SectionEditable
                                        label={I18n.get("lblConfigGeneral")}
                                        labelEdit={I18n.get("lblFormatPreferences")}
                                        Action={"UPDATE"}
                                        UpdateAction={UpdateActionVersionMinAppStatus}
                                        CancelAction={CancelVersionMinApp}
                                        SaveAction={ChangeVersionMinApp}
                                        Collapse={false}
                                        lblbtnSave={I18n.get("lblSave")}
                                        lblbtnCancel={I18n.get("lblCancel")}
                                    >
                                        <div className="row">
                                        <div className="col-sm-12 col-md-4 ml-0">
                                            <InputText
                                                id="minversionappa"
                                                name="minversionappa"
                                                required={true}
                                                maxLength="10"
                                                label={I18n.get("lblminversionappa")}
                                                value={state.versionMinAppa}
                                                onChange={handleChangeMinVersiona}
                                                isEditable={state.updateChangeVersionMinApp}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-4 mr-0">
                                            <InputText
                                                id="minversionappios"
                                                name="minversionappios"
                                                required={true}
                                                maxLength="10"
                                                label={I18n.get("lblminversionappios")}
                                                value={state.versionMinAppios}
                                                onChange={handleChangeMinVersionios}
                                                isEditable={state.updateChangeVersionMinApp}
                                            />
                                        </div>
                                        </div>
                                    </SectionEditable>
                                </div>
                            </div>
                        )}
                        
                    </>
            }
        </>
    )
}
