import React, { useState, useEffect } from "react"
import { InputEmail, InputPass, Button, Loader } from "xsales-components"

import Linked from "../../componentsConsumer/Link"
import PreferenceDefault from "../../configConsumer/preferenceDefault.json"
import ImageCDN from "../../configConsumer/imagesDefaultCDN.json"
import { I18n } from "@aws-amplify/core"
import { Auth } from "aws-amplify"
import { XSalesConsumer } from "./../../Utils/Utils"
import "../../assets/css/login.css"

import {analyticsApp} from '../../firebase/firebaseApp'
import { logEvent } from "firebase/analytics";
//import { XSalesData } from "../../DataQuery/XSalesData"

function SignIn(props) {
    useEffect(() => {
        XSalesConsumer.getCurrentUserFromCognitoNew().then((data) => {
           if(data){
                if (data.attributes["custom:userType"] === "2") {
                    // props.history.push("/Main")
                    window.location.href = '/Main'
                }
           }
        })  
        // XSalesConsumer.getCurrentUserFromCognito()
        //     .then((data) => {    
        //         // console.log('user autheticated', data)
        //         if (data.attributes["custom:userType"] === "2") {
        //             // props.history.push("/Main")
        //             window.location.href = '/Main'
        //         }
        //     })
        //     .catch((err) => {
        //         console.log("SignIn user not authenticated yet", err)
        //     })
            
    })

    const sendDataAuth = async  (e) => {
        // e.preventDefault()
        if (
            sectionData.email.trim() !== "" &&
            sectionData.password.trim() !== ""
        ) {
            setShowLoader(true)
            await Auth.signIn(
                sectionData.email.trim().toLowerCase(),
                sectionData.password.trim()
            )
                .then(async (data) => {
                    // console.log("sign in data from ", data)
                    logEvent(analyticsApp, 'login', {
                        email: sectionData.email.trim().toLowerCase()
                      });

                    
                    //the user require new password
                    if (
                        data.challengeName !== null &&
                        data.challengeName !== undefined &&
                        data.challengeName === "NEW_PASSWORD_REQUIRED"
                    ) {
                        //console.log("changePassword is required", data)
                    }
                })
                .catch(async (err) => {
                    //console.log("error doing login", err)
                    if (err.code === "UserNotConfirmedException") {
                        XSalesConsumer.fireMessage(
                            "lblLogin",
                            "msgUserNotConfirmetYet"
                        )
                        await Auth.resendSignUp(sectionData.email)
                            .then(async (data) => {
                                XSalesConsumer.fireMessage(
                                    "lblLogin",
                                    "msgResendingCode"
                                )
                                props.history.push(
                                    `/Validation/${sectionData.email}`
                                )
                                return
                            })
                            .catch(async (err) => {
                                XSalesConsumer.fireMessage(
                                    "lblLogin",
                                    "msgLoginFail"
                                )
                            })
                    } else if (err.code === "NotAuthorizedException") {
                        if (err.message === "User is disabled.") {
                            XSalesConsumer.fireMessage(
                                "lblLogin",
                                "msgUserDisabled"
                            )
                        } else {
                            XSalesConsumer.fireMessage(
                                "lblLogin",
                                "msgUserPassIncorrect"
                            )
                        }
                    } else if (err.code === "UserNotFoundException") {
                        XSalesConsumer.fireMessage(
                            "lblLogin",
                            "msgLoginUserNotExist"
                        )
                    } else {
                        XSalesConsumer.fireMessage("lblLogin", "msgLoginFail")
                    }
                    setShowLoader(false)
                })
        } else {
            return false
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name !== undefined && name !== null && name !== "") {
            setSectionData((prevState) => ({
                ...prevState,
                [name]: value
            }))
        }
    }

    const [sectionData, setSectionData] = useState({
        email: "",
        password: "",
    })

    const [showLoader, setShowLoader] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            
        await sendDataAuth()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <section className="login__container">
                <div className="login__box">
                    <a
                        href={PreferenceDefault.XSalesConsumerWEB}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={ImageCDN.XSalesConsumerWebIsotipo}
                            alt="Logo Consumer"
                        /><br />
                        <img
                            src={ImageCDN.XSalesConsumerWebLogo}
                            alt="Logo Consumer"
                        />
                    </a>
                    
                    <div className="container-fluid">
                        <div className="row mt-3">
                            <div className="col-sm-12 col-md-12 text-center">
                                <h4 className="login_sigInIndication">
                                    {I18n.get("lblSigInIndication")}
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <form className="login__form" onSubmit={handleSubmit}>
                                <div className="col-sm-12 col-md-12">
                                    <InputEmail
                                        id="email"
                                        name="email"
                                        maxLength="30"
                                        required={true}
                                        label={I18n.get("lblEmail")}
                                        value={sectionData.email}
                                        onChange={handleChange}
                                    />
                                    <InputPass
                                        id="password"
                                        name="password"
                                        maxLength="30"
                                        required={true}
                                        label={I18n.get("lblPassword")}
                                        value={sectionData.password}
                                        onChange={handleChange}
                                        Icon={true}
                                    />
                                    {/* <button className="login__btn btn btn-primary btn-block">{I18n.get("lblSend")}</button> */}
                                    <Button
                                        id="btn-login"
                                        label={I18n.get("lblSend")}
                                        name={I18n.get("lblSend")}
                                        size="lg"
                                        color="primary"
                                        type="submit"
                                        className="login__btn btn btn-primary btn-block"
                                        outline={false}
                                        // dataDismiss='modal'
                                        // onClick={() => alert("")}
                                    />
                                </div>
                            </form>    
                        </div>
                        <div className="row login__Linked">
                            <div className="col-sm-6 col-md-6 login__options text-left">
                                <Linked
                                    label={I18n.get("lblForgotPass")}
                                    href="/Forgot"
                                />
                            </div>
                            {/*  <div className="col-sm-6 col-md-6 login__options text-right">
                                <Linked
                                    label={I18n.get("lblRegisterNewUser")}
                                    href="/SignUp"
                                />
                            </div>*/}
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 login__Version">
                                <span>Version {PreferenceDefault.XSalesConsumerWEBVersion}</span>
                            </div>
                        </div>
                        
                    </div>
                    {showLoader && (
                        <Loader
                            center={true}
                            style={{ height: "60px", width: "60px" }}
                        />
                    )}
                </div>
            </section>
        </>
    )
}

export default SignIn
