import React, { useEffect, useState } from "react"
import { SectionEditable, CheckBox, SeparateTop, Warning } from "xsales-components"
import { I18n } from "aws-amplify"
import { useParams } from "react-router-dom"
import { XSalesData } from "../../../DataQuery/XSalesData"


export default function ConsumerXSM(props) {
    let { id } = useParams()

    /* eslint-disable no-unused-vars */
    const [consumerId, SetConsumerId] = useState(id)
    /* eslint-enable no-unused-vars */

    const [consumerXSM, SetConsumerXSM] = useState(false)

    const handleChange = () => {
        SetConsumerXSM(!consumerXSM)
    }

    useEffect(() => {
        XSalesData.Consumer.GetConsumer(consumerId)
            .then(async (data) => {
                //si viene NULL asumir false
                SetConsumerXSM(data.isXSM ?? false)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [id])// eslint-disable-line react-hooks/exhaustive-deps

    const [updateChangeisXSM, SetUpdateChangeisXSM] = useState(false)

    const UpdateActionButtonisXSM = () => {
        SetUpdateChangeisXSM(!updateChangeisXSM)
    }

    const CancelisXSM = (e) => {
        SetUpdateChangeisXSM(false)
        props.RefreshData()
    }

    const ChangeisXSM = async (e) => {
        await XSalesData.Consumer.SetConsumerXSM(consumerId, consumerXSM)
            .then((data) => {
                //console.log("saved ok in method SetConsumerXSM", data)
                props.RefreshData()
            })
            .catch((err) => {
                console.log("error running SetConsumerXSM method", err)
            })
        SetUpdateChangeisXSM(false)
    }

    return (
        <div className="col-sm-12">
            <SeparateTop>
                <SectionEditable
                    label={I18n.get("lblXSM")}
                    labelEdit={I18n.get("lblXSM")}
                    Action={"UPDATE"}
                    UpdateAction={UpdateActionButtonisXSM}
                    CancelAction={CancelisXSM}
                    SaveAction={ChangeisXSM}
                    Collapse={false}
                    lblbtnSave={I18n.get("lblSave")}
                    lblbtnCancel={I18n.get("lblCancel")}
                >
                    <div className="row">
                        <div className="col-sm-12">
                            <CheckBox
                                id="isXSM"
                                name="isXSM"
                                value={consumerXSM}
                                onChange={handleChange}
                                Label={I18n.get("lblisConsumerXSM")}
                                isEditable={updateChangeisXSM}
                            />
                        </div>
                        <Warning>{I18n.get("lblXSMConsumerInfo")}</Warning>
                    </div>
                </SectionEditable>
            </SeparateTop>
        </div>
    )
}
